import { IPartialDriver } from "../../models";

export const driverCategories: any = {
  all: "All",
  accounting: "Accounting",
  nosql: "Big Data & NoSQL",
  collab: "Collaboration",
  erpcrm: "CRM & ERP",
  ecommerce: "E-Commerce",
  apifile: "File & API",
  marketing: "Marketing",
  rdbms: "RDBMS",
};

export function compareDrivers(a: IPartialDriver, b: IPartialDriver): number {
  return (a.niceName ?? "").localeCompare(b.niceName ?? "");
}
