import { Card, CardBody } from "reactstrap";
import { TitleAndToggleButtonRow } from "./TitleAndToggleButtonRow";
import { RunFrequencyRow } from "./RunFrequencyRow";
import { DestinationWriteSchemeRow } from "./DestinationWriteSchemeRow";
import { DestinationSelectors } from "./DestinationSelectors";
import { JobType } from "../../../../models/Cache/JobType";

interface IScheduledQueryDetailsCard {
  setUnsavedChanges: (unsavedChanges: boolean) => void;
}

export const ScheduledQueryDetailsCard = (
  props: IScheduledQueryDetailsCard,
) => {
  const { setUnsavedChanges } = props;

  return (
    <Card
      className="scheduled-query-details-card w-100"
      data-testid="scheduled-query-details-card"
    >
      <CardBody>
        <TitleAndToggleButtonRow
          jobType={JobType.ScheduledQuery}
          setUnsavedChanges={setUnsavedChanges}
        />
        <RunFrequencyRow
          jobType={JobType.ScheduledQuery}
          setUnsavedChanges={setUnsavedChanges}
        />
        <DestinationWriteSchemeRow setUnsavedChanges={setUnsavedChanges} />
        <DestinationSelectors setUnsavedChanges={setUnsavedChanges} />
      </CardBody>
    </Card>
  );
};
