import { useEffect, useRef, useState } from "react";
import { useAPI } from "../../../components/useAPI";
import { BackendType, RequestType } from "../../../components/withAPI";
import { IConnection, IUser } from "../../../models";
import { ToastrError } from "../../../services/toastrService";
import { AISchemaOption, AITableOption } from "./AIWizard";
import { IOpenAIRequest } from "../../../models/OpenAI/IOpenAIRequest";
import { buildMetadataString } from "./aicomponents/metadataStringUtils";
import { debounce } from "lodash";
import useTokenCalculator from "./useTokenCalculator";
import { IAzureOpenAIQueryResult } from "../../../models/OpenAI/IAzureOpenAIQueryResult";
import { mockEmptyAIResponse } from "./AIWizardFlyout.mock";
import { useMutation } from "@tanstack/react-query";
import { getConnections } from "../../connections/connectionList/getConnections";
import { getBillingUsage } from "../../users/api/getBillingUsage";
import { useAppDispatch } from "../../../redux/hooks";
import { addUsage } from "../../../redux/actions";

type openAITableMetadata = {
  table: AITableOption;
  metadata: string;
};

export const useAIWizardFunctions = () => {
  const dispatch = useAppDispatch();

  const [connectionList, setConnectionList] = useState<IConnection[]>([]);
  const [selectedConnections, setSelectedConnections] = useState<IConnection[]>(
    [],
  );
  const [processingConnections, setProcessingConnections] = useState(false);

  const [schemaList, setSchemaList] = useState<AISchemaOption[]>([]);
  const [selectedSchemas, setSelectedSchemas] = useState<AISchemaOption[]>([]);
  const [processingSchemas, setProcessingSchemas] = useState(false);

  const [tableList, setTableList] = useState<AITableOption[]>([]);
  const [selectedTables, setSelectedTables] = useState<AITableOption[]>([]);
  const [processingTables, setProcessingTables] = useState(false);

  const [aiResponse, setAiResponse] = useState<
    IAzureOpenAIQueryResult | undefined
  >(mockEmptyAIResponse);
  const [hasExecuted, setHasExecuted] = useState<boolean>(false);
  const [tableMetadata, setTableMetadata] = useState<openAITableMetadata[]>([]);
  const [metadataTokenLength, setMetadataTokenLength] = useState<number>(0);
  const [processingSubmit, setProcessingSubmit] = useState<boolean>(false);
  const [promptText, setPromptText] = useState<string>("");

  const api = useAPI();
  const { tokenCount, calculateTokens } = useTokenCalculator();

  const { mutate: getConnectionsData } = useMutation({
    mutationKey: ["/account/connections"],
    mutationFn: (currentUserId: string) =>
      getConnections({ IsAdmin: false, CurrentUserId: currentUserId }),
    meta: {
      errorMessage: "Failed to get connection list due to the following error:",
    },
    onSuccess: (data) => {
      setConnectionList(data?.connections ?? []);
    },
  });

  const { mutateAsync: getUsageAsync } = useMutation({
    mutationKey: ["/account/billing/usage"],
    mutationFn: getBillingUsage,
    meta: {
      errorMessage: "Failed to get account usage due to the following error:",
    },
    onSuccess: (data) => {
      dispatch(addUsage(data));
    },
  });

  // Whenever the metadata from the selected tables updates, we need to update the token count automatically.
  useEffect(() => {
    async function updateMetadataTokens() {
      if (selectedTables?.length > 0) {
        const metadataTokens = await fetchMetadataTokenCount(tableMetadata);
        setMetadataTokenLength(metadataTokens || 0);
      } else {
        setMetadataTokenLength(0);
      }
    }
    updateMetadataTokens();
  }, [tableMetadata]); // eslint-disable-line

  // In the event that the user has only selected one connection, and that connection only has one schema,
  // we auto-select that schema for them. It is not deselected if another connection is added.
  useEffect(() => {
    async function fetchSingleConnectionSingleSchemaData() {
      await onSchemaChange(schemaList);
    }

    if (selectedConnections?.length === 1 && schemaList?.length === 1) {
      fetchSingleConnectionSingleSchemaData();
    }
  }, [selectedConnections, schemaList]); // eslint-disable-line

  async function fetchMetadataTokenCount(
    metadataStrings: openAITableMetadata[],
  ) {
    const metadata = metadataStrings.map(
      (tableMetadata) => tableMetadata.metadata,
    );
    const data: IOpenAIRequest = {
      promptText: "",
      metadata: metadata,
    };
    const { status, payload } = await api.callAPI(
      RequestType.Post,
      "/openai/tokens",
      "Failed to get token count: ",
      data,
      BackendType.OpenAIService,
    );
    if (status === 200) {
      return payload;
    } else {
      return null;
    }
  }
  async function getConnectionList(user: IUser) {
    getConnectionsData(user.id);
  }

  async function onConnectionChange(newConnections: IConnection[]) {
    setProcessingConnections(true);

    // If a connection was removed, remove the associated schemas, tables, and metadata info.
    const removedConnections = selectedConnections.filter(
      (conn) => !newConnections.includes(conn),
    );
    if (removedConnections.length > 0) {
      removeSchemasForConnection(removedConnections[0]);
    }

    // If a connection was added, fetch the schemas associated with that connection.
    const addedConnections = newConnections.filter(
      (conn) => !selectedConnections.includes(conn),
    );
    if (addedConnections.length > 0) {
      await handleConnectionAdded(addedConnections[0]);
    }

    setSelectedConnections(newConnections);
    setProcessingConnections(false);
    setHasExecuted(false);
  }

  async function handleConnectionAdded(connection: IConnection) {
    const { status, payload } = await api.callAPI(
      RequestType.Get,
      `/schemas?catalogName=${encodeURIComponent(connection.name!)}`,
      "Failed to retrieve schema list list due to the following error:",
    );

    if (status === 200) {
      const data = await payload;
      if (data.error) {
        ToastrError("Error fetching results", data.error.message);
        return;
      }
      const results = data.results[0].rows;
      const newSchemaOptions: AISchemaOption[] = results.map((result: any) => {
        return {
          connectionName: result[0],
          driver: connection.driver,
          schemaName: result[1],
        };
      });

      setSchemaList((prevSchemaList) => [
        ...prevSchemaList,
        ...newSchemaOptions,
      ]);
    }
  }

  async function onSchemaChange(newSchemas: AISchemaOption[]) {
    setProcessingSchemas(true);

    // If a schema was removed, remove any associated tables.
    const removedSchemas = selectedSchemas.filter(
      (schema) => !newSchemas.includes(schema),
    );
    if (removedSchemas.length > 0) {
      removeTablesForSchemas(removedSchemas);
    }

    // If a schema was added, expand the list of available tables.
    const addedSchemas = newSchemas.filter(
      (schema) => !selectedSchemas.includes(schema),
    );
    if (addedSchemas.length > 0) {
      await handleSchemaAdded(addedSchemas[0]);
    }

    setSelectedSchemas(newSchemas);
    setProcessingSchemas(false);
    setHasExecuted(false);
  }

  async function handleSchemaAdded(schema: AISchemaOption) {
    const { status, payload } = await api.callAPI(
      RequestType.Get,
      `/tables?catalogName=${encodeURIComponent(schema.connectionName)}&schemaName=${encodeURIComponent(schema.schemaName)}`,
      "Error fetching tables:",
    );

    if (status === 200) {
      const data = await payload;
      if (data.error) {
        ToastrError("Error fetching results", data.error.message);
        return;
      }
      const results = data.results[0].rows;
      const newTableOptions: AITableOption[] = results.map((result: any) => {
        return {
          connectionName: result[0],
          driver: schema.driver,
          schemaName: result[1],
          tableName: result[2],
        };
      });

      setTableList((prevTableList) => [...prevTableList, ...newTableOptions]);
    }
  }

  async function onTableChange(newTables: AITableOption[]) {
    setProcessingTables(true);

    // Check if a table was removed. If it was, remove it from the metadata string array.
    const removedTables = selectedTables.filter(
      (table) => !newTables.includes(table),
    );
    if (removedTables.length > 0) {
      removeMetadataForTables(removedTables);
    }

    // Check if a table was added. If it was, add it to the metadata string array.
    const addedTables = newTables.filter(
      (table) => !selectedTables.includes(table),
    );
    if (addedTables.length > 0) {
      await handleTableAdded(addedTables[0]);
    }

    setSelectedTables(newTables);
    setProcessingTables(false);
    setHasExecuted(false);
  }

  async function handleTableAdded(table: AITableOption) {
    const newMetadata = await buildMetadataString(table, api.callAPI);
    const newMetadataObject: openAITableMetadata = {
      table,
      metadata: newMetadata,
    };
    setTableMetadata([...tableMetadata, newMetadataObject]);
  }

  function removeSchemasForConnection(connection: IConnection) {
    // Get the list of schemas that should be removed
    const removedSchemas = schemaList.filter(
      (schema) => schema.connectionName === connection.name,
    );

    // Filter out schema options & seleced schemas that belong to the removed connection
    const trimmedSchemaList = schemaList.filter(
      (schema) => !removedSchemas.includes(schema),
    );
    const trimmedSelectedSchemas = selectedSchemas.filter(
      (schema) => !removedSchemas.includes(schema),
    );

    // Remove tables for each of the purged schemas
    removeTablesForSchemas(removedSchemas);
    setSchemaList(trimmedSchemaList);
    setSelectedSchemas(trimmedSelectedSchemas);
  }

  function removeTablesForSchemas(schemas: AISchemaOption[]) {
    // Get the full list of tables that need to be removed for each passed in schema
    let tablesToRemove: AITableOption[] = [];
    schemas.forEach((schema) => {
      const tables = tableList.filter(
        (table) => table.schemaName === schema.schemaName,
      );
      tablesToRemove = [...tablesToRemove, ...tables];
    });

    // Remove each entry in tablesToRemove from both TableList and SelectedTables
    const trimmedTableList = tableList.filter(
      (table) => !tablesToRemove.includes(table),
    );
    const trimmedSelectedTables = selectedTables.filter(
      (table) => !tablesToRemove.includes(table),
    );

    // Remove metadata entries for each of the purged tables
    removeMetadataForTables(tablesToRemove);
    setTableList(trimmedTableList);
    setSelectedTables(trimmedSelectedTables);
  }

  function removeMetadataForTables(tables: AITableOption[]) {
    // For each table passed in, get the tableMetadata associated with that table
    let metadataToRemove: openAITableMetadata[] = [];
    tables.forEach((table) => {
      const metadata = tableMetadata.filter(
        (metadata) => metadata.table === table,
      );
      metadataToRemove = [...metadataToRemove, ...metadata];
    });

    // Remove the matching entries in tableMetadata
    const trimmedMetadata = tableMetadata.filter(
      (metadata) => !metadataToRemove.includes(metadata),
    );
    setTableMetadata(trimmedMetadata);
  }

  function onPromptChange(prompt: string) {
    setPromptText(prompt);
    debouncedCalculateTokens(prompt);
    setHasExecuted(false);
  }

  async function sendQueryGenerationRequest() {
    setProcessingSubmit(true);

    const trimmedPrompt = promptText.trim();
    setPromptText(trimmedPrompt);
    debouncedCalculateTokens?.cancel();
    calculateTokens(trimmedPrompt);

    const metadata = tableMetadata.map(
      (tableMetadata) => tableMetadata.metadata,
    );
    const data: IOpenAIRequest = {
      promptText: trimmedPrompt,
      metadata: metadata,
    };

    const { status, payload } = await api.callAPI(
      RequestType.Post,
      "/openai/query",
      "Failed to to get response from service: ",
      data,
      BackendType.OpenAIService,
    );

    if (status === 200) {
      setAiResponse(payload);
      await getUsageAsync();
    }

    setProcessingSubmit(false);
    setHasExecuted(true);
  }

  function resetFields() {
    setSelectedConnections([]);
    setSelectedSchemas([]);
    setSelectedTables([]);
    setSchemaList([]);
    setTableList([]);
    setPromptText("");
    calculateTokens("");
    setMetadataTokenLength(0);
    setTableMetadata([]);
    setAiResponse(undefined);
  }

  const debouncedCalculateTokens = useRef(
    debounce(calculateTokens, 500),
  ).current;

  return {
    aiResponse,
    connectionList,
    getConnectionList,
    getUsageAsync,
    hasExecuted,
    metadataTokenLength,
    onConnectionChange,
    onPromptChange,
    onSchemaChange,
    onTableChange,
    processingConnections,
    processingSchemas,
    processingTables,
    processingSubmit,
    promptText,
    resetFields,
    schemaList,
    selectedConnections,
    selectedSchemas,
    selectedTables,
    sendQueryGenerationRequest,
    tableList,
    tokenCount,
  };
};
