import { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  UncontrolledTooltip,
} from "reactstrap";

import { JobStatusBadge } from "../../components/JobStatusBadge";
import { InfoIcon } from "../../../../components/InfoIcon";
import { ICacheJob } from "../../../../models/Cache/ICacheJob";
import { JobStatus } from "../../../../models/Cache/JobStatus";
import { formatUTCDateTime } from "../../../../utility/FormatUTCDateTime";
import { CDataModalV2 } from "../../../../components/modal/CDataModalV2";
import { IScheduledQuery } from "../../../../models/ScheduledQueries/IScheduledQuery";
import { useMutation } from "@tanstack/react-query";
import { stopCacheJob } from "../../../../api/CacheJobs/stopCacheJob";
import { JobType } from "../../../../models/Cache/JobType";
import { useFormikContext } from "formik";
import { format } from "date-fns";
import { enUS } from "date-fns/locale";
import { ToastrError } from "../../../../services/toastrService";
import { jobsConstants } from "../../Jobs.constants";
import { useJobsFunctions } from "../../useJobsFunctions";
import { ButtonType, CDataButton } from "src/components/buttons/CDataButton";
import { stopScheduledQuery } from "src/pages/jobs/api/stopScheduledQuery";

export const RunHistoryCard = <T extends ICacheJob | IScheduledQuery>() => {
  const [showStopJobModal, setShowStopJobModal] = useState(false);
  const { setValues, values } = useFormikContext<T>();

  const { runScheduledQueryAsync, runCacheJobAsync } = useJobsFunctions();

  const isAddJob = values.id === jobsConstants.DEFAULT_JOB_ID;
  const isCacheJob = values.jobType === JobType.Caching;

  const jobStatus = values.status;
  const runDisabled =
    [
      JobStatus.JOB_RUNNING,
      JobStatus.JOB_QUEUED,
      JobStatus.JOB_CREATED,
    ].includes(jobStatus?.status) || !values.enabled;

  const disabledTooltip = !values.enabled
    ? "You can not run a job when it is in a disabled state."
    : "Running is currently in progress. Please wait for it to finish before initiating it again.";

  const formatDate = (date: string, nextRun: boolean) => {
    if (!values.enabled && nextRun) {
      return "N/A";
    }

    return date && date !== "0001-01-01T00:00:00Z"
      ? `${formatUTCDateTime(date)} UTC`
      : "---";
  };

  const StopJobModal = () => {
    return (
      <CDataModalV2
        close={() => setShowStopJobModal(false)}
        displayed={showStopJobModal}
        displayToggleCloseButton={true}
        title="Stop Run"
        primaryButton={
          <Button
            color="danger"
            form="newLicenseForm"
            onClick={() => manuallyStopJob()}
          >
            <div className="icon no-pointer-event" />
            Stop Run
          </Button>
        }
        secondaryButton={
          <Button color="secondary" onClick={() => setShowStopJobModal(false)}>
            Cancel
          </Button>
        }
      >
        You are about to stop the run for this job. Are you sure you want to
        proceed?
      </CDataModalV2>
    );
  };

  function stopCachedJobOrScheduledQuery() {
    if (isCacheJob) {
      return stopCacheJob({ jobId: values.id });
    } else {
      return stopScheduledQuery({ queryId: values.id });
    }
  }

  const { mutate: manuallyStopJob } = useMutation({
    mutationKey: [`/cachejob/${values.id}/stop`],
    mutationFn: stopCachedJobOrScheduledQuery,
    onSuccess: () => {
      setValues({
        ...values,
        status: {
          ...values.status,
          status: JobStatus.JOB_CANCELED,
        },
      });
    },
    onError: (error) => {
      ToastrError(
        isCacheJob
          ? "Failed to stop Cache job."
          : "Failed to stop scheduled query",
        error.message,
      );
    },
    onSettled: () => setShowStopJobModal(false),
  });

  async function manuallyRunJob() {
    if (isCacheJob) {
      const runJobSucceeded = await runCacheJobAsync(values.id);
      if (runJobSucceeded) {
        setValues({
          ...values,
          status: {
            ...values.status,
            status: JobStatus.JOB_QUEUED,
          },
        });
      }
    } else {
      const runJobSucceeded = await runScheduledQueryAsync(values.id);
      if (runJobSucceeded) {
        setValues({
          ...values,
          status: {
            ...values.status,
            status: JobStatus.JOB_QUEUED,
          },
        });
      }
    }
  }

  const CardHeader = (
    <h5
      className="card-title mb-3 runHistoryCardHeader"
      data-testid="header-run-history"
    >
      <span>Run History</span>
      <span>
        <CDataButton
          buttonType={ButtonType.InfoOutline}
          data-testid="button-stop-job"
          onClick={() => setShowStopJobModal(true)}
          style={{
            visibility:
              jobStatus?.status === JobStatus.JOB_RUNNING
                ? "visible"
                : "hidden",
          }}
        >
          <i className="fa fa-solid fa-circle-stop" /> Stop Run
        </CDataButton>
      </span>
    </h5>
  );

  const LastRunStatusRow = (
    <>
      <Row className="mb-2">
        <Col data-testid="col-last-run-title" className="fw-bold">
          Last Run
        </Col>
        <Col data-testid="col-status-title" className="fw-bold">
          Status
        </Col>
      </Row>

      <Row className="mb-3">
        <Col data-testid="col-last-run">
          {formatDate(jobStatus?.lastRun ?? "", false)}
        </Col>
        <Col data-testid="col-status" className="d-flex align-items-center">
          <JobStatusBadge status={values?.status?.status} isAddJob={isAddJob} />
        </Col>
      </Row>
    </>
  );

  const DurationNumberOfRecordsRow = (
    <>
      <Row className="mb-2">
        <Col data-testid="col-last-run-duration-title" className="fw-bold">
          Last Run Duration
        </Col>
        <Col data-testid="col-rows-affected-title" className="fw-bold">
          Number of Records
          <InfoIcon
            iconId="icon-total-records"
            tooltipMessage={
              isCacheJob
                ? "Represents the total number of records in the cache."
                : "Represents the total number of records in the query."
            }
            className="ms-2"
          />
        </Col>
      </Row>

      <Row className="pb-3 border-bottom-line">
        <Col data-testid="col-last-run-duration">
          {jobStatus?.lastRunDuration
            ? formatUTCDateTime(jobStatus?.lastRunDuration * 1000, "HH:mm:ss")
            : "---"}
        </Col>
        <Col data-testid="col-rows-affected">
          {jobStatus?.rowsAffected !== undefined
            ? jobStatus?.rowsAffected
            : "---"}
        </Col>
      </Row>
    </>
  );

  const getNextRunDate = () => {
    // Caching jobs don't have DefinedNextRun; just return the nextRun date in the status.
    if (isCacheJob) {
      return formatDate(jobStatus?.nextRun ?? "", true);
    }

    // If a scheduled query has a definedNextRun that's in the future, return it with special formatting to bypass time zone.
    // Else, return jobStatus.nextRun.
    if (values.definedNextRun && new Date(values.definedNextRun) > new Date()) {
      return `${format(new Date(values.definedNextRun), "yyyy-MM-dd HH:mm:ss", {
        locale: enUS,
      })} UTC`;
    } else {
      return formatDate(jobStatus?.nextRun ?? "", true);
    }
  };

  const NextRunRunNowRow = (
    <>
      <Row className="mb-2 pt-3">
        <Col className="flex-column">
          <Row className="mb-2 align-self-start">
            <Col data-testid="col-next-run-title" className="fw-bold">
              Next Run
            </Col>
          </Row>
          <Row className="align-self-start">
            <Col data-testid="col-next-run">{getNextRunDate()}</Col>
          </Row>
        </Col>

        <Col className="justify-content-end">
          <span id="button-run-job">
            <Button
              data-testid="button-run-job"
              disabled={runDisabled}
              onClick={manuallyRunJob}
            >
              <i className="fa fa-solid fa-sm fa-play me-2" />
              Run Now
            </Button>
          </span>
          {runDisabled && !isAddJob ? (
            <UncontrolledTooltip
              placement="left"
              target={"button-run-job"}
              trigger="hover"
            >
              {disabledTooltip}
            </UncontrolledTooltip>
          ) : null}
        </Col>
      </Row>
    </>
  );

  return (
    <Card data-testid="card-run-history" className="w-100 run-history-card">
      <CardBody>
        {CardHeader}
        {LastRunStatusRow}
        {DurationNumberOfRecordsRow}
        {NextRunRunNowRow}
        <StopJobModal />
      </CardBody>
    </Card>
  );
};
