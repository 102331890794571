import React, { useState, useEffect } from "react";
import { UsersTable } from "./UsersTable";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import { RequestType } from "../../components/withAPI";
import { CallAPIFunction, useAPI } from "../../components/useAPI";
import { useFeatureFlags } from "../../hooks/useFeatureFlags";
import Loader from "../../components/Loader";

export const Impersonation = () => {
  const [selection, setSelection] = useState({});
  const [users, setUsers] = useState([]);
  const api = useAPI();
  const flags = useFeatureFlags().getFlags(["impersonation"]);
  const [loading, setLoading] = useState(true);

  async function getImpersonationUsers(callAPI: CallAPIFunction) {
    const { payload, status } = await callAPI(
      RequestType.Get,
      "/users/impersonationConsentGiven",
      "Failed to get impersonation users.",
    );

    setLoading(false);

    if (status === 200) {
      return payload;
    } else {
      return null;
    }
  }

  useEffect(() => {
    (async () => {
      const data = await getImpersonationUsers(api.callAPI);
      if (data) {
        setUsers(data);
      }
    })();
  }, []); // eslint-disable-line

  if (loading) {
    return <Loader />;
  }

  return flags.impersonation.enabled ? (
    <Container fluid className={"p-0 pages-caching"}>
      <Row className="mb-4">
        <Col className="h3 mb-0 d-flex align-items-center">
          Support Impersonation
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <Row className="mb-3">
                <Col className="d-flex align-content-center">
                  <CardTitle className="mb-0 d-flex align-items-center">
                    Support Impersonation Users
                  </CardTitle>
                </Col>
              </Row>
              <Row>
                <Col>
                  <UsersTable
                    selection={selection}
                    setSelection={setSelection}
                    users={users}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  ) : null;
};

export default Impersonation;
