export function generateDefaultNumberedName<T extends { name?: string }>(
  niceName: string,
  existingObjects: T[] = [],
): string {
  const modifiedNiceName = niceName.replace(/\W/g, "");

  const matchingNames = new Set(
    existingObjects
      .filter((obj) => obj.name?.includes(modifiedNiceName))
      .map((obj) => obj.name),
  );

  let numberOfOccurrences = 1;
  let defaultName;

  do {
    defaultName = modifiedNiceName + numberOfOccurrences.toString();
    numberOfOccurrences++;
  } while (matchingNames.has(defaultName));

  return defaultName;
}
