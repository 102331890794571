import { useEffect, useState } from "react";
import { Card, CardHeader, CardTitle, Container } from "reactstrap";
import Loader from "../../components/Loader";
import { IUserStats, IUserStatsReport, UserRole } from "../../models";
import { ColumnDef } from "@tanstack/react-table";
import ListTable from "../../components/tables/ListTable";
import classNames from "classnames";
import { parseUTCDateTime } from "../../utility/ParseUTCDateTime";
import { useNavigate } from "react-router-dom";
import { useAPI } from "../../components/useAPI";
import { getActiveUserStatistics } from "./components/OverviewApiCalls";
import { useAppSelector } from "../../redux/hooks";
import { CDataTypography } from "src/components/text/CDataTypography";

interface IQueriesByUserProps {
  period: number;
}

export const QueriesByUser = (props: IQueriesByUserProps) => {
  const api = useAPI();
  const user = useAppSelector((state) => state.user);
  const navigate = useNavigate();

  const [activeUserStatistics, setActiveUserStatistics] =
    useState<IUserStatsReport | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const statistics = await getActiveUserStatistics(
        api.callAPI,
        props.period,
      );
      setActiveUserStatistics(statistics);
      setIsLoading(false);
    };

    fetchData();
  }, [props.period]); // eslint-disable-line

  if (isLoading) {
    return <Loader />;
  }

  if (!activeUserStatistics) {
    return null;
  }

  const navigateToLogs = (row: IUserStats) => {
    navigate("/logs", {
      state: {
        state: {
          timePeriod: activeUserStatistics.period,
          user: row.user,
        },
      },
    });
  };

  const columns: ColumnDef<IUserStats>[] = [
    {
      accessorKey: "user",
      id: "name",
      enableSorting: true,
      header: () => <span>User</span>,
      meta: {
        className: "user-column",
      },
      cell: ({ row }) => {
        return <>{row.original.user}</>;
      },
    },
    {
      accessorKey: "totalQueries",
      id: "totalQueries",
      enableSorting: true,
      header: () => <span>Queries</span>,
      meta: {
        className: "queries-column",
      },
      cell: ({ row }) => {
        return <>{row.original.totalQueries}</>;
      },
    },
    {
      accessorKey: "rows",
      id: "rows",
      enableSorting: true,
      header: () => <span>Rows</span>,
      meta: {
        className: "rows-column",
      },
      cell: ({ row }) => {
        return <>{row.original.rows}</>;
      },
    },
    {
      accessorKey: "failedQueries",
      id: "failedQueries",
      enableSorting: true,
      header: () => <span>Errors</span>,
      meta: {
        className: "errors-column",
      },
      cell: ({ row }) => {
        return <>{row.original.failedQueries}</>;
      },
    },
    {
      accessorKey: "lastQueryTime",
      id: "lastQueryTime",
      enableSorting: true,
      sortingFn: (rowA, rowB) => {
        const lastQueryA = rowA.original.lastQueryTime;
        const lastQueryB = rowB.original.lastQueryTime;

        return lastQueryA.localeCompare(lastQueryB);
      },
      header: () => <span>Last Query</span>,
      meta: {
        className: "last-query-time-column",
      },
      cell: ({ row }) => {
        const lastQueryTime = parseUTCDateTime(row.original.lastQueryTime);
        return (
          <>{`${lastQueryTime.hyphenatedDate} ${lastQueryTime.compiledTime} UTC`}</>
        );
      },
    },
  ];

  const isTableDisabled = activeUserStatistics.datasets.length === 0;

  const isOEM = user?.role === UserRole.ServiceUser;

  return (
    <Container fluid className="queries-by-user">
      <Card
        className="flex-fill w-100"
        onClick={() => {
          window.DD_RUM?.addAction("Queries by user card clicked");
        }}
      >
        <CardHeader>
          <CardTitle tag="h5" className="mb-0 card-title-queries">
            Queries by User
          </CardTitle>
        </CardHeader>
        {isTableDisabled ? (
          <div className="table-disabled-message">
            <i className="fa fa-chart-line-up table-disabled-icon fa-7x mb-2" />
            <CDataTypography>
              No data available for the selected time period
            </CDataTypography>
          </div>
        ) : null}
        <ListTable
          columns={columns}
          data={activeUserStatistics!.datasets!}
          className={classNames("queries-by-user-table", {
            "table-disabled": isTableDisabled,
          })}
          defaultSort={[{ id: "totalQueries", desc: true }]}
          emptyTableMessage={""}
          enableFiltering={false}
          enablePagination={false}
          enableCheckboxes={false}
          onRowClick={isOEM ? undefined : (row) => navigateToLogs(row)}
        />
      </Card>
    </Container>
  );
};
