//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum EntityType {
  Account = 0,
  Connection = 1,
  User = 2,
  Query = 3,
  PersonalAccessToken = 4,
  Invite = 5,
  DerivedView = 6,
  CustomReport = 7,
  Workspace = 8,
  DataAsset = 9,
  FolderAsset = 10,
  CacheConnection = 11,
  UserImpersonationConsent = 12
}
