import React from "react";
import { Route, Routes } from "react-router-dom";

import Page404 from "../auth/Page404";
import AdminGuard from "../../components/guards/AdminGuard";
import Users from "./Users";
import EditUser from "./EditUser";
import InviteUsers from "./InviteUsers";

const UserRoutes = () => {
  return (
    <Routes>
      {/* /user route */}
      <Route
        path="/"
        element={
          <AdminGuard>
            <Users />
          </AdminGuard>
        }
      />

      <Route
        path="edit"
        element={
          <AdminGuard>
            <EditUser />
          </AdminGuard>
        }
      />

      <Route
        path="invite"
        element={
          <AdminGuard>
            <InviteUsers />
          </AdminGuard>
        }
      />

      <Route path="*" element={<Page404 showBranding={false} />} />
    </Routes>
  );
};

export default UserRoutes;
