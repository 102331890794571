import { cdataDownloadFetch } from "../../../api/cdataFetch";

interface IDownloadCsv {
  isWorkspace: boolean;
  workspaceName?: string;
  body: {
    query: string;
    defaultCatalog: string;
  };
}

export async function downloadCsv(params: IDownloadCsv) {
  const { isWorkspace, workspaceName, body } = params;

  const endpoint = isWorkspace
    ? `/downloadCSV?workspace=${workspaceName}`
    : "/downloadCSV";

  return cdataDownloadFetch({
    url: endpoint,
    method: "POST",
    body: JSON.stringify(body),
    fileName: "export",
    extension: ".csv",
  });
}
