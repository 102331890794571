import { useContext, useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Button, Spinner, Container, Row } from "reactstrap";
import { Form } from "react-bootstrap";

import Loader from "../../components/Loader";
import PostSignup from "../../components/PostSignup";
import { IModalFunctions } from "../../layouts/Dashboard";
import { tosLastUpdated } from "../../utility/TermsOfServiceLastUpdated";
import {
  withRouter,
  ComponentWithRouterProps,
} from "../../components/withRouter";
import {
  ICountry,
  IPartnerMetric,
  IPartnerUserUpdateRequest,
} from "../../models";
import { ModalContext } from "../../routes/ModalContext";
import { countryList } from "../../utility/Countries";
import { prependApiPrefix } from "../../api/prependApiPrefix";

type IUserSetPasswordProps = ComponentWithRouterProps & IModalFunctions;

function PartnerUserSetPassword(props: IUserSetPasswordProps) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState(false);
  const [partnerMetricId, setPartnerMetricId] = useState<string>();
  const modalContext = useContext(ModalContext);

  useEffect(() => {
    setIsLoading(true);
    const queryParams = new URLSearchParams(window.location.search);
    setPartnerMetricId(queryParams.get("id") ?? "");

    async function fetchData() {
      const requestOptions = {
        method: "GET",
        headers: {
          "content-type": "application/json",
        },
      } as RequestInit;

      const response = await fetch(
        prependApiPrefix(`/partner/metric/${queryParams.get("id")}`),
        requestOptions,
      );
      if (response.status === 200) {
        const data: IPartnerMetric = await response.json();
        // check if the record is already activated & redirect
        if (data.activated) {
          props.router.navigate("/connections");
        }
      } else {
        const err = await response.json();
        modalContext.showError(
          "Failed to get user information due to the following error:",
          err.error,
        );
        setIsLoading(true);
      }
    }
    fetchData();
    setIsLoading(false);
  }, []); // eslint-disable-line

  async function handleValidSubmit(values: any) {
    setIsSaving(true);

    const partnerUserUpdateRequest: IPartnerUserUpdateRequest = {
      country: values.Country,
      partnerMetricId: partnerMetricId!,
      password: values.Password,
      tosVersion: tosLastUpdated,
    };

    const requestOptions = {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(partnerUserUpdateRequest),
    } as RequestInit;

    const response = await fetch(
      prependApiPrefix("/partner/userUpdate"),
      requestOptions,
    );
    if (response.status === 200) {
      props.router.navigate("/connections");
      return;
    } else {
      const err = await response.json();
      modalContext.showError(
        "Failed to set user information due to the following error:",
        err.error,
      );
    }

    setIsSaving(false);
  }

  function validatePassword(password: string | undefined) {
    if (!password || password.length < 8) {
      return false;
    }
    let numRulesSatisfied = 0;
    if (/[a-z]/.test(password)) {
      numRulesSatisfied++;
    }
    if (/[A-Z]/.test(password)) {
      numRulesSatisfied++;
    }
    if (/\d/.test(password)) {
      numRulesSatisfied++;
    }
    if (/[^A-Za-z0-9]/.test(password)) {
      numRulesSatisfied++;
    }
    return numRulesSatisfied >= 3;
  }

  const validationSchema = Yup.object().shape({
    Country: Yup.string()
      .required("Please select a country")
      .notOneOf(["Select"], "Please select a country"),
    Password: Yup.string()
      .required("Please enter your password")
      .test(
        "password-validation",
        "Password must have a minimum of 8 characters and contain at least 3 of the following: lowercase letters, uppercase letters, numbers, and special characters",
        validatePassword,
      ),
    PasswordConfirm: Yup.string()
      .required("Please confirm your password")
      .oneOf([Yup.ref("Password"), null], "Passwords must match"),
    TosAccepted: Yup.boolean().oneOf([true]),
  });

  const countryOptions = countryList.map((country: ICountry) => (
    <option key={country.threeLetterCode} value={country.threeLetterCode}>
      {country.name}
    </option>
  ));
  countryOptions.unshift(
    <option key={"Select"} value={"Select"}>
      Select
    </option>,
  );

  function renderUserSetData() {
    const initialFormValues = {
      Country: "",
      Password: "",
      PasswordConfirm: "",
      TosAccepted: false,
    };

    return (
      <div className="auth-user-UserSetPassword">
        <PostSignup />
        <span hidden={isSaving}>
          <div className="m-sm-4">
            <h3>Welcome</h3>
            <p className="font-weight-light text-muted">
              Before you get started, please set your password & accept the
              terms of service. After, you will be redirected to login.
            </p>
          </div>
          <div className="m-sm-4">
            <Formik
              initialValues={initialFormValues}
              validationSchema={validationSchema}
              onSubmit={handleValidSubmit}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Label className="required">Password</Form.Label>
                    <Form.Control
                      size="lg"
                      name="Password"
                      placeholder="Enter a password for login"
                      value={values.Password}
                      isInvalid={Boolean(touched.Password && errors.Password)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                    />
                    {!!touched.Password && (
                      <Form.Control.Feedback type="invalid">
                        {errors.Password?.toString()}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="required">
                      Confirm Password
                    </Form.Label>
                    <Form.Control
                      size="lg"
                      name="PasswordConfirm"
                      placeholder="Confirm your password for login"
                      value={values.PasswordConfirm}
                      isInvalid={Boolean(
                        touched.PasswordConfirm && errors.PasswordConfirm,
                      )}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                    />
                    {!!touched.PasswordConfirm && (
                      <Form.Control.Feedback type="invalid">
                        {errors.PasswordConfirm?.toString()}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="required">Country</Form.Label>
                    <Form.Control
                      size="lg"
                      name="Country"
                      as="select"
                      value={values.Country}
                      isInvalid={Boolean(touched.Country && errors.Country)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      className="form-select ps-3"
                    >
                      {countryOptions}
                    </Form.Control>
                    {!!touched.Country && (
                      <Form.Control.Feedback type="invalid">
                        {errors.Country?.toString()}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Check
                      name="TosAccepted"
                      value={tosLastUpdated}
                      label={
                        <>
                          I agree to the{" "}
                          <a
                            href="https://cloud.cdata.com/docs/terms-of-service.html"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-primary"
                          >
                            Terms and Conditions
                          </a>
                        </>
                      }
                      onBlur={handleBlur}
                      className={"checked-background"}
                      onChange={handleChange}
                    />
                    {touched.TosAccepted && errors.TosAccepted && (
                      <div>
                        <small className="text-danger">
                          You must agree to the terms and conditions
                        </small>
                      </div>
                    )}
                  </Form.Group>
                  <Form.Group className="text-center mt-4">
                    <Button
                      type="submit"
                      color="primary"
                      className="full-width"
                      disabled={isSaving}
                    >
                      Complete Sign Up
                    </Button>
                  </Form.Group>
                </Form>
              )}
            </Formik>
          </div>
        </span>
        <span hidden={!isSaving}>
          <div className="text-center mt-4">
            <Container fluid className="vh-50 d-flex">
              <Row className="justify-content-center align-self-center w-100 text-center">
                <Spinner color="info" />
              </Row>
            </Container>
          </div>
        </span>
      </div>
    );
  }

  const contents = isLoading ? (
    <div>
      <Loader />
    </div>
  ) : (
    renderUserSetData()
  );

  return <>{contents}</>;
}

export default withRouter(PartnerUserSetPassword);
