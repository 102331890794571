import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

import Loader from "../../components/Loader";
import { IRowsOverTimeReport, IQueriesOverTimeReport } from "../../models";
import { useAPI } from "../../components/useAPI";
import {
  getQueryLogsOverTime,
  getRowLogsOverTime,
} from "./components/OverviewApiCalls";
import { CDataTypography } from "src/components/text/CDataTypography";

interface IOverTimeChartProps {
  period: number;
}

export const OverTimeChart = (props: IOverTimeChartProps) => {
  const api = useAPI();

  const [queriesOverTimeStatistics, setQueriesOverTimeStatistics] =
    useState<IQueriesOverTimeReport | null>(null);
  const [rowsOverTimeStatistics, setRowsOverTimeStatistics] =
    useState<IRowsOverTimeReport | null>(null);
  const [showQueriesOverTimeChart, setShowQueriesOverTimeChart] =
    useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await Promise.all([
        getQueryLogsOverTime(api.callAPI, props.period).then((r) =>
          setQueriesOverTimeStatistics(r),
        ),
        getRowLogsOverTime(api.callAPI, props.period).then((r) =>
          setRowsOverTimeStatistics(r),
        ),
      ]);

      setIsLoading(false);
    };

    fetchData();
  }, [props.period]); // eslint-disable-line

  if (isLoading) {
    return <Loader />;
  }

  if (!queriesOverTimeStatistics || !rowsOverTimeStatistics) {
    return null;
  }

  const queryData = [
    {
      name: "Failed",
      data: queriesOverTimeStatistics.series.failed,
    },
    {
      name: "Successful",
      data: queriesOverTimeStatistics.series.successful,
    },
  ];

  const rowData = [
    {
      name: "RowsReturned",
      data: rowsOverTimeStatistics.rowsReturned,
    },
  ];

  const queryBarColors = ["#e51c23", "#3B82EC"];
  const rowBarColor = ["#3B82EC"];

  const options: ApexOptions = {
    chart: {
      animations: {
        enabled: true,
        easing: "easeinout",
        dynamicAnimation: {
          enabled: false,
          speed: 350,
        },
      },
      stacked: true,
      zoom: {
        enabled: false,
      },
      toolbar: {
        offsetY: -25,
        tools: {
          //direct SVG used as we can't do faIcon imports for a non-react component here
          download: `<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.6013 11H11.4138L10.0076 12.4375C9.6326 12.8125 9.1326 13 8.60135 13C8.03885 13 7.53885 12.8125 7.16385 12.4375L5.7576 11H1.60135C1.03885 11 0.601349 11.4688 0.601349 12V15C0.601349 15.5625 1.03885 16 1.60135 16H15.6013C16.1326 16 16.6013 15.5625 16.6013 15V12C16.6013 11.4688 16.1326 11 15.6013 11ZM14.1013 14.25C13.6638 14.25 13.3513 13.9375 13.3513 13.5C13.3513 13.0938 13.6638 12.75 14.1013 12.75C14.5076 12.75 14.8513 13.0938 14.8513 13.5C14.8513 13.9375 14.5076 14.25 14.1013 14.25ZM7.8826 11.7188C8.0701 11.9062 8.3201 12 8.60135 12C8.85135 12 9.10135 11.9062 9.28885 11.7188L13.2888 7.71875C13.6951 7.34375 13.6951 6.6875 13.2888 6.3125C12.9138 5.90625 12.2576 5.90625 11.8826 6.3125L9.60135 8.59375V1C9.60135 0.46875 9.1326 0 8.60135 0C8.03885 0 7.60135 0.46875 7.60135 1V8.59375L5.28885 6.3125C4.91385 5.90625 4.2576 5.90625 3.8826 6.3125C3.47635 6.6875 3.47635 7.34375 3.8826 7.71875L7.8826 11.7188Z" fill="#677986"/>
                      </svg>`,
        },
      },
    },
    legend: {
      show: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: rowsOverTimeStatistics.categories,
      type: "datetime",
      crosshairs: {
        show: false,
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    colors: showQueriesOverTimeChart ? queryBarColors : rowBarColor,
  };

  const isTableDisabled = showQueriesOverTimeChart
    ? queriesOverTimeStatistics.series.total.every((item: number) => item === 0)
    : rowsOverTimeStatistics.rowsReturned.every((item: number) => item === 0);

  return (
    <Container fluid className="pe-0 pages-status-OverTimeChart">
      <Card
        id="overTimeCard"
        className="over-time-card flex-fill w-100"
        onClick={() => {
          window.DD_RUM?.addAction("OverTime card clicked");
        }}
      >
        <CardHeader>
          <Row>
            <Col>
              <CardTitle tag="h5" className="mb-0 over-time-row">
                <ListGroup
                  horizontal
                  className="connected-button-group-list mt-1"
                >
                  <div className="over-time-title">Over Time</div>
                  <ListGroupItem
                    action
                    type="button"
                    size="sm"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowQueriesOverTimeChart(!showQueriesOverTimeChart);
                      window.DD_RUM?.addAction(
                        "OverTime card toggle tab clicked",
                        {
                          activeTab: "Queries",
                        },
                      );
                    }}
                    active={showQueriesOverTimeChart}
                    className="text-center connected-button queries-button"
                    data-testid="button-queries"
                  >
                    Queries
                  </ListGroupItem>
                  <ListGroupItem
                    action
                    type="button"
                    size="sm"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowQueriesOverTimeChart(!showQueriesOverTimeChart);
                      window.DD_RUM?.addAction(
                        "OverTime card toggle tab clicked",
                        {
                          activeTab: "Rows",
                        },
                      );
                    }}
                    active={!showQueriesOverTimeChart}
                    className="text-center connected-button"
                    data-testid="button-rows"
                  >
                    Rows
                  </ListGroupItem>
                </ListGroup>
              </CardTitle>
            </Col>
          </Row>
        </CardHeader>
        <CardBody className="d-flex mt-n4 chart-card">
          <div className="align-self-center w-100">
            {isTableDisabled ? (
              <div className="table-disabled-message">
                <i className="fa fa-chart-column table-disabled-icon fa-7x mb-2" />
                <CDataTypography>
                  No data available for the selected time period.
                </CDataTypography>
              </div>
            ) : null}
            <div
              className={
                isTableDisabled
                  ? "chart chart-lg table-disabled"
                  : "chart chart-lg"
              }
            >
              <Chart
                series={showQueriesOverTimeChart ? queryData : rowData}
                options={options}
                type="bar"
                height="350"
              />
            </div>
            <Col className="text-center me-2">
              <div className="table-note">All times UTC</div>
            </Col>
          </div>
        </CardBody>
      </Card>
    </Container>
  );
};
