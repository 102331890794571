import { CDataModalV2 } from "../../../../components/modal/CDataModalV2";
import {
  ButtonType,
  CDataButton,
} from "../../../../components/buttons/CDataButton";
import { useAPI } from "../../../../components/useAPI";
import { RequestType } from "../../../../components/withAPI";
import { ToastrSuccess } from "../../../../services/toastrService";
import { useState } from "react";
import { Spinner } from "reactstrap";
import useQueryTabs from "../Tabs/useQueryTabs";

export type UpdateSavedQueryModalProps = {
  displayed: boolean;
  close: () => void;
  fetchSavedQueries: () => void;
};

export function UpdateSavedQueryModal(props: UpdateSavedQueryModalProps) {
  const api = useAPI();
  const tabs = useQueryTabs();

  const { displayed, close, fetchSavedQueries } = props;

  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const currentTab = tabs.List.find((t) => t.id === tabs.CurrentTabId);

  if (!currentTab) {
    return null;
  }

  const updateSavedQuery = async () => {
    setIsProcessing(true);

    const trimmedQueryInput = currentTab.queryString.trim();
    const values = {
      Query: trimmedQueryInput,
    };

    const { status } = await api.callAPI(
      RequestType.Put,
      `/users/self/savedQueries/${encodeURIComponent(currentTab.uniqueId ?? "")}`,
      "Failed to update saved query due to the following error:",
      values,
    );

    if (status === 200) {
      await fetchSavedQueries();
      ToastrSuccess(
        "Saved Query Successfully Saved!",
        `${currentTab.tabName} was successfully saved.`,
      );
      close();
      tabs.SetUnsavedChanges(tabs.CurrentTabId, false);
    }

    setIsProcessing(false);
  };

  return (
    <>
      <CDataModalV2
        modalSize="lg"
        displayed={displayed}
        close={close}
        title="Save Changes"
        spacedFooter={false}
        primaryButton={
          <CDataButton
            buttonType={ButtonType.Primary}
            onClick={updateSavedQuery}
          >
            Save
          </CDataButton>
        }
        secondaryButton={
          <CDataButton
            buttonType={ButtonType.Secondary}
            onClick={close}
            data-testid="button-cancel"
          >
            Cancel
          </CDataButton>
        }
      >
        You are about to overwrite {currentTab.tabName} with new changes. Are
        you sure you want to proceed?
      </CDataModalV2>
      <div hidden={!isProcessing}>
        <div className="loading-background" />
        <Spinner className="spinner-border loading-spinner" color="info" />
      </div>
    </>
  );
}
