import React from "react";
import { Link } from "react-router-dom";
import ExpiredIllustration from "../../assets/img/ExpiredIllustration.svg?react";

import { Button } from "reactstrap";

const PageExpired = () => (
  <div className="text-center">
    <ExpiredIllustration className="mb-4" />
    <p className="h1">Page Expired</p>
    <p className="font-weight-normal mt-3 mb-4">
      Looks like you’ve already finished setting up your CData Connect Cloud
      account. To return to where you left off, click the button below.
    </p>
    <Link
      to={{
        pathname: "/",
      }}
    >
      <Button color="primary" size="lg" data-testid="button-return">
        Return to Connect Cloud
        <i className="fa fa-arrow-right ms-2" />
      </Button>
    </Link>
  </div>
);

export default PageExpired;
