import { useRef, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";
import { RequestType } from "../../../../components/withAPI";
import {
  showSidebar,
  hideSidebar,
} from "../../../../redux/actions/sidebarActions";
import { toggleFlyout } from "../../../../redux/actions/flyoutActions";
import {
  ApiConnectionPageType,
  IGlobalSettings,
  KeyValueTableRow,
} from "../../ApiConnector";
import { ToastrError, ToastrSuccess } from "../../../../services/toastrService";
import {
  connectionSubmissionData,
  mapConnectionSubmissionData,
} from "../../ApiConnectorFactory";
import { IFullPermission } from "../../../connections/components/PermissionsCard";
import { APIAuthType, IAPITable } from "../../../../models";
import { createDeepCopy } from "../../../../utility/CreateDeepCopy";
import PromptWithModal from "../../../../components/PromptWithModal";
import { GlobalHeadersCard } from "./components/GlobalHeadersCard";
import AuthenticationCard from "./components/AuthenticationCard";
import PaginationCard from "./components/PaginationCard";
import AdvancedCard from "./components/AdvancedCard";
import TertiaryButton from "../../../../components/buttons/TertiaryButton";
import { FormikProps } from "formik";
import { useAPI } from "../../../../components/useAPI";
import { useNavigate } from "react-router-dom";
import { IAPIConnectorSettings } from "../../SetTable/types/IAPIConnectorSettings";
import { OAuthParamsCard } from "./components/OAuthParamsCard";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";

interface IGlobalSettingsFlyoutProps {
  apiSettings: IAPIConnectorSettings;
  apiConnectionPageType: ApiConnectionPageType;
  tables: IAPITable[];
  permissions: IFullPermission[];
}

const GlobalSettingsFlyout = (props: IGlobalSettingsFlyoutProps) => {
  const {
    apiSettings: { globalSettings, setGlobalSettings, setUnsavedChanges },
  } = props;

  const [processingRequest, setProcessingRequest] = useState<boolean>(false);
  const [flyoutGlobalSettings, setFlyoutGlobalSettings] =
    useState<IGlobalSettings>(createDeepCopy(globalSettings));
  const [unsavedFlyoutChanges, setUnsavedFlyoutChanges] =
    useState<boolean>(false);

  const navigate = useNavigate();
  const sidebar = useAppSelector((store) => store.sidebar);

  const dispatch = useAppDispatch();

  const authRef = useRef<any>();
  const paginationRef = useRef<any>();
  const headersFormRef = useRef<FormikProps<any>>(null as any);
  const oAuthParamsRef = useRef<FormikProps<KeyValueTableRow[]>>(null as any);
  const api = useAPI();

  function getComponentName() {
    return "pages-apiConnector-GlobalSettingsFlyout";
  }

  async function saveChanges() {
    authRef.current?.submitForm();
    paginationRef.current?.submitForm();
    headersFormRef.current?.submitForm();
    oAuthParamsRef.current?.submitForm();

    const data: connectionSubmissionData = mapConnectionSubmissionData(
      flyoutGlobalSettings,
      props.tables,
      props.permissions,
    );

    setProcessingRequest(true);
    const { status, error } = await api.callAPI(
      RequestType.Put,
      "/account/updateConnection",
      "",
      data,
    );
    if (status === 200) {
      setGlobalSettings(flyoutGlobalSettings);
      ToastrSuccess(
        "Connection Saved Successfully!",
        `Your API connection, ${globalSettings.connectionName}, has been updated successfully.`,
      );
      setUnsavedChanges(false);
    } else {
      ToastrError("Error Saving Global Settings", error.message);
    }
    setProcessingRequest(false);
  }

  function renderGlobalSettingsFlyout() {
    return (
      <Card>
        <CardBody className="flyout-height">
          <Row className="title-row">
            <Col className="title">Global Settings</Col>
            <Col className="close-btn-container">
              <TertiaryButton
                onClick={() => {
                  [
                    dispatch(toggleFlyout()),
                    sidebar.keepExpanded
                      ? dispatch(showSidebar())
                      : dispatch(hideSidebar()),
                  ];
                }}
              >
                {" "}
                <span className="x-btn">X</span>Close
              </TertiaryButton>
            </Col>
          </Row>
          <Row className="divider"></Row>
          <Row className="warning-row">
            <Col>
              <div className="change-warning">
                <i className="fa fa-exclamation-triangle fa-lg info-icon"></i>
                Changes made to the settings below will apply to the global
                settings for all tables that have been added to the API
                Connector.
              </div>
            </Col>
          </Row>
          <div className="flyout-content">
            <Row>
              <Col>
                <AuthenticationCard
                  globalSettings={flyoutGlobalSettings}
                  setGlobalSettings={setFlyoutGlobalSettings}
                  setUnsavedChanges={setUnsavedFlyoutChanges}
                  isFlyout={true}
                  apiConnectionPageType={props.apiConnectionPageType}
                  ref={authRef}
                />
              </Col>
            </Row>
            {(flyoutGlobalSettings.authentication.type === APIAuthType.OAuth2 ||
              flyoutGlobalSettings.authentication.type ===
                APIAuthType.OAuthClient) && (
              <Row className="my-3">
                <Col>
                  <OAuthParamsCard
                    globalSettings={flyoutGlobalSettings}
                    setGlobalSettings={setFlyoutGlobalSettings}
                    setUnsavedChanges={setUnsavedFlyoutChanges}
                    formRef={oAuthParamsRef}
                  />
                </Col>
              </Row>
            )}
            <Row className="my-3">
              <Col>
                <GlobalHeadersCard
                  globalSettings={flyoutGlobalSettings}
                  setGlobalSettings={setFlyoutGlobalSettings}
                  setUnsavedChanges={setUnsavedFlyoutChanges}
                  disableDrag
                  formRef={headersFormRef}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <PaginationCard
                  globalSettings={flyoutGlobalSettings}
                  setGlobalSettings={setFlyoutGlobalSettings}
                  setUnsavedChanges={setUnsavedFlyoutChanges}
                  isFlyout={true}
                  ref={paginationRef}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <AdvancedCard
                  globalSettings={flyoutGlobalSettings}
                  setGlobalSettings={setFlyoutGlobalSettings}
                  setUnsavedChanges={setUnsavedFlyoutChanges}
                  isFlyout={true}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  data-testid="button-save-changes"
                  color="primary"
                  className="float-start save-btn m-0"
                  onClick={() => saveChanges()}
                >
                  <i className="fa fa-save fa-sm align-middle add-connection-icon no-pointer-event" />
                  Save Changes
                </Button>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
    );
  }

  return (
    <Container fluid className={`p-0 ${getComponentName()}`}>
      <div hidden={!processingRequest}>
        <div className="loading-background" />
        <Spinner className="spinner-border loading-spinner" color="info" />
      </div>
      {renderGlobalSettingsFlyout()}
      <PromptWithModal
        when={unsavedFlyoutChanges}
        customMessage={
          "If you leave this page, your unsaved Global Settings changes will be lost. Are you sure you want to leave?"
        }
        navigate={(path) => navigate(path)}
      />
    </Container>
  );
};

export default GlobalSettingsFlyout;
