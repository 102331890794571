import { useEffect, useState } from "react";
import { ITableSettings, QueryInput } from "../SetTable";
import PreviewTable from "./PreviewTable";
import Loader from "../../../../components/Loader";
import { getPreviewRows } from "./ParsingUtilities/getPreviewRows";
import { IAPIConnectorSettings } from "../types/IAPIConnectorSettings";
import { useAPI } from "../../../../components/useAPI";
import { IStepBodyProps } from "../../../../components/wizard/components/StepWizardStep";
import { StepWizardStepFooter } from "../../../../components/wizard/components/StepWizardStepFooter";
import {
  ButtonType,
  CDataButton,
} from "../../../../components/buttons/CDataButton";

interface IPostSavePreviewTable extends IStepBodyProps {
  apiSettings: IAPIConnectorSettings;
  queryInputs: QueryInput[];
  tableSettings: ITableSettings;
  onClose: () => void;
}

const PostSavePreviewTable = ({
  apiSettings,
  queryInputs,
  tableSettings,
  previousStep,
  onClose,
}: IPostSavePreviewTable) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [previewRows, setPreviewRows] = useState<any[]>([]);
  const api = useAPI();

  useEffect(() => {
    const fetchPreviewRows = async () => {
      setLoading(true);
      try {
        const rows = await getPreviewRows(
          apiSettings.globalSettings.connectionName,
          api.callAPI,
          tableSettings,
          queryInputs,
        );
        if (rows) {
          setPreviewRows(rows);
        } else {
          setPreviewRows([]);
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
        previousStep!();
      }
    };

    fetchPreviewRows();
  }, []); // eslint-disable-line

  function onPrevious() {
    previousStep!();
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <PreviewTable
          previewRows={previewRows}
          columns={tableSettings.columns}
          pseudoColumns={tableSettings.pseudoColumns}
        />
      )}

      <StepWizardStepFooter>
        {tableSettings.useQuerySlicer ? (
          <CDataButton buttonType={ButtonType.Tertiary} onClick={onPrevious}>
            Back
          </CDataButton>
        ) : (
          <span />
        )}

        <CDataButton
          buttonType={ButtonType.Primary}
          onClick={() => {
            onClose();
          }}
        >
          Close
        </CDataButton>
      </StepWizardStepFooter>
    </>
  );
};

export default PostSavePreviewTable;
