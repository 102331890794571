import { useContext, useEffect, useState } from "react";
import { useDebounce } from "react-use";
import { QueryBuilderHeader } from "./QueryBuilderHeader";
import { QueryBuilderTabs } from "./tabs/QueryBuilderTabs";
import { QueryBuilderActionButtons } from "./QueryBuilderActionButtons";
import {
  buildDefaultQueryBuilderModel,
  IQueryBuilderModel,
} from "./models/IQueryBuilderModel";
import { IConnection } from "../../../../models";
import useQueryTabs, { IQueryTab } from "../Tabs/useQueryTabs";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { createDeepCopy } from "../../../../utility/CreateDeepCopy";
import { updateDataExplorerQueryBuilderTabs } from "../../../../redux/actions";
import { QueryTabsContext } from "../Tabs/QueryTabsContext";
import { isEqual } from "lodash";

export interface IQueryBuilderProps {
  tab: IQueryTab;
  connectionsList: IConnection[];
  isVisible: boolean;
  abortRunningQueryRequest: () => void;
}

export const QueryBuilder = (props: IQueryBuilderProps) => {
  const { tab } = props;

  const tabs = useQueryTabs();
  const tabContext = useContext(QueryTabsContext);
  const dataExplorer = useAppSelector((state) => state.dataExplorerTabs);
  const dispatch = useAppDispatch();

  const defaultQueryData = dataExplorer.queryBuilderTabs[tab.id]
    ? dataExplorer.queryBuilderTabs[tab.id]
    : buildDefaultQueryBuilderModel({
        connectionId: tab.connectionId!,
        connectionName: tab.connectionName!,
        schema: tab.schemaName!,
        tableName: tab.tableName!,
        driver: tab.driver!,
        tableAlias: tab.tableName!,
      });

  const [queryData, innerSetQueryData] =
    useState<IQueryBuilderModel>(defaultQueryData);

  const setQueryData: React.Dispatch<
    React.SetStateAction<IQueryBuilderModel>
  > = (value) => {
    if (!tab.unsavedChanges) {
      let newQueryData: IQueryBuilderModel;
      if (typeof value === "function") {
        newQueryData = value(queryData);
      } else {
        newQueryData = value;
      }

      if (!isEqual(queryData, newQueryData)) {
        tabContext.setTabs((previousTabs) => {
          const newTabs = createDeepCopy(previousTabs);
          const rowToMutate = newTabs.find(
            (tab) => tab.id === tabs.CurrentTabId,
          );
          if (rowToMutate) {
            rowToMutate.unsavedChanges = true;
          }

          return newTabs;
        });
      }
    }

    innerSetQueryData(value);
  };

  useEffect(() => {
    setQueryData((prevQueryData) => ({
      ...prevQueryData,
      from: {
        connectionId: tab.connectionId!,
        connectionName: tab.connectionName!,
        schema: tab.schemaName!,
        tableName: tab.tableName!,
        driver: tab.driver!,
        tableAlias: tab.tableName!,
      },
    }));
  }, [tab]); // eslint-disable-line

  const updateQueryDataRedux = (newQueryData: IQueryBuilderModel) => {
    const newQueryBuilderTabs = createDeepCopy(dataExplorer.queryBuilderTabs);
    newQueryBuilderTabs[tabs.CurrentTabId] = newQueryData;
    dispatch(updateDataExplorerQueryBuilderTabs(newQueryBuilderTabs));
  };

  useDebounce(() => updateQueryDataRedux(queryData), 200, [queryData]);

  return (
    <div className="query-builder" hidden={!props.isVisible}>
      <QueryBuilderActionButtons
        queryData={queryData}
        setQueryData={setQueryData}
        connectionsList={props.connectionsList}
      />
      <div className="query-builder-body">
        <QueryBuilderHeader tab={tab} connectionList={props.connectionsList} />
        <QueryBuilderTabs
          tab={tab}
          connectionsList={props.connectionsList}
          queryData={queryData}
          setQueryData={setQueryData}
          abortRunningQueryRequest={props.abortRunningQueryRequest}
        />
      </div>
    </div>
  );
};
