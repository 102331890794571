import { useEffect } from "react";
import { BroadcastChannel } from "broadcast-channel";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";

const OAuthCallback = () => {
  const { callbackId } = useParams();

  useEffect(() => {
    const asyncFn = async () => {
      await handleOAuthCallback();
    };
    asyncFn();
  });

  async function handleOAuthCallback() {
    // Get query string parameters.
    const qp = Object.fromEntries(new URLSearchParams(window.location.search));
    // oauth.cdata.com base64-encodes all parameters, undo that.
    Object.keys(qp).forEach((key) => {
      if (key !== "__proto__") {
        qp[key] = atob(qp[key]);
      }
    });
    // Send the parameters to the tab where the user is setting up the connection.
    const oauthChannel = new BroadcastChannel(callbackId!);
    oauthChannel.postMessage(qp);
    // Use a cookie as a fallback.
    // This is for OEM customers who embed our site in an iframe.
    Cookies.set(`oauthCallback-${callbackId}`, JSON.stringify(qp), {
      sameSite: "none",
      secure: true,
    });
    await oauthChannel.close();
    // Close this tab.
    window.close();
  }

  return (
    <div className="text-center">
      <p>
        We&apos;re finishing your sign-in right now. This tab should close on
        its own momentarily.
      </p>
    </div>
  );
};

export default OAuthCallback;
