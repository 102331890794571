declare global {
  interface Window {
    grecaptcha: any;
  }
}

export default class reCAPTCHA {
  siteKey: string;
  action: string;

  constructor(action: string) {
    const recaptchaSiteKey = window.VITE_CONFIG.VITE_GOOGLE_RECAPTCHA;
    loadReCaptcha(recaptchaSiteKey);
    this.siteKey = recaptchaSiteKey;
    this.action = action;
  }

  async getToken(): Promise<string> {
    let token = "";
    await window.grecaptcha
      .execute(this.siteKey, { action: this.action })
      .then((res: string) => {
        token = res;
      });
    return token;
  }
}

const loadReCaptcha = (siteKey: string) => {
  const script = document.createElement("script");
  script.src = `https://www.recaptcha.net/recaptcha/api.js?render=${siteKey}`;
  document.body.appendChild(script);
};
