import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Button } from "reactstrap";

import Loader from "../../components/Loader";
import { prependApiPrefix } from "../../api/prependApiPrefix";

const ValidateEmailChange = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    verifyEmail();
  }, []);

  async function verifyEmail() {
    const queryParams = new URLSearchParams(window.location.search);
    const verificationCode = queryParams.get("verificationCode");
    const type = queryParams.get("type");

    const requestOptions = {
      method: "PUT",
    };

    const url =
      type === "user"
        ? `/users/loginEmail?verificationCode=${verificationCode}`
        : `/account/contactEmail?verificationCode=${verificationCode}`;

    const response = await fetch(prependApiPrefix(url), requestOptions);

    if (response.status === 200) {
      setLoading(false);
    } else {
      window.location.href = encodeURI(
        window.location.origin + "/auth/page-expired",
      );
    }
  }

  function renderValidateEmailChange() {
    return (
      <div className="text-center">
        <p className="h1">Email Successfully Verified</p>
        <p className="mt-3 mb-4">
          Your email has been successfully verified. Click the button below to
          return to Connect Cloud.
        </p>
        <Link to="/">
          <Button
            color="primary"
            className="d-inline-flex align-items-center"
            data-testid="button-return"
          >
            Return to Connect Cloud
            <i className="fa fa-arrow-right align-middle ms-2 no-pointer-event" />
          </Button>
        </Link>
      </div>
    );
  }

  const contents = loading ? (
    <div>
      <Loader />
    </div>
  ) : (
    renderValidateEmailChange()
  );

  return contents;
};

export default ValidateEmailChange;
