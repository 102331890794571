import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import classnames from "classnames";

import {
  Badge,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  UncontrolledTooltip,
} from "reactstrap";

import { IAssetListItem, UserRole } from "../../../models";
import { useAssetModals } from "../../../hooks/useAssetModals";
import { IPartialDriver } from "../../../models/Drivers/IPartialDriver";
import { AuthorizeContext } from "../../../components/guards/UserAuthenticationGuard";
import { IWorkspaceAssetListItem } from "../../../bffmodels/IWorkspaceAssetListItem";
import { useAppSelector } from "../../../redux/hooks";
import { getSettingsPageSubscriptionTab } from "../../../utility/SubscriptionProvider";
import {
  CDataTooltip,
  CDataTooltipType,
} from "../../../components/CDataTooltip";
import { getDriverIcon } from "../../../components/drivers/DriverIconFactory";
import { IsPremiumConnectorDisabled } from "../../../utility/IsPremiumConnectorDisabled";
import { FeatureId } from "../../../models/Features/FeatureId";
import { CDataTypography } from "../../../components/text/CDataTypography";

/**
 * A horizontal tile that displays information about an asset. Also provides a sub-menu for
 * performing edit, delete, and copy functions on the asset.
 */

interface IAssetRowTileProps {
  assetListItem: IWorkspaceAssetListItem | IAssetListItem;
  copyTile?: (id: string) => void;
  onSuccessfulDelete?: (assetId: string) => void;
  openMoveAssetModal?: (
    folderId: string,
    assetId?: string,
    name?: string,
  ) => void;
  selectedDataObjects: any[];
  tileType: DatasetsTileTypes;
  toggleCheckbox: (item: IWorkspaceAssetListItem | IAssetListItem) => void;
}

export enum DatasetsTileTypes {
  Workspace,
  DataAsset,
  FolderAsset,
}

const enum DropdownMenuOptions {
  Edit,
  Delete,
  Copy,
  Move,
}

export function AssetRowTile(props: IAssetRowTileProps) {
  const userAccount = useContext(AuthorizeContext);
  const isAdminUser = userAccount && userAccount.role === UserRole.Admin;

  const [isActionMenuOpen, setIsActionMenuOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const { workspaceId } = useParams();
  const assetModals = useAssetModals();
  const isWorkspace = props.tileType === DatasetsTileTypes.Workspace;
  const isDataAsset = props.tileType === DatasetsTileTypes.DataAsset;
  const isFolderAsset = props.tileType === DatasetsTileTypes.FolderAsset;

  const driversList = useAppSelector((state) => state.driversList);
  const availableFeatureIds: FeatureId[] =
    useAppSelector(
      (state) => state.subscription?.limits?.availableFeatureIds,
    ) ?? [];
  const user = useAppSelector((state) => state.user);

  const rowIcon = classnames("fa-regular", {
    "fa-folder-open": isWorkspace || isFolderAsset,
    "fa-border-none":
      isDataAsset && props.assetListItem?.driver === "DerivedView",
    "fa-table": isDataAsset && props.assetListItem?.driver !== "DerivedView",
  });

  const handleDropdownClick = (option: DropdownMenuOptions) => {
    setIsActionMenuOpen(false);
    switch (option) {
      case DropdownMenuOptions.Edit: {
        if (props.assetListItem.isConnectionTested !== false) {
          let url: string;
          if (isDataAsset)
            url = `/datasets/workspace/${workspaceId}/asset/${props.assetListItem.id}`;
          else if (isFolderAsset)
            url = `/datasets/workspace/${workspaceId}/folder/${props.assetListItem.id}`;
          else url = `/datasets/workspace/${workspaceId}`;

          navigate(url);
        }
        break;
      }
      case DropdownMenuOptions.Delete: {
        assetModals.openDeleteAssetModal(
          props.assetListItem.id,
          props.assetListItem.assetType,
          props.assetListItem.alias!,
          props.onSuccessfulDelete,
        );
        break;
      }
      case DropdownMenuOptions.Copy: {
        props.copyTile!(props.assetListItem.id);
        break;
      }
      case DropdownMenuOptions.Move: {
        if (isFolderAsset)
          props.openMoveAssetModal!(
            props.assetListItem.id,
            undefined,
            props.assetListItem.alias,
          );
        else {
          props.openMoveAssetModal!(
            "",
            props.assetListItem.id,
            props.assetListItem.alias,
          );
        }
        break;
      }
    }
  };

  const premiumConnectorDisabled = IsPremiumConnectorDisabled(
    props.assetListItem.driver!,
    driversList,
    availableFeatureIds,
  );

  function navigateToConnectionPage() {
    const drivers = [...driversList.drivers!];
    navigate("/connections/edit", {
      state: {
        driver: props.assetListItem.driver,
        connectionId: props.assetListItem.connectionId,
        hasCustomReports: drivers.find(
          (driver: IPartialDriver) =>
            driver.driver === props.assetListItem.driver,
        )!.hasCustomReports,
        overrideConnectionType: false,
      },
    });
  }

  const warningMessage =
    user.role === UserRole.Query ? (
      <>
        The connection associated with this asset is not connected. Contact an
        administrator to resolve this issue.
      </>
    ) : (
      <>
        The connection associated with this asset is not connected. Please open
        the{" "}
        <a
          className="upgrade-link"
          onClick={(event: React.MouseEvent) => {
            event.stopPropagation();
            navigateToConnectionPage();
          }}
        >
          {" "}
          connection details
        </a>{" "}
        to resolve this issue.
      </>
    );

  const warningIcon =
    props.assetListItem.isConnectionTested === false ? (
      <Col
        className={classnames("pe-0", {
          "asset-tile-objects-disabled": premiumConnectorDisabled,
        })}
      >
        <i
          id={`warning-icon-${props.assetListItem.id}`}
          className="fa-fa-exclamation-triangle me-2 warning-icon"
          onClick={(event: React.MouseEvent) => {
            event.stopPropagation();
          }}
        />

        <UncontrolledTooltip
          placement="top"
          target={`warning-icon-${props.assetListItem.id}`}
          autohide={false}
        >
          {warningMessage}
        </UncontrolledTooltip>
      </Col>
    ) : null;

  const cachingBadge =
    (isDataAsset || isFolderAsset) &&
    "linkedCacheJobId" in props.assetListItem &&
    props.assetListItem.linkedCacheJobId != null &&
    props.assetListItem.linkedCacheJobIsEnabled === true ? (
      <Col
        className={classnames("assets-count mx-2 p-0", {
          "asset-tile-objects-disabled": premiumConnectorDisabled,
        })}
      >
        <Badge
          color=""
          className="badge-quaternary"
          data-testid="badge-caching"
        >
          Caching
        </Badge>
      </Col>
    ) : null;

  const assetCount =
    isWorkspace || isFolderAsset ? (
      <Col
        className={classnames("assets-count mx-2 p-0", {
          "asset-tile-objects-disabled": premiumConnectorDisabled,
        })}
      >
        <i className="fa-regular fa-files assets-icon"></i>&nbsp;
        {props.assetListItem.childCount}&nbsp;assets
      </Col>
    ) : null;

  return (
    <CDataTooltip
      type={CDataTooltipType.Dark}
      title={
        premiumConnectorDisabled ? (
          <span>
            Premium Connectors are not available with your subscription.
            <a
              className="upgrade-link"
              onClick={() =>
                navigate(
                  "/settings?defaultTab=" + getSettingsPageSubscriptionTab(),
                )
              }
            >
              {" "}
              Upgrade
            </a>{" "}
            your plan today to continue using this connection.
          </span>
        ) : undefined
      }
    >
      <span>
        <Row
          className={classnames(
            "row-tile",
            {
              "cursor-pointer":
                props.assetListItem.isConnectionTested &&
                !premiumConnectorDisabled,
            },
            { "query-user-card": !isAdminUser },
          )}
          onClick={() => {
            if (!premiumConnectorDisabled)
              handleDropdownClick(DropdownMenuOptions.Edit);
          }}
        >
          {isAdminUser ? (
            <Col className="checkbox ms-3 me-2 p-0">
              <Input
                type="checkbox"
                checked={props.selectedDataObjects.some(
                  (tile) => tile.id === props.assetListItem.id,
                )}
                onClick={(event) => {
                  event.stopPropagation();
                  props.toggleCheckbox(props.assetListItem);
                }}
                className="m-0 cursor-pointer"
              />
            </Col>
          ) : null}
          <Col
            className={classnames("folder-icon mx-2 p-0", {
              "asset-tile-objects-disabled": premiumConnectorDisabled,
            })}
          >
            <i className={rowIcon} />
          </Col>
          <Col
            className={classnames("nameAndDescription text-truncate mx-0 p-0", {
              "asset-tile-objects-disabled": premiumConnectorDisabled,
            })}
          >
            <Row className="mx-0 p-0">
              <Col className="name text-truncate">
                <CDataTypography variant="typography-variant-card-title">
                  {props.assetListItem.alias}
                  {getDriverIcon(
                    props.assetListItem.driver as string,
                    "driver-icon-asset me-2 p-0",
                  )}
                </CDataTypography>
              </Col>
            </Row>
            <Row className="mx-0 p-0 mt-1">
              <Col className="description text-truncate">
                <CDataTypography
                  variant="typography-variant-caption"
                  color="typography-color-text-muted"
                >
                  {props.assetListItem.description}
                </CDataTypography>
              </Col>
            </Row>
          </Col>
          {warningIcon}
          {cachingBadge}
          {assetCount}
          {isAdminUser ? (
            <Col className="ellipses-icon ms-2 me-3 p-0">
              <Dropdown
                isOpen={isActionMenuOpen}
                toggle={(event: React.KeyboardEvent | React.MouseEvent) => {
                  event.stopPropagation();
                  setIsActionMenuOpen(!isActionMenuOpen);
                }}
              >
                <DropdownToggle nav>
                  <i className="fa fa-regular fa-ellipsis-vertical p-2"></i>
                </DropdownToggle>
                <DropdownMenu
                  direction="left"
                  className="options-menu inline-dropdown dropdown-window"
                >
                  <DropdownItem
                    className={classnames("edit-button", {
                      disabled:
                        props.assetListItem.isConnectionTested === false ||
                        premiumConnectorDisabled,
                    })}
                    onClick={() => {
                      if (!premiumConnectorDisabled)
                        handleDropdownClick(DropdownMenuOptions.Edit);
                    }}
                  >
                    Edit
                  </DropdownItem>
                  <DropdownItem
                    onClick={() =>
                      handleDropdownClick(DropdownMenuOptions.Delete)
                    }
                  >
                    Delete
                  </DropdownItem>
                  {isWorkspace ? (
                    <DropdownItem
                      disabled={premiumConnectorDisabled}
                      onClick={() => {
                        if (!premiumConnectorDisabled)
                          handleDropdownClick(DropdownMenuOptions.Copy);
                      }}
                    >
                      Copy
                    </DropdownItem>
                  ) : (
                    <DropdownItem
                      disabled={premiumConnectorDisabled}
                      onClick={() => {
                        if (!premiumConnectorDisabled)
                          handleDropdownClick(DropdownMenuOptions.Move);
                      }}
                    >
                      Move
                    </DropdownItem>
                  )}
                </DropdownMenu>
              </Dropdown>
            </Col>
          ) : null}
        </Row>
      </span>
    </CDataTooltip>
  );
}
