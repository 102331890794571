import React, { useEffect } from "react";
import { useAuthentication } from "../../hooks/useAuthentication";

const LogOut = () => {
  const auth = useAuthentication();

  useEffect(() => {
    auth.Logout();
  });

  return <></>;
};

export default LogOut;
