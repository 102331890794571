import React from "react";
import { Col, Input, Row } from "reactstrap";
import { JobFrequencyUnit } from "../../../../models/Cache/JobFrequencyUnit";
import classnames from "classnames";
import { CDataTypography } from "../../../../components/text/CDataTypography";
import { getNextJobRun } from "../../util/CacheUtils";
import { CDataDatePicker } from "../../../../components/datepicker/CDataDatePicker";
import { format, parseISO } from "date-fns";
import { JobType } from "../../../../models/Cache/JobType";
import { useFormikContext } from "formik";
import { ICacheJob } from "../../../../models/Cache/ICacheJob";
import { IScheduledQuery } from "../../../../models/ScheduledQueries/IScheduledQuery";

interface IRunFrequencyRowProps {
  jobType: JobType;
  setUnsavedChanges: (unsavedChanges: boolean) => void;
}

export const RunFrequencyRow = <T extends ICacheJob | IScheduledQuery>(
  props: IRunFrequencyRowProps,
) => {
  const { jobType, setUnsavedChanges } = props;
  const { errors, values, setValues, touched } = useFormikContext<T>();

  const validateFrequency = (event: React.ChangeEvent) => {
    const value = (event.target as HTMLInputElement).value;
    const regex = /^[0-9]{0,3}$/;

    // Limit the length of the frequency to 3 digits
    if (regex.test(value)) {
      updateJobFrequency(parseInt(value || "0"));
    }
  };

  function updateJobFrequency(newFrequency: number) {
    setValues({
      ...values,
      jobFrequency: newFrequency,
      status: {
        ...values.status,
        nextRun: getNextJobRun(
          values.status?.lastRun,
          values.status?.nextRun,
          newFrequency,
          values.jobFrequencyUnit,
        ),
      },
    });
    setUnsavedChanges(true);
  }

  function updateJobFrequencyUnit(newFrequencyUnit: number) {
    setValues({
      ...values,
      jobFrequencyUnit: newFrequencyUnit,
      status: {
        ...values.status,
        nextRun: getNextJobRun(
          values.status?.lastRun,
          values.status?.nextRun,
          values.jobFrequency,
          newFrequencyUnit,
        ),
      },
    });
    setUnsavedChanges(true);
  }

  function updateStartDate(newDate: Date | undefined) {
    if (newDate == null) {
      setValues({
        ...values,
        definedNextRun: undefined,
      });
      return;
    }

    const dateString =
      format(newDate, "yyyy-MM-dd") + "T" + format(newDate, "HH:mm:ss");

    setValues({ ...values, definedNextRun: dateString });
    setUnsavedChanges(true);
  }

  return (
    <span data-testid="row-run-frequency" className="run-frequency-row">
      <CDataTypography
        variant="typography-variant-body-medium"
        className="mb-2"
      >
        Set how often you would like this data to be updated.
      </CDataTypography>
      <Row className="align-items-start mb-3">
        <Col
          className={classnames("flex-column", {
            "frequency-control": jobType === JobType.Caching,
            "frequency-control-with-date": jobType === JobType.ScheduledQuery,
          })}
        >
          <Input
            name="jobFrequency"
            data-testid="input-frequency"
            value={values?.jobFrequency}
            className={classnames("form-control select unit-number-input", {
              "red-focus": !!errors.jobFrequency,
            })}
            onChange={(event: React.ChangeEvent) => {
              validateFrequency(event);
            }}
            invalid={touched.jobFrequency && !!errors.jobFrequency}
          />
          {errors.jobFrequency ? (
            <div
              className="red-error align-self-start"
              data-testid="validation-text-frequency"
            >
              {errors.jobFrequency.toString()}
            </div>
          ) : null}
        </Col>
        <Col
          className={classnames({
            "ps-0 frequency-control": jobType === JobType.Caching,
            "px-0 frequency-control-with-date":
              jobType === JobType.ScheduledQuery,
          })}
        >
          <Input
            name="jobFrequencyUnit"
            data-testid="input-select-frequency-unit"
            type="select"
            className="form-control select"
            value={values.jobFrequencyUnit}
            onChange={(event: React.ChangeEvent) => {
              updateJobFrequencyUnit(
                parseInt((event.target as HTMLInputElement).value),
              );
            }}
          >
            {Object.keys(JobFrequencyUnit)
              .filter((frequency) => !isNaN(Number(frequency)))
              .map((frequency) => (
                <option key={frequency} value={frequency}>
                  {`${JobFrequencyUnit[frequency as keyof typeof JobFrequencyUnit]}s`}
                </option>
              ))}
          </Input>
        </Col>
        {jobType === JobType.ScheduledQuery && (
          <Col>
            <CDataDatePicker
              date={
                values?.definedNextRun != null &&
                values?.definedNextRun.length > 0
                  ? parseISO(values.definedNextRun)
                  : undefined
              }
              onChange={(date) => updateStartDate(date)}
              allowPreviousDates={false}
              showClearButton={false}
              showTimePicker={true}
              helperText="Select your start date & run time (UTC)."
              name="definedNextRun"
              invalid={!!errors.definedNextRun}
              selectedDateDisplay="calendar"
            />
          </Col>
        )}
      </Row>
    </span>
  );
};
