/**
 * Converts a json path string like `$.people.children.first_name` to xpath like `/people/children/first_name`
 */
export function jsonPathToXPath(jsonPath: string) {
  if (jsonPath === "$") {
    return "/";
  }
  // Remove the leading $ for a json path.
  // Then replace all `.` characters with `/` characters to match an XML XPath.
  return jsonPath.replace(/\$/g, "").replace(/\./g, "/");
}
