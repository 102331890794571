import React, { ReactNode } from "react";
import { MenuItem, Select, SelectProps } from "@mui/material";
import classNames from "classnames";
import { CDataTypography } from "../text/CDataTypography";
import Loader from "../Loader";

export type CDataSelectProps = Omit<
  SelectProps<string>,
  "value" | "variant"
> & {
  value: string;
  label?: ReactNode;
  /** True to display a loading spinner inside the dropdown if the user opens it. */
  loading?: boolean;
  helperText?: ReactNode | null;
  isInvalid?: boolean | null;
  /** Props passed to the <div> container. */
  containerProps?: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >;
  "data-testid"?: string;
};

/**
 * A light-weight wrapper around the material-ui Select component.
 *
 * This exposes all the material-ui Select props here:
 * https://mui.com/material-ui/react-select/
 *
 */
export function CDataSelect(props: CDataSelectProps) {
  const {
    children,
    label,
    className,
    containerProps,
    loading,
    isInvalid,
    helperText,
    ...selectProps
  } = props;

  let title = label;
  if (label && typeof label === "string") {
    title = (
      <CDataTypography
        variant="typography-variant-headline-5"
        className={classNames("mb-2", { required: props.required })}
      >
        {label}
      </CDataTypography>
    );
  }

  const feedback = helperText && (
    <CDataTypography
      variant="typography-variant-helper-text"
      color={
        isInvalid ? "typography-color-danger" : "typography-color-text-regular"
      }
    >
      {helperText}
    </CDataTypography>
  );

  return (
    <div
      className={classNames("cdataSelect-container", className)}
      {...containerProps}
    >
      {title}
      <Select<string>
        displayEmpty
        fullWidth
        renderValue={(selected) => {
          if (selected == null || selected.length === 0) {
            return "Select";
          }
          return selected;
        }}
        IconComponent={(props) => (
          <i
            {...props}
            className={classNames(
              "dropdown-icon fa-regular me-2 fa-chevron-down",
              props.className,
            )}
          />
        )}
        aria-label={
          label != null && typeof label === "string" ? label : undefined
        }
        {...selectProps}
        className={classNames(
          "cdataSelect-select",
          isInvalid ? "is-invalid" : null,
        )}
        MenuProps={{
          ...selectProps.MenuProps,
          className: classNames(
            "cdataSelect-menu",
            selectProps.MenuProps?.className,
          ),
        }}
      >
        {loading && (
          <MenuItem
            key="loader"
            onClick={(event) => {
              // Clicking the loading spinner should do nothing.
              event.stopPropagation();
              event.preventDefault();
            }}
          >
            <Loader customHeightClass="cdataSelect-loader" />
          </MenuItem>
        )}

        {!loading && children}
      </Select>

      {feedback}
    </div>
  );
}
