import { MutableRefObject, useEffect } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { Form, Formik, FormikProps, useFormikContext } from "formik";
import { produce } from "immer";
import * as Yup from "yup";
import { HTML5Backend } from "react-dnd-html5-backend";
import { isEmpty } from "lodash";

import { KeyValueTableRow, IGlobalSettings } from "../../../ApiConnector";
import TextInput from "../../../../../components/form/TextInput";
import {
  ButtonType,
  CDataButton,
} from "../../../../../components/buttons/CDataButton";
import {
  YupOptionalSchema,
  YupRequiredSchema,
} from "../../../../../utility/types/yupHelperTypes";

export interface IOAuthParamsCardProps {
  globalSettings: IGlobalSettings;
  setGlobalSettings: (globalSettings: IGlobalSettings) => void;
  setUnsavedChanges: (hasUnsavedChanges: boolean) => void;
  disableDrag?: boolean;
  formRef: MutableRefObject<FormikProps<KeyValueTableRow[]>>;
}

const yupSchema = Yup.object<YupOptionalSchema<IGlobalSettings>>({
  headers: Yup.array().of(
    Yup.object<YupRequiredSchema<KeyValueTableRow>>({
      name: Yup.string().nullable().trim().required("Name is required."),
      value: Yup.string().nullable().trim().required("Value is required."),
    }),
  ),
});

export const OAuthParamsCard = (props: IOAuthParamsCardProps) => {
  const { globalSettings, setGlobalSettings, formRef } = props;

  function setOAuthParams(oAuthParams: KeyValueTableRow[]) {
    setGlobalSettings({
      ...globalSettings,
      authentication: {
        ...globalSettings.authentication,
        oAuthParams: oAuthParams
          .map((kv) => `${kv.name}=${kv.value}`)
          .join(","),
      },
    });
  }
  function convertToObjectArray(
    commaSeparatedString: string | undefined,
  ): KeyValueTableRow[] {
    if (!commaSeparatedString) {
      return [];
    }
    const keyValuePairs = commaSeparatedString.split(",");
    if (
      keyValuePairs.length === 0 ||
      (keyValuePairs.length === 1 && keyValuePairs[0] === "")
    ) {
      return [];
    }
    const objectArray: KeyValueTableRow[] = keyValuePairs.map((pair) => {
      const [name, value] = pair.split("=").map((part) => part.trim());
      return { name, value };
    });
    return objectArray;
  }
  const oAuthParamasArray = convertToObjectArray(
    globalSettings.authentication.oAuthParams,
  );
  return (
    <>
      <h5 className="card-title">OAuth Parameters</h5>
      <div className="global-header-card-description mt-3 mb-3">
        These settings will be added as parameters to the associated request.
      </div>
      <div>
        <Formik
          innerRef={formRef}
          initialValues={oAuthParamasArray}
          validationSchema={yupSchema}
          validateOnChange={true}
          onSubmit={() => {
            // no-op
          }}
        >
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <OAuthParamsFormBody {...props} setOAuthParams={setOAuthParams} />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

interface IOAuthParamsCardFormBodyProps {
  setOAuthParams: (headers: KeyValueTableRow[]) => void;
  setUnsavedChanges: (hasUnsavedChanges: boolean) => void;
}

const OAuthParamsFormBody = (props: IOAuthParamsCardFormBodyProps) => {
  const { setOAuthParams, setUnsavedChanges } = props;

  const { values, setValues, validateForm, touched } =
    useFormikContext<KeyValueTableRow[]>();

  // This isn't a real form, just update the parent whenever the form data changes.
  useEffect(() => {
    setOAuthParams(values);
  }, [values]); // eslint-disable-line

  useEffect(() => {
    if (!isEmpty(touched)) {
      setUnsavedChanges(true);
    }
  }, [touched]); // eslint-disable-line

  useEffect(() => {
    // This is annoying but deleting a row causes the indexes to shuffle around
    // and validation messages to show up on the wrong fields. We have no real key for the rows.
    // We need to trigger a full form validation when the rows are deleted to avoid validation messages
    // being moved to the wrong field.
    validateForm();
  }, [values.length]); // eslint-disable-line

  function addNewHeaderRow() {
    const newTableSettings = produce(values, (draft) => {
      draft.push({
        name: "",
        value: "",
      });
    });
    setValues(newTableSettings, true);
    setOAuthParams(newTableSettings);

    setUnsavedChanges(true);
  }

  return (
    <div className="pages-apiConnector-components-OAuthParamsCard">
      {values.length > 0 && (
        <div className="oauth-params-row oauth-param-title-row">
          <div className="oauth-params-column">
            <h5>Name</h5>
          </div>
          <div className="oauth-params-column">
            <h5>Value</h5>
          </div>
          {/* Empty div to take up the same space as the buttons in the header rows. */}
          <div className="oauth-params-button-container" />
        </div>
      )}
      <DndProvider backend={HTML5Backend}>
        <div className="oauth-params-rows-container">
          {values.map((h, index) => {
            return (
              <HeaderRow
                key={index}
                {...props}
                index={index}
                disableDrag={true}
              />
            );
          })}
        </div>
      </DndProvider>
      <CDataButton
        buttonType={ButtonType.Tertiary}
        className="oauth-params-add-button"
        onClick={addNewHeaderRow}
      >
        <i className="fa fa-plus icon" />
        Add Parameter
      </CDataButton>
    </div>
  );
};

interface IHeaderRowProps {
  index: number;
  setUnsavedChanges: (hasUnsavedChanges: boolean) => void;
  disableDrag?: boolean;
}

const HeaderRow = (props: IHeaderRowProps) => {
  const { index, setUnsavedChanges, disableDrag } = props;

  const { values, setValues } = useFormikContext<KeyValueTableRow[]>();

  const myHeader = values[index];

  const [, dragRef, previewRef] = useDrag(
    () => ({
      type: "HeaderRow",
      item: { index },
      previewOptions: {},
    }),
    [],
  );

  const [, dropRef] = useDrop({
    accept: "HeaderRow",
    drop: (draggedRow: { index: number }) => {
      const newData = produce(values, (draft) => {
        draft.splice(index, 0, draft.splice(draggedRow.index, 1)[0]);
      });
      setValues(newData);
    },
  });
  return (
    <div ref={dropRef}>
      <div ref={previewRef} className="oauth-params-row">
        <div className="oauth-params-column">
          <TextInput
            label=""
            name={`[${index}].name`}
            aria-label="Header Name"
          />
        </div>
        <div className="oauth-params-column">
          <TextInput
            label=""
            name={`[${index}].value`}
            aria-label="Header Value"
          />
        </div>
        <div className="oauth-params-button-container">
          <CDataButton
            className="oauth-params-icon-button"
            buttonType={ButtonType.Tertiary}
            aria-label={`Delete Header ${myHeader.name}`}
            onClick={() => {
              const newValues = produce(values, (draft) => {
                draft.splice(index, 1);
              });
              setValues(newValues);
              setUnsavedChanges(true);
            }}
          >
            <i className="fa fa-trash" />
          </CDataButton>
          {!disableDrag && (
            <span
              className="dnd-btn"
              aria-label={`Reorder Header ${myHeader.name}`}
              ref={dragRef}
            >
              <i className="fa fa-arrows-alt" />
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
