import { useEffect, useState } from "react";
import { QueryBuilderAddButton } from "../components/QueryBuilderAddButton";
import { QueryBuilderCard } from "../components/QueryBuilderCard";
import { QueryBuilderCardHeader } from "../components/QueryBuilderCardHeader";
import { IQueryBuilderCommonProps } from "../../../models/IQueryBuilderCommonProps";
import { AddSortsModal } from "./AddSortsModal";
import { SortTileList } from "./SortTileList";
import { produce } from "immer";
import { getFullyQualifiedColumnName } from "../../../sqlGenerator/getFullyQualifiedColumnName";
import {
  CDataTooltip,
  CDataTooltipType,
} from "../../../../../../../components/CDataTooltip";

interface QueryBuilderSortCardProps extends IQueryBuilderCommonProps {}

export function QueryBuilderSortCard(props: QueryBuilderSortCardProps) {
  const { queryData, setQueryData } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sortToEditIndex, setSortToEditIndex] = useState<number>();
  const isQueryBuilderEmpty = props.queryData.from.connectionId === "";

  const sortCount = queryData.sort?.length ?? 0;

  //Update sorts whenever metrics or dimension changes
  useEffect(() => {
    const allMetrics = new Set([
      ...queryData.metrics.map((m) => getFullyQualifiedColumnName(m.column)),
    ]);
    const allGroupBys = new Set([
      ...queryData.groupBy.map((g) => getFullyQualifiedColumnName(g.column)),
    ]);

    const newQueryData = produce(queryData, (draft) => {
      draft.sort = draft.sort.filter((s) => {
        return (
          allMetrics.has(getFullyQualifiedColumnName(s.column)) ||
          allGroupBys.has(getFullyQualifiedColumnName(s.column))
        );
      });
    });
    setQueryData(newQueryData);
  }, [queryData.metrics, queryData.groupBy]); // eslint-disable-line

  function onClickAddSort() {
    setSortToEditIndex(undefined);
    setIsModalOpen(true);
  }

  function onClickEditSort(index: number) {
    setSortToEditIndex(index);
    setIsModalOpen(true);
  }

  function handleDelete(sortIndex: number) {
    const newQueryData = { ...queryData };
    const updatedSorts = queryData.sort.filter(
      (_j, index) => sortIndex !== index,
    );

    newQueryData.sort = updatedSorts;

    setQueryData(newQueryData);
  }

  const addSortsModal = isModalOpen && (
    <AddSortsModal
      {...props}
      sortToEditIndex={sortToEditIndex}
      displayed={isModalOpen}
      close={() => setIsModalOpen(false)}
    />
  );

  function onReorderSorts(oldSortIndex: number, newSortIndex: number) {
    const reorderedSorts = produce(queryData, (draft) => {
      draft.sort.splice(newSortIndex, 0, draft.sort.splice(oldSortIndex, 1)[0]);
    });

    setQueryData(reorderedSorts);
  }

  return (
    <QueryBuilderCard>
      <QueryBuilderCardHeader title="Sort">
        <CDataTooltip
          type={CDataTooltipType.Dark}
          title={
            sortCount >= 2 ? "A max of two sorts can be added at a time." : ""
          }
        >
          <div>
            <QueryBuilderAddButton
              aria-label="Add Sort"
              onClick={() => onClickAddSort()}
              disabled={sortCount >= 2 || isQueryBuilderEmpty}
              isQueryBuilderEmpty={isQueryBuilderEmpty}
              id="add-sort-button"
            >
              Add Sorts
            </QueryBuilderAddButton>
          </div>
        </CDataTooltip>
      </QueryBuilderCardHeader>
      <SortTileList
        queryData={queryData}
        getSortId={(querySort) =>
          `${getFullyQualifiedColumnName(querySort.column)}.[${querySort.direction}]`
        }
        onReorderSorts={onReorderSorts}
        onClickEditSort={onClickEditSort}
        handleDelete={handleDelete}
      />
      {addSortsModal}
    </QueryBuilderCard>
  );
}
