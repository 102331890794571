import DataLabelAndDropdown from "./DataLabelAndDropdown";
import { useFormikContext } from "formik";
import { Badge } from "reactstrap";
import { TextField } from "@mui/material";
import { produce } from "immer";
import { IQueryBuilderColumn } from "../../../../../models/IQueryBuilderColumn";
import { IQueryBuilderJoin } from "../../../../../models/IQueryBuilderModel";
import { upperCase } from "lodash";
import { DataType } from "../../../../../../../../../models";

interface JoinTableColumnFieldProps {
  joinTableColumns: IQueryBuilderColumn[];
  processing: boolean;
}

const JoinTableColumnField = (props: JoinTableColumnFieldProps) => {
  const { joinTableColumns, processing } = props;
  const { setValues, values } = useFormikContext<IQueryBuilderJoin>();

  const currentJoinTable = values.left.table.tableName;

  function updateSelectedColumn(column: IQueryBuilderColumn) {
    setValues(
      produce(values, (draft) => {
        draft.left.column = column.column;
        draft.left.dataType = column.dataType;
      }),
    );
  }

  // Filter out columns that don't match the New Table column data type
  const columnsWithMatchingType =
    joinTableColumns?.filter(
      (column) => column.dataType === values.right.dataType,
    ) || [];

  const helperText = (
    <span className="helper-text">
      Only columns that are compatible with the new tables column data type will
      be displayed.
    </span>
  );

  const validationError =
    !processing &&
    currentJoinTable &&
    values.right.column &&
    columnsWithMatchingType?.length === 0
      ? "No compatible columns are available based on the column data type selected in the New Table."
      : null;

  const isOptionEqualToValue = (
    option: IQueryBuilderColumn,
    value: IQueryBuilderColumn,
  ) => {
    return option.column === value.column && option.dataType === value.dataType;
  };

  return (
    <DataLabelAndDropdown
      handleChange={updateSelectedColumn}
      helperText={helperText}
      isDisabled={
        !values.right.column || !currentJoinTable || Boolean(validationError)
      }
      isOptionEqualToValue={isOptionEqualToValue}
      label={"Column Name"}
      dropdownLabel={(option) => option.column}
      optionLabel={(option) => (option.column ? option.column : "")}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {params.InputProps?.endAdornment}
                  {values.left.column && (
                    <Badge className="data-type-badge" color="">
                      {upperCase(DataType[values.left.dataType])}
                    </Badge>
                  )}
                </>
              ),
            }}
          />
        );
      }}
      id={"joinTable.column"}
      key={values.left?.column}
      options={columnsWithMatchingType || []}
      validationError={validationError ?? undefined}
      value={values.left?.column.length > 0 ? values.left : null}
    />
  );
};

export default JoinTableColumnField;
