export interface XMLTreeTableRow {
  id: string;
  name: string;
  path: string;
  type: XMLValueType;
  subRows?: XMLTreeTableRow[];
}

export enum XMLValueType {
  String = "string",
  Number = "number",
  Boolean = "boolean",
  Null = "null",
  Object = "object",
  Array = "array",
}

const parseXMLAsTreeTable = (xml: string): XMLTreeTableRow[] | Error => {
  try {
    const xmlParser = new DOMParser();
    const parsedXML = xmlParser.parseFromString(xml, "text/xml");
    const errorNode = parsedXML.querySelector("parsererror");
    if (errorNode) {
      return new Error(
        `Error parsing XML: ${errorNode.querySelector("div")!.textContent}`,
      );
    }
    const docEl = parsedXML.documentElement;
    const rootPath = "";
    return convertXMLToTreeTable(docEl, rootPath);
  } catch (e) {
    return new Error(`Error parsing XML: ${e}`);
  }
};

const convertXMLToTreeTable = (
  xml: Element,
  path: string,
): XMLTreeTableRow[] => {
  return [recursivelyTraverseChildElements(xml, path)];
};

const recursivelyTraverseChildElements = (
  childXML: ChildNode,
  parentPath: string,
): XMLTreeTableRow => {
  const currentPath = buildCurrentPath(childXML, parentPath);

  // Loop over child nodes of the current node
  const subRowArray = Array.from(childXML.childNodes)
    // Check if is a node element, meaning we have to continue traversing its children
    .filter((n) => n.nodeType === Node.ELEMENT_NODE)
    // Recursive call to traverse the sub-tree
    .map((n) => recursivelyTraverseChildElements(n, currentPath));

  return {
    name: childXML.nodeName,
    id: childXML.nodeName,
    path: currentPath,
    type: typeof childXML.textContent as XMLValueType,
    ...(subRowArray.length > 0 ? { subRows: subRowArray } : {}),
  } as XMLTreeTableRow;
};

const buildCurrentPath = (childXML: ChildNode, parentPath: string): string => {
  // Find sibling elements with the same node name
  const siblingElements = Array.from(
    childXML.parentNode?.childNodes || [],
  ).filter((node) => node.nodeName === childXML.nodeName);

  let currentPath = parentPath;
  if (siblingElements.length > 1) {
    const currentIndex = siblingElements.indexOf(childXML);
    currentPath += `/${childXML.nodeName}[${currentIndex}]`;
  } else {
    currentPath += `/${childXML.nodeName}`;
  }
  return currentPath;
};

export default parseXMLAsTreeTable;
