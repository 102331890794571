import React from "react";
import { Button } from "reactstrap";
import { getSalesEmailAddress } from "../../utility/LocalizedEmailAddresses";
import InactiveAccountIcon from "../../assets/img/auth/InactiveAccount.svg?react";

const AccountInactive = () => {
  const salesEmail = getSalesEmailAddress();
  const emailLink = <a href={`mailto:${salesEmail}`}>{salesEmail}</a>;

  const handleClick = () => {
    window.location.href = `mailto:${salesEmail}`;
  };

  return (
    <div className="m-sm-4 text-center">
      <InactiveAccountIcon />
      <h1 className="my-4">Inactive Account</h1>
      <p className="text-muted">
        Your account is inactive or not associated with an active subscription.
        Reach out to your system administrator for help or contact {emailLink}{" "}
        to upgrade your plan.
      </p>
      <Button color="primary" onClick={handleClick}>
        <i className="fa fa-solid fa-envelope fa-xs me-1" /> Contact Sales
      </Button>
    </div>
  );
};

export default AccountInactive;
