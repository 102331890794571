import { IWorkspace } from "../../models";
import { useContext, useEffect, useState } from "react";
import { Card, Col, Row } from "reactstrap";
import { sortByNameOrAlias } from "./components/ObjectSorter";
import { EmptyMessages, ViewMode } from "../../components/IFilterList";
import WorkspaceGridTile from "./components/WorkspaceGridTile";
import WorkspaceRibbon from "../../components/WorkspaceRibbon";
import { useWorkspaceModals } from "../../hooks/useWorkspaceModals";
import { useAPI } from "../../components/useAPI";
import { RequestType } from "../../components/withAPI";
import { IWorkspaceList } from "../../models/Datasets/IWorkspaceList";
import { IWorkspaceListItem } from "../../models/Datasets/IWorkspaceListItem";
import { SearchBar } from "./components/SearchBar";
import { WorkspaceRowTile } from "./components/WorkspaceRowTile";
import GridContainer from "./EditWorkspace/GridContainer";
import ListContainer from "./components/ListContainer";
import { useCheckboxManager } from "../../components/useCheckboxManager";
import { PermissionFlag } from "../../utility/PermissionFlag";
import { AuthorizeContext } from "../../components/guards/UserAuthenticationGuard";
import { UserRole } from "../../models/Users/UserRole";
import { ButtonType, CDataButton } from "../../components/buttons/CDataButton";
import { useNameAvailabilityCheck } from "../../hooks/useNameAvailabilityCheck";
import { useUserInfo } from "../../hooks/useUserInfo";
import Loader from "../../components/Loader";

const WorkspacesCard = () => {
  const userAccount = useContext(AuthorizeContext);
  const isAdminUser = userAccount && userAccount.role === UserRole.Admin;

  const [workspaces, setWorkspaces] = useState<IWorkspaceListItem[]>([]);
  const [textFilter, setTextFilter] = useState<string>("");
  const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.List);
  const [workspacesAllowed, setWorkspacesAllowed] = useState<{
    [key: string]: boolean;
  }>({});
  const [unavailableNames, setUnavailableNames] = useState<string[]>([]);
  const [loadingWorkspaces, setLoadingWorkspaces] = useState(true);

  const user = useUserInfo();
  const { getRestrictedNames, pendingFetch } = useNameAvailabilityCheck();

  function getComponentName() {
    return "pages-datasets-WorkspacesCard";
  }

  const api = useAPI();
  const checkboxManager = useCheckboxManager<IWorkspaceListItem>(workspaces);

  useEffect(() => {
    const getWorkspaceList = async () => {
      const { status, payload } = await api.callAPI(
        RequestType.Get,
        "/workspaces",
        "Failed to get workspace list due to the following error:",
      );
      if (status === 200) {
        const list = payload as IWorkspaceList;
        setWorkspaces(list.workspaces as IWorkspaceListItem[]);
      }
      setLoadingWorkspaces(false);
    };
    getWorkspacePermissions();
    getWorkspaceList();
  }, []); // eslint-disable-line

  // Update workspace permissions and the list of restricted names anytime the list of workspaces updates
  useEffect(() => {
    const fetchRestrictedNames = async () => {
      const names = await getRestrictedNames({
        existingWorkspaces: workspaces,
      });
      setUnavailableNames(names ?? []);
    };

    fetchRestrictedNames();
    getWorkspacePermissions();
  }, [workspaces]); // eslint-disable-line

  const workspaceModals = useWorkspaceModals(
    workspaces,
    setWorkspaces,
    unavailableNames,
  );

  // Alphabetize workspaces and map them to objects that FilterList can process correctly
  const alphabetizedWorkspacesArray: IWorkspace[] =
    sortByNameOrAlias(workspaces);
  const [filteredCards, setFilteredCards] = useState(
    alphabetizedWorkspacesArray,
  );

  // Filter workspace cards
  useEffect(() => {
    let cardsToDisplay = alphabetizedWorkspacesArray;
    if (textFilter) {
      cardsToDisplay = cardsToDisplay.filter((card: any) =>
        card.name.toLowerCase().includes(textFilter.toLowerCase()),
      );
    }
    setFilteredCards(cardsToDisplay);
  }, [alphabetizedWorkspacesArray, textFilter]);

  const getWorkspacePermissions = async () => {
    const { status, payload } = await api.callAPI(
      RequestType.Get,
      `/users/${user.Self.id}`,
      "Failed to user info due to the following error:",
    );
    if (status === 200) {
      const workspacePermissions = payload.workspacePermissions;

      const isAllowed: { [key: string]: boolean } = {};

      workspacePermissions.forEach(
        (workspaceItem: { workspaceId: string; opsAllowed: number }) => {
          isAllowed[workspaceItem.workspaceId] =
            (PermissionFlag.SELECT & workspaceItem.opsAllowed) > 0 ||
            (PermissionFlag.INSERT & workspaceItem.opsAllowed) > 0 ||
            (PermissionFlag.UPDATE & workspaceItem.opsAllowed) > 0 ||
            (PermissionFlag.DELETE & workspaceItem.opsAllowed) > 0 ||
            (PermissionFlag.EXECUTE & workspaceItem.opsAllowed) > 0;
        },
      );

      setWorkspacesAllowed(isAllowed);
    }
  };

  const workspacesMappedToTiles = filteredCards.map(
    (workspaceItem: IWorkspaceListItem) => {
      return {
        tile: (
          <WorkspaceRowTile
            workspace={workspaceItem}
            selectedWorkspaces={checkboxManager.selectedCheckboxes}
            toggleCheckbox={checkboxManager.toggleCheckbox}
            setWorkspaces={setWorkspaces}
            workspaces={workspaces}
            disabled={!workspacesAllowed[workspaceItem.id]}
            isAdminUser={isAdminUser}
          />
        ),
        name: workspaceItem.name,
        showToolTip: !workspacesAllowed[workspaceItem.id],
      };
    },
  );

  const workspacesMappedToGridTiles = filteredCards.map((item: IWorkspace) => {
    return {
      tile: (
        <WorkspaceGridTile
          workspace={item}
          selectedWorkspaces={checkboxManager.selectedCheckboxes}
          toggleCheckbox={checkboxManager.toggleCheckbox}
          setWorkspaces={setWorkspaces}
          workspaces={workspaces}
          disabled={!workspacesAllowed[item.id]}
          isAdminUser={isAdminUser}
        />
      ),
      name: item.name,
      showToolTip: !workspacesAllowed[item.id],
    };
  });

  // Deletes the row(s) that the user has selected
  const deleteSelected = (selectedRows: IWorkspaceListItem[]) => {
    const selectedRowsIds = selectedRows.map(
      (workspace: IWorkspaceListItem) => {
        return workspace.id;
      },
    );
    if (selectedRows.length === 1) {
      workspaceModals.deleteWorkspaceConfirmationModal(selectedRows[0]);
    } else {
      workspaceModals.batchDeleteWorkspaceConfirmationModal(selectedRowsIds);
    }
  };

  // If there are no rows to display, render the correct empty message
  function renderEmptyMessage() {
    const emptyMessage =
      filteredCards.length === 0
        ? EmptyMessages.WorkspacesNoData // There is no data to display
        : EmptyMessages.WorkspacesNoMatches; // There is data, but it does not match the filters

    return filteredCards.length === 0 ? (
      <div className="empty-message">{emptyMessage}</div>
    ) : null;
  }

  if (loadingWorkspaces || pendingFetch) return <Loader />;

  return (
    <Card className="workspaces-card">
      <span className={getComponentName()}>
        <Row className="workspaces-title-section">
          <Col className="workspaces-title ps-0 mb-0">Workspaces</Col>
          {isAdminUser ? (
            <Col className="workspaces-button-section">
              <CDataButton
                buttonType={ButtonType.Secondary}
                onClick={() =>
                  deleteSelected(checkboxManager.selectedCheckboxes)
                }
                className={
                  checkboxManager.selectedCheckboxes.length > 0
                    ? "ms-1"
                    : "ms-1 no-pointer-event"
                }
                disabled={!(checkboxManager.selectedCheckboxes.length > 0)}
              >
                <i className="fa fa-times icon no-pointer-event" />
                Delete
              </CDataButton>
              <CDataButton
                buttonType={ButtonType.Primary}
                onClick={async () => {
                  await workspaceModals.createWorkspaceConfirmation();
                }}
                className="ms-1"
              >
                <i className="fa fa-plus icon no-pointer-event" />
                Add
              </CDataButton>
            </Col>
          ) : null}
        </Row>
        <SearchBar textFilter={textFilter} setTextFilter={setTextFilter} />
        <WorkspaceRibbon
          setViewMode={setViewMode}
          toggleSelectAll={checkboxManager.toggleSelectAll}
          selectedDataObjects={checkboxManager.selectedCheckboxes}
          listOfDataObjects={workspaces}
          displayViewButtons={true}
          displayAssetCount={false}
          viewMode={viewMode}
        />
        {renderEmptyMessage()}
        {viewMode === ViewMode.List ? (
          <ListContainer listOfTiles={workspacesMappedToTiles} />
        ) : (
          <GridContainer
            workspacesMappedToTilesGrid={workspacesMappedToGridTiles}
          />
        )}
      </span>
    </Card>
  );
};

export default WorkspacesCard;
