import { Col } from "reactstrap";
import { CDataTypography } from "../../../../../components/text/CDataTypography";
import { IQueryFilterProps } from "../types/IQueryFilterProps";
import { useEffect } from "react";
import { DataType } from "../../../../../models";
import { CDataDatePicker } from "../../../../../components/datepicker/CDataDatePicker";
import { format, parseISO } from "date-fns";
import { produce } from "immer";
import { useFormik } from "formik";
import * as Yup from "yup";
import { YupRequiredSchema } from "../../../../../utility/types/yupHelperTypes";

const validationSchema = Yup.object<YupRequiredSchema<IDateBetween>>({
  start: Yup.date()
    .nullable()
    .required("Start date is required")
    .when("end", (endDate) => {
      const validation = Yup.date()
        .nullable()
        .required("Start date is required")
        .typeError("Start date is required");
      if (endDate) {
        return validation.max(
          endDate,
          "Start date must come before the end date.",
        );
      }

      return validation;
    }),
  end: Yup.date().nullable().required("End date is required"),
});

export function FilterDateBetween(props: IQueryFilterProps) {
  const {
    queryFilter,
    setQueryFilter,
    setIsConfirmButtonDisabled,
    isDisabled,
  } = props;

  const initialData = queryFilter.data as IDateBetween;

  const showTimePicker = queryFilter.column.dataType === DataType.TIMESTAMP;

  function onSaveDates(formData: IDateBetween) {
    const updatedFilter = produce(queryFilter, (draft) => {
      draft.data = formData;
    });
    setQueryFilter(updatedFilter);
  }

  const formik = useFormik({
    initialValues: initialData,
    validationSchema: validationSchema,
    validateOnMount: true,
    onSubmit: (formData) => {
      onSaveDates(formData);
    },
  });

  useEffect(() => {
    setIsConfirmButtonDisabled(!formik.isValid);
  }, [formik.isValid]); // eslint-disable-line

  // Whenever the form changes, updated the parent values.
  useEffect(() => {
    setQueryFilter(
      produce(queryFilter, (draft) => {
        draft.data = formik.values;
      }),
    );
  }, [formik.values]); // eslint-disable-line

  function dateToString(newDate: Date | undefined) {
    if (newDate == null) {
      return undefined;
    }

    let dateString: string;
    if (showTimePicker) {
      dateString =
        format(newDate, "yyyy-MM-dd") + "T" + format(newDate, "HH:mm:ss");
    } else {
      dateString = format(newDate, "yyyy-MM-dd");
    }

    return dateString;
  }

  return (
    <Col className="filterDateBetween ps-0">
      <div className="datePicker-container">
        <CDataDatePicker
          date={
            formik.values.start != null && formik.values.start.length > 0
              ? parseISO(formik.values.start)
              : undefined
          }
          showTimePicker={showTimePicker}
          onChange={(newDate) => {
            formik.setFieldValue("start", dateToString(newDate), true);
          }}
          selectProps={{
            sx: {
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            },
            name: "start",
            // The timeout here is terrible, but the blur fires when clicking the date picker
            // and the validation messages incorrectly show up too early without it.
            onBlur: (event) => setTimeout(() => formik.handleBlur(event), 100),
            disabled: isDisabled,
            isInvalid: formik.touched.start && !!formik.errors.start,
          }}
        />
        {formik.touched.start && !!formik.errors.start && (
          <CDataTypography
            variant="typography-variant-helper-text"
            color="typography-color-danger"
          >
            {formik.errors.start}
          </CDataTypography>
        )}
      </div>
      <div className="andText-container">and</div>
      <div className="datePicker-container">
        <CDataDatePicker
          date={
            formik.values.end != null && formik.values.end.length > 0
              ? parseISO(formik.values.end)
              : undefined
          }
          showTimePicker={showTimePicker}
          onChange={(newDate) => {
            formik.setFieldValue("end", dateToString(newDate), true);
          }}
          selectProps={{
            name: "end",
            // The timeout here is terrible, but the blur fires when clicking the date picker
            // and the validation messages incorrectly show up too early without it.
            onBlur: (event) => setTimeout(() => formik.handleBlur(event), 100),
            disabled: isDisabled,
            isInvalid: formik.touched.end && !!formik.errors.end,
          }}
        />
        {formik.touched.end && !!formik.errors.end && (
          <CDataTypography
            variant="typography-variant-helper-text"
            color="typography-color-danger"
          >
            {formik.errors.end}
          </CDataTypography>
        )}
      </div>
    </Col>
  );
}

type IDateBetween = {
  start: string | undefined;
  end: string | undefined;
};
