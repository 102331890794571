import React, { ReactNode, useEffect, useState } from "react";
import { createPortal } from "react-dom";

import { ModalFooter } from "reactstrap";

export interface IStepBodyStepFooter {
  children?: ReactNode;
}

/**
 * Renders a modal footer to be used in the stepper.
 * Note that this component is portaled into the modal itself so you have to be careful with CSS selectors.
 */
export function StepWizardStepFooter(props: IStepBodyStepFooter) {
  const [modalIsRendered, setModalIsRendered] = useState(false);

  // We need to portal the footer to the modal to make sure it is in the correct location.
  // Wait until the modal has rendered and then use createPortal to move this.
  useEffect(() => {
    setModalIsRendered(true);
  }, []);

  if (!modalIsRendered) {
    return <></>;
  }

  return createPortal(
    <ModalFooter
      data-testid="modal-footer"
      className="p-3 justify-content-between"
    >
      {props.children}
    </ModalFooter>,
    document.querySelector(".step-wizard")!,
  );
}
