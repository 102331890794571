import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  forwardRef,
  useEffect,
  useState,
} from "react";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
  RowData,
} from "@tanstack/react-table";
import DraggableRow from "../../../../components/tables/DraggableRow";
import Select from "../../../../components/select/Select";
import { FormFeedback, Input, Button } from "reactstrap";
import { Form, Formik, FormikProps } from "formik";
import { Column, ITableSettings, PseudoColumn } from "../SetTable";
import * as Yup from "yup";
import {
  COLUMN_NAME_CHARACTERS_INVALID,
  COLUMN_NAME_LENGTH_INVALID,
  dummyTableSubmit,
  TABLE_COLUMN_NAME_REGEX,
} from "../SetTableValidationDictionary";
import {
  generateInitialValues,
  generateValidationSchema,
} from "./FormikHelpers";
import { SET_TABLE_CONSTANTS as Constants } from "../../ApiConnector.constants";
import { CDataModalV2 } from "../../../../components/modal/CDataModalV2";
import { CheckMarkIcon } from "../../../../components/CheckMarkIcon";
import { getPerRowValue } from "./PerRowRequestHelper";

declare module "@tanstack/react-table" {
  // eslint-disable-next-line
  interface TableMeta<TData extends RowData> {
    updateData: (rowIndex: number, columnId: string, value: any) => void;
  }
}

export interface IInputTableProps {
  columns: ColumnDef<Column>[];
  data: Column[];
  emptyRow: Column;
  setData: Dispatch<SetStateAction<Column[]>>;
  selectOptions?: any[];
  childTableEnabled: boolean;
  setUnsavedChanges: (unsavedChanges: boolean) => void;
  columnsNeedRegeneration: boolean;
  setColumnsNeedRegeneration: (columnsNeedRegeneration: boolean) => void;
  tableSettings: ITableSettings;
  pseudoColumnsRef: MutableRefObject<FormikProps<ITableSettings>>;
}

const ColumnTable = forwardRef((props: IInputTableProps, columnsRef: any) => {
  const columns = props.columns;
  const [initialValues, setInitialValues] = useState({});
  const [validationSchema, setValidationSchema] = useState(
    Yup.object().shape({}),
  );
  const [warningModalRowIndex, setWarningModalRowIndex] = useState<
    number | null
  >(null);

  useEffect(() => {
    generateFormikParameters(props.data);
  }, []); // eslint-disable-line

  // Regenerate the formik parameters if the configure wizard is completed.
  // The props.data dependency ensures it doesn't fire until the data updates.
  useEffect(() => {
    if (props.columnsNeedRegeneration) {
      generateFormikParameters(props.data);
      props.setColumnsNeedRegeneration(false);
    }
  }, [props.data]); // eslint-disable-line

  const fieldNames: string[] = [
    Constants.COLUMN_NAME,
    Constants.PATH,
    Constants.DATA_TYPE,
  ];

  function generateFormikParameters(data: Column[]) {
    setInitialValues(generateInitialValues(data, fieldNames));
    setValidationSchema(generateValidationSchema(data, generateColumnSchema));
  }

  function validateDuplicatePseudoColumnName(columnName: string | undefined) {
    const pseudoColumnFormValues = props.pseudoColumnsRef.current?.values;
    const pseudoColumns: string[] = pseudoColumnFormValues?.pseudoColumns?.map(
      (pcol: PseudoColumn) => pcol.pseudoColumnName,
    );
    let notDuplicate = true;
    pseudoColumns?.forEach((pcol) => {
      if (pcol === columnName) {
        notDuplicate = false;
      }
    });
    return notDuplicate;
  }

  function generateColumnSchema(column: Column, index: number) {
    const schema = Yup.object().shape({
      [`${Constants.COLUMN_NAME}${index}`]: Yup.string()
        .required("Column name is required.")
        .min(1, COLUMN_NAME_LENGTH_INVALID)
        .max(64, COLUMN_NAME_LENGTH_INVALID)
        .matches(TABLE_COLUMN_NAME_REGEX, COLUMN_NAME_CHARACTERS_INVALID)
        .test(
          "duplicate pseudo column",
          "A pseudo column already exists with this name.",
          validateDuplicatePseudoColumnName,
        ),
      [`${Constants.PATH}${index}`]: Yup.string().required("Path is required."),
      [`${Constants.DATA_TYPE}${index}`]: Yup.number()
        .required("Please select a data type.")
        .moreThan(0, "Please select a data type."),
    });
    return schema;
  }

  const defaultColumn: Partial<ColumnDef<Column>> = {
    // TODO: CLOUD-12779: Address lint issues with calling hooks inside of cell. The linter thinks is not a React component due to it's name
    cell: ({ getValue, row: { index, original }, column: { id }, table }) => {
      const perRowColumns = getPerRowValue(props.tableSettings.requestUrl);
      const isParentRef = perRowColumns.has(original.columnName);
      const initialValue = getValue();

      // We need to keep and update the state of the cell normally
      const [value, setValue] = useState(initialValue); // eslint-disable-line

      const fieldName: string = id.toString() + index.toString();

      // Update Formik validation values when a form value changes. Has to be a useEffect thanks to Tanstack.
      // eslint-disable-next-line
      useEffect(() => {
        columnsRef.current?.setFieldValue(fieldName, value, true);
      }, [value, fieldName]);

      const tanstackOnBlur = () => {
        table.options.meta?.updateData(index, id, value);
      };

      // If the initialValue is changed externally, sync it up with our state
      // eslint-disable-next-line
      useEffect(() => {
        setValue(initialValue);
      }, [initialValue]);

      let returnValue;

      if (id === "dataType") {
        returnValue = (
          <>
            <Select
              value={value as string}
              name={fieldName}
              options={props.selectOptions ?? []}
              onChange={(value: string) => {
                setValue(value);
              }}
              onBlur={(e) => {
                tanstackOnBlur();
                columnsRef.current?.handleBlur(e);
              }}
              invalid={Boolean(
                columnsRef.current?.errors[
                  fieldName as keyof typeof columnsRef.current.errors
                ] &&
                  columnsRef.current?.touched[
                    fieldName as keyof typeof columnsRef.current.touched
                  ],
              )}
            />
            <FormFeedback type="invalid">
              {
                columnsRef.current?.errors[
                  fieldName as keyof typeof columnsRef.current.errors
                ]
              }
            </FormFeedback>
          </>
        );
      } else if (id === "primaryKey") {
        returnValue = (
          <Input
            className="primary-key-checkbox"
            key={"primaryKey" + index}
            name="primaryKey"
            type="checkbox"
            checked={!!value}
            onChange={(e: any) => {
              setValue(e.target.checked);
            }}
            onBlur={tanstackOnBlur}
          />
        );
      } else if (id === "columnName") {
        const invalidField = Boolean(
          columnsRef.current?.errors[
            fieldName as keyof typeof columnsRef.current.errors
          ] &&
            columnsRef.current?.touched[
              fieldName as keyof typeof columnsRef.current.touched
            ],
        );

        returnValue = (
          <div
            className={
              "input-with-icon" + (invalidField ? " input-with-icon-error" : "")
            }
          >
            <div className="input-container">
              <Input
                name={fieldName}
                key={index.toString() + id.toString()}
                value={value as string}
                onChange={(e) => {
                  setValue(e.target.value);
                }}
                onBlur={(e) => {
                  tanstackOnBlur();
                  columnsRef.current?.handleBlur(e);
                }}
                invalid={invalidField}
                className="form-control"
              />
              {isParentRef && (
                <CheckMarkIcon
                  tooltipMessage="Unique record identifier column."
                  className="checkmark-icon-column"
                  iconId={`check-mark-icon-${index}`}
                />
              )}
            </div>
            <FormFeedback type="invalid">
              {
                columnsRef.current?.errors[
                  fieldName as keyof typeof columnsRef.current.errors
                ]
              }
            </FormFeedback>
          </div>
        );
      } else {
        returnValue = (
          <>
            <Input
              name={fieldName}
              disabled={id === "path"}
              key={index.toString() + id.toString()}
              value={value as string}
              onChange={(e) => {
                setValue(e.target.value);
              }}
              onBlur={(e) => {
                tanstackOnBlur();
                columnsRef.current?.handleBlur(e);
              }}
              invalid={Boolean(
                columnsRef.current?.errors[
                  fieldName as keyof typeof columnsRef.current.errors
                ] &&
                  columnsRef.current?.touched[
                    fieldName as keyof typeof columnsRef.current.touched
                  ],
              )}
              className="form-control"
            />
            <FormFeedback type="invalid">
              {
                columnsRef.current?.errors[
                  fieldName as keyof typeof columnsRef.current.errors
                ]
              }
            </FormFeedback>
          </>
        );
      }
      return returnValue;
    },
  };

  // After reordering the row its index gets changed here
  const reorderRow = (draggedRowIndex: number, targetRowIndex: number) => {
    const reorderedData = [...props.data];
    reorderedData.splice(
      targetRowIndex,
      0,
      reorderedData.splice(draggedRowIndex, 1)[0],
    );
    generateFormikParameters(reorderedData);
    props.setData(reorderedData);
    props.setUnsavedChanges(true);
  };

  const data = props.data;

  const table = useReactTable({
    data,
    columns,
    defaultColumn,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      updateData: (rowIndex: any, columnId: any, value: any) => {
        props.setUnsavedChanges(true);
        props.setData((old: any) =>
          old.map((row: any, index: number) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex]!,
                [columnId]: value,
              };
            }
            return row;
          }),
        );
      },
    },
  });

  function onDeleteRow(rowIndex: number) {
    const deletedColumn = props.tableSettings.columns[rowIndex];
    if (deletedColumn == null) {
      return;
    }
    const matchingFilter = props.tableSettings.filters.find(
      (f) => f.columnName === deletedColumn.columnName,
    );

    if (matchingFilter == null) {
      deleteColumnFromTableSettings(rowIndex);
    } else {
      setWarningModalRowIndex(rowIndex);
    }
  }

  const deleteColumnFromTableSettings = (index: number | null) => {
    if (index === null) return;

    const newData = [...props.data];
    newData.splice(index, 1);

    generateFormikParameters(newData);
    props.setData(newData);
  };

  const deleteWarningModal = (
    <CDataModalV2
      fade={false}
      modalSize="lg"
      displayed={warningModalRowIndex !== null}
      close={() => {
        setWarningModalRowIndex(null);
      }}
      title="Delete Column"
      spacedFooter={false}
      primaryButton={
        <Button
          color="danger"
          onClick={() => {
            deleteColumnFromTableSettings(warningModalRowIndex);
            setWarningModalRowIndex(null);
          }}
        >
          Confirm
        </Button>
      }
      secondaryButton={
        <Button
          color="secondary"
          onClick={() => {
            setWarningModalRowIndex(null);
          }}
        >
          Cancel
        </Button>
      }
    >
      {
        "Deleting this column will also remove any linked filters you have configured for it. Are you sure you want to proceed?"
      }
    </CDataModalV2>
  );

  return (
    <>
      <Formik
        innerRef={columnsRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount={true}
        validateOnChange={true}
        onSubmit={dummyTableSubmit}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <table className="column-table">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th
                        className={"column-table-header pb-2"}
                        key={header.id}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row, index) => (
                  <DraggableRow
                    disableDrag
                    data={data}
                    key={row.id}
                    row={row}
                    rowIndex={index}
                    reorderRow={reorderRow}
                    deleteRow={onDeleteRow}
                  />
                ))}
              </tbody>
            </table>
          </Form>
        )}
      </Formik>
      {deleteWarningModal}
    </>
  );
});
ColumnTable.displayName = "ColumnTable";

export default ColumnTable;
