export enum QueryTabType {
  Home = "home",
  DerivedView = "derivedView",
  EditDerivedView = "editDerivedView",
  SavedQuery = "savedQuery",
  ScheduledQuery = "scheduledQuery",
  Query = "query",
  Workspace = "workspace",
  QueryBuilder = "queryBuilder",
  View = "VIEW",
  Table = "TABLE",
}
