import { IWorkspace } from "../../../models";
import {
  Dispatch,
  ForwardedRef,
  forwardRef,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Button,
  Card,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  FormFeedback,
} from "reactstrap";
import { RequestType } from "../../../components/withAPI";
import { ToastrSuccess } from "../../../services/toastrService";
import {
  EndpointModalOptions,
  WorkspaceEditEndpointsModal,
} from "./WorkspaceEditEndpointsModal";
import { Formik, Form, FormikProps, FormikErrors } from "formik";
import * as Yup from "yup";
import { useAPI } from "../../../components/useAPI";
import { AuthorizeContext } from "../../../components/guards/UserAuthenticationGuard";
import { UserRole } from "../../../models/Users/UserRole";
import { useNameAvailabilityCheck } from "../../../hooks/useNameAvailabilityCheck";

interface IWorkspaceEditPaneProps {
  workspaceDetails: IWorkspace;
  setWorkspaceDetails: Dispatch<SetStateAction<IWorkspace | null>>;
  setIsProcessing: (isProcessing: boolean) => void;
  setFormState: (params: {
    dirty: boolean;
    hasWorkspaceNameError: boolean;
  }) => void;
}

export type IWorkspacePaneFormValues = {
  WorkspaceName: string;
  Description: string;
};

const WorkspaceEditPane = forwardRef(
  (
    props: IWorkspaceEditPaneProps,
    ref: ForwardedRef<FormikProps<IWorkspacePaneFormValues>>,
  ) => {
    const userAccount = useContext(AuthorizeContext);
    const isAdminUser = userAccount && userAccount.role === UserRole.Admin;
    const api = useAPI();
    const { getRestrictedNames } = useNameAvailabilityCheck();

    const [isModalDisplayed, setIsModalDisplayed] = useState<boolean>(false);
    const [unavailableNames, setUnavailableNames] = useState<string[]>([]);

    const updateParentFormState = (
      dirty: boolean,
      errors: FormikErrors<IWorkspacePaneFormValues>,
    ) => {
      props.setFormState({
        dirty: dirty,
        hasWorkspaceNameError: !!errors?.WorkspaceName,
      });
    };

    useEffect(() => {
      const fetchRestrictedNames = async () => {
        const names = await getRestrictedNames({
          excludedName: props.workspaceDetails.name,
        });
        setUnavailableNames(names ?? []);
      };

      fetchRestrictedNames();
    }, []); // eslint-disable-line

    const endpointsModal = (
      <WorkspaceEditEndpointsModal
        endpoints={[
          EndpointModalOptions.VirtualSQLServer,
          EndpointModalOptions.OData,
        ]}
        workspaceName={props.workspaceDetails.name!}
        workspaceId={props.workspaceDetails.id}
        isDisplayed={isModalDisplayed}
        setIsDisplayed={setIsModalDisplayed}
      />
    );

    const handleSave = async (values: IWorkspacePaneFormValues) => {
      const data = {
        name: values.WorkspaceName,
        description: values.Description,
      };

      const url = `/workspaces/${props.workspaceDetails.id}`;
      const { status } = await api.callAPI(
        RequestType.Put,
        url,
        "Failed to update workspace due to the following error:",
        data,
      );
      if (status === 200) {
        props.setWorkspaceDetails((prevWorkspaceDetails: IWorkspace | null) => {
          const newWorkspaceDetails = { ...prevWorkspaceDetails };
          newWorkspaceDetails.name = values.WorkspaceName;
          newWorkspaceDetails.description = values.Description;
          return newWorkspaceDetails as IWorkspace;
        });

        ToastrSuccess(
          "Workspace successfully saved",
          `${values.WorkspaceName} was successfully updated.`,
        );
      }
    };

    function renderWorkspaceEditPane() {
      const initialFormValues: IWorkspacePaneFormValues = {
        WorkspaceName: props.workspaceDetails?.name || "",
        Description: props.workspaceDetails?.description || "",
      };

      const validationSchema = Yup.object().shape({
        WorkspaceName: Yup.string()
          .matches(
            /^\w[\w_.() -]{0,99}(?<! )$/,
            "Workspace name must only consist of letters, numbers, underscores, hyphens, spaces, and are limited to 100 characters.",
          )
          .required("Please enter a Workspace name")
          .test(
            "unique-name",
            "This name is already used in a Connection or Workspace.",
            function (value) {
              return !unavailableNames?.some(
                (name) => name?.toLowerCase() === value?.toLowerCase(),
              );
            },
          ),
      });

      return (
        <Card className="no-shadow">
          <Formik
            initialValues={initialFormValues}
            validationSchema={validationSchema}
            onSubmit={handleSave}
            enableReinitialize
            innerRef={ref}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              dirty,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Row className="side-pane-title mb-3">
                  <Col>Configure</Col>
                </Row>
                <FormGroup>
                  <Label for="workspaceName" className="required">
                    Workspace Name
                  </Label>
                  <Input
                    required
                    id="workspaceName"
                    type="text"
                    name="WorkspaceName"
                    value={values.WorkspaceName}
                    invalid={Boolean(
                      touched.WorkspaceName && errors.WorkspaceName,
                    )}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    data-testid="workspace-name-field"
                    disabled={!isAdminUser}
                    onKeyUp={() => {
                      updateParentFormState(dirty, errors);
                    }}
                  />
                  {!!touched.WorkspaceName && (
                    <FormFeedback type="invalid">
                      {errors.WorkspaceName?.toString()}
                    </FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="description">Description</Label>
                  <Input
                    id="description"
                    name="Description"
                    type="textarea"
                    value={values.Description}
                    onChange={handleChange}
                    data-testid="description-field"
                    disabled={!isAdminUser}
                    onKeyUp={() => {
                      updateParentFormState(dirty, errors);
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label className="align-center gap-1 bold-text">
                    Endpoints
                  </Label>
                  <div>
                    <Label>Virtual SQL Server</Label>
                  </div>
                  <div>
                    <Label>OData</Label>
                  </div>
                  <div>
                    <Label>OpenAPI</Label>
                  </div>
                  <Button
                    onClick={() => setIsModalDisplayed(true)}
                    className="view-endpoints-button btn-outline-primary align-center gap-2"
                    data-testid="button-view-endpoints"
                  >
                    <i className="fa fa-solid fa-eye fa-sm" /> View Endpoints
                  </Button>
                </FormGroup>

                {endpointsModal}
              </Form>
            )}
          </Formik>
        </Card>
      );
    }

    return <>{renderWorkspaceEditPane()}</>;
  },
);
WorkspaceEditPane.displayName = "WorkspaceEditPane";

export default WorkspaceEditPane;
