import { useContext, useState } from "react";
import { ConnectionContext } from "./ConnectionContext";
import { addWhiteSpace } from "../../../services/textFormatterService";
import { ColumnDef } from "@tanstack/react-table";
import {
  ButtonType,
  CDataButton,
} from "../../../components/buttons/CDataButton";
import classNames from "classnames";
import { CDataModalV2 } from "../../../components/modal/CDataModalV2";
import ListTable from "../../../components/tables/ListTable";

interface ICustomReportPickReportTypeModalProps {
  reportTypes: string[];
  isModalOpen: boolean;
  onCloseModal: () => void;
  onSelectReportType: (reportType: string) => void;
}

export const CustomReportPickReportTypeModal = (
  props: ICustomReportPickReportTypeModalProps,
) => {
  const {
    reportTypes,
    isModalOpen,
    onCloseModal,
    onSelectReportType: confirmRowSelection,
  } = props;

  const [selectedReportType, setSelectedReportType] = useState<string>("");

  const connectionContext = useContext(ConnectionContext);

  type ITableReportType = {
    displayName: string;
    reportType: string;
  };

  const reportChoices = reportTypes.map<ITableReportType>((reportType) => {
    return {
      displayName: addWhiteSpace(reportType),
      reportType: reportType,
    };
  });

  const columns: ColumnDef<ITableReportType>[] = [
    {
      accessorKey: "displayName",
      id: "displayName",
      header: "",
      enableSorting: false,
      cell: (cellContext) => {
        const row = cellContext.row.original;
        return (
          <CDataButton
            className={classNames(
              "pick-report-type-button",
              selectedReportType === row.reportType
                ? "is-selected-button"
                : null,
            )}
            buttonType={ButtonType.Borderless}
            onClick={() => setSelectedReportType(row.reportType)}
          >
            {cellContext.row.original.displayName}
          </CDataButton>
        );
      },
    },
  ];

  return (
    <CDataModalV2
      title="Add Custom Report"
      displayed={isModalOpen}
      close={onCloseModal}
      primaryButton={
        <CDataButton
          disabled={!selectedReportType}
          buttonType={ButtonType.Primary}
          onClick={() => confirmRowSelection(selectedReportType)}
          data-testid="button-confirm-row-selection"
        >
          {connectionContext.isInitialSetupPage ? "Confirm" : "Next"}
        </CDataButton>
      }
      secondaryButton={
        <CDataButton
          buttonType={ButtonType.Secondary}
          onClick={onCloseModal}
          data-testid="button-cancel-add-custom-report"
        >
          Cancel
        </CDataButton>
      }
    >
      <div className="reports-options">
        <p>Select a report type from the list below.</p>

        <ListTable
          data={reportChoices}
          columns={columns}
          emptyTableMessage="No Results"
          enableCheckboxes={false}
          enableFiltering
          enablePagination={false}
          searchPlaceholder="Search by report..."
          onRowClick={(row) => setSelectedReportType(row.reportType)}
          defaultSort={[
            {
              id: "displayName",
              desc: false,
            },
          ]}
        />
      </div>
    </CDataModalV2>
  );
};
