import { useContext, useEffect, useState } from "react";

import { Button, Col, Row } from "reactstrap";
import { EmptyMessages } from "../../../components/IFilterList";
import {
  IMapDatasetsTilesForFilterList,
  mapAssetsToTiles,
  NameType,
} from "../components/DatasetsTileFactory";
import { RequestType } from "../../../components/withAPI";
import { useParams } from "react-router-dom";
import { IAssetList, IAssetListItem } from "../../../models";
import AddAssetWizard from "../components/AddAssetWizard/AddAssetWizard";
import WorkspaceRibbon from "../../../components/WorkspaceRibbon";
import { SearchBar } from "../components/SearchBar";
import { DatasetsTileTypes } from "../components/AssetRowTile";
import ListContainer from "../components/ListContainer";
import { MoveAssetModal } from "../components/MoveAssetModal";
import { useCheckboxManager } from "../../../components/useCheckboxManager";
import { useAPI } from "../../../components/useAPI";
import { useAssetModals } from "../../../hooks/useAssetModals";
import { sortAssetsByAlias } from "../components/ObjectSorter";
import { IFolderAsset } from "../../../models/Datasets/IFolderAsset";
import { AuthorizeContext } from "../../../components/guards/UserAuthenticationGuard";
import { UserRole } from "../../../models/Users/UserRole";
import { IErrorTableRow } from "../../../components/ErrorTable";

interface EditFolderCardProps {
  folderName?: string;
  setDeleteAssetErrors: (
    updatedBulkDeleteFailedMessages: IErrorTableRow[],
  ) => void;
}

const EditFolderCard = (props: EditFolderCardProps) => {
  const userAccount = useContext(AuthorizeContext);
  const isAdminUser = userAccount && userAccount.role === UserRole.Admin;

  const [deleteButtonEnabled, setDeleteButtonEnabled] =
    useState<boolean>(false);
  const [assetList, setAssetList] = useState<IAssetListItem[]>([]);
  const [filteredList, setFilteredCards] = useState(assetList);
  const [isAssetWizardDisplayed, setIsAssetWizardDisplayed] =
    useState<boolean>(false);
  const [moveFolderId, setMoveFolderId] = useState<string>();
  const [moveAssetId, setMoveAssetId] = useState<string>();
  const [isMoveAssetModalOpen, setMoveAssetModal] = useState<boolean>(false);
  const [selectedMoveName, setSelectedMoveName] = useState<string>();
  const [folderDetails, setFolderDetails] = useState<IFolderAsset>();

  const { folderId } = useParams();
  const { workspaceId } = useParams();

  const [textFilter, setTextFilter] = useState<string>("");

  const checkboxManager = useCheckboxManager<IAssetListItem>(assetList);

  const api = useAPI();
  const assetModals = useAssetModals();

  useEffect(() => {
    const getFolderChildren = async () => {
      const { status, payload } = await api.callAPI(
        RequestType.Get,
        `/workspaces/${workspaceId}/folders/${folderId}/children`,
        "Failed to get folder items",
      );
      if (status === 200) {
        const list = payload as IAssetList;
        sortAssetsByAlias(list.assets ?? []);
        setAssetList(list.assets!);
      }
    };
    getFolderChildren();
  }, [props.folderName]); // eslint-disable-line

  useEffect(() => {
    const getFolder = async () => {
      const { status, payload } = await api.callAPI(
        RequestType.Get,
        `/workspaces/${workspaceId}/folders/${folderId}`,
        "Error fetching folder.",
      );

      if (status === 200) {
        const folder = payload as IFolderAsset;
        setFolderDetails(folder);
      }
    };

    getFolder();
  }, [props.folderName]); // eslint-disable-line

  function getComponentName() {
    return "pages-datasets-EditFolderCard";
  }

  // Updates the Delete button when rows are selected and deselected
  useEffect(() => {
    setDeleteButtonEnabled(checkboxManager.selectedCheckboxes.length > 0);
  }, [checkboxManager.selectedCheckboxes]);

  const assetListTiles = mapAssetsToTiles({
    dataObjects: filteredList,
    datasetsTileType: DatasetsTileTypes.FolderAsset,
    selectedDataObjects: checkboxManager.selectedCheckboxes,
    toggleCheckbox: checkboxManager.toggleCheckbox,
    nameType: NameType.Alias,
    onSuccessfulDelete: onWorkspaceDeletedSuccessfully,
    openMoveAssetModal: openMoveAssetModal,
  } as IMapDatasetsTilesForFilterList<IAssetListItem>);

  // If there are no rows to display, render the correct empty message
  function renderEmptyMessage() {
    const emptyMessage =
      assetList.length === 0
        ? EmptyMessages.FolderNoData // There is no data to display
        : EmptyMessages.AssetsNoMatches; // There is data, but it does not match the filters

    return assetList.length === 0 ? (
      <div className="empty-message">{emptyMessage}</div>
    ) : null;
  }

  //filter folder cards
  useEffect(() => {
    let cardsToDisplay = assetList;
    if (textFilter && assetList) {
      cardsToDisplay = cardsToDisplay.filter((card: IAssetListItem) =>
        card.alias?.toLowerCase().includes(textFilter.toLowerCase()),
      );
    }
    setFilteredCards(cardsToDisplay);
  }, [assetList, textFilter]);

  function toggleAddAssetModal() {
    setIsAssetWizardDisplayed(!isAssetWizardDisplayed);
  }

  async function openMoveAssetModal(
    folderId: string,
    assetId?: string,
    name?: string,
  ) {
    setMoveFolderId(folderId);
    setSelectedMoveName(name);
    setMoveAssetId(assetId);
    setMoveAssetModal(true);
  }

  function moveAsset() {
    let cardsToDisplay = assetList;
    cardsToDisplay = cardsToDisplay.filter(
      (card: IAssetListItem) =>
        card.id !== moveAssetId && card.id !== moveFolderId,
    );
    setAssetList(cardsToDisplay);
    setMoveAssetModal(false);
  }

  function onWorkspaceDeletedSuccessfully(assetId: string) {
    const newAssetList = assetList.filter((assetListItem: IAssetListItem) => {
      return assetListItem.id !== assetId;
    });
    setAssetList(newAssetList);
  }

  function onBatchAssetsDeletedSuccessfully(
    deletedAssetIds: string[],
    updatedBulkDeleteFailedMessages: IErrorTableRow[],
  ) {
    const newAssetList = assetList.filter((assetListItem: IAssetListItem) => {
      return !deletedAssetIds.find((id: string) => id === assetListItem.id);
    });
    setAssetList(newAssetList);
    props.setDeleteAssetErrors(updatedBulkDeleteFailedMessages);
  }

  return (
    <span className={getComponentName()}>
      <MoveAssetModal
        isModalOpen={isMoveAssetModalOpen}
        submitMoveSelection={moveAsset}
        setIsModalOpen={(isModalOpen: boolean) =>
          setMoveAssetModal(isModalOpen)
        }
        workspaceId={workspaceId!}
        folderId={moveFolderId!}
        assetId={moveAssetId}
        movingName={selectedMoveName!}
        currentFolder={folderId}
      />
      <Row className="edit-folders-title-section">
        <Col className="edit-folders-title ps-0 mb-0">Assets</Col>
        {isAdminUser ? (
          <Col className="edit-folders-button-section">
            <Button
              color="secondary"
              onClick={() =>
                ((selectedAssets: IAssetListItem[]) => {
                  assetModals.openBatchDeleteAssetsModal(
                    selectedAssets,
                    onBatchAssetsDeletedSuccessfully,
                  );
                })(checkboxManager.selectedCheckboxes)
              }
              className={deleteButtonEnabled ? "ms-1" : "ms-1 no-pointer-event"}
              disabled={!deleteButtonEnabled}
            >
              <i className="fa fa-times icon no-pointer-event" />
              Delete
            </Button>
            <Button
              color="primary"
              onClick={() => {
                setIsAssetWizardDisplayed(true);
              }}
              className="ms-1"
            >
              <i className="fa fa-plus icon no-pointer-event" />
              Add
            </Button>
          </Col>
        ) : null}
      </Row>
      <SearchBar textFilter={textFilter} setTextFilter={setTextFilter} />
      <WorkspaceRibbon
        toggleSelectAll={checkboxManager.toggleSelectAll}
        selectedDataObjects={checkboxManager.selectedCheckboxes}
        listOfDataObjects={assetList}
        displayViewButtons={false}
        displayAssetCount={true}
      />
      {renderEmptyMessage()}
      <ListContainer listOfTiles={assetListTiles} />
      <AddAssetWizard
        containerName={folderDetails?.name}
        toggleModal={toggleAddAssetModal}
        isDisplayed={isAssetWizardDisplayed}
        setAssetList={setAssetList}
        setAddAssertErrors={null!}
      />
    </span>
  );
};

export default EditFolderCard;
