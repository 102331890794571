import { useContext, useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import { EmptyMessages } from "../../../components/IFilterList";
import { useAPI } from "../../../components/useAPI";
import { RequestType } from "../../../components/withAPI";
import { useParams } from "react-router-dom";
import { IAssetList, IAssetListItem, UserRole } from "../../../models";
import WorkspaceRibbon from "../../../components/WorkspaceRibbon";
import {
  IMapDatasetsTilesForFilterList,
  mapAssetsToTiles,
  NameType,
} from "../components/DatasetsTileFactory";
import { SearchBar } from "../components/SearchBar";
import ListContainer from "../components/ListContainer";
import AddAssetWizard from "../components/AddAssetWizard/AddAssetWizard";
import { MoveAssetModal } from "../components/MoveAssetModal";
import { useCheckboxManager } from "../../../components/useCheckboxManager";
import { useAssetModals } from "../../../hooks/useAssetModals";
import { sortAssetsByAlias } from "../components/ObjectSorter";
import { AuthorizeContext } from "../../../components/guards/UserAuthenticationGuard";
import { IDatasetBulkAddError } from "../components/AddAssetWizard/DatasetBulkAddErrorAccordion";
import { IErrorTableRow } from "../../../components/ErrorTable";

export interface IEditWorkspaceAssetsCardProps {
  workspaceId: string;
  workspaceName: string;
  setAddAssertErrors: (errors: IDatasetBulkAddError[]) => void;
  setDeleteAssetErrors: (errors: IErrorTableRow[]) => void;
}

const EditWorkspaceCard = (props: IEditWorkspaceAssetsCardProps) => {
  const { setAddAssertErrors } = props;

  const userAccount = useContext(AuthorizeContext);
  const isAdminUser = userAccount && userAccount.role === UserRole.Admin;

  const [deleteButtonEnabled, setDeleteButtonEnabled] =
    useState<boolean>(false);
  const [assetList, setAssetList] = useState<IAssetListItem[]>([]);
  const [filteredList, setFilteredCards] = useState(assetList);
  const [isAssetWizardDisplayed, setIsAssetWizardDisplayed] =
    useState<boolean>(false);
  const [textFilter, setTextFilter] = useState<string>("");
  const [isMoveAssetModalOpen, setMoveAssetModal] = useState<boolean>(false);
  const [moveFolderId, setMoveFolderId] = useState<string>();
  const [moveAssetId, setMoveAssetId] = useState<string>();
  const [selectedMoveName, setSelectedMoveName] = useState<string>();
  const checkboxManager = useCheckboxManager<IAssetListItem>(assetList);

  const { workspaceId } = useParams();
  const api = useAPI();
  const assetModals = useAssetModals();

  useEffect(() => {
    const getWorkspaceChildren = async () => {
      const { status, payload } = await api.callAPI(
        RequestType.Get,
        `/workspaces/${workspaceId}/children`,
        "Failed to get workspace items",
      );
      if (status === 200) {
        const list = payload as IAssetList;
        sortAssetsByAlias(list.assets ?? []);
        setAssetList(list.assets ?? []);
      }
    };

    getWorkspaceChildren();
  }, []); // eslint-disable-line

  // Updates the Delete button when rows are selected and deselected
  useEffect(() => {
    setDeleteButtonEnabled(checkboxManager.selectedCheckboxes.length > 0);
  }, [checkboxManager.selectedCheckboxes]);

  const assetListTiles = mapAssetsToTiles({
    dataObjects: filteredList,
    selectedDataObjects: checkboxManager.selectedCheckboxes,
    toggleCheckbox: checkboxManager.toggleCheckbox,
    nameType: NameType.Alias,
    onSuccessfulDelete: onWorkspaceDeletedSuccessfully,
    openMoveAssetModal: openMoveAssetModal,
    workspaceId: workspaceId,
  } as IMapDatasetsTilesForFilterList<IAssetListItem>);

  function onWorkspaceDeletedSuccessfully(assetId: string) {
    const newAssetList = assetList.filter((assetListItem) => {
      return assetListItem.id !== assetId;
    });
    setAssetList(newAssetList);
  }

  function onBatchAssetsDeletedSuccessfully(
    deletedAssetIds: string[],
    updatedBulkDeleteFailedMessages: IErrorTableRow[],
  ) {
    const newAssetList = assetList.filter((assetListItem) => {
      return !deletedAssetIds.find((id: string) => id === assetListItem.id);
    });
    setAssetList(newAssetList);
    props.setDeleteAssetErrors(updatedBulkDeleteFailedMessages);
  }

  function openAddAssetModal() {
    setIsAssetWizardDisplayed(true);
  }

  function toggleAddAssetModal() {
    setIsAssetWizardDisplayed(!isAssetWizardDisplayed);
  }

  async function openMoveAssetModal(
    folderId: string,
    assetId?: string,
    name?: string,
  ) {
    setMoveFolderId(folderId);
    setSelectedMoveName(name);
    setMoveAssetId(assetId);
    setMoveAssetModal(true);
  }

  function moveAsset() {
    let cardsToDisplay = assetList;
    cardsToDisplay = cardsToDisplay.filter(
      (card: IAssetListItem) =>
        card.id !== moveAssetId && card.id !== moveFolderId,
    );
    setAssetList(cardsToDisplay);
    setMoveAssetModal(false);
  }

  //filter workspace cards
  useEffect(() => {
    let cardsToDisplay = assetList;
    if (textFilter && assetList) {
      cardsToDisplay = cardsToDisplay.filter((card: IAssetListItem) =>
        card.alias?.toLowerCase().includes(textFilter.toLowerCase()),
      );
    }
    setFilteredCards(cardsToDisplay);
  }, [assetList, textFilter]);

  function renderEmptyMessage() {
    const emptyMessage =
      assetList.length === 0
        ? EmptyMessages.AssetsNoData // There is no data to display
        : EmptyMessages.AssetsNoMatches; // There is data, but it does not match the filters

    return assetList.length === 0 ? (
      <div className="empty-message">{emptyMessage}</div>
    ) : null;
  }

  return (
    <span className="pages-datasets-EditWorkspaceCard">
      <MoveAssetModal
        isModalOpen={isMoveAssetModalOpen}
        submitMoveSelection={moveAsset}
        setIsModalOpen={(isModalOpen: boolean) =>
          setMoveAssetModal(isModalOpen)
        }
        workspaceId={workspaceId!}
        folderId={moveFolderId!}
        assetId={moveAssetId}
        movingName={selectedMoveName!}
      />
      <Row className="edit-workspaces-title-section">
        <Col className="edit-workspaces-title ps-0 mb-0">Assets</Col>
        {isAdminUser ? (
          <Col className="edit-workspaces-button-section">
            <Button
              color="secondary"
              onClick={() => {
                ((selectedAssets: IAssetListItem[]) => {
                  assetModals.openBatchDeleteAssetsModal(
                    selectedAssets,
                    onBatchAssetsDeletedSuccessfully,
                  );
                })(checkboxManager.selectedCheckboxes);
              }}
              className={deleteButtonEnabled ? "ms-1" : "ms-1 no-pointer-event"}
              disabled={!deleteButtonEnabled}
            >
              <i className="fa fa-times icon no-pointer-event" />
              Delete
            </Button>
            <Button
              color="primary"
              onClick={openAddAssetModal}
              className="ms-1"
            >
              <i className="fa fa-plus icon no-pointer-event" />
              Add
            </Button>
          </Col>
        ) : null}
      </Row>
      <SearchBar textFilter={textFilter} setTextFilter={setTextFilter} />
      <WorkspaceRibbon
        toggleSelectAll={checkboxManager.toggleSelectAll}
        selectedDataObjects={checkboxManager.selectedCheckboxes}
        listOfDataObjects={assetList}
        displayViewButtons={false}
        displayAssetCount={true}
      />
      {renderEmptyMessage()}
      <ListContainer listOfTiles={assetListTiles} />
      <AddAssetWizard
        containerName={props.workspaceName}
        toggleModal={toggleAddAssetModal}
        isDisplayed={isAssetWizardDisplayed}
        setAssetList={setAssetList}
        setAddAssertErrors={setAddAssertErrors}
      />
    </span>
  );
};

export default EditWorkspaceCard;
