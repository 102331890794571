import { useEffect, useMemo, useState } from "react";
import BootstrapTable, { ColumnDescription } from "react-bootstrap-table-next";
import filterFactory, {
  customFilter,
  CustomFilterProps,
} from "react-bootstrap-table2-filter";
import { Spinner } from "reactstrap";
import { ITableToCacheJobMap } from "../useTableToCacheJobMap";
import SchemaTablesList, { ISchemaTablesListProps } from "./SchemaTablesList";
import { useMutation } from "@tanstack/react-query";
import { ICustomReport } from "../../../../models";
import { ConnectionType } from "../../../../models/Connections/ConnectionType";
import { listCustomReports } from "../../api/listCustomReports";

export interface ISchemaListProps {
  noData: () => JSX.Element;
  connectionId: string | undefined;
  selectedDriver: string;
  filterSearch: CustomFilterProps;
  callbackSearchFilter: (props: CustomFilterProps) => void;
  tablesLoading: { [key: number]: boolean };
  schemas: string[];
  tables: { rowId: number; rowTables: ISchemaTablesListProps["tables"] }[];
  expanded: number[];
  formattedSchemas: {
    id: number;
    name: JSX.Element;
  }[];
  handleOnExpand: (
    row: { id: number; name: JSX.Element },
    isExpand: boolean,
  ) => void;
  selectedConnection: string;
  schemasLoading: boolean;
  filterSchemas: (filterVal: string, schemas: string[]) => string[];
  fetchTablesFromSchemaCallback: (
    selectedConnection: string,
    schema: string,
    rowId: number,
  ) => void;
  tablesResponseEmpty: boolean;
  filteredOutSchemas: number[];
  searchQuery: string | null;
  clickDisabled: boolean;
  disableTableClick: (disabled: boolean) => void;
  isCachingSupported: boolean;
  tableToCacheJobMap: ITableToCacheJobMap;
  connectionType: ConnectionType | undefined;
}

const SchemaList = (props: ISchemaListProps) => {
  let onSearchFilter = props.filterSearch;
  const [customReportList, setCustomReportList] =
    useState<Set<string | undefined>>();
  const schemaOptionColumns: (ColumnDescription & {
    filterRenderer?: (onFilter: CustomFilterProps) => null;
  })[] = [
    {
      dataField: "name",
      text: "",
      filter: customFilter({
        onFilter: props.filterSchemas,
      } as CustomFilterProps),
      filterRenderer: (onFilter: CustomFilterProps): null => {
        onSearchFilter = onFilter;
        props.callbackSearchFilter(onSearchFilter);
        return null;
      },
    },
    {
      dataField: "id",
      text: "",
      hidden: true,
    },
  ];

  const someTableLoading = Object.values(props.tablesLoading).some(
    (loading) => loading === true,
  );

  const handleOnExpandCallback = (
    row: { id: number; name: JSX.Element },
    isExpand: boolean,
  ) => {
    if (!someTableLoading) {
      props.handleOnExpand(row, isExpand);
    }
  };

  const renderTableBlock = useMemo(() => {
    const TableBlock = (rowId: number) => {
      const tableDataForRow = props.tables.find(
        (table) => table.rowId === rowId,
      );
      const tablesForRow = tableDataForRow ? tableDataForRow.rowTables : [];
      return (
        <SchemaTablesList
          connectionId={props.connectionId}
          connectionName={props.selectedConnection}
          selectedDriver={props.selectedDriver}
          tables={tablesForRow}
          filteredOutSchemas={props.filteredOutSchemas}
          searchQuery={props.searchQuery}
          clickDisabled={props.clickDisabled}
          disableTableClick={props.disableTableClick}
          isCachingSupported={props.isCachingSupported}
          tableToCacheJobMap={props.tableToCacheJobMap}
          customReportList={customReportList}
          connectionType={props.connectionType}
        />
      );
    };

    return TableBlock;
    // TODO: CLOUD-12840 - Add all necessary dependencies to this depenency array
  }, [props.tables, props.searchQuery, props.selectedDriver]); // eslint-disable-line

  const { mutateAsync: getCustomReportListAsync, isPending } = useMutation({
    mutationKey: ["/customReportsList"],
    mutationFn: listCustomReports,
  });

  useEffect(() => {
    async function asyncWrapper() {
      const customReportListData = await getCustomReportListAsync(
        props.connectionId!,
      );
      if (customReportListData) {
        setCustomReportList(
          new Set(
            customReportListData.customReports?.map(
              (crd: ICustomReport) => crd.name,
            ),
          ),
        );
      }
    }
    asyncWrapper();
  }, [getCustomReportListAsync, props.connectionId]);

  const isRowLoading = (rowId: number) => {
    return !!props.tablesLoading[rowId] || isPending; // Return true if the row is loading, false otherwise
  };

  const expandRow = {
    renderer: (row: any) => {
      if (isRowLoading(row.id)) {
        return (
          <div className="m-2">
            <Spinner size="sm" color="secondary" />
          </div>
        );
      } else {
        //case where a catalog has one schema, and we want to expand by default upon initial selection
        if (
          props.schemas.length === 1 &&
          props.tablesResponseEmpty === false &&
          props.tables.length === 0 &&
          props.expanded.includes(0) &&
          props.expanded.length === 1
        ) {
          const schema = props.schemas[0];
          props.fetchTablesFromSchemaCallback(
            props.selectedConnection,
            schema,
            row.id,
          );
        }
        return renderTableBlock(row.id);
      }
    },
    expanded: props.expanded,
    onExpand: handleOnExpandCallback,
    showExpandColumn: true,
    expandColumnRenderer: ({ expanded, rowKey }: any) => {
      let chevron;

      const someTableLoadingExceptCurrentRow = Object.entries(
        props.tablesLoading,
      )
        .filter(([key]) => key !== rowKey.toString())
        .some(([, loading]) => loading === true);

      const tableAlreadyFetched = props.tables.some(
        (table) => table.rowId === rowKey,
      );

      if (expanded) {
        // Check if the table has already been fetched
        if (tableAlreadyFetched) {
          chevron = (
            <i
              className="fa-regular fa-chevron-down cursor-pointer align-middle"
              data-testid="chevron-schema-list-expanded"
            ></i>
          );
        } else if (someTableLoadingExceptCurrentRow) {
          // If another table is loading, show the collapsed icon
          chevron = (
            <i
              className="fa-regular fa-chevron-right cursor-pointer align-middle"
              data-testid="chevron-schema-list-collapsed"
            ></i>
          );
        } else {
          // Default to expanded icon
          chevron = (
            <i
              className="fa-regular fa-chevron-down cursor-pointer align-middle"
              data-testid="chevron-schema-list-expanded"
            ></i>
          );
        }
      } else {
        chevron = (
          <i
            className="fa-regular fa-chevron-right cursor-pointer align-middle"
            data-testid="chevron-schema-list-collapsed"
          ></i>
        );
      }

      return <span>{chevron}</span>;
    },
    expandByColumnOnly: false,
  };
  if (!props.selectedConnection && !props.schemasLoading) {
    return (
      <div className="centered-muted-text">
        <div className="text-muted text-center">Select a connection</div>
      </div>
    );
  } else if (props.selectedConnection && props.schemasLoading) {
    return (
      <div className="loading-table-list" data-testid="schema-list-spinner">
        <Spinner color="primary" />
      </div>
    );
  } else {
    const formattedSchemas = props.formattedSchemas;
    return (
      <BootstrapTable
        bootstrap4
        classes="data-explorer-tab-list"
        id="schema-list-data-explorer"
        bordered={false}
        wrapperClasses="table-borderless px-0"
        keyField="id"
        data={formattedSchemas}
        columns={schemaOptionColumns}
        rowClasses={"schema-row"}
        hover={false}
        filter={filterFactory()}
        noDataIndication={props.noData}
        expandRow={expandRow}
      />
    );
  }
};

export default SchemaList;
