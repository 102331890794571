import { cdataFetch } from "../../../api/cdataFetch";
import { IBatchCreateRequest, IBatchCreateResponse } from "../../../models";

export async function batchRunScheduledQueries(
  queries: IBatchCreateRequest<string>,
) {
  await cdataFetch<IBatchCreateResponse<string>>({
    method: "POST",
    url: "/scheduledquery/run",
    body: queries,
  });
  return true; // The back-end model doesn't return anything, so we just assert success this way
}
