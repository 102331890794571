import { useState } from "react";
import { Col, Row } from "reactstrap";
import { IWorkspaceListItem } from "../../../models";
import WorkspaceField from "./components/WorkspaceField";
import TabWrapper, { ITabComponent } from "../../../components/TabWrapper";
import { useAppSelector } from "../../../redux/hooks";
import SelectResourceTypeButtons from "./components/SelectResourceTypeButtons";
import ConnectionFields from "./components/ConnectionFields";
import UrlField from "./components/UrlField";
import DatabaseField from "./components/DatabaseField";
import { CreatePATButton } from "../../../components/buttons/CreatePATButton";
import {
  IModalPromptBodyProps,
  ModalPromptBodyRow,
  ResourceType,
} from "./ModalPromptBody";
import { getOpenAPIURL } from "../../../services/endpointService";
import { DisabledInputWithCopyButton } from "../../../components/form/DisabledInputWithCopyButton";

export interface IOpenAPIModalPromptBodyProps {
  idName: string;
  modalBodyValues: ModalPromptBodyRow[];
  workspaces: IWorkspaceListItem[];
  isOdata: boolean;
  isOpenApi: boolean;
  isRestApi?: boolean;
  isDisplayed: boolean;
}

function ModalPromptBody(props: IModalPromptBodyProps) {
  const [selectedResourceType, setSelectedResourceType] =
    useState<ResourceType>(0);
  const userEmail = useAppSelector((state) => state.user?.email);
  const [selectedWorkspace, setSelectedWorkspace] =
    useState<IWorkspaceListItem>();

  const isWorkspaceTabSelected =
    selectedResourceType === ResourceType.Workspace;

  const areConnectionFieldsDisplayed =
    !props.isOdata &&
    (selectedResourceType === ResourceType.Connection ||
      (isWorkspaceTabSelected && selectedWorkspace));

  const selectionButtons =
    props.isOdata || props.isRestApi ? null : (
      <SelectResourceTypeButtons
        selectedResourceType={selectedResourceType}
        setSelectedResourceType={setSelectedResourceType}
      />
    );

  const workspaceField =
    (isWorkspaceTabSelected || props.isOdata) && !props.isRestApi ? (
      <WorkspaceField
        selectedWorkspace={selectedWorkspace}
        setSelectedWorkspace={setSelectedWorkspace}
        workspaces={props.workspaces}
      />
    ) : null;

  const connectionFields =
    areConnectionFieldsDisplayed && props.modalBodyValues ? (
      <ConnectionFields
        idName={props.idName}
        modalBodyValues={props.modalBodyValues}
        selectedResourceType={selectedResourceType}
        selectedWorkspaceName={selectedWorkspace?.name || ""}
        userEmail={userEmail}
      />
    ) : null;

  const urlField =
    props.isOdata && (selectedWorkspace || props.isRestApi) ? (
      <UrlField
        idName={props.idName}
        isOpenApi={props.isOpenApi}
        selectedResourceType={selectedResourceType}
        selectedWorkspaceName={selectedWorkspace?.name}
        selectedWorkspaceId={selectedWorkspace?.id || ""}
        isRestApi={props.isRestApi}
      />
    ) : null;

  const dataField = !props.isOdata ? (
    <DatabaseField
      idName={props.idName}
      isWorkspaceTabSelected={isWorkspaceTabSelected}
      selectedResourceType={selectedResourceType}
      selectedWorkspaceName={selectedWorkspace?.name}
      userEmail={userEmail}
    />
  ) : null;

  return (
    <div className="modal-prompt-body">
      {selectionButtons}
      {workspaceField}
      {connectionFields}
      {urlField}
      {dataField}
      <Row>
        <Col className="label-col">
          <div className="client-endpoint-label">Password:</div>
        </Col>
        <Col className="input-col">
          <CreatePATButton />
        </Col>
      </Row>
    </div>
  );
}

export default function OpenAPIModalPromptBody(
  props: IOpenAPIModalPromptBodyProps,
) {
  const modalBody = (
    <ModalPromptBody
      idName={props.idName}
      modalBodyValues={props.modalBodyValues}
      workspaces={props.workspaces}
      isOdata={props.isOdata}
      isOpenApi={props.isOpenApi}
      isRestApi={props.isRestApi}
    />
  );

  const [selectedWorkspace, setSelectedWorkspace] =
    useState<IWorkspaceListItem>();

  const openAPIContent = () => {
    if (selectedWorkspace && selectedWorkspace.name) {
      const displayURL = getOpenAPIURL(selectedWorkspace.name, "v3");
      return (
        <div className="modal-prompt-body">
          <Row className="align-items-center row-align">
            <Col className="label-col url-label">
              <div className="form-field-title text-nowrap">URL:</div>
            </Col>
            <Col className="ps-0">
              <DisabledInputWithCopyButton text={displayURL} />
            </Col>
          </Row>
          <Row>
            <Col className="label-col">
              <div className="client-endpoint-label">Password:</div>
            </Col>
            <Col className="input-col">
              <CreatePATButton />
            </Col>
          </Row>
        </div>
      );
    }
  };

  const tabContent = () => {
    const tabs: ITabComponent[] = [];
    tabs.push({
      tabName: "OpenAPI",
      tabEnum: "1",
      tabComponent: (
        <>
          <WorkspaceField
            selectedWorkspace={selectedWorkspace}
            setSelectedWorkspace={setSelectedWorkspace}
            workspaces={props.workspaces}
            containerClassName="my-2"
          />
          {openAPIContent()}
        </>
      ),
    });
    tabs.push({
      tabName: "Virtual SQL Server",
      tabEnum: "2",
      tabComponent: <>{modalBody}</>,
    });

    return (
      <TabWrapper
        tabs={tabs}
        smallerBottomPadding={true}
        activeTabWhiteBackground={true}
        tabContentClassName="modal-prompt-body"
      />
    );
  };

  return tabContent();
}
