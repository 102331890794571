import { MutableRefObject, useEffect } from "react";
import { Form, Formik, FormikProps, useFormikContext } from "formik";
import { Input, FormFeedback } from "reactstrap";
import * as Yup from "yup";
import { ITableSettings, PseudoColumn } from "../SetTable";
import { produce } from "immer";
import {
  ButtonType,
  CDataButton,
} from "../../../../components/buttons/CDataButton";
import { isEmpty } from "lodash";
import TextInput from "../../../../components/form/TextInput";
import Select from "../../../../components/select/Select";
import { InfoIcon } from "../../../../components/InfoIcon";
import { CheckMarkIcon } from "../../../../components/CheckMarkIcon";
import { selectOptions } from "./ColumnsTableContainer";
import { getPerRowValue } from "./PerRowRequestHelper";

interface IPseudoColumnProps {
  tableSettings: ITableSettings;
  setTableSettings: (tableSettings: ITableSettings) => void;
  setUnsavedChanges: (unsavedChanges: boolean) => void;
  formRef: MutableRefObject<FormikProps<ITableSettings>>;
}

const PseudoColumnTable = (props: IPseudoColumnProps) => {
  const perRowColumns = getPerRowValue(props.tableSettings.requestUrl);
  function validateDuplicateColumnName(pseudoColumn: string | undefined) {
    const columns: string[] = props.tableSettings.columns.map(
      (c) => c.columnName,
    );
    let notDuplicate = true;
    columns.forEach((col) => {
      if (col === pseudoColumn) {
        notDuplicate = false;
      }
    });
    return notDuplicate;
  }

  // Define the validation schema for a single pseudo column
  const pseudoColumnSchema = Yup.object().shape({
    pseudoColumnName: Yup.string()
      .required("Pseudo column name is required")
      .test(
        "duplicate column",
        "Column name already exists. Please remove this pseudo column from your table.",
        validateDuplicateColumnName,
      ),
    dataType: Yup.number()
      .required("Please select a data type.")
      .moreThan(0, "Please select a data type."),
    outputColumn: Yup.boolean().nullable(),
    required: Yup.boolean().nullable(),
  });

  // Define the validation schema for the pseudoColumns array
  const validationSchema = Yup.object().shape({
    pseudoColumns: Yup.array().of(pseudoColumnSchema),
  });

  useEffect(() => {
    setPseudoColumns(props.tableSettings.pseudoColumns);
  }, [props.tableSettings.requestUrl]); // eslint-disable-line

  function setPseudoColumns(pseudoColumns: PseudoColumn[]) {
    const updatedColumns =
      pseudoColumns?.map((pseudoColumn) => {
        if (perRowColumns?.has(pseudoColumn.pseudoColumnName)) {
          return {
            ...pseudoColumn,
            required: perRowColumns.has(pseudoColumn.pseudoColumnName),
          };
        }

        return pseudoColumn;
      }) ?? [];

    props.setTableSettings({
      ...props.tableSettings,
      pseudoColumns: updatedColumns,
    });
  }

  return (
    <div>
      <Formik
        enableReinitialize={true}
        validationSchema={validationSchema}
        innerRef={props.formRef}
        initialValues={props.tableSettings}
        validateOnMount={true}
        validateOnChange={true}
        onSubmit={() => {
          // no-op
        }}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <PseudoColumnFormBody
              tableSettings={props.tableSettings}
              setUnsavedChanges={props.setUnsavedChanges}
              setTablePseudoColumns={setPseudoColumns}
              formRef={props.formRef}
              requestUrl={props.tableSettings.requestUrl}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

interface IPseudoColumnFormBody {
  tableSettings: ITableSettings;
  setUnsavedChanges: (unsavedChanges: boolean) => void;
  setTablePseudoColumns: (tablePseudoColumns: PseudoColumn[]) => void;
  formRef: MutableRefObject<FormikProps<ITableSettings>>;
  requestUrl: string;
}

const PseudoColumnFormBody = (props: IPseudoColumnFormBody) => {
  const { values, setValues, touched, handleChange, setFieldValue } =
    useFormikContext<ITableSettings>();

  useEffect(() => {
    props.setTablePseudoColumns(values.pseudoColumns);
  }, [values.pseudoColumns]); // eslint-disable-line

  useEffect(() => {
    if (!isEmpty(touched.pseudoColumns)) {
      props.setUnsavedChanges(true);
    }
  }, [touched.pseudoColumns]); // eslint-disable-line

  function addNewPseudoColumn() {
    const newTableSettings = produce(values, (draft) => {
      if (!draft.pseudoColumns) {
        draft.pseudoColumns = [];
      }
      draft.pseudoColumns.push({
        pseudoColumnName: "",
        dataType: undefined!,
        outputColumn: false,
        required: false,
      });
    });
    setValues(newTableSettings, true);
    props.setTablePseudoColumns(newTableSettings.pseudoColumns);

    props.setUnsavedChanges(true);
  }

  function removeColumn(columnIndex: number) {
    const newTableSettings = produce(values, (draft) => {
      draft.pseudoColumns.splice(columnIndex, 1);
    });
    setValues(newTableSettings, true);
    props.setTablePseudoColumns(newTableSettings.pseudoColumns);

    props.setUnsavedChanges(true);
  }

  return (
    <div className="pseudoColumn-tab">
      {values.pseudoColumns?.length > 0 && (
        <div className="pseudoColumn-row pseudoColumns-title-row">
          <div className="pseudoColumn-column">
            <h5 className="required">Pseudo Column</h5>
          </div>
          <div className="pseudoColumn-column">
            <h5 className="required">Data Type</h5>
          </div>
          <div className="pseudoColumn-column-centered">
            <label className="form-field-title">Output Column</label>
            <InfoIcon
              tooltipMessage={
                "Output this column within the result of your API table."
              }
              className="info-icon-column"
              iconId="info-icon-output"
            />
          </div>
          <div className="pseudoColumn-column-centered">
            <label className="form-field-title">Required</label>
            <InfoIcon
              tooltipMessage={
                "Each query must include this column in the WHERE clause."
              }
              className="info-icon-column"
              iconId="info-icon-required"
            />
          </div>
          {/* Empty div to take up the same space as the buttons in the pseudoColumn rows. */}
          <div className="pseudoColumn-button-container" />
        </div>
      )}

      <div className="pseudoColumn-rows-container">
        {values.pseudoColumns?.map((p, index) => {
          const perRowColumns = getPerRowValue(props.tableSettings.requestUrl);
          const isParentRef = perRowColumns.has(p.pseudoColumnName);

          const isInvalidColumnName = Boolean(
            props.formRef.current?.errors?.pseudoColumns &&
              props.formRef.current?.touched?.pseudoColumns &&
              (
                props.formRef.current?.errors?.pseudoColumns[
                  index
                ] as unknown as { pseudoColumnName: boolean }
              )?.pseudoColumnName &&
              (
                props.formRef.current?.touched?.pseudoColumns[
                  index
                ] as unknown as { pseudoColumnName: boolean }
              )?.pseudoColumnName,
          );

          return (
            <div key={index} className="pseudoColumn-row">
              <div className="pseudoColumn-column">
                <div
                  className={
                    "input-with-icon" +
                    (isInvalidColumnName ? " input-with-icon-error" : "")
                  }
                >
                  <TextInput
                    label=""
                    name={`pseudoColumns[${index}].pseudoColumnName`}
                    aria-label="pseudoColumn Name"
                  />
                  {isParentRef && (
                    <CheckMarkIcon
                      tooltipMessage="Unique record identifier column."
                      className="checkmark-icon-pseudocolumn"
                      iconId={`check-mark-icon-${index}`}
                    />
                  )}
                </div>
              </div>
              <div className="pseudoColumn-column">
                <Select
                  value={p.dataType?.toString()}
                  options={selectOptions}
                  name={`pseudoColumns[${index}].dataType`}
                  onChange={(val: string) => {
                    setFieldValue(
                      `pseudoColumns[${index}].dataType`,
                      Number(val),
                      true,
                    );
                  }}
                  onBlur={(e) => {
                    props.formRef.current?.handleBlur(e);
                  }}
                  invalid={Boolean(
                    props.formRef.current?.errors?.pseudoColumns &&
                      props.formRef.current?.touched?.pseudoColumns
                      ? (
                          props.formRef.current?.errors?.pseudoColumns[
                            index
                          ] as unknown as { dataType: boolean }
                        )?.dataType &&
                          (
                            props.formRef.current?.touched?.pseudoColumns[
                              index
                            ] as unknown as { dataType: boolean }
                          )?.dataType
                      : false,
                  )}
                />
                <FormFeedback type="invalid">
                  {props.formRef.current?.errors?.pseudoColumns
                    ? (
                        props.formRef.current?.errors?.pseudoColumns[
                          index
                        ] as unknown as { dataType: boolean }
                      )?.dataType
                    : ""}
                </FormFeedback>
              </div>
              <div className="pseudoColumn-column-centered">
                <Input
                  checked={p.outputColumn}
                  className="primary-key-checkbox"
                  key={"pseudoColumnOutputColumn" + index}
                  name={`pseudoColumns[${index}].outputColumn`}
                  type="checkbox"
                  onChange={(event) => {
                    handleChange(event);
                  }}
                />
              </div>
              <div className="pseudoColumn-column-centered">
                <Input
                  checked={p.required}
                  className="primary-key-checkbox"
                  key={"pseudoColumnRequired" + index}
                  name={`pseudoColumns[${index}].required`}
                  disabled={perRowColumns?.has(p.pseudoColumnName)}
                  type="checkbox"
                  onChange={(event) => {
                    handleChange(event);
                  }}
                />
              </div>
              <div className="pseudoColumn-button-container">
                <CDataButton
                  className="pseudoColumns-icon-button"
                  buttonType={ButtonType.Tertiary}
                  aria-label={`Delete pseudoColumn ${values.pseudoColumns[index].pseudoColumnName}`}
                  onClick={() => {
                    removeColumn(index);
                  }}
                >
                  <i className="fa fa-trash-alt" />
                </CDataButton>
              </div>
            </div>
          );
        })}
      </div>
      <CDataButton
        buttonType={ButtonType.Tertiary}
        className="pseudoColumn-add-button"
        onClick={addNewPseudoColumn}
      >
        <i className="fa fa-plus icon" />
        Pseudo Column
      </CDataButton>
    </div>
  );
};

export default PseudoColumnTable;
