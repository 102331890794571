import React from "react";
import { Label } from "reactstrap";
import { DisabledInputWithCopyButton } from "../../../components/form/DisabledInputWithCopyButton";

interface ICallbackURLProps {
  labelClassName?: string;
}

const CallbackURL = (props: ICallbackURLProps) => {
  const getComponentName = () => {
    return "pages-connections-components-CallbackURL";
  };

  const helperText =
    "To use this data source, you'll need to supply a Callback URL when setting up your OAuth app. This is sometimes referred to as a 'Redirect URL'.";

  return (
    <div className={`mb-3 ${getComponentName()}`}>
      <Label className={props.labelClassName}>Callback URL</Label>
      <DisabledInputWithCopyButton text={"https://oauth.cdata.com/oauth/"} />
      <div className="helper-text">{helperText}</div>
    </div>
  );
};

export default CallbackURL;
