import { cdataFetch } from "../cdataFetch";
import { IQueryResult } from "../../models";
import { IServiceError } from "../../models/IServiceError";

export type ITableMetadata = {
  catalog: string;
  schema: string;
  tableName: string;
};

export async function getTablesForSchema(params: {
  connectionName: string;
  schema: string;
}) {
  const { connectionName, schema } = params;
  const rawData = await cdataFetch<IQueryResult | IServiceError>({
    method: "GET",
    url: `/tables?catalogName=${encodeURIComponent(connectionName)}&schemaName=${encodeURIComponent(schema)}`,
  });

  if ("error" in rawData) {
    throw new Error(
      rawData.error?.message ??
        `An error ocurred loading the table metadata for connection = ${connectionName}, schema = ${schema}`,
    );
  }

  const metadata: ITableMetadata[] =
    "results" in rawData && rawData?.results?.[0]?.rows != null
      ? rawData.results[0].rows.map<ITableMetadata>((r) => {
          return {
            catalog: r[0],
            schema: r[1],
            tableName: r[2],
          };
        })
      : [];

  return metadata;
}
