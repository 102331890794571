import { IQueryBuilderModel } from "../../pages/dataExplorer/RightPanel/QueryBuilder/models/IQueryBuilderModel";
import { IQueryTab } from "../../pages/dataExplorer/RightPanel/Tabs/useQueryTabs";
import * as types from "../constants";

export function updateDataExplorerTabs(tabs: IQueryTab[]) {
  return {
    type: types.UPDATE_DATA_EXPLORER_TABS,
    payload: tabs,
  };
}

export function updateDataExplorerQueryBuilderTabs(
  tabs: Record<string, IQueryBuilderModel>,
) {
  return {
    type: types.UPDATE_DATA_EXPLORER_QUERY_BUILDER_TABS,
    payload: tabs,
  };
}

export function updateDataExplorerSelectedTab(selectedTab: number) {
  return {
    type: types.UPDATE_DATA_EXPLORER_SELECTED_TAB,
    payload: selectedTab,
  };
}

export function updateDataExplorerTabTitleCounter(tabTitleCounter: number) {
  return {
    type: types.UPDATE_DATA_EXPLORER_TAB_TITLE_COUNTER,
    payload: tabTitleCounter,
  };
}

export function updateDataExplorerQueryTabTitleCounter(
  queryTabTitleCounter: number,
) {
  return {
    type: types.UPDATE_DATA_EXPLORER_QUERY_TAB_TITLE_COUNTER,
    payload: queryTabTitleCounter,
  };
}
