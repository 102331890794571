import React, { useState } from "react";
import { useParams } from "react-router-dom";
import StepWizardStep from "../../../../components/wizard/components/StepWizardStep";
import { StepWizardModal } from "../../../../components/wizard/StepWizardModal";
import {
  AddAssetWizardContext,
  IAddAssetWizardContextType,
} from "./AddAssetWizardContext";
import { SelectAssetType } from "./Steps/SelectAssetType";
import { SelectConnection } from "./Steps/SelectConnection";
import { SelectDerivedViews } from "./Steps/SelectDerivedViews";
import { SelectFolderName } from "./Steps/SelectFolderName";
import { SelectSchemaTable } from "./Steps/SelectSchemaTable";
import { StepNames } from "./Steps/StepNames";
import { SelectSchemaWizardContext } from "./SelectSchemaWizardContext";
import { IDatasetBulkAddError } from "./DatasetBulkAddErrorAccordion";

interface IAddAssetWizardProps {
  containerName?: string;
  toggleModal: () => void;
  isDisplayed: boolean;
  setAssetList: IAddAssetWizardContextType["setAssetList"];
  /**
   * Called to display an accordion of errors if one or more asset fails to be added.
   * This only accepts null because the EditFolderCard does not use the accordion.
   */
  setAddAssertErrors: (errors: IDatasetBulkAddError[]) => void | null;
}

interface ISelectedConnection {
  id: string;
  name: string;
}

function AddAssetWizard(props: IAddAssetWizardProps) {
  const { setAddAssertErrors } = props;

  const [selectedConnection, setSelectedConnection] =
    useState<ISelectedConnection>({ id: "", name: "" });
  const [driverName, setDriverName] = useState<string>();

  const { workspaceId } = useParams();

  function toggleModalAndClearContext() {
    setSelectedConnection({ id: "", name: "" });
    props.toggleModal();
  }

  return (
    <AddAssetWizardContext.Provider
      value={{
        workspaceID: workspaceId!,
        containerName: props.containerName,
        setAssetList: props.setAssetList,
      }}
    >
      <SelectSchemaWizardContext.Provider
        value={{
          assetList: [],
          selectedConnection,
          driverName: driverName,
          setSelectedConnection,
          setAssetList: props.setAssetList,
          setDriverName: setDriverName,
        }}
      >
        <StepWizardModal
          className="pages-datasets-AddAssetWizard-AddAssetWizard"
          isDisplayed={props.isDisplayed}
          showNavigation={false}
          title={"Add Asset"}
          toggleModal={toggleModalAndClearContext}
        >
          <StepWizardStep stepName={StepNames.SelectAssetType}>
            <SelectAssetType />
          </StepWizardStep>
          <StepWizardStep stepName={StepNames.SelectFolderName}>
            <SelectFolderName />
          </StepWizardStep>
          <StepWizardStep stepName={StepNames.SelectConnection}>
            <SelectConnection
              isCacheJobWizard={false}
              nextStepName={StepNames.SelectSchemaTables}
            />
          </StepWizardStep>
          <StepWizardStep stepName={StepNames.SelectSchemaTables}>
            <SelectSchemaTable
              isCacheJobWizard={false}
              previousStepName={StepNames.SelectConnection}
              setAddAssertErrors={setAddAssertErrors}
            />
          </StepWizardStep>
          <StepWizardStep stepName={StepNames.SelectDerivedViews}>
            <SelectDerivedViews setAddAssertErrors={setAddAssertErrors} />
          </StepWizardStep>
        </StepWizardModal>
      </SelectSchemaWizardContext.Provider>
    </AddAssetWizardContext.Provider>
  );
}

export default AddAssetWizard;
