import React, { useRef, useState } from "react";
import {
  Card,
  CardBody,
  CardText,
  Badge,
  UncontrolledTooltip,
} from "reactstrap";
import { showFlyout } from "../../../redux/actions/flyoutActions";
import { hasOverflow } from "../ClientConnectivityFunctions";
import { useAppDispatch } from "../../../redux/hooks";
import { ButtonBase } from "@mui/material";

export interface IExternalDownloadTileProps {
  idName: string;
  testId?: string;
  imageIcon: string;
  description: string;
  showBetaBadge: boolean;
  internalDoc: string;
  externalDoc: string;
}

const ExternalDownloadTile = (props: IExternalDownloadTileProps) => {
  const cardRef = useRef(null);
  const dispatch = useAppDispatch();
  const [showToolTip, setShowTooltip] = useState(false);

  const docUrl = props.internalDoc;
  const name = props.description;
  const source = "Clients";

  return (
    <Card
      data-testid={props.testId}
      name={props.idName}
      id={props.idName}
      className="shadow-sm client-tools-card"
      onMouseOver={() => setShowTooltip(hasOverflow(cardRef.current))}
      onMouseOut={() => setShowTooltip(false)}
    >
      <ButtonBase
        disableRipple
        className="client-tools-clickable-area"
        onClick={() => {
          if (props.externalDoc) {
            window.open(props.externalDoc, "_blank", "noopener,noreferrer");
          }
        }}
      >
        <div>
          <svg
            className="client-connectivity-icon card-img-top img-thumbnail"
            viewBox="0 0 48 48"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
          >
            <use xlinkHref={props.imageIcon}></use>
          </svg>
        </div>

        <CardBody className="card-body">
          <UncontrolledTooltip
            placement="top"
            target={`title-${props.idName}`}
            isOpen={showToolTip}
          >
            {props.description}
          </UncontrolledTooltip>
          <CardText
            id={`title-${props.idName}`}
            tag="h6"
            className="card-text"
            ref={cardRef}
          >
            {props.description}
          </CardText>
        </CardBody>
      </ButtonBase>
      <div>
        <div className="action-card-container d-flex">
          <Badge
            id="beta-badge"
            hidden={!props.showBetaBadge}
            color=""
            className="client-tools-beta-badge"
          >
            Beta
          </Badge>
          <a
            className="docs-btn"
            onClick={() => dispatch(showFlyout(docUrl, name, source))}
            role="button"
          >
            Docs
          </a>
          <div className="divider" />
          <a
            className="docs-btn"
            href={props.externalDoc}
            target="_blank"
            rel="noopener noreferrer"
          >
            Download
          </a>
        </div>
      </div>
    </Card>
  );
};

export default ExternalDownloadTile;
