import React from "react";
import { Navbar, Nav } from "reactstrap";
import CdataIcon from "../assets/img/icons/CloudLogo.svg?react";

const SimpleHeader = () => {
  return (
    <Navbar color="white" className="components-SimpleHeader" light expand>
      <a href="/">
        <CdataIcon
          className="cdata-logo"
          aria-description="CData Connect Cloud logo"
        />
      </a>
      <Nav className="ms-auto" navbar />
    </Navbar>
  );
};

export default SimpleHeader;
