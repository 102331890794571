import React from "react";
import { CDataModalV2 } from "../../../components/modal/CDataModalV2";
import {
  ButtonType,
  CDataButton,
} from "../../../components/buttons/CDataButton";
import { useAPI } from "../../../components/useAPI";
import { RequestType } from "../../../components/withAPI";
import { IUser } from "../../../models";
import { ToastrError, ToastrSuccess } from "../../../services/toastrService";

interface IDeleteUsersModal {
  open: boolean;
  close: () => void;
  usersToDelete: IUser[];
  handlePostDeleteTasks: (deleteSucceeded: boolean) => void;
  setProcessingRequest: (processingRequest: boolean) => void;
}

export function DeleteUsersModal(props: IDeleteUsersModal) {
  const {
    open,
    close,
    handlePostDeleteTasks,
    setProcessingRequest,
    usersToDelete,
  } = props;

  const api = useAPI();

  const isSingleDelete = usersToDelete.length === 1;

  const singleDeleteMessage = "Are you sure you want to delete this user?";
  const multiDeleteMessage = "Are you sure you want to delete these users?";

  const onDelete = async () => {
    setProcessingRequest(true);
    const selectedUser = usersToDelete[0];
    const deleteQueryUrl = `/users/${selectedUser.id}`;

    const { status } = await api.callAPI(
      RequestType.Delete,
      deleteQueryUrl,
      "Failed to delete selected user due to the following error:",
    );
    if (status === 200 || status === 204) {
      ToastrSuccess(
        "User successfully deleted",
        `User ${selectedUser.email} was successfully deleted.`,
      );

      close();
      handlePostDeleteTasks(true);
    } else {
      handlePostDeleteTasks(false);
    }
    setProcessingRequest(false);
  };

  const onBatchDelete = async () => {
    setProcessingRequest(true);
    const deleteQueryUrl = "/users";
    const requestBody = {
      ids: usersToDelete.map((user: IUser) => user.id),
    };

    const { status, payload } = await api.callAPI(
      RequestType.Delete,
      deleteQueryUrl,
      "Failed to delete selected user due to the following error:",
      requestBody,
    );
    if (status === 200) {
      const data = payload;
      if (data.error) {
        ToastrError(
          "Error",
          "There was a problem deleting some of your users. Please try again.",
        );
      } else {
        ToastrSuccess(
          "Users successfully deleted",
          `${payload.deletedIds.length} users were successfully deleted.`,
        );
      }

      close();
      handlePostDeleteTasks(true);
    } else {
      handlePostDeleteTasks(false);
    }
    setProcessingRequest(false);
  };

  return (
    <CDataModalV2
      title="Delete User"
      displayed={open}
      spacedFooter={false}
      close={close}
      primaryButton={
        <CDataButton
          buttonType={ButtonType.Danger}
          onClick={(event) => {
            event.preventDefault();
            isSingleDelete ? onDelete() : onBatchDelete();
          }}
        >
          Confirm
        </CDataButton>
      }
      secondaryButton={
        <CDataButton buttonType={ButtonType.Secondary} onClick={close}>
          Close
        </CDataButton>
      }
    >
      {isSingleDelete ? singleDeleteMessage : multiDeleteMessage}
    </CDataModalV2>
  );
}
