import {
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  ListGroup,
  ListGroupItem,
  Row,
} from "reactstrap";
import TertiaryButton from "../../../../components/buttons/TertiaryButton";
import { Search } from "react-feather";
import { isSelect } from "../QueryEditor/sanitizeQuery";
import { IDataExplorerRightPanelColumnMetadata } from "../EditorAndResults";
import { NotificationBar } from "../../../../components/notification/NotificationBar";
import { ColumnDef } from "@tanstack/react-table";

interface IQueryResultsView {
  queryInput: string;
  resultColumns: ColumnDef<any>[];
  executeDisabled: boolean;
  isNewDerivedViewUIResult: boolean;
  resultsToggled: boolean;
  setResultsToggled: (newResultsToggled: boolean) => void;
  csvDownloadLoading: boolean;
  setDownloadCSVModalDisplayed: (newCSVDownloadDisplayed: boolean) => void;
  columnMetadata: IDataExplorerRightPanelColumnMetadata[];
  filterColumns: React.ChangeEventHandler<HTMLInputElement>;
  csvDownloadError: boolean;
  setCsvDownloadError: (newCSVDownloadEror: boolean) => void;
  cachingPromptNotification?: JSX.Element;
  resultsTable: JSX.Element;
  resultsSubtitle: JSX.Element;
}

export const QueryResultsView = (props: IQueryResultsView) => {
  const {
    queryInput,
    resultColumns,
    executeDisabled,
    isNewDerivedViewUIResult,
    resultsToggled,
    setResultsToggled,
    csvDownloadLoading,
    setDownloadCSVModalDisplayed,
    columnMetadata,
    filterColumns,
    csvDownloadError,
    setCsvDownloadError,
    cachingPromptNotification,
    resultsTable,
    resultsSubtitle,
  } = props;

  const csvDownloadTimeout = () => {
    if (csvDownloadError && isSelect(queryInput) && resultsToggled) {
      return (
        <div className="pb-3">
          <NotificationBar
            message="Timeout: Your export has taken too long, leading to a system timeout. To prevent this, we suggest utilizing our Client Tools add-in to import your results into Excel."
            barColor="notification-bar-red"
            linkText="Get Started"
            linkClickOverride={() =>
              window.open(
                "https://appsource.microsoft.com/en-us/product/office/WA200004063",
                "_blank",
              )
            }
            onClose={() => setCsvDownloadError(false)}
          />
        </div>
      );
    }
  };

  return (
    <>
      <Card className="p-3 pb-0 mb-0 result-container no-border-radius border-top">
        {!isNewDerivedViewUIResult ? (
          <ListGroup
            horizontal
            className="col-2 connected-button-group data-explorer-result-controls"
          >
            <ListGroupItem
              action
              type="button"
              onClick={() =>
                (() => {
                  setResultsToggled(false);
                })()
              }
              active={resultsToggled === false}
              className="text-center"
            >
              Details
            </ListGroupItem>
            <ListGroupItem
              action
              type="button"
              onClick={() =>
                (() => {
                  setResultsToggled(true);
                })()
              }
              active={resultsToggled === true}
              className="text-center"
            >
              Results
            </ListGroupItem>
          </ListGroup>
        ) : (
          <></>
        )}
        <CardBody
          className={
            "pb-0 px-0 h-100" + (isNewDerivedViewUIResult ? " p-0" : "")
          }
        >
          <CardTitle tag="h5">
            <Row className="align-items-center">
              <Col>{resultsToggled ? "Query Results" : "Table Details"}</Col>
              <Col className="download-csv-button">
                <TertiaryButton
                  onClick={() => setDownloadCSVModalDisplayed(true)}
                  hidden={
                    executeDisabled ||
                    !resultsToggled ||
                    resultColumns.length === 0
                  }
                  disabled={csvDownloadLoading || !isSelect(queryInput)}
                  className="transparent-button"
                >
                  <i className="fa fa-solid fa-download fa-sm me-1" />
                  Download CSV
                </TertiaryButton>
              </Col>
            </Row>
          </CardTitle>
          <CardSubtitle className="query-results">
            {resultsSubtitle}
          </CardSubtitle>
          {!resultsToggled && columnMetadata.length > 0 ? (
            <span className="col-search-bar-outer">
              <Search className="align-middle-search-glass" size={16} />
              <input
                className="col-search-filter-bar"
                onChange={(e: any) => filterColumns(e)}
                type="text"
                placeholder="Search Columns..."
              />
            </span>
          ) : (
            <></>
          )}
          {csvDownloadTimeout()}
          {cachingPromptNotification}
          <div
            id="resultsTableContainer"
            className={csvDownloadError ? "timeout-error-visible" : ""}
            data-dd-privacy="hidden"
          >
            {resultsTable}
          </div>
        </CardBody>
      </Card>
    </>
  );
};
