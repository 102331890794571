import React from "react";
import { UncontrolledTooltip, Badge } from "reactstrap";

export interface ITitleBadgeProps {
  name: string;
  isVisible?: boolean;
  tooltip?: JSX.Element;
}

const TitleBadge = (props: ITitleBadgeProps) => (
  <span className="titleBadge">
    <span hidden={props.isVisible === false}>
      <Badge id="badgeId" color="" className="badge-client-page-beta badge">
        {props.name}
      </Badge>
    </span>
    {props.tooltip ? (
      <UncontrolledTooltip
        placement="top"
        target="badgeId"
        trigger="hover"
        autohide={false}
      >
        {props.tooltip}
      </UncontrolledTooltip>
    ) : null}
  </span>
);

export default TitleBadge;
