import { Col, Row } from "react-bootstrap";
import {
  IQueryBuilderModel,
  IQueryBuilderSort,
} from "../../../models/IQueryBuilderModel";
import { SortTile } from "./SortTile";
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  pointerWithin,
} from "@dnd-kit/core";

import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CDataTypography } from "../../../../../../../components/text/CDataTypography";

interface ISortTileList {
  queryData: IQueryBuilderModel;
  getSortId: (column: IQueryBuilderSort) => string;
  onReorderSorts: (oldColumnIndex: number, newColumnIndex: number) => void;
  onClickEditSort: (index: number) => void;
  handleDelete: (index: number) => void;
}

export const SortTileList = (props: ISortTileList) => {
  const {
    queryData,
    getSortId,
    onReorderSorts,
    onClickEditSort,
    handleDelete,
  } = props;

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (over == null) {
      return;
    }

    if (active.id !== over.id) {
      const oldIndex = queryData.sort.findIndex(
        (c) => getSortId(c) === active.id,
      );
      const newIndex = queryData.sort.findIndex(
        (c) => getSortId(c) === over.id,
      );
      onReorderSorts(oldIndex, newIndex);
    }
  }

  return (
    <>
      <Row className="gray-helper-text">
        <Col>
          <CDataTypography>
            Specify a column to be sorted in ascending or descending order.
          </CDataTypography>
        </Col>
      </Row>
      <DndContext
        sensors={sensors}
        collisionDetection={pointerWithin}
        onDragCancel={handleDragEnd}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={queryData.sort.map((c) => getSortId(c))}
          strategy={verticalListSortingStrategy}
        >
          {queryData.sort?.map((sort, index) => (
            <SortTile
              {...props}
              index={index}
              querySort={sort}
              key={getSortId(sort)}
              getSortId={getSortId}
              onClickEditSort={onClickEditSort}
              handleDelete={handleDelete}
            />
          ))}
        </SortableContext>
      </DndContext>
    </>
  );
};
