import { BackendType, RequestType } from "./withAPI";
import { useAPI } from "./useAPI";
import { useContext } from "react";
import { ODataContext } from "../pages/odata/Wizard/ODataContext";
import { useMemoryCache } from "./useMemoryCache";

export function useSchemaFinder() {
  const api = useAPI();
  const odataContext = useContext(ODataContext);
  const memoryCache = useMemoryCache(getSchemaTables);

  async function getSchemaTables(searchParam: string): Promise<any[] | null> {
    const { status, payload } = await api.callAPI(
      RequestType.Get,
      `/tables?${searchParam}`,
      "Failed to get user defined connection list due to the following error:",
      null,
      BackendType.QueryRouter,
    );

    if (status !== 200) {
      return null;
    }
    const result = payload;
    if (!result.error) {
      const schema = result.results[0].schema;
      const rows = result.results[0].rows;
      const tableNameIndex = schema.findIndex(
        (property: any) => property.columnName === "TABLE_NAME",
      );
      const tableConnectionIndex = schema.findIndex(
        (property: any) => property.columnName === "TABLE_CATALOG",
      );
      const tableTypeIndex = schema.findIndex(
        (property: any) => property.columnName === "TABLE_TYPE",
      );
      const tableSchemaIndex = schema.findIndex(
        (property: any) => property.columnName === "TABLE_SCHEMA",
      );
      const tables = rows
        .map((table: any) => ({
          fullName: `${table[tableConnectionIndex]}.${table[tableSchemaIndex]}.${table[tableNameIndex]}`,
          connectionName: table[tableConnectionIndex],
          schemaName: table[tableSchemaIndex],
          defaultTableName: table[tableNameIndex],
          tableType: table[tableTypeIndex],
        }))
        .filter(
          (table: any) =>
            table.tableType === "TABLE" || table.tableType === "VIEW",
        )
        .filter((table: any) => {
          return !odataContext.alreadyExistingTables.some(
            (existingTable: any) => {
              return table.fullName === existingTable.fullName;
            },
          );
        });

      // If there are no tables to add, but the connection has at least one table, then all tables have been added.
      if (tables.length === 0) {
        if (rows.length > 0) {
          return [];
        } else {
          return null;
        }
      }
      return tables;
    }

    return null;
  }

  const schemaFinder = {
    get: memoryCache.get,
  };

  return schemaFinder;
}
