import { Card, CardBody } from "reactstrap";
import { Search } from "react-feather";
import { IDataExplorerRightPanelColumnMetadata } from "../EditorAndResults";
import TabWrapper from "../../../../components/TabWrapper";
import classNames from "classnames";
import { CDataTypography } from "../../../../components/text/CDataTypography";
import { ColumnDef } from "@tanstack/react-table";

interface IDerivedViewResults {
  isNewDerivedViewUIResult: boolean;
  resultsToggled: boolean;
  setResultsToggled: (newResultsToggled: boolean) => void;
  resultColumns: ColumnDef<any>[];
  columnMetadata: IDataExplorerRightPanelColumnMetadata[];
  filterColumns: React.ChangeEventHandler<HTMLInputElement>;
  renderResultsTable: () => JSX.Element;
  fetchPreview: () => void;
}

export const DerivedViewResults = (props: IDerivedViewResults) => {
  const {
    isNewDerivedViewUIResult,
    resultsToggled,
    setResultsToggled,
    resultColumns,
    columnMetadata,
    filterColumns,
    renderResultsTable,
    fetchPreview,
  } = props;

  return (
    <div className="derived-view-results">
      <Card className="p-3 pb-0 mb-0 result-container shadow-none">
        <TabWrapper
          currentTab={!resultsToggled ? "1" : "2"}
          activeTabWhiteBackground
          setCurrentTab={() => {
            if (
              (resultsToggled && !resultColumns) ||
              resultColumns.length === 0
            ) {
              fetchPreview();
            }
            setResultsToggled(!resultsToggled);
          }}
          tabs={[
            {
              tabName: "Details",
              tabEnum: "1",
              tabComponent: (
                <CardBody
                  className={classNames(
                    "derived-view-results pb-0 px-0 h-100",
                    { "p-0": isNewDerivedViewUIResult },
                  )}
                >
                  <CDataTypography
                    variant="typography-variant-card-title"
                    className="mb-3"
                  >
                    Column Details
                  </CDataTypography>
                  {columnMetadata.length > 0 && (
                    <span className="col-search-bar-outer">
                      <Search className="align-middle-search-glass" size={16} />
                      <input
                        className="col-search-filter-bar"
                        onChange={(e: any) => filterColumns(e)}
                        type="text"
                        placeholder="Search Columns..."
                      />
                    </span>
                  )}
                  <div id="resultsTableContainer" data-dd-privacy="hidden">
                    {renderResultsTable()}
                  </div>
                </CardBody>
              ),
            },
            {
              tabName: "Preview",
              tabEnum: "2",
              tabComponent: (
                <CardBody
                  className={classNames(
                    "derived-view-results pb-0 px-0 h-100",
                    { "p-0": isNewDerivedViewUIResult },
                  )}
                >
                  <CDataTypography
                    variant="typography-variant-card-title"
                    className="mb-3"
                  >
                    Preview Derived View
                  </CDataTypography>
                  <div
                    id="resultsTableContainer"
                    className="derived-view-preview-table"
                    data-dd-privacy="hidden"
                  >
                    {renderResultsTable()}
                  </div>
                </CardBody>
              ),
            },
          ]}
        />
      </Card>
    </div>
  );
};
