import { useState, useEffect, useRef } from "react";
import "codemirror/lib/codemirror.css";
import "codemirror/mode/sql/sql.js";
import "codemirror/addon/selection/active-line";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

import QueryTabs from "./Tabs/QueryTabs";
import { useLocation } from "react-router-dom";
import useQueryTabs, { IQueryTab } from "./Tabs/useQueryTabs";
import EditorAndResults from "./EditorAndResults";
import { QueryBuilder } from "./QueryBuilder/QueryBuilder";
import { QueryTabType } from "./Tabs/queryTabType";
import { IConnection } from "../../../models";
import { DerivedViewEditorAndResults } from "./DerivedViewEditor/DerivedViewEditorAndResults";
import { CreateDerivedViewModal } from "./modals/CreateDerivedViewModal";
import { LeftPanelType } from "../LeftPanel/leftPanelType";
import { MaxTabsModal } from "./modals/MaxTabsModal";
import { CreateSavedQueryModal } from "./modals/CreateSavedQueryModal";
import { UpdateScheduledQueryModal } from "./ScheduledQuery/UpdateScheduledQueryModal";
import { UpdateSavedQueryModal } from "./modals/UpdateSavedQueryModal";
import { UpdateDerivedViewModal } from "./modals/UpdateDerivedViewModal";
import { HomeTab } from "./Tabs/HomeTab";
import { CDataModalV2 } from "../../../components/modal/CDataModalV2";
import {
  ButtonType,
  CDataButton,
} from "../../../components/buttons/CDataButton";
import { CDataTypography } from "../../../components/text/CDataTypography";

interface IDataExplorerRightPanel {
  setSidebarView: (newSidebarView: LeftPanelType) => void;
  fetchDerivedViews: () => void;
  fetchSavedQueries: () => void;
  connectionsList: IConnection[];
}

const DataExplorerRightPanel = (props: IDataExplorerRightPanel) => {
  const tabs = useQueryTabs();
  const location = useLocation();
  const [isAbortQueryModalDisplayed, setIsAbortQueryModalDisplayed] =
    useState(false);
  const [maxTabsModalOpen, setMaxTabsModalOpen] = useState<boolean>(false);
  const [createDerivedViewModalOpen, setCreateDerivedViewModalOpen] =
    useState<boolean>(false);
  const [createSavedQueryModalOpen, setCreateSavedQueryModalOpen] =
    useState<boolean>(false);
  const [updateDerivedViewModalOpen, setUpdateDerivedViewModalOpen] =
    useState(false);
  const [updateSavedQueryModalOpen, setUpdateSavedQueryModalOpen] =
    useState<boolean>(false);
  const [updateScheduledQueryModalOpen, setUpdateScheduledQueryModalOpen] =
    useState(false);

  const clickedFromDatasets = (location.state as any)?.clickedFromDatasets;
  const fromDatasetsTable = (location.state as any)?.clickedFromDatasetsTable;
  const clickedFromScheduledQuery = (location.state as any)
    ?.clickedFromScheduledQuery;
  const scheduledQueryToEdit = (location.state as any)?.scheduledQueryToEdit;

  const abortControllerRef = useRef<AbortController | null>(null);
  function abortRunningQueryRequest() {
    setIsAbortQueryModalDisplayed(true);
  }

  useEffect(() => {
    if (clickedFromDatasets) {
      tabs.AddWorkspaceTab(
        fromDatasetsTable.meta.tableName,
        fromDatasetsTable.meta.schemaName,
        null,
        fromDatasetsTable.meta.connectionName,
      );
    } else if (clickedFromScheduledQuery) {
      tabs.AddScheduledQueryTab(scheduledQueryToEdit);
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (tabs.List.length > tabs.MaxTabs) setMaxTabsModalOpen(true);
  }, [tabs.List, tabs.MaxTabs]);

  return (
    <div className="col-9 ps-0 h-100 pages-dataExplorer-RightPanel">
      <CDataModalV2
        title="Cancel Query"
        close={() => setIsAbortQueryModalDisplayed(false)}
        displayed={isAbortQueryModalDisplayed}
        primaryButton={
          <CDataButton
            buttonType={ButtonType.Primary}
            onClick={() => {
              abortControllerRef.current?.abort();
              setIsAbortQueryModalDisplayed(false);
            }}
          >
            Confirm
          </CDataButton>
        }
      >
        <CDataTypography>
          Please confirm if you want to cancel the query.
        </CDataTypography>
      </CDataModalV2>
      <QueryTabs />
      {tabs.List.map((t: IQueryTab) => {
        switch (t.tabType) {
          case QueryTabType.Home: {
            return (
              <span
                key={`homeTab`}
                className="home-tab-span"
                hidden={tabs.CurrentTabId !== 0}
              >
                <HomeTab />
              </span>
            );
          }
          case QueryTabType.QueryBuilder: {
            return (
              <span key={`queryBuilder-${t.id}`}>
                <QueryBuilder
                  tab={t}
                  connectionsList={props.connectionsList}
                  isVisible={tabs.CurrentTabId === t.id}
                  abortRunningQueryRequest={abortRunningQueryRequest}
                />
              </span>
            );
          }
          case QueryTabType.EditDerivedView: {
            return (
              <span key={`derivedView-${t.id}`}>
                <DerivedViewEditorAndResults
                  tab={t}
                  openCreateDerivedViewModal={() =>
                    setCreateDerivedViewModalOpen(true)
                  }
                  openCreateSavedQueryModal={() =>
                    setCreateSavedQueryModalOpen(true)
                  }
                  setSidebarView={props.setSidebarView}
                  fetchDerivedViews={props.fetchDerivedViews}
                  fetchSavedQueries={props.fetchSavedQueries}
                  abortRunningQueryRequest={abortRunningQueryRequest}
                />
              </span>
            );
          }
          default: {
            return (
              <span key={t.id}>
                <EditorAndResults
                  tab={t}
                  openCreateDerivedViewModal={() =>
                    setCreateDerivedViewModalOpen(true)
                  }
                  openCreateSavedQueryModal={() =>
                    setCreateSavedQueryModalOpen(true)
                  }
                  openUpdateDerivedViewModal={() =>
                    setUpdateDerivedViewModalOpen(true)
                  }
                  openUpdateSavedQueryModal={() =>
                    setUpdateSavedQueryModalOpen(true)
                  }
                  openUpdateScheduledQueryModal={() =>
                    setUpdateScheduledQueryModalOpen(true)
                  }
                  abortControllerRef={abortControllerRef}
                  abortRunningQueryRequest={abortRunningQueryRequest}
                />
              </span>
            );
          }
        }
      })}
      <CreateDerivedViewModal
        displayed={createDerivedViewModalOpen}
        close={() => setCreateDerivedViewModalOpen(false)}
        fetchDerivedViews={props.fetchDerivedViews}
        setSidebarView={props.setSidebarView}
      />
      <CreateSavedQueryModal
        displayed={createSavedQueryModalOpen}
        close={() => setCreateSavedQueryModalOpen(false)}
        fetchSavedQueries={props.fetchSavedQueries}
        setSidebarView={props.setSidebarView}
      />
      <UpdateDerivedViewModal
        displayed={updateDerivedViewModalOpen}
        close={() => setUpdateDerivedViewModalOpen(false)}
        fetchDerivedViews={props.fetchDerivedViews}
      />
      <UpdateSavedQueryModal
        displayed={updateSavedQueryModalOpen}
        close={() => setUpdateSavedQueryModalOpen(false)}
        fetchSavedQueries={props.fetchSavedQueries}
      />
      <UpdateScheduledQueryModal
        displayed={updateScheduledQueryModalOpen}
        close={() => setUpdateScheduledQueryModalOpen(false)}
      />
      <MaxTabsModal
        displayed={maxTabsModalOpen}
        close={() => setMaxTabsModalOpen(false)}
      />
    </div>
  );
};

export default DataExplorerRightPanel;
