import React from "react";
import { Badge, CardTitle, Col, Row, UncontrolledTooltip } from "reactstrap";

interface IUserSeatBadge {
  usersCount: number;
  maxUsers: number;
}

const UserSeatBadge = (props: IUserSeatBadge) => {
  let usersCountText: string;
  let badgeColor = "secondary";
  let tooltip: JSX.Element = <></>;

  if (props.maxUsers === -1) {
    usersCountText = "Unlimited";
  } else if (props.usersCount > props.maxUsers) {
    usersCountText = "Over Limit";
    badgeColor = "danger";
    const excessUsers = props.usersCount - props.maxUsers;
    const excessUserMessage =
      excessUsers === 1
        ? `You are ${excessUsers} user seat over your plan limit.`
        : `You are ${excessUsers} user seats over your plan limit.`;

    tooltip = (
      <UncontrolledTooltip placement="top" target="userSeatsBadge">
        {excessUserMessage}
      </UncontrolledTooltip>
    );
  } else {
    usersCountText = `${props.usersCount}/${props.maxUsers}`;
  }

  return (
    <Row>
      <Col className="flex-grow-0 pe-2">
        <CardTitle className="ms-1 mb-0">Seats:</CardTitle>
      </Col>
      <Col className="flex-grow-0 ps-0">
        <Badge
          id="userSeatsBadge"
          color={badgeColor}
          className="align-middle ms-0"
        >
          {usersCountText}
        </Badge>
        {tooltip}
      </Col>
    </Row>
  );
};

export default UserSeatBadge;
