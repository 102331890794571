import { useContext, useEffect } from "react";
import DataLabelAndDropdown from "./DataLabelAndDropdown";
import { useFormikContext } from "formik";
import { AddDataModalContext } from "../AddDataModalContext";
import {
  IQueryBuilderJoin,
  IQueryBuilderModel,
} from "../../../../../models/IQueryBuilderModel";
import { produce } from "immer";
import { useMutation } from "@tanstack/react-query";
import { getTablesForSchema } from "../../../../../../../../../api/metadata/getTablesForSchema";
import { IQueryBuilderTable } from "../../../../../models/IQueryBuilderTable";
import { IConnection } from "../../../../../../../../../models";
import { generateUniqueTableAlias } from "../../utilities/generateUniqueTableAlias";

interface INewTableSchemaField {
  queryData: IQueryBuilderModel;
  connectionsList: IConnection[];
  schemaList: string[];
}

const NewTableSchemaField = (props: INewTableSchemaField) => {
  const { queryData, connectionsList, schemaList } = props;
  const { setValues, values } = useFormikContext<IQueryBuilderJoin>();
  const { setNewTableColumns, setProcessing, setTables } =
    useContext(AddDataModalContext);

  const { mutateAsync: loadTablesForSchemaAsync } = useMutation({
    mutationKey: ["/tables"],
    mutationFn: getTablesForSchema,
    onSuccess: (tableMetadata) => {
      const connection = connectionsList.find(
        (c) =>
          c.name?.toLowerCase() === tableMetadata[0]?.catalog?.toLowerCase(),
      );
      // We should always have a connection, if we don't just bail out, nothing we can do.
      if (connection == null) {
        setTables([]);
        return;
      }

      setTables(
        tableMetadata.map<IQueryBuilderTable>((t) => {
          return {
            connectionId: connection.id!,
            connectionName: connection.name!,
            driver: connection.driver!,
            schema: t.schema,
            tableName: t.tableName,
            tableAlias: generateUniqueTableAlias({
              queryData: queryData,
              tableName: t.tableName,
            }),
          };
        }),
      );
    },
    onMutate: () => {
      setProcessing(true);
    },
    onSettled: () => {
      setProcessing(false);
    },
    meta: {
      errorMessage:
        "Failed to load schemas for table due to the following error:",
    },
  });

  useEffect(() => {
    async function setDefaultSchema() {
      await updateSelectedSchema(schemaList[0]);
    }

    // If we are editing an existing record, don't try and set the schema since
    // this clears out the selected column.
    if (values.right.column.length > 0) {
      return;
    }

    if (schemaList?.length === 1) {
      setDefaultSchema();
    }
  }, [schemaList]); // eslint-disable-line

  async function updateSelectedSchema(schema: string) {
    if (schema !== values.right.table.schema || schemaList?.length === 1) {
      await updateTableList(schema);
      setValues(
        produce(values, (draft) => {
          draft.right.table.schema = schema;
          // Clear out the selected table.
          draft.right.table.tableAlias = "";
          draft.right.table.tableName = "";
          draft.right.column = "";
          draft.left.column = "";
        }),
      );

      // Clear the downstream selection options
      setNewTableColumns([]);
    }
  }

  async function updateTableList(newSchema: string) {
    await loadTablesForSchemaAsync({
      connectionName: values.right.table.connectionName,
      schema: newSchema,
    });
  }

  const hasValue =
    values.right?.table?.schema != null && values.right.table.schema.length > 0;

  return (
    <DataLabelAndDropdown<string>
      handleChange={updateSelectedSchema}
      isDisabled={!values.right.table.connectionName || schemaList.length === 1}
      label={"Schema"}
      dropdownLabel={(option) => option}
      optionLabel={(option) => option}
      id={"newTable.schema"}
      options={schemaList || []}
      value={hasValue ? values.right.table.schema : null}
    />
  );
};

export default NewTableSchemaField;
