import { IQueryTab } from "../Tabs/useQueryTabs";
import { IDataExplorerRightPanelColumnMetadata } from "../EditorAndResults";

export type ResultsSubtitleProps = {
  resultsToggled: boolean;
  queryResults: string;
  columnMetadata: IDataExplorerRightPanelColumnMetadata[];
  columnMetadataLoading: boolean;
  tab: IQueryTab;
};

export function ResultsSubtitle(props: ResultsSubtitleProps) {
  const {
    resultsToggled,
    queryResults,
    columnMetadata,
    columnMetadataLoading,
    tab,
  } = props;

  let resultSubtitle;

  if (resultsToggled) {
    resultSubtitle = queryResults;
  } else if (
    !resultsToggled &&
    !columnMetadataLoading &&
    columnMetadata.length > 0 &&
    tab.tableName
  ) {
    return (
      <>
        <div className="table-name-subtitle mb-1">{tab.tableName}</div>
        {columnMetadataLoading === false && (
          <div>{columnMetadata.length} Columns</div>
        )}
      </>
    );
  } else {
    return (
      <>
        {" "}
        <div className="table-details" />
      </>
    );
  }

  return <>{resultSubtitle}</>;
}
