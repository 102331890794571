import { IDriverExtended } from "../../../../bffmodels/IDriverExtended";
import { compareStrings } from "../../../../utility/CompareStrings";
import { getSelectedAuthSchemeValue } from "./getSelectedAuthScheme";

/** Returns true if the user has selected OAuthISU for the auth scheme in the connector */
export function isOAuthISUSelected(
  driverInfo: IDriverExtended | null | undefined,
): boolean {
  const selectedAuthScheme = getSelectedAuthSchemeValue(driverInfo);

  // If the user selected OAuthISU for the auth scheme, (e.x. Workday), then do not
  // show the callback URL.
  const isOAuthISU =
    selectedAuthScheme != null &&
    compareStrings(selectedAuthScheme, "OAuthISU");

  return isOAuthISU;
}
