import { useState, useEffect } from "react";
import { Button, Col, Row } from "reactstrap";
import { NotificationBar } from "../../../../components/notification/NotificationBar";
import { CDataModalV2 } from "../../../../components/modal/CDataModalV2";

interface IQueryErrorNotificationBannerProps {
  errorMessage: string;
}

export const QueryErrorNotificationBanner = ({
  errorMessage,
}: IQueryErrorNotificationBannerProps) => {
  const [dismissedError, setDismissedError] = useState<string>();
  const [showDetailsModal, setShowDetailsModal] = useState(false);

  useEffect(() => {
    setDismissedError("");
  }, [errorMessage]);

  const ErrorDetailsModal = (
    <CDataModalV2
      displayed={showDetailsModal}
      title={"Query Failed"}
      close={() => setShowDetailsModal(false)}
      primaryButton={
        <Button color="primary" onClick={() => setShowDetailsModal(false)}>
          Close
        </Button>
      }
    >
      {errorMessage}
    </CDataModalV2>
  );

  if (!errorMessage || dismissedError === errorMessage) return <></>;

  return (
    <Row data-testid="banner-job-error">
      <Col>
        <NotificationBar
          message={errorMessage}
          barColor="notification-bar-red"
          linkText="Learn More"
          linkClickOverride={() => setShowDetailsModal(true)}
          onClose={() => setDismissedError(errorMessage)}
          hideTextOverflow={true}
        />
      </Col>
      {ErrorDetailsModal}
    </Row>
  );
};
