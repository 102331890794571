export function hasOverflow(element: any): boolean {
  if (!element || !(element instanceof HTMLElement)) {
    return false;
  }

  const hasOverflowingChildren =
    element.offsetHeight < element.scrollHeight ||
    element.offsetWidth < element.scrollWidth;

  return hasOverflowingChildren;
}
