import { useContext } from "react";
import useQueryTabs from "../Tabs/useQueryTabs";
import { QueryTabsContext } from "../Tabs/QueryTabsContext";
import { CDataModalV2 } from "../../../../components/modal/CDataModalV2";
import {
  ButtonType,
  CDataButton,
} from "../../../../components/buttons/CDataButton";

export type MaxTabsModalProps = {
  displayed: boolean;
  close: () => void;
};

export function MaxTabsModal(props: MaxTabsModalProps) {
  const { displayed, close } = props;
  const tabs = useQueryTabs();
  const tabContext = useContext(QueryTabsContext);

  function clearCurrentTab(removeCurrent: boolean) {
    if (removeCurrent) {
      tabs.RemoveTab(tabs.List[tabs.MaxTabs].id);
    } else if (
      tabContext.tabs
        .find((tab) => tab.id === tabContext.selectedTab)
        ?.tabName.includes("QueryBuilder") ||
      tabs.CurrentTabId === 0
    ) {
      tabs.RemoveTab(tabs.List[tabs.MaxTabs - 1].id);
    } else {
      tabs.RemoveTab(tabs.CurrentTabId);
    }
    close();
  }

  return (
    <CDataModalV2
      modalSize="lg"
      displayed={displayed}
      close={() => clearCurrentTab(true)}
      title="Clear Tab"
      spacedFooter={false}
      primaryButton={
        <CDataButton
          buttonType={ButtonType.Primary}
          onClick={() => {
            clearCurrentTab(false);
            tabs.ChangeTab(tabs.List[tabs.List.length - 1].id);
          }}
        >
          Confirm
        </CDataButton>
      }
      secondaryButton={
        <CDataButton
          buttonType={ButtonType.Secondary}
          onClick={() => clearCurrentTab(true)}
        >
          Cancel
        </CDataButton>
      }
    >
      You currently have the maximum amount of tabs open within the Data
      Explorer. By initiating this action it will clear the contents of the
      current tab. Press &quot;Confirm&quot; to continue or click
      &quot;Cancel&quot; and close another tab before proceeding.
    </CDataModalV2>
  );
}
