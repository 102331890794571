import React, { useState } from "react";
import classnames from "classnames";

import {
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

import { UserRole } from "../../models";
import { useUserInfo } from "../../hooks/useUserInfo";
import LogTable from "./LogTable";
import AuditTable from "./AuditTable";

const Logs = () => {
  const userInfo = useUserInfo();
  const isAdminUser =
    userInfo.Self.role === UserRole.Admin ||
    userInfo.Self.role === UserRole.ServiceUser;

  const [activeTab, setActiveTab] = useState<string>("1");

  function getComponentName() {
    return "pages-logs-Logs";
  }

  function toggleTab(tab: string) {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  function renderLogsPage() {
    // Prepare Query Log Table
    return (
      <div className="logs-page">
        <Row>
          <Col>
            <h1 className="h3 ms-1 mb-4">Logs</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <Nav tabs className="tabs" hidden={!isAdminUser}>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "1",
                  })}
                  onClick={() => toggleTab("1")}
                >
                  Query Log
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "2",
                  })}
                  onClick={() => toggleTab("2")}
                >
                  Audit Log
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <LogTable />
          </TabPane>
          <TabPane tabId="2">
            <AuditTable />
          </TabPane>
        </TabContent>
      </div>
    );
  }

  const contents = renderLogsPage();

  return (
    <Container fluid className={`p-0 ${getComponentName()}`}>
      {contents}
    </Container>
  );
};

export default Logs;
