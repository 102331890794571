import { IAPIColumn, IAPIFilter, IAPITable } from "../../../models";
import { parseKeyValuePairsFromTableRows } from "../ApiConnectorFactory";
import { getPerRowValue } from "./components/PerRowRequestHelper";
import { Column, ITableSettings, PseudoColumn, RootPath } from "./SetTable";

export function mapColumns(
  requestUrl: string,
  columns: Column[],
): IAPIColumn[] {
  const perRowColumns = getPerRowValue(requestUrl);
  const apiColumns = columns.map<IAPIColumn>((column, index) => {
    return {
      dataType: column.dataType!,
      isKey: column.primaryKey,
      isQuerySlicerKey: perRowColumns.has(column.columnName),
      name: column.columnName,
      ordinal: index,
      path: column.path,
    };
  });

  return apiColumns;
}

function mapRootPaths(rootPaths: RootPath[]) {
  return rootPaths.map((rootPath: RootPath) => {
    return rootPath.rootPath;
  });
}

export function mapTable(tableSettings: ITableSettings): IAPITable {
  const mappedColumns = mapColumns(
    tableSettings.requestUrl,
    tableSettings.columns,
  );
  const mappedRootPaths = mapRootPaths(tableSettings.rootPaths);
  const parsedHeaders = parseKeyValuePairsFromTableRows(tableSettings.headers);
  const parsedParameters = parseKeyValuePairsFromTableRows(
    tableSettings.queryParams,
  );
  const filters: IAPIFilter[] = tableSettings.filters;

  const perRowColumns = getPerRowValue(tableSettings.requestUrl);

  return {
    columns: mappedColumns,
    filters: filters,
    headers: parsedHeaders,
    name: tableSettings.tableName,
    previousName: tableSettings.previousTableName,
    queryParams: parsedParameters,
    requestBody: tableSettings.requestBody,
    requestMethod: tableSettings.requestMethod,
    requestUrl: tableSettings.requestUrl,
    responseType: tableSettings.responseType,
    rootPaths: mappedRootPaths,
    useQuerySlicer: tableSettings.useQuerySlicer,
    pseudoColumns: tableSettings.pseudoColumns?.map((pcol: PseudoColumn) => {
      return {
        dataType: pcol.dataType,
        name: pcol.pseudoColumnName,
        isMirrored: pcol.outputColumn,
        isRequired: pcol.required,
        isQuerySlicerKey: perRowColumns.has(pcol.pseudoColumnName),
      };
    }),
  };
}
