import React, { useState } from "react";
import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useWorkspaceModals } from "../../../hooks/useWorkspaceModals";
import { IWorkspaceListItem } from "../../../models";
import classNames from "classnames";

interface IWorkspaceRowTile {
  workspace: IWorkspaceListItem;
  setWorkspaces: (workspaceItem: IWorkspaceListItem[]) => void;
  selectedWorkspaces: IWorkspaceListItem[];
  workspaces: IWorkspaceListItem[];
  toggleCheckbox: (checkbox: IWorkspaceListItem) => void;
  disabled: boolean;
  isAdminUser: boolean;
}

const enum DropdownMenuOptions {
  Edit,
  Delete,
  Copy,
  Move,
}

export function WorkspaceRowTile(props: IWorkspaceRowTile) {
  const [isActionMenuOpen, setIsActionMenuOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const allWorkspaces = [...props.selectedWorkspaces, ...props.workspaces];

  // In case we leave selected a workspace by checkbox but we decide an action on another not checked,
  // This filter will remove duplicates and fix the need to refresh the age to see the updated list after the action was completed.
  const filteredAllWorkspaces = [
    ...new Map(
      allWorkspaces.map((workspace) => [workspace.id, workspace]),
    ).values(),
  ];

  const workspaceModalFunctions = useWorkspaceModals(
    filteredAllWorkspaces,
    props.setWorkspaces,
  );

  const handleDropdownClick = (option: DropdownMenuOptions) => {
    setIsActionMenuOpen(false);
    switch (option) {
      case DropdownMenuOptions.Edit: {
        navigate("/datasets/workspace/" + props.workspace.id);
        break;
      }
      case DropdownMenuOptions.Delete: {
        workspaceModalFunctions.deleteWorkspaceConfirmationModal(
          props.workspace,
        );
        break;
      }
      case DropdownMenuOptions.Copy: {
        workspaceModalFunctions.copyWorkspace(props.workspace.id);
        break;
      }
    }
  };

  return (
    <Row
      className={classNames(
        "row-tile cursor-pointer",
        { "disabled-card": !props.isAdminUser && props.disabled },
        { "padding-user-card": !props.isAdminUser },
      )}
      onClick={() => handleDropdownClick(DropdownMenuOptions.Edit)}
    >
      {props.isAdminUser ? (
        <Col className="checkbox ms-3 me-2 p-0">
          <Input
            type="checkbox"
            checked={props.selectedWorkspaces.some(
              (tile) => tile.id === props.workspace.id,
            )}
            onClick={(event: any) => {
              event.stopPropagation();
              props.toggleCheckbox(props.workspace);
            }}
            className="m-0 cursor-pointer"
          />
        </Col>
      ) : null}
      <Col className="folder-icon mx-2 p-0">
        <i className={"fa-regular fa-folder-open"} />
      </Col>
      <Col className="name-and-description text-truncate mx-0 p-0">
        <Row className="mx-0 p-0">
          <Col className="name text-truncate">{props.workspace.name}</Col>
        </Row>
        <Row className="mx-0 p-0">
          <Col className="name text-truncate">
            {props.workspace.description}
          </Col>
        </Row>
      </Col>
      <Col className="assets-count mx-2 p-0">
        <i className="fa-regular fa-files assets-icon"></i>&nbsp;
        {props.workspace.childCount}&nbsp;assets
      </Col>
      {props.isAdminUser ? (
        <Col className="ellipses-icon ms-2 me-3 p-0">
          <Dropdown
            isOpen={isActionMenuOpen}
            toggle={(event: any) => {
              event.preventDefault();
              event.stopPropagation();
              setIsActionMenuOpen(!isActionMenuOpen);
            }}
          >
            <DropdownToggle nav>
              <i className="fa fa-regular fa-ellipsis-vertical p-2"></i>
            </DropdownToggle>
            <DropdownMenu
              direction="left"
              className="options-menu inline-dropdown"
            >
              <DropdownItem
                onClick={() => handleDropdownClick(DropdownMenuOptions.Edit)}
              >
                Edit
              </DropdownItem>
              <DropdownItem
                onClick={() => handleDropdownClick(DropdownMenuOptions.Delete)}
              >
                Delete
              </DropdownItem>
              <DropdownItem
                onClick={() => handleDropdownClick(DropdownMenuOptions.Copy)}
              >
                Copy
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </Col>
      ) : null}
    </Row>
  );
}
