import { createContext } from "react";
import { IQueryBuilderColumn } from "../../../../models/IQueryBuilderColumn";
import { IQueryBuilderTable } from "../../../../models/IQueryBuilderTable";

interface IAddDataModalContext {
  setProcessing: (processing: boolean) => void;
  setSchemas: (schemas: string[]) => void;
  setTables: (tables: IQueryBuilderTable[]) => void;
  setNewTableColumns: (columns: IQueryBuilderColumn[]) => void;
  setJoinTableColumns: (columns: IQueryBuilderColumn[]) => void;
}

export const AddDataModalContext = createContext<IAddDataModalContext>(null!);
