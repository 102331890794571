import { MouseEventHandler } from "react";
import {
  ButtonDropdown,
  ButtonDropdownProps,
  DropdownItem,
  DropdownItemProps,
  DropdownMenu,
  DropdownToggle,
  Tooltip,
  TooltipProps,
} from "reactstrap";

import { getSettingsPageSubscriptionTab } from "../../../../utility/SubscriptionProvider";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useFeatureFlags } from "../../../../hooks/useFeatureFlags";
import { useUserInfo } from "../../../../hooks/useUserInfo";
import { UserRole } from "../../../../models";
import { jobsConstants } from "../../../jobs/Jobs.constants";

export interface IQueryEditorSaveButton {
  areDerivedViewsAllowed: boolean;
  connectionsOverLimit: boolean;
  createDropdownOpen: boolean;
  handleSaveClick: DropdownItemProps["onClick"];
  isQueryUser: boolean;
  isSaveChangesVisible: boolean;
  openSaveChangesModal: MouseEventHandler<HTMLElement>;
  areSaveAsButtonsDisabled: boolean;
  openCreateDerivedViewModal: () => void;
  openCreateSavedQueryModal: () => void;
  toggle: TooltipProps["toggle"];
  toggleCreateDropdown: ButtonDropdownProps["toggle"];
  tooltipOpen: boolean;
  usersOverLimit: boolean;
  queryText: string;
}

export const QueryEditorSaveButton = (props: IQueryEditorSaveButton) => {
  const navigate = useNavigate();
  const flags = useFeatureFlags().getFlags(["scheduled_queries"]);
  const user = useUserInfo();

  const addScheduledQuery = () => {
    navigate(`/jobs/editScheduledQuery/${jobsConstants.DEFAULT_JOB_ID}`, {
      state: {
        queryText: props.queryText,
      },
    });
  };

  return (
    <ButtonDropdown
      isOpen={props.createDropdownOpen}
      toggle={props.toggleCreateDropdown}
      className="save-dropdown"
    >
      <DropdownToggle
        caret
        outline
        color="primary"
        className="save-dropdown-toggle"
        data-testid="save-dropdown-toggle"
        disabled={props.connectionsOverLimit || props.usersOverLimit}
      >
        <span>
          <i className="fa fa-edit align-middle add-connection-icon no-pointer-event" />
          Save
        </span>
      </DropdownToggle>
      <DropdownMenu className="save-dropdown-menu">
        <div id="createActions">
          <DropdownItem
            onClick={(event) => {
              event.preventDefault();
              props.openCreateDerivedViewModal();
            }}
            disabled={props.areSaveAsButtonsDisabled}
            hidden={props.isQueryUser}
            className={!props.areDerivedViewsAllowed ? "disabled-feature" : ""}
            data-testid="create-derived-view"
          >
            <i className="fa fa-border-none fa-solid align-middle save-icon no-pointer-event" />
            Save as Derived View
          </DropdownItem>
          <DropdownItem
            onClick={(event) => {
              event.preventDefault();
              props.openCreateSavedQueryModal();
            }}
            disabled={props.areSaveAsButtonsDisabled}
            color="secondary"
            data-testid="create-saved-query"
            className={
              props.areSaveAsButtonsDisabled ? "me-2 no-pointer-event" : "me-2"
            }
          >
            <i className="fa fa-save fa-solid align-middle save-icon no-pointer-event" />
            Save as Saved Query
          </DropdownItem>
          {flags.scheduled_queries.enabled &&
            !user.IsInRole(UserRole.Query) && (
              <DropdownItem
                onClick={(event) => {
                  event.preventDefault();
                  addScheduledQuery();
                }}
                disabled={props.areSaveAsButtonsDisabled}
                color="secondary"
                data-testid="create-scheduled-query"
                className={
                  props.areSaveAsButtonsDisabled
                    ? "me-2 no-pointer-event"
                    : "me-2"
                }
              >
                <i className="fa fa-solid fa-clock save-icon align-middle no-pointer-event" />
                Save as Scheduled Query
              </DropdownItem>
            )}
          {props.isSaveChangesVisible && (
            <>
              <Box sx={{ borderTop: 1 }} className="divider" />
              <DropdownItem
                onClick={props.openSaveChangesModal}
                color="secondary"
                data-testid="button-save-changes"
                disabled={props.areSaveAsButtonsDisabled}
              >
                <i className="fa fa-save align-middle save-icon no-pointer-event" />
                Save Changes
              </DropdownItem>
            </>
          )}
        </div>
        {!props.areDerivedViewsAllowed ? (
          <Tooltip
            placement="left"
            target="createActions"
            autohide={false}
            toggle={props.toggle}
            isOpen={props.tooltipOpen}
          >
            <a
              className="upgrade-link"
              onClick={() =>
                navigate(
                  "/settings?defaultTab=" + getSettingsPageSubscriptionTab(),
                )
              }
            >
              Upgrade
            </a>
            &nbsp; your subscription with our Virtualization Package add-on to
            use this feature.
          </Tooltip>
        ) : null}
      </DropdownMenu>
    </ButtonDropdown>
  );
};
