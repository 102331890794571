import React from "react";
import { Button, Col, Row } from "reactstrap";
import {
  getOdataEndpointWorkspace,
  getTdsHostName,
  getTdsPortNumber,
} from "../../../services/endpointService";
import TabWrapper, { ITabComponent } from "../../../components/TabWrapper";
import { OpenAPIDownload } from "./components/OpenAPIDownload";
import { EndpointsModalLabel } from "./components/EndpointsModalLabel";
import { EndpointsModalUsernameRow } from "./components/EndpointsModalUsernameRow";
import { EndpointsModalPasswordRow } from "./components/EndpointsModalPasswordRow";
import { CDataModalV2 } from "../../../components/modal/CDataModalV2";
import { DisabledInputWithCopyButton } from "../../../components/form/DisabledInputWithCopyButton";

/**
 * Contains the contents of a modal that displays endpoint connection information. This information is dynamically
 * populated based on the endpoint options that have been selected in the Workspace edit panel.
 */

export interface IWorkspaceEditEndpointsModal {
  endpoints: EndpointModalOptions[];
  workspaceName: string;
  workspaceId: string;
  isDisplayed: boolean;
  setIsDisplayed: (isDisplayed: boolean) => void;
}

export enum EndpointModalOptions {
  VirtualSQLServer,
  OData,
}

export const WorkspaceEditEndpointsModal = (
  props: IWorkspaceEditEndpointsModal,
) => {
  const tdsHostName: string = getTdsHostName();
  const tdsPortNumber: string = getTdsPortNumber();
  const odataEndpoint: string = getOdataEndpointWorkspace(
    encodeURIComponent(props.workspaceName),
  );

  const virtualSQLServerContent = (
    <>
      <EndpointsModalLabel tab={EndpointModalOptions.VirtualSQLServer} />
      <EndpointsModalUsernameRow
        tab={EndpointModalOptions.VirtualSQLServer}
        workspaceName={props.workspaceName}
      />
      <Row className="mb-2">
        <Col className="sql-server-host-name">
          <div className="form-field-title">SQL Server Host Name:</div>
        </Col>
        <Col className="client-endpoint">
          <DisabledInputWithCopyButton text={tdsHostName} />
        </Col>

        <Col className="client-endpoint-adjusted-port">
          <div className="form-field-title">Port:</div>
        </Col>
        <Col className="client-endpoint">
          <DisabledInputWithCopyButton text={tdsPortNumber} />
        </Col>
      </Row>

      <Row className="mb-2">
        <Col className="sql-server-host-name">
          <div className="form-field-title">Database:</div>
        </Col>
        <Col className="ps-0 client-endpoint-username">
          <span>If required, specify the name of this Workspace.</span>
        </Col>
      </Row>
      <EndpointsModalPasswordRow tab={EndpointModalOptions.VirtualSQLServer} />
    </>
  );

  const odataContent = (
    <>
      <EndpointsModalLabel tab={EndpointModalOptions.OData} />
      <EndpointsModalUsernameRow
        tab={EndpointModalOptions.OData}
        workspaceName={props.workspaceName}
      />
      <Row className="mb-2">
        <Col className="client-endpoint-adjusted-powerbi">
          <div className="form-field-title">OData URL:</div>
        </Col>
        <Col className="ps-0 client-endpoint-username">
          <DisabledInputWithCopyButton text={odataEndpoint} />
        </Col>
      </Row>
      <EndpointsModalPasswordRow tab={EndpointModalOptions.OData} />
    </>
  );

  const openAPIContent = (
    <OpenAPIDownload
      workspaceName={props.workspaceName}
      workspaceId={props.workspaceId}
    />
  );

  const tabContent = () => {
    const tabs: ITabComponent[] = [];

    if (props.endpoints.includes(EndpointModalOptions.VirtualSQLServer)) {
      tabs.push({
        tabName: "Virtual SQL Server",
        tabEnum: "1",
        tabComponent: virtualSQLServerContent,
      });
    }

    if (props.endpoints.includes(EndpointModalOptions.OData)) {
      tabs.push({
        tabName: "OData",
        tabEnum: "2",
        tabComponent: odataContent,
      });
    }

    tabs.push({
      tabName: "OpenAPI",
      tabEnum: "3",
      tabComponent: openAPIContent,
    });

    return (
      <TabWrapper
        tabs={tabs}
        smallerBottomPadding={true}
        activeTabWhiteBackground={true}
      />
    );
  };

  return (
    <CDataModalV2
      fade={false}
      displayed={props.isDisplayed}
      close={() => {
        props.setIsDisplayed(false);
      }}
      title="Endpoints"
      spacedFooter={false}
      primaryButton={
        <Button color="primary" onClick={() => props.setIsDisplayed(false)}>
          Close
        </Button>
      }
    >
      {tabContent()}
    </CDataModalV2>
  );
};
