import {
  APIFilterOperator,
  APIFilterType,
  APITableRequestMethod,
  APITableResponseType,
  DataType,
  IAPIColumn,
  IAPIFilter,
  IAPITable,
} from "../../../models";
import { createDeepCopy } from "../../../utility/CreateDeepCopy";
import { KeyValueTableRow } from "../ApiConnector";
import { initialGlobalSettings } from "../ApiConnector.mock";
import { Column, ITableSettings, RootPath } from "./SetTable";
import { IAPIConnectorSettings } from "./types/IAPIConnectorSettings";

export const defaultKeyValueTableRow: KeyValueTableRow[] = [
  {
    name: "",
    value: "",
  },
];
export const defaultRootPaths: RootPath[] = [
  {
    rootPath: "",
  },
];

export const defaultAPIColumns: IAPIColumn[] = [
  {
    dataType: DataType.VARCHAR,
    isKey: false,
    isQuerySlicerKey: true, // If child table selected, this makes sure at least one parent ref radio button is selected by default.
    name: "",
    ordinal: 0,
    path: "",
  },
];

export const defaultFilters: IAPIFilter[] = [];

export const defaultColumns: Column[] = [
  {
    columnName: "",
    path: "",
    dataType: DataType.VARCHAR,
    primaryKey: false,
  },
];

export const emptyTable: IAPITable = {
  columns: defaultAPIColumns,
  filters: defaultFilters,
  headers: { Accept: "*/*" },
  name: "",
  queryParams: {},
  requestBody: "",
  requestMethod: APITableRequestMethod.GET,
  requestUrl: "",
  responseType: APITableResponseType.JSON,
  rootPaths: [""],
  useQuerySlicer: false,
};

const testColumns: IAPIColumn[] = [
  {
    dataType: DataType.BIGINT,
    isKey: false,
    isQuerySlicerKey: false,
    name: "testColumn1",
    ordinal: 0,
    path: "testPath1",
  },
];

const testFilters: IAPIFilter[] = [
  {
    columnName: "testColumn1",
    headerName: "testColumnHeader",
    operator: APIFilterOperator.Equal,
    paramName: "",
    type: APIFilterType.Header,
  },
];

export const testTable: IAPITable = {
  columns: testColumns,
  filters: testFilters,
  headers: { header1: "headerValue1", header2: "headerValue2" },
  name: "Table1",
  queryParams: { param1: "paramValue1", param2: "paramValue2" },
  requestBody: "THIS IS A REQUEST BODY",
  requestMethod: APITableRequestMethod.POST,
  requestUrl: "www.test.com",
  responseType: APITableResponseType.XML,
  rootPaths: ["rootPath1", "rootPath2"],
  useQuerySlicer: false,
};

export const emptyTableSettings: ITableSettings = {
  tableName: "",
  previousTableName: "",
  responseType: APITableResponseType.JSON,
  requestMethod: APITableRequestMethod.GET,
  requestUrl: "",
  requestBody: "",
  queryParams: [],
  headers: [],
  rootPaths: [],
  columns: [],
  filters: [],
  useQuerySlicer: false,
  pseudoColumns: [],
};

/**
 * Creates fairly empty API connector options for use in unit tests.
 * This object is new every time this function is called so it can be directly mutated to change for tests.
 *  */
export function createMockAPIConnectorSettings(): IAPIConnectorSettings {
  return {
    globalSettings: createDeepCopy(initialGlobalSettings),
    tableSettings: createDeepCopy(emptyTableSettings),
    setGlobalSettings: () => {
      // no-op
    },
    setTableSettings: () => {
      // no-op
    },
    setUnsavedChanges: () => {
      // no-op
    },
  };
}

export const jsonExample = `{
    "Metadata": {
      "CreatedDate": {
        "Date": "2023-06-03",
        "Time": "12:00:00"
      },
      "Author": {
        "FirstName": "Your",
        "LastName": "Name"
      },
      "Version": "1.0"
    },
      "Employees": {
      "emp1": {
        "Transaction Date": "2022-03-20",
        "Transaction Time": "07:21:55",
        "Amount": 15000,
        "Name": "John Doe",
        "Employee ID": 12345,
        "Contact": {
          "Email": "john.doe@example.com",
          "Phone": "123-456-7890"
        },
        "Address": [
          {
            "Street": "123 Main St",
            "City": "New York",
            "State": "NY",
            "Zip": "10001"
          },
          {
            "Street": "456 Elm St",
            "City": "Los Angeles",
            "State": "CA",
            "Zip": "90001"
          }
        ]
      },
      "emp2": {
        "Transaction Date": "2022-03-23",
        "Transaction Time": "07:21:55",
        "Amount": 750,
        "Name": "Jane Doe",
        "Employee ID": 12345,
        "Contact": {
          "Email": "jane.doe@example.com",
          "Phone": "987-654-3210"
        },
        "Address": [
          {
            "Street": "789 Oak St",
            "City": "Chicago",
            "State": "IL",
            "Zip": "60001"
          }
        ]
      },
      "emp3": {
        "Transaction Date": "2022-03-22",
        "Transaction Time": "07:21:55",
        "Amount": 16809,
        "Name": "test name",
        "Employee ID": 54321,
        "Contact": {
          "Email": "test.test@example.com",
          "Phone": "555-123-4567"
        },
        "Address": [
          {
            "Street": "321 Pine St",
            "City": "San Francisco",
            "State": "CA",
            "Zip": "94101"
          }
        ]
      },
      "emp4": {
        "Transaction Date": "2022-03-21",
        "Transaction Time": "07:21:55",
        "Amount": 4700,
        "Name": "other name",
        "Employee ID": 98765,
        "Contact": {
          "Email": "test.2@example.com",
          "Phone": "555-123-4567"
        },
        "Address": [
          {
            "Street": "555 Broad St",
            "City": "Seattle",
            "State": "WA",
            "Zip": "98101"
          },
          {
            "Street": "999 Elm St",
            "City": "Miami",
            "State": "FL",
            "Zip": "33101"
          }
        ]
      }
    }
  }`;

export const xmlExample = `<?xml version="1.0" encoding="UTF-8" ?>
<Root>
  <Metadata>
    <CreatedDate>
      <Date>2023-06-03</Date>
      <Time>12:00:00</Time>
    </CreatedDate>
    <Author>
      <FirstName>Your</FirstName>
      <LastName>Name</LastName>
    </Author>
    <Version>1.0</Version>
  </Metadata>
  <ArrayOfEmployees>
    <Employees>
      <employee>
        <TransactionDate>2022-03-20</TransactionDate>
        <TransactionTime>07:21:55</TransactionTime>
        <Amount>15000</Amount>
        <Name>John Doe</Name>
        <EmployeeID>12345</EmployeeID>
        <Contact>
          <Email>john.doe@example.com</Email>
          <Phone>123-456-7890</Phone>
        </Contact>
        <Address>
          <Street>123 Main St</Street>
          <City>New York</City>
          <State>NY</State>
          <Zip>10001</Zip>
        </Address>
        <Address>
          <Street>456 Elm St</Street>
          <City>Los Angeles</City>
          <State>CA</State>
          <Zip>90001</Zip>
        </Address>
      </employee>
      <employee>
        <TransactionDate>2022-03-23</TransactionDate>
        <TransactionTime>07:21:55</TransactionTime>
        <Amount>750</Amount>
        <Name>Jane Doe</Name>
        <EmployeeID>12345</EmployeeID>
        <Contact>
          <Email>jane.doe@example.com</Email>
          <Phone>987-654-3210</Phone>
        </Contact>
        <Address>
          <Street>789 Oak St</Street>
          <City>Chicago</City>
          <State>IL</State>
          <Zip>60001</Zip>
        </Address>
      </employee>
    </Employees>
    <Employees>
      <employee>
        <TransactionDate>2022-03-22</TransactionDate>
        <TransactionTime>07:21:55</TransactionTime>
        <Amount>16809</Amount>
        <Name>test name</Name>
        <EmployeeID>54321</EmployeeID>
        <Contact>
          <Email>test.test@example.com</Email>
          <Phone>555-123-4567</Phone>
        </Contact>
        <Address>
          <Street>321 Pine St</Street>
          <City>San Francisco</City>
          <State>CA</State>
          <Zip>94101</Zip>
        </Address>
      </employee>
      <employee>
        <TransactionDate>2022-03-21</TransactionDate>
        <TransactionTime>07:21:55</TransactionTime>
        <Amount>4700</Amount>
        <Name>other name</Name>
        <EmployeeID>98765</EmployeeID>
        <Contact>
          <Email>test.2@example.com</Email>
          <Phone>555-123-4567</Phone>
        </Contact>
        <Address>
          <Street>555 Broad St</Street>
          <City>Seattle</City>
          <State>WA</State>
          <Zip>98101</Zip>
        </Address>
        <Address>
          <Street>999 Elm St</Street>
          <City>Miami</City>
          <State>FL</State>
          <Zip>33101</Zip>
        </Address>
      </employee>
    </Employees>
  </ArrayOfEmployees>
</Root>
`;
