import { useEffect, useState } from "react";
import { StepNames } from "./StepNames";
import { IStepBodyProps } from "../../../../../components/wizard/components/StepWizardStep";
import BigNavigationButton from "../../../../../components/buttons/BigNavigationButton";
import { UncontrolledTooltip } from "reactstrap";
import { IWorkspaceItemSummary } from "../../../../../models";
import { useAPI } from "../../../../../components/useAPI";
import { RequestType } from "../../../../../components/withAPI";
import Loader from "../../../../../components/Loader";
import classNames from "classnames";
import { useAppSelector } from "../../../../../redux/hooks";

export function SelectAssetType(props: IStepBodyProps) {
  const [workspaceItemSummary, setWorkspaceItemSummary] =
    useState<IWorkspaceItemSummary>();
  const [loading, setLoading] = useState(true);
  const userId = useAppSelector((state) => state.user.id);
  const api = useAPI();

  useEffect(() => {
    async function callAsync() {
      await getWorkspaceItemSummary();
    }
    callAsync();
  }, []); // eslint-disable-line

  async function getWorkspaceItemSummary() {
    const { status, payload } = await api.callAPI(
      RequestType.Get,
      `/workspaces/${userId}/workspaceItemSummary`,
      "Error fetching workspace item summary.",
    );

    if (status === 200) {
      const itemSummary = payload as IWorkspaceItemSummary;
      setWorkspaceItemSummary(itemSummary);
      setLoading(false);
    }
  }

  const toolTipMessages = {
    tablesAndViews:
      "Setup a Connection to add tables & views to your workspace.",
    derivedViews:
      "Create a Derived View in the Data Explorer to add them to your workspace.",
  };

  const folderButton = !window.location.href.includes("folder") ? (
    <BigNavigationButton
      title="Folder"
      onClick={() => {
        props.goToNamedStep!(StepNames.SelectFolderName);
      }}
    />
  ) : null;

  const disableConnections = classNames({
    "no-pointer-event disabled": !workspaceItemSummary?.isExistingConnection,
  });

  const disableDerivedViews = classNames({
    "no-pointer-event disabled": !workspaceItemSummary?.isExistingDerivedView,
  });

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="steps-SelectAssetType">
      <div className="mb-3">Select the item you would like to add.</div>
      {folderButton}
      <div id="tablesAndViews">
        <BigNavigationButton
          className={disableConnections}
          title="Tables & Views"
          onClick={() => props.goToNamedStep!(StepNames.SelectConnection)}
        />
        {!workspaceItemSummary?.isExistingConnection ? (
          <UncontrolledTooltip
            placement="top"
            target="tablesAndViews"
            trigger="hover"
          >
            {toolTipMessages.tablesAndViews}
          </UncontrolledTooltip>
        ) : null}
      </div>
      <div id="derivedViews">
        <BigNavigationButton
          className={disableDerivedViews}
          title="Derived Views"
          onClick={() => props.goToNamedStep!(StepNames.SelectDerivedViews)}
        />
        {!workspaceItemSummary?.isExistingDerivedView ? (
          <UncontrolledTooltip
            placement="top"
            target="derivedViews"
            trigger="hover"
          >
            {toolTipMessages.derivedViews}
          </UncontrolledTooltip>
        ) : null}
      </div>
    </div>
  );
}
