import { Card, CardBody, Col } from "reactstrap";
import { setBaseDocsAddress } from "../../../utility/FlyoutDocsFactory";
import { showFlyout } from "../../../redux/actions";
import { Form } from "react-bootstrap";
import { NotificationBar } from "../../../components/notification/NotificationBar";
import { useAppDispatch } from "../../../redux/hooks";

interface IPrerequisiteWarningCard {
  /**
   * Determines whether the warning card is currently visible.
   */
  isShowing: boolean;
  driverName: string;
  /**
   * Opens the documentation flyout at the specific documentation section.
   * @example "prerequisite"
   */
  docSection: string;
  /**
   * The message to be displayed on the warning card.
   */
  message: string;
  /**
   * This state variable indicates whether the checkbox has been checked or not.
   */
  prerequisiteChecked: boolean;
  /**
   * This state variable is used to manage invalid-feedback helper text.
   */
  isPrerequisiteCheckInvalid: boolean;
  setPrerequisiteChecked: (value: boolean) => void;
  setPrerequisiteCheckInvalid: (value: boolean) => void;
}
function PrerequisiteWarningCard(props: IPrerequisiteWarningCard) {
  const dispatch = useAppDispatch();
  const {
    isShowing,
    driverName,
    docSection,
    message,
    prerequisiteChecked,
    isPrerequisiteCheckInvalid,
    setPrerequisiteChecked,
    setPrerequisiteCheckInvalid,
  } = props;
  return isShowing ? (
    <Card className="prerequisite-card">
      <CardBody>
        <Col>
          <h5 className="card-title">Prerequisites</h5>
        </Col>
        <NotificationBar
          message={message}
          barColor="notification-bar-pale-orange"
          linkText="View Prerequisites"
          linkClickOverride={() => {
            const docUrl = `${setBaseDocsAddress()}${driverName}.html`;
            dispatch(showFlyout(docUrl, null, "Connection", docSection));
          }}
        />
        <Form.Group className="prerequisite-checkbox">
          <Col className="mt-2">
            <Form.Check
              required
              id="prerequisites-checkbox"
              type="checkbox"
              label={
                "I have completed all prerequisite steps listed within the documentation."
              }
              checked={prerequisiteChecked}
              isInvalid={isPrerequisiteCheckInvalid}
              onInvalid={(e) => {
                e.preventDefault();
                setPrerequisiteCheckInvalid(!prerequisiteChecked);
              }}
              onChange={(e) => {
                setPrerequisiteChecked(e.target.checked);
                setPrerequisiteCheckInvalid(!e.target.checked);
              }}
              feedback={
                "Please validate that you've completed the prerequisite steps."
              }
            />
          </Col>
        </Form.Group>
      </CardBody>
    </Card>
  ) : (
    <></>
  );
}

export default PrerequisiteWarningCard;
