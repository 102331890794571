import { Button, Card, CardBody } from "reactstrap";
import { logoutUser } from "../../routes/AuthorizeContext";

const UserOverageError = () => (
  <div className="text-center">
    <Card>
      <CardBody>
        <div className="m-sm-4">
          <h1 className="mb-3">User Seat Limit Reached</h1>
          <p className="mb-4">
            Your companies seat limit has been reached. Please contact your
            administrator to increase the user seat count.
          </p>
          <div className="text-center">
            <Button
              color="primary"
              tag="a"
              onClick={() => logoutUser()}
              className="logout-button"
              data-testid="button-logout"
            >
              <i className="fa fa-sign-out-alt align-middle me-2 no-pointer-event" />
              Sign Out
            </Button>
          </div>
        </div>
      </CardBody>
    </Card>
  </div>
);

export default UserOverageError;
