import { CDataMenu } from "src/components/menu/CDataMenu";
import { CDataMenuItem } from "src/components/menu/CDataMenuItem";
import { CDataTypography } from "src/components/text/CDataTypography";
import useQueryTabs from "./useQueryTabs";
import { QueryTabType } from "./queryTabType";

export interface OpenTabMenuProps {
  openTabMenuAnchor: null | HTMLElement;
  setOpenTabMenuAnchor: (openTabMenuAnchor: null | HTMLElement) => void;
}

export function OpenTabMenu(props: OpenTabMenuProps) {
  const { openTabMenuAnchor, setOpenTabMenuAnchor } = props;

  const tabs = useQueryTabs();

  const openQueryBuilderTab = () => {
    setOpenTabMenuAnchor(null);
    tabs.AddQueryBuilderTab("", "", "", "", "");
  };

  const openEditorTab = () => {
    setOpenTabMenuAnchor(null);
    tabs.AddEmptyTab(QueryTabType.Query, undefined, "");
  };

  return (
    <CDataMenu
      anchorEl={openTabMenuAnchor}
      open={Boolean(openTabMenuAnchor)}
      onClose={() => setOpenTabMenuAnchor(null)}
      className="open-tab-menu"
    >
      <CDataMenuItem onClick={openQueryBuilderTab}>
        <div className="icon-div mx-2">
          <i className="fa-solid fa-layer-plus align-middle home-tab-icon" />
        </div>
        <CDataTypography className="me-2">Query Builder</CDataTypography>
      </CDataMenuItem>
      <CDataMenuItem onClick={openEditorTab} data-testid="button-add-tab">
        <div className="icon-div mx-2">
          <i className="fa-solid fa-code align-middle home-tab-icon" />
        </div>
        <CDataTypography className="me-2">SQL Editor</CDataTypography>
      </CDataMenuItem>
    </CDataMenu>
  );
}
