export interface JSONTreeTableRow {
  id: string;
  name: string;
  value: any;
  type: JsonValueType;
  path: string;
  subRows?: JSONTreeTableRow[];
}

export enum JsonValueType {
  String = "string",
  Number = "number",
  Boolean = "boolean",
  Null = "null",
  Object = "object",
  Array = "array",
}

const parseJSONAsTreeTable = (json: string) => {
  const createRow = (
    id: string,
    value: any,
    type: JsonValueType,
    subRows?: JSONTreeTableRow[],
  ): JSONTreeTableRow => {
    const lastPathSegment = id.split("/").pop() || "";
    return {
      id,
      name: lastPathSegment,
      value,
      type,
      path: id,
      subRows,
    };
  };

  const buildRows = (value: any, path: string): JSONTreeTableRow[] => {
    const sanitizedPath = sanitizePath(path);

    // Handle arrays through recursion
    if (Array.isArray(value)) {
      const subRows = value.flatMap((element, index) =>
        buildRows(element, `${sanitizedPath}[${index}]`),
      );
      return [createRow(path, "", JsonValueType.Array, subRows)];
    }

    // Handle objects through recursion
    if (typeof value === "object" && value !== null) {
      const subRows = Object.entries(value).flatMap(([key, val]) =>
        buildRows(val, `${sanitizedPath}/${key}`),
      );
      return [createRow(path, value, JsonValueType.Object, subRows)];
    }

    // Handle leaf nodes by returning the node as a row
    return [createRow(path, value, typeof value as JsonValueType)];
  };

  try {
    const parsedJSON = JSON.parse(json);
    return buildRows(parsedJSON, "/");
  } catch (e) {
    return new Error(`Error parsing JSON: ${e}`);
  }
};

const sanitizePath = (path: string): string => {
  return path === "/" ? "" : path;
};

export default parseJSONAsTreeTable;
