export function setBaseDocsAddress() {
  if (import.meta.env.NODE_ENV === "test") return "";
  return window.VITE_CONFIG.VITE_DOC_URL;
}

export function connectionsPageDocs() {
  if (import.meta.env.NODE_ENV === "test") return "";
  return window.VITE_CONFIG.VITE_DOC_URL + "Connections.html";
}

export function randomFlyoutKey() {
  return Math.floor(Math.random() * 100);
}
