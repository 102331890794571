import { useEffect, useState } from "react";
import { IQueryBuilderCommonProps } from "../../../models/IQueryBuilderCommonProps";
import { Col, Row, Spinner } from "reactstrap";
import { CDataTypography } from "../../../../../../../components/text/CDataTypography";
import { FilterTypeAndRange } from "./FilterTypeAndRange";
import {
  FilterType,
  IQueryBuilderFilter,
} from "../../../models/IQueryBuilderFilter";
import Loader from "../../../../../../../components/Loader";
import { IQueryBuilderColumn } from "../../../models/IQueryBuilderColumn";
import { IQueryBuilderModel } from "../../../models/IQueryBuilderModel";
import { useMutation } from "@tanstack/react-query";
import { IQueryBuilderTable } from "../../../models/IQueryBuilderTable";
import { DataType } from "../../../../../../../models";
import { getColumnsForTable } from "../../../../../../../api/metadata/getColumnsForTable";
import { QueryBuilderTableDropdownOption } from "../components/QueryBuilderTableDropdownOption";
import { CDataAutocomplete } from "@cdatacloud/component-library";

interface AddFiltersModalContentProps extends IQueryBuilderCommonProps {
  queryFilter: IQueryBuilderFilter;
  setQueryFilter: (queryFilter: IQueryBuilderFilter) => void;
  filterToEdit?: IQueryBuilderFilter;
  setIsConfirmButtonDisabled: (isDisabled: boolean) => void;
}

export function AddFiltersModalContent(props: AddFiltersModalContentProps) {
  const { queryData, queryFilter, setQueryFilter, filterToEdit } = props;

  const [columns, setColumns] = useState<IQueryBuilderColumn[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(
    props.filterToEdit !== undefined,
  );

  const { mutateAsync: fetchAndProcessColumns } = useMutation({
    mutationKey: ["columns"],
    mutationFn: (table: IQueryBuilderTable) =>
      getColumnsForTable({
        connectionName: table.connectionName,
        schema: table.schema,
        tableName: table.tableName,
      }),
    onSuccess: (data, params) => {
      const allTables: IQueryBuilderTable[] = [
        queryData.from,
        ...queryData.joins.map((j) => j.right.table),
      ];
      const myTable = allTables.find(
        (t) => t.tableAlias === params.tableAlias,
      )!;

      const columns = data.map((col) => {
        const mappedColumn: IQueryBuilderColumn = {
          dataType: col.dataType,
          table: myTable,
          column: col.columnName,
        };

        return mappedColumn;
      });
      setColumns(columns);
    },
    onError: () => {
      clearColumns();
    },
    onSettled: () => {
      setIsLoading(false);
    },
    meta: {
      errorMessage: "Failed to get schema list due to the following error:",
    },
  });

  useEffect(() => {
    async function fetchInitialColumns(table: IQueryBuilderTable) {
      await fetchAndProcessColumns(table);
    }

    if (filterToEdit !== undefined) {
      fetchInitialColumns(filterToEdit.column.table);
    } else if (
      queryFilter.column &&
      queryFilter.column.table.tableName &&
      !queryFilter.column.column
    ) {
      fetchInitialColumns(queryFilter.column.table);
    }
  }, []); // eslint-disable-line

  if (isLoading) {
    return <Loader />;
  }

  const getTableDropdownOptions = (
    queryData: IQueryBuilderModel,
    currentDriver: string,
  ): IQueryBuilderTable[] => {
    const currentTable = {
      ...queryData.from,
      driver: currentDriver,
    };

    const allTables = [
      currentTable,
      ...queryData.joins.map((j) => j.right.table),
    ];

    return allTables;
  };

  const currentTab = props.connectionsList.find(
    (x) => x.id === queryData.from.connectionId,
  );

  const allTables = getTableDropdownOptions(queryData, currentTab!.driver!);

  const onTableChange = async (newTable: IQueryBuilderTable) => {
    setQueryFilter({
      ...queryFilter,
      column: {
        table: newTable,
        column: "",
        dataType: DataType.VARCHAR,
      },
      filterType: FilterType.EqualTo,
      data: {},
    });

    setIsProcessing(true);
    await fetchAndProcessColumns(newTable);
    setIsProcessing(false);
  };

  const onColumnChange = async (newColumn: IQueryBuilderColumn) => {
    setQueryFilter({
      ...queryFilter,
      column: newColumn,
      filterType: FilterType.EqualTo,
      data: {},
    });
  };

  const clearColumns = () => {
    setQueryFilter({
      ...queryFilter,
      column: {
        ...queryFilter.column,
        column: "",
      },
    });
    setColumns([]);
  };

  return (
    <div className="add-filters-modal-body">
      <div>Specify the table and column to filter.</div>
      <Row>
        <Col>
          <CDataTypography
            variant="typography-variant-headline-5"
            className="required mb-2"
          >
            Table Name
          </CDataTypography>
          <CDataAutocomplete<IQueryBuilderTable>
            aria-label="Select Table Name"
            options={allTables}
            menuClass="query-builder-table-dropdown-menu"
            isOptionEqualToValue={(option, value) =>
              option.tableAlias === value.tableAlias
            }
            dropdownLabel={(option) => {
              return <QueryBuilderTableDropdownOption option={option} />;
            }}
            getOptionLabel={(option) => option.tableAlias}
            handleChange={(_event, newTable) => onTableChange(newTable)}
            disableWholeSelector={allTables.length === 1}
            selectedValue={
              queryFilter.column.table?.tableName?.length > 0
                ? queryFilter.column.table
                : null
            }
          />
        </Col>
        <Col>
          <CDataTypography
            variant="typography-variant-headline-5"
            className="required mb-2"
          >
            Column Name
          </CDataTypography>
          <CDataAutocomplete<IQueryBuilderColumn>
            options={columns}
            dropdownLabel={(option) => option.column}
            getOptionLabel={(option) => (option.column ? option.column : "")}
            handleChange={(_event, newColumn) => onColumnChange(newColumn)}
            isOptionEqualToValue={(option, value) => {
              return (
                option.column === value.column &&
                option.dataType === value.dataType
              );
            }}
            disableWholeSelector={
              props.queryFilter.column.table.tableName === ""
            }
            selectedValue={
              queryFilter.column?.column?.length > 0 ? queryFilter.column : null
            }
          />
        </Col>
      </Row>
      <FilterTypeAndRange
        queryFilter={props.queryFilter}
        setQueryFilter={props.setQueryFilter}
        setIsConfirmButtonDisabled={props.setIsConfirmButtonDisabled}
        isDisabled={queryFilter.column.column === ""}
      />
      <div hidden={!isProcessing} className="spinner-height-adjusted">
        <div className="loading-background" />
        <Spinner className="spinner-border loading-spinner" color="info" />
      </div>
    </div>
  );
}
