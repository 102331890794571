import { useContext, useEffect, useState } from "react";
import { ConnectionContext, IConnectionContext } from "./ConnectionContext";
import { useAPI } from "../../../components/useAPI";
import { RequestType } from "../../../components/withAPI";
import {
  ConnectionArgs,
  useConnectionCrudCalls,
} from "../../../hooks/useConnectionCrudCalls";
import {
  dynamicInputUpdated,
  getDisplayedBasicDriverProperties,
} from "./ConnectionFunctions";
import { IDynamicPropValue } from "../../../models/Drivers/IDynamicPropValue";
import { IDynamicPropertyArgs } from "../../../bffmodels/IDynamicPropertyArgs";
import { IHierarchyPropertyExtended } from "../../../bffmodels/IHierarchyPropertyExtended";
import { Form } from "react-bootstrap";
import { CDataAutocompleteFreeSolo } from "../../../components/select/CDataAutocompleteFreeSolo";
import { createFilterOptions, FilterOptionsState } from "@mui/material";
import { produce } from "immer";
import { ToastrError } from "../../../services/toastrService";
import { isGoogleDriver } from "./utils/google/isGoogleDriver";

export type DynamicPropsStatus = "success" | "failure" | "incomplete";

interface IDynamicConnectionProperty {
  property: IHierarchyPropertyExtended;
}

const DynamicConnectionProperty = (props: IDynamicConnectionProperty) => {
  const { property } = props;
  const [options, setOptions] = useState<IDynamicPropValue[]>([]);
  const [enabled, setEnabled] = useState<boolean>(true);
  const [value, setValue] = useState<IDynamicPropValue | null>(
    property.currentValue ? { name: "", value: property.currentValue } : null,
  );

  const api = useAPI();
  const connectionContext = useContext(ConnectionContext);
  const connectionCrudCalls = useConnectionCrudCalls(
    connectionContext as IConnectionContext,
    connectionContext.updatePreventNavigation!,
    undefined,
  );

  useEffect(() => {
    async function refreshDynamicOptions() {
      const request = await createDynamicPropertyRequest();
      const newOptions = await getDynamicPropertyOptions(request);

      // If the user is re-authenticating after previously making a selection, update their selected option to the full option once the list loads
      if (property.currentValue) {
        let fullValue = matchCurrentValueToFullList(newOptions) ?? null;
        // If there's no match, it's a custom value. Add a label and add it to the options list.
        if (!fullValue) {
          fullValue = {
            name: "Custom Value",
            value: property.currentValue,
          };
          newOptions.push(fullValue);
        }
        setValue(fullValue);
      }
      setOptions(newOptions);
      connectionContext.setDynamicPropsStatus("success");
    }

    const oauthProps = connectionContext.oauthProps || {};

    if (Object.keys(oauthProps).length > 0) {
      refreshDynamicOptions();
      setEnabled(true);
    } else if (property.currentValue) {
      // If an option was previously selected but OAuth has not been re-authenticated, show that option's stored value
      setOptions([{ name: "", value: property.currentValue }]);
      setEnabled(true);
    } else {
      setOptions([]);
      setEnabled(false);
    }
  }, [connectionContext.oauthProps]); // eslint-disable-line

  const createDynamicPropertyRequest = async () => {
    const basicDriverProperties = getDisplayedBasicDriverProperties(
      connectionContext,
      "EditConnection",
    );

    const formValues = connectionCrudCalls.compileFormValues(
      basicDriverProperties,
      true,
    );

    if (formValues == null) {
      return;
    }

    const request =
      await connectionCrudCalls.createConnectionRequest(formValues);
    if (request != null) {
      const requestWithOauth = await connectionCrudCalls.handleOauth(request);
      return requestWithOauth;
    } else {
      return null;
    }
  };

  const getDynamicPropertyOptions = async (
    connectionRequest: ConnectionArgs | null | undefined,
  ) => {
    // Convert property fields to string pairs because we don't support File types for dynamic prop requests
    const propsAsStringPairs = Object.fromEntries(
      Object.entries(connectionRequest?.Props ?? {}).map(([key, value]) => [
        key,
        String(value),
      ]),
    );

    const dynamicPropRequest: IDynamicPropertyArgs = {
      propertyName: property.name!,
      oAuthProps: connectionRequest?.OAuthProps,
      props: propsAsStringPairs,
      valuesQuery: property.valuesQuery!,
    };

    const { status, payload } = await api.callAPI(
      RequestType.Post,
      `drivers/${connectionContext.driverType}/dynamicPropValues`,
      "",
      dynamicPropRequest,
    );

    if (status === 200) {
      return payload.propertyValues ?? [];
    } else {
      ToastrError(
        "Failed to retrieve dynamic property values.",
        `We could not retrieve values for the '${property.propertyName}' property. If you have entered a custom value for this property, remove it and try connecting again.`,
      );
      connectionContext.setDynamicPropsStatus("failure");

      return [];
    }
  };

  const matchCurrentValueToFullList = (optionList: IDynamicPropValue[]) => {
    return optionList.find((option) => option.value === property.currentValue);
  };

  const getDynamicPropertyHelperText = () => {
    const signIn = isGoogleDriver(connectionContext.driverType)
      ? "Sign in with Google"
      : "Sign in";
    return (
      <div className="helper-text">
        {signIn} or click Save & Test to populate your {property.name}s.
      </div>
    );
  };

  const dropdownLabel = (option: IDynamicPropValue) => {
    if (option.name) {
      return (
        <span className="dynamic-connection-property-option">
          <span className="dynamic-connection-property-first-line">
            {option.name}
          </span>
          <span className="dynamic-connection-property-second-line">
            {option.value}
          </span>
        </span>
      );
    } else {
      return option.value;
    }
  };

  function handleDynamicPropertyChange(
    newValue: IDynamicPropValue | string | null,
  ) {
    if (newValue == null) {
      setValue(null);
      dynamicInputUpdated(connectionContext, property.propertyName!, "");
      return;
    }

    let convertedValue: IDynamicPropValue;

    if (typeof newValue === "string") {
      convertedValue = { name: "Custom Value", value: newValue };
    } else {
      convertedValue = newValue;
    }

    // Add the custom value to the options array if it doesn't already exist
    const newOptions = produce(options, (draft) => {
      const optionExists = draft.some(
        (option) => option.value === convertedValue.value,
      );
      if (!optionExists) {
        draft.push(convertedValue);
      }
    });
    setOptions(newOptions);

    setValue(convertedValue);
    dynamicInputUpdated(
      connectionContext,
      property.propertyName!,
      convertedValue.value,
    );
  }

  function getOptionLabel(option: IDynamicPropValue | string) {
    if (typeof option === "string") {
      return option;
    } else {
      if (!option.name) return option.value;
      else return `${option.name}: ${option.value}`;
    }
  }

  function getSelectionOptions(): IDynamicPropValue[] {
    if (property.currentValue && !options) {
      return [{ name: "", value: property.currentValue }];
    } else {
      return options;
    }
  }

  const filter = createFilterOptions<IDynamicPropValue>();

  const filterFunction = (
    filterOptions: IDynamicPropValue[],
    params: FilterOptionsState<IDynamicPropValue>,
  ) => {
    const filtered = filter(filterOptions, params);
    const { inputValue } = params;
    const optionExists = filterOptions.some(
      (option) => option.value === inputValue || option.name === inputValue,
    );

    if (inputValue !== "" && !optionExists) {
      filtered.push({
        name: "Custom Value",
        value: inputValue,
      });
    }
    return filtered;
  };

  return (
    <div className="mb-3">
      <CDataAutocompleteFreeSolo<IDynamicPropValue>
        disableWholeSelector={!enabled}
        dropdownLabel={(option) => dropdownLabel(option)}
        filterOptions={filterFunction}
        getOptionLabel={(option) => getOptionLabel(option)}
        handleChange={(_event, option) => handleDynamicPropertyChange(option)}
        id={property.propertyName}
        options={getSelectionOptions()}
        selectedValue={value}
      />
      {/* This hidden form control is here to handle the real value of the selected option, because otherwise the field sends the full label as the value. */}
      <Form.Control
        readOnly
        hidden
        name={property.propertyName}
        value={value?.value ?? ""}
      />
      {getDynamicPropertyHelperText()}
    </div>
  );
};

export default DynamicConnectionProperty;
