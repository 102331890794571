import { IValidateUrlResponse } from "../../bffmodels/IValidateUrlResponse";
import { cdataFetch } from "../cdataFetch";

export async function validateUrl(url: string) {
  return await cdataFetch<IValidateUrlResponse>({
    method: "POST",
    url: "/utility/validate-url",
    body: url,
  });
}
