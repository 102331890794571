import React, { useContext } from "react";
import { Button } from "reactstrap";
import { ModalContext } from "../../../../routes/ModalContext";
import { IModalProps } from "../../../../components/CDataModal";

export interface IApiConnectorSaveButtonProps {
  cacheJobs: string[];
  handleValidSubmit: React.MouseEventHandler<HTMLButtonElement>;
}

export default function ApiConnectorSaveButton({
  cacheJobs,
  handleValidSubmit,
}: IApiConnectorSaveButtonProps) {
  const modalContext = useContext(ModalContext);

  const openSaveApiConnectionWithCacheJobsModal = () => {
    const message =
      "We've found cached jobs linked to this connection. Saving changes will clear the cached data which will restart the job run.";
    const confirmButton = (
      <Button
        color="primary"
        type="button"
        onClick={(event) => {
          handleValidSubmit(event);
          modalContext.toggleModal();
        }}
      >
        <div className="icon no-pointer-event" />
        Save & Clear Cache
      </Button>
    );

    const modal = {
      title: "Cached Jobs Detected",
      body: <div>{message}</div>,
      primaryButton: confirmButton,
      secondaryButton: (
        <Button color="secondary" onClick={modalContext.toggleModal}>
          Cancel
        </Button>
      ),
      displayToggleCloseButton: true,
      displayed: true,
    } as IModalProps;
    modalContext.setModal(modal);
  };

  const handleSubmit =
    cacheJobs?.length > 0
      ? openSaveApiConnectionWithCacheJobsModal
      : handleValidSubmit;

  return (
    <Button
      data-testid="button-save-changes"
      color="primary"
      type="button"
      className="card-actions float-end create-connector"
      onClick={handleSubmit}
    >
      <i className="fa fa-save fa-sm align-middle add-connection-icon no-pointer-event" />
      Save Changes
    </Button>
  );
}
