import { MutableRefObject, useEffect } from "react";
import {
  FieldArray,
  Form,
  Formik,
  FormikProps,
  useFormikContext,
} from "formik";
import * as Yup from "yup";
import { Card, CardBody } from "reactstrap";

import { KeyValueTableRow } from "../../ApiConnector";
import { ITableSettings } from "../SetTable";
import {
  YupOptionalSchema,
  YupRequiredSchema,
} from "../../../../utility/types/yupHelperTypes";
import { produce } from "immer";
import {
  ButtonType,
  CDataButton,
} from "../../../../components/buttons/CDataButton";
import { isEmpty } from "lodash";
import TextInput from "../../../../components/form/TextInput";
import { IAPIConnectorSettings } from "../types/IAPIConnectorSettings";

interface ITableHeadersCardProps {
  apiSettings: IAPIConnectorSettings;
  isTableFirstLoad: boolean;
  formRef: MutableRefObject<FormikProps<ITableSettings>>;
}

const yupSchema = Yup.object<YupOptionalSchema<ITableSettings>>({
  headers: Yup.array().of(
    Yup.object<YupRequiredSchema<KeyValueTableRow>>({
      name: Yup.string().nullable().trim().required("Name is required."),
      value: Yup.string().nullable().trim().required("Value is required."),
    }),
  ),
});

export const TableHeadersCard = (props: ITableHeadersCardProps) => {
  const {
    apiSettings: { tableSettings, setTableSettings },
    formRef,
  } = props;

  function setTableHeaders(headers: KeyValueTableRow[]) {
    setTableSettings({
      ...tableSettings,
      headers,
    });
  }

  return (
    <Card className="pages-apiConnector-components-HeadersCard">
      <CardBody>
        <h5 className="card-title">Headers</h5>
        <div className="card-description">
          Add request headers below that will be included in every request. If
          the API has dynamic headers that may change per query, consider using
          Filters instead.
        </div>
        <div>
          <Formik
            innerRef={formRef}
            initialValues={tableSettings}
            validationSchema={yupSchema}
            validateOnChange={true}
            onSubmit={() => {
              // no-op
            }}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <TableHeadersCardFormBody
                  {...props}
                  setTableHeaders={setTableHeaders}
                />
              </Form>
            )}
          </Formik>
        </div>
      </CardBody>
    </Card>
  );
};

interface ITableHeadersCardFormBody {
  apiSettings: IAPIConnectorSettings;
  setTableHeaders: (headers: KeyValueTableRow[]) => void;
}

const TableHeadersCardFormBody = (props: ITableHeadersCardFormBody) => {
  const {
    apiSettings: { setUnsavedChanges },
    setTableHeaders,
  } = props;

  const { values, setValues, validateForm, touched } =
    useFormikContext<ITableSettings>();

  // This isn't a real form, just update the parent whenever the form data changes.
  useEffect(() => {
    setTableHeaders(values.headers);
  }, [values.headers]); // eslint-disable-line

  useEffect(() => {
    // This is annoying but deleting a row causes the indexes to shuffle around
    // and validation messages to show up on the wrong fields. We have no real key for the rows.
    // We need to trigger a full form validation when the rows are deleted to avoid validation messages
    // being moved to the wrong field.
    validateForm();
  }, [values.headers.length]); // eslint-disable-line

  useEffect(() => {
    if (!isEmpty(touched.headers)) {
      setUnsavedChanges(true);
    }
  }, [touched.headers]); // eslint-disable-line

  function addNewHeaderRow() {
    const newTableSettings = produce(values, (draft) => {
      draft.headers.push({
        name: "",
        value: "",
      });
    });
    setValues(newTableSettings, true);
    setTableHeaders(newTableSettings.headers);

    setUnsavedChanges(true);
  }

  return (
    <div className="pages-apiConnector-components-TableHeadersCard">
      {values.headers.length > 0 && (
        <div className="header-row headers-title-row">
          <div className="header-column">
            <h5 className="required">Name</h5>
          </div>
          <div className="header-column">
            <h5 className="required">Value</h5>
          </div>
          {/* Empty div to take up the same space as the buttons in the header rows. */}
          <div className="header-button-container" />
        </div>
      )}
      <FieldArray
        name="headers"
        render={(arrayHelpers) => (
          <div className="header-rows-container">
            {values.headers.map((h, index) => {
              return (
                <div key={index} className="header-row">
                  <div className="header-column">
                    <TextInput
                      label=""
                      name={`headers[${index}].name`}
                      aria-label="Header Name"
                    />
                  </div>
                  <div className="header-column">
                    <TextInput
                      label=""
                      name={`headers[${index}].value`}
                      aria-label="Header Value"
                    />
                  </div>
                  <div className="header-button-container">
                    <CDataButton
                      className="headers-icon-button"
                      buttonType={ButtonType.Tertiary}
                      aria-label={`Delete Header ${values.headers[index].name}`}
                      onClick={() => {
                        arrayHelpers.remove(index);
                        setUnsavedChanges(true);
                      }}
                    >
                      <i className="fa fa-trash-alt" />
                    </CDataButton>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      />
      <CDataButton
        buttonType={ButtonType.Tertiary}
        className="header-add-button"
        onClick={addNewHeaderRow}
      >
        <i className="fa fa-plus icon" />
        Add Header
      </CDataButton>
    </div>
  );
};
