import React, { useRef, useContext, useEffect, useState } from "react";
import classnames from "classnames";
import {
  Button,
  Nav,
  NavItem,
  NavLink,
  UncontrolledTooltip,
  Spinner,
} from "reactstrap";
import useQueryTabs, { IQueryTab } from "./useQueryTabs";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { CDataModalV2 } from "../../../../components/modal/CDataModalV2";
import { QueryTabType } from "./queryTabType";
import { OpenTabMenu } from "./OpenTabMenu";

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

const QueryTabs = () => {
  const useTabs = useQueryTabs();
  const [openTabMenuAnchor, setOpenTabMenuAnchor] =
    React.useState<null | HTMLElement>(null);
  const [displayRemoveTabWarning, setDisplayRemoveTabWarning] =
    useState<boolean>(false);

  useEffect(() => {
    apiRef.current.scrollToItem(
      apiRef.current.getItemElementById("tabId" + useTabs.CurrentTabId)!,
    );
  }, [useTabs.CurrentTabId]);

  const apiRef = useRef({} as scrollVisibilityApiType);

  const getComponentName = () => {
    return "pages-dataExplorer-QueryTabs";
  };

  const toggle = (e: any, tabid: any) => {
    if (useTabs.CurrentTabId !== tabid) {
      handleTabChange(tabid);
    }
  };

  const handleTabChange = (tabid: any) => {
    useTabs.ChangeTab(tabid);
    apiRef.current.scrollToItem(
      apiRef.current?.getItemElementById(tabid) ?? undefined,
    );
  };
  const [currTabId, setCurrTabId] = useState(null);

  const closeTab = (e: any, tabid: any) => {
    e.stopPropagation();

    if (useTabs.IsTabEmpty(tabid)) {
      useTabs.RemoveTab(tabid);
    } else {
      setCurrTabId(tabid);
      setDisplayRemoveTabWarning(true);
    }
  };

  const closeTabWarning = (
    <CDataModalV2
      fade={false}
      displayed={displayRemoveTabWarning}
      close={() => {
        setDisplayRemoveTabWarning(false);
      }}
      title="Close Tab"
      spacedFooter={false}
      primaryButton={
        <Button
          color="danger"
          onClick={() => {
            useTabs.RemoveTab(currTabId!);
            setDisplayRemoveTabWarning(false);
          }}
        >
          Confirm
        </Button>
      }
      secondaryButton={
        <Button
          color="secondary"
          onClick={() => {
            setDisplayRemoveTabWarning(false);
          }}
        >
          Cancel
        </Button>
      }
    >
      Closing this tab will erase the contents of the editor and discard any
      unsaved changes to your Saved Query or Derived View. Are you sure you want
      to proceed?
    </CDataModalV2>
  );

  const renderTabs = () => {
    const tabMap = useTabs.List.map((tab: IQueryTab, index: any) => {
      return (
        <span key={"tabId" + tab.id}>
          <NavItem
            className="query-tab-nav-item"
            itemID={tab.id.toString()}
            key={tab.id}
            id={"tab" + tab.id}
          >
            <NavLink
              className={classnames({
                active: useTabs.CurrentTabId === tab.id,
                "pe-0": tab.tabType === QueryTabType.Home,
              })}
              key={index}
              onClick={(e: any) => {
                toggle(e, tab.id);
              }}
            >
              <span>
                {renderTabIcon(
                  tab.tabType,
                  tab.isLoading,
                  tab.isError,
                  tab.parentTabId,
                )}
              </span>
              <span className="table-title-spacing">{tab.tabName}</span>
              {useTabs.List.length > 1 && tab.tabType !== QueryTabType.Home ? (
                <i
                  className="fa-regular fa-xmark tab-close-icon"
                  onClick={(e) => closeTab(e, tab.id)}
                  data-testid={`close-tab-${tab.id}`}
                />
              ) : (
                <></>
              )}
            </NavLink>
          </NavItem>
          {renderTooltip(tab)}
        </span>
      );
    });
    return tabMap;
  };

  const renderTooltip = (tab: IQueryTab) => {
    let tabName = "";

    switch (tab.tabType) {
      case "savedQuery":
        tabName = "Saved Query";
        break;
      case "derivedView":
        tabName = "Derived View";
        break;
      case "scheduledQuery":
        tabName = "Scheduled Query";
        break;
      default:
        return;
    }

    return (
      <UncontrolledTooltip
        placement="top"
        target={"tab" + tab.id}
        trigger="hover"
      >
        {tabName}
      </UncontrolledTooltip>
    );
  };

  const renderTabIcon = (
    entitySet: string,
    isLoading: boolean,
    isError: boolean,
    parentTabId?: number,
  ) => {
    if (isLoading) {
      return (
        <Spinner
          className="spinner-border tab-running-icon"
          size="sm"
          color="info"
        />
      );
    } else if (isError) {
      return (
        <i
          className="fa fa-exclamation-triangle align-middle no-pointer-event tab-running-icon"
          color="red"
        />
      );
    }

    switch (entitySet) {
      case QueryTabType.Home:
        return (
          <i
            className="fa-solid fa-house align-middle home-icon"
            data-testid="home-icon"
          />
        );
      case QueryTabType.SavedQuery:
        return (
          <i
            className="fa-solid fa-save align-middle tab-icon"
            data-testid="saved-query-icon"
          />
        );
      case QueryTabType.EditDerivedView:
        return (
          <i
            className="fa-solid fa-border-none align-middle tab-icon"
            data-testid="edit-derived-view-icon"
          />
        );
      case QueryTabType.DerivedView:
        if (parentTabId) {
          return (
            <i
              className="fa-solid fa-edit align-middle tab-icon"
              data-testid="derived-view-icon"
            />
          );
        } else {
          return;
        }
      case QueryTabType.QueryBuilder:
        return (
          <i
            className="fa-solid fa-wand-magic-sparkles align-middle tab-icon"
            data-testid="query-builder-icon"
          />
        );
      case QueryTabType.ScheduledQuery:
        return (
          <i
            className="fa-solid fa-clock align-middle tab-icon"
            data-testid="scheduled-query-icon"
          />
        );
      default:
        return;
    }
  };

  const renderAdd = () => {
    return (
      <>
        <span id="add-tab-container">
          <Button
            color="primary"
            onClick={(event) => setOpenTabMenuAnchor(event.currentTarget)}
            className="add-tab-button"
            disabled={useTabs.List.length >= useTabs.MaxTabs}
            data-testid="open-tab-menu"
          >
            <i className="fa fa-plus fa-xs" />
          </Button>
        </span>
        <UncontrolledTooltip
          placement="top"
          target="add-tab-container"
          trigger="hover"
          data-testid="max-tabs-tooltip"
          className={useTabs.List.length < useTabs.MaxTabs ? "d-none" : ""}
        >
          You have reached your max amount of available tabs.
        </UncontrolledTooltip>
        <OpenTabMenu
          openTabMenuAnchor={openTabMenuAnchor}
          setOpenTabMenuAnchor={setOpenTabMenuAnchor}
        />
      </>
    );
  };

  function LeftArrow() {
    const { isFirstItemVisible, isLastItemVisible, scrollPrev } =
      useContext(VisibilityContext);

    return (
      <span
        onClick={() => scrollPrev()}
        className={
          (isFirstItemVisible && isLastItemVisible ? "d-none" : "") +
          " scroll-arrow"
        }
      >
        <i
          className={`"fa-caret-left ${isFirstItemVisible ? "disabled-caret" : "enabled-caret"}`}
        />
      </span>
    );
  }

  function RightArrow() {
    const { isFirstItemVisible, isLastItemVisible, scrollNext } =
      useContext(VisibilityContext);

    return (
      <span
        onClick={() => scrollNext()}
        className={
          (isFirstItemVisible && isLastItemVisible ? "d-none" : "") +
          " scroll-arrow"
        }
      >
        <i
          className={`"fa-caret-right ${isLastItemVisible ? "disabled-caret" : "enabled-caret"}`}
        />
      </span>
    );
  }

  return (
    <div className={`${getComponentName()}`}>
      {closeTabWarning}
      <div className="tab-container">
        <Nav tabs id="query-tab-nav-el">
          <ScrollMenu
            LeftArrow={LeftArrow}
            RightArrow={RightArrow}
            apiRef={apiRef}
          >
            {renderTabs()}
          </ScrollMenu>
          {renderAdd()}
        </Nav>
      </div>
    </div>
  );
};

export default QueryTabs;
