import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";

import { Col, Row } from "reactstrap";

interface ITitleWithBackButtonProps {
  title: ReactNode;
  customNavigate?: () => void;
  isBackButtonHidden?: boolean;
  noMargin?: boolean;
}

function TitleWithBackButton(props: ITitleWithBackButtonProps) {
  const navigate = useNavigate();

  return (
    <Row
      className={classNames("title-with-back-button-row", {
        "mb-0": props.noMargin,
        "mb-4": !props.noMargin,
      })}
    >
      <Col className="back-arrow-col" hidden={props.isBackButtonHidden}>
        <span
          onClick={() =>
            props.customNavigate ? props.customNavigate() : navigate(-1)
          }
        >
          <i className="fa fa-arrow-left fa-lg back-arrow-button" />
        </span>
      </Col>
      <Col className="title-col">
        <h2 className="title-text">{props.title}</h2>
      </Col>
    </Row>
  );
}

export default TitleWithBackButton;
