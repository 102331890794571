import { IColumnMetadata } from "../../../../../../../../api/metadata/getColumnsForTable";
import { IConnection } from "../../../../../../../../models";
import { IQueryBuilderColumn } from "../../../../models/IQueryBuilderColumn";

export function findConnection(
  connectionId: string,
  connectionList: IConnection[],
) {
  return connectionList.find((connection) => connection.id === connectionId);
}

export function mapColumnMetadataToQueryBuilderColumn(
  connectionsList: IConnection[],
  metadata: IColumnMetadata[],
): IQueryBuilderColumn[] {
  const connection = connectionsList.find(
    (c) => c.name?.toLowerCase() === metadata?.[0]?.catalog?.toLowerCase(),
  );
  if (connection == null) {
    return [];
  }

  return metadata.map<IQueryBuilderColumn>((d) => {
    return {
      dataType: d.dataType,
      column: d.columnName,
      table: {
        connectionId: connection.id!,
        connectionName: connection.name!,
        driver: connection.driver!,
        schema: d.schema,
        tableAlias: d.tableName,
        tableName: d.tableName,
      },
    };
  });
}
