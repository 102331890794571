import React, { MouseEventHandler, useContext } from "react";
import { BillingStatus, ISubscriptionInfo } from "../../../models";
import { ConnectionContext } from "./ConnectionContext";
import {
  ButtonType,
  CDataButton,
} from "../../../components/buttons/CDataButton";

interface IConnectionSaveButton {
  finishCustomReports: () => void;
  subscription: ISubscriptionInfo;
  openSaveConnectionWithCacheJobsModal?: React.MouseEventHandler<HTMLButtonElement>;
  // openSaveSSHConnectionModal?: React.MouseEventHandler<HTMLButtonElement>;
}

export const ConnectionSaveButton = ({
  finishCustomReports,
  subscription,
  openSaveConnectionWithCacheJobsModal,
  // openSaveSSHConnectionModal,
}: IConnectionSaveButton) => {
  const {
    chameleonId,
    connectionId,
    connectionInfo,
    currentlyCreatingReports,
    unsavedChanges,
  } = useContext(ConnectionContext);

  if (currentlyCreatingReports) {
    return (
      <CDataButton
        onClick={() => finishCustomReports()}
        type="submit"
        buttonType={ButtonType.Primary}
        className="card-actions float-end text-nowrap"
        data-testid="button-continue"
      >
        Continue
      </CDataButton>
    );
  } else {
    let buttonType: "submit" | "button" = "submit";
    let onClick: MouseEventHandler<HTMLButtonElement>;
    const cacheJobCount = connectionInfo.cacheJobs?.length ?? 0;

    if (cacheJobCount > 0) {
      buttonType = "button";
      onClick = openSaveConnectionWithCacheJobsModal!;
    }
    // TODO: Re-enable me in CLOUD-11995 once fingerprint fetching logic is in place
    // else if (hasSSH) {
    //   buttonType = "button";
    //   onClick = openSaveSSHConnectionModal!;
    // }

    return (
      <CDataButton
        id={chameleonId}
        data-testid="button-save-and-test"
        className="card-actions float-end text-nowrap"
        type={buttonType}
        buttonType={ButtonType.Primary}
        onClick={onClick!}
        disabled={
          (!unsavedChanges && !connectionId) ||
          subscription.billingStatus === BillingStatus.Canceled
        }
      >
        <i className="fa fa-solid fa-floppy-disk fa-md align-middle add-connection-icon no-pointer-event" />
        Save & Test
      </CDataButton>
    );
  }
};
