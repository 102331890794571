import { cdataFetch } from "../../../api/cdataFetch";
import { IQuerySearchCriteria, IQuerySearchResult } from "../../../models";

export async function postQueryLogs(params: IQuerySearchCriteria) {
  return cdataFetch<IQuerySearchResult>({
    method: "POST",
    url: "/log/query/list",
    body: params ?? {},
  });
}
