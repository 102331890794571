import React from "react";
import { Label } from "reactstrap";
import { IConnection } from "../../../models";
import { getDriverIcon } from "../../../components/drivers/DriverIconFactory";
import { CDataAutocompleteMulti } from "../../../components/select/CDataAutocompleteMulti";
import { Box } from "@mui/material";
import { CDataTypography } from "../../../components/text/CDataTypography";

interface IConnectionSelector {
  connectionList: IConnection[];
  disabled: boolean;
  onConnectionChange: (values: IConnection[]) => void;
  processingRequest: boolean;
  selectedConnections: IConnection[];
}

export const ConnectionSelector = (props: IConnectionSelector) => {
  const {
    connectionList,
    disabled,
    onConnectionChange,
    processingRequest,
    selectedConnections,
  } = props;

  function getOptionDisabled(option: IConnection) {
    return (
      selectedConnections?.length >= 2 && !selectedConnections.includes(option)
    );
  }

  return (
    <Box
      id="ai-connection-selector-box"
      data-testid="component-connection-selector"
    >
      <Label className="form-field-title required mt-3">Connections</Label>
      <CDataAutocompleteMulti<IConnection>
        chipLabel={(option) => option.name!}
        disableWholeSelector={disabled}
        dropdownLabel={(option) => (
          <Box className="d-flex align-items-center">
            {getDriverIcon(option.driver!, "ai-connection-driver-icon p-0")}
            <CDataTypography>{option.name}</CDataTypography>
          </Box>
        )}
        getOptionDisabled={getOptionDisabled}
        getOptionLabel={(option) => option.name!}
        handleChange={(_event, newValues) => {
          onConnectionChange(newValues);
        }}
        id="ai-connection-selector"
        isLoading={processingRequest}
        options={connectionList}
        selectedValues={selectedConnections}
      />
      <CDataTypography
        variant="typography-variant-helper-text"
        color="typography-color-text-dark"
      >
        Select up to 2 connections.
      </CDataTypography>
    </Box>
  );
};
