import {
  IPopoverOption,
  PopoverList,
} from "../../../../components/popover/PopoverList";
import { IDerivedView } from "../../../../models";
import { CreateAssetModal } from "../../../datasets/components/CreateAssetModal";
import { useState } from "react";
import useQueryTabs from "../../RightPanel/Tabs/useQueryTabs";
import { QueryTabType } from "../../RightPanel/Tabs/queryTabType";

interface IDerivedViewItemProps {
  row: any;
  rowIndex: any;
  isQueryUser: boolean;
  setTargetDerivedView: (derivedView: IDerivedView) => void;
  setDeleteDerivedViewModalOpen: (deleteDerivedViewModalOpen: boolean) => void;
}

export const DerivedViewItem = (props: IDerivedViewItemProps) => {
  const { row, rowIndex, isQueryUser } = props;
  const [createAssetModalOpen, setCreateAssetModalOpen] =
    useState<boolean>(false);

  const tabs = useQueryTabs();

  const queryUserPopoverOptions: IPopoverOption[] = [
    {
      label: "Open/Edit",
      action: () =>
        tabs.AddDerivedViewTab(
          row.name,
          row.query,
          row.id,
          QueryTabType.EditDerivedView,
          "open",
        ),
    },
    {
      label: "Query",
      "data-testid": "query-derived-view",
      action: () =>
        tabs.AddDerivedViewTab(
          row.name,
          "",
          row.id,
          QueryTabType.DerivedView,
          "query",
        ),
    },
  ];

  const adminPopoverOptions: IPopoverOption[] = [
    ...queryUserPopoverOptions,
    {
      label: "Delete",
      action: () => {
        props.setTargetDerivedView(row);
        props.setDeleteDerivedViewModalOpen(true);
      },
    },
    {
      label: "Add to Workspace",
      action: () => setCreateAssetModalOpen(true),
    },
  ];

  return (
    <div
      // @ts-ignore prexisting issue, needs refactor
      name={row.name}
      // eslint-disable-next-line
      query={row.query}
      id={row.id}
    >
      <div className="table-name-text data-explorer-container" title={row.name}>
        <div
          className="cursor-pointer table-name-text"
          onClick={() =>
            tabs.AddDerivedViewTab(
              row.name,
              row.query,
              row.id,
              QueryTabType.DerivedView,
              "open",
            )
          }
        >
          <i className="fa fa-border-none align-middle" />
          <span className="align-middle truncate ms-3">{row.name}</span>
        </div>
        {/* CLOUD-13013: Move this up to table, no need to create a modal for every item */}
        <CreateAssetModal
          isModalOpen={createAssetModalOpen}
          setIsMoveWorkspaceOpen={(isWorkspaceOpen: boolean) => {
            setCreateAssetModalOpen(isWorkspaceOpen);
          }}
          isDerivedView={true}
          derivedViewId={row.id}
          tableName={row.name}
        />
        <div
          id={"DerivedViewPopover-" + props.rowIndex}
          className="ellipsis-right cursor-pointer"
        >
          <i
            className="fa fa-ellipsis-v align-middle"
            data-testid={"popover-derived-view-" + rowIndex}
          />
          {isQueryUser ? (
            <PopoverList
              target={"DerivedViewPopover-" + rowIndex}
              key={rowIndex}
              popoverOptions={queryUserPopoverOptions}
            />
          ) : (
            <PopoverList
              target={"DerivedViewPopover-" + rowIndex}
              key={rowIndex}
              popoverOptions={adminPopoverOptions}
            />
          )}
        </div>
      </div>
    </div>
  );
};
