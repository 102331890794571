import React from "react";
import { useFeatureFlags } from "../../hooks/useFeatureFlags";
import { useAppSelector } from "../../redux/hooks";

interface PardotLoggerProps {
  driver?: string;
  client?: string;
}
const PardotLogger = (props: PardotLoggerProps) => {
  const user = useAppSelector((state) => state.user);
  const flags = useFeatureFlags().getFlags(["pardot_logging"]);
  const uriBuilder = (driver?: string, client?: string) => {
    let uri = `https://go.cdata.com/l/933343/2023-04-12/73mtn?email=${user?.email}&id=${user?.accountId}&accountname=${user?.accountName}`;
    uri = driver
      ? (uri += `&data_source=${driver}`)
      : (uri += `&client=${client}`);
    return uri;
  };

  const content = (
    <iframe
      src={uriBuilder(props.client, props.driver)}
      name="pdt"
      sandbox=""
      width="1"
      height="1"
      title="Log Info"
    />
  );

  return <>{flags.pardot_logging.enabled ? content : null}</>;
};

export default PardotLogger;
