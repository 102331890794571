import { useState } from "react";
import { CDataModalV2 } from "../../../../components/modal/CDataModalV2";
import {
  ButtonType,
  CDataButton,
} from "../../../../components/buttons/CDataButton";
import { ToastrError, ToastrSuccess } from "../../../../services/toastrService";
import * as Yup from "yup";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { Spinner } from "reactstrap";
import { validateQuery } from "../../validation/validateQuery";
import { useAPI } from "../../../../components/useAPI";
import { RequestType } from "../../../../components/withAPI";
import { LeftPanelType } from "../../LeftPanel/leftPanelType";
import useQueryTabs from "../Tabs/useQueryTabs";
import { QueryTabType } from "../Tabs/queryTabType";
import { duplicateColumnsInTableResult } from "../../api/duplicateColumnsInTableResult";

export interface CreateDerivedViewModalProps {
  displayed: boolean;
  close: () => void;
  fetchDerivedViews: () => void;
  setSidebarView: (newSideBarView: LeftPanelType) => void;
}

export function CreateDerivedViewModal(props: CreateDerivedViewModalProps) {
  const api = useAPI();
  const tabs = useQueryTabs();
  const {
    displayed,
    close,
    fetchDerivedViews: fetchAndSetSavedDerivedViews,
    setSidebarView,
  } = props;

  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const currentTab = tabs.List.find((t) => t.id === tabs.CurrentTabId);

  if (!currentTab) {
    return null;
  }

  const createDerivedView = async (values: any) => {
    setIsProcessing(true);
    const currentQueryInput = currentTab.queryString ?? "";

    //validation for query input string
    const validation = validateQuery(currentQueryInput);
    if (!validation.isValid) {
      ToastrError(
        "Failed to create derived view due to the following error: ",
        validation.message,
      );
      return;
    }

    //verify duplicate column names before actually saving the dervied view.
    const { duplicateColumns, queryId, invalidQuery } =
      await duplicateColumnsInTableResult(currentQueryInput, api);

    if (invalidQuery) {
      //if /query call throws error, then don't proceed to save the derived view.
      close();
      return;
    }

    if (duplicateColumns?.length > 0) {
      const duplicateColumnNames = duplicateColumns.join(", ");
      ToastrError(
        "Duplicate column names detected",
        `Unable to save derived view due to an unresolved SQL compilation error. Ambiguous column names: ${duplicateColumnNames}.`,
        `Query ID: ${queryId}`,
      );

      close();
      return;
    }

    const { status, payload } = await api.callAPI(
      RequestType.Post,
      "/account/derivedViews",
      "Failed to create derived view due to the following error:",
      { ...values, Query: currentQueryInput },
    );
    if (status === 200) {
      setSidebarView(LeftPanelType.DerivedViews);
      await fetchAndSetSavedDerivedViews();
      ToastrSuccess(
        "Derived View Successfully Created!",
        `${values.Name} was created successfully.`,
      );
      close();
      tabs.ChangeTabType(
        tabs.CurrentTabId,
        values.Name,
        QueryTabType.EditDerivedView,
        payload.id,
        currentQueryInput,
      );
    }

    setIsProcessing(false);
  };

  const initialFormValues = {
    Name: "",
  };

  const validationSchema = Yup.object().shape({
    Name: Yup.string()
      .min(2, "Derived View name must be between 2 and 128 characters")
      .max(128, "Derived View name must be between 2 and 128 characters")
      .matches(
        /^[a-zA-Z_][a-zA-Z0-9_ -]{0,126}[a-zA-Z0-9_-]$/,
        "Derived View name must be 2-128 ASCII letters, numbers, hyphens, underscores, and spaces; must begin and end with a letter/underscore; and cannot end with a space",
      )
      .required("This is a required field"),
  });

  return (
    <CDataModalV2
      title="Create Derived View"
      close={close}
      displayed={displayed}
      spacedFooter={false}
      primaryButton={
        <CDataButton
          buttonType={ButtonType.Primary}
          type="submit"
          form="newDerivedViewForm"
        >
          Confirm
        </CDataButton>
      }
      secondaryButton={
        <CDataButton buttonType={ButtonType.Secondary} onClick={() => close()}>
          Cancel
        </CDataButton>
      }
    >
      <>
        <Formik
          initialValues={initialFormValues}
          validationSchema={validationSchema}
          onSubmit={(values: any) => createDerivedView(values)}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
          }) => (
            <Form id="newDerivedViewForm" onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Label className="required h5">View Name</Form.Label>
                <Form.Control
                  id="Name"
                  type="text"
                  placeholder="Enter a name for your derived view."
                  value={values.Name}
                  isInvalid={Boolean(touched.Name && errors.Name)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.Name && (
                  <Form.Control.Feedback type="invalid">
                    {errors.Name?.toString()}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Form>
          )}
        </Formik>
        <div hidden={!isProcessing}>
          <div className="loading-background" />
          <Spinner className="spinner-border loading-spinner" color="info" />
        </div>
      </>
    </CDataModalV2>
  );
}
