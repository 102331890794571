import React from "react";
import { ModalPromptFieldTypes, ResourceType } from "../ModalPromptBody";
import { Col } from "reactstrap";
import classnames from "classnames";
import { DisabledInputWithCopyButton } from "../../../../components/form/DisabledInputWithCopyButton";

interface IClientCardModalField {
  fieldKey: string;
  fieldType: ModalPromptFieldTypes;
  idName: string;
  selectedResourceType: ResourceType;
  selectedWorkspaceName: string | undefined;
  value: string;
  userEmail?: string | undefined;
}

const ClientCardModalField = (props: IClientCardModalField) => {
  const { fieldKey, selectedWorkspaceName, userEmail } = props;
  let { value } = props;
  const modifiedKey = fieldKey.replace(/\s/g, "");
  const id = `copy-button-${props.idName}-${modifiedKey}`;

  // Replace connection name if the the workspace tab is active and the user has selected a workspace
  if (
    props.selectedResourceType === ResourceType.Workspace &&
    selectedWorkspaceName
  ) {
    if (value.includes("[ConnectionName]")) {
      value = value.replace("[ConnectionName]", selectedWorkspaceName);
    }

    if (props.fieldType === ModalPromptFieldTypes.Email) {
      value = `${value}@${selectedWorkspaceName}`;
    }

    if (value.includes("[userName]")) {
      value = value.replace(
        "[userName]",
        `${userEmail}@${selectedWorkspaceName}`,
      );
    }
  } else {
    if (value.includes("[userName]")) {
      value = value.replace("[userName]", `${userEmail}`);
    }
  }

  // Generate helper text based on the key and value
  let helperText = null;
  const showConnectionNameHelperText = value.includes("[ConnectionName]");
  if (showConnectionNameHelperText) {
    helperText = (
      <span className="helper-text">
        Replace [ConnectionName] with the connection you want to pull data from.
      </span>
    );
  } else if (fieldKey === "Database") {
    helperText = (
      <span className="helper-text">
        If required, copy this value into the Database field.
      </span>
    );
  } else if (fieldKey === "Connection String") {
    helperText = (
      <span className="helper-text">
        Replace [password] with your Personal Access Token.
      </span>
    );
  }

  return (
    <>
      <Col
        className={classnames("label-col", {
          "show-helper-text": helperText,
        })}
      >
        <div className="client-endpoint-label">{fieldKey}:</div>
      </Col>
      <Col className="input-col">
        <DisabledInputWithCopyButton id={id} text={value} />
        {helperText}
      </Col>
    </>
  );
};

export default ClientCardModalField;
