import {
  IconOverrideOptions,
  NotificationBar,
} from "../../../components/notification/NotificationBar";

export const CacheConnectionAlertBanner = () => {
  return (
    <div className="mb-3" data-testid="cache-connection-banner">
      <div className="mb-2">
        <NotificationBar
          barColor={"notification-bar-blue"}
          linkText="Setup Cache Connection"
          message={
            "Before you can cache connections, you need to configure your PostgreSQL data warehouse."
          }
          navigateDestination="/jobs/cacheConnection"
          iconOverride={IconOverrideOptions.Triangle}
        />
      </div>
    </div>
  );
};
