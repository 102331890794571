import { useState } from "react";
import { Col, Container, ListGroup, ListGroupItem, Row } from "reactstrap";
import { UserRole } from "../../models";
import { Statistics } from "./Statistics";
import { OverTimeChart } from "./OverTimeChart";
import { QueriesByConnection } from "./QueriesByConnection";
import { QueriesByUser } from "./QueriesByUser";
import { useAppSelector } from "../../redux/hooks";

const Status = () => {
  const user = useAppSelector((state) => state.user);

  const [timePeriod, setTimePeriod] = useState<number>(7);

  const isOEM = user?.role === UserRole.ServiceUser;

  return (
    <Container fluid className="p-0 pages-status-Status">
      <Row>
        <Col>
          <h1 className="h3 ms-1 mb-4">Overview</h1>
        </Col>
        <Col className="timescale-section">
          <Row className="text-end align-items-center">
            <Col>Show data for the past:</Col>
            <Col>
              <ListGroup horizontal className="connected-button-group">
                <ListGroupItem
                  data-testid={`button-${"Tabs.OneDay"}`}
                  action
                  type="button"
                  onClick={() => setTimePeriod(1)}
                  active={timePeriod === 1}
                  className="text-center"
                >
                  1 Day
                </ListGroupItem>
                <ListGroupItem
                  data-testid={`button-${"Tabs.SevenDays"}`}
                  action
                  type="button"
                  onClick={() => setTimePeriod(7)}
                  active={timePeriod === 7}
                  className="text-center"
                >
                  7 Days
                </ListGroupItem>
                <ListGroupItem
                  data-testid={`button-${"Tabs.ThirtyDays"}`}
                  action
                  type="button"
                  onClick={() => setTimePeriod(30)}
                  active={timePeriod === 30}
                  className="text-center"
                >
                  30 Days
                </ListGroupItem>
              </ListGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="statistics-row">
        <Statistics period={timePeriod} />
      </Row>
      <Row>
        <Col lg="7" className="queries-over-time-column d-flex">
          <OverTimeChart period={timePeriod} />
        </Col>
        <Col lg="5" className="queries-by-connection-column">
          <QueriesByConnection period={timePeriod} />
        </Col>
      </Row>
      <Row hidden={isOEM}>
        <QueriesByUser period={timePeriod} />
      </Row>
    </Container>
  );
};

export default Status;
