import { createContext } from "react";
import { IQueryTab } from "./useQueryTabs";

export const QueryTabsContext = createContext<IQueryTabsContextProps>(null!);

export interface IQueryTabsContextProps {
  tabs: IQueryTab[];
  setTabs: React.Dispatch<React.SetStateAction<IQueryTab[]>>;
  selectedTab: number;
  setSelectedTab: (selectedTab: number) => void;
  queryTabTitleCounter: number;
  setQueryTabTitleCounter: (queryTabTitleCounter: number) => void;
  queryBuilderTabTitleCounter: number;
  setQueryBuilderTabTitleCounter: (queryBuilderTabTitleCounter: number) => void;
}
