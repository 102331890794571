import { useContext, useEffect, useState } from "react";
import DataLabelAndDropdown from "./DataLabelAndDropdown";
import { useFormikContext } from "formik";
import { produce } from "immer";
import { IQueryBuilderCommonProps } from "../../../../../models/IQueryBuilderCommonProps";
import { AddDataModalContext } from "../AddDataModalContext";
import { mapColumnMetadataToQueryBuilderColumn } from "../AddDataModal.functions";
import { IQueryBuilderTable } from "../../../../../models/IQueryBuilderTable";
import { useMutation } from "@tanstack/react-query";
import { getColumnsForTable } from "../../../../../../../../../api/metadata/getColumnsForTable";
import { IQueryBuilderJoin } from "../../../../../models/IQueryBuilderModel";
import { cloneDeep } from "lodash";
import { DataType } from "../../../../../../../../../models";
import { QueryBuilderTableDropdownOption } from "../../../components/QueryBuilderTableDropdownOption";

const JoinTableTableField = (props: IQueryBuilderCommonProps) => {
  const { queryData, connectionsList } = props;
  const { values, setValues } = useFormikContext<IQueryBuilderJoin>();
  const [tableSelectOptions, setTableSelectOptions] = useState<
    IQueryBuilderTable[]
  >([]);
  const { setProcessing, setJoinTableColumns } =
    useContext(AddDataModalContext);

  useEffect(() => {
    async function populateJoinColumns() {
      await populateJoinTableOptions();
    }

    populateJoinColumns();
  }, []); // eslint-disable-line

  async function populateJoinTableOptions() {
    setProcessing(true);

    // If we are editing an existing join, we need to load the table/column metadata for the
    // existing record.
    if (values.left.column.length > 0) {
      await updateColumnList(values.left.table);
    }

    const updatedJoinTableOptions: IQueryBuilderTable[] = [];

    if (queryData.joins.length > 0) {
      const myTable: string | null =
        values.right.table.tableAlias.length > 0
          ? values.right.table.tableAlias
          : null;

      // They can always JOIN to the from table.
      updatedJoinTableOptions.push(queryData.from);

      for (const join of queryData.joins) {
        if (join.right.table.tableAlias === "") {
          continue;
        }
        // They can JOIN to any other JOIN as long as it isn't the one they are currently editing.
        if (myTable != null && myTable === join.right.table.tableAlias) {
          continue;
        }
        updatedJoinTableOptions.push(join.right.table);
      }
    } else {
      updatedJoinTableOptions.push(queryData.from);

      await updateColumnList(queryData.from);
    }

    setTableSelectOptions(updatedJoinTableOptions);

    setProcessing(false);
  }

  function updateSelectedTable(table: IQueryBuilderTable) {
    updateColumnList(table);
    setValues(
      produce(values, (draft) => {
        draft.left.table = cloneDeep(table);
        draft.left.column = "";
        draft.left.dataType = DataType.VARCHAR;
      }),
    );
  }

  const { mutateAsync: getMetadataForColumnsAsync } = useMutation({
    mutationKey: ["/columns"],
    mutationFn: getColumnsForTable,
    onSuccess: (data) => {
      setJoinTableColumns(
        mapColumnMetadataToQueryBuilderColumn(connectionsList, data),
      );
    },
    onMutate: () => {
      setProcessing(true);
    },
    onSettled: () => {
      setProcessing(false);
    },
    meta: {
      errorMessage:
        "Failed to load columns for table due to the following error:",
    },
  });

  async function updateColumnList(newTableValues: IQueryBuilderTable) {
    await getMetadataForColumnsAsync({
      connectionName: newTableValues.connectionName,
      schema: newTableValues.schema,
      tableName: newTableValues.tableName,
    });
  }

  const tooltipText =
    queryData.joins?.length < 1 ? (
      <span className="data-modal-tooltip-text">
        Adding new joins will activate this dropdown for future custom
        configurations.
      </span>
    ) : undefined;

  const currentTableOption =
    tableSelectOptions.length > 1
      ? tableSelectOptions?.find(
          (tableObj) => tableObj.tableAlias === values.left.table.tableAlias,
        )
      : tableSelectOptions[0];

  const isOptionEqualToValue = (
    option: IQueryBuilderTable,
    value: IQueryBuilderTable,
  ) => {
    return (
      option.tableAlias === value.tableAlias &&
      option.tableAlias === value.tableAlias
    );
  };

  return (
    <DataLabelAndDropdown
      handleChange={updateSelectedTable}
      isDisabled={!values.right.column || tableSelectOptions.length <= 1}
      key={`${currentTableOption?.connectionName}-${currentTableOption?.schema}-${currentTableOption?.tableAlias}`}
      isOptionEqualToValue={isOptionEqualToValue}
      label={"Table Name"}
      menuClass="query-builder-table-dropdown-menu"
      dropdownLabel={(option) => {
        return <QueryBuilderTableDropdownOption option={option} />;
      }}
      optionLabel={(option) => option.tableName}
      id={"joinTable.tableName"}
      options={tableSelectOptions || []}
      tooltipId="joinTableTable"
      tooltipText={tooltipText}
      value={currentTableOption!}
    />
  );
};

export default JoinTableTableField;
