import React from "react";
import { Label } from "reactstrap";
import { Box, Grid } from "@mui/material";
import { CDataAutocompleteMulti } from "../../../components/select/CDataAutocompleteMulti";
import { CDataTypography } from "../../../components/text/CDataTypography";
import { getDriverIcon } from "../../../components/drivers/DriverIconFactory";
import { AITableOption } from "./AIWizard";

interface ITableSelector {
  onTableChange: (tables: AITableOption[]) => void;
  processingRequest: boolean;
  selectedTables: AITableOption[];
  tableList: AITableOption[];
  disabled: boolean;
  tokensOverLimit: boolean;
}

export const TableSelector = (props: ITableSelector) => {
  const {
    disabled,
    onTableChange,
    processingRequest,
    selectedTables,
    tableList,
    tokensOverLimit,
  } = props;

  function getOptionDisabled(option: AITableOption) {
    return selectedTables?.length >= 2 && !selectedTables.includes(option);
  }

  return (
    <Box id="ai-table-selector-box" data-testid="component-table-selector">
      <Label className="form-field-title required mt-3">Tables</Label>
      <CDataAutocompleteMulti<AITableOption>
        chipLabel={(option) => `${option.connectionName}.${option.tableName}`}
        disableWholeSelector={disabled}
        dropdownLabel={(option) => (
          <Grid container spacing={0} className="py-2">
            <Grid item xs={12}>
              <CDataTypography
                variant="typography-variant-caption"
                color="typography-color-medium-grey"
              >
                {option.connectionName}.{option.schemaName}
              </CDataTypography>
            </Grid>
            <Grid item xs={0} className="d-flex align-items-center">
              {getDriverIcon(option.driver, "ai-connection-driver-icon p-0")}
            </Grid>
            <Grid item xs={11}>
              <CDataTypography>{option.tableName}</CDataTypography>
            </Grid>
          </Grid>
        )}
        getOptionDisabled={getOptionDisabled}
        getOptionLabel={(option) =>
          `${option.connectionName}.${option.schemaName}.${option.tableName}`
        }
        handleChange={(_event, newValues) => {
          onTableChange(newValues);
        }}
        id="ai-table-selector"
        isInvalid={tokensOverLimit}
        isLoading={processingRequest}
        options={tableList}
        selectedValues={selectedTables}
      />
      <CDataTypography
        variant="typography-variant-helper-text"
        color={
          tokensOverLimit
            ? "typography-color-danger"
            : "typography-color-text-dark"
        }
      >
        Select up to 2 tables for the AI Generator to scan upon submitting a
        prompt.
      </CDataTypography>
    </Box>
  );
};
