import { useContext } from "react";
import { Button, Col, Row } from "reactstrap";
import { ICacheJob } from "../../../../models/Cache/ICacheJob";
import { IModalProps } from "../../../../components/CDataModal";
import { ModalContext } from "../../../../routes/ModalContext";
import { IQueryRecord } from "../../../../models";
import { NotificationBar } from "../../../../components/notification/NotificationBar";
import { IScheduledQuery } from "../../../../models/ScheduledQueries/IScheduledQuery";
import { useJobsFunctions } from "../../useJobsFunctions";

interface IErrorNotifcationBannerProps {
  jobDetails: ICacheJob | IScheduledQuery;
  errorLog: IQueryRecord | undefined;
}

export const ErrorNotificationBanner = ({
  jobDetails,
  errorLog,
}: IErrorNotifcationBannerProps) => {
  const modalContext = useContext(ModalContext);
  const errorMessage = errorLog?.errorMessage || "No error details available.";
  const { downloadJobErrorLogsAsync } = useJobsFunctions();

  function showErrorModal() {
    const modal = {
      displayed: true,
      title: "Run Failed",
      body: <>{errorMessage}</>,
      primaryButton: (
        <Button color="primary" onClick={modalContext.toggleModal}>
          Close
        </Button>
      ),
      secondaryButton: (
        <Button
          hidden={!errorLog?.logFileKey}
          color="secondary"
          onClick={() =>
            downloadJobErrorLogsAsync({
              queryId: jobDetails.status.lastRunId ?? "",
            })
          }
        >
          Download Log
        </Button>
      ),
      modalSize: "md",
      displayToggleCloseButton: true,
    } as IModalProps;

    modalContext.setModal(modal);
  }

  return (
    <Row data-testid="banner-job-error" className="mb-4">
      <Col>
        <NotificationBar
          message="We ran into an issue when trying to run data to this table."
          barColor="notification-bar-red"
          linkText="Learn More"
          linkClickOverride={showErrorModal}
        />
      </Col>
    </Row>
  );
};
