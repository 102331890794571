import React, { Component } from "react";

import { Spinner, Row, UncontrolledTooltip } from "reactstrap";
import { CDataComponent } from "../../../utility/CDataComponent";
import { ODataContext } from "./ODataContext";
import { withAPI, IAPI } from "../../../components/withAPI";
import { Col } from "react-bootstrap";
import { getTables } from "./SelectConnection";

interface ISelectEntitySetTypeProps extends IAPI {
  catalog?: any[];
  nextStep?: () => void;
  goToNamedStep?: (namedStep: string) => void;
  currentStep?: number;
  setIsModalFooterDisplayed: (isModalFooterDisplayed: boolean) => void;
  setEntityType: (entityType: string) => void;
}
interface ISelectEntitySetTypeState {
  displayToggleCloseButton: boolean;
  processingRequest: boolean;
  isDerivedViewButtonDisabled: boolean;
}

class SelectEntitySetType
  extends Component<ISelectEntitySetTypeProps, ISelectEntitySetTypeState>
  implements CDataComponent
{
  getTables: (
    this: Component<{ callAPI: IAPI["callAPI"] }>,
    catalog: string,
    schema?: string,
  ) => Promise<any>;
  constructor(props: ISelectEntitySetTypeProps) {
    super(props);
    this.getTables = getTables.bind(this);
    this.state = {
      displayToggleCloseButton: true,
      processingRequest: false,
      isDerivedViewButtonDisabled: false,
    };
  }

  getComponentName() {
    return "pages-odata-Wizard-SelectEntitySetType";
  }

  static contextType = ODataContext;
  context!: React.ContextType<typeof ODataContext>;

  componentDidMount = async () => {
    this.props.setIsModalFooterDisplayed(false);
    this.setState({ processingRequest: true });
    const derivedViews = await this.getTables("CData", "DerivedViews");
    if (!derivedViews) {
      this.setState({ isDerivedViewButtonDisabled: true });
    }
    this.setState({ processingRequest: false });
  };

  goToDerivedViewTables = async () => {
    const derivedViews = await this.getTables("CData", "DerivedViews");
    if (derivedViews) {
      this.context.selectedConnection = {
        name: "DerivedViews",
        type: "CData",
      };
      this.context.availableTables = derivedViews;
      this.props.setEntityType("Views");
      this.props.goToNamedStep!("SelectTables");
    } else {
      this.setState({ processingRequest: false });
    }
  };

  render() {
    const tooltip = this.state.isDerivedViewButtonDisabled ? (
      <UncontrolledTooltip placement="top" target="derivedView">
        You currently have added all Derived Views to OData.
      </UncontrolledTooltip>
    ) : null;

    return (
      <div className={this.getComponentName()}>
        <div hidden={!this.state.processingRequest}>
          <div className="loading-background" />
          <Spinner className="spinner-border loading-spinner" color="info" />
        </div>
        <p className="select-entity-type-message mt-3 mb-1">
          Select whether you want to add an entity set from a Derived View or
          from a Connection.
        </p>
        <Row
          id="derivedView"
          className={
            this.state.isDerivedViewButtonDisabled
              ? "select-entity-type-button-disabled select-entity-type-button"
              : "select-entity-type-button"
          }
          onClick={() => this.goToDerivedViewTables()}
        >
          {tooltip}
          <Col className="select-entity-type-button-label">Derived Views</Col>
          <Col className="pe-3">
            <i className="fa fa-chevron-right align-middle float-end" />
          </Col>
        </Row>
        <Row
          className="select-entity-type-button"
          onClick={() => this.props.goToNamedStep!("SelectConnection")}
        >
          <Col className="select-entity-type-button-label">Connections</Col>
          <Col className="pe-3">
            <i className="fa fa-chevron-right align-middle float-end" />
          </Col>
        </Row>
      </div>
    );
  }
}

export default withAPI(SelectEntitySetType);
