import { useContext } from "react";
import { ConnectionContext } from "./ConnectionContext";
import { IHierarchyPropertyExtended } from "../../../bffmodels/IHierarchyPropertyExtended";
import { ConnectionProperty } from "./ConnectionProperty";
import { Card, CardBody } from "reactstrap";
import { Form } from "react-bootstrap";

export const SSHPropertyCard = () => {
  const connectionContext = useContext(ConnectionContext);
  const sshPropCategory = connectionContext.driverInfo.advancedProps.find(
    (prop) => prop.name === "SSH",
  );
  if (!sshPropCategory || !sshPropCategory.properties) return;
  const categoryName = sshPropCategory.name;
  const currentAuthMode = sshPropCategory.properties?.find(
    (property) => property.propertyName === "SSHAuthMode",
  )?.currentValue;

  // Remove useSSH because it's toggled by the selector.
  // Remove SSHClientCertSubject because it's in advanced props.
  // Remove SSHServerFingerprint because it's in a modal.
  const propsToFilter = [
    "UseSSH",
    "SSHClientCertSubject",
    "SSHServerFingerprint",
  ];
  const filteredSshProps = sshPropCategory.properties.filter(
    (prop) => !propsToFilter.includes(prop.propertyName!),
  );

  // Sort the properties in our custom display order
  const propertySortOrder = [
    "SSHServer",
    "SSHPort",
    "SSHAuthMode",
    "SSHUser",
    "SSHPassword",
    "SSHClientCertType",
    "SSHClientCert",
    "SSHClientCertPassword",
  ];
  const sortedSshProps = filteredSshProps!.sort(
    (a, b) =>
      propertySortOrder.indexOf(a.propertyName!) -
      propertySortOrder.indexOf(b.propertyName!),
  );

  const createConnectionProperty = (
    property: IHierarchyPropertyExtended,
    readOnly: boolean = false,
  ) => {
    return (
      <ConnectionProperty
        key={property.propertyName!}
        location={"EditConnection"}
        property={property}
        readOnly={readOnly}
        tab="basic"
      />
    );
  };

  // Map the properties that appear and are mandatory for all auth modes
  const universalPropertyNames = ["SSHServer", "SSHPort", "SSHAuthMode"];
  const universalProperties = sortedSshProps.map((universalProp) => {
    if (universalPropertyNames.includes(universalProp.propertyName!)) {
      if (
        universalProp.propertyName === "SSHAuthMode" &&
        universalProp.enum != null
      ) {
        universalProp.enum = universalProp.enum.filter(
          (item) => item !== "None",
        );
      }
      return createConnectionProperty(universalProp);
    }
  });

  // Map the properties for Password auth mode
  const passwordPropertyNames = ["SSHUser", "SSHPassword"];
  const passwordProperties = sortedSshProps.map((passwordProp) => {
    if (passwordPropertyNames.includes(passwordProp.propertyName!)) {
      return createConnectionProperty(passwordProp);
    }
  });

  // Map the properties for Public_Key auth mode
  const publickeyPropertyNames = [
    "SSHUser",
    "SSHClientCertType",
    "SSHClientCert",
    "SSHClientCertPassword",
  ];
  const publicKeyProperties = sortedSshProps.map((keyProp) => {
    let readOnly = false;
    if (publickeyPropertyNames.includes(keyProp.propertyName!)) {
      if (
        keyProp.propertyName === "SSHClientCertType" &&
        keyProp.enum != null
      ) {
        // Confine SSH Client Cert Type to only PEMKEY_BLOB and make it read-only
        keyProp.enum = keyProp.enum.filter((item) => item === "PEMKEY_BLOB");
        keyProp.enum.push(""); // Single-item selectors are automatically hidden. This dummy value prevents this behavior.
        keyProp.helperText =
          "Only PEMKEY_BLOB is available for SSH Client Cert Type.";
        readOnly = true;
      }
      return createConnectionProperty(keyProp, readOnly);
    }
  });

  return (
    <Card data-testid={`category-${categoryName}`} key={categoryName}>
      <CardBody>
        <Form.Group>
          <h5 className="card-title mb-3">{categoryName}</h5>
          {universalProperties}
          {currentAuthMode === "Password" && passwordProperties}
          {currentAuthMode === "Public_Key" && publicKeyProperties}
        </Form.Group>
      </CardBody>
    </Card>
  );
};
