import React from "react";
import { Label } from "reactstrap";
import { AISchemaOption } from "./AIWizard";
import { CDataAutocompleteMulti } from "../../../components/select/CDataAutocompleteMulti";
import { Box, Grid } from "@mui/material";
import { CDataTypography } from "../../../components/text/CDataTypography";
import { getDriverIcon } from "../../../components/drivers/DriverIconFactory";

interface ISchemaSelector {
  disabled: boolean;
  onSchemaChange: (values: AISchemaOption[]) => void;
  processingRequest: boolean;
  schemaList: AISchemaOption[];
  selectedSchemas: AISchemaOption[];
  tokensOverLimit: boolean;
}

export const SchemaSelector = (props: ISchemaSelector) => {
  const {
    disabled,
    onSchemaChange,
    processingRequest,
    schemaList,
    selectedSchemas,
    tokensOverLimit,
  } = props;

  function getOptionDisabled(option: AISchemaOption) {
    return selectedSchemas?.length >= 2 && !selectedSchemas.includes(option);
  }

  return (
    <Box id="ai-schema-selector-box" data-testid="component-schema-selector">
      <Label className="form-field-title required mt-3">Schemas</Label>
      <CDataAutocompleteMulti<AISchemaOption>
        chipLabel={(option) => `${option.connectionName}.${option.schemaName}`}
        disableWholeSelector={disabled}
        dropdownLabel={(option) => (
          <Grid container spacing={0} className="py-2">
            <Grid item xs={12}>
              <CDataTypography
                variant="typography-variant-caption"
                color="typography-color-medium-grey"
              >
                {option.connectionName}
              </CDataTypography>
            </Grid>
            <Grid item xs={0} className="d-flex align-items-center">
              {getDriverIcon(option.driver, "ai-connection-driver-icon p-0")}
            </Grid>
            <Grid item xs={11}>
              <CDataTypography>{option.schemaName}</CDataTypography>
            </Grid>
          </Grid>
        )}
        getOptionDisabled={getOptionDisabled}
        getOptionLabel={(option) =>
          `${option.connectionName}.${option.schemaName}`
        }
        handleChange={(_event, newValues) => {
          onSchemaChange(newValues);
        }}
        id="ai-schema-selector"
        isInvalid={tokensOverLimit}
        isLoading={processingRequest}
        options={schemaList}
        selectedValues={selectedSchemas}
      />
      <CDataTypography
        variant="typography-variant-helper-text"
        color={
          tokensOverLimit
            ? "typography-color-danger"
            : "typography-color-text-dark"
        }
      >
        Select up to 2 schemas.
      </CDataTypography>
    </Box>
  );
};
