import { CDataTypography } from "../../../../components/text/CDataTypography";
import useQueryTabs from "./useQueryTabs";
import { CDataRow } from "../../../../components/layout/CDataRow";
import { CDataCol } from "../../../../components/layout/CDataCol";
import { CDataCard } from "../../../../components/card/CDataCard";
import { CDataCardBody } from "../../../../components/card/CDataCardBody";
import { CDataIconCircle } from "../../../../components/CDataIconCircle";
import {
  ButtonType,
  CDataButton,
} from "../../../../components/buttons/CDataButton";
import { QueryTabType } from "./queryTabType";
import AddQueryBuilderButton from "../../AddQueryBuilderButton";

export function HomeTab() {
  const tabs = useQueryTabs();

  return (
    <span className="home-tab" hidden={!(tabs.CurrentTabId === 0)}>
      <CDataTypography variant="typography-variant-card-title" className="pb-3">
        Welcome to the CData Connect Cloud Data Explorer
      </CDataTypography>
      <CDataTypography
        variant="typography-variant-body-medium"
        color="typography-color-text-muted"
        className="pb-3"
      >
        Select one of the actions below to start querying your data.
      </CDataTypography>
      <CDataRow noGutters>
        <CDataCol className="query-builder-card-col me-3">
          <CDataCard outline roundedCorners noShadow className="home-tab-card">
            <CDataCardBody className="d-flex justify-content-center flex-column">
              <CDataIconCircle className="align-self-center">
                <i className="fa-solid fa-layer-plus align-middle home-tab-icon" />
              </CDataIconCircle>
              <CDataTypography
                variant="typography-variant-headline-3"
                className="p-3"
              >
                Query Builder
              </CDataTypography>
              <CDataTypography
                variant="typography-variant-body-medium"
                color="typography-color-medium-grey"
                className="pb-3"
              >
                Quickly build custom queries with our easy step-by-step
                wizard—no coding required!
              </CDataTypography>
              <AddQueryBuilderButton />
            </CDataCardBody>
          </CDataCard>
        </CDataCol>
        <CDataCol>
          <CDataCard outline roundedCorners noShadow className="home-tab-card">
            <CDataCardBody className="d-flex justify-content-center flex-column">
              <CDataIconCircle className="align-self-center">
                <i className="fa-solid fa-code align-middle home-tab-icon" />
              </CDataIconCircle>
              <CDataTypography
                variant="typography-variant-headline-3"
                className="p-3"
              >
                SQL Editor
              </CDataTypography>
              <CDataTypography
                variant="typography-variant-body-medium"
                color="typography-color-medium-grey"
                className="pb-3"
              >
                Write your own SQL to generate precise, detailed queries
                tailored to your needs.
              </CDataTypography>
              <CDataButton
                buttonType={ButtonType.Primary}
                className="navigate-button"
                onClick={() =>
                  tabs.AddEmptyTab(QueryTabType.Query, undefined, "")
                }
              >
                Open Editor
              </CDataButton>
            </CDataCardBody>
          </CDataCard>
        </CDataCol>
      </CDataRow>
    </span>
  );
}
