export const jobsConstants = {
  TIME_CHECK_COLUMN_SELECTED: "This Column will be used as Time Check Column.",
  TIME_CHECK_NOT_REQUIRED:
    "Time Check Column is not required when using Full Updates as a Cache Scheme.",
  FULL_UPDATES_ONLY: "This table only supports Full Update caching.",
  FULL_UPDATES_INFO: "Caches the entire data set each time the job runs.",
  INCREMENTAL_UPDATES_INFO:
    "Uses the last modified timestamp to only retrieve new or changed records since the last job run when caching data.",
  DELETE_SINGLE_JOB:
    "You are about to delete a job, which will delete the cached data in your database. This action cannot be undone. Are you sure you want to proceed?",
  DELETE_MULTIPLE_JOBS:
    "You are about to delete the selected jobs, which will delete the associated cached data in your database. This action cannot be undone. Are you sure you want to proceed?",
  JOB_SUCCESSFULLY_DELETED_TITLE: "Job successfully deleted",
  JOBS_SUCCESSFULLY_DELETED_TITLE: "Jobs successfully deleted",
  JOB_SUCCESSFULLY_DELETED_BODY: "1 job was successfully deleted.",
  DEFAULT_JOB_ID: "00000000-0000-0000-0000-000000000000",
};
