import { MenuItem } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useFormikContext } from "formik";
import { isEmpty } from "lodash";
import React from "react";
import { CDataFormSelect } from "../../../../../../../components/form/CDataFormSelect";
import { DataType } from "../../../../../../../models";
import { IColumnMetadata } from "../../../../../../../api/metadata/getColumnsForTable";
import {
  IQueryBuilderModel,
  QueryAggregateFunctionType,
} from "../../../models/IQueryBuilderModel";

type MetricSelectProps = {
  metricIndex: number;
};

export function MetricTypeSelect(props: MetricSelectProps) {
  const { metricIndex } = props;

  const queryClient = useQueryClient();

  const { values } = useFormikContext<IQueryBuilderModel>();

  const metric = values.metrics[metricIndex];

  const disabled = isEmpty(metric.column.column);

  // The ColumnSelect component should have run this query, so just pull the data from there.
  const data = queryClient.getQueryData<IColumnMetadata[]>([
    "/columns",
    metric.column.table.connectionName,
    metric.column.table.schema,
    metric.column.table.tableName,
  ]);

  const allColumns = data ?? [];

  const columnMetadata = allColumns.find(
    (c) => c.columnName === metric.column.column,
  );

  let children: React.JSX.Element[] = [];

  const possibleAggregates =
    columnMetadata != null
      ? getPossibleAggregatesForColumn(columnMetadata.dataType)
      : // Default to the aggregates for a string, we default to COUNT so we need that in the options.
        getPossibleAggregatesForColumn(DataType.VARCHAR);

  children = possibleAggregates.map((item) => (
    <MenuItem key={item} value={item}>
      {item}
    </MenuItem>
  ));

  return (
    <CDataFormSelect
      disabled={disabled}
      name={`metrics[${metricIndex}].operatorType`}
      suppressInternalHelperText={true}
    >
      {children}
    </CDataFormSelect>
  );
}

function getPossibleAggregatesForColumn(
  dataType: DataType,
): QueryAggregateFunctionType[] {
  if (
    dataType === DataType.BIGINT ||
    dataType === DataType.DECIMAL ||
    dataType === DataType.DOUBLE ||
    dataType === DataType.FLOAT ||
    dataType === DataType.INTEGER ||
    dataType === DataType.NUMERIC ||
    dataType === DataType.SMALLINT ||
    dataType === DataType.TINYINT
  ) {
    return [
      QueryAggregateFunctionType.AVERAGE,
      QueryAggregateFunctionType.COUNT,
      QueryAggregateFunctionType.DISTINCT_COUNT,
      QueryAggregateFunctionType.MAX,
      QueryAggregateFunctionType.MIN,
      QueryAggregateFunctionType.SUM,
    ];
  } else {
    return [
      QueryAggregateFunctionType.COUNT,
      QueryAggregateFunctionType.DISTINCT_COUNT,
    ];
  }
}
