import React from "react";

export const VerbosityHelperText = () => {
  return (
    <div className="verbosity-helper-text">
      Setting the verbosity level to 3 or higher may result in sensitive
      customer information, such as Personal Identifiable Information (PII),
      being stored in log files for up to 10 days. This is especially important
      to avoid when logs contain highly sensitive data, such as personal Health
      Records (HIPAA) information.
    </div>
  );
};
