import { useEffect, useState } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import FilterGrid from "../../components/FilterGrid";
import { connect } from "react-redux";
import { ClientDetails, IClientDetails } from "./ClientDetails";
import { ClientCard } from "./ClientCard";
import { IWorkspaceList, IWorkspaceListItem } from "../../models";
import { useAPI } from "../../components/useAPI";
import { RequestType } from "../../components/withAPI";
import Loader from "../../components/Loader";
import { useIsSpreadsheetUser } from "../../hooks/useIsSpreadsheetUser";

const Categories: any = {
  all: "All",
  bi: "BI and Reporting",
  etl: "ETL",
  no: "No-Code/Low-Code",
  dev: "Dev",
  iPaaS: "iPaaS",
};

const ClientConnectivityContent = () => {
  const [workspaces, setWorkspaces] = useState<IWorkspaceListItem[]>([]);
  const [loading, setLoading] = useState(true);
  const api = useAPI();

  const showOnlySpreadsheets = useIsSpreadsheetUser();

  useEffect(() => {
    const getWorkspaceList = async () => {
      const { status, payload } = await api.callAPI(
        RequestType.Get,
        "/workspaces/forCurrentUser",
        "Failed to get workspace list due to the following error:",
      );
      if (status === 200) {
        const list = payload as IWorkspaceList;
        setWorkspaces(list.workspaces as IWorkspaceListItem[]);
      }
      setLoading(false);
    };

    getWorkspaceList();
  }, []); // eslint-disable-line

  function getSpreadSheetClients(): string[] {
    return ["Excel", "Google Sheets"];
  }

  const spreadSheetClients = getSpreadSheetClients();

  const clientList = ClientDetails.filter((client) => {
    return (
      (showOnlySpreadsheets &&
        spreadSheetClients.includes(client.description)) ||
      !showOnlySpreadsheets
    );
  });

  // TODO: Process ClientDetails list here, so we don't have to pass props to FilterGrid for things like isFirstTimeRun
  const clientOptions = clientList
    .map((client: IClientDetails, index: number) => {
      return {
        tile: <ClientCard clientDetails={client} workspaces={workspaces} />,
        name: client.description,
        category: client.category,
        id: index,
      };
    })
    .sort((a: any, b: any) => {
      if (showOnlySpreadsheets) {
        if (
          spreadSheetClients.includes(a.name) &&
          !spreadSheetClients.includes(b.name)
        ) {
          return -1;
        } else if (
          !spreadSheetClients.includes(a.name) &&
          spreadSheetClients.includes(b.name)
        ) {
          return 1;
        }
      }

      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      } else {
        return 0;
      }
    });

  if (loading) {
    return <Loader />;
  }

  return (
    <span className="pages-clientConnectivity-ClientConnectivityContent">
      <FilterGrid
        tiles={clientOptions}
        tileTypes="Clients"
        categories={Categories}
        searchHidden={showOnlySpreadsheets}
      />
    </span>
  );
};
const mapStateToProps = (state: any) => {
  return {
    sidebarIsOpen: state.sidebar.isOpen,
  };
};
export default connect(mapStateToProps)(ClientConnectivityContent);
