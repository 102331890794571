import { CallAPIFunction } from "../../components/useAPI";
import { RequestType } from "../../components/withAPI";
import { ToastrError } from "../../services/toastrService";

export async function goToDashboard(callAPI: CallAPIFunction) {
  try {
    window.onbeforeunload = null;

    const response = await callAPI(
      RequestType.Put,
      "/account/setIsSetupFinished",
      "",
    );

    if (response.status === 200) {
      window.location.href = encodeURI(window.location.origin + "/");
    } else {
      const err = response.error;
      if (response.status === 401) {
        window.location.href = encodeURI(
          window.location.origin + "/initial-setup",
        );
      } else {
        ToastrError(
          "Failed to set IsSetupFinished with the following error:",
          err.error,
        );
      }
    }
  } catch (error) {
    console.error(error);
    window.location.href = encodeURI(window.location.origin + "/initial-setup");
  }
}
