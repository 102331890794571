import { useEffect, useMemo, useState } from "react";
import { IQueryBuilderCommonProps } from "../../../models/IQueryBuilderCommonProps";
import { Col, Input, Row, Spinner } from "reactstrap";
import { CDataTypography } from "../../../../../../../components/text/CDataTypography";
import Loader from "../../../../../../../components/Loader";
import {
  IQueryBuilderModel,
  IQueryBuilderSort,
} from "../../../models/IQueryBuilderModel";
import { CDataAutocomplete } from "@cdatacloud/component-library";
import { SortDirection } from "../../../models/SortDirection";
import { useMutation } from "@tanstack/react-query";
import { IQueryBuilderTable } from "../../../models/IQueryBuilderTable";
import { getColumnsForTable } from "../../../../../../../api/metadata/getColumnsForTable";
import { mapColumnMetadataToQueryBuilderColumn } from "../Data/AddDataModal/AddDataModal.functions";
import { IQueryBuilderColumn } from "../../../models/IQueryBuilderColumn";
import { produce } from "immer";
import { getFullyQualifiedColumnName } from "../../../sqlGenerator/getFullyQualifiedColumnName";
import { QueryBuilderTableDropdownOption } from "../components/QueryBuilderTableDropdownOption";

interface AddSortsModalContentProps extends IQueryBuilderCommonProps {
  querySort: IQueryBuilderSort;
  setQuerySort: (queryFilter: IQueryBuilderSort) => void;
  sortToEdit?: IQueryBuilderSort;
}

export function AddSortsModalContent(props: AddSortsModalContentProps) {
  const { connectionsList, queryData, querySort, setQuerySort, sortToEdit } =
    props;

  const [isProcessing, setIsProcessing] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(
    props.sortToEdit !== undefined,
  );

  const { data, mutateAsync: fetchAndProcessColumns } = useMutation({
    mutationKey: ["columns"],
    mutationFn: getColumnsForTable,
    onError: () => {
      setQuerySort(
        produce(querySort, (draft) => {
          draft.column.column = "";
        }),
      );
    },
    onSettled: () => {
      setIsLoading(false);
    },
    meta: {
      errorMessage: "Failed to get column list due to the following error:",
    },
  });

  const possibleColumns = useMemo(() => {
    if (data == null) {
      return [];
    }
    let columns: IQueryBuilderColumn[];

    // If we have no metrics or GROUP BYs, just use all possible columns from the selected table.
    if (queryData.metrics.length === 0 && queryData.groupBy.length === 0) {
      columns = mapColumnMetadataToQueryBuilderColumn(connectionsList, data);
    } else {
      const myTableAlias = querySort.column.table.tableAlias;
      // If the user has a metric or GROUP BY, they can only select those columns.
      columns = queryData.metrics
        .filter((m) => m.column.table.tableAlias === myTableAlias)
        .map((m) => m.column);

      columns = [
        ...columns,
        ...queryData.groupBy
          .filter((g) => g.column.table.tableAlias === myTableAlias)
          .map((g) => g.column),
      ];
    }

    function getUniqueColumnName(column: IQueryBuilderColumn) {
      return getFullyQualifiedColumnName(column) + ";;;" + column.alias;
    }

    // Remove columns from the dropdown if the user already added that sort.
    const alreadyAddedSortColumns = new Set(
      queryData.sort.map((s) => getUniqueColumnName(s.column)),
    );

    columns = columns.filter(
      (c) =>
        !alreadyAddedSortColumns.has(getUniqueColumnName(c)) ||
        getUniqueColumnName(c) === getUniqueColumnName(querySort.column),
    );

    return columns;
  }, [data, querySort]); // eslint-disable-line

  useEffect(() => {
    async function fetchInitialColumns(
      connectionName: string,
      schema: string,
      tableName: string,
    ) {
      await fetchAndProcessColumns({ connectionName, schema, tableName });
    }

    if (sortToEdit != null) {
      fetchInitialColumns(
        sortToEdit.column.table.connectionName,
        sortToEdit.column.table.schema,
        sortToEdit.column.table.tableName,
      );
    } else if (
      querySort.column &&
      querySort.column.table.tableName &&
      !querySort.column.column
    ) {
      fetchInitialColumns(
        querySort.column.table.connectionName,
        querySort.column.table.schema,
        querySort.column.table.tableName,
      );
    }
  }, []); // eslint-disable-line

  if (isLoading) {
    return <Loader />;
  }

  const onTableChange = async (newSort: IQueryBuilderTable) => {
    setQuerySort(
      produce(querySort, (draft) => {
        draft.column.table = newSort;
      }),
    );

    setIsProcessing(true);
    await fetchAndProcessColumns({
      connectionName: newSort.connectionName,
      schema: newSort.schema,
      tableName: newSort.tableName,
    });
    setIsProcessing(false);
  };

  const onColumnChange = async (value: IQueryBuilderColumn) => {
    setQuerySort(
      produce(querySort, (draft) => {
        draft.column.column = value.column;
        draft.column.alias = value.alias;
        draft.column.dataType = value.dataType;
      }),
    );
  };

  const getTableDropdownOptions = (queryData: IQueryBuilderModel) => {
    return [queryData.from, ...queryData.joins.map((j) => j.right.table)];
  };

  const tableOptions = getTableDropdownOptions(queryData);

  return (
    <div className="add-sorts-modal-body">
      <div>Specify the table and column to sort.</div>
      <Row className="mb-2">
        <Col>
          <CDataTypography
            variant="typography-variant-headline-5"
            className="required mb-2"
          >
            Table Name
          </CDataTypography>
          <CDataAutocomplete<IQueryBuilderTable>
            aria-label="Select Table Name"
            dropdownLabel={(option) => {
              return <QueryBuilderTableDropdownOption option={option} />;
            }}
            getOptionLabel={(option) => option.tableAlias}
            handleChange={(_event, newValue) => {
              onTableChange(newValue);
            }}
            id="ai-connection-selector2"
            isOptionEqualToValue={(option, value) => {
              return option.tableAlias === value.tableAlias;
            }}
            options={tableOptions}
            menuClass="query-builder-table-dropdown-menu"
            disableWholeSelector={tableOptions.length === 1}
            selectedValue={
              querySort.column.table.tableName !== ""
                ? querySort.column.table
                : null
            }
          />
        </Col>
        <Col>
          <CDataTypography
            variant="typography-variant-headline-5"
            className="required mb-2"
          >
            Column Name
          </CDataTypography>
          <CDataAutocomplete<IQueryBuilderColumn>
            aria-label="Select Column Name"
            disableWholeSelector={querySort.column.table.tableName === ""}
            selectedValue={
              querySort.column.column !== "" ? querySort.column : null
            }
            isOptionEqualToValue={(option, value) => {
              if (!!option.alias && !!value.alias) {
                return option.alias === value.alias;
              } else {
                return option.column === value.column;
              }
            }}
            dropdownLabel={(column) => <>{column.alias ?? column.column}</>}
            getOptionLabel={(column) => column.alias ?? column.column}
            handleChange={(_event, value) => onColumnChange(value)}
            options={possibleColumns}
          />
        </Col>
      </Row>

      <CDataTypography
        variant="typography-variant-headline-5"
        className="sort-type-header required"
      >
        Sort Type
      </CDataTypography>
      <Row>
        <Col className="sort-type">
          <Input
            type="radio"
            className="me-2"
            aria-label="Set Sort Type Ascending"
            checked={querySort.direction === SortDirection.ASC}
            onChange={() => {
              setQuerySort({
                ...querySort,
                direction: SortDirection.ASC,
              });
            }}
          />
          <CDataTypography
            variant="typography-variant-body-regular"
            className="d-inline"
          >
            Ascending
          </CDataTypography>
        </Col>
        <Col className="sort-type ps-1">
          <Input
            type="radio"
            className="me-2"
            aria-label="Set Sort Type Descending"
            checked={querySort.direction === SortDirection.DESC}
            onChange={() => {
              setQuerySort({
                ...querySort,
                direction: SortDirection.DESC,
              });
            }}
          />
          <CDataTypography
            variant="typography-variant-body-regular"
            className="d-inline"
          >
            Descending
          </CDataTypography>
        </Col>
      </Row>
      <div hidden={!isProcessing} className="spinner-height-adjusted">
        <div className="loading-background" />
        <Spinner className="spinner-border loading-spinner" color="info" />
      </div>
    </div>
  );
}
