import { useMutation } from "@tanstack/react-query";
import {
  ButtonType,
  CDataButton,
} from "../../../../components/buttons/CDataButton";
import { CDataModalV2 } from "../../../../components/modal/CDataModalV2";
import { IConnection } from "../../../../models";
import { deleteConnection } from "../../api/deleteConnection";
import { ToastrSuccess } from "../../../../services/toastrService";
import { useNavigate } from "react-router-dom";

export type DeleteConnectionModalProps = {
  connection: Pick<IConnection, "id" | "name" | "driver">;
  displayed: boolean;
  close: () => void;
};

export function DeleteConnectionModal(props: DeleteConnectionModalProps) {
  const { connection, displayed, close } = props;
  const navigate = useNavigate();

  const { mutate, isPending } = useMutation({
    mutationKey: ["/account/connections/:connectionId"],
    mutationFn: deleteConnection,
    meta: {
      errorMessage: "Failed to delete connection due to the following error:",
    },
    onSuccess: () => {
      close();
      ToastrSuccess(
        "Connection successfully deleted",
        `Your ${connection.driver} connection, ${connection.name} was successfully deleted.`,
      );
      navigate("/connections");
    },
  });

  return (
    <CDataModalV2
      title="Delete Connection"
      close={close}
      displayed={displayed}
      spacedFooter={false}
      primaryButton={
        <CDataButton
          buttonType={ButtonType.Danger}
          disabled={isPending}
          onClick={() => {
            mutate({
              connectionId: connection.id!,
            });
          }}
        >
          <div className="icon" />
          Delete
        </CDataButton>
      }
      secondaryButton={
        <CDataButton buttonType={ButtonType.Secondary} onClick={() => close()}>
          Close
        </CDataButton>
      }
    >
      By processing this request, your previously setup Virtual Datasets,
      Derived Views, Jobs and Saved Queries may become affected that originate
      from this connection. Are you sure you want to proceed?
    </CDataModalV2>
  );
}
