import DataLabelAndDropdown from "./DataLabelAndDropdown";
import { useFormikContext } from "formik";
import { Badge } from "reactstrap";
import { IQueryBuilderJoin } from "../../../../../models/IQueryBuilderModel";
import { upperCase } from "lodash";
import { IQueryBuilderColumn } from "../../../../../models/IQueryBuilderColumn";
import { getFullyQualifiedColumnName } from "../../../../../sqlGenerator/getFullyQualifiedColumnName";
import { DataType } from "../../../../../../../../../models";
import { produce } from "immer";
import { TextField } from "@mui/material";

interface NewTableColumnFieldProps {
  columnList: IQueryBuilderColumn[];
}

const NewTableColumnField = (props: NewTableColumnFieldProps) => {
  const { columnList } = props;
  const { setValues, values } = useFormikContext<IQueryBuilderJoin>();

  const currentTable = values.right.table.tableName;

  function updateSelectedColumn(column: IQueryBuilderColumn) {
    if (
      getFullyQualifiedColumnName(column) !==
      getFullyQualifiedColumnName(values.right)
    ) {
      setValues(
        produce(values, (draft) => {
          draft.right.column = column.column;
          draft.right.dataType = column.dataType;
          draft.left.column = "";
        }),
      );
    }
  }

  const isOptionEqualToValue = (
    option: IQueryBuilderColumn,
    value: IQueryBuilderColumn,
  ) => {
    return option.column === value.column && option.dataType === value.dataType;
  };

  return (
    <DataLabelAndDropdown
      handleChange={updateSelectedColumn}
      isDisabled={!currentTable}
      isOptionEqualToValue={isOptionEqualToValue}
      label={"Column Name"}
      dropdownLabel={(option) => option.column}
      optionLabel={(option) => (option.column ? option.column : "")}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {params.InputProps?.endAdornment}
                  {values.right.column && (
                    <Badge className="data-type-badge" color="">
                      {upperCase(DataType[values.right.dataType])}
                    </Badge>
                  )}
                </>
              ),
            }}
          />
        );
      }}
      id={"newTable.column"}
      key={values.right?.column}
      options={columnList || []}
      value={values.right.column ? values.right : null}
    />
  );
};

export default NewTableColumnField;
