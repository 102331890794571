import { useContext, useEffect, useState } from "react";
import { Button, Col } from "reactstrap";

import {
  APIAuthType,
  ConnectionType,
  UserRole,
} from "../../../../../../models";
import { IModalProps } from "../../../../../../components/CDataModal";
import { ToastrSuccess } from "../../../../../../services/toastrService";
import { RequestType } from "../../../../../../components/withAPI";
import {
  ApiConnectionPageType,
  IGlobalSettings,
} from "../../../../ApiConnector";
import { ModalContext } from "../../../../../../routes/ModalContext";
import classNames from "classnames";
import { OAuthRequest } from "../../../../../connections/components/oauth/OAuthButton";
import { OAuthPasswordGrantMode } from "../../../../../../models/APIConnector/OAuthPasswordGrantMode";
import { useAppSelector } from "../../../../../../redux/hooks";
import { useAPI } from "../../../../../../components/useAPI";
import { useOAuthListener } from "../../../../../connections/components/oauth/useOAuthListener";

export interface OAuthButtonProps {
  connectionId: string;
  apiConnectionPageType: ApiConnectionPageType;
  driverType: string;
  authScheme: number;
  isOAuthWebDriver: boolean;
  globalSettings: IGlobalSettings;
  setGlobalSettings: (globalSettings: IGlobalSettings) => void;
  connectionType?: ConnectionType;
  isGoogleConnection?: boolean;
  formRef?: any;
}

interface OAuthButtonState {
  oauthBtnText: string;
  oauthBtnColor: string;
  oauthBtnDisabled: boolean;
  currentUserId: any;
  currentUserRole: UserRole;
}

export function OAuthButton(props: OAuthButtonProps) {
  const user = useAppSelector((state) => state.user);
  const api = useAPI();

  const { startOAuthListener, cancelOAuthListener } = useOAuthListener();

  const [oauthState, setOAuthState] = useState<OAuthButtonState>({
    oauthBtnText: "Sign in",
    oauthBtnColor: "primary",
    oauthBtnDisabled: false,
    currentUserId: user.id,
    currentUserRole: user.role,
  });
  const isInvalid = props.globalSettings?.oAuthButtonInvalid ?? false;

  const modalContext = useContext(ModalContext);

  useEffect(() => {
    updateOAuthButtonText(props);
  }, [props]); // eslint-disable-line

  useEffect(() => {
    clearExistingOAuth();
  }, [props.authScheme]); // eslint-disable-line

  async function setupOAuth() {
    setOAuthState({ ...oauthState, oauthBtnDisabled: true });

    let keepOAuthBtnDisabled = false;

    try {
      await clearExistingOAuth();

      const driverAndProps = getDriverAndProps();
      const { status, payload } = await api.callAPI(
        RequestType.Post,
        `/oauth/${driverAndProps.Driver}/authorizationUrl`,
        "Failed to get OAuth authorization URL due to the following error:",
        driverAndProps,
      );

      if (status === 200) {
        const body = payload;

        startOAuthListener(body.callbackId, (oauthData) =>
          oauthMessage(oauthData, body.passthroughParameters),
        );

        // Open a new tab where the user can complete the OAuth sign-in flow.
        window.open(body.oauthUrl, "_blank");

        // Show the OAuth modal.
        const modal = {
          title: "Waiting for API Credentials",
          body: (
            <text>
              Please complete the sign-in flow in the other tab in order to set
              up access to your API account.
            </text>
          ),
          secondaryButton: (
            <Button
              color="secondary"
              onClick={() => {
                cancelOAuth();
                modalContext.toggleModal();
              }}
            >
              Cancel
            </Button>
          ),
          modalSize: "md",
          displayToggleCloseButton: false,
          displayed: true,
          close: cancelOAuth,
        } as IModalProps;
        modalContext.setModal(modal);
        keepOAuthBtnDisabled = true;
      }
    } finally {
      setOAuthState({ ...oauthState, oauthBtnDisabled: keepOAuthBtnDisabled });
    }
  }

  async function cancelOAuth() {
    cancelOAuthListener();

    setOAuthState({
      ...oauthState,
      oauthBtnDisabled: false,
    });
  }

  async function clearExistingOAuth() {
    // If we haven't started OAuth setup, nothing to do, we're done.
    if (oauthState.oauthBtnText === "Sign in") return;

    // Otherwise we have OAuth stuff to clear.
    // Delete any existing OAuth tokens stored in our system if this is an existing connection.
    if (props.connectionId) {
      const driverAndProps = getDriverAndProps();
      const { status, error } = await api.callAPI(
        RequestType.Delete,
        `/account/connections/${props.connectionId}/oauthTokens`,
        "",
        driverAndProps,
      );

      if (status !== 200) {
        if (status !== 401) {
          modalContext.showTimeout();
        } else {
          const err = error;
          modalContext.showError(
            "Failed to delete existing OAuth tokens due to the following error:",
            err.error,
          );
          throw Object.assign(
            new Error("Failed to delete existing OAuth tokens."),
            { code: 500 },
          );
        }
      }
    }

    // Clear the OAuth properties and change button back to initial state.
    updateOAuthProps(null);
    setOAuthState({
      ...oauthState,
      oauthBtnText: "Sign in",
      oauthBtnColor: "primary",
    });
  }

  function updateOAuthButtonText(props: OAuthButtonProps) {
    if (
      props.globalSettings?.authentication?.oAuthAccessToken &&
      props.apiConnectionPageType === ApiConnectionPageType.EditConnection
    ) {
      setOAuthState({
        ...oauthState,
        oauthBtnText: "Reconnect",
        oauthBtnDisabled: false,
        oauthBtnColor: "secondary",
      });
    } else {
      setOAuthState({
        ...oauthState,
        oauthBtnText: "Sign in",
        oauthBtnDisabled: false,
        oauthBtnColor: "primary",
      });
    }
  }
  function updateOAuthProps(tokenResponse: any) {
    const newGlobalSettings = { ...props.globalSettings };
    const authentication: any = newGlobalSettings.authentication;
    authentication.oAuthAccessToken = tokenResponse
      ? tokenResponse.OAuthAccessToken || tokenResponse.oauthaccesstoken
      : null;
    authentication.oAuthRefreshToken = tokenResponse
      ? tokenResponse.OAuthRefreshToken || tokenResponse.oauthrefreshtoken
      : null;
    newGlobalSettings.oAuthButtonInvalid = !authentication.oAuthAccessToken;
    props.setGlobalSettings(newGlobalSettings);
  }

  // Event handler for oauthChannel.onmessage. Receives an object with query string parameters from the OAuth callback.
  async function oauthMessage(
    qp: Record<string, unknown>,
    oauthPassthroughParams: Record<string, unknown>,
  ) {
    // Get connection settings, and add OAuth parameters to it. Also add any OAuth passthrough parameters.
    const data = getDriverAndProps();
    data.oauthParams = {
      ...qp,
      ...oauthPassthroughParams,
    };

    // Get OAuth settings.
    const { status, payload } = await api.callAPI(
      RequestType.Post,
      `/oauth/${data.Driver}/token`,
      "Failed to get OAuth settings due to the following error:",
      data,
    );

    if (status === 200) {
      updateOAuthProps(payload);
    }

    // Close the channel, hide the modal, and re-enable the button.
    cancelOAuth();
    modalContext.toggleModal();
    ToastrSuccess(
      "Your authentication was successful",
      "Click Save Changes to update the Connection",
    );
  }

  function getDriverAndProps(): OAuthRequest {
    const data: OAuthRequest = {};
    data.Driver = props.driverType;
    data.Props = {};
    data.ConnectionId = props.connectionId;
    data.ConnectionType = props.connectionType;
    data.UserId = oauthState.currentUserId;
    data.UserRole = oauthState.currentUserRole;
    const connProps: { [key: string]: any } = {};
    for (const pair of props.formRef.current.children) {
      // This code is looping over the HTML in the form and pulling out attributes, which is a really bad practice
      // and breaks for things like dropdowns.
      // Setting fields for things like dropdowns is done below.
      if (pair.children?.[1]?.id) {
        connProps[pair.children[1].id as keyof typeof connProps] =
          pair.children[1].value;
      }
    }
    if (props.authScheme === APIAuthType.OAuth1) {
      connProps["OAuthVersion"] = "1.0";
    } else if (props.authScheme === APIAuthType.OAuth2) {
      connProps["OAuthVersion"] = "2.0";
      connProps["OAuthParams"] =
        props.globalSettings?.authentication.oAuthParams;
      connProps["OAuthPasswordGrantMode"] =
        props.globalSettings?.authentication.oAuthPasswordGrantMode ===
        OAuthPasswordGrantMode.Post
          ? "Post"
          : "Basic";
      connProps["Scope"] = "offline_access";
    }
    data.Props = connProps;
    const verbosity = props.globalSettings?.advanced?.verbosity;
    if (verbosity != null && verbosity.length > 0) {
      data.Props["Verbosity"] = verbosity;
    }
    return data;
  }

  return (
    <>
      {props.isOAuthWebDriver ? (
        <div data-testid="oauth-buttons" className="oauth-container my-4">
          <Col xs="auto">
            <Button
              color={isInvalid ? undefined : oauthState.oauthBtnColor}
              className={classNames({
                "invalid-button": isInvalid,
                "oauth-reconnect-button":
                  oauthState.oauthBtnText === "Reconnect" && !isInvalid,
                "oauth-sign-in-button":
                  oauthState.oauthBtnText === "Reconnect" && !isInvalid,
              })}
              name="setupOAuth"
              id="setupOAuth"
              outline
              onClick={setupOAuth}
              disabled={oauthState.oauthBtnDisabled}
              data-testid="button-setup-oauth"
            >
              {props.globalSettings?.authentication?.oAuthAccessToken ? (
                <i className="fa fa-redo align-middle no-pointer-event" />
              ) : (
                <i className="fa fa-sign-in-alt align-middle no-pointer-event" />
              )}
              <span className="ms-2">{oauthState.oauthBtnText}</span>
            </Button>
            {isInvalid && (
              <div className="invalid-feedback-text mt-1 ">
                Sign in is required before saving & testing your connection.
              </div>
            )}
          </Col>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
