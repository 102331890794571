import { useRef, useState } from "react";
import { Range } from "react-date-range";
import { Option } from "react-multi-select-component";

export function useDateRange() {
  const myRef = useRef<any>(null);
  const [dateRange, setDateRange] = useState<Range>({
    startDate: undefined,
    endDate: new Date(""),
    key: "selection",
  });
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedDateRange, setSelectedDateRange] = useState<Option | null>(
    null,
  );

  const dateRangeOptions = [
    { label: "Last 1 Day", value: "Last 1 Day" },
    { label: "Last 7 Days", value: "Last 7 Days" },
    { label: "Last 30 Days", value: "Last 30 Days" },
    { label: "Custom", value: "Custom" },
  ];

  const handleDateRangeSelectChange = (selected: Option[]) => {
    setSelectedDateRange(
      selected.length > 0 ? selected[selected.length - 1] : null,
    );
  };

  const executeFunctionForNonCustomOption = (value: string) => {
    if (selectedDateRange?.value === value) {
      clearDateRange();
      return;
    }

    const millisecondsInDay = 24 * 60 * 60 * 1000;
    let days: number;

    switch (value) {
      case "Last 1 Day":
        days = 1;
        break;
      case "Last 7 Days":
        days = 7;
        break;
      default:
        days = 30;
    }

    setDateRange({
      startDate: new Date(Date.now() - days * millisecondsInDay),
      endDate: new Date(),
      key: "selection",
    });
  };

  const handleItemClick = (
    option: Option,
    onClick: () => void,
    disabled: boolean,
  ) => {
    if (disabled) return;

    onClick();

    if (option.value !== "Custom") {
      executeFunctionForNonCustomOption(option.value);
    } else {
      clearDateRange();
    }
  };

  const clearDateRange = () => {
    setDateRange({
      startDate: undefined,
      endDate: new Date(""),
      key: "selection",
    });
  };

  const clearDateRangeSelection = () => {
    setSelectedDateRange(null);
  };

  return {
    myRef,
    dateRange,
    setDateRange,
    anchorEl,
    setAnchorEl,
    dateRangeOptions,
    selectedDateRange,
    handleDateRangeSelectChange,
    handleItemClick,
    clearDateRange,
    clearDateRangeSelection,
  };
}
