import React from "react";
import { Row } from "reactstrap";
import { ModalPromptFieldTypes, ResourceType } from "../ModalPromptBody";
import ClientCardModalField from "./ClientCardModalField";

interface IUrlField {
  idName: string;
  isOpenApi?: boolean;
  selectedResourceType: ResourceType;
  selectedWorkspaceName: string | undefined;
  selectedWorkspaceId: string;
  isRestApi?: boolean;
  containerClassName?: string;
}

const UrlField = (props: IUrlField) => {
  let url;
  if (props.isOpenApi) {
    url = `${window.location.origin}/datasets/workspace/${props.selectedWorkspaceId}/swagger?version=3`;
  } else if (props.isRestApi) {
    url = "https://cloud.cdata.com/api/query";
  } else {
    url = props.selectedWorkspaceName
      ? `${window.location.origin}/api/odata/${props.selectedWorkspaceName}`
      : `${window.location.origin}/api/odata/service`;
  }

  url = encodeURI(url);
  return (
    <Row className={props.containerClassName || "my-3"}>
      <ClientCardModalField
        fieldKey={"URL"}
        fieldType={ModalPromptFieldTypes.SingleField}
        idName={props.idName}
        selectedResourceType={props.selectedResourceType}
        selectedWorkspaceName={props.selectedWorkspaceName}
        value={url}
      />
    </Row>
  );
};

export default UrlField;
