import { ReactNode, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { OEM401Page } from "./oemconnections/OEM401Page";
import { useAPI } from "../../components/useAPI";
import Loader from "../../components/Loader";
import { RequestType } from "../../components/withAPI";
import { OEMUserLoader } from "./OEMUserLoader";

interface OEMUserGuardProps {
  children: ReactNode;
}

/**
 * This component handles the authentication for regular OEM users.
 * This component wraps routes where the OEM redirects a user to our site to perform a single action
 * like create or edit a connection.
 *
 * It does not handle authentication for OEM admins.
 */
export const OEMUserGuard = (props: OEMUserGuardProps) => {
  const { children } = props;

  return <OEMJwtLoader>{children}</OEMJwtLoader>;
};

/**
 * This component is hit first when authenticating an OEM user in the UI.
 *
 * It handles the logic of:
 * 1. Make sure they have a JWT.
 * 2. If their JWT is in the URL, it's short lived. Load a long lived JWT.
 * 3. If any of this fails, give them a 401 page.
 */
const OEMJwtLoader = (props: OEMUserGuardProps) => {
  const { children } = props;

  const navigate = useNavigate();

  const api = useAPI();

  const [searchParams] = useSearchParams();

  const existingJwt = window.sessionStorage.getItem("oemJwt");
  const jwtFromQueryParams = searchParams.get("token");

  // Default loading to false if there is not a token query param.
  // If we have a token query param we need to use it to get a long lived JWT.
  const [loading, setLoading] = useState(jwtFromQueryParams != null);

  // True if there was an error authenticating the user and we need to show them a 401 page.
  const [hadAuthError, setHadAuthError] = useState<boolean>(false);

  async function getLongLivedJwt() {
    if (jwtFromQueryParams == null) {
      setLoading(false);
    } else {
      // Use the short-lived JWT from the URL to get a long lived JWT.
      window.sessionStorage.setItem("oemJwt", jwtFromQueryParams);

      const { payload, status } = await api.callAPI<string>(
        RequestType.Post,
        "/oem/user/authenticate",
        "401 - Unauthenticated",
        {},
      );

      setLoading(false);

      if (status === 200) {
        window.sessionStorage.setItem("oemJwt", payload!);

        // Remove the token from the URL so we don't re-trigger the logic of loading the JWT from the API.
        const urlSearchParams = new URLSearchParams(window.location.search);
        urlSearchParams.delete("token");
        navigate(window.location.pathname + "?" + urlSearchParams.toString(), {
          replace: true,
        });
      } else {
        setHadAuthError(true);
      }
    }
  }

  useEffect(() => {
    getLongLivedJwt();
  }, []); // eslint-disable-line

  // If the user has no JWT from the token query param and they have no
  // JWT in session storage, they can not access this page.
  // Just give them a generic 401 page.
  if (jwtFromQueryParams == null && existingJwt == null) {
    return <OEM401Page />;
  }

  // If we could not authenticate the user there's not much we can do,
  // give them a generic 401 page.
  // We don't know where to send them if their token expires.
  if (hadAuthError) {
    return <OEM401Page />;
  }

  if (loading) {
    return <Loader />;
  }

  return <OEMUserLoader>{children}</OEMUserLoader>;
};
