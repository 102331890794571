import { useState, useEffect, useContext, useMemo } from "react";
import { CachingStepNames } from "./CachingStepNames";
import { useAPI } from "../../../components/useAPI";
import { IStepBodyProps } from "../../../components/wizard/components/StepWizardStep";
import Loader from "../../../components/Loader";
import { SelectSchemaWizardContext } from "../../datasets/components/AddAssetWizard/SelectSchemaWizardContext";
import { ICreateCacheJobRequest } from "../../../models/Cache/ICreateCacheJobRequest";
import { JobFrequencyUnit } from "../../../models/Cache/JobFrequencyUnit";
import { ICacheSchemas } from "../../../models/Cache/ICacheSchemas";
import {
  createCacheJob,
  getTimeCheckColumnOptions,
} from "../components/CachingApiCalls";
import CacheTablesBody from "./CacheTablesBody";
import {
  findMatchingColumn,
  getDateTimeColumns,
  getTableColumnInfo,
  parseTimeCheckColumns,
} from "../util/CacheUtils";
import {
  ButtonType,
  CDataButton,
} from "../../../components/buttons/CDataButton";
import { StepWizardStepFooter } from "../../../components/wizard/components/StepWizardStepFooter";
import { StepWizardContext } from "../../../components/wizard/components/StepWizardContext";
import { WizardRunFrequencyRow } from "../EditJob/components/WizardRunFrequencyRow";

export type Schema = {
  schemaName: string;
  parentName: string;
  subRows?: Schema[];
};

interface IScheduleJobs extends IStepBodyProps {
  helperText?: string;
  hasNextStep?: boolean;
  schemas: any[];
}

export type SchemaRowInformation = {
  connectionId: string;
  tableName: string;
  schemaName: string;
  isFullUpdate: boolean;
  timeCheckColumn: string;
  columnOptions?: any[];
  checkColumnSupport: boolean;
};

const ScheduleJobs = (props: IScheduleJobs): any => {
  const { driverName, selectedConnection } = useContext(
    SelectSchemaWizardContext,
  );

  const { setModalSize, toggleModal } = useContext(StepWizardContext);

  const [loading, setLoading] = useState(true);
  const [unit, setUnit] = useState<JobFrequencyUnit>(JobFrequencyUnit.Day);
  const [freqNumberValue, setFreqNumberValue] = useState<number>(1);
  const [rows, setRows] = useState<SchemaRowInformation[]>([]);
  const areAllRowsValid = useMemo(
    () => validateRows(),
    // TODO: CLOUD-12788: Fix this depenency array
    [unit, freqNumberValue, rows], // eslint-disable-line
  );

  const api = useAPI();

  useEffect(() => {
    //Populate schema options
    async function populateOptions(newRows: SchemaRowInformation[]) {
      const timeCheckColumnInfo = await getTimeCheckColumnOptions(
        api.callAPI,
        driverName!,
        selectedConnection.id,
      );
      // If the API call fails, we can't do anything, just bail out.
      if (timeCheckColumnInfo == null) {
        return;
      }
      for (const qRow of newRows) {
        let timeCheckColumns: string[] = [];
        qRow.timeCheckColumn = "";
        if (
          timeCheckColumnInfo.checkColumnSupported === true ||
          timeCheckColumnInfo.timeCheckColumns?.length > 0
        ) {
          const tableColumnInfo = await getTableColumnInfo(
            api.callAPI,
            selectedConnection.name,
            qRow.schemaName,
            qRow.tableName,
          );
          if (tableColumnInfo != null) {
            if (timeCheckColumnInfo.checkColumnSupported === true) {
              timeCheckColumns = getDateTimeColumns(tableColumnInfo);
              qRow.checkColumnSupport = true;
            } else {
              timeCheckColumns = findMatchingColumn(
                tableColumnInfo,
                parseTimeCheckColumns(timeCheckColumnInfo.timeCheckColumns),
              );
              qRow.checkColumnSupport = false;
              qRow.timeCheckColumn = timeCheckColumns[0];
            }
          }
        }
        if (timeCheckColumns.length > 0) {
          qRow.isFullUpdate = qRow.checkColumnSupport;
          qRow.columnOptions = timeCheckColumns;
        } else {
          qRow.isFullUpdate = true;
        }
      }
      setRows(newRows);
      setLoading(false);
    }

    setModalSize("lg");

    //Set up load of the table rows
    const newRows = props.schemas.map<SchemaRowInformation>((row) => {
      return {
        connectionId: selectedConnection.id,
        schemaName: row[1],
        tableName: row[0],
        isFullUpdate: true,
        timeCheckColumn: "",
        checkColumnSupport: false,
      };
    });

    populateOptions(newRows);
  }, []); // eslint-disable-line

  function validateRows() {
    let areAllRowsValid = true;
    rows?.forEach((row) => {
      if (!row.isFullUpdate && !row.timeCheckColumn) {
        areAllRowsValid = false;
      }
    });

    return areAllRowsValid;
  }

  async function onSubmit() {
    const requestModel = {
      jobFrequencyUnit: unit,
      jobFrequency: freqNumberValue,
      cacheSchemas: rows?.map((row: SchemaRowInformation) => {
        return {
          sourceConnection: row.connectionId,
          sourceSchema: row.schemaName,
          sourceTable: row.tableName,
          isFullUpdate: row.isFullUpdate,
          timeCheckColumn: row.timeCheckColumn ? row.timeCheckColumn : "",
        } as ICacheSchemas;
      }),
    } as ICreateCacheJobRequest;
    const isSuccess = await createCacheJob(api.callAPI, requestModel);
    if (isSuccess) {
      setModalSize("md");
      toggleModal();
    }
  }

  const jobFrequencyKeys = Object.keys(JobFrequencyUnit).filter((v) =>
    isNaN(Number(v)),
  );
  jobFrequencyKeys.unshift("select");

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="page-caching-setup-schema">
      <span className="select-table-desc">
        The schedule you set below will apply to all the datasets you are adding
        to your jobs. You will be able to change the schedule for each job
        individually after adding.
      </span>
      <div className="mt-2 mb-2">
        <WizardRunFrequencyRow
          jobFrequency={freqNumberValue}
          setJobFrequency={setFreqNumberValue}
          jobFrequencyUnit={unit}
          setJobFrequencyUnit={setUnit}
        />
      </div>
      <CacheTablesBody rows={rows} setRows={setRows} />
      <StepWizardStepFooter>
        <CDataButton
          buttonType={ButtonType.Borderless}
          onClick={() => {
            props.goToNamedStep!(CachingStepNames.AddTables);
            setModalSize("md");
          }}
        >
          <span className="btn-outline-primary">Back</span>
        </CDataButton>
        <CDataButton
          buttonType={ButtonType.Primary}
          onClick={onSubmit}
          disabled={!(unit && freqNumberValue && areAllRowsValid)}
        >
          Confirm
        </CDataButton>
      </StepWizardStepFooter>
    </div>
  );
};

export default ScheduleJobs;
