import React, { useContext } from "react";
import { ConnectionContext } from "../ConnectionContext";
import { Col, Row } from "reactstrap";
import { compareStrings } from "../../../../utility/CompareStrings";

const SpecialConnectionElements = () => {
  const connectionContext = useContext(ConnectionContext);
  const driver = connectionContext.driverType;

  let bannerText = <></>;
  let bannerLink = <></>;
  let bannerIcon = <></>;
  let url = "";

  // This is currently a simple if/else because we only have one driver, but it can be a switch when there are more special cases.
  if (compareStrings(driver, "SAPSuccessFactors")) {
    bannerIcon = <i className="fa fa-solid fa-arrow-up-right-from-square" />;

    url =
      "https://help.sap.com/docs/SAP_SUCCESSFACTORS_PLATFORM/d599f15995d348a1b45ba5603e2aba9b/af2b8d5437494b12be88fe374eba75b6.html#api-servers";

    bannerLink = (
      <a href={url} target="_blank" rel="noopener noreferrer">
        Retrieve URL
      </a>
    );

    bannerText = (
      <>
        You can find your URL using your Environment and Location from the&nbsp;
        <a href={url} target="_blank" rel="noopener noreferrer">
          API Servers
        </a>
        &nbsp;page of SAP’s Help Portal.
      </>
    );
  } else {
    // If the driver does not have special connection elements, don't return anything.
    return;
  }

  return (
    <span className="connections-special-connection-elements">
      <Row className="special-prop-banner">
        <Col className="special-prop-banner-icon">{bannerIcon}</Col>
        <Col className="special-prop-banner-text">{bannerText}</Col>
        <Col className="special-prop-banner-link">{bannerLink}</Col>
      </Row>
    </span>
  );
};

export default SpecialConnectionElements;
