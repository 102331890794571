import React from "react";
import { Input } from "reactstrap";
import { ICacheJob } from "../../../../models/Cache/ICacheJob";
import { IScheduledQuery } from "../../../../models/ScheduledQueries/IScheduledQuery";
import { useFormikContext } from "formik";

enum AvailableLogVerbosity {
  INFO = 1,
  CONFIG = 2,
  FINE = 3,
  FINER = 4,
}

interface ILogVerbosityDropdown {
  setUnsavedChanges: (unsavedChanges: boolean) => void;
}

export const LogVerbosityDropdown = <T extends ICacheJob | IScheduledQuery>({
  setUnsavedChanges,
}: ILogVerbosityDropdown) => {
  const { values, setValues } = useFormikContext<T>();

  const setVerbosity = (verbosity: number) => {
    setValues({ ...values, logVerbosity: verbosity });
    setUnsavedChanges(true);
  };

  return (
    <span data-testid="row-log-verbosity">
      <label className="fw-bold mb-2">Log Verbosity</label>
      <Input
        name="input-select-log-verbosity"
        data-testid="input-select-log-verbosity"
        type="select"
        className="form-control select"
        value={values.logVerbosity}
        onChange={(event: React.ChangeEvent) => {
          setVerbosity(parseInt((event.target as HTMLInputElement).value));
        }}
      >
        {Object.keys(AvailableLogVerbosity)
          .filter((verbosity) => !isNaN(Number(verbosity)))
          .map((verbosity) => (
            <option key={verbosity} value={verbosity}>
              {verbosity}
            </option>
          ))}
      </Input>
    </span>
  );
};
