import { UncontrolledTooltip } from "reactstrap";
import { ConnectionType, UserRole } from "../models";
import classNames from "classnames";

interface ICustomReportTooltip {
  id: string;
  isCRAvailable: boolean;
  selectedDriver: string;
  connectionType: ConnectionType;
  currentUserRole: UserRole;
  color?: "gray" | "primary";
  openEditConnectionPage: (
    event: React.MouseEvent | null | undefined,
    selectedDriver: string,
    id: string,
    clickedFromCustomReportsIcon: boolean,
    connectionType: ConnectionType,
    overrideConnectionType?: boolean,
  ) => void;
}

const customReportDocs =
  "https://cloud.cdata.com/docs/Connections.html#configuring-custom-reports";

const adminUserTooltipMessage = (
  <>
    <div>Custom reports available.</div>
    <span>
      <a
        className="text-light text-decoration-underline"
        href={customReportDocs}
      >
        Learn more
      </a>
      &nbsp;about Custom Reports.
    </span>
  </>
);

const queryUserTooltipMessage = (
  <>
    <div>Custom reports available.</div>
    <div>Contact an administrator of this</div>
    <div>account to have them added to</div>
    <div>
      to this connection.&nbsp;
      <a
        className="text-light text-decoration-underline"
        href={customReportDocs}
      >
        Learn more
      </a>
    </div>
    <div>about Custom Reports.</div>
  </>
);

const CustomReportTooltip = (props: ICustomReportTooltip) => {
  const {
    color,
    connectionType,
    currentUserRole,
    id,
    isCRAvailable,
    openEditConnectionPage,
    selectedDriver,
  } = props;

  if (!isCRAvailable) return <></>;
  return (
    <span className="custom-report-tooltip">
      <span
        onClick={(event: React.MouseEvent) => {
          if (currentUserRole === UserRole.Admin) {
            openEditConnectionPage(
              event,
              selectedDriver,
              id,
              true,
              connectionType,
            );
          }
        }}
        id={"crIcon" + id}
        className="custom-report-icon"
        data-testid="report-icon"
      >
        <i
          className={classNames("fa-solid fa-file align-bottom", {
            "cr-color-primary": !color || color === "primary",
            "cr-color-gray": color === "gray",
          })}
        />
      </span>
      <UncontrolledTooltip
        autohide={false}
        onClick={(e: { stopPropagation: () => any }) => e.stopPropagation()}
        placement="top"
        target={"crIcon" + props.id}
      >
        {props.currentUserRole === UserRole.Admin
          ? adminUserTooltipMessage
          : queryUserTooltipMessage}
      </UncontrolledTooltip>
    </span>
  );
};

export default CustomReportTooltip;
