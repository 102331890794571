import { useEffect, useMemo, useState } from "react";
import { useAPI } from "../../../../../../components/useAPI";
import { RequestType } from "../../../../../../components/withAPI";
import { IQueryResult } from "../../../../../../models";
import {
  ToastrClean,
  ToastrError,
} from "../../../../../../services/toastrService";
import {
  sanitizeQuery,
  sanitizeQueryProps,
} from "../../../QueryEditor/sanitizeQuery";
import ResultsDetailsGridContainer from "../../../Results/ResultsDetailsGridContainer";
import { IQueryBuilderModel } from "../../models/IQueryBuilderModel";
import { generateQueryBuilderSql } from "../../sqlGenerator/generateQueryBuilderSql";
import { IQueryTab } from "../../../Tabs/useQueryTabs";

type QueryBuilderPreviewTabProps = {
  tab: IQueryTab;
  queryData: IQueryBuilderModel;
  abortRunningQueryRequest: () => void;
};

export function QueryBuilderPreviewTab(props: QueryBuilderPreviewTabProps) {
  const { tab, queryData } = props;

  const api = useAPI();

  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState<IQueryResult | null>(null);

  // Call the API to load our query results whenever the query changes.
  useEffect(() => {
    executeQuery();
  }, [sanitizeQuery]); // eslint-disable-line

  const query = generateQueryBuilderSql(queryData);

  const schema = results?.results?.[0]?.schema;
  const rows = results?.results?.[0]?.rows;

  const tableColumns = useMemo(() => {
    if (schema == null) return [];

    return schema.map((x) => {
      return {
        accessorKey: x.columnLabel!,
        id: x.columnLabel!,
        enableSorting: true,
      };
    });
  }, [schema]);

  type ISanitizedResult = Record<string, string | number | boolean>;

  const sanitizedResults: ISanitizedResult[] = useMemo(() => {
    if (rows == null) {
      return [];
    }

    const allColumnNamesArray = tableColumns.map((x) => x.accessorKey);

    const resultArray: ISanitizedResult[] = [];
    for (let i = 0; i < rows.length; i++) {
      const row: Record<string, string | number | boolean> = {};
      for (let j = 0; j < allColumnNamesArray.length; j++) {
        row[allColumnNamesArray[j]] = rows[i][j];
      }
      resultArray.push(row);
    }

    return resultArray;
  }, [tableColumns, rows]);

  const executeQuery = async () => {
    ToastrClean();
    setResults(null);

    const sanitizeParameters: sanitizeQueryProps = {
      currentQueryInput: query,
      queryLimit: 10,
      setQueryLimit: () => {
        // no-op
      },
      setCurrentQueryInput: () => {
        // no-op
      },
      enforceLimit: true,
    };

    const sanitizedQuery = sanitizeQuery(sanitizeParameters);

    const postData = {
      query: sanitizedQuery,
    };

    setLoading(true);

    const { status, payload } = await api.callAPI<IQueryResult>(
      RequestType.Post,
      "/query",
      "Error fetching results",
      JSON.stringify(postData),
      undefined,
      { "Connect-Cloud-Client": "CDataDataExplorer" },
    );

    if (status === 200) {
      if ((payload as any).error) {
        ToastrError("Error fetching results", (payload as any).error.message);
        return;
      }

      setResults(payload!);
    }

    setLoading(false);
  };

  return (
    <div className="queryBuilderPreviewTab">
      <ResultsDetailsGridContainer
        tab={tab}
        loading={loading}
        showResults={!loading && results != null}
        resultsToggled
        resultColumns={tableColumns}
        resultData={sanitizedResults}
        noData={() => (
          <div className="text-muted text-center">No matching records</div>
        )}
        columnDetails={[]}
        columnMetadata={[]}
        enablePagination={false}
        abortRunningQueryRequest={props.abortRunningQueryRequest}
      />
    </div>
  );
}
