import { OAuthPasswordGrantMode } from "../../../../../models/APIConnector/OAuthPasswordGrantMode";
import { GLOBAL_SETTINGS_CONSTANTS as Constants } from "../../../ApiConnector.constants";

export interface AuthField {
  label: string;
  id: string;
  name: string;
  required?: boolean;
  default?: string;
}

export const basicAuthProps: AuthField[] = [
  { label: "User", id: "User", name: Constants.USER, required: true },
  {
    label: "Password",
    id: "Password",
    name: Constants.PASSWORD,
    required: true,
  },
];

export const digestAuthProps: AuthField[] = [
  { label: "User", id: "User", name: Constants.USER, required: true },
  {
    label: "Password",
    id: "Password",
    name: Constants.PASSWORD,
    required: true,
  },
];

export const oAuth1Props: AuthField[] = [
  {
    label: "OAuth Client Id",
    id: "OAuthClientId",
    name: Constants.OAUTH_CLIENT_ID,
    required: true,
  },
  {
    label: "OAuth Client Secret",
    id: "OAuthClientSecret",
    name: Constants.OAUTH_CLIENT_SECRET,
    required: true,
  },
  {
    label: "OAuth Request Token URL",
    id: "OAuthRequestTokenUrl",
    name: Constants.OAUTH_REQUEST_TOKEN_URL,
    required: true,
  },
  {
    label: "OAuth Authorization URL",
    id: "OAuthAuthorizationUrl",
    name: Constants.OAUTH_AUTHORIZATION_URL,
    required: true,
  },
  {
    label: "OAuth Access Token URL",
    id: "OAuthAccessTokenUrl",
    name: Constants.OAUTH_ACCESS_TOKEN_URL,
    required: true,
  },
];

export const oAuth2Props: AuthField[] = [
  {
    label: "OAuth Client Id",
    id: "OAuthClientId",
    name: Constants.OAUTH_CLIENT_ID,
    required: true,
  },
  {
    label: "OAuth Client Secret",
    id: "OAuthClientSecret",
    name: Constants.OAUTH_CLIENT_SECRET,
    required: true,
  },
  {
    label: "OAuth Authorization URL",
    id: "OAuthAuthorizationUrl",
    name: Constants.OAUTH_AUTHORIZATION_URL,
    required: true,
  },
  {
    label: "OAuth Access Token URL",
    id: "OAuthAccessTokenUrl",
    name: Constants.OAUTH_ACCESS_TOKEN_URL,
    required: true,
  },
  {
    label: "OAuth Refresh Token URL",
    id: "OAuthRefreshTokenUrl",
    name: Constants.OAUTH_REFRESH_TOKEN_URL,
  },
  {
    label: "OAuth Client Authentication Mode",
    id: Constants.OAUTH_PASSWORD_GRANT_MODE,
    name: Constants.OAUTH_PASSWORD_GRANT_MODE,
    default: OAuthPasswordGrantMode.Basic.toString(),
    required: false,
  },
  {
    label: "Header Prefix",
    id: "AuthorizationHeaderPrefix",
    name: Constants.AUTHORIZATION_HEADER_PREFIX,
    required: true,
    default: "Bearer",
  },
];

export const authFieldsToResetOnTypeChange = [
  Constants.OAUTH_ACCESS_TOKEN_URL,
  Constants.OAUTH_AUTHORIZATION_URL,
  Constants.OAUTH_CLIENT_ID,
  Constants.OAUTH_CLIENT_SECRET,
  Constants.OAUTH_REFRESH_TOKEN_URL,
  Constants.OAUTH_REQUEST_TOKEN_URL,
  Constants.PASSWORD,
  Constants.USER,
  Constants.AUTHORIZATION_HEADER_PREFIX,
];
