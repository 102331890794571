//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum SignupReason {
  DataConnectivity = 1,
  DataBlending = 2,
  OrganizeData = 3,
  DataGovernance = 4,
  ETL = 5,
  Other = 6
}
