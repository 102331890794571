import { Outlet } from "react-router-dom";

import { Col, Container, Row } from "reactstrap";

import Main from "../components/Main";
import SimpleHeader from "../components/SimpleHeader";
import { Footer } from "../components/Footer";

const Auth = ({ children }: any) => {
  function getComponentName() {
    return "layouts-Auth";
  }

  return (
    <Main
      className={`d-flex w-100 justify-content-center ${getComponentName()}`}
    >
      <SimpleHeader />
      <Container className="d-flex flex-column flex-grow-1">
        <Row className="flex-grow-1 align-content-center">
          <Col sm="10" md="8" lg="6" className="mx-auto d-table h-100">
            <Outlet />
            <div className="d-table-cell align-middle">{children}</div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </Main>
  );
};

export default Auth;
