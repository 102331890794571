import { Form } from "react-bootstrap";
import { Label, PopoverBody, UncontrolledPopover } from "reactstrap";

interface ICustomReportNameField {
  handleInputUpdate: () => void;
  name: string;
}

export const CustomReportNameField = (props: ICustomReportNameField) => {
  return (
    <div>
      <Label for="name" className="required">
        Report Name
      </Label>
      <div className="mb-3">
        <Form.Control
          data-testid="form-control-name"
          id="name"
          name="name"
          type="text"
          defaultValue={props.name}
          onChange={() => props.handleInputUpdate()}
        />
        <UncontrolledPopover placement="top" target="name" trigger="focus">
          <PopoverBody>Name of the Custom Report</PopoverBody>
        </UncontrolledPopover>
      </div>
    </div>
  );
};
