import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import Connections from "./Connections";
import { ModalContext } from "../../routes/ModalContext";
import EditConnection from "./EditConnection";
import AdminGuard from "../../components/guards/AdminGuard";
import AddReport from "./AddReports";
import SelectConnection from "./SelectConnection";
import Page404 from "../auth/Page404";

const ConnectionRoutes = () => {
  const modalFunctions = useContext(ModalContext);

  return (
    <Routes>
      {/* /connections route */}
      <Route path="/" element={<Connections />} />

      <Route path="edit" element={<EditConnection />} />

      <Route
        path="reports/edit"
        element={
          <AdminGuard>
            <AddReport />
          </AdminGuard>
        }
      />

      <Route
        path="SelectConnection"
        element={
          <AdminGuard>
            <SelectConnection
              showErrorModal={modalFunctions.showError}
              showTimeoutModal={modalFunctions.showTimeout}
              showUnsupportedBrowser={modalFunctions.showUnsupportedBrowser}
              setModal={modalFunctions.setModal}
              toggleModal={modalFunctions.toggleModal}
            />
          </AdminGuard>
        }
      />

      <Route path="*" element={<Page404 showBranding={false} />} />
    </Routes>
  );
};

export default ConnectionRoutes;
