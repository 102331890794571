import { Col, FormGroup, Input, Row } from "reactstrap";
import { ICacheJob } from "../../../../models/Cache/ICacheJob";
import { useFormikContext } from "formik";

interface IRunFrequencyRowProps {
  setUnsavedChanges: (unsavedChanges: boolean) => void;
}

export const AutoTruncateStringsToggleButton = ({
  setUnsavedChanges,
}: IRunFrequencyRowProps) => {
  const { values, setValues } = useFormikContext<ICacheJob>();

  return (
    <Row
      className="mb-3 title-row"
      data-testid="auto-truncate-strings-toggle-button"
    >
      <Col className="pe-0">
        <label className="fw-bold">Auto-Truncate Strings</label>
        <FormGroup switch className="form-check form-switch custom-form">
          <Input
            name="input-toggle-caching-auto-truncate-strings"
            id="input-toggle-caching-auto-truncate-strings"
            data-testid="input-toggle-caching-auto-truncate-strings"
            type="switch"
            role="switch"
            value={values.isAutoTruncateStrings as any}
            checked={values.isAutoTruncateStrings}
            className="cursor-pointer"
            onChange={() => {
              setUnsavedChanges(true);
              setValues({
                ...values,
                isAutoTruncateStrings: !values.isAutoTruncateStrings,
              });
            }}
          ></Input>
        </FormGroup>
      </Col>
      <div className="helper-text" data-testid="helper-auto-truncate-strings">
        This setting will automatically truncate strings based on the column
        size of the cache database. Disabling this setting may result in job
        errors.
      </div>
    </Row>
  );
};
