import { useState } from "react";
import {
  PopoverBody,
  Label,
  UncontrolledPopover,
  FormGroup,
  Input,
} from "reactstrap";

import { Form } from "react-bootstrap";
import { CustomReportNameField } from "./CustomReportNameField";
import { ICustomReportParameter } from "../../../models";
import { filterUnusedParameters } from "./CustomReportFunctions";
import { compareStrings } from "../../../utility/CompareStrings";

export interface ICustomReportFormProps {
  customReportName: string;
  inputUpdated: () => void;
  customReportProperties: ICustomReportParameter[];
  useOnboardingFlowInterface?: boolean;
  errors: any;
}

export const CustomReportForm = (props: ICustomReportFormProps) => {
  const filteredParameters = filterUnusedParameters(
    props.customReportProperties,
  );

  function displayPropertyControl(property: any) {
    if (compareStrings(property.type, "string")) {
      if (property.values !== undefined) {
        return (
          <div className="mb-3">
            <Form.Control
              data-testid={`form-control-select-${property.name}`}
              isInvalid={props.errors[property.name]}
              id={property.name}
              className="form-select"
              name={property.name}
              as="select"
              defaultValue={property.default}
              onChange={() => props.inputUpdated()}
            >
              {property.values.map((value: any, index: any) => (
                <option key={index} value={value}>
                  {value}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {props.errors[property.name]}
            </Form.Control.Feedback>
            <UncontrolledPopover
              placement="top"
              target={property.name}
              trigger="focus"
            >
              <PopoverBody>{property.description}</PopoverBody>
            </UncontrolledPopover>
          </div>
        );
      } else {
        return (
          <div className="mb-3">
            <Form.Control
              data-testid={`form-control-text-${property.name}`}
              id={property.name}
              name={property.name}
              type="text"
              defaultValue={property.default}
              onChange={() => props.inputUpdated()}
            />
            <UncontrolledPopover
              placement="top"
              target={property.name}
              trigger="focus"
            >
              <PopoverBody>{property.description}</PopoverBody>
            </UncontrolledPopover>
          </div>
        );
      }
    } else if (compareStrings(property.type, "int")) {
      return (
        <div className="mb-3">
          <Form.Control
            data-testid={`form-control-number-${property.name}`}
            id={property.name}
            name={property.name}
            type="number"
            defaultValue={property.default}
            onChange={() => props.inputUpdated()}
          />
          <UncontrolledPopover
            placement="top"
            target={property.name}
            trigger="focus"
          >
            <PopoverBody>{property.description}</PopoverBody>
          </UncontrolledPopover>
        </div>
      );
    } else if (compareStrings(property.type, "datetime")) {
      return (
        <div className="mb-3">
          <Form.Control
            data-testid={`form-control-date-${property.name}`}
            id={property.name}
            name={property.name}
            type="datetime"
            placeholder="YYYY-MM-DD"
            defaultValue={property.default}
            onChange={() => props.inputUpdated()}
          />
          <UncontrolledPopover
            placement="top"
            target={property.name}
            trigger="focus"
          >
            <PopoverBody>{property.description}</PopoverBody>
          </UncontrolledPopover>
        </div>
      );
    } else if (compareStrings(property.type, "bool")) {
      // TODO: CLOUD-12783: Replace this with CDataToggleButton so we don't have to worry about state here, or convert displayPropertyControl to a component
      const [intialVal, setInitialVal] = useState(false); // eslint-disable-line
      return (
        <div className="mb-3 toggle-button">
          <FormGroup switch className="form-check form-switch">
            <Input
              data-testid={`form-control-bool-${property.name}`}
              type="switch"
              role="switch"
              name={property.name}
              id={property.name}
              value={intialVal as any}
              checked={intialVal}
              onChange={() => {
                setInitialVal(!intialVal);
              }}
            />
          </FormGroup>
          <UncontrolledPopover
            placement="top"
            target={property.name}
            trigger="focus"
          >
            <PopoverBody>{property.description}</PopoverBody>
          </UncontrolledPopover>
        </div>
      );
    }
  }

  const reportFields = Object.values(filteredParameters).map(
    (property, index) => {
      return (
        <div
          key={index}
          className={
            compareStrings(property.type, "bool") ? "boolean-property" : ""
          }
        >
          <Label
            for={property.name}
            className={property.required === true ? "required" : ""}
          >
            {property.name}
          </Label>
          {displayPropertyControl(property)}
        </div>
      );
    },
  );

  return (
    <Form.Group>
      <h5
        hidden={props.useOnboardingFlowInterface}
        className="card-title mb-3 form-custom"
      >
        Parameters
      </h5>
      {props.useOnboardingFlowInterface ? (
        <CustomReportNameField
          handleInputUpdate={props.inputUpdated}
          name={props.customReportName}
        />
      ) : null}
      {filteredParameters.length > 0 ? reportFields : null}
    </Form.Group>
  );
};
