import React, { useState } from "react";
import {
  ButtonType,
  CDataButton,
} from "../../../../../../../components/buttons/CDataButton";
import { CDataModalV2 } from "../../../../../../../components/modal/CDataModalV2";
import { IQueryBuilderCommonProps } from "../../../models/IQueryBuilderCommonProps";
import {
  FilterType,
  IQueryBuilderFilter,
} from "../../../models/IQueryBuilderFilter";
import { AddFiltersModalContent } from "./AddFiltersModalContent";
import { DataType } from "../../../../../../../models";

interface AddFiltersModalProps extends IQueryBuilderCommonProps {
  displayed: boolean;
  close: () => void;
  filterToEditIndex?: number;
}

export function AddFiltersModal(props: AddFiltersModalProps) {
  const { queryData, setQueryData, displayed, close, filterToEditIndex } =
    props;

  const filterToEdit =
    filterToEditIndex !== undefined
      ? queryData.filters[filterToEditIndex]
      : undefined;

  const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState(true);
  const [queryFilter, setQueryFilter] = useState<IQueryBuilderFilter>(() =>
    filterToEdit !== undefined ? filterToEdit : getDefaultValues(),
  );

  function getDefaultValues(): IQueryBuilderFilter {
    const defaultValues: IQueryBuilderFilter = {
      column: {
        table: {
          connectionId: "",
          connectionName: "",
          driver: "",
          schema: "",
          tableName: "",
          tableAlias: "",
        },
        column: "",
        dataType: DataType.VARCHAR,
      },
      filterType: FilterType.EqualTo,
      data: {},
    };

    // If we only have a single table, default all the above fields to that table..
    if (queryData.joins.length === 0) {
      defaultValues.column.table = queryData.from;
    }

    return defaultValues;
  }

  function onSubmit() {
    const updatedFilters = [...queryData.filters];
    if (props.filterToEditIndex !== undefined) {
      updatedFilters[filterToEditIndex!] = { ...queryFilter };
    } else {
      updatedFilters.push({ ...queryFilter });
    }

    setQueryData({
      ...queryData,
      filters: updatedFilters,
    });
    close();
  }

  const modalTitle = props.filterToEditIndex ? "Edit Filter" : "Add Filter";

  return (
    <>
      <CDataModalV2
        displayed={displayed}
        close={close}
        title={modalTitle}
        className="add-filters-modal"
        primaryButton={
          <CDataButton
            buttonType={ButtonType.Primary}
            disabled={isConfirmButtonDisabled}
            onClick={() => onSubmit()}
          >
            Confirm
          </CDataButton>
        }
      >
        <AddFiltersModalContent
          {...props}
          queryFilter={queryFilter}
          setQueryFilter={setQueryFilter}
          filterToEdit={filterToEdit}
          setIsConfirmButtonDisabled={setIsConfirmButtonDisabled}
          connectionsList={props.connectionsList}
        />
      </CDataModalV2>
    </>
  );
}
