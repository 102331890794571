import { CDataModalV2 } from "../../../../components/modal/CDataModalV2";
import {
  ButtonType,
  CDataButton,
} from "../../../../components/buttons/CDataButton";
import useQueryTabs from "../Tabs/useQueryTabs";

export type ClearQueryModalProps = {
  displayed: boolean;
  close: () => void;
  clearQuery: () => void;
};

export function ClearQueryModal(props: ClearQueryModalProps) {
  const tabs = useQueryTabs();

  const { displayed, close, clearQuery } = props;

  const currentTab = tabs.List.find((t) => t.id === tabs.CurrentTabId);

  if (!currentTab) {
    return null;
  }

  return (
    <CDataModalV2
      modalSize="lg"
      displayed={displayed}
      close={close}
      title="Confirm"
      spacedFooter={false}
      backdrop={true}
      primaryButton={
        <CDataButton
          buttonType={ButtonType.Primary}
          onClick={clearQuery}
          data-testid="confirm-clear"
        >
          Ok
        </CDataButton>
      }
      secondaryButton={
        <CDataButton buttonType={ButtonType.Secondary} onClick={close}>
          Cancel
        </CDataButton>
      }
    >
      Are you sure you want to clear the contents of the editor?
    </CDataModalV2>
  );
}
