import React, { ChangeEvent, Dispatch, SetStateAction } from "react";
import { Card, CardBody, FormGroup, FormText, Label, Input } from "reactstrap";
import { IGlobalSettings } from "../../../ApiConnector";
import { createDeepCopy } from "../../../../../utility/CreateDeepCopy";
import { IAPIAdvanced } from "../../../../../models";

interface ILoggingCardProps {
  globalSettings: IGlobalSettings;
  setGlobalSettings: Dispatch<SetStateAction<IGlobalSettings>>;
  setUnsavedChanges: (unsavedChanges: boolean) => void;
  isFlyout?: boolean;
}

const AdvancedCard = (props: ILoggingCardProps) => {
  function handleLoggingCardChange(event: ChangeEvent<HTMLInputElement>) {
    props.setGlobalSettings((prevGlobalSettings: IGlobalSettings) => {
      const newGlobalSettings = createDeepCopy(prevGlobalSettings);
      const advancedObj: IAPIAdvanced = newGlobalSettings.advanced
        ? newGlobalSettings.advanced
        : {};
      advancedObj[event.target.name as keyof IAPIAdvanced] = event.target.value;
      newGlobalSettings.advanced = advancedObj;
      return newGlobalSettings;
    });
    props.setUnsavedChanges(true);
  }

  const loggingCardContent = (
    <div>
      <h5 className="card-title">Advanced</h5>
      <FormGroup className="mt-3">
        <Label className="form-field-title">Verbosity</Label>
        <Input
          data-testid="select-verbosity"
          id="Verbosity"
          type="select"
          name="verbosity"
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            handleLoggingCardChange(event);
          }}
          value={props.globalSettings.advanced?.verbosity}
        >
          <option key="1" value="1">
            1
          </option>
          <option key="2" value="2">
            2
          </option>
          <option key="3" value="3">
            3
          </option>
          <option key="4" value="4">
            4
          </option>
          <option key="5" value="5">
            5
          </option>
        </Input>
        <FormText data-testid="helper-text">
          Setting the verbosity level to 3 or higher may result in sensitive
          customer information, such as Personal Identifiable Information (PII),
          being stored in log files for up to 10 days. This is especially
          important to avoid when logs contain highly sensitive data, such as
          personal Health Records (HIPAA) information.
        </FormText>
      </FormGroup>
    </div>
  );

  function renderLoggingCard() {
    return (
      <>
        {props.isFlyout ? (
          <div>{loggingCardContent}</div>
        ) : (
          <Card>
            <CardBody>{loggingCardContent}</CardBody>
          </Card>
        )}
      </>
    );
  }

  return <>{renderLoggingCard()}</>;
};

export default AdvancedCard;
