import React from "react";
import { InfoIcon } from "../../../../../../components/InfoIcon";

export const RepeatElementsTooltip = () => {
  return (
    <div className="tooltip-label">
      <label className="form-field-title required">Repeat Element</label>
      <InfoIcon
        tooltipMessage="Select the element(s) whose child-items should be interpreted as rows in your API model."
        className="info-icon-col"
        iconId="info-icon-col"
      />
    </div>
  );
};
