import { Col } from "reactstrap";
import { CDataTypography } from "../../../../../components/text/CDataTypography";
import { IQueryFilterProps } from "../types/IQueryFilterProps";
import { useEffect, useState } from "react";
import { DataType } from "../../../../../models";
import { CDataDatePicker } from "../../../../../components/datepicker/CDataDatePicker";
import { format, parseISO } from "date-fns";

export function FilterSingleDate(props: IQueryFilterProps) {
  const {
    queryFilter,
    setQueryFilter,
    setIsConfirmButtonDisabled,
    isDisabled,
  } = props;

  const [isTouched, setIsTouched] = useState(false);

  const value = (queryFilter.data.value as string) ?? "";

  const showTimePicker = queryFilter.column.dataType === DataType.TIMESTAMP;

  let errorMessage = "";
  if (!value || value === "") {
    errorMessage = "Filter value must not be empty.";
  }

  useEffect(() => {
    if (errorMessage) {
      setIsConfirmButtonDisabled(true);
    } else {
      setIsConfirmButtonDisabled(false);
    }
  }, [errorMessage]); // eslint-disable-line

  function onChangeDate(newDate: Date | undefined) {
    if (newDate == null) {
      setQueryFilter({
        ...queryFilter,
        data: {
          value: undefined,
        },
      });

      return;
    }

    let dateString: string;
    if (showTimePicker) {
      dateString =
        format(newDate, "yyyy-MM-dd") + "T" + format(newDate, "HH:mm:ss");
    } else {
      dateString = format(newDate, "yyyy-MM-dd");
    }

    setQueryFilter({
      ...queryFilter,
      data: {
        value: dateString,
      },
    });
  }

  return (
    <Col className="ps-0">
      <CDataDatePicker
        date={value != null && value.length > 0 ? parseISO(value) : undefined}
        showTimePicker={showTimePicker}
        onChange={onChangeDate}
        selectProps={{
          sx: {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          },
          onBlur: () => {
            // The timeout here is terrible, but the blur fires when clicking the date picker
            // and the validation messages incorrectly show up too early without it.
            setTimeout(() => setIsTouched(true), 100);
          },
          disabled: isDisabled,
          isInvalid: isTouched && !!errorMessage,
        }}
      />
      {isTouched && errorMessage && (
        <CDataTypography
          variant="typography-variant-helper-text"
          color="typography-color-danger"
        >
          {errorMessage}
        </CDataTypography>
      )}
    </Col>
  );
}
