import parseXMLAsTreeTable, {
  XMLTreeTableRow,
} from "./XML/parseXMLAsTreeTable";
import parseJSONAsTreeTable, {
  JSONTreeTableRow,
} from "./JSON/parseJSONAsTreeTable";
import { ITableSettings } from "../../SetTable";
import { APITableResponseType } from "../../../../../models";
import { IAPIResponse } from "../../../../../components/withAPI";

type TreeTableRow = XMLTreeTableRow[] | JSONTreeTableRow[] | Error;

export function parseResponseAsTreeTable(
  tableSettings: ITableSettings,
  axiosResult: IAPIResponse,
): TreeTableRow {
  let parsedResponse: TreeTableRow;

  if (tableSettings.responseType === APITableResponseType.XML) {
    parsedResponse = parseXMLAsTreeTable(axiosResult.payload);
  } else {
    parsedResponse = parseJSONAsTreeTable(axiosResult.payload);
  }

  return parsedResponse;
}
