import React, { useState, useEffect } from "react";
import {
  clearImpersonation,
  getImpersonatingUserId,
  setImpersonatingUserId,
} from "../services/userImpersonation";
import {
  getOEMSubAccounts,
  getSubAccountUserId,
} from "../pages/oem/oemlandingpage/OEMLandingPageAPICalls";
import { useAPI } from "./useAPI";
import { IOEMSubAccountList } from "../models/OEM/IOEMSubAccountList";
import { Button, List, ListItem, ListItemText, Popover } from "@mui/material";
import { Input } from "reactstrap";
import { Search } from "react-feather";
interface IfilteredOption {
  label: string | undefined;
  value: string | undefined;
}

const OEMServiceUserHeader = () => {
  async function clearCurrentImpersonation() {
    clearImpersonation();
    // We need a hard redirect here so all our queries re-run and we reload the user information.
    window.location.href = "/";
  }
  const [subAccountList, setSubAccountList] =
    useState<IOEMSubAccountList | null>(null);
  const api = useAPI();
  const [filteredOptions, setFilteredOptions] = useState<IfilteredOption[]>([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const currOemSubAccount = getCurrentOemSubAccount();

  useEffect(() => {
    const getOEMInformationOnLoad = async () => {
      const accounts = await getOEMSubAccounts(api.callAPI, true);
      if (accounts) {
        setSubAccountList(accounts);
      }
    };
    getOEMInformationOnLoad();
  }, []); // eslint-disable-line

  function getCurrentOemSubAccount() {
    const currentOemSubUserId = getImpersonatingUserId();
    const currSubAccount = subAccountList?.accounts.find(
      (sa) => sa.serviceUserId === currentOemSubUserId,
    );
    return currSubAccount;
  }
  function getOemSubAccountListOptions() {
    const subAccountOptions = subAccountList?.accounts.map((subAccount) => {
      return { label: subAccount.externalId, value: subAccount.id };
    });
    return subAccountOptions ?? [];
  }

  const options = getOemSubAccountListOptions();
  useEffect(() => {
    setFilteredOptions(options);
  }, [subAccountList]); // eslint-disable-line

  async function handleOptionSelect(subAccountData: IfilteredOption) {
    const subAccountId = subAccountData.value;
    if (subAccountId) {
      const subAccountUserId = await getSubAccountUserId(
        api.callAPI,
        subAccountId,
        true,
      );
      setImpersonatingUserId(subAccountUserId);
    }
    window.location.href = "/connections";
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchTerm(searchValue);
    if (searchValue !== "") {
      setFilteredOptions(
        options?.filter((option) =>
          option.label?.toLowerCase().includes(searchValue),
        ),
      );
    } else {
      setFilteredOptions(options);
    }
  };

  const handleClick = (event: React.BaseSyntheticEvent<MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setSearchTerm("");
    setFilteredOptions(options);
    setAnchorEl(null);
  };
  const handleOptionClick = (option: IfilteredOption) => {
    handleOptionSelect(option);
    handleClose();
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Button
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
        className="sub-account-dropdown"
        sx={{ textTransform: "none" }}
        data-testid="subaccountlist-dropdown"
      >
        <span className="sub-account-display-name">
          {currOemSubAccount?.externalId}
        </span>
        <i className="fa fa-chevron-down" />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{ className: "sub-account-dropdown-paper" }}
      >
        <>
          <div
            className="manage-account-button"
            onClick={() => clearCurrentImpersonation()}
          >
            <span className="manage-account-label">Manage Accounts</span>
            <i className="fa fa-solid fa-lg fa-arrow-up-right-from-square" />
          </div>
          <span className="col search-bar-outer flex-grow-1">
            <Search className="align-middle me-2" size={16} />
            <Input
              className="sub-account-filter"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearchChange}
              fullWidth
            />
          </span>
          <List>
            {filteredOptions.map((option, index) => (
              <ListItem
                button
                key={index}
                onClick={() => handleOptionClick(option)}
              >
                <ListItemText primary={option.label} />
              </ListItem>
            ))}
          </List>
        </>
      </Popover>
    </>
  );
};

export default OEMServiceUserHeader;
