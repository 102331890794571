import { Col, FormGroup, Input, Row, UncontrolledTooltip } from "reactstrap";
import { ICacheJob } from "../../../../models/Cache/ICacheJob";
import { IScheduledQuery } from "../../../../models/ScheduledQueries/IScheduledQuery";
import { CDataTypography } from "../../../../components/text/CDataTypography";
import { JobType } from "../../../../models/Cache/JobType";
import { useFormikContext } from "formik";

interface IRunFrequencyRowProps {
  jobType: JobType;
  setUnsavedChanges: (unsavedChanges: boolean) => void;
}

export const TitleAndToggleButtonRow = <T extends ICacheJob | IScheduledQuery>({
  jobType,
  setUnsavedChanges,
}: IRunFrequencyRowProps) => {
  const { values, setValues } = useFormikContext<T>();
  const isCacheJob = jobType === JobType.Caching;

  const isEnabled = values.enabled;

  function toggleEnabled() {
    setValues({ ...values, enabled: !values.enabled });
    setUnsavedChanges(true);
  }

  return (
    <Row className="mb-3 title-row" data-testid="row-title-toggle-button">
      <Col className="pe-0">
        <CDataTypography variant="typography-variant-card-title">
          {isCacheJob ? "Caching" : "Schedule Query"}
        </CDataTypography>
      </Col>
      <Col className="ps-0">
        <FormGroup switch className="form-check form-switch custom-form">
          <Input
            name="enabled"
            id="input-toggle-caching-enabled"
            data-testid="input-toggle-caching-enabled"
            type="switch"
            role="switch"
            value={isEnabled as any}
            checked={isEnabled}
            className="cursor-pointer"
            onChange={toggleEnabled}
          />
          {isEnabled && isCacheJob ? (
            <UncontrolledTooltip
              placement="top"
              target="input-toggle-caching-enabled"
              trigger="hover"
            >
              Disabling this job will pause all scheduling parameters that have
              been set and force queries to go to the live data source.
            </UncontrolledTooltip>
          ) : null}
        </FormGroup>
      </Col>
    </Row>
  );
};
