import { useContext } from "react";
import DataLabelAndDropdown from "./DataLabelAndDropdown";
import { useFormikContext } from "formik";
import { AddDataModalContext } from "../AddDataModalContext";
import { IQueryBuilderJoin } from "../../../../../models/IQueryBuilderModel";
import { useMutation } from "@tanstack/react-query";
import { getColumnsForTable } from "../../../../../../../../../api/metadata/getColumnsForTable";
import { IConnection } from "../../../../../../../../../models";
import { mapColumnMetadataToQueryBuilderColumn } from "../AddDataModal.functions";
import { produce } from "immer";
import { IQueryBuilderTable } from "../../../../../models/IQueryBuilderTable";
import { cloneDeep } from "lodash";

interface INewTableTableField {
  connectionList: IConnection[];
  tableList: IQueryBuilderTable[];
}

const NewTableTableField = (props: INewTableTableField) => {
  const { connectionList, tableList } = props;
  const { setValues, values } = useFormikContext<IQueryBuilderJoin>();
  const { setProcessing, setNewTableColumns } = useContext(AddDataModalContext);

  const currentSchema = values.right.table.schema;

  function updateSelectedTable(table: IQueryBuilderTable) {
    updateColumnList(table);
    const newJoin = produce(values, (draft) => {
      draft.right.table = cloneDeep(table);
      // Clear out the existing columns.
      draft.right.column = "";
      draft.left.column = "";
    });
    setValues(newJoin);
  }

  const { mutateAsync: getMetadataForColumnsAsync } = useMutation({
    mutationKey: ["/columns"],
    mutationFn: getColumnsForTable,
    onSuccess: (data) => {
      setNewTableColumns(
        mapColumnMetadataToQueryBuilderColumn(connectionList, data),
      );
    },
    onMutate: () => {
      setProcessing(true);
    },
    onSettled: () => {
      setProcessing(false);
    },
    meta: {
      errorMessage:
        "Failed to load columns for table due to the following error:",
    },
  });

  async function updateColumnList(newTable: IQueryBuilderTable) {
    await getMetadataForColumnsAsync({
      connectionName: newTable.connectionName,
      schema: newTable.schema,
      tableName: newTable.tableName,
    });
  }

  const hasValue = values.right?.table?.tableName?.length > 0 ?? false;

  return (
    <DataLabelAndDropdown<IQueryBuilderTable>
      handleChange={updateSelectedTable}
      isDisabled={!currentSchema}
      isOptionEqualToValue={(option, value) =>
        option.tableName === value.tableName
      }
      label={"Table Name"}
      dropdownLabel={(option) => option.tableName}
      optionLabel={(option) => option.tableName}
      options={tableList || []}
      value={hasValue ? values.right.table : null}
    />
  );
};

export default NewTableTableField;
