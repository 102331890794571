import { DataType } from "../../../../../models";

export const isDataTypeNumeric = (dataType?: DataType) => {
  if (dataType === undefined) {
    return false;
  }

  return (
    dataType === DataType.BIGINT ||
    dataType === DataType.DECIMAL ||
    dataType === DataType.DOUBLE ||
    dataType === DataType.FLOAT ||
    dataType === DataType.INTEGER ||
    dataType === DataType.NUMERIC ||
    dataType === DataType.SMALLINT ||
    dataType === DataType.TINYINT
  );
};
