import classNames from "classnames";

export function CDataCol(props: React.HTMLAttributes<HTMLDivElement>) {
  const { className, ...remainingProps } = props;

  return (
    <div {...remainingProps} className={classNames("cdata-col", className)}>
      {props.children}
    </div>
  );
}
