import { ButtonType, CDataButton } from "../../components/buttons/CDataButton";
import useQueryTabs from "./RightPanel/Tabs/useQueryTabs";

const AddQueryBuilderButton = () => {
  const queryTabs = useQueryTabs();

  return (
    <CDataButton
      data-testid="query-builder-button"
      buttonType={ButtonType.Primary}
      className="navigate-button"
      onClick={() => queryTabs.AddQueryBuilderTab("", "", "", "", "")}
    >
      Build Query
    </CDataButton>
  );
};

export default AddQueryBuilderButton;
