import React, { useState, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import {
  Badge,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Spinner,
  UncontrolledTooltip,
} from "reactstrap";
import { produce } from "immer";

import { IModalProps } from "../../components/CDataModal";
import Loader from "../../components/Loader";
import { ToastrSuccess } from "../../services/toastrService";
import { ModalContext } from "../../routes/ModalContext";
import { IUser, IUserList, UserRole } from "../../models";
import TertiaryButton from "../../components/buttons/TertiaryButton";
import {
  getSettingsPageSubscriptionTab,
  ManageSubscriptionText,
  isManageSubscriptionVisible,
} from "../../utility/SubscriptionProvider";
import { ColumnDef, RowSelectionState } from "@tanstack/react-table";
import ListTable from "../../components/tables/ListTable";
import { addUsage } from "../../redux/actions";
import { DeleteUsersModal } from "./components/DeleteUsersModal";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getUserList } from "./api/getUserList";
import { orderBy } from "lodash";
import Page500 from "../auth/Page500";
import { postResendUserInvite } from "./api/postResendUserInvite";
import { getBillingUsage } from "./api/getBillingUsage";
import { useIsConnectForSpreadsheets } from "../../hooks/useIsConnectForSpreadsheets";
import { getIsSupportImpersonationActive } from "../../services/userImpersonation";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

interface IStatusProps {
  isInvite: boolean;
  enabled: boolean;
}

export const Status = (props: IStatusProps) => {
  let name, color;
  if (props.isInvite) {
    name = "Invited";
    color = "secondary";
  } else if (props.enabled) {
    name = "Active";
    color = "success";
  } else {
    name = "Inactive";
    color = "danger";
  }

  return <Badge color={color}>{name}</Badge>;
};

interface IUserTableRow {
  id: string;
  email: string;
  role: string;
  status: JSX.Element;
  editButtons: JSX.Element;
  user: IUser;
}

const Users = () => {
  const modalFunctions = useContext(ModalContext);
  const navigate = useNavigate();
  const [selectedUsers, setSelectedUsers] = useState<RowSelectionState>({});
  const [usersToDelete, setUsersToDelete] = useState<IUser[]>([]);
  const [isDeleteUsersModalOpen, setIsDeleteUsersModalOpen] =
    useState<boolean>(false);
  const [processingRequest, setProcessingRequest] = useState<boolean>(false);
  const isSupportImpersonationActive = getIsSupportImpersonationActive();

  const usage = useAppSelector((state) => state.usage);
  const currentUser = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const queryClient = useQueryClient();
  const isConnectForSpreadsheetClient = useIsConnectForSpreadsheets();

  const usersQueryKey = ["/users"];

  const {
    data,
    isLoading,
    error,
    refetch: refetchUsersList,
  } = useQuery({
    queryKey: usersQueryKey,
    queryFn: getUserList,
    meta: {
      errorMessage: "An error ocurred loading the list of users",
    },
  });

  const userSeatCount = data?.userSeatCount ?? 0;

  const userList =
    data?.users != null ? orderBy(data.users, (u) => u.email) : [];

  const inviteButtonDisabled =
    userSeatCount > -1 && userList.length >= userSeatCount;

  const { mutateAsync: resendUserInvite } = useMutation({
    mutationKey: [`/users/invite`],
    mutationFn: postResendUserInvite,
    onSuccess: (invitedUser) => {
      const updatedUsers = produce(userList, (draft) => {
        const matchingUser = draft.find((u) => u.id === invitedUser.userId);
        if (matchingUser != null) {
          matchingUser.inviteExpires = invitedUser.expires;
        }
      });
      modalFunctions.toggleModal();
      // Update the list of users without having to re-run our query.
      queryClient.setQueryData<IUserList>(usersQueryKey, {
        ...data!,
        users: updatedUsers,
      });
      ToastrSuccess(
        "Invite Successfully Resent!",
        "You have successfully invited a user to CData Connect Cloud.",
      );
    },
  });

  const { mutate: reloadBillingUsage } = useMutation({
    mutationKey: ["/account/billing/usage"],
    mutationFn: getBillingUsage,
    onSuccess: (payload) => {
      dispatch(addUsage(payload));
    },
  });

  const openResendInviteModal = (event: any, userId: string) => {
    event.stopPropagation();
    const modal = {
      title: "Resend Invite",
      body: (
        <text>
          Are you sure you want to send a new invitation email to this user?
        </text>
      ),
      primaryButton: (
        <Button
          color="primary"
          onClick={() => {
            resendUserInvite(userId);
          }}
        >
          Confirm
        </Button>
      ),
      secondaryButton: (
        <Button color="secondary" onClick={modalFunctions.toggleModal}>
          Close
        </Button>
      ),
      displayToggleCloseButton: true,
      displayed: true,
    } as IModalProps;
    modalFunctions.setModal(modal);
  };

  const handleRowClick = (user: IUserTableRow) => {
    openEditUserPage(user.user);
  };

  const openEditUserPage = (user: IUser) => {
    if (usage.usersOverLimit) {
      return;
    }

    navigate("/user/edit", {
      state: { user: user },
    });
  };

  const openInviteUsersPage = () => {
    navigate("/user/invite");
  };

  // If a user is supplied to this function, it is a single user delete. Else, it is a batch user delete that uses the selectedUsers variable.
  const openDeleteUsersModal = (event: React.MouseEvent, user?: IUser) => {
    event.stopPropagation();

    if (user) {
      setUsersToDelete([user]);
    } else {
      const matchedSelectedUsers = convertRowSelectionToUsers();
      setUsersToDelete(matchedSelectedUsers);
    }

    setIsDeleteUsersModalOpen(true);
  };

  const convertRowSelectionToUsers = (): IUser[] => {
    const selectedUserIds = Object.keys(selectedUsers);
    const matchedUsers = userList.filter((user) =>
      selectedUserIds.includes(user.id),
    );
    return matchedUsers;
  };

  const handlePostDeleteTasks = (deleteSucceeded: boolean) => {
    if (deleteSucceeded) {
      setSelectedUsers({});
    }

    reloadBillingUsage();
    refetchUsersList();
  };

  let userListBody: IUserTableRow[] = [];
  if (userList.length !== 0) {
    userListBody = userList.map((user: IUser) => {
      return {
        id: user.id,
        email: user.email!,
        role: user.role === UserRole.Admin ? "Administrator" : "Query",
        enabled: user.enabled,
        status: <Status isInvite={user.isInvite} enabled={user.enabled} />,
        editButtons: (
          <>
            <button
              hidden={!user.isInvite}
              onClick={(event) => openResendInviteModal(event, user.id)}
              className="table-button"
              disabled={usage.usersOverLimit}
              aria-label="resend invite"
              data-testid="button-resend-invite"
            >
              <i className="fa-regular fa-paper-plane align-middle resend-invite-button"></i>
            </button>
            <button
              onClick={() => openEditUserPage(user)}
              className={"table-button"}
              disabled={usage.usersOverLimit}
              aria-label="edit users"
              data-testid="button-edit-user"
            >
              <i className="fa-regular fa-pen align-middle me-2"></i>
            </button>
            <button
              onClick={(event) => openDeleteUsersModal(event, user)}
              className="table-button"
              aria-label="delete user"
              data-testid="button-delete-user"
              hidden={isSupportImpersonationActive}
            >
              <i className="fa-regular fa-xmark fa-lg align-middle"></i>
            </button>
          </>
        ),
        user: user,
      };
    });
  }

  const columns = useMemo<ColumnDef<IUserTableRow>[]>(
    () => [
      {
        accessorKey: "email",
        id: "email",
        enableSorting: true,
        header: () => <span>User</span>,
        meta: {
          className: "email-column",
        },
        cell: ({ row }) => {
          return <>{row.original.email}</>;
        },
      },
      {
        accessorKey: "role",
        id: "role",
        enableSorting: true,
        header: () => <span>Role</span>,
        meta: {
          className: "role-column",
        },
        cell: ({ row }) => {
          return <>{row.original.role}</>;
        },
      },
      {
        accessorKey: "status",
        id: "status",
        enableSorting: true,
        sortingFn: (rowA, rowB) => {
          const statusA = Number(rowA.original.user.isInvite);
          const statusB = Number(rowB.original.user.isInvite);

          return statusA - statusB;
        },
        header: () => <span>Status</span>,
        meta: {
          className: "status-column",
        },
        cell: ({ row }) => {
          return <>{row.original.status}</>;
        },
      },
      {
        accessorKey: "editButtons",
        id: "editButtons",
        enableSorting: false,
        header: () => <></>,
        meta: {
          className: "buttons-column",
        },
        cell: ({ row }) => {
          return <>{row.original.editButtons}</>;
        },
      },
    ],
    [],
  );

  // Set up user seat badge
  let userSeats: string;
  let userSeatsBadgeColor = "secondary";
  let userSeatsTooltip: JSX.Element | null = null;

  if (userSeatCount === -1) {
    userSeats = "Unlimited";
  } else if (userList.length > userSeatCount) {
    userSeats = "Over Limit";
    userSeatsBadgeColor = "danger";
    const excessUsers = userList.length - userSeatCount;
    const excessUserMessage =
      excessUsers === 1
        ? `You are ${excessUsers} user seat over your plan limit.`
        : `You are ${excessUsers} user seats over your plan limit.`;
    userSeatsTooltip = (
      <UncontrolledTooltip placement="top" target="userSeatsBadge">
        {excessUserMessage}
      </UncontrolledTooltip>
    );
  } else {
    userSeats = `${userList.length}/${userSeatCount}`;
  }

  const usersTableDisabledTooltip = usage.usersOverLimit ? (
    <UncontrolledTooltip
      placement="top"
      target="usersTableDisabled"
      trigger="hover"
      autohide={false}
    >
      <a
        className="upgrade-link"
        onClick={() =>
          navigate("/settings?defaultTab=" + getSettingsPageSubscriptionTab())
        }
      >
        Upgrade
      </a>
      &nbsp;your plan or remove users to retain full Connect Cloud
      functionality.
    </UncontrolledTooltip>
  ) : null;

  const deleteDisabled = Object.keys(selectedUsers).length === 0;

  if (error) {
    return <Page500 error={error} />;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container fluid className={"p-0 pages-users-Users"}>
      <div hidden={!processingRequest}>
        <div className="loading-background" />
        <Spinner className="spinner-border loading-spinner" color="info" />
      </div>
      <div>
        <Row>
          <Col>
            <h1 className="h3 ms-1 mb-4">Users</h1>
          </Col>
          <Col className="text-end">
            <span>
              <Button
                disabled={deleteDisabled}
                color="secondary"
                onClick={(event) => openDeleteUsersModal(event)}
                className="me-1 card-actions"
                hidden={isSupportImpersonationActive}
              >
                <i className="fa fa-times icon no-pointer-event" />
                Delete
              </Button>
              <span id="inviteUserButton" className="tooltip-padding">
                <Button
                  color="primary"
                  onClick={() => openInviteUsersPage()}
                  hidden={isSupportImpersonationActive}
                  disabled={inviteButtonDisabled}
                  className={
                    inviteButtonDisabled
                      ? "me-1 no-pointer-event action-button"
                      : "me-1"
                  }
                  data-testid="button-invite-users"
                >
                  <i className="fa fa-plus icon no-pointer-event" />
                  Invite Users
                </Button>
              </span>
            </span>
            <UncontrolledTooltip
              placement="top"
              target="inviteUserButton"
              className={inviteButtonDisabled ? "" : "d-none"}
              trigger="hover"
              autohide={false}
            >
              <a
                className="upgrade-link"
                onClick={() =>
                  navigate(
                    "/settings?defaultTab=" + getSettingsPageSubscriptionTab(),
                  )
                }
              >
                Upgrade
              </a>
              {!isConnectForSpreadsheetClient
                ? ` your plan or remove users to retain full Connect Cloud
              functionality.`
                : ` your plan to invite more users to your account.`}
            </UncontrolledTooltip>
          </Col>
        </Row>
        <Card>
          <CardBody>
            <Row className="mb-3">
              <Col className="d-flex align-items-center">
                <CardTitle className="ms-1 mb-0">User Seats:</CardTitle>
                <Badge
                  id="userSeatsBadge"
                  color={userSeatsBadgeColor}
                  className="align-middle ms-2"
                >
                  {userSeats}
                </Badge>
                {userSeatsTooltip}
              </Col>
              {isManageSubscriptionVisible(currentUser.role) && (
                <Col className="text-end">
                  <TertiaryButton
                    key="selectConnectionButton"
                    onClick={() =>
                      navigate(
                        "/settings?defaultTab=" +
                          getSettingsPageSubscriptionTab(),
                      )
                    }
                  >
                    {ManageSubscriptionText()}
                  </TertiaryButton>
                </Col>
              )}
            </Row>
            <div
              id="usersTableDisabled"
              className={usage.usersOverLimit ? "users-table-disabled" : ""}
            >
              {usersTableDisabledTooltip}
              <ListTable
                columns={columns}
                data={userListBody}
                defaultSort={[
                  {
                    desc: false,
                    id: "email",
                  },
                ]}
                emptyTableMessage={""}
                enableFiltering={true}
                enablePagination={true}
                enableCheckboxes={!isSupportImpersonationActive}
                onRowClick={usage.usersOverLimit ? undefined : handleRowClick}
                searchPlaceholder={"Search by name..."}
                selection={selectedUsers}
                setSelection={setSelectedUsers}
              />
            </div>
          </CardBody>
        </Card>
      </div>
      <DeleteUsersModal
        open={isDeleteUsersModalOpen}
        close={() => setIsDeleteUsersModalOpen(!isDeleteUsersModalOpen)}
        handlePostDeleteTasks={handlePostDeleteTasks}
        usersToDelete={usersToDelete}
        setProcessingRequest={setProcessingRequest}
      />
    </Container>
  );
};

export default Users;
