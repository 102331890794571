import React, { ReactNode } from "react";

interface IQueryBuilderCardHeaderProps {
  title: ReactNode;
  children: ReactNode;
}

export function QueryBuilderCardHeader(props: IQueryBuilderCardHeaderProps) {
  const { title, children } = props;
  return (
    <div className="queryBuilderCardHeader">
      <div className="cardHeader-title">{title}</div>
      {children}
    </div>
  );
}
