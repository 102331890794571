import { createRoot } from "react-dom/client";
import chameleon from "@chamaeleonidae/chmln";

import App from "./App";
import TagManager from "react-gtm-module";
import { BrowserRouter } from "react-router-dom";
import { store } from "./redux/store";

// Reloads the page if a dynamic import fails:
// https://vite.dev/guide/build#load-error-handling
window.addEventListener("vite:preloadError", (event) => {
  console.error(event.payload);
  window.location.reload();
});

const tagManagerArgsProd = {
  gtmId: "GTM-MLXH3WB",
};

const tagManagerArgsDev = {
  gtmId: "GTM-M2GH4MW",
};

if (window.location.hostname === "cloud.cdata.com") {
  TagManager.initialize(tagManagerArgsProd);
  initChameleon();
} else if (window.location.origin.includes("dev")) {
  TagManager.initialize(tagManagerArgsDev);
  initChameleon();
}

//AccountId is added here for consumption in the tagmanager
function handleChange() {
  window.accountId = store.getState().user?.accountId;
  const event = new CustomEvent("accountIdAdded", {
    bubbles: true,
    composed: true,
    detail: { accountId: window.accountId },
  });
  window.dispatchEvent(event);
  TagManager.dataLayer({
    dataLayer: {
      event: "accountIdAdded",
    },
  });
}
store.subscribe(handleChange);

// Used to setup chameleon for user tutorials in the app.
// See: https://app.chameleon.io/setup/install
// You need an account with chameleon to see the above page.

function initChameleon() {
  const chameleonToken =
    "SIHQaYpNGI6fz6f0omG5typcHXrohRckFL7c1e8z5KHUt0-1NtP1b-DwdaAEluXImNiywG";

  try {
    chameleon.init(chameleonToken);
  } catch (err) {
    console.error("An error ocurred initializing chameleon");
    console.error(err);
  }
}

// Once the window is loaded, hide the spinner
window.onload = () => {
  const spinner = document.getElementById("spinner");
  if (spinner) {
    spinner.style.display = "none";
  }
};

const root = createRoot(document.getElementById("root")!);
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
);
