import { useEffect, useMemo, useState } from "react";
import { ColumnDef, RowSelectionState } from "@tanstack/react-table";
import ListTable from "../../../../components/tables/ListTable";
import { Card, CardBody, Row, Spinner } from "reactstrap";
import { IUser } from "../../../../models";
import UserSeatBadge from "../../../users/components/UserSeatBadge";
import { Col } from "react-bootstrap";
import {
  ButtonType,
  CDataButton,
} from "../../../../components/buttons/CDataButton";
import { ErrorTable, IErrorTableRow } from "../../../../components/ErrorTable";
import OEMUserModals, { ModalType } from "./OEMUserModals";
import { getUserList } from "../../../users/UserApiCalls";
import { useAPI } from "../../../../components/useAPI";
import { Loader } from "react-feather";
import { Status } from "../../../users/Users";

const OEMUserCard = () => {
  const api = useAPI();

  const [users, setUsers] = useState<IUser[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<RowSelectionState>({});
  const [totalUsersAllowed, setTotalUsersAllowed] = useState<number>(0);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [currentModalType, setCurrentModalType] = useState<ModalType>();
  const [rowClicked, setRowClicked] = useState<IUser>();

  const [errorTableTitle, setErrorTableTitle] = useState<string>("");
  const [errorTableData, setErrorTableData] = useState<IErrorTableRow[]>([]);

  useEffect(() => {
    const getUsersOnLoad = async () => {
      const payload = await getUserList(api.callAPI);
      if (payload && payload.userSeatCount && payload.users) {
        setTotalUsersAllowed(payload.userSeatCount);
        setUsers(payload.users);
      }

      setIsLoading(false);
    };
    getUsersOnLoad();
  }, []); // eslint-disable-line

  const rowButtons = (row: IUser) => {
    const resendInviteButton = row.isInvite ? (
      <CDataButton
        buttonType={ButtonType.Borderless}
        className="table-button button-color pe-1"
        onClick={() => {
          setRowClicked(row);
          setCurrentModalType(ModalType.Resend);
          setIsModalOpen(true);
        }}
      >
        <span className="d-flex justify-content-end">
          <i className="fa-regular fa-paper-plane align-middle resend-invite-button" />
        </span>
      </CDataButton>
    ) : null;

    return (
      <>
        {resendInviteButton}
        <CDataButton
          buttonType={ButtonType.Borderless}
          className="table-button button-color pe-1"
          onClick={() => {
            setRowClicked(row);
            setCurrentModalType(ModalType.Edit);
            setIsModalOpen(true);
          }}
        >
          <span className="d-flex justify-content-end">
            <i className="fa-regular fa-pen align-middle" />
          </span>
        </CDataButton>
        <CDataButton
          buttonType={ButtonType.Borderless}
          className="table-button button-color x-button"
          onClick={() => {
            setRowClicked(row);
            setCurrentModalType(ModalType.Delete);
            setIsModalOpen(true);
          }}
        >
          <span className="d-flex justify-content-end">
            <i className="fa-regular fa-xmark fa-lg align-middle" />
          </span>
        </CDataButton>
      </>
    );
  };

  const columns = useMemo<ColumnDef<IUser>[]>(
    () => [
      {
        accessorKey: "email",
        id: "email",
        enableSorting: true,
        header: () => <span>User</span>,
        meta: {
          className: "row-email-column",
        },
        cell: ({ row }) => {
          return <>{row.original.email}</>;
        },
      },
      {
        accessorKey: "status",
        id: "status",
        enableSorting: true,
        sortingFn: (rowA, rowB) => {
          const statusA = Number(rowA.original.isInvite);
          const statusB = Number(rowB.original.isInvite);

          return statusA - statusB;
        },
        header: () => <span>Status</span>,
        meta: {
          className: "row-status-column",
        },
        cell: ({ row }) => {
          return (
            <Status
              isInvite={row.original.isInvite}
              enabled={row.original.enabled}
            />
          );
        },
      },
      {
        accessorKey: "buttons",
        id: "buttons",
        enableSorting: false,
        header: () => <></>,
        meta: {
          className: "row-buttons-column",
        },
        cell: ({ row }) => {
          return <>{rowButtons(row.original)}</>;
        },
      },
    ],
    [],
  );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <ErrorTable
        title={errorTableTitle}
        entityName="Email"
        data={errorTableData}
        className="mb-3"
      />
      <Card className="oem-user-card">
        <CardBody>
          <Row className="mb-3 align-items-center">
            <Col>
              <UserSeatBadge
                usersCount={users.length}
                maxUsers={totalUsersAllowed}
              />
            </Col>
            <Col className="batch-buttons-column">
              <CDataButton
                buttonType={ButtonType.Secondary}
                disabled={Object.keys(selectedUsers).length === 0}
                onClick={() => {
                  setCurrentModalType(ModalType.BatchDelete);
                  setIsModalOpen(true);
                }}
              >
                <i className="fa-regular fa-xmark align-middle me-1" />
                Delete
              </CDataButton>
              <CDataButton
                buttonType={ButtonType.Primary}
                disabled={
                  totalUsersAllowed === -1
                    ? false
                    : users.length >= totalUsersAllowed
                }
                className="ms-1"
                onClick={() => {
                  setCurrentModalType(ModalType.Invite);
                  setIsModalOpen(true);
                }}
              >
                <i className="fa fa-regular fa-plus align-middle me-1" />
                Add
              </CDataButton>
            </Col>
          </Row>
          <ListTable
            columns={columns}
            data={users}
            emptyTableMessage="No users have been found."
            enableFiltering={true}
            enablePagination={true}
            enableCheckboxes={true}
            searchPlaceholder="Search users..."
            selection={selectedUsers}
            setSelection={setSelectedUsers}
            defaultSort={[
              {
                id: "email",
                desc: false,
              },
            ]}
          />
        </CardBody>
      </Card>
      <div hidden={!isProcessing}>
        <div className="loading-background" />
        <Spinner className="spinner-border loading-spinner" color="info" />
      </div>
      <OEMUserModals
        modalType={currentModalType}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        users={users}
        setUsers={setUsers}
        totalUsersAllowed={totalUsersAllowed}
        rowClicked={rowClicked}
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
        setErrorTableTitle={setErrorTableTitle}
        setErrorTableData={setErrorTableData}
        setIsProcessing={setIsProcessing}
      />
    </>
  );
};

export default OEMUserCard;
