import React from "react";
import { Route, Routes } from "react-router-dom";

import Page404 from "../auth/Page404";
import WorkspaceList from "./WorkspaceList";
import WorkspaceDetails from "./EditWorkspace/WorkspaceDetails";
import FolderDetails from "./EditFolder/FolderDetails";
import AssetDetails from "./EditAsset/AssetDetails";

const DatasetsRoutes = () => {
  return (
    <Routes>
      {/* /datasets route */}
      <Route path="/" element={<WorkspaceList />} />

      <Route path="workspace/:workspaceId" element={<WorkspaceDetails />} />

      <Route
        path="workspace/:workspaceId/folder/:folderId"
        element={<FolderDetails />}
      />

      <Route
        path="workspace/:workspaceId/asset/:assetId"
        element={<AssetDetails />}
      />

      <Route path="*" element={<Page404 showBranding={false} />} />
    </Routes>
  );
};

export default DatasetsRoutes;
