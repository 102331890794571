import { useContext } from "react";
import { QueryTabsContext } from "./QueryTabsContext";
import { QueryTabType } from "./queryTabType";
import { createDeepCopy } from "../../../../utility/CreateDeepCopy";
import { IQueryBuilderModel } from "../QueryBuilder/models/IQueryBuilderModel";
import { IScheduledQuery } from "../../../../models/ScheduledQueries/IScheduledQuery";

export interface IQueryTab {
  id: number;
  tabName: string;
  previousTabName?: string;
  tabType: QueryTabType;
  queryString: string;
  driver?: string;
  tableName?: string;
  schemaName?: string;
  connectionId?: string;
  connectionName?: string;
  uniqueId?: string;
  metadataOnStart: boolean;
  resultsOnStart?: boolean;
  isWorkspace?: boolean;
  isEmpty: boolean;
  isError: boolean;
  isLoading: boolean;
  unsavedChanges?: boolean;
  parentTabId?: number;
  queryData?: IQueryBuilderModel;
}

export default function useQueryTabs() {
  const tabContext = useContext(QueryTabsContext);
  const maxTabs = 11;

  function getNewTabId() {
    return tabContext.tabs[tabContext.tabs.length - 1].id + 1;
  }

  function getCurrentTab() {
    const currentTabIndex = tabContext.tabs.findIndex(
      (tab) => tab.id === tabContext.selectedTab,
    );

    return tabContext.tabs[currentTabIndex];
  }

  function checkForEmptyTab(queryString: string, tabType: QueryTabType) {
    return queryString === "" && tabType !== QueryTabType.Home;
  }

  function checkForExistingTab(
    query: string,
    tabType: QueryTabType,
    name?: string,
  ) {
    const existingTab = tabContext.tabs.find((tab) => {
      let isMatchingTab =
        tab.queryString.replace(/\s/g, "").toLowerCase() ===
          query?.replace(/\s/g, "").toLowerCase() &&
        tab.tabType !== QueryTabType.Home &&
        !(tab.tabType === QueryTabType.QueryBuilder && tab.unsavedChanges) &&
        tab.tabType === tabType;

      // Check for name in instances where 2 tabs of the same type (i.e. DerivedView) can contain the same query
      if (name) {
        isMatchingTab = isMatchingTab && tab.tabName === name;
      }

      return isMatchingTab;
    });

    return existingTab;
  }

  function replaceCurrentTab(queryTab: IQueryTab) {
    const currentTabIndex = tabContext.tabs.findIndex(
      (tab) => tab.id === tabContext.selectedTab,
    );

    const newTabs = [...tabContext.tabs];
    newTabs[currentTabIndex] = {
      ...newTabs[currentTabIndex],
      ...queryTab,
      previousTabName: tabContext.tabs[currentTabIndex].tabName,
    };

    tabContext.setTabs(newTabs);

    changeTab(queryTab.id);
  }

  function appendTab(queryTab: IQueryTab) {
    tabContext.setTabs([...tabContext.tabs, queryTab]);

    if (tabContext.tabs.length < maxTabs) {
      changeTab(queryTab.id);
    }
  }

  function replaceOrAppendTab(queryTab: IQueryTab) {
    const currentTab = getCurrentTab();

    if (
      currentTab &&
      checkForEmptyTab(currentTab.queryString, currentTab.tabType)
    ) {
      replaceCurrentTab(queryTab);
    } else {
      appendTab(queryTab);
    }
  }

  function addEmptyTab(
    tabType: QueryTabType,
    queryName: string | undefined,
    queryString: string,
    metadataOnStart?: boolean,
    resultsOnStart?: boolean,
  ) {
    const existingTab = checkForExistingTab(queryString, tabType);
    if (existingTab) {
      changeTab(existingTab.id);
      return existingTab.id;
    } else {
      const tabId = getNewTabId();
      const queryTabTitleCount = tabContext.queryTabTitleCounter;
      const tab = {
        id: tabId,
        tabName: queryName ?? "Query " + queryTabTitleCount,
        tabType: tabType,
        queryString: queryString,
        metadataOnStart: metadataOnStart ?? false,
        resultsOnStart: resultsOnStart ?? false,
        isEmpty: true,
      } as IQueryTab;

      tabContext.setTabs([...tabContext.tabs, tab]);
      tabContext.setQueryTabTitleCounter(queryTabTitleCount + 1);

      if (tabContext.tabs.length < maxTabs) {
        changeTab(tabId);
      }

      return tabId;
    }
  }

  function addGenericTab(
    tabType: QueryTabType,
    connectionName: string,
    schema: string,
    table: string,
    action: string,
  ) {
    const queryString = `SELECT * FROM [${connectionName}].[${schema}].[${table}]`;
    const existingTab = checkForExistingTab(queryString, tabType);

    if (existingTab) {
      changeTab(existingTab.id);
    } else {
      const tabId = getNewTabId();
      const queryTabTitleCount = tabContext.queryTabTitleCounter;
      tabContext.setQueryTabTitleCounter(tabContext.queryTabTitleCounter + 1);
      replaceOrAppendTab({
        id: tabId,
        tabName: "Query " + queryTabTitleCount,
        tabType: tabType,
        queryString: queryString,
        metadataOnStart: action === "open",
        tableName: table,
        schemaName: schema,
        connectionName: connectionName,
        isEmpty: false,
      } as IQueryTab);
    }
  }

  function addSavedQueryTab(name: string, query?: string, id?: string) {
    const existingTab = checkForExistingTab(
      query ?? "",
      QueryTabType.SavedQuery,
    );

    if (existingTab) {
      changeTab(existingTab.id);
    } else {
      const tabId = getNewTabId();
      const queryTabTitleCount = tabContext.queryTabTitleCounter;
      tabContext.setQueryTabTitleCounter(tabContext.queryTabTitleCounter + 1);
      replaceOrAppendTab({
        id: tabId,
        tabName: name ?? "Query " + queryTabTitleCount,
        tabType: QueryTabType.SavedQuery,
        queryString: query,
        uniqueId: id,
        metadataOnStart: false,
        isEmpty: false,
      } as IQueryTab);
    }
  }

  function addScheduledQueryTab(queryDetails: IScheduledQuery) {
    const existingTab = checkForExistingTab(
      queryDetails.query,
      QueryTabType.ScheduledQuery,
    );

    if (existingTab) {
      changeTab(existingTab.id);
    } else {
      const tabId = getNewTabId();
      tabContext.setQueryTabTitleCounter(tabContext.queryTabTitleCounter + 1);
      replaceOrAppendTab({
        id: tabId,
        tabName: queryDetails.name,
        tabType: QueryTabType.ScheduledQuery,
        queryString: queryDetails.query,
        resultsOnStart: true,
        isEmpty: false,
        uniqueId: queryDetails.id,
      } as IQueryTab);
    }
  }

  function addDerivedViewTab(
    name: string,
    query: string,
    uniqueId: string,
    tabType: QueryTabType,
    action: string,
    resultOnStart?: boolean,
    parentTabId?: number,
  ) {
    const queryString =
      !query && action === "query"
        ? `SELECT * FROM [CData].[DerivedViews].[${name}]`
        : query;

    const shouldUseDerivedViewName = action === "open";

    const existingTab = checkForExistingTab(
      queryString,
      tabType,
      shouldUseDerivedViewName ? name : undefined,
    );

    if (existingTab) {
      changeTab(existingTab.id);
    } else {
      const tabId = getNewTabId();
      const queryTabTitleCount = tabContext.queryTabTitleCounter;
      if (!shouldUseDerivedViewName) {
        tabContext.setQueryTabTitleCounter(tabContext.queryTabTitleCounter + 1);
      }

      replaceOrAppendTab({
        id: tabId,
        tabName: shouldUseDerivedViewName
          ? name
          : "Query " + queryTabTitleCount,
        tabType: tabType,
        queryString: queryString,
        connectionName: "CData",
        schemaName: "DerivedViews",
        tableName: name,
        uniqueId: uniqueId,
        metadataOnStart: action !== "query",
        resultsOnStart: resultOnStart,
        isEmpty: false,
        parentTabId: parentTabId,
      } as IQueryTab);
    }
  }

  function addWorkspaceTab(
    tableName: string,
    schemaName: string,
    action: string | null | undefined,
    workspaceName: string,
  ) {
    const queryString = `SELECT * FROM [${workspaceName}].[${schemaName}].[${tableName}]`;
    const existingTab = checkForExistingTab(
      queryString,
      QueryTabType.Workspace,
    );

    if (existingTab) {
      changeTab(existingTab.id);
    } else {
      const tabId = getNewTabId();
      const queryTabTitleCount = tabContext.queryTabTitleCounter;
      tabContext.setQueryTabTitleCounter(tabContext.queryTabTitleCounter + 1);
      replaceOrAppendTab({
        id: tabId,
        tabName: "Query " + queryTabTitleCount,
        tabType: QueryTabType.Workspace,
        queryString: queryString,
        tableName: tableName,
        schemaName: schemaName,
        connectionName: workspaceName,
        metadataOnStart: true,
        isWorkspace: true,
        isEmpty: false,
      } as IQueryTab);
    }
  }

  function addQueryBuilderTab(
    connectionName: string,
    connectionId: string | undefined,
    schemaName: string,
    tableName: string,
    driver: string,
  ) {
    const queryString = connectionName
      ? `SELECT * FROM [${connectionName}].[${schemaName}].[${tableName}] AS [${tableName}]`
      : "";

    const existingTab = checkForExistingTab(
      queryString,
      QueryTabType.QueryBuilder,
    );

    if (existingTab) {
      changeTab(existingTab.id);
    } else {
      const tabId = getNewTabId();
      const queryBuilderTabTitleCount = tabContext.queryBuilderTabTitleCounter;
      tabContext.setQueryBuilderTabTitleCounter(queryBuilderTabTitleCount + 1);
      replaceOrAppendTab({
        id: tabId,
        tabName: "QueryBuilder" + queryBuilderTabTitleCount,
        tabType: QueryTabType.QueryBuilder,
        queryString: queryString,
        driver: driver,
        tableName: tableName,
        schemaName: schemaName,
        connectionName: connectionName,
        connectionId: connectionId,
        metadataOnStart: false,
        isWorkspace: false,
      } as IQueryTab);
    }
  }

  function removeTab(selectedTabNumber: number) {
    // Find tab to remove
    if (tabContext.tabs.length > 1) {
      const currentTabs = [...tabContext.tabs];
      const toCloseIndex = currentTabs
        .map((tab) => {
          return tab.id;
        })
        .indexOf(selectedTabNumber);
      if (toCloseIndex !== -1) {
        const toCloseID = currentTabs[toCloseIndex].id;
        currentTabs.splice(toCloseIndex, 1);
        if (toCloseID === tabContext.selectedTab && toCloseIndex > 0) {
          //current tab is closed
          changeTab(currentTabs[toCloseIndex - 1].id); //change to nearest leftmost tab
        } else if (toCloseID === tabContext.selectedTab && toCloseIndex === 0) {
          //current tab is leftmost and is closed
          changeTab(currentTabs[0].id); //new leftmost tab
        }
      }
    }

    const updatedTabs = tabContext.tabs.filter(
      (tab) => tab.id !== selectedTabNumber,
    );
    tabContext.setTabs(updatedTabs);
  }

  function changeTab(selectedTabNumber: number) {
    tabContext.setSelectedTab(selectedTabNumber);
  }

  function clearQueryBuilderTab(id: number, type: QueryTabType) {
    const tabs = [...tabContext.tabs];
    const rowToMutate = tabContext.tabs.find((tab) => tab.id === id);

    if (rowToMutate) {
      if (rowToMutate.previousTabName) {
        rowToMutate.tabName = rowToMutate.previousTabName;
      }

      rowToMutate.connectionName = "";
      rowToMutate.schemaName = "";
      rowToMutate.tableName = "";
      rowToMutate.tabType = type;
      rowToMutate.queryString = "";
      tabContext.setTabs(tabs);
    }
  }

  function changeTabType(
    id: number,
    tabName: string,
    type: QueryTabType,
    uniqueId: string,
    query: string,
  ) {
    tabContext.setTabs((previousTabs) => {
      const newTabs = createDeepCopy(previousTabs);
      const rowToMutate = newTabs.find((tab) => tab.id === id);
      if (rowToMutate) {
        rowToMutate.tabName = tabName;
        rowToMutate.tabType = type;
        rowToMutate.uniqueId = uniqueId;
        rowToMutate.queryString = query;
      }

      return newTabs;
    });
  }

  function isCurrentTabEmpty(tabid: number) {
    const tab = tabContext.tabs.find((tab: IQueryTab) => tabid === tab.id);
    return tab ? tab.isEmpty : false;
  }

  function updatedTabEmpty(tabid: number, isEmptyFlag: boolean) {
    const rowToMutate = tabContext.tabs.find((tab) => tab.id === tabid);
    if (rowToMutate) rowToMutate.isEmpty = isEmptyFlag;
  }

  function setUnsavedChanges(tabId: number, unsavedChanges?: boolean) {
    tabContext.setTabs((previousTabs) => {
      const newTabs = createDeepCopy(previousTabs);
      const rowToMutate = newTabs.find((tab) => tab.id === tabId);
      if (rowToMutate) {
        rowToMutate.unsavedChanges =
          unsavedChanges === undefined ? true : unsavedChanges;
      }

      return newTabs;
    });
  }

  function setTabStatus(
    tabId: number,
    isLoading: boolean,
    isError: boolean = false,
  ) {
    tabContext.setTabs((previousTabs) => {
      const newTabs = createDeepCopy(previousTabs);
      const rowToMutate = newTabs.find((tab) => tab.id === tabId);
      if (rowToMutate) {
        rowToMutate.isLoading = isLoading;
        rowToMutate.isError = isError;
      }

      return newTabs;
    });
  }

  const modalAndAPIs = {
    AddEmptyTab: addEmptyTab,
    AddWorkspaceTab: addWorkspaceTab,
    AddDerivedViewTab: addDerivedViewTab,
    AddGenericTab: addGenericTab,
    AddSavedQueryTab: addSavedQueryTab,
    AddScheduledQueryTab: addScheduledQueryTab,
    AddQueryBuilderTab: addQueryBuilderTab,
    RemoveTab: removeTab,
    ChangeTab: changeTab,
    ClearQueryBuilderTab: clearQueryBuilderTab,
    ChangeTabType: changeTabType,
    List: tabContext.tabs,
    CurrentTabId: tabContext.selectedTab,
    IsTabEmpty: isCurrentTabEmpty,
    SetTabEmpty: updatedTabEmpty,
    SetUnsavedChanges: setUnsavedChanges,
    SetTabStatus: setTabStatus,
    MaxTabs: maxTabs,
  };

  return modalAndAPIs;
}
