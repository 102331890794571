import React, { useState } from "react";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import ResultsDetailsGrid from "../../../dataExplorer/RightPanel/Results/ResultsDetailsGrid";
import { Column, PseudoColumn } from "../SetTable";

interface IPreviewTableProps {
  previewRows: any[];
  columns: Column[];
  pseudoColumns: PseudoColumn[] | null | undefined;
}

const PreviewTable = (props: IPreviewTableProps) => {
  const [resultDropdownOpen, setResultDropdownOpen] = useState(false);

  const toggleResultDropdownOpen = () => {
    setResultDropdownOpen((prevState) => !prevState);
  };
  const noData = () => {
    return <div className="text-muted text-center">No matching records</div>;
  };
  if (props.columns.length === 0) {
    return null;
  } else {
    const columns = [
      ...props.columns,
      ...(props.pseudoColumns?.filter((pseudo) => pseudo.outputColumn) ?? []),
    ].map((column) => {
      const columnName =
        "columnName" in column ? column.columnName : column.pseudoColumnName;
      return {
        dataField: columnName,
        text: columnName,
        sort: true,
        formatter: (cell: string | number | boolean | null | undefined) => {
          if (cell == null) {
            return <span className="null-values">null</span>;
          }
          return cell;
        },
      };
    });
    //we want to limit to 100 rows
    const data = props.previewRows?.slice(0, 100);

    return (
      <div className="pages-apiConnector-components-PreviewTable">
        <ResultsDetailsGrid
          columns={columns}
          noData={noData}
          resultDropdownOpen={resultDropdownOpen}
          toggleResultDropdownOpen={toggleResultDropdownOpen}
          gridData={data}
          gridID={"preview-table"}
          wrapperClassNames={"table-responsive"}
        />
      </div>
    );
  }
};

export default PreviewTable;
