import { createDeepCopy } from "../../../../../utility/CreateDeepCopy";
import { Column } from "../../SetTable";

const getRelativePath = (
  rootPath: string,
  columnPath: string,
  xmlPath: boolean,
) => {
  let relativePath = columnPath;

  if (xmlPath) {
    //Regex that trims the root path from the column path
    //escape [ and ] characters
    const sanitizedRootPath = rootPath
      .replace(/\[/g, "\\[")
      .replace(/\]/g, "\\]");
    relativePath = relativePath.replace(
      new RegExp(`^${sanitizedRootPath}`),
      "",
    );
    let firstToken = getFirstToken(relativePath);
    relativePath = relativePath.replace(`${firstToken}`, "");
    firstToken = removeIndex(firstToken);
    relativePath = firstToken + relativePath;
  } else {
    rootPath = rootPath === "/" ? "" : rootPath;
    relativePath = columnPath.startsWith(rootPath)
      ? columnPath.slice(rootPath.length)
      : columnPath;
  }
  relativePath = relativePath.replace(/(?<!\[\d+\])\[\d+\]/g, "");
  if (relativePath.startsWith("/")) {
    relativePath = relativePath.substring(1);
  }

  return relativePath;
};

const getFirstToken = (column: string) => {
  const firstToken = column.substring(1).split("/")[0];
  return firstToken ? `/${firstToken}` : "";
};

const removeIndex = (xpath: string) => {
  const indexRegex = /\[(\d+)\]/;
  return xpath.replace(indexRegex, "");
};

const removeInvalidColNameChars = (input: string) => {
  const validCharsRegex = /[^a-zA-Z0-9_ -]/g;
  const sanitizedString = input.replace(validCharsRegex, "");
  return sanitizedString;
};

export const getLastToken = (column: string) => {
  const lastToken = column.split("/").pop();
  return lastToken ? lastToken : "";
};

const handleDuplicateColumnNames = (columns: Column[]) => {
  const columnNameCount: Record<string, number> = {};
  const uniqueColumnNames = createDeepCopy(columns);

  uniqueColumnNames.forEach((col) => {
    const pathTokens = col.path.split("/");
    const columnName = col.columnName;

    if (columnNameCount[columnName] !== undefined) {
      // If there's a previous path token, use it for the new column name
      if (pathTokens.length > 1) {
        col.columnName = getNewColumnNameUsingPathToken(
          columnName,
          pathTokens,
          columnNameCount,
        );
      } else {
        col.columnName = incrementName(columnName, columnNameCount);
      }
    }
    const currCount = columnNameCount[col.columnName] || 0;
    columnNameCount[col.columnName] = currCount + 1;
  });

  return uniqueColumnNames;
};

const getNewColumnNameUsingPathToken = (
  columnName: string,
  pathTokens: string[],
  columnNameCount: Record<string, number>,
): string => {
  const previousPathToken = pathTokens[pathTokens.length - 2];
  const newColumnName = `${previousPathToken}_${columnName}`;

  if (columnNameCount[newColumnName] !== undefined) {
    return incrementName(columnName, columnNameCount);
  } else {
    return newColumnName;
  }
};

const incrementName = (
  currName: string,
  colNameCount: Record<string, number>,
) => {
  const count = colNameCount[currName];
  return `${currName}${count + 1}`;
};

const startsWithArrayIndex = (path: string) => {
  const leadingArrayIndex = new RegExp(/^\[\d+\]/);
  return leadingArrayIndex.test(path);
};

export default {
  getRelativePath,
  removeInvalidColNameChars,
  handleDuplicateColumnNames,
  startsWithArrayIndex,
};
