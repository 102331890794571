import { useContext, useEffect } from "react";
import {
  DataType,
  FeatureId,
  IConnection,
} from "../../../../../../../../../models";
import DataLabelAndDropdown from "./DataLabelAndDropdown";
import { useFormikContext } from "formik";
import { AddDataModalContext } from "../AddDataModalContext";
import { IQueryBuilderCommonProps } from "../../../../../models/IQueryBuilderCommonProps";
import { findConnection } from "../AddDataModal.functions";
import { useNavigate } from "react-router-dom";
import { getSettingsPageSubscriptionTab } from "../../../../../../../../../utility/SubscriptionProvider";
import { IQueryBuilderJoin } from "../../../../../models/IQueryBuilderModel";
import { produce } from "immer";
import { getSchemasForConnection } from "../../../../../../../../../api/metadata/getSchemasForConnection";
import { useMutation } from "@tanstack/react-query";
import { QueryBuilderConnectionDropdownOption } from "../../../components/QueryBuilderConnectionDropdownOption";
import { useAppSelector } from "../../../../../../../../../redux/hooks";

interface INewTableConnectionField extends IQueryBuilderCommonProps {}

const NewTableConnectionField = (props: INewTableConnectionField) => {
  const { connectionsList } = props;
  const { setValues, values } = useFormikContext<IQueryBuilderJoin>();
  const { setProcessing, setSchemas, setTables, setNewTableColumns } =
    useContext(AddDataModalContext);
  const navigate = useNavigate();

  const availableFeatureIds = useAppSelector(
    (state) => state.subscription.limits!.availableFeatureIds,
  );

  const isVirtualizationEnabled = availableFeatureIds.includes(
    FeatureId.QueryFederation,
  );

  const joinType = values.joinType;

  const { mutateAsync: loadSchemasForConnectionAsync } = useMutation({
    mutationKey: ["/schemas"],
    mutationFn: getSchemasForConnection,
    onSuccess: (schemaMetadata) => {
      setSchemas(schemaMetadata.map((s) => s.schema));
    },
    onMutate: () => {
      setProcessing(true);
    },
    onSettled: () => {
      setProcessing(false);
    },
    meta: {
      errorMessage:
        "Failed to retrieve schema list list due to the following error:",
    },
  });

  useEffect(() => {}, []);

  function updateSelectedConnection(connection: IConnection) {
    loadSchemasForConnectionAsync(connection.name!);
    const newJoin = produce(values, (draft) => {
      draft.right.table = {
        connectionId: connection.id!,
        connectionName: connection.name!,
        driver: connection.driver!,
        schema: "",
        tableAlias: "",
        tableName: "",
      };
      draft.right.column = "";
      draft.right.dataType = DataType.VARCHAR;

      draft.left.column = "";
    });
    setValues(newJoin);

    // Clear the downstream selection options
    setTables([]);
    setNewTableColumns([]);
  }

  useEffect(() => {
    if (values.right.table.connectionName !== "") {
      loadSchemasForConnectionAsync(values.right.table.connectionName);
    }
  }, []); // eslint-disable-line

  const upgradeLink = (
    <a
      className="tooltip-hyperlink"
      onClick={() => {
        navigate("/settings?defaultTab=" + getSettingsPageSubscriptionTab());
      }}
    >
      Upgrade
    </a>
  );

  const tooltipText = !isVirtualizationEnabled ? (
    <span className="data-modal-tooltip-text">
      {upgradeLink} your plan with the Virtualization package to take advantage
      of query federation.
    </span>
  ) : undefined;

  const value = findConnection(
    values.right.table.connectionId,
    connectionsList,
  );

  const isOptionEqualToValue = (option: IConnection, value: IConnection) => {
    return option.id === value.id && option.name === value.name;
  };

  return (
    <DataLabelAndDropdown<IConnection>
      handleChange={updateSelectedConnection}
      isDisabled={!joinType || !isVirtualizationEnabled}
      isOptionEqualToValue={isOptionEqualToValue}
      label={"Connection"}
      id={"newTable.connection"}
      dropdownLabel={(option) => {
        return <QueryBuilderConnectionDropdownOption option={option} />;
      }}
      optionLabel={(option) => (option.name ? option.name : "")}
      options={connectionsList}
      tooltipId={"newTableConnection"}
      tooltipText={tooltipText}
      value={value ?? null}
    />
  );
};

export default NewTableConnectionField;
