import { useContext, useEffect, useRef, useState } from "react";
import TitleWithBackButton from "../../../components/TitleWithBackButton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAPI } from "../../../components/useAPI";
import Loader from "../../../components/Loader";
import {
  getCacheJob,
  getTimeCheckColumnOptions,
  updateCacheJobs,
  createCacheJob,
  refreshCacheJob,
} from "../components/CachingApiCalls";
import { RunHistoryCard } from "./components/RunHistoryCard";
import { CacheJobHeaderCard } from "./components/CacheJobHeaderCard";
import { ErrorNotificationBanner } from "./components/ErrorNotificationBanner";
import { JobStatus } from "../../../models/Cache/JobStatus";
import { JobType } from "../../../models/Cache/JobType";
import { CacheJobDetailsCard } from "./components/CacheJobDetailsCard";
import { Button, Col, Row } from "reactstrap";
import { ICreateCacheJobRequest } from "../../../models/Cache/ICreateCacheJobRequest";
import { ToastrSuccess } from "../../../services/toastrService";
import { IQueryRecord } from "../../../models";
import TertiaryButton from "../../../components/buttons/TertiaryButton";
import { ModalContext } from "../../../routes/ModalContext";
import { IModalProps } from "../../../components/CDataModal";
import { jobsConstants } from "../Jobs.constants";
import PromptWithModal from "../../../components/PromptWithModal";
import {
  findMatchingColumn,
  getDateTimeColumns,
  getTableColumnInfo,
  parseTimeCheckColumns,
} from "../util/CacheUtils";
import AdvancedSettingsCard from "./components/AdvancedSettingsCard";
import { JobFrequencyUnit } from "../../../models/Cache/JobFrequencyUnit";
import {
  ButtonType,
  CDataButton,
} from "../../../components/buttons/CDataButton";
import { Form, Formik, FormikProps, FormikValues } from "formik";
import * as Yup from "yup";
import { ICacheJobExtended } from "./components/ICacheJobExtended";
import { DeleteJobModal } from "../modals/DeleteJobModal";
import { useJobsFunctions } from "../useJobsFunctions";
import { useUserInfo } from "../../../hooks/useUserInfo";
import { UserRole } from "../../../models/Users/UserRole";
import { useMutation } from "@tanstack/react-query";
import { IConnection } from "../../../models/Connections/IConnection";
import { getConnections } from "../../connections/connectionList/getConnections";
import { IConnectionList } from "../../../models/Connections/IConnectionList";
import { NotificationBar } from "../../../components/notification/NotificationBar";

interface IJobData {
  connectionId: string;
  connectionName: string;
  schema: string;
  tableName: string;
  driver: string;
}

function EditCacheJob() {
  const [cacheJobDetails, setCacheJobDetails] = useState<ICacheJobExtended>(
    {} as ICacheJobExtended,
  );

  const [timeCheckColumnOptions, setTimeCheckColumnOptions] = useState<
    string[]
  >([]);
  const [checkColumnSupport, setCheckColumnSupport] = useState(false);
  const [errorLog, setErrorLog] = useState<IQueryRecord | undefined>(undefined);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isAddJob, setIsAddJob] = useState(false);
  const [showDeleteJobModal, setShowDeleteJobModal] = useState(false);
  const [connection, setConnection] = useState<IConnection | undefined>();
  const api = useAPI();
  const modalContext = useContext(ModalContext);
  const navigate = useNavigate();
  const params = useParams();
  const cacheJobId = params.cacheJobId!;
  const location = useLocation();
  const formikRef = useRef<FormikProps<FormikValues>>(null);
  const { deleteJob, getJobErrorLogsAsync } = useJobsFunctions();
  const user = useUserInfo();

  const { mutateAsync: getConnectionsAsync, isPending: gettingConnections } =
    useMutation({
      mutationKey: ["connections/list"],
      mutationFn: getConnections,
      meta: {
        errorMessage:
          "Failed to retrieve list of connections due to the following error:",
      },
    });

  async function getConnectionsData(jobDetails: ICacheJobExtended) {
    const data: IConnectionList = await getConnectionsAsync({
      IsAdmin: user.IsInRole(UserRole.Admin),
      CurrentUserId: user.Self.id,
    });
    setConnection(
      data.connections?.find((c) => c.id === jobDetails.sourceConnection),
    );
  }

  useEffect(() => {
    async function initializeData() {
      let details: ICacheJobExtended;
      const jobData = location.state as IJobData;
      if (location.state) {
        setIsAddJob(true);
        details = {
          id: jobsConstants.DEFAULT_JOB_ID,
          name: jobData.tableName,
          enabled: true,
          isAutoTruncateStrings: true,
          created: "0001-01-01T00:00:00Z",
          lastModified: "0001-01-01T00:00:00Z",
          sourceConnection: jobData.connectionId,
          sourceConnectionName: jobData.connectionName,
          sourceConnectionDriver: jobData.driver,
          timeCheckColumn: "",
          definedNextRun: "",
          jobFrequency: 0,
          jobFrequencyUnit: JobFrequencyUnit.Day,
          logVerbosity: 1,
          accountId: "",
          sourceSchema: jobData.schema,
          sourceTable: jobData.tableName,
          jobType: JobType.Caching,
          isFullUpdate: true,
          status: {
            lastRun: "0001-01-01T00:00:00Z",
            lastRunDuration: 0,
            info: "",
            status: JobStatus.JOB_CREATED,
            nextRun: "0001-01-01T00:00:00Z",
          },
        };
      } else {
        details = await getCacheJob(api.callAPI, cacheJobId);
      }

      const timeCheckColumnInfo = details.sourceConnectionDriver
        ? await getTimeCheckColumnOptions(
            api.callAPI,
            details.sourceConnectionDriver,
            details.sourceConnection,
          )
        : null;

      // If the API call fails, we can't do anything, just bail out.
      if (timeCheckColumnInfo == null) {
        return;
      }
      let timeCheckColumns: string[] = [];
      let tableColumnInfo = [];
      if (
        timeCheckColumnInfo.checkColumnSupported === true ||
        timeCheckColumnInfo.timeCheckColumns?.length > 0
      ) {
        if (
          details.sourceConnectionName &&
          details.sourceSchema &&
          details.sourceTable
        ) {
          tableColumnInfo = await getTableColumnInfo(
            api.callAPI,
            details.sourceConnectionName,
            details.sourceSchema,
            details.sourceTable,
          );
        }
        if (tableColumnInfo != null) {
          if (timeCheckColumnInfo.checkColumnSupported === true) {
            timeCheckColumns = getDateTimeColumns(tableColumnInfo);
          } else {
            timeCheckColumns = findMatchingColumn(
              tableColumnInfo,
              parseTimeCheckColumns(timeCheckColumnInfo.timeCheckColumns),
            );
          }
        }
      }
      setCheckColumnSupport(timeCheckColumnInfo.checkColumnSupported);
      setTimeCheckColumnOptions(timeCheckColumns);

      // If there are time check options available for this driver, and none are selected, we can assume the cache scheme should be set to full updates
      details.isFullUpdate = !details.timeCheckColumn;

      setCacheJobDetails(details);

      if (details.status?.status === JobStatus.JOB_FAILED) {
        const newErrorLogs = await getJobErrorLogsAsync(
          details.status.lastRunId ?? "",
        );
        if (
          newErrorLogs &&
          newErrorLogs.queries &&
          newErrorLogs.queries.length > 0
        ) {
          setErrorLog(newErrorLogs.queries[0]);
        }
      }

      await getConnectionsData(details);

      setLoading(false);
    }
    initializeData();
  }, []); // eslint-disable-line

  const validationSchema = Yup.object().shape({
    jobFrequency: Yup.number()
      .required("A job frequency is required.")
      .moreThan(0, "Value cannot equal 0."),
  });

  const onSubmit = async (values: FormikValues) => {
    const requestBody: ICreateCacheJobRequest = {
      jobFrequencyUnit: values.jobFrequencyUnit,
      jobFrequency: values.jobFrequency,
      verbosity: values.logVerbosity,
      cacheSchemas: [
        {
          id: values.id,
          enabled: values.enabled,
          isAutoTruncateStrings: values.isAutoTruncateStrings,
          sourceConnection: values.sourceConnection,
          sourceSchema: values.sourceSchema,
          sourceTable: values.sourceTable,
          isFullUpdate: values.isFullUpdate,
          timeCheckColumn: values.timeCheckColumn,
        },
      ],
    };

    if (isAddJob) {
      setUnsavedChanges(false);
      const result = await createCacheJob(api.callAPI, requestBody);
      if (result) {
        ToastrSuccess(
          "Job successfully created",
          "Your cached job was successfully created.",
        );
      }
      navigate("/jobs");
    } else {
      const result = await updateCacheJobs(api.callAPI, requestBody);
      if (result) {
        setUnsavedChanges(false);
        ToastrSuccess(
          "Job successfully saved",
          "Your cached job was successfully updated.",
        );
      } else {
        setUnsavedChanges(true);
      }
    }
  };

  async function refreshCache() {
    const isSuccess = await refreshCacheJob(api.callAPI, cacheJobDetails.id);
    if (isSuccess) {
      formikRef.current?.setValues({
        ...formikRef.current?.values,
        status: {
          ...formikRef.current?.values.status,
          status: JobStatus.JOB_QUEUED,
        },
      });
    }
  }

  async function openRefreshCacheModal() {
    const message =
      "Clearing the cache from this job will delete all previously stored data and invoke an immediate full " +
      "update, which may take some time to fully populate. We recommend doing this when the information from the originating " +
      " source table has changed. Are you sure you want to proceed?";

    const modal = {
      title: "Refresh Cache",
      body: <span>{message}</span>,
      primaryButton: (
        <Button
          color="danger"
          onClick={async () => {
            refreshCache();
            modalContext.toggleModal();
          }}
          data-testid="button-confirm-refresh-cache"
        >
          Refresh Cache
        </Button>
      ),
      secondaryButton: (
        <Button
          color="secondary"
          onClick={modalContext.toggleModal}
          data-testid="button-cancel-refresh-cache"
        >
          Cancel
        </Button>
      ),
      displayToggleCloseButton: true,
      displayed: true,
    } as IModalProps;
    modalContext.setModal(modal);
  }

  async function onDelete() {
    const response = await deleteJob(cacheJobDetails);
    setShowDeleteJobModal(false);

    if (!response.error) {
      ToastrSuccess(
        jobsConstants.JOB_SUCCESSFULLY_DELETED_TITLE,
        `Cache job for table '${cacheJobDetails.name}' deleted successfully.`,
      );
      navigate("/jobs");
    }
  }

  if (loading || gettingConnections) {
    return <Loader />;
  }

  return (
    <div data-testid="card-edit-cache-job" className="edit-cache-job">
      <Formik
        innerRef={formikRef}
        initialValues={cacheJobDetails}
        validationSchema={validationSchema}
        validateOnMount={true}
        onSubmit={onSubmit}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <TitleWithBackButton
                  title="Edit Job"
                  customNavigate={() => navigate("/jobs")}
                />
              </Col>
              <Col className="align-items-start justify-content-end">
                <TertiaryButton
                  className="me-1"
                  data-testid="button-refresh-cache"
                  onClick={() => openRefreshCacheModal()}
                  disabled={isAddJob}
                >
                  <i className="fa fa-solid fa-rotate-right me-1" /> Refresh
                  Cache
                </TertiaryButton>
                <CDataButton
                  buttonType={ButtonType.Secondary}
                  data-testid="button-delete"
                  onClick={() => setShowDeleteJobModal(true)}
                  disabled={isAddJob}
                  className="card-actions me-1"
                >
                  <i className="fa fa-solid fa-times me-1" />
                  Delete
                </CDataButton>
                <CDataButton
                  buttonType={ButtonType.Primary}
                  data-testid="button-save-changes"
                  className="card-actions"
                  type="submit"
                  disabled={
                    !unsavedChanges ||
                    formikRef.current?.values.jobFrequency === 0 ||
                    (!formikRef.current?.values.isFullUpdate &&
                      timeCheckColumnOptions.length > 0 &&
                      formikRef.current?.values.timeCheckColumn === "")
                  }
                >
                  <i className="fa fa-solid fa-floppy-disk fa-md align-middle add-connection-icon no-pointer-event" />
                  Save Changes
                </CDataButton>
              </Col>
            </Row>
            {!connection?.isTested && (
              <Row className="mb-4">
                <Col>
                  <NotificationBar
                    message="We've detected an issue with this connection. Please visit the edit connection page to resolve it."
                    barColor="notification-bar-red"
                    linkText="Edit Connection"
                    linkClickOverride={() => {
                      navigate("/connections/edit", {
                        state: {
                          driverType: connection?.driver,
                          connectionId: connection?.id,
                        },
                      });
                    }}
                  />
                </Col>
              </Row>
            )}
            {formikRef.current?.values?.status?.status ===
            JobStatus.JOB_FAILED ? (
              <ErrorNotificationBanner
                jobDetails={cacheJobDetails}
                errorLog={errorLog}
              />
            ) : null}
            <CacheJobHeaderCard
              cacheJobDetails={cacheJobDetails}
              connection={connection}
            />
            <Row>
              <Col className="align-items-start">
                <CacheJobDetailsCard
                  timeCheckColumnOptions={timeCheckColumnOptions}
                  checkColumnSupport={checkColumnSupport}
                  setUnsavedChanges={setUnsavedChanges}
                />
              </Col>
              <Col className="align-items-start right-col">
                <RunHistoryCard<ICacheJobExtended> />
                <AdvancedSettingsCard<ICacheJobExtended>
                  jobType={JobType.Caching}
                  setUnsavedChanges={setUnsavedChanges}
                />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
      <DeleteJobModal
        isBulk={false}
        isDisplayed={showDeleteJobModal}
        onDelete={onDelete}
        setIsDisplayed={setShowDeleteJobModal}
      />
      <PromptWithModal
        when={unsavedChanges}
        navigate={(path: string) => navigate(path)}
      />
    </div>
  );
}

export default EditCacheJob;
