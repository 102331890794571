import classNames from "classnames";
import { isEmpty } from "lodash";
import React from "react";
import { Input } from "reactstrap";

export interface ISearchInputProps {
  value: string | null | undefined;
  setValue: (value: string) => void;
  searchPlaceholder?: string;
  className?: string;
}

export const SearchInput = (props: ISearchInputProps) => {
  const { searchPlaceholder, value, setValue, className } = props;

  return (
    <div className={classNames(["component-SearchInput", className])}>
      <i className="fa fa-regular fa-magnifying-glass align-middle me-2 search-icon" />
      <Input
        data-testid="table-search-bar"
        value={value ?? ""}
        autoFocus={false}
        onChange={(event) => setValue(event.target.value)}
        placeholder={searchPlaceholder ?? "Search..."}
        aria-label={searchPlaceholder ?? "Search"}
      />
      {!isEmpty(value) && (
        <div className="clear-container">
          <button
            className="table-button list-table-clear-button"
            onClick={(event) => {
              event.stopPropagation();
              setValue("");
            }}
          >
            <i className="fa fa-solid fa-circle-xmark" />
          </button>
        </div>
      )}
    </div>
  );
};
