import React, { Dispatch, SetStateAction, forwardRef, useRef } from "react";

import {
  Card,
  CardBody,
  Col,
  Container,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { APIAuthType } from "../../../../models";
import { ApiConnectionPageType, IGlobalSettings } from "../../ApiConnector";
import { GlobalHeadersCard } from "./components/GlobalHeadersCard";
import { OAuthParamsCard } from "./components/OAuthParamsCard";
import { Form, Formik, FormikErrors, FormikProps } from "formik";
import AuthenticationCard from "./components/AuthenticationCard";
import PaginationCard from "./components/PaginationCard";
import AdvancedCard from "./components/AdvancedCard";
import * as Yup from "yup";
import { isEmpty } from "lodash";
import { scrollToFirstValidationError } from "../../../../components/form/scrollToFirstValidationError";

interface IGlobalSettingsTabProps {
  globalSettings: IGlobalSettings;
  setGlobalSettings: Dispatch<SetStateAction<IGlobalSettings>>;
  apiConnectionPageType?: ApiConnectionPageType;
  unsavedGlobalChanges: boolean;
  setUnsavedGlobalChanges: (hasUnsavedChanges: boolean) => void;
  existingNames: string[];
}

const GlobalSettingsTab = forwardRef(
  (props: IGlobalSettingsTabProps, connectionNameRef: any) => {
    const authRef = useRef<any>();
    const paginationRef = useRef<any>();
    const oAuthParamsRef = useRef<any>();
    const jwtAuthParamsRef = useRef<FormikProps<any>>(null);
    const globalHeaderRef = useRef<FormikProps<any>>(null);

    function getComponentName() {
      return "pages-apiConnector-GlobalSettingsTab-GlobalSettingsTab";
    }

    function updateConnectionName(
      event: React.ChangeEvent<HTMLInputElement>,
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean,
      ) => Promise<void | FormikErrors<{
        ConnectionName: string;
      }>>,
    ) {
      const newConnectionName = event.target.value;

      props.setGlobalSettings((prevProps: IGlobalSettings) => {
        return {
          ...prevProps,
          connectionName: newConnectionName,
        };
      });
      setFieldValue("ConnectionName", newConnectionName, true);
      props.setUnsavedGlobalChanges(true);
    }

    const validationSchema = Yup.object().shape({
      ConnectionName: Yup.string()
        .required("A connection name is required.")
        .min(1, "Connection name must be between 1 and 100 characters.")
        .max(100, "Connection name must be between 1 and 100 characters.")
        .matches(
          /^[A-Za-z0-9-_S]+$/,
          "Connection name must only consist of letters, numbers, underscores, and hyphens.",
        )
        .test(
          "unique-name",
          "This name is already used in a Connection or Workspace.",
          function (value) {
            return !props.existingNames.some(
              (name) => name?.toLowerCase() === value?.toLowerCase(),
            );
          },
        ),
    });

    const initialValues = {
      ConnectionName: props.globalSettings.connectionName,
    };

    async function handleValidSubmit(): Promise<boolean | void> {
      // Submit the form to show any validation errors in the UI.
      globalHeaderRef.current?.submitForm();
      const headerValidationErrors =
        await globalHeaderRef.current?.validateForm();

      //if required headers are added & invalid then don't proceed further for submit form.
      if (!isEmpty(headerValidationErrors)) {
        scrollToFirstValidationError();
        //returning true as a flag so that main handleValidSubmit for save changes button can detect it and use it accordingly.
        return true;
      }

      authRef.current?.submitForm();
      paginationRef.current?.submitForm();
      oAuthParamsRef.current?.submitForm();
      jwtAuthParamsRef.current?.submitForm();
    }

    function renderSettingTabCards() {
      return (
        <>
          <Row>
            <Col>
              <Formik
                innerRef={connectionNameRef}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleValidSubmit}
              >
                {({
                  errors,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  touched,
                }) => (
                  <Card>
                    <CardBody>
                      <Form onSubmit={handleSubmit}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="ConnectionName">
                            <h5 className="card-title required">
                              Connection Name
                            </h5>
                          </Label>
                          <Input
                            data-testid="input-name-connection"
                            id="ConnectionName"
                            name="ConnectionName"
                            value={props.globalSettings.connectionName}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>,
                            ) => updateConnectionName(event, setFieldValue)}
                            onBlur={handleBlur}
                            required={true}
                            invalid={Boolean(
                              touched.ConnectionName && errors.ConnectionName,
                            )}
                          />
                          <FormFeedback type="invalid">
                            {errors.ConnectionName}
                          </FormFeedback>
                        </FormGroup>
                      </Form>
                    </CardBody>
                  </Card>
                )}
              </Formik>
            </Col>
          </Row>
          <Row>
            <Col>
              <AuthenticationCard
                globalSettings={props.globalSettings}
                setGlobalSettings={props.setGlobalSettings}
                setUnsavedChanges={props.setUnsavedGlobalChanges}
                apiConnectionPageType={props.apiConnectionPageType}
                isFlyout={false}
                ref={authRef}
              />
            </Col>
          </Row>
          {(props.globalSettings.authentication.type === APIAuthType.OAuth2 ||
            props.globalSettings.authentication.type ===
              APIAuthType.OAuthClient) && (
            <Row>
              <Col>
                <Container fluid className={"p-0"}>
                  <Card>
                    <CardBody>
                      <OAuthParamsCard
                        globalSettings={props.globalSettings}
                        setGlobalSettings={props.setGlobalSettings}
                        setUnsavedChanges={props.setUnsavedGlobalChanges}
                        formRef={oAuthParamsRef}
                      />
                    </CardBody>
                  </Card>
                </Container>
              </Col>
            </Row>
          )}
          <Row>
            <Row>
              <Col>
                <Container fluid className={"p-0"}>
                  <Card>
                    <CardBody>
                      <GlobalHeadersCard
                        globalSettings={props.globalSettings}
                        setGlobalSettings={props.setGlobalSettings}
                        setUnsavedChanges={props.setUnsavedGlobalChanges}
                        formRef={globalHeaderRef}
                      />
                    </CardBody>
                  </Card>
                </Container>
              </Col>
            </Row>
            <Col>
              <PaginationCard
                globalSettings={props.globalSettings}
                setGlobalSettings={props.setGlobalSettings}
                setUnsavedChanges={props.setUnsavedGlobalChanges}
                ref={paginationRef}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <AdvancedCard
                globalSettings={props.globalSettings}
                setGlobalSettings={props.setGlobalSettings}
                setUnsavedChanges={props.setUnsavedGlobalChanges}
              />
            </Col>
          </Row>
        </>
      );
    }

    return (
      <Container fluid className={"p-0 " + getComponentName()}>
        {renderSettingTabCards()}
      </Container>
    );
  },
);
GlobalSettingsTab.displayName = "GlobalSettingsTab";

export default GlobalSettingsTab;
