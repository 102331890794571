import { useState } from "react";
import { Popover, PopoverBody } from "reactstrap";
import { PopoverItem } from "./PopoverItem";
import { CDataTooltip, CDataTooltipType } from "../CDataTooltip";
import { useNavigate } from "react-router-dom";

export type IPopoverOption = {
  label: string;
  action: () => void;
  disabled?: boolean;
  "data-testid"?: string;
};

interface IPopoverListProps {
  target: string;
  popoverOptions: IPopoverOption[];
}

export const PopoverList = (props: IPopoverListProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const toggleIsDisplayed = () => {
    setIsPopoverOpen(!isPopoverOpen);
  };

  const buttons = props.popoverOptions.map((item) => {
    if (item.disabled) {
      return (
        <CDataTooltip
          key={item.label}
          type={CDataTooltipType.Dark}
          title={
            <>
              Before you can cache connections, you need to configure your{" "}
              <a
                className="underline"
                onClick={() => {
                  toggleIsDisplayed();
                  navigate("/jobs/cacheConnection");
                }}
              >
                data warehouse.
              </a>
            </>
          }
        >
          <div>
            <PopoverItem popoverOption={item} />
          </div>
        </CDataTooltip>
      );
    } else {
      return (
        <PopoverItem
          key={item.label}
          popoverOption={item}
          toggleIsDisplayed={toggleIsDisplayed}
        />
      );
    }
  });

  return (
    <Popover
      className={"components-PopoverList"}
      placement="bottom"
      isOpen={isPopoverOpen}
      target={props.target}
      toggle={toggleIsDisplayed}
      popperClassName="no-arrow"
      trigger="legacy"
    >
      <PopoverBody className="popover-body-extra">{buttons}</PopoverBody>
    </Popover>
  );
};
