import { useFormikContext } from "formik";
import React, { useEffect } from "react";
import { CDataTextField } from "../../../../../../components/form/CDataTextField";
import { IAPIAuth } from "../../../../../../models";
import { IGlobalSettings } from "../../../../ApiConnector";

type JwtOAuthFormBodyProps = {
  globalSettings: IGlobalSettings;
  setGlobalSettings: (globalSettings: IGlobalSettings) => void;
  setUnsavedChanges: (hasUnsavedChanges: boolean) => void;
  isFlyout: boolean;
};

export function OAuthClientFormBody(props: JwtOAuthFormBodyProps) {
  const { globalSettings, setGlobalSettings, setUnsavedChanges } = props;

  const {
    values,
    handleBlur,
    handleChange: wrappedHandleChange,
  } = useFormikContext<IAPIAuth>();

  function handleChange(event: React.ChangeEvent<any>) {
    // Whenever a field changes, we need to save the changes.
    setUnsavedChanges(true);
    return wrappedHandleChange(event);
  }
  useEffect(() => {
    setGlobalSettings({
      ...globalSettings,
      authentication: values,
    });
  }, [values]); // eslint-disable-line

  return (
    <div className="oAuthClientFormBody">
      <div>
        <CDataTextField
          label="OAuth Client Id"
          required
          name="oAuthClientId"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.oAuthClientId}
        />

        <div className="helper-text mt-1">
          To authenticate with OAuth, you must register an OAuth application
          with your API service to obtain the information requested.
        </div>
      </div>

      <CDataTextField
        label="OAuth Client Secret"
        required
        name="oAuthClientSecret"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.oAuthClientSecret}
      />

      <CDataTextField
        label="OAuth Access Token URL"
        required
        name="oAuthAccessTokenUrl"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.oAuthAccessTokenUrl}
      />
    </div>
  );
}
