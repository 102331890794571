import { Col, Row } from "reactstrap";
import { EndpointModalOptions } from "../WorkspaceEditEndpointsModal";
import classnames from "classnames";
import { DisabledInputWithCopyButton } from "../../../../components/form/DisabledInputWithCopyButton";
import { useAppSelector } from "../../../../redux/hooks";

export const EndpointsModalUsernameRow = (props: {
  tab: EndpointModalOptions;
  workspaceName: string;
}) => {
  const { tab } = props;
  const email = useAppSelector((state) => state.user?.email) ?? "";
  let username = "";

  if (tab === EndpointModalOptions.VirtualSQLServer) {
    username = `${email}@${props.workspaceName}`;
  } else {
    username = email;
  }

  return (
    <Row className="mb-2">
      <Col
        className={classnames(
          {
            "sql-server-host-name":
              tab === EndpointModalOptions.VirtualSQLServer,
            "client-endpoint-adjusted-powerbi":
              tab === EndpointModalOptions.OData,
          },
          "virtual-sql-server-username",
        )}
      >
        <div className="form-field-title">Username:</div>
      </Col>
      <Col className="ps-0 client-endpoint-username">
        <DisabledInputWithCopyButton text={username} />
      </Col>
    </Row>
  );
};
