import React from "react";
import { Col, Row } from "reactstrap";
import { EndpointModalOptions } from "../WorkspaceEditEndpointsModal";
import { SettingTabs } from "../../../settings/Settings";

export const EndpointsModalLabel = (props: { tab: EndpointModalOptions }) => {
  const { tab } = props;

  const clientName =
    tab === EndpointModalOptions.VirtualSQLServer
      ? "Virtual SQL Server"
      : "OData";

  const url = "/settings?defaultTab=" + SettingTabs.AccountSettings;

  return (
    <Row className="mb-2">
      <Col>
        <span className="gray-helper-text">
          Use the settings below if connecting from a Workspace via {clientName}{" "}
          clients. You will still need a
          <a
            className="card-setting"
            href={url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            Personal Access Token{" "}
          </a>
          to use as your password during authentication.
        </span>
      </Col>
    </Row>
  );
};
