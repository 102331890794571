import { Card, CardBody, CardText, Badge } from "reactstrap";
import { BILLING_TAB_DESTINATION } from "../../../components/notification/DashboardNotificationManagerConstants";
import { UserRole } from "../../../models";
import { useAppSelector } from "../../../redux/hooks";

export interface IUpgradePlanTileProps {
  idName: string;
  imageIcon: string;
  description: string;
  showBetaBadge: boolean;
}

export default function UpgradePlanTile(props: IUpgradePlanTileProps) {
  const userRole = useAppSelector((state) => state.user.role);

  return (
    <Card
      name={props.idName}
      id={props.idName}
      data-testid="upgrade-plan-card"
      className="shadow-sm client-tools-card"
    >
      <div>
        <svg
          className="client-connectivity-icon card-img-top img-thumbnail"
          viewBox="0 0 48 48"
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
        >
          <use xlinkHref={props.imageIcon}></use>
        </svg>
      </div>

      <div>
        <CardBody className="card-body">
          <CardText
            id={`title-${props.idName}`}
            tag="h6"
            className="card-text text-truncate text-muted"
          >
            {props.description}
          </CardText>
          <div>
            <div className="action-card-container">
              <Badge
                id="beta-badge"
                hidden={!props.showBetaBadge}
                color=""
                className="client-tools-beta-badge"
              >
                Beta
              </Badge>
              <a
                href={BILLING_TAB_DESTINATION}
                data-testid="upgrade-plan-link"
                className={
                  userRole === UserRole.Admin ? "" : "no-pointer-event"
                }
                rel="noopener noreferrer"
              >
                Upgrade Plan
              </a>
            </div>
          </div>
        </CardBody>
      </div>
    </Card>
  );
}
