import { useState } from "react";
import { StepWizardModal } from "../../../components/wizard/StepWizardModal";
import StepWizardStep from "../../../components/wizard/components/StepWizardStep";
import { CachingStepNames } from "./CachingStepNames";
import { SelectConnection } from "../../datasets/components/AddAssetWizard/Steps/SelectConnection";
import { SelectSchemaTable } from "../../datasets/components/AddAssetWizard/Steps/SelectSchemaTable";
import { SelectSchemaWizardContext } from "../../datasets/components/AddAssetWizard/SelectSchemaWizardContext";
import ScheduleJobs from "./ScheduleJobs";

interface IAddCacheWizardProps {
  toggleModal: Function;
  isDisplayed: boolean;
  existingCacheJobs: string[];
}

interface ISelectedConnection {
  id: string;
  name: string;
}

function AddCacheJobWizard(props: IAddCacheWizardProps) {
  const [selectedConnection, setSelectedConnection] =
    useState<ISelectedConnection>({ id: "", name: "" });
  const [assetList, setAssetList] = useState<any[]>([]);
  const [driverName, setDriverName] = useState<string>();

  function toggleModalAndClearContext() {
    setSelectedConnection({ id: "", name: "" });
    props.toggleModal();
  }

  return (
    <SelectSchemaWizardContext.Provider
      value={{
        assetList: assetList,
        selectedConnection,
        driverName: driverName,
        setSelectedConnection,
        setAssetList: setAssetList,
        setDriverName: setDriverName,
        existingCacheJobs: props.existingCacheJobs,
      }}
    >
      <StepWizardModal
        className=""
        isDisplayed={props.isDisplayed}
        showNavigation={true}
        title={"Add Jobs"}
        toggleModal={toggleModalAndClearContext}
      >
        <StepWizardStep stepName={CachingStepNames.SelectConnection}>
          <SelectConnection
            helperText={
              "Select a connection from the list below that you would like to begin caching tables from. " +
              "\nNote: Caching is not available for databases, data warehouses, and data lakes. "
            }
            isCacheJobWizard={true}
            nextStepName={CachingStepNames.AddTables}
          />
        </StepWizardStep>
        <StepWizardStep stepName={CachingStepNames.AddTables}>
          <SelectSchemaTable
            helperText={
              "Select the datasets from your connection that you want to add to your jobs."
            }
            isCacheJobWizard={true}
            nextStepName={CachingStepNames.ScheduleJobs}
            previousStepName={CachingStepNames.SelectConnection}
            setAddAssertErrors={null}
          />
        </StepWizardStep>
        <StepWizardStep stepName={CachingStepNames.ScheduleJobs}>
          <ScheduleJobs schemas={assetList} />
        </StepWizardStep>
      </StepWizardModal>
    </SelectSchemaWizardContext.Provider>
  );
}

export default AddCacheJobWizard;
