import React, { isValidElement } from "react";
import { cssTransition, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastrBody, ToastrCloseButton } from "../components/toast/Toast";
import "../components/toast/Toast.scss";

const toastrTransition = cssTransition({
  enter: "fade-in",
  exit: "fade-out",
});

export function ToastrSuccess(title: string, body: string) {
  toast.success(<ToastrBody title={title} body={body} />, {
    autoClose: 5000,
    closeOnClick: true,
    closeButton: ToastrCloseButton,
    draggable: false,
    icon: <i className="fa fa-regular fa-check icon-style"></i>,
    pauseOnHover: true,
    progress: undefined,
    theme: "colored",
    transition: toastrTransition,
  });
}

export function ToastrWarning(title: string, body: string) {
  toast.warning(<ToastrBody title={title} body={body} />, {
    autoClose: 5000,
    closeOnClick: true,
    closeButton: ToastrCloseButton,
    draggable: false,
    icon: <i className="fa fa-solid fa-triangle-exclamation icon-style"></i>,
    pauseOnHover: true,
    progress: undefined,
    theme: "colored",
    transition: toastrTransition,
  });
}

export function ToastrError(title: string, body: any, details?: any) {
  const bodyIsValidType = typeof body === "string" || isValidElement(body);
  body = bodyIsValidType ? body : JSON.stringify(body);

  toast.dismiss();
  toast.error(<ToastrBody title={title} body={body} details={details} />, {
    autoClose: false,
    closeOnClick: false,
    closeButton: ToastrCloseButton,
    draggable: false,
    icon: <i className="fa fa-solid fa-triangle-exclamation icon-style"></i>,
    pauseOnHover: true,
    progress: undefined,
    theme: "colored",
    transition: toastrTransition,
  });
}

export function ToastrClean() {
  toast.dismiss();
}
