import {
  Table,
  Skeleton,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

const LoadingPlaceholderTable = () => {
  const cellElement = (
    <TableCell>
      <p className="card-text placeholder-glow">
        <Skeleton variant="rounded" height={21} />
      </p>
    </TableCell>
  );

  function renderRepeatedPlaceholderElement() {
    const fields: JSX.Element[] = [];
    const tableCells: JSX.Element[] = [];

    for (let col = 1; col <= 4; col++) {
      tableCells.push(cellElement);
    }

    for (let row = 1; row <= 10; row++) {
      fields.push(<TableRow>{tableCells}</TableRow>);
    }

    return fields;
  }

  return (
    <>
      <Table className="place-holder-skeleton-table">
        <TableHead>
          <TableRow>
            {cellElement}
            {cellElement}
            {cellElement}
            {cellElement}
          </TableRow>
        </TableHead>
        <TableBody>{renderRepeatedPlaceholderElement()}</TableBody>
      </Table>

      <Table>
        <TableFooter>
          <TableRow className="skeleton-footer">
            <TableCell>
              <p className="card-text placeholder-glow">
                <Skeleton variant="rounded" height={21} />
              </p>
              <p className="card-text placeholder-glow">
                <Skeleton variant="rounded" height={21} />
              </p>
            </TableCell>
            {cellElement}
          </TableRow>
        </TableFooter>
      </Table>
    </>
  );
};

export default LoadingPlaceholderTable;
