import { useContext, useEffect, useState } from "react";
import { Button, Col } from "reactstrap";

import { ConnectionType, UserRole } from "../../../../models";
import { IModalProps } from "../../../../components/CDataModal";
import { ToastrError, ToastrSuccess } from "../../../../services/toastrService";
import { RequestType } from "../../../../components/withAPI";
import { ModalContext } from "../../../../routes/ModalContext";
import { setOAuthProps } from "./OAuthFunctions";
import { ConnectionContext } from "../ConnectionContext";
import { useUserInfo } from "../../../../hooks/useUserInfo";
import { useAPI } from "../../../../components/useAPI";
import { compareStrings } from "../../../../utility/CompareStrings";
import { getSelectedAuthSchemeValue } from "./getSelectedAuthScheme";
import { isOAuthWeb } from "./isOAuthWeb";
import QuickBooksConnect from "../../../../assets/img/icons/qbo-connect.svg?react";
import QuickBooksConnectHover from "../../../../assets/img/icons/qbo-connect-hover.svg?react";
import {
  ButtonType,
  CDataButton,
} from "../../../../components/buttons/CDataButton";
import { buildBasicPropertyHierarchy } from "../ConnectionFunctions";
import { isGoogleDriver } from "../utils/google/isGoogleDriver";
import { useOAuthListener } from "./useOAuthListener";
import { useMutation } from "@tanstack/react-query";
import { validateUrl } from "../../../../api/metadata/validateUrl";

export type OAuthRequest = {
  Driver?: string;
  Props?: any;
  oauthParams?: object;
  ConnectionId?: string;
  HasUserConnectionString?: boolean;
  ConnectionType?: ConnectionType;
  UserId?: string;
  UserRole?: UserRole;
};

enum OAuthButtonText {
  SignIn = "Sign In",
  Reconnect = "Reconnect",
}

const OAuthButton = () => {
  const connectionContext = useContext(ConnectionContext);
  const modalContext = useContext(ModalContext);
  const api = useAPI();
  const userInfo = useUserInfo();

  const { startOAuthListener, cancelOAuthListener } = useOAuthListener();

  const [isReconnect, setIsReconnect] = useState<boolean>(
    connectionContext.connectionInfo?.isOauthTokenPresent ?? false,
  );
  const [oauthBtnDisabled, setOauthBtnDisabled] = useState<boolean>(false);
  const [qboHovered, setQboHovered] = useState<boolean>(false);

  const authPropertyContainsGoogle = doesAuthPropertyContainGoogle();
  const authScheme = getSelectedAuthSchemeValue(connectionContext.driverInfo);
  const isInvalid = connectionContext.oauthButtonInvalid;
  const oauthBtnText = isReconnect
    ? OAuthButtonText.Reconnect
    : OAuthButtonText.SignIn;

  const driverHasDynamicProps = checkIfDriverHasDynamicProps();

  function doesAuthPropertyContainGoogle(): boolean {
    if (compareStrings(connectionContext.driverType, "FHIR")) {
      const authProperty = connectionContext.driverInfo!.basicProps.find((t) =>
        compareStrings(t.propertyName, "connectiontype"),
      );
      if (!authProperty) return false;
      return compareStrings(authProperty.currentValue, "google");
    }

    return false;
  }

  // Manually update the isReconnect status if we clear it out via a function call
  useEffect(() => {
    setIsReconnect(
      connectionContext.connectionInfo?.isOauthTokenPresent ?? false,
    );
  }, [connectionContext.connectionInfo?.isOauthTokenPresent]);

  // For connections with dynamic properties, this waits until those properties are populated before finishing up
  useEffect(() => {
    if (driverHasDynamicProps) {
      if (connectionContext.dynamicPropsStatus === "success") {
        displaySuccess();
      }
      if (connectionContext.dynamicPropsStatus === "failure") {
        cancelOAuth();
        modalContext.toggleModal();
      }
    }
  }, [connectionContext.dynamicPropsStatus]); // eslint-disable-line

  const isOAuthWebDriver = isOAuthWeb(connectionContext.driverInfo!);

  async function clearExistingOAuth() {
    setOauthBtnDisabled(true);

    // If there's no connection ID -- i.e., the user is clicking this after signing in but before saving, we just need to clear out the OAuth data they've created
    if (!connectionContext.connectionId) {
      setOAuthProps(connectionContext, null, false);
      setOauthBtnDisabled(false);

      return;
    }

    // Call the stored procedure to delete the oauth token from the connection server, then delete it from our database.
    const data = getDriverAndProps();
    const storedProcedureSucceeded = await callDisconnectStoredProcedure(data);
    const databaseDeleteSucceeded = await deleteOauthTokenInDatabase();

    if (databaseDeleteSucceeded) {
      resetConnectionTested();
      setOAuthProps(connectionContext, null, false);
      connectionContext.setConnectionInfo({
        ...connectionContext.connectionInfo,
        isOauthTokenPresent: false,
        isTested: false,
      });

      if (storedProcedureSucceeded) {
        ToastrSuccess(
          "Successfully disconnected",
          `Your ${connectionContext.driverType} connection has been successfully disconnected.`,
        );
      } else {
        ToastrError(
          "OAuth disconnect error",
          "We received an error when attempting to delete your OAuth token from the Intuit servers. Log into your Intuit account and manually remove the authorization token to confirm completion of this process.",
        );
      }
      if (data.ConnectionId) await deleteConnectionMetadata(data.ConnectionId);
    }

    setOauthBtnDisabled(false);
  }

  async function deleteOauthTokenInDatabase() {
    const { status } = await api.callAPI(
      RequestType.Delete,
      `/account/connections/${connectionContext.connectionId}/oauthTokens`,
      "Failed to delete existing OAuth connection token",
    );

    return status === 200;
  }

  async function resetConnectionTested() {
    await api.callAPI(
      RequestType.Put,
      `/account/connection/${connectionContext.connectionId}/resetTested`,
      "",
    );
  }

  async function callDisconnectStoredProcedure(data: OAuthRequest) {
    const { status } = await api.callAPI(
      RequestType.Put,
      "account/connections/oauth/token/disconnect",
      "",
      data,
    );

    return status === 200;
  }

  async function deleteConnectionMetadata(connectionId: string) {
    await api.callAPI(
      RequestType.Delete,
      `account/connection/${connectionId}/metadata`,
      "",
    );
  }

  const { mutateAsync: validateUrlAsync } = useMutation({
    mutationKey: ["/validate-url"],
    mutationFn: (url: string) => validateUrl(url),
    meta: {
      errorMessage: "Error validating oauth url",
    },
  });

  async function setupOAuth() {
    setOauthBtnDisabled(true);

    let keepOAuthBtnDisabled = false;

    try {
      connectionContext.setDynamicPropsStatus!("incomplete"); // In case the connection has dynamic props that need to populate

      const driverAndProps = getDriverAndProps();
      const { status, payload } = await api.callAPI(
        RequestType.Post,
        `/oauth/${driverAndProps.Driver}/authorizationUrl`,
        "Failed to get OAuth authorization URL due to the following error:",
        driverAndProps,
      );

      const body = payload;

      if (status === 200) {
        //Validate oauthUrl for NetSuite driver before opening it in new window
        if (compareStrings(connectionContext.driverInfo.name, "NetSuite")) {
          const oauthBaseUrl = new URL(body.oauthUrl).origin;
          const response = await validateUrlAsync(oauthBaseUrl);
          if (!response.isValid) {
            ToastrError(
              "Invalid Account Id",
              "Please enter a valid Account ID before signing in to authorize your connection.",
            );
            return;
          }
        }

        startOAuthListener(body.callbackId, (oauthData) =>
          oauthMessage(oauthData, body.passthroughParameters),
        );

        // Open a new tab where the user can complete the OAuth sign-in flow.
        window.open(body.oauthUrl, "_blank");

        // Show the OAuth modal.
        const modal = {
          title: `Waiting for ${connectionContext.driverType} Credentials`,
          body: (
            <text>
              Please complete the sign-in flow in the other tab in order to set
              up access to your {connectionContext.driverType} account. If you
              are not automatically taken to a new tab,
              <a
                className="card-setting"
                onClick={() => window.open(body.oauthUrl, "_blank")}
              >
                {" "}
                click here.
              </a>
            </text>
          ),
          secondaryButton: (
            <Button
              color="secondary"
              onClick={() => {
                cancelOAuth();
                modalContext.toggleModal();
              }}
            >
              Cancel
            </Button>
          ),
          modalSize: "md",
          displayToggleCloseButton: false,
          displayed: true,
          close: cancelOAuth,
        } as IModalProps;
        modalContext.setModal(modal);
        keepOAuthBtnDisabled = true;
      }
    } finally {
      setOauthBtnDisabled(keepOAuthBtnDisabled);
    }
  }

  async function cancelOAuth() {
    cancelOAuthListener();
    setOauthBtnDisabled(false);
  }

  function showXeroSignInButton(name: string) {
    const driverName = name?.toLowerCase();
    return driverName?.includes("xero");
  }

  function isQuickBooksButton(name: string) {
    const driverName = name?.toLowerCase();
    return driverName?.includes("quickbooksonline");
  }

  // Event handler for oauthChannel.onmessage. Receives an object with query string parameters from the OAuth callback.
  async function oauthMessage(
    qp: Record<string, unknown>,
    oauthPassthroughParams: Record<string, unknown>,
  ) {
    // Get connection settings, and add OAuth parameters to it. Also add any OAuth passthrough parameters.
    const data = getDriverAndProps();
    data.oauthParams = { ...qp, ...oauthPassthroughParams };
    // Get OAuth settings.
    const { status, payload } = await api.callAPI(
      RequestType.Post,
      `/oauth/${data.Driver}/token`,
      "Failed to get OAuth settings due to the following error:",
      data,
    );

    if (status === 200) {
      setOAuthProps(connectionContext, payload, true);
      connectionContext.setOauthButtonInvalid?.(false);
    }

    // If the connection doesn't have dynamic properties to populate, call the success function now.
    if (!driverHasDynamicProps) {
      displaySuccess();
    }
  }

  function checkIfDriverHasDynamicProps(): boolean {
    const basicProperties = buildBasicPropertyHierarchy(
      connectionContext.driverInfo.basicProps,
    );

    return basicProperties.some(
      (property) => property.valuesQuery && property.valuesQuery.length > 0,
    );
  }

  function displaySuccess() {
    // Close the channel, hide the modal, and re-enable the button.
    cancelOAuth();
    modalContext.toggleModal();

    ToastrSuccess(
      "Your authentication was successful",
      "Click on Save & Test to establish the Connection",
    );
  }

  function getOAuthButton() {
    const driverName = connectionContext.driverType;
    let button;
    switch (true) {
      case isGoogleDriver(driverName as string):
      case authPropertyContainsGoogle:
        button = (
          <div
            className={`google-row ${oauthBtnText === OAuthButtonText.Reconnect ? "google-row-adjust" : ""}`}
            onClick={setupOAuth}
            hidden={
              !isGoogleDriver(connectionContext.driverType!) &&
              !authPropertyContainsGoogle
            }
          >
            <Col className="p-0">
              <Button
                className="google-button-wrapper"
                name="setupOAuth"
                id="setupOAuth"
                type="button"
                disabled={oauthBtnDisabled}
                data-testid="button-setup-oauth-google"
                aria-label={`${oauthBtnText} with Google`}
              ></Button>
              <span className="signin-text">{oauthBtnText} with Google</span>
            </Col>
          </div>
        );
        break;
      case showXeroSignInButton(driverName as string):
        button = (
          <div
            className="xero-row"
            hidden={!showXeroSignInButton(connectionContext.driverType!)}
          >
            <Col className="p-0">
              <Button
                className="xero-button"
                name="setupOAuth"
                id="setupOAuth"
                onClick={setupOAuth}
                disabled={oauthBtnDisabled}
                data-testid="button-setup-oauth-xero"
                aria-label="Connect to Xero"
              ></Button>
            </Col>
          </div>
        );
        break;
      case isQuickBooksButton(driverName as string):
        button = (
          <div
            className="quickbooks-online-row"
            hidden={!isQuickBooksButton(connectionContext.driverType!)}
          >
            <Col className="p-0">
              {isReconnect ? (
                <CDataButton
                  buttonType={ButtonType.PrimaryOutline}
                  disabled={oauthBtnDisabled}
                  name="disconnectOauth"
                  id="disconnectOauth"
                  onClick={clearExistingOAuth}
                  data-testid="button-disconnect-oauth-qbo"
                  aria-label="Disconnect from QuickBooks"
                >
                  Disconnect from QuickBooks
                </CDataButton>
              ) : (
                <Button
                  className="quickbooks-online-connect-button p-0"
                  name="setupOAuth"
                  id="setupOAuth"
                  onClick={setupOAuth}
                  onMouseOver={() => setQboHovered(true)}
                  onMouseOut={() => setQboHovered(false)}
                  disabled={oauthBtnDisabled}
                  data-testid="button-setup-oauth-qbo"
                  aria-label="Connect to QuickBooks"
                >
                  {qboHovered ? (
                    <QuickBooksConnectHover />
                  ) : (
                    <QuickBooksConnect />
                  )}
                </Button>
              )}
            </Col>
          </div>
        );
        break;
      default: {
        let buttonType: ButtonType = ButtonType.PrimaryOutline;
        if (oauthBtnText === OAuthButtonText.Reconnect && !isInvalid) {
          buttonType = ButtonType.SecondaryOutline;
        } else if (isInvalid) {
          buttonType = ButtonType.DangerOutline;
        }

        button = (
          <>
            <CDataButton
              hidden={
                (isGoogleDriver(connectionContext.driverType!) ||
                  authPropertyContainsGoogle) &&
                showXeroSignInButton(connectionContext.driverType!)
              }
              name="setupOAuth"
              id="setupOAuth"
              buttonType={buttonType}
              onClick={setupOAuth}
              disabled={oauthBtnDisabled}
              data-testid="button-setup-oauth"
            >
              {oauthBtnText === OAuthButtonText.Reconnect ? (
                <i className="fa fa-redo align-middle no-pointer-event" />
              ) : (
                <i className="fa fa-sign-in-alt align-middle no-pointer-event" />
              )}
              <span className="ms-2">{oauthBtnText}</span>
            </CDataButton>
            {isInvalid && (
              <div className="invalid-feedback-text mt-1 ">
                Sign in is required before saving & testing your connection.
              </div>
            )}
          </>
        );
      }
    }

    return button;
  }

  function getDriverAndProps(): OAuthRequest {
    const form = new FormData(
      connectionContext.formRef?.current as HTMLFormElement,
    );
    const data: OAuthRequest = {};
    data.Driver = connectionContext.driverType;
    data.Props = {};
    data.ConnectionId = connectionContext.connectionId;
    data.ConnectionType =
      connectionContext.connectionInfo?.existingConnectionType;
    data.HasUserConnectionString = connectionContext.hasUserConnectionString;
    data.UserId = userInfo.Self.id;
    data.UserRole = userInfo.Self.role;
    for (const pair of form) {
      // If the pair is related to permissions, ignore it
      if (
        pair[0].match(
          /^connectionName|0:SELECT|1:INSERT|2:UPDATE|3:DELETE|4:EXECUTE$/,
        )
      )
        continue;
      if (pair[0].match(/AuthScheme/)) {
        data.Props[pair[0]] = authScheme;
        continue;
      }
      data.Props[pair[0]] = pair[1];
    }
    return data;
  }

  return (
    <>
      {isOAuthWebDriver ? (
        <div data-testid="oauth-buttons" className="oauth-container my-4">
          <Col xs="auto">{getOAuthButton()}</Col>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default OAuthButton;
