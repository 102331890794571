import React from "react";
import { Input } from "reactstrap";

export interface ISearchBarProps {
  setTextFilter: (text: string) => void;
  textFilter: string;
}

export const SearchBar = (props: ISearchBarProps) => {
  return (
    <>
      <div className="search-filter-bar">
        <i className="fa fa-regular fa-magnifying-glass align-middle me-2" />
        <Input
          autoFocus={true}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            props.setTextFilter(event.target.value)
          }
          placeholder="Search..."
          value={props.textFilter}
        />
      </div>
    </>
  );
};
