import { useAPI } from "../components/useAPI";
import { RequestType } from "../components/withAPI";
import {
  addAccountInfo,
  addCacheConnection,
  addDriversList,
  addSubscription,
  addUsage,
} from "../redux/actions";
import { useFeatureFlags } from "./useFeatureFlags";
import { useNavigate } from "react-router-dom";
import chameleon from "@chamaeleonidae/chmln";
import { IUser } from "../models/Users/IUser";
import { ISubscriptionInfo } from "../models/Billing/ISubscriptionInfo";
import { FeatureId } from "../models/Features/FeatureId";
import { UserRole } from "../models/Users/UserRole";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { ISessionInfo } from "../bffmodels/ISessionInfo";
import { IAccount, IBillingUsage, IConnection, IDriverList } from "../models";
import { store } from "../redux/store";

function registerUserWithChameleon(
  user: IUser | null | undefined,
  subscription: ISubscriptionInfo,
) {
  // If we haven't initialized chameleon (e.x. in local dev)
  // then just bail out.
  if (window.chmln == null) {
    return;
  }

  if (user != null) {
    const isConnectSpreadsheet =
      subscription?.limits?.availableFeatureIds.includes(
        FeatureId.ConnectForSpreadsheets,
      );
    try {
      chameleon.identify(user.id, {
        accountId: user.accountId,
        // True if the user is an OEM user, either an OEM admin or a regular OEM user.
        isOem:
          user.role === UserRole.OEMAdmin || user.role === UserRole.ServiceUser,
        isConnectSpreadsheet: isConnectSpreadsheet,
        role: user.role != null ? UserRole[user.role] : undefined,
      });
    } catch (err) {
      // Do not blow the app up if chameleon doesn't work.
      console.error("An error occurred registering a user with chameleon");
      console.error(err);
    }
  }
}

export function useSessionState() {
  const api = useAPI();
  const dispatch = useAppDispatch();
  const featureFlags = useFeatureFlags();
  const navigate = useNavigate();
  const drivers = useAppSelector((state) => state.driversList?.drivers);

  const getUserSessionInfo = async () => {
    const { status, payload, error } = await api.callAPI<ISessionInfo>(
      RequestType.Get,
      "/users/session",
      "Failed to get session info due to the following error:",
    );
    if (status === 200) {
      featureFlags.setTrait(
        "company",
        (payload!.accountInfo as IAccount).organization,
      );
      dispatch(addSubscription(payload!.subscriptionInfo as ISubscriptionInfo));
      dispatch(addDriversList(payload!.driverList as IDriverList));
      dispatch(addUsage(payload!.billingUsage as IBillingUsage));
      dispatch(addAccountInfo(payload!.accountInfo as IAccount));
      dispatch(addCacheConnection(payload!.cacheConnection as IConnection));
      // Using `store.getState()` here because the `await` call above means our state is out of date by the time we get here.
      const user = store.getState()?.user;
      registerUserWithChameleon(
        user,
        payload!.subscriptionInfo as ISubscriptionInfo,
      );
    } else {
      const isOemUser = window.location.pathname.startsWith("/oem/user/");
      // We cannot send OEM users to login or sign-up pages, they get short lived access tokens.
      if (!isOemUser) {
        if (status === 401) {
          window.location.href = encodeURI(
            window.location.origin + "/account/login",
          );
        } else {
          if (error && error.message && error.message.includes("signing up")) {
            const redirectUri = "/auth/user-sign-up";
            window.location.href = encodeURI(
              window.location.origin + redirectUri,
            );
          } else {
            navigate("/auth/500");
          }
        }
      } else {
        navigate("/auth/500");
      }
    }
  };

  async function initializeSessionState(skipIfAlreadyLoaded = false) {
    // Just see if drivers have already loaded and skip loading again.
    // Using drivers here just because drivers are only set in this function and no where else.
    if (skipIfAlreadyLoaded === true && drivers != null) {
      return;
    }
    await getUserSessionInfo();
  }

  const modalAndAPIs = {
    initializeSessionState: initializeSessionState,
  };

  return modalAndAPIs;
}
