import React, { Component, ReactNode } from "react";
import BootstrapTable, { SelectRowProps } from "react-bootstrap-table-next";

import { Button, Spinner, UncontrolledTooltip } from "reactstrap";

import { ToastrError } from "../../../services/toastrService";
import { CDataComponent } from "../../../utility/CDataComponent";
import { getDriverIcon } from "../../../components/drivers/DriverIconFactory";
import { SelectTableRow } from "./SelectTables";
import { ODataContext } from "./ODataContext";
import {
  RequestType,
  withAPI,
  IAPI,
  BackendType,
} from "../../../components/withAPI";
import { getSettingsPageSubscriptionTab } from "../../../utility/SubscriptionProvider";
import {
  withRouter,
  ComponentWithRouterProps,
} from "../../../components/withRouter";
import { checkPremiumDisabled } from "../OData";

export async function getTables(
  this: Component<{ callAPI: IAPI["callAPI"] }>,
  catalog: string,
  schema?: string,
) {
  this.setState({ processingRequest: true });
  const searchParams = new URLSearchParams({ catalogName: catalog });
  if (schema) {
    searchParams.append("schemaName", schema);
  }
  const url = `/tables?${searchParams}`;

  const { status, payload } = await this.props.callAPI(
    RequestType.Get,
    url,
    "",
  );
  if (status === 200) {
    const result = payload;
    if (!result.error) {
      const schema = result.results[0].schema;
      const rows = result.results[0].rows;
      const tableNameIndex = schema.findIndex(
        (property: any) => property.columnName === "TABLE_NAME",
      );
      const tableConnectionIndex = schema.findIndex(
        (property: any) => property.columnName === "TABLE_CATALOG",
      );
      const tableTypeIndex = schema.findIndex(
        (property: any) => property.columnName === "TABLE_TYPE",
      );
      const tableSchemaIndex = schema.findIndex(
        (property: any) => property.columnName === "TABLE_SCHEMA",
      );
      const tables = rows
        .map((table: any) => ({
          fullName: `${table[tableConnectionIndex]}.${table[tableSchemaIndex]}.${table[tableNameIndex]}`,
          connectionName: table[tableConnectionIndex],
          schemaName: table[tableSchemaIndex],
          defaultTableName: table[tableNameIndex],
          tableType: table[tableTypeIndex],
        }))
        .filter(
          (table: any) =>
            table.tableType === "TABLE" || table.tableType === "VIEW",
        )
        .filter((table: any) => {
          return !(this.context as any).alreadyExistingTables.some(
            (existingTable: any) => {
              return table.fullName === existingTable.fullName;
            },
          );
        });

      // If there are no tables to add, but the connection has at least one table, then all tables have been added.
      if (tables.length === 0) {
        if (rows.length > 0) {
          return [];
        } else {
          return null;
        }
      }

      this.setState({ tables: tables, processingRequest: false });
      return tables;
    } else {
      // TODO: Clean up this error handling
      this.setState({ processingRequest: false });
      return null;
    }
  } else {
    // TODO: Clean up this error handling
    this.setState({ processingRequest: false });
    return null;
  }
}

interface ISelectConnectionProps extends IAPI, ComponentWithRouterProps {
  catalog?: any[];
  nextStep?: () => void;
  currentStep?: number;
  setModalBackButton: (backButton: JSX.Element | null) => void;
  setModalNextButton: (backButton: JSX.Element | null) => void;
  setIsModalFooterDisplayed: (isDisplayed: boolean) => void;
  setEntityType: (entityType: string) => void;
}
interface ISelectConnectionState {
  tables: SelectTableRow[];
  selectedConnection: {
    connectionName: string;
    connectionType: string;
    connectionTypeNiceName: string;
  } | null;
  processingRequest: boolean;
  goToTables: () => void;
  fetchSchemas: () => Promise<void>;
  modalTitle: string;
  modalBody: ReactNode;
  modalConfirmButton: ReactNode | undefined;
  modalCancelButton: ReactNode | undefined;
  modalFooterHidden: boolean;
  modalSize: string;
  modalDisplayed: boolean;
  displayToggleCloseButton: boolean;
  connectionsList: any[];
  selectedConnectionId: string;
}

class SelectConnection
  extends Component<ISelectConnectionProps, ISelectConnectionState>
  implements CDataComponent
{
  getTables: (
    this: Component<{ callAPI: IAPI["callAPI"] }>,
    catalog: string,
    schema?: string,
  ) => Promise<any>;
  constructor(props: ISelectConnectionProps) {
    super(props);
    this.toggleModal = this.toggleModal.bind(this);
    this.goToTables = this.goToTables.bind(this);
    this.fetchSchemas = this.fetchSchemas.bind(this);
    this.getTables = getTables.bind(this);
    this.state = {
      tables: [],
      selectedConnection: null,
      processingRequest: false,
      goToTables: this.goToTables,
      fetchSchemas: this.fetchSchemas,
      modalTitle: "",
      modalBody: <React.Fragment />,
      modalConfirmButton: <React.Fragment />,
      modalCancelButton: <React.Fragment />,
      modalFooterHidden: false,
      modalSize: "lg",
      modalDisplayed: false,
      displayToggleCloseButton: true,
      connectionsList: [],
      selectedConnectionId: "",
    };
  }

  getComponentName() {
    return "pages-odata-Wizard-SelectConnection";
  }

  static contextType = ODataContext;
  context!: React.ContextType<typeof ODataContext>;

  toggleModal() {
    this.setState({ modalDisplayed: !this.state.modalDisplayed });
  }

  async componentDidMount() {
    await this.getConnectionsList();
    this.props.setModalBackButton(null);
    this.props.setModalNextButton(
      <Button
        key="selectTablesButton"
        color="primary"
        className="ms-auto"
        onClick={this.fetchSchemas}
        disabled
      >
        Next
      </Button>,
    );
    this.props.setIsModalFooterDisplayed(true);
    this.props.setEntityType("Tables");
  }

  async getConnectionsList() {
    const url = "/account/connections";
    const { status, payload } = await this.props.callAPI(
      RequestType.Get,
      url,
      "Failed to retrieve connections list due to the following error.",
    );

    if (status === 200) {
      const connectionsList = payload;
      this.setState({ connectionsList: connectionsList.connections });
    }
  }

  async clearConnectionMetadata(selectedConnectionId: string) {
    const clearMetadataCacheUrl = `/account/connection/${selectedConnectionId}/metadata`;

    const { status, error } = await this.props.callAPI(
      RequestType.Delete,
      clearMetadataCacheUrl,
      "",
    );
    if (status !== 200) {
      const err = error;
      this.showErrorModal(
        "Failed to clear connection metadata due to the following error.",
        err.error,
      );
    }
  }

  showErrorModal(text: string, error: any) {
    this.setState({
      modalTitle: "Error",
      modalBody: (
        <text>
          {text}
          <br />
          <b>{error.code}</b>: {error.message}
        </text>
      ),
      modalConfirmButton: null,
      modalCancelButton: (
        <Button color="primary" onClick={this.toggleModal}>
          Close
        </Button>
      ),
      modalFooterHidden: false,
      displayToggleCloseButton: true,
      modalSize: "lg",
      modalDisplayed: true,
    });
  }

  async fetchSchemas() {
    const selectedConnection = this.state.selectedConnection!;
    const selectedConnectionData = this.state.connectionsList.find(
      (x) => x.name === selectedConnection.connectionName,
    );
    if (selectedConnectionData) {
      this.clearConnectionMetadata(selectedConnectionData.id);
    }
    const catalog = selectedConnection.connectionName;

    try {
      const { status, payload } = await this.props.callAPI(
        RequestType.Get,
        `/schemas?catalogName=${encodeURIComponent(catalog)}`,
        "Failed to get user defined connection list due to the following error:",
        null,
        BackendType.QueryRouter,
      );

      if (status === 200) {
        const data = await payload;
        if (data.error) {
          ToastrError("Error fetching results", data.error.message);
          return;
        }
        const schemas = data.results[0].rows;
        const schemaArray = schemas.map((el: any) => {
          return el[1];
        });

        this.context.selectedConnection = {
          name: selectedConnection.connectionName,
          type: selectedConnection.connectionType,
        };
        this.context.schemas = schemaArray;
        this.props.nextStep!();
      }
    } catch (err) {
      ToastrError("Error fetching results", err);
    }
  }

  async goToTables() {
    const selectedConnection = this.state.selectedConnection!;
    const tableInfo = await this.getTables(selectedConnection.connectionName);

    if (tableInfo) {
      // Check truthiness to rule out null results
      if (tableInfo.length !== 0) {
        // If there are still tables that have not been added
        this.context.selectedConnection = {
          name: selectedConnection.connectionName,
          type: selectedConnection.connectionType,
        };
        this.context.availableTables = tableInfo;
        this.props.nextStep!();
      } else {
        // If all tables have already been added
        this.setState({ processingRequest: false });
        ToastrError(
          "Error",
          "All tables for this connection have already been added to OData.",
        );
      }
    } else {
      // If a null result is returned, i.e. cannot obtain tables one way or another
      this.setState({ processingRequest: false });
      ToastrError(
        "Error",
        "Unable to get tables associated with this connection. Please check your data source.",
      );
    }
  }

  render() {
    const connections = this.props.catalog!.map((connection) => {
      return {
        connectionName: connection[0],
        connectionTypeNiceName: connection[1],
        connectionType: connection[2],
      };
    });

    const connectionsColumns = [
      {
        dataField: "connectionName",
        text: "Connection Name",
        sort: false,
      },
      {
        dataField: "connectionTypeNiceName",
        text: "Connection Type",
        sort: false,
      },
      {
        dataField: "connectionType",
        text: "Connection Type",
        sort: false,
        hidden: true,
      },
    ];

    const connectionsData = connections.map((connection) => {
      const premiumConnectorDisabled = checkPremiumDisabled(
        connection.connectionType,
      );
      const premiumConnectorTooltip = premiumConnectorDisabled ? (
        <UncontrolledTooltip
          placement="top"
          target="premiumConnection"
          trigger="hover"
          onClick={(e: { stopPropagation: () => any }) => e.stopPropagation()}
          autohide={false}
        >
          Premium Connectors are not available with your subscription.
          <br />
          <a
            className="upgrade-link"
            onClick={() =>
              this.props.router.navigate(
                "/settings?defaultTab=" + getSettingsPageSubscriptionTab(),
              )
            }
          >
            Upgrade
          </a>
          &nbsp;your plan today to continue using this connection.
        </UncontrolledTooltip>
      ) : null;

      return {
        connectionName: !premiumConnectorDisabled ? (
          connection.connectionName
        ) : (
          <div className={"premiumConnectorDisabled"}>
            {connection.connectionName}
          </div>
        ),
        connectionType: connection.connectionType,
        connectionTypeNiceName: (
          <div
            id={premiumConnectorDisabled ? "premiumConnection" : ""}
            className={
              premiumConnectorDisabled ? "premiumConnectorDisabled" : ""
            }
          >
            {getDriverIcon(connection.connectionType, "connection-icon me-2")}
            {connection.connectionTypeNiceName}
            {premiumConnectorTooltip}
          </div>
        ),
      };
    });

    const selectConnectionRow: SelectRowProps<any> = {
      mode: "radio",
      clickToSelect: true,
      bgColor: "#dee2e6",
      onSelect: (row) => {
        const premiumConnectorDisabled = checkPremiumDisabled(
          row.connectionType,
        );
        this.setState({ selectedConnection: row });
        this.props.setModalNextButton(
          <Button
            key="selectTablesButton"
            disabled={premiumConnectorDisabled}
            color="primary"
            className="ms-auto"
            onClick={this.fetchSchemas}
          >
            Next
          </Button>,
        );
      },
    };

    const odataTable = (
      <BootstrapTable
        bootstrap4
        id="odataConnectionsList"
        classes="odata-connections-list mt-0 mb-0"
        bordered={false}
        keyField="connectionName"
        columns={connectionsColumns}
        data={connectionsData}
        selectRow={selectConnectionRow}
      />
    );

    return (
      <div className={this.getComponentName()}>
        <div hidden={!this.state.processingRequest}>
          <div className="loading-background" />
          <Spinner className="spinner-border loading-spinner" color="info" />
        </div>
        <p className="mt-3 mb-1">Select a connection from the list below.</p>
        {odataTable}
      </div>
    );
  }
}

export default withAPI(withRouter(SelectConnection));
