import { ChangeEvent, useContext, useEffect, useState } from "react";
import Loader from "../../components/Loader";
import { Formik } from "formik";
import CDataIcon from "../../assets/img/icons/CloudLogo.svg?react";
import { logoutUser } from "../../routes/AuthorizeContext";
import * as Yup from "yup";

import { Button } from "reactstrap";
import { Col, Form, Row } from "react-bootstrap";

import reCAPTCHA from "./components/ReCaptcha";
import { tosLastUpdated } from "../../utility/TermsOfServiceLastUpdated";
import {
  withRouter,
  ComponentWithRouterProps,
} from "../../components/withRouter";
import { IAccountLoginResponse, ICountry } from "../../models/";
import { countryList } from "../../utility/Countries";
import { ModalContext } from "../../routes/ModalContext";
import { isFreeEmailDomain } from "./components/EmailUtils";
import { getSalesEmailAddress } from "../../utility/LocalizedEmailAddresses";
import { useAuthentication } from "../../hooks/useAuthentication";
import { prependApiPrefix } from "../../api/prependApiPrefix";
import { useNavigate } from "react-router-dom";

type ISignUpProps = ComponentWithRouterProps;

function SignUp(props: ISignUpProps) {
  const [email, setEmail] = useState<string>();
  const [isSSO, setIsSSO] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const salesEmailAddress = getSalesEmailAddress();

  const modalContext = useContext(ModalContext);
  const auth = useAuthentication();
  const navigate = useNavigate();

  const companyAffiliatedErrorMessage = "not-company-affiliated-email";

  useEffect(() => {
    getUserEmail();
    getSSOStatus();
    setIsLoading(false);
  }, []);

  const recaptcha = new reCAPTCHA("submit");

  function isSpace(currentValue: any) {
    if (currentValue.target.value.indexOf(" ") >= 0) {
      return true;
    }
    return false;
  }

  function getUserEmail() {
    const queryParams = new URLSearchParams(window.location.search);
    const userEmail = (queryParams.get("email") ?? "").replace(" ", "+");
    setEmail(userEmail);
  }

  function getSSOStatus() {
    const queryParams = new URLSearchParams(window.location.search);
    const isSSO = queryParams.get("isSSO");
    setIsSSO(isSSO === "true");
  }

  async function handleValidSubmit(values: any) {
    const token: string = await recaptcha.getToken();
    values.TosVersion = tosLastUpdated;
    values.IsSSO = isSSO;
    values.RecaptchaToken = token;

    const createAccountUserForm = new FormData();
    Object.keys(values).forEach((key) =>
      createAccountUserForm.append(key, values[key]),
    );
    if (!auth.IsLoading && auth.User)
      createAccountUserForm.append("UserExists", "true");

    const requestOptions = {
      method: "POST",
      body: createAccountUserForm,
    } as RequestInit;

    const response = await fetch(
      prependApiPrefix("/account/createAccountUser"),
      requestOptions,
    );
    if (response.status === 200) {
      const res: IAccountLoginResponse = await response.json();
      const accountInfo = res.account;

      //don't assume the account type if the user already existed
      if (auth.IsLoading || !auth.User)
        (accountInfo as any).accounttype = "admin";

      if (values.IsSSO || (!auth.IsLoading && auth.User)) {
        navigate("/initial-setup?signup#select-initial-connection", {
          state: { signUpFormValues: accountInfo },
        });
        return;
      } else if (res.userInSSO) {
        navigate("/auth/verify-email?signup#select-initial-connection", {
          state: { signUpFormValues: accountInfo },
        });
        return;
      } else {
        navigate("/auth/verify-email?signup", {
          state: { signUpFormValues: accountInfo },
        });
        return;
      }
    } else {
      const err = await response.json();
      if (err.error.code === "USER_INVITED")
        props.router.navigate("/auth/user-sign-up");
      modalContext.showError(
        "Failed to create user account due to the following error:",
        err.error,
      );
    }
  }

  function renderSignUpCard() {
    const countryOptions = countryList.map((country: ICountry) => (
      <option key={country.threeLetterCode} value={country.threeLetterCode}>
        {country.name}
      </option>
    ));

    const initialFormValues = {
      PhoneNumber: "",
      ContactFirstName: "",
      ContactLastName: "",
      Organization: "",
      Country: "USA",
      ContactEmail: email,
      TosAccepted: false,
    };

    const validationSchema = Yup.object().shape({
      PhoneNumber: Yup.string()
        .max(20, "Phone number must be a max of 20 characters")
        .matches(
          /^\+?[0-9.()-]{0,19}$/,
          "Phone number must contain numbers, dashes parentheses and periods",
        ),
      ContactFirstName: Yup.string()
        .min(1, "First name must be between 1 and 100 characters")
        .max(100, "First name must be between 1 and 100 characters")
        .required("This is a required field"),
      ContactLastName: Yup.string()
        .min(1, "Last name must be between 1 and 100 characters")
        .max(100, "Last name must be between 1 and 100 characters")
        .required("This is a required field"),
      Organization: Yup.string()
        .min(
          1,
          "Organization name must be 2-50 ASCII letters, numbers, hyphens, and underscores; and must begin and end with a letter/number.",
        )
        .max(
          100,
          "Organization name must be 2-50 ASCII letters, numbers, hyphens, and underscores; and must begin and end with a letter/number.",
        )
        .matches(
          /^[a-zA-Z0-9][ a-zA-Z0-9_-]{0,48}[a-zA-Z0-9]$/,
          "Account name must be 2-50 ASCII letters, numbers, hyphens, underscores, and spaces; and must begin and end with a letter/number.",
        )
        .required("This is a required field"),
      ContactEmail: Yup.string()
        .email("Must be a valid email address")
        .test({
          name: "freeEmailDomainCheck",
          message: companyAffiliatedErrorMessage,
          test: (value) => !isFreeEmailDomain(value) || !!isSSO,
        })
        .max(255)
        .required("This is a required field"),
      TosAccepted: Yup.boolean().oneOf([true]),
    });

    return (
      <>
        <span className="d-block d-sm-none">
          <CDataIcon className="cdata-logo" aria-description="CData Logo" />
        </span>
        <div className="m-sm-4">
          <h3 className="d-block d-sm-none bold-text">
            All Your Data. All Your Apps. Connected through 1 Platform.
          </h3>
          <p className="lead mr-5 sub-heading">
            <i className="fa fa-check-circle align-middle me-2 text-primary" />
            Start your 30 Day Free Trial Today!
          </p>
        </div>
        <div className="m-sm-4">
          <Formik
            initialValues={initialFormValues}
            validationSchema={validationSchema}
            onSubmit={handleValidSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Form.Group className="mb-3" as={Col} md={6}>
                    <Form.Label className="required">First Name</Form.Label>
                    <Form.Control
                      size="lg"
                      name="ContactFirstName"
                      placeholder="Enter your first name"
                      value={values.ContactFirstName}
                      isInvalid={Boolean(
                        touched.ContactFirstName && errors.ContactFirstName,
                      )}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {!!touched.ContactFirstName && (
                      <Form.Control.Feedback type="invalid">
                        {errors.ContactFirstName?.toString()}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3" as={Col} md={6}>
                    <Form.Label className="required">Last Name</Form.Label>
                    <Form.Control
                      size="lg"
                      name="ContactLastName"
                      placeholder="Enter your last name"
                      value={values.ContactLastName}
                      isInvalid={Boolean(
                        touched.ContactLastName && errors.ContactLastName,
                      )}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {!!touched.ContactLastName && (
                      <Form.Control.Feedback type="invalid">
                        {errors.ContactLastName?.toString()}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className="mb-3" as={Col} md={6}>
                    <Form.Label className="required">Email</Form.Label>
                    <Form.Control
                      size="lg"
                      type="email"
                      name="ContactEmail"
                      placeholder="Enter your email"
                      value={values.ContactEmail}
                      isInvalid={Boolean(
                        touched.ContactEmail && errors.ContactEmail,
                      )}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      className={email !== "" ? "gray-background" : ""}
                      readOnly={email !== ""}
                    />
                    {!!touched.ContactEmail && (
                      <Form.Control.Feedback type="invalid">
                        {errors.ContactEmail?.toString() ===
                        companyAffiliatedErrorMessage ? (
                          <>
                            Use a company-affiliated email or contact{" "}
                            <a
                              href={`mailto:${salesEmailAddress}`}
                              className="overage-hyperlink"
                            >
                              {salesEmailAddress}
                            </a>{" "}
                            for further assistance.
                          </>
                        ) : (
                          errors.ContactEmail?.toString()
                        )}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3" as={Col} md={6}>
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      size="lg"
                      name="PhoneNumber"
                      placeholder="Enter a phone number for your account"
                      value={values.PhoneNumber}
                      isInvalid={Boolean(
                        touched.PhoneNumber && errors.PhoneNumber,
                      )}
                      onBlur={handleBlur}
                      onChange={(e: ChangeEvent<any>) => {
                        if (!isSpace(e)) {
                          handleChange(e);
                        }
                      }}
                    />
                    {!!touched.PhoneNumber && (
                      <Form.Control.Feedback type="invalid">
                        {errors.PhoneNumber?.toString()}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3" as={Col} md={6}>
                    <Form.Label className="required">Organization</Form.Label>
                    <Form.Control
                      size="lg"
                      name="Organization"
                      placeholder="Enter a name for your account"
                      value={values.Organization}
                      isInvalid={Boolean(
                        touched.Organization && errors.Organization,
                      )}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {!!touched.Organization && (
                      <Form.Control.Feedback type="invalid">
                        {errors.Organization?.toString()}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3" as={Col} md={6}>
                    <Form.Label className="required">Country</Form.Label>
                    <Form.Control
                      size="lg"
                      as="select"
                      name="Country"
                      value={values.Country}
                      onBlur={handleBlur}
                      onChange={(e: ChangeEvent<any>) => {
                        handleChange(e);
                      }}
                      className="form-select"
                    >
                      {countryOptions}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group className="checked-background" as={Col} md={6}>
                    <Form.Check
                      name="TosAccepted"
                      value={tosLastUpdated}
                      label={
                        <>
                          I agree to the{" "}
                          <a
                            href="https://cloud.cdata.com/docs/terms-of-service.html"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-primary"
                          >
                            Terms and Conditions
                          </a>
                        </>
                      }
                      onBlur={handleBlur}
                      className={"checked-background"}
                      onChange={handleChange}
                    />
                    {touched.TosAccepted && errors.TosAccepted && (
                      <div>
                        <small className="text-danger">
                          You must agree to the terms and conditions
                        </small>
                      </div>
                    )}
                  </Form.Group>
                </Row>
                <Form.Group className="text-center mt-4">
                  <Button
                    type="submit"
                    color="primary"
                    className="full-width submit-button"
                  >
                    Create Account
                  </Button>
                </Form.Group>
              </Form>
            )}
          </Formik>
        </div>

        <small className="mt-0 m-sm-4">
          Already have an account?
          <a
            onClick={() => logoutUser()}
            target="_blank"
            rel="noopener noreferrer"
            className="text-primary"
          >
            {" "}
            Log In
          </a>
        </small>
      </>
    );
  }

  const contents = isLoading ? (
    <div>
      <Loader />
    </div>
  ) : (
    renderSignUpCard()
  );

  return <div className="pages-auth">{contents}</div>;
}

export default withRouter(SignUp);
