// Please keep this list alphabetized to make it easier to use and edit.
const ClientConnectivityInfo = {
  airbyte: {
    databaseServer: "tds.cdata.com",
    port: "14333",
  },
  amazonQuickSight: {
    databaseServer: "tds.cdata.com",
    port: "14333",
  },
  apos: {
    connectionString:
      "jdbc:sqlserver://tds.cdata.com:14333;databaseName=[ConnectionName]",
  },
  appSheet: {
    server: "tds.cdata.com,14333",
  },
  azureAnalysisServices: {
    server: "tds.cdata.com,14333",
  },
  azureDataFactory: {
    server: "tds.cdata.com,14333",
  },
  bubble: {
    connectionString: "sql://[username]:[password]@tds.cdata.com:14333",
  },
  grafana: {
    connectionString: "tds.cdata.com:14333",
  },
  looker: {
    remoteHost: "tds.cdata.com",
    port: "14333",
  },
  mendix: {
    dataSourceRelativePath: "api/odata/service",
    environmentLocation: "https://cloud.cdata.com/",
  },
  metabase: {
    hostName: "tds.cdata.com",
    port: "14333",
  },
  microsoftPowerApps: {
    server: "tds.cdata.com,14333",
  },
  microsoftPowerAutomate: {
    hostName: "tds.cdata.com",
    port: "14333",
  },
  microStrategy: {
    hostName: "tds.cdata.com",
    port: "14333",
  },
  mode: {
    hostName: "tds.cdata.com",
    port: "14333",
  },
  nintex: {
    server: "tds.cdata.com,14333",
  },
  nodejs: {
    hostName: "tds.cdata.com",
    port: "14333",
  },
  outSystems: {
    server: "tds.cdata.com,14333",
  },
  powerBi: {
    Url: "https://cloud.cdata.com/api/odata/service",
  },
  polyBase: {
    server: "tds.cdata.com:14333",
  },
  qlikSense: {
    server: "tds.cdata.com",
    port: "14333",
  },
  redash: {
    connectionString: "sql://[username]:[password]@tds.cdata.com:14333",
  },
  retool: {
    connectionString: "sql://[username]:[password]@tds.cdata.com:14333",
  },
  salesforceLightning: {
    Url: "https://cloud.cdata.com/api/odata/service",
  },
  sapAnalyticsCloud: {
    dataServiceUrl: "https://cloud.cdata.com/api/odata/service",
  },
  tableauCloud: {
    server: "tds.cdata.com,14333",
  },
  thoughtSpot: {
    hostName: "tds.cdata.com",
    port: "14333",
  },
  workato: {
    hostName: "tds.cdata.com",
    port: "14333",
  },
  zohoAnalytics: {
    serverName: "tds.cdata.com",
    port: "14333",
  },
};

export default ClientConnectivityInfo;
