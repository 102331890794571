export const isFreeEmailDomain = (email: string | undefined): boolean => {
  const freeEmailDomains: string[] = [
    "gmail.com",
    "hotmail.com",
    "outlook.com",
    "yahoo.com",
    "aol.com",
    "aim.com",
    "inbox.com",
    "icloud.com",
    "mail.com",
    "protonmail.com",
    "proton.me",
    "yandex.com",
    "tutanota.com",
  ];

  const domain: string | undefined = email?.split("@")[1]?.toLowerCase();

  const urlSearchParams = new URLSearchParams(window.location.search);
  if (urlSearchParams.get("emailmode") === "1") return false;

  return domain ? freeEmailDomains.includes(domain) : false;
};
