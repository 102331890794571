import React, { useContext } from "react";
import { Button } from "reactstrap";
import { IModalProps } from "./CDataModal";
import ReactRouterPrompt from "react-router-prompt";
import { ModalContext } from "../routes/ModalContext";

interface PromptWithModalProps {
  when: boolean;
  navigate: (path: string) => void;
  customMessage?: string;
  customTitle?: string;
}

const PromptWithModal = ({
  when,
  customMessage,
  customTitle,
}: PromptWithModalProps) => {
  const modalContext = useContext(ModalContext);

  const handleConfirmNavigationClick = () => {
    modalContext.toggleModal();
    document.body.className = "";
    document.body.style.overflow = "auto";
  };

  const handleBlockedNavigation = (
    isActive: boolean,
    onConfirm: any,
    onCancel: any,
  ) => {
    if (!isActive) return <></>;
    const message =
      customMessage ??
      "If you leave this page, any unsaved changes will be lost. Are you sure you want to leave?";

    const modal = {
      title: customTitle ?? "Unsaved Changes",
      body: <div>{message}</div>,
      secondaryButton: (
        <Button
          color="dark"
          onClick={() => {
            onCancel();
            modalContext.toggleModal();
            document.body.className = "";
            document.body.style.overflow = "auto";
          }}
        >
          Cancel
        </Button>
      ),
      primaryButton: (
        <Button
          color="danger"
          onClick={() => {
            onConfirm();
            handleConfirmNavigationClick();
          }}
        >
          Leave Page
        </Button>
      ),
      displayToggleCloseButton: true,
      displayed: true,
      modalSize: "lg",
      close: () => {
        onCancel();
        document.body.className = "";
        document.body.style.overflow = "auto";
      },
    } as IModalProps;
    modalContext.setModal(modal);
    return <></>;
  };

  return (
    <ReactRouterPrompt when={when}>
      {({ isActive, onConfirm, onCancel }) =>
        handleBlockedNavigation(isActive, onConfirm, onCancel)
      }
    </ReactRouterPrompt>
  );
};

export default PromptWithModal;
