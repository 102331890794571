import { useState } from "react";
import { getDriverIcon } from "../../../../components/drivers/DriverIconFactory";
import { CDataTypography } from "../../../../components/text/CDataTypography";
import Grid from "@mui/material/Unstable_Grid2";
import { TextWithOverflowTooltip } from "../../../../components/text/TextWithOverflowTooltip";
import { QueryBuilderCard } from "./tabs/Builder/components/QueryBuilderCard";
import { QueryBuilderCardHeader } from "./tabs/Builder/components/QueryBuilderCardHeader";
import { QueryBuilderAddButton } from "./tabs/Builder/components/QueryBuilderAddButton";
import { OpenQueryBuilderModal } from "./OpenQueryBuilderModal";
import { IConnection } from "../../../../models";
import { IQueryTab } from "../Tabs/useQueryTabs";

export const QueryBuilderHeader = (props: {
  tab: IQueryTab;
  connectionList: IConnection[];
}) => {
  const { tab } = props;
  const [isQueryBuilderModalOpen, setIsQueryBuilderModalOpen] =
    useState<boolean>(false);

  const queryBuilderModal = isQueryBuilderModalOpen && (
    <OpenQueryBuilderModal
      connectionList={props.connectionList}
      isModalDisplayed={isQueryBuilderModalOpen}
      setIsModalDisplayed={setIsQueryBuilderModalOpen}
    />
  );

  if (tab.driver === "") {
    return (
      <div className="query-builder-add-table">
        {queryBuilderModal}
        <CDataTypography
          variant="typography-variant-headline-4"
          className="mb-3"
        >
          Query Builder
        </CDataTypography>
        <QueryBuilderCard>
          <QueryBuilderCardHeader title={<span>Table </span>}>
            <QueryBuilderAddButton
              onClick={() => {
                setIsQueryBuilderModalOpen(true);
              }}
            >
              Add Table
            </QueryBuilderAddButton>
          </QueryBuilderCardHeader>
          <CDataTypography>
            Select a table or view from a Connection to begin creating a query
            without writing code.
          </CDataTypography>
        </QueryBuilderCard>
      </div>
    );
  }

  return (
    <>
      <CDataTypography variant="typography-variant-headline-4" className="mb-3">
        Query Builder
      </CDataTypography>
      <Grid className="header-card mb-3" container columns={2} spacing={2}>
        {/* Title row */}
        <Grid xs={1} className="title-cell-text">
          Connection
        </Grid>
        <Grid xs={1} className="title-cell-text">
          Table
        </Grid>

        {/* Body row */}
        <Grid xs={1}>
          <div className="content-cell">
            {getDriverIcon(tab.driver!, "connection-icon me-2")}
            <TextWithOverflowTooltip fullText={tab.connectionName!} />
          </div>
        </Grid>
        <Grid xs={1} className="content-cell">
          <TextWithOverflowTooltip fullText={tab.tableName!} />
        </Grid>
      </Grid>
    </>
  );
};
