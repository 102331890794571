export function getApiUrlEndpoint() {
  return window.location.origin.includes("localhost")
    ? window.location.origin
    : window.location.origin + "/api";
}

export function getOdataEndpoint() {
  return window.location.origin.includes("localhost")
    ? window.location.origin + "/odata/service"
    : window.location.origin + "/api/odata/service";
}

export function getOdataEndpointWorkspace(workspace: string) {
  return window.location.origin.includes("localhost")
    ? window.location.origin + "/odata/" + workspace
    : window.location.origin + "/api/odata/" + workspace;
}

export function getTdsHostName() {
  let tdsHostName = window.location.origin + "/api";
  if (window.location.origin.includes("dev")) {
    tdsHostName = "tds-dev.clouddataos.com";
  } else if (window.location.origin.includes("staging")) {
    tdsHostName = "tds-staging.clouddataos.com";
  } else if (window.location.origin === "https://cloud.cdata.com") {
    tdsHostName = "tds.cdata.com";
  }
  return tdsHostName;
}

export function getTdsPortNumber() {
  return window.location.origin.includes("localhost")
    ? window.location.port
    : "14333";
}

export function getOpenAPIURL(workspace: string, version: string) {
  const requestUrl = getOdataEndpointWorkspace(workspace);
  const domain = window.location.origin.includes("localhost")
    ? "https://localhost:44351"
    : `${window.location.origin}/api`;
  return `${domain}/openapi/${encodeURIComponent(version)}/${encodeURIComponent(workspace)}?requesturl=${encodeURIComponent(requestUrl)}`;
}
