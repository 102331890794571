import { IConnectionList, UserRole } from "../../models";
import { cdataFetch } from "../cdataFetch";

export async function getConnections(
  userType: UserRole,
  userId: string,
  header?: Record<string, string>,
) {
  const url =
    userType === UserRole.Query
      ? `/account/connections/forUser/${userId}`
      : "/account/connections";

  return cdataFetch<IConnectionList>({
    method: "GET",
    headers: header,
    url: url,
  });
}
