import { doesDerivedViewReferenceDerivedView } from "./doesDerivedViewReferenceDerivedView";

export function validateQuery(query: string): {
  isValid: boolean;
  message: string;
} {
  const MAX_LENGTH = 10000;

  if (!query || query.trim() === "") {
    return { isValid: false, message: "Query cannot be an empty string" };
  }

  if (query.length > MAX_LENGTH) {
    return {
      isValid: false,
      message: `Query cannot exceed ${MAX_LENGTH} characters`,
    };
  }

  if (doesDerivedViewReferenceDerivedView(query)) {
    return {
      isValid: false,
      message:
        "Cannot create a derived view using a query from a previously created derived view. Please modify your query to use the original source data and try again.",
    };
  }

  return { isValid: true, message: "" };
}
