import { Suspense } from "react";
import { CardBody, Card, Col, Row, Button } from "reactstrap";
import Loader from "../../../components/Loader";
import TitleBadge from "../../../components/TitleBadge";
import { lazyRetry } from "../../../utility/lazyRetry";
import { getSalesEmailAddress } from "../../../utility/LocalizedEmailAddresses";

const AIWizard = lazyRetry(() =>
  import("./AIWizard").then((module) => {
    return { default: module.AIWizard };
  }),
);

const AIWizardFlyout = (props: {
  isVisible: boolean;
  closeWizard: () => void;
}) => {
  return (
    <>
      <Col className="components-AI-Flyout" hidden={!props.isVisible}>
        <div className={"p-0"} data-testid="ai-flyout">
          <Card className={"fixed-frame"}>
            <CardBody className="flyout-height">
              {/* Title for the AI Generator with the close button */}
              <Row
                className="bottom-divider mx-3 mt-3 mb-0"
                data-testid="section-title"
              >
                <Col className="title-row p-0">
                  <span className="title-text px-0 pt-0 pb-3">
                    <h3 className="m-0">AI Generator</h3>
                  </span>
                  <span className="beta-badge px-0 pt-0 pb-3">
                    <TitleBadge
                      name="Beta"
                      isVisible={true}
                      tooltip={
                        <>
                          This feature is currently in beta. If you require
                          assistance or have feedback, please contact{" "}
                          <a
                            href={`mailto:${getSalesEmailAddress()}`}
                            className="support-hyperlink"
                          >
                            {getSalesEmailAddress()}
                          </a>
                          .
                        </>
                      }
                    />
                  </span>
                  <span className="close-btn-container px-0 pt-0 pb-3">
                    <Button
                      className="transparent-button"
                      onClick={() => {
                        props.closeWizard();
                      }}
                      data-testid="button-close-flyout"
                    >
                      <div className="d-flex align-items-center">
                        <i className="fa-regular fa-xmark fa-xs me-2" />
                        <span className="x-btn me-1">Close</span>
                      </div>
                    </Button>
                  </span>
                </Col>
              </Row>

              {/* Lazy load the AIWizard body. We do this to split the very large js-tiktoken library into its own javascript file. */}
              <Suspense fallback={<Loader />}>
                <AIWizard {...props} />
              </Suspense>
            </CardBody>
          </Card>
        </div>
      </Col>
    </>
  );
};

export default AIWizardFlyout;
