import { cdataFetch } from "../../../api/cdataFetch";
import { IQuerySearchCriteria, IQuerySearchResult } from "../../../models";

export async function getJobErrorLogs(queryId: string) {
  const querySearchCriteria: IQuerySearchCriteria = {
    failed: true,
    queryId: [queryId],
    credential: [],
    queryType: [],
    drivers: [],
    connectionNames: [],
  };

  return cdataFetch<IQuerySearchResult>({
    method: "POST",
    url: "/log/query/list",
    body: querySearchCriteria,
  });
}
