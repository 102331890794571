import { useEffect, useMemo, useState } from "react";
import { RequestType } from "../../../components/withAPI";
import { IDatasetsTree, IDatasetsTreeWorkspaceNode } from "../../../models";
import {
  Column,
  ExpandedState,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getExpandedRowModel,
  ColumnDef,
  flexRender,
} from "@tanstack/react-table";
import { useAPI } from "../../../components/useAPI";

interface ISelectAssetsTableProps {
  pageName: string;
  setMoveDestination: (
    workspaceId: string,
    folderId: string,
    name: string,
  ) => void;
  isAssetMove: boolean;
}

// TODO: Rename this to select destingation workspace modal or something along those lines
const SelectAssetsTable = (props: ISelectAssetsTableProps): any => {
  function getComponentName() {
    return "select-assets-table";
  }

  const api = useAPI();

  const [data, setData] = useState<IDatasetsTreeWorkspaceNode[]>([]);
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const [selectedRow, setSelectedRow] = useState<string | null>(null);

  const getWorkspaces = async () => {
    const { status, payload } = await api.callAPI(
      RequestType.Get,
      "/workspaces/fullTree/forCurrentUser",
      "Failed to get workspace items",
    );
    if (status === 200) {
      const list = payload as IDatasetsTree;
      //If not an asset move remove the children
      if (!props.isAssetMove) {
        list.workspaces!.map(
          (child: IDatasetsTreeWorkspaceNode) => (child.children = []),
        );
      }
      setData(list.workspaces as IDatasetsTreeWorkspaceNode[]);
    }
  };

  useEffect(() => {
    getWorkspaces();
  }, []); // eslint-disable-line

  const columns = useMemo<ColumnDef<IDatasetsTreeWorkspaceNode>[]>(
    () => [
      {
        header: "Workspaces",
        columns: [
          {
            accessorFn: (row) => row.name,
            accessorKey: "name",
            header: ({ table }) => (
              <>
                <div className="header-container">
                  <div className="header">Workspaces</div>
                  {props.isAssetMove ? (
                    <div className="expand-button-container">
                      <button
                        {...{
                          onClick: table.getToggleAllRowsExpandedHandler(),
                        }}
                        className="expand-collapse-button expand-all-button"
                      >
                        {table.getIsAllRowsExpanded() ? (
                          <i className="fa fa-chevron-square-right expand-all-icon"></i>
                        ) : (
                          <i className="fa fa-chevron-square-down expand-all-icon"></i>
                        )}
                        {"  "}
                        {table.getIsAllRowsExpanded()
                          ? "Collapse All"
                          : "Expand All"}
                      </button>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </>
            ),
            cell: ({ row, getValue }) => (
              <div
                style={{
                  paddingLeft: `${row.depth * 1.6}rem`,
                }}
              >
                <div className="list-of-workspaces">
                  {row.getCanExpand() && props.isAssetMove ? (
                    <button
                      {...{
                        onClick: row.getToggleExpandedHandler(),
                      }}
                      className="expand-collapse-button"
                    >
                      {row.getIsExpanded() ? (
                        <i className="fa fa-chevron-down"></i>
                      ) : (
                        <i className="fa fa-chevron-right"></i>
                      )}
                    </button>
                  ) : (
                    ""
                  )}{" "}
                  {
                    <div
                      className={`${row.getCanExpand() ? "workspace-folders-row" : "workspace-row"}`}
                    >
                      {getValue()}
                    </div>
                  }
                </div>
              </div>
            ),
          },
        ],
      },
    ],
    [props.isAssetMove],
  );

  const handleRowClick = async (row: any) => {
    if (row.original.workspaceId) {
      props.setMoveDestination(
        row.original.workspaceId,
        row.original.id,
        row.original.name,
      );
    } else {
      props.setMoveDestination(row.original.id, null!, row.original.name);
    }

    setSelectedRow(row.id);
  };

  const table = useReactTable({
    data,
    columns,
    state: {
      expanded,
    },
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.children,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    filterFromLeafRows: true,
    maxLeafRowFilterDepth: 1,
  });

  const workspaceTableClassName = `select-table-desc ${props.pageName === "dataExplorer" ? "data-exp" : "wkspc"}`;

  return (
    <div className={getComponentName()}>
      <table>
        <thead>
          <span className={workspaceTableClassName}></span>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <>
                    {header.column.getCanFilter() ? (
                      <div>
                        <Filter column={header.column} />
                      </div>
                    ) : null}
                    <th key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <div>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                        </div>
                      )}
                    </th>
                  </>
                );
              })}
            </tr>
          ))}
        </thead>
        <div className="scroll-bar-tanstack">
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr
                  key={row.id}
                  className={`${row.depth === 0 ? "schema-row" : ""}
                     ${selectedRow === row.id ? "selected" : ""}
                     ${"cursor"}
                     "workspace-folders-row"`}
                  onClick={() => handleRowClick(row)}
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </div>
      </table>
    </div>
  );
};

function Filter({ column }: { column: Column<any, any> }) {
  const columnFilterValue = column.getFilterValue();

  return (
    <>
      <i className="fa-regular fa-magnifying-glass align-middle me-2 search-glass"></i>
      <input
        type="text"
        value={(columnFilterValue ?? "") as string}
        onChange={(e) => column.setFilterValue(e.target.value)}
        placeholder={"Search..."}
        className="border shadow rounded search-bar"
      />
    </>
  );
}

export default SelectAssetsTable;
