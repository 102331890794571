import { Button, Card, Col, Container, Row, Spinner } from "reactstrap";
import { useContext, useEffect, useRef, useState } from "react";
import EditWorkspaceCard from "./EditWorkspaceCard";
import Sidepane from "../Sidepane";
import { useParams, useNavigate } from "react-router-dom";
import WorkspaceEditPane, {
  IWorkspacePaneFormValues,
} from "./WorkspaceEditPane";
import { RequestType } from "../../../components/withAPI";
import { useAPI } from "../../../components/useAPI";
import { IWorkspace } from "../../../models";
import Loader from "../../../components/Loader";
import FullScreenDashboardContent from "../../../components/FullScreenDashboardContent";
import TitleWithBackButton from "../../../components/TitleWithBackButton";
import { DatasetsBreadcrumbs } from "../../../components/breadcrumb/DatasetBreadcrumbs";
import { BreadcrumbType } from "../../../models/Datasets/BreadcrumbType";
import { PermissionFlag } from "../../../utility/PermissionFlag";
import { AuthorizeContext } from "../../../components/guards/UserAuthenticationGuard";
import { UserRole } from "../../../models/Users/UserRole";
import {
  DatasetBulkAddErrorAccordion,
  IDatasetBulkAddError,
} from "../components/AddAssetWizard/DatasetBulkAddErrorAccordion";
import { useAppSelector } from "../../../redux/hooks";
import { ToastrError, ToastrSuccess } from "../../../services/toastrService";
import { ModalContext } from "../../../routes/ModalContext";
import { IModalProps } from "../../../components/CDataModal";
import { FormikProps } from "formik";
import { ErrorTable, IErrorTableRow } from "../../../components/ErrorTable";

const WorkspaceDetails = () => {
  const formRef = useRef<FormikProps<IWorkspacePaneFormValues>>(null);
  const userAccount = useContext(AuthorizeContext);
  const isAdminUser = userAccount && userAccount.role === UserRole.Admin;
  const modalFunctions = useContext(ModalContext);
  const [workspaceDetails, setWorkspaceDetails] = useState<IWorkspace | null>(
    null,
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [addAssetErrors, setAddAssertErrors] = useState<IDatasetBulkAddError[]>(
    [],
  );
  const [deleteAssetErrors, setDeleteAssetErrors] = useState<IErrorTableRow[]>(
    [],
  );
  const { workspaceId } = useParams();
  const api = useAPI();
  const userId = useAppSelector((state) => state.user.id);
  const navigate = useNavigate();
  const [formState, setFormState] = useState({
    dirty: false,
    hasWorkspaceNameError: false,
  });

  const getWorkspacePermissions = async () => {
    let pageAllowed = false;
    const { status, payload } = await api.callAPI(
      RequestType.Get,
      `/users/${userId}`,
      "Failed to user info due to the following error:",
    );

    if (status === 200) {
      const workspacePermissions = payload.workspacePermissions;

      workspacePermissions.forEach(
        (workspaceItem: { workspaceId: string; opsAllowed: number }) => {
          if (workspaceItem.workspaceId === workspaceId) {
            pageAllowed =
              (PermissionFlag.SELECT & workspaceItem.opsAllowed) > 0 ||
              (PermissionFlag.INSERT & workspaceItem.opsAllowed) > 0 ||
              (PermissionFlag.UPDATE & workspaceItem.opsAllowed) > 0 ||
              (PermissionFlag.DELETE & workspaceItem.opsAllowed) > 0 ||
              (PermissionFlag.EXECUTE & workspaceItem.opsAllowed) > 0;
          }
        },
      );
    }

    if (!pageAllowed) {
      navigate("/datasets");
    }
  };

  useEffect(() => {
    const getWorkspace = async () => {
      const { status, payload } = await api.callAPI(
        RequestType.Get,
        `/workspaces/${workspaceId}`,
        "Error fetching workspace.",
      );

      if (status === 200) {
        const workspace = payload as IWorkspace;
        setWorkspaceDetails(workspace);
      }
      setLoading(false);
    };
    if (!isAdminUser) {
      getWorkspacePermissions();
    }

    getWorkspace();
  }, []); // eslint-disable-line

  const handleSaveClick = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  async function handleDeleteWorkspace() {
    const { status, payload } = await api.callAPI(
      RequestType.Delete,
      `/workspaces/${workspaceId}`,
      "Failed to delete workspace due to the following error:",
    );
    if (status === 200) {
      ToastrSuccess(
        "Workspace deleted successfully",
        `${workspaceDetails?.name} was successfully deleted`,
      );
      modalFunctions.toggleModal();
      navigate("/datasets");
    } else {
      const err = payload;
      ToastrError("Error deleting workspace", err);
    }
  }
  function openDeleteWorkspaceModal(event: React.MouseEvent) {
    event.stopPropagation();
    const message =
      "You are about to delete this Workspace. Are you sure you want to proceed?";
    const confirmButton = (
      <Button color="danger" onClick={handleDeleteWorkspace}>
        <div className="icon no-pointer-event" />
        Delete
      </Button>
    );
    const modal = {
      title: "Delete Workspace",
      body: <div>{message}</div>,
      primaryButton: confirmButton,
      secondaryButton: (
        <Button color="secondary" onClick={modalFunctions.toggleModal}>
          Close
        </Button>
      ),
      displayToggleCloseButton: true,
      displayed: true,
    } as IModalProps;
    modalFunctions.setModal(modal);
  }

  if (loading || !workspaceDetails) {
    return <Loader />;
  }
  return (
    <FullScreenDashboardContent>
      <div hidden={!isProcessing}>
        <div className="loading-background" />
        <Spinner className="spinner-border loading-spinner" color="info" />
      </div>
      <Container fluid className="mt-0 pages-datasets-WorkspaceDetails">
        <Row className="h-100 w-100 ms-0 flex-nowrap">
          <Col className="edit-workspace-card-col">
            <DatasetsBreadcrumbs
              workspaceId={workspaceId}
              page={BreadcrumbType.Workspace}
              folderName={workspaceDetails.name}
            />

            <Row>
              <Col>
                <TitleWithBackButton
                  title={workspaceDetails.name}
                  customNavigate={() => navigate("/datasets")}
                />
              </Col>
              <Col className="text-end">
                {isAdminUser ? (
                  <>
                    <Button
                      type="button"
                      color="secondary"
                      onClick={(e) => openDeleteWorkspaceModal(e)}
                      data-testid="button-delete"
                    >
                      <i className="fa fa-solid fa-xmark fa-sm" /> Delete
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      data-testid="button-save"
                      className="ms-1"
                      onClick={handleSaveClick}
                      disabled={
                        !formState.dirty || formState.hasWorkspaceNameError
                      }
                    >
                      <i className="fa fa-solid fa-floppy-disk color-white fa-sm" />{" "}
                      Save Changes
                    </Button>
                  </>
                ) : null}
              </Col>
            </Row>
            {addAssetErrors.length > 0 && (
              <DatasetBulkAddErrorAccordion errors={addAssetErrors} />
            )}
            {deleteAssetErrors.length > 0 && (
              <ErrorTable
                title={`${deleteAssetErrors.length} Errors`}
                entityName="Data Asset"
                data={deleteAssetErrors}
                className="mb-4"
              />
            )}
            <Card className="edit-workspaces-card">
              <EditWorkspaceCard
                workspaceId={workspaceId!}
                workspaceName={workspaceDetails.name!}
                setAddAssertErrors={setAddAssertErrors}
                setDeleteAssetErrors={setDeleteAssetErrors}
              />
            </Card>
          </Col>
          <Col className="sidepane-col">
            <Sidepane>
              <WorkspaceEditPane
                workspaceDetails={workspaceDetails}
                setWorkspaceDetails={setWorkspaceDetails}
                setIsProcessing={setIsProcessing}
                ref={formRef}
                setFormState={setFormState}
              />
            </Sidepane>
          </Col>
        </Row>
      </Container>
    </FullScreenDashboardContent>
  );
};

export default WorkspaceDetails;
