import React from "react";

import { IDataAssetConnectionBulkCreateError } from "../../../../models/Datasets/IDataAssetConnectionBulkCreateError";
import { IDataAssetDerivedViewBulkCreateError } from "../../../../models/Datasets/IDataAssetDerivedViewBulkCreateError";
import { ErrorTable, IErrorTableRow } from "../../../../components/ErrorTable";
import { IDerivedView } from "../../../../models";

export type IDatasetBulkAddError =
  | (IDataAssetDerivedViewBulkCreateError & { derivedView: IDerivedView })
  | IDataAssetConnectionBulkCreateError;

export interface IDatasetBulkAddErrorAccordionProps {
  errors: IDatasetBulkAddError[];
}

/**
 * An accordion of errors, shown when at least one derived view or table fails
 * to be added in the Virtual Dataset page.
 */
export const DatasetBulkAddErrorAccordion = (
  props: IDatasetBulkAddErrorAccordionProps,
) => {
  const { errors } = props;

  const errorsForTable = errors.map<IErrorTableRow>((error: any) => {
    if ("derivedView" in error) {
      return {
        name: error.derivedView?.name ?? "",
        errorMessage: error.errorMessage,
      };
    } else {
      return {
        name: `[${error.schemaName}].[${error.tableName}]`,
        errorMessage: error.errorMessage,
      };
    }
  });

  return (
    <div className="component-DataSetBulkAddErrorAccordion">
      <ErrorTable
        title={`${errors.length} Datasets Failed`}
        entityName="Dataset"
        data={errorsForTable}
      />
    </div>
  );
};
