import { useMutation, useQuery } from "@tanstack/react-query";
import { IScheduledQuery } from "../../../../models/ScheduledQueries/IScheduledQuery";
import { getConnections } from "../../../connections/connectionList/getConnections";
import { useUserInfo } from "../../../../hooks/useUserInfo";
import { ConnectionType, IConnection, UserRole } from "../../../../models";
import { CDataAutocomplete } from "@cdatacloud/component-library";
import { useEffect, useState } from "react";
import { QueryBuilderConnectionDropdownOption } from "../../../dataExplorer/RightPanel/QueryBuilder/tabs/Builder/components/QueryBuilderConnectionDropdownOption";
import {
  getSchemasForConnection,
  ISchemaMetadata,
} from "../../../../api/metadata/getSchemasForConnection";
import { CDataTypography } from "../../../../components/text/CDataTypography";
import { useFormikContext } from "formik";
import { CDataFormTextField } from "../../../../components/form/CDataFormTextField";

interface IDestinationSelectors {
  setUnsavedChanges: (unsavedChanges: boolean) => void;
}

export const DestinationSelectors = (props: IDestinationSelectors) => {
  const { setUnsavedChanges } = props;
  const userInfo = useUserInfo();

  const [selectedConnection, setSelectedConnection] = useState<IConnection>();
  const [schemas, setSchemas] = useState<string[]>();

  const { errors, values, touched, setValues, setTouched } =
    useFormikContext<IScheduledQuery>();

  const allowedDestinationConnections = [
    "GoogleBigQuery",
    "Databricks",
    "PostgreSQL",
    "Redshift",
    "Smartsheet",
    "Snowflake",
  ];

  // Query to load the connections on mount.
  const { data: connectionListData, isLoading: isLoadingConnections } =
    useQuery({
      queryKey: ["/account/connections"],
      queryFn: () =>
        getConnections({
          IsAdmin: userInfo.IsInRole(UserRole.Admin),
          CurrentUserId: userInfo.Self.id,
        }),
      meta: {
        errorMessage:
          "Failed to get connection list due to the following error:",
      },
    });

  // Destination connections must be on the whitelist and cannot have UDC enabled
  const destinationConnections =
    connectionListData?.connections?.filter(
      (connection) =>
        allowedDestinationConnections.includes(connection.driver ?? "") &&
        connection.connectionType !== ConnectionType.UserDefined,
    ) ?? [];

  // Once the connections are fetched, if we are editing a query, set the current connection to the matching one in valid destination list.
  useEffect(() => {
    if (values.destinationConnection && !isLoadingConnections) {
      const matchingValidConnection = destinationConnections.find(
        (connection) => connection.id === values.destinationConnection,
      );

      if (matchingValidConnection) {
        setSelectedConnection(matchingValidConnection);
        loadSchemasForConnectionAsync(matchingValidConnection?.name ?? "");
      } else {
        // If there's no matching valid connection, e.g. the user deleted the connection or changed it to UDC, clear the fields so the user has to re-fill them.
        setValues({
          ...values,
          destinationConnection: "",
          destinationConnectionName: "",
          destinationSchema: "",
          destinationTable: "",
        });
      }
    }
  }, [connectionListData]); // eslint-disable-line

  const {
    mutateAsync: loadSchemasForConnectionAsync,
    isPending: isLoadingSchemas,
  } = useMutation({
    mutationKey: ["/schemas"],
    mutationFn: getSchemasForConnection,
    onSuccess: (schemaMetadata) => {
      processConnectionSchemas(schemaMetadata);
    },
    meta: {
      errorMessage:
        "Failed to retrieve schema list list due to the following error:",
    },
  });

  const processConnectionSchemas = (schemaMetadata: ISchemaMetadata[]) => {
    const schemaList = schemaMetadata.map((s) => s.schema);
    setSchemas(schemaList);

    // If there is only one schema available, auto-select it.
    if (schemaList?.length === 1) {
      setValues({ ...values, destinationSchema: schemaList[0] });
    }
  };

  const onConnectionChange = (newConnection: IConnection) => {
    setSelectedConnection(newConnection);
    setSchemas([]);

    setValues({
      ...values,
      destinationConnectionName: newConnection.name!,
      destinationConnection: newConnection.id!,
      destinationSchema: "",
    });

    // Reset touched status for schema & table so they don't show errors
    setTouched({
      ...touched,
      destinationSchema: false,
    });
    loadSchemasForConnectionAsync(newConnection.name!);
    setUnsavedChanges(true);
  };

  const onSchemaChange = (newSchema: string) => {
    setValues({
      ...values,
      destinationSchema: newSchema,
    });
    setUnsavedChanges(true);
  };

  const onTableChange = (newTable: string) => {
    setValues({
      ...values,
      destinationTable: newTable,
    });
    setUnsavedChanges(true);
  };

  return (
    <>
      <div className="mb-3">
        <CDataTypography
          variant="typography-variant-body-medium"
          className="required mb-2"
        >
          Destination Connection
        </CDataTypography>
        <CDataAutocomplete<IConnection>
          key={"destination-connection-selector"}
          id="destinationConnection"
          name="destinationConnection"
          isLoading={isLoadingConnections}
          disableWholeSelector={isLoadingSchemas}
          options={destinationConnections}
          dropdownLabel={(option) => {
            return <QueryBuilderConnectionDropdownOption option={option} />;
          }}
          getOptionLabel={(option) => (option.name ? option.name : "")}
          handleChange={(_event, newConnection) =>
            onConnectionChange(newConnection)
          }
          isOptionEqualToValue={(option, value) => {
            return option.id === value.id;
          }}
          isInvalid={
            !!errors.destinationConnection && touched.destinationConnection
          }
          onBlur={() => {
            setTouched({ ...touched, destinationConnection: true });
          }}
          selectedValue={selectedConnection ?? null}
        />
        {touched.destinationConnection && !!errors.destinationConnection ? (
          <CDataTypography
            variant="typography-variant-helper-text"
            color="typography-color-danger"
          >
            {errors.destinationConnection}
          </CDataTypography>
        ) : (
          <CDataTypography variant="typography-variant-helper-text">
            Only connections available to be set as a destination will be
            listed.
          </CDataTypography>
        )}
      </div>
      <div className="mb-3">
        <CDataTypography
          variant="typography-variant-body-medium"
          className="required mb-2"
        >
          Destination Schema
        </CDataTypography>
        <CDataAutocomplete
          key={"destination-schema-selector"}
          id="destinationSchema"
          name="destinationSchema"
          isLoading={isLoadingSchemas}
          options={schemas ?? []}
          dropdownLabel={(option) => option}
          getOptionLabel={(option) => option}
          handleChange={(_event, newSchema) => onSchemaChange(newSchema)}
          disableWholeSelector={
            isLoadingConnections ||
            !selectedConnection ||
            !schemas ||
            schemas.length <= 1
          }
          isInvalid={!!errors.destinationSchema && touched.destinationSchema}
          onBlur={() => {
            setTouched({ ...touched, destinationSchema: true });
          }}
          selectedValue={values.destinationSchema ?? null}
        />
        {touched.destinationSchema && !!errors.destinationSchema && (
          <CDataTypography
            variant="typography-variant-helper-text"
            color="typography-color-danger"
          >
            {errors.destinationSchema}
          </CDataTypography>
        )}
      </div>
      <div>
        <CDataTypography
          variant="typography-variant-body-medium"
          className="required mb-2"
        >
          Destination Table
        </CDataTypography>
        <CDataFormTextField
          name="destinationTable"
          onChange={(e) => onTableChange(e.target.value)}
          value={values.destinationTable}
          sx={{ height: "36px", alignContent: "center" }}
        />
      </div>
    </>
  );
};
