import { Route, Routes } from "react-router-dom";
import { Jobs } from "./Jobs";
import EditCacheJob from "./EditJob/EditCacheJob";
import CacheConnection from "./CacheConnection/CacheConnection";
import Page404 from "../auth/Page404";
import { EditScheduledQuery } from "./EditJob/EditScheduledQuery";

const JobsRoutes = () => {
  return (
    <Routes>
      {/* /jobs route */}
      <Route path="/" element={<Jobs />} />

      <Route path="editCacheJob/:cacheJobId" element={<EditCacheJob />} />

      <Route
        path="editScheduledQuery/:scheduledQueryId"
        element={<EditScheduledQuery />}
      />

      <Route path="cacheConnection" element={<CacheConnection />} />

      <Route path="*" element={<Page404 showBranding={false} />} />
    </Routes>
  );
};

export default JobsRoutes;
