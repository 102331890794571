import { ICustomReportParameter } from "../../../models";
import { compareStrings } from "../../../utility/CompareStrings";

export function parseReportNameParameter(
  parameters: ICustomReportParameter[],
): string | undefined {
  for (let i = 0; i < parameters.length; i++) {
    if (compareStrings(parameters[i].name!, "tablename")) {
      return parameters[i].name;
    } else if (parameters[i].name?.toLowerCase() === "reportname") {
      return parameters[i].name;
    }
  }
}

export function sortAndShiftParameters(parameters: ICustomReportParameter[]) {
  const sortedParameters = sortParameters(parameters);
  const shiftedParameters =
    shiftDefaultParameterValuesToSelect(sortedParameters);
  return shiftedParameters;
}

function sortParameters(
  parameters: ICustomReportParameter[],
): ICustomReportParameter[] {
  return parameters.sort(function (a: any, b: any) {
    const aCatOrdinal = parseInt(a.catOrdinal);
    const bCatOrdinal = parseInt(b.catOrdinal);
    const aOrdinal = parseInt(a.ordinal);
    const bOrdinal = parseInt(b.ordinal);

    if (aCatOrdinal < bCatOrdinal) {
      if (aOrdinal < bOrdinal) {
        return -3;
      } else {
        return -2;
      }
    } else if (aCatOrdinal > bCatOrdinal) {
      if (aOrdinal < bOrdinal) {
        return 2;
      } else {
        return 3;
      }
    } else {
      if (aOrdinal < bOrdinal) {
        return -1;
      } else if (aOrdinal > bOrdinal) {
        return 1;
      } else {
        return 0;
      }
    }
  });
}

function shiftDefaultParameterValuesToSelect(
  parameters: ICustomReportParameter[],
): ICustomReportParameter[] {
  parameters.forEach((property) => {
    if (property.values) {
      property.values.unshift("Select");
    }
  });
  return parameters;
}

export function filterUnusedParameters(
  customReportProperties: ICustomReportParameter[],
): ICustomReportParameter[] {
  return Object.values(customReportProperties).filter((property) => {
    const propName = property.name?.toLowerCase();
    return (
      propName !== "tablename" &&
      propName !== "reportname" &&
      !propName?.includes("description")
    );
  });
}
