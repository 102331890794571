import { cdataFetch } from "../cdataFetch";
import { DataType, IQueryResult } from "../../models";
import { IServiceError } from "../../models/IServiceError";

export type IColumnMetadata = {
  catalog: string;
  schema: string;
  tableName: string;
  columnName: string;
  dataType: DataType;
  dataTypeName: string;
};

export async function getColumnsForTable(params: {
  connectionName: string;
  schema: string;
  tableName: string;
}) {
  const { connectionName, schema, tableName } = params;
  const rawData = await cdataFetch<IQueryResult | IServiceError>({
    method: "GET",
    url: `/columns?catalogName=${encodeURIComponent(connectionName)}&schemaName=${encodeURIComponent(schema)}&tableName=${encodeURIComponent(tableName)}`,
  });

  if ("error" in rawData) {
    throw new Error(
      rawData.error?.message ??
        `An error ocurred loading the column metadata for connection = ${connectionName}, schema = ${schema}, table = ${tableName}`,
    );
  }

  const metadata: IColumnMetadata[] =
    "results" in rawData && rawData?.results?.[0]?.rows != null
      ? rawData.results[0].rows.map<IColumnMetadata>((r) => {
          return {
            catalog: r[0],
            schema: r[1],
            tableName: r[2],
            columnName: r[3],
            dataType: r[4],
            dataTypeName: r[5],
          };
        })
      : [];

  return metadata;
}
