import { ITableSettings, QueryInput } from "./SetTable";

export function replaceAPITableSettingsURL(
  tableSettings: ITableSettings,
  queryInputs: QueryInput[],
): ITableSettings {
  if (!queryInputs || queryInputs.length === 0) {
    return tableSettings;
  }
  let updatedUrl = tableSettings.requestUrl;
  queryInputs.forEach((input) => {
    // If using a request per row string like `{id}`, then update the URL with the user's entered ID.
    const regex = new RegExp(`{${input.key}}`, "g");
    updatedUrl = updatedUrl.replace(regex, input.value);
  });
  return {
    ...tableSettings,
    requestUrl: updatedUrl,
  };
}
