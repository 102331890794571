import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { Search } from "react-feather";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  Spinner,
  Input,
  UncontrolledTooltip,
  FormGroup,
} from "reactstrap";
import TertiaryButton from "../../../../components/buttons/TertiaryButton";
import { getDriverIcon } from "../../../../components/drivers/DriverIconFactory";
import { useAPI } from "../../../../components/useAPI";
import { useMemoryCache } from "../../../../components/useMemoryCache";
import { RequestType } from "../../../../components/withAPI";
import { ModalContext } from "../../../../routes/ModalContext";
import { ToastrError } from "../../../../services/toastrService";
import ResultsDetailsGrid from "../../../dataExplorer/RightPanel/Results/ResultsDetailsGrid";
import { useUserInfo } from "../../../../hooks/useUserInfo";
import { UserRole } from "../../../../models";
import { NotificationBar } from "../../../../components/notification/NotificationBar";

export interface IColumnsTab {
  sourceCatalog: string;
  sourceSchema: string;
  sourceAlias: string;
  sourceTable: string;
  assetQuery: string;
  driverName: string;
  workspaceName: string;
  folderName: string;
  assetName: string;
  colOverRide: { [key: string]: boolean };
  setColOverRide: Dispatch<SetStateAction<{ [key: string]: boolean } | null>>;
  setUnsavedChanges: (unsavedChanges: boolean) => void;
  isAdminUser: boolean;
}

const ColumnsTab = (props: IColumnsTab) => {
  const [toggleResult, setToggleResult] = useState(false);
  const [columnMetadata, setColumnMetadata] = useState<any[]>([]);
  const [columnMetadataPayload, setColumnMetadataPayload] = useState<any[]>([]);
  const [filterText, setFilterText] = useState("");
  const [filteredColumns, setFilteredColumns] = useState<any[]>([]);
  const [tablesMetadataLoading, setTablesMetadataLoading] = useState(true);
  const columnMetaData = useMemoryCache(getColumnMetadata);
  const modalFunctions = useContext(ModalContext);
  const api = useAPI();
  const navigate = useNavigate();
  const userInfo = useUserInfo();
  const isAdmin = userInfo.IsInRole(UserRole.Admin);

  // col[11] checks if the column is a real primary key, and the colOverRide call checks if the column has been manually selected as an OData Key
  const tableHasPK = columnMetadataPayload.some(
    (col: any) => col[11] || props.colOverRide[col[3]],
  );

  const tableObj: any = {
    type: "asset",
    name: null,
    query: null,
    meta: {
      connectionName: props.workspaceName,
      tableName: props.sourceAlias,
      schemaName: props.folderName,
    },
  };

  //On first render of the columnsTab fetch column metadata to display in the table.
  useEffect(() => {
    async function getFetchColumnMetada(tableObj: any) {
      await fetchColumnMetadata(tableObj);
    }

    getFetchColumnMetada(tableObj);
  }, []); // eslint-disable-line

  const ColumnsTabTableCell = ({
    children,
  }: {
    dataField: string;
    isPrimaryKey: boolean;
    name: string;
    children: React.ReactNode;
  }) => {
    return <>{children}</>;
  };

  useEffect(() => {
    setFilteredColumns(columnMetadata);
  }, [columnMetadata]);

  useEffect(() => {
    //use filtererd  payload before re-rendering the table cell data.
    const filteredColumnsList = filteredColumns.map((ele) => {
      return ele["Filter Name"];
    });
    const filteredColumnMetadataPayload = columnMetadataPayload.filter(
      (ele) => {
        return filteredColumnsList.includes(ele[3]);
      },
    );

    const newColData: any[] = getTableDataElements(
      filteredColumnMetadataPayload,
    );
    setFilteredColumns(newColData);
  }, [props.colOverRide, filterText]); // eslint-disable-line

  function filterColumns(event: any) {
    setFilterText(event.target.value);
    let newFilteredColumns: { [key: string]: any }[] = columnMetadata;
    if (event.target.value !== "") {
      newFilteredColumns = newFilteredColumns.filter((ele: any) =>
        ele["Filter Name"]
          .toLowerCase()
          .includes(event.target.value.toLowerCase()),
      );
    }
    setFilteredColumns(newFilteredColumns);
  }

  function odataKeyChangeHandler(event: any) {
    props.setUnsavedChanges(true);
    props.setColOverRide((prevState: { [key: string]: boolean } | null) => ({
      ...prevState,
      [event.target.name]: event.target.checked,
    }));
  }

  function getTableDataElements(colMetadataPayload: any): any[] {
    const data = colMetadataPayload
      ? colMetadataPayload.map((el: any) => {
          return {
            "Column Name": (
              <ColumnsTabTableCell
                dataField="Column Name"
                isPrimaryKey={el[11]}
                name={el[3]}
              >
                {el[3]}
                {el[11] && (
                  <>
                    <UncontrolledTooltip
                      placement="top"
                      target="pk-icon"
                      trigger="hover"
                    >
                      Primary Key
                    </UncontrolledTooltip>
                    <span className="primary-key-icon" id="pk-icon">
                      <i className="fa fa-key" />
                    </span>
                  </>
                )}
              </ColumnsTabTableCell>
            ),
            "Column Type": (
              <ColumnsTabTableCell
                dataField="Column Type"
                isPrimaryKey={el[11]}
                name={el[5]}
              >
                {el[5]}
              </ColumnsTabTableCell>
            ),
            Nullable: (
              <ColumnsTabTableCell
                dataField="Nullable"
                isPrimaryKey={el[11]}
                name={el[12]}
              >
                {el[12] ? "True" : "False"}
              </ColumnsTabTableCell>
            ),
            OdataPK: (
              <ColumnsTabTableCell
                dataField="OdataPK"
                isPrimaryKey={el[11]}
                name={props.colOverRide[el[3]] ? "True" : "False"}
              >
                <FormGroup switch>
                  <Input
                    key={`key-${el[3]}`}
                    type="switch"
                    role="switch"
                    name={el[3]}
                    value={el[11] || props.colOverRide[el[3]]}
                    checked={el[11] || props.colOverRide[el[3]]}
                    disabled={!props.isAdminUser || el[11]}
                    onChange={(event: any) => odataKeyChangeHandler(event)}
                  ></Input>
                </FormGroup>
              </ColumnsTabTableCell>
            ),
            "Filter Name": el[3],
          };
        })
      : [];

    return data;
  }

  async function getColumnMetadata(tableMeta: any) {
    const workspaceParam = `&workspace=${encodeURIComponent(tableMeta.connectionName)}`;
    try {
      const url = `/columns?catalogName=${encodeURIComponent(tableMeta.connectionName)}&schemaName=${encodeURIComponent(tableMeta.schemaName)}&tableName=${encodeURIComponent(tableMeta.tableName)}${workspaceParam}`;
      const { status, payload, error } = await api.callAPI(
        RequestType.Get,
        url,
        "",
        null,
        null!,
        { "Connect-Cloud-Client": "CDataDataExplorer" },
      );
      if (status === 200) {
        if (payload?.error) {
          ToastrError("Error fetching results", payload.error.message);
          return [];
        }
        const colMetadata = payload?.results[0]?.rows;
        setColumnMetadataPayload(colMetadata);

        const colArray = getTableDataElements(colMetadata);

        if (colArray.length === 0) {
          ToastrError("Error fetching results", error);
        }

        return colArray;
      } else {
        if (status === 401) {
          modalFunctions.showTimeout();
        } else {
          const err = error;
          ToastrError("Error fetching results", err);
        }
      }
    } catch (err) {
      ToastrError("Error fetching column metadata", err);
    }

    return [];
  }

  async function fetchColumnMetadata(tableData: any) {
    // Update metadata if table is derived view
    if (tableData.query && tableData.type === "derivedView") {
      tableData.meta.connectionName = "CData";
      tableData.meta.schemaName = "DerivedViews";
      tableData.meta.tableName = tableData.name;
    }
    setTablesMetadataLoading(true);
    setColumnMetadata([]);
    const tableMetadata = await columnMetaData.get(tableData.meta);
    setColumnMetadata(tableMetadata);
    setTablesMetadataLoading(false);
  }

  const sortFunc = (a: any, b: any, order: string) => {
    const aIsPrimaryKey = a.props.isPrimaryKey;
    const bIsPrimaryKey = b.props.isPrimaryKey;

    if (aIsPrimaryKey && !bIsPrimaryKey) {
      return -2; // a is primary key, prioritize it
    }

    if (!aIsPrimaryKey && bIsPrimaryKey) {
      return 2; // b is primary key, prioritize it
    }

    // Sort the fields based on their values
    const aValue = a.props.name;
    const bValue = b.props.name;

    if (order === "asc") {
      if (typeof aValue === "string" && typeof bValue === "string") {
        return aValue.localeCompare(bValue);
      } else {
        return aValue < bValue ? -1 : 1;
      }
    } else {
      if (typeof aValue === "string" && typeof bValue === "string") {
        return bValue.localeCompare(aValue);
      } else {
        return bValue < aValue ? -1 : 1;
      }
    }
  };

  const connectionIcon =
    props?.driverName === "DerivedView" ? (
      <>
        <i
          className="fa-border-none align-middle connection-icon primary-icon"
          id="derived-view-icon"
        />
        <UncontrolledTooltip
          placement="top"
          target="derived-view-icon"
          trigger="hover"
        >
          Derived View
        </UncontrolledTooltip>
      </>
    ) : (
      getDriverIcon(props?.driverName, "connection-icon")
    );

  const assetLabel =
    props?.driverName === "DerivedView"
      ? props.sourceTable
      : `${props.sourceCatalog} / ${props.sourceSchema} / ${props.sourceTable}`;

  const alertMessage = isAdmin
    ? "This dataset is a missing an OData Key. Select a column to act as the Primary Key in the OData endpoint. " +
      "Certain applications may result in an error without defining one."
    : "This dataset is a missing an OData Key. To prevent errors when querying from OData, please contact a " +
      "Connect Cloud admin to designate a column as the Primary Key for this table.";

  return (
    <div className="columns-tab">
      <div hidden={!tablesMetadataLoading}>
        <div className="loading-background" />
        <Spinner className="spinner-border loading-spinner" color="info" />
      </div>
      {!tablesMetadataLoading && !tableHasPK && (
        <div className="mb-3">
          <NotificationBar
            barColor={"notification-bar-orange"}
            message={alertMessage}
          />
        </div>
      )}
      <Card>
        <CardBody>
          <h5 className="card-title">Column Details</h5>
          <TertiaryButton
            className="explore-data-button"
            onClick={() =>
              navigate("/data-explorer/", {
                state: {
                  assetQuery: props.assetQuery,
                  clickedFromDatasets: true,
                  clickedFromDatasetsTable: tableObj,
                },
              })
            }
          >
            <i className="fa fa-search" />
            Explore Data
          </TertiaryButton>
          {/*TODO: once we are actually getting assets from api and we asset, we will have to pass them to asset details component and then access them here to show actual address*/}
          <div className="asset-address">
            {connectionIcon}
            {assetLabel}
          </div>
          <span className="col-search-bar-outer">
            <Search className="align-middle-search-glass" size={16} />
            <input
              className="col-search-filter-bar"
              onChange={(e: any) => filterColumns(e)}
              type="text"
              placeholder="Search Columns..."
            />
          </span>
          <UncontrolledTooltip
            placement="top"
            target="odata-pk"
            trigger="hover"
          >
            Enabling a column as a Primary Key exclusively impacts the OData
            endpoint only.
          </UncontrolledTooltip>
          <ResultsDetailsGrid
            columns={[
              {
                dataField: "Column Name",
                text: "Column Name",
                sort: true,
                sortFunc: sortFunc,
              },
              {
                dataField: "Column Type",
                text: "Column Type",
                sort: true,
                sortFunc: sortFunc,
              },
              {
                dataField: "Nullable",
                text: "Nullable",
                sort: true,
                sortFunc: sortFunc,
              },
              {
                dataField: "OdataPK",
                text: <span id="odata-pk">OData Key</span>,
                sort: true,
                sortFunc: sortFunc,
              },
            ]}
            noData={() => {
              return (
                <div className="text-muted text-center">
                  No matching records
                </div>
              );
            }}
            resultDropdownOpen={toggleResult}
            toggleResultDropdownOpen={() => setToggleResult(!toggleResult)}
            gridData={filteredColumns}
            gridID={"dataExplorerDetailsTable"}
            wrapperClassNames={"table-responsive"}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default ColumnsTab;
