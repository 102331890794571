import React, { Component } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { CDataComponent } from "../../../utility/CDataComponent";
import StepWizard from "react-step-wizard";
import { IODataContextType, ODataContext } from "./ODataContext";
import SelectEntitySetType from "./SelectEntitySetType";
import SelectConnection from "./SelectConnection";
import SelectTables from "./SelectTables";
import { IODataMetadata } from "src/models";

interface IODataWizardProps {
  catalog?: any[];
  odataWizardDisplayed: boolean;
  toggleOdataModal: () => void;
  setAlreadyExistingTables: (metadata: IODataMetadata | null) => void;
  setFailedTables?: (
    failedTables: { id: string; table: string; message: string }[],
  ) => void;
  onCompletion: () => void;
  odataExists: boolean;
  selectedEntitySetType?: string;
  selectedConnection?: IODataContextType["selectedConnection"];
}
interface IODataWizardState {
  modalBackButton: JSX.Element;
  modalNextButton: JSX.Element;
  isModalFooterDisplayed: boolean;
  entityType: string;
}

export default class ODataWizard
  extends Component<IODataWizardProps, IODataWizardState>
  implements CDataComponent
{
  constructor(props: IODataWizardProps) {
    super(props);
    this.state = {
      modalBackButton: <></>,
      modalNextButton: <></>,
      isModalFooterDisplayed: true,
      entityType: "Table",
    };
  }

  public getComponentName() {
    return "pages-odata-Wizard-ODataWizard";
  }

  static contextType = ODataContext;
  context!: React.ContextType<typeof ODataContext>;

  setModalBackButton = (button: any) => {
    this.setState({ modalBackButton: button });
  };

  setModalNextButton = (button: any) => {
    this.setState({ modalNextButton: button });
  };

  setIsModalFooterDisplayed = (enableFooter: boolean) => {
    this.setState({ isModalFooterDisplayed: enableFooter });
  };

  setEntityType = (type: string) => {
    this.setState({ entityType: type });
  };

  render() {
    const noTransitions = {
      enterRight: "",
      enterLeft: "",
      exitRight: "",
      exitLeft: "",
    };

    const selectEntitySetType = !this.props.selectedEntitySetType ? (
      <SelectEntitySetType
        stepName="SelectEntitySetType"
        catalog={this.props.catalog}
        setModalBackButton={this.setModalBackButton}
        setModalNextButton={this.setModalNextButton}
        setIsModalFooterDisplayed={this.setIsModalFooterDisplayed}
        setEntityType={this.setEntityType}
      />
    ) : null;

    // If the connection has already been selected, skip the select connection step
    const selectConnection = !this.props.selectedConnection ? (
      <SelectConnection
        stepName="SelectConnection"
        catalog={this.props.catalog}
        setModalBackButton={this.setModalBackButton}
        setModalNextButton={this.setModalNextButton}
        setIsModalFooterDisplayed={this.setIsModalFooterDisplayed}
        setEntityType={this.setEntityType}
      />
    ) : null;

    const footer = this.state.isModalFooterDisplayed ? (
      <ModalFooter
        className={
          window.location.href.includes("initial-setup")
            ? "odata-wizard-footer-space-end"
            : "odata-wizard-footer-space-between"
        }
      >
        {this.state.modalBackButton}
        {this.state.modalNextButton}
      </ModalFooter>
    ) : null;

    return (
      <Modal
        centered
        className={this.getComponentName()}
        isOpen={this.props.odataWizardDisplayed}
        toggle={this.props.toggleOdataModal.bind(this)}
        backdrop="static"
        size="md"
        contentClassName="odata-wizard"
      >
        <ModalHeader toggle={this.props.toggleOdataModal.bind(this)}>
          Add Entity Set
        </ModalHeader>
        <ModalBody className="pt-0">
          <StepWizard transitions={noTransitions} isLazyMount={true}>
            {selectEntitySetType!}
            {selectConnection!}
            <SelectTables
              stepName="SelectTables"
              toggleOdataModal={this.props.toggleOdataModal}
              setAlreadyExistingTables={this.props.setAlreadyExistingTables}
              setFailedTables={this.props.setFailedTables}
              onCompletion={this.props.onCompletion}
              odataExists={this.props.odataExists}
              setModalBackButton={this.setModalBackButton}
              setModalNextButton={this.setModalNextButton}
              setIsModalFooterDisplayed={this.setIsModalFooterDisplayed}
              entityType={this.state.entityType}
              setEntityType={this.setEntityType}
            />
          </StepWizard>
        </ModalBody>
        {footer}
      </Modal>
    );
  }
}
