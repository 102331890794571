import { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import * as Yup from "yup";
import { Formik, FormikHelpers, FormikProps } from "formik";
import classnames from "classnames";
import { Card, CardBody, Col, Container, Row, Button } from "reactstrap";
import { Form } from "react-bootstrap";

import Loader from "../../../components/Loader";
import DropdownListInput from "../../../components/form/DropdownListInput";
import { VerifyEmailType } from "./AccountTabModals";
import { ToastrSuccess } from "../../../services/toastrService";
import { RequestType } from "../../../components/withAPI";
import {
  IAccount,
  IContactEmailChange,
  ICountry,
  UserRole,
} from "../../../models";
import { countryList } from "../../../utility/Countries";
import TertiaryButton from "../../../components/buttons/TertiaryButton";
import { YupOptionalSchema } from "../../../utility/types/yupHelperTypes";
import { useAPI } from "../../../components/useAPI";
import { VerifyEmailModal } from "./VerifyEmailModal";
import { addAccountInfo } from "../../../redux/actions";
import { OEMAccountDetailsForm } from "./oem/OEMAccountDetailsForm";
import { CDataFormTextField } from "../../../components/form/CDataFormTextField";
import { getSalesEmailAddress } from "../../../utility/LocalizedEmailAddresses";
import { getIsSupportImpersonationActive } from "../../../services/userImpersonation";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

const validationSchema = Yup.object<YupOptionalSchema<IAccount>>({
  contactFirstName: Yup.string()
    .min(1, "First name must be between 1 and 100 characters")
    .max(100, "First name must be between 1 and 100 characters")
    .required("This is a required field"),
  contactLastName: Yup.string()
    .min(1, "Last name must be between 1 and 100 characters")
    .max(100, "Last name must be between 1 and 100 characters")
    .required("This is a required field"),
  contactEmail: Yup.string()
    .email("Must be a valid email address")
    .max(255)
    .required("This is a required field"),
  phoneNumber: Yup.string()
    .max(20, "Phone number must be a max of 20 characters")
    .matches(
      /^\+?[0-9.()-]{0,19}$/,
      "Phone number must contain numbers, dashes parentheses and periods",
    ),
});

export function AccountTab() {
  const api = useAPI();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(true);
  const [isVerifyEmailModalOpen, setIsVerifyEmailModalOpen] = useState(false);
  const formikRef = useRef<FormikProps<IAccount>>(null);

  const account = useAppSelector((s) => s.accountInfo);
  const user = useAppSelector((u) => u.user);

  const isOemAdmin = user.role === UserRole.OEMAdmin;
  const isOemSub = user.role === UserRole.ServiceUser;

  const isSupportImpersonationActive = getIsSupportImpersonationActive();

  async function getAccountInfo() {
    const { status, payload } = await api.callAPI<IAccount>(
      RequestType.Get,
      "/account/getInfo",
      "Failed to get account info due to the following error:",
      null,
      undefined,
      undefined,
      isOemSub,
    );
    if (status === 200) {
      setLoading(false);
      dispatch(addAccountInfo(payload!));
    }
  }

  // The account info should already be loaded here, but we defensively run it again in case
  // the user has done something like verified their email, which can be done in a different tab.
  useEffect(() => {
    getAccountInfo();
  }, []); // eslint-disable-line

  async function updateContactName(formValues: IAccount) {
    const { status } = await api.callAPI(
      RequestType.Put,
      "/account",
      "Failed to update account due to: ",
      formValues,
      undefined,
      undefined,
      isOemSub,
    );

    if (status === 200) {
      dispatch(addAccountInfo(formValues));
      ToastrSuccess(
        "Primary Contact Information Successfully Saved!",
        "You have successfully updated your primary contact information.",
      );
    }
  }

  async function sendVerificationEmail(email: string) {
    const data: IContactEmailChange = {
      emailAddress: email,
    };

    const { status } = await api.callAPI(
      RequestType.Post,
      "/account/contactEmail",
      "Failed to update account due to: ",
      data,
      undefined,
      undefined,
      isOemSub,
    );

    if (status === 200) {
      dispatch(
        addAccountInfo({
          ...account,
          pendingEmail: email,
        }),
      );
      setIsVerifyEmailModalOpen(false);
      ToastrSuccess(
        "Email Verification Successfully Sent!",
        `A verification email was sent to ${email}.`,
      );
    }
  }

  async function cancelEmailChangeRequest(updateUI: boolean) {
    const { status } = await api.callAPI(
      RequestType.Delete,
      "/account/contactEmail",
      "Failed to update account due to: ",
      null,
      undefined,
      undefined,
      isOemSub,
    );

    if (status === 200) {
      if (updateUI) {
        dispatch(
          addAccountInfo({
            ...account,
            pendingEmail: "",
          }),
        );

        formikRef.current!.resetForm({
          values: {
            ...account,
            pendingEmail: "",
          },
        });

        ToastrSuccess(
          "Email Change Request Canceled",
          "The email change you requested has been canceled.",
        );
      }
    }
  }

  async function clearMetadataCache() {
    const { status } = await api.callAPI(
      RequestType.Delete,
      "/account/metadata",
      "Failed to clear metadata cache due to the following error:",
      null,
      undefined,
      undefined,
      isOemSub,
    );
    if (status === 200 || status === 204) {
      ToastrSuccess(
        "Metadata Cache Cleared Successfully!",
        "The metadata cache has been successfully cleared.",
      );
    }
  }

  async function handleValidSubmit(
    values: IAccount,
    actions: FormikHelpers<IAccount>,
  ) {
    if (
      values.contactEmail === account.contactEmail ||
      values.contactEmail === account.pendingEmail
    ) {
      updateContactName(values);
      actions.resetForm({
        values,
      });
    } else {
      setIsVerifyEmailModalOpen(true);
    }
  }

  if (loading) {
    return (
      <Container fluid className="p-0">
        <Loader />
      </Container>
    );
  }

  const countryOptions = countryList.map((country: ICountry) => (
    <option key={country.threeLetterCode} value={country.threeLetterCode}>
      {country.name}
    </option>
  ));
  countryOptions.splice(0, 0, <option key="placeholder" value=""></option>);

  const firstTimeSetupCard =
    window.location.toString().includes("localhost") && !isOemAdmin ? (
      <Card>
        <CardBody>
          <h4 className="mb-3">First Time Setup</h4>
          <div className="mb-3">Get started with CData Connect Cloud!</div>
          <NavLink
            className="text-light"
            to={{
              pathname: "/initial-setup",
              hash: "select-initial-connection",
            }}
          >
            <Button>
              <i className="fa fa-check small-icon align-middle no-pointer-event" />
              Setup
            </Button>
          </NavLink>
        </CardBody>
      </Card>
    ) : null;

  const metadataCacheCard = window.location.toString().includes("localhost") ? (
    <Card>
      <CardBody>
        <h4 className="mb-3">Metadata Cache</h4>
        <div className="mb-3">
          Clearing your metadata cache can help fix certain problems like
          loading or performance issues across the application.
        </div>
        <button
          onClick={() => clearMetadataCache()}
          className="btn btn-dark"
          data-testid="button-clear-metadata-cache"
        >
          <i className="fa fa-trash small-icon align-middle no-pointer-event" />
          Clear Cache
        </button>
      </CardBody>
    </Card>
  ) : null;

  let pendingVerificationButton: JSX.Element;
  let resendVerificationButton: JSX.Element;
  let cancelVerificationButton: JSX.Element;

  if (account.pendingEmail) {
    pendingVerificationButton = (
      <Button
        color="warning"
        className="pending-verification-button ms-2 card-actions no-pointer-event"
        data-testid="button-account-pending-verification"
      >
        Pending Verification
      </Button>
    );
    resendVerificationButton = (
      <Button
        color="secondary"
        className="card-actions"
        data-testid="button-account-resend-verification"
        onClick={() => setIsVerifyEmailModalOpen(true)}
      >
        <i className="fa fa-paper-plane small-icon align-middle no-pointer-event" />
        Resend Verification
      </Button>
    );
    cancelVerificationButton = (
      <TertiaryButton
        key="selectConnectionButton"
        className="cancel-verification-button"
        data-testid="button-account-cancel-verification"
        onClick={() => cancelEmailChangeRequest(true)}
      >
        Cancel Verification Request
      </TertiaryButton>
    );
  }

  const initialFormData: IAccount = {
    ...account,
    contactEmail: account.pendingEmail ?? account.contactEmail,
  };

  return (
    <Row>
      <Col>
        {!isOemAdmin && !isOemSub && (
          <Card>
            <CardBody>
              <h4 className="mb-3">Details</h4>
              <Row className="account-details-row mx-0">
                <Col>
                  <div>
                    <Row className="fw-bold mb-3">Organization Name</Row>
                    <Row>{account.organization}</Row>
                  </div>
                </Col>
                <Col>
                  <div>
                    <Row className="fw-bold mb-3">Country</Row>
                    <Row>
                      {account.country
                        ? countryList.find(
                            (t) => t.threeLetterCode === account.country,
                          )?.name ?? ""
                        : ""}
                    </Row>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        )}
        {(isOemAdmin || isOemSub) && <OEMAccountDetailsForm />}
        <Card>
          <CardBody>
            <Formik
              innerRef={formikRef}
              initialValues={initialFormData}
              validationSchema={validationSchema}
              validateOnMount={true}
              onSubmit={(values, actions) => handleValidSubmit(values, actions)}
            >
              {({
                handleSubmit,
                values,
                resetForm,
                setFieldValue,
                isValid,
                dirty,
              }) => {
                return (
                  <Form>
                    <VerifyEmailModal
                      open={isVerifyEmailModalOpen}
                      close={() => {
                        if (account.pendingEmail) {
                          setFieldValue(
                            "contactEmail",
                            account.pendingEmail,
                            true,
                          );
                        } else {
                          resetForm();
                        }
                        setIsVerifyEmailModalOpen(false);
                      }}
                      emailType={VerifyEmailType.Contact}
                      email={values.contactEmail!}
                      onSave={async () => {
                        await updateContactName(values);
                        await sendVerificationEmail(values.contactEmail!);
                      }}
                    />
                    <Row>
                      <Col>
                        <h4 className="mb-4">Primary Contact Information</h4>
                      </Col>
                      <Col>
                        <Button
                          onClick={() => handleSubmit()}
                          color="primary"
                          className="card-actions float-end"
                          disabled={!(isValid && dirty)}
                          hidden={isSupportImpersonationActive}
                          data-testid="primary-contact-information-save-changes-button"
                        >
                          <i className="fa fa-save small-icon align-middle no-pointer-event" />
                          Save Changes
                        </Button>
                      </Col>
                    </Row>
                    <div className="mb-3">
                      This user will receive all account related notifications
                      including invoices, announcements, alerts, etc.
                    </div>
                    <CDataFormTextField
                      label="First Name"
                      name="contactFirstName"
                      className="mb-3"
                      required
                      disabled={isSupportImpersonationActive}
                    />
                    <CDataFormTextField
                      label="Last Name"
                      name="contactLastName"
                      className="mb-3"
                      required
                      disabled={isSupportImpersonationActive}
                    />
                    <Form.Group className="mb-3">
                      <h5 className="required">
                        Email {pendingVerificationButton}
                      </h5>
                      <CDataFormTextField
                        name="contactEmail"
                        type="email"
                        isInvalid={Boolean(account.pendingEmail)}
                        errorMessage={
                          account.pendingEmail
                            ? `All account related notifications will continue to be sent to ${account.contactEmail} until you verify the email above.`
                            : undefined
                        }
                        disabled={isSupportImpersonationActive}
                      />
                      <div className="helper-text mb-3">
                        Changing the Primary Contact email won&apos;t affect
                        your login email. To update your login email, please
                        navigate to the &apos;Profile&apos; tab.
                      </div>
                    </Form.Group>
                    <CDataFormTextField
                      label="Phone Number"
                      name="phoneNumber"
                      className="mb-3"
                      disabled={isSupportImpersonationActive}
                    />
                    <DropdownListInput
                      label="Country"
                      name="country"
                      list={countryOptions}
                      defaultValue={account.country!}
                      disabled={isSupportImpersonationActive}
                    />
                    <Row
                      className={classnames("verification-buttons", {
                        "mt-3": account.pendingEmail,
                      })}
                    >
                      <Col className="pe-1">{resendVerificationButton}</Col>
                      <Col className="ps-0">{cancelVerificationButton}</Col>
                    </Row>
                  </Form>
                );
              }}
            </Formik>
          </CardBody>
        </Card>
        {firstTimeSetupCard}
        {!isOemAdmin && metadataCacheCard}
        <Card>
          <CardBody>
            <h4 className="mb-3">Delete Account</h4>
            <div className="mb-3">
              If you wish to delete your account and all associated data, press
              the Contact Sales button below so we can assist you in this
              request.
            </div>
            <Button
              disabled={isSupportImpersonationActive}
              onClick={() => {
                window.location.href = `mailto:${getSalesEmailAddress()}`;
              }}
              color="primary"
              className="card-actions"
            >
              Contact Sales
            </Button>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}
