import React, { useContext } from "react";

import { StepNames } from "./StepNames";
import { IStepBodyProps } from "../../../../../components/wizard/components/StepWizardStep";
import { AddAssetWizardContext } from "../AddAssetWizardContext";
import { useAPI } from "../../../../../components/useAPI";
import { RequestType } from "../../../../../components/withAPI";
import { AssetType, IAssetListItem } from "../../../../../models";
import { sortAssetsByAlias } from "../../ObjectSorter";
import { ToastrSuccess } from "../../../../../services/toastrService";
import {
  ButtonType,
  CDataButton,
} from "../../../../../components/buttons/CDataButton";
import { StepWizardStepFooter } from "../../../../../components/wizard/components/StepWizardStepFooter";
import { StepWizardContext } from "../../../../../components/wizard/components/StepWizardContext";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import TextInput from "../../../../../components/form/TextInput";

export function SelectFolderName(props: IStepBodyProps) {
  const addAssetWizardContext = useContext(AddAssetWizardContext);
  const { toggleModal } = useContext(StepWizardContext);

  const api = useAPI();

  async function createAssetFolder(value: { folderName: string }) {
    const workspaceID = addAssetWizardContext.workspaceID;
    const containerName = addAssetWizardContext.containerName;

    const data = {
      Name: value.folderName,
    };

    const { status, payload } = await api.callAPI(
      RequestType.Post,
      `/workspaces/${workspaceID}/folders`,
      "Error creating folder:",
      data,
    );
    if (status === 200) {
      addAssetWizardContext.setAssetList((prevAssetList: IAssetListItem[]) => {
        const newAssetList = [...prevAssetList];
        newAssetList.push({
          alias: payload.name,
          assetType: AssetType.Folder,
          childCount: payload.childCount,
          description: payload.description,
          driver: null!,
          isConnectionTested: payload.isConnectionTested,
          id: payload.id,
          sourceSchema: null!,
          sourceTable: null!,
        });
        sortAssetsByAlias(newAssetList);
        return newAssetList;
      });
      ToastrSuccess(
        "Folder successfully added",
        `Successfully added ${value.folderName} to ${containerName}`,
      );
    }

    toggleModal();
  }

  const initialFormValues = {
    folderName: "",
  };

  const validationSchema = Yup.object().shape({
    folderName: Yup.string()
      .matches(
        /^\w[\w_ -]{0,99}(?<! )$/,
        "Folder name must only consist of letters, numbers, underscores, hyphens, and are limited to 100 characters.",
      )
      .required("Folder name is required"),
  });

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={validationSchema}
      onSubmit={createAssetFolder}
    >
      {({ handleSubmit }) => (
        <Form id="folderForm" onSubmit={handleSubmit}>
          <div className="mb-3">
            <TextInput
              label={"Folder Name"}
              name={"folderName"}
              isRequired={true}
            />
          </div>
          <StepWizardStepFooter>
            <CDataButton
              buttonType={ButtonType.Borderless}
              onClick={() => props.goToNamedStep!(StepNames.SelectAssetType)}
            >
              Back
            </CDataButton>
            <CDataButton
              buttonType={ButtonType.Primary}
              form="folderForm"
              type="submit"
            >
              Confirm
            </CDataButton>
          </StepWizardStepFooter>
        </Form>
      )}
    </Formik>
  );
}
