import { CDataModalV2 } from "../../../../components/modal/CDataModalV2";
import {
  ButtonType,
  CDataButton,
} from "../../../../components/buttons/CDataButton";
import { useAPI } from "../../../../components/useAPI";
import { RequestType } from "../../../../components/withAPI";
import { ToastrSuccess } from "../../../../services/toastrService";
import { useState } from "react";
import { Spinner } from "reactstrap";
import { LeftPanelType } from "../../LeftPanel/leftPanelType";
import { Formik } from "formik";
import * as Yup from "yup";
import { Form } from "react-bootstrap";
import useQueryTabs from "../Tabs/useQueryTabs";
import { QueryTabType } from "../Tabs/queryTabType";

export type CreateSavedQueryModalProps = {
  displayed: boolean;
  close: () => void;
  fetchSavedQueries: () => void;
  setSidebarView: (sidebarView: LeftPanelType) => void;
};

export function CreateSavedQueryModal(props: CreateSavedQueryModalProps) {
  const api = useAPI();
  const tabs = useQueryTabs();

  const { displayed, close, fetchSavedQueries, setSidebarView } = props;

  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const currentTab = tabs.List.find((t) => t.id === tabs.CurrentTabId);

  if (!currentTab) {
    return null;
  }

  const createSavedQuery = async (values: any) => {
    setIsProcessing(true);

    const { status, payload } = await api.callAPI(
      RequestType.Post,
      "/users/self/savedQueries",
      "Failed to create saved query due to the following error:",
      values,
    );

    if (status === 200) {
      ToastrSuccess(
        "Query Successfully Saved!",
        `${values.Name} was created successfully.`,
      );
      close();
      tabs.ChangeTabType(
        currentTab.id,
        values.Name,
        QueryTabType.SavedQuery,
        payload.id,
        payload.query,
      );
      await fetchSavedQueries();
      setSidebarView(LeftPanelType.SavedQueries);
    }

    setIsProcessing(false);
  };

  const initialFormValues = {
    Name: "",
    Query: currentTab.queryString,
  };

  const validationSchema = Yup.object().shape({
    Name: Yup.string()
      .min(2, "Saved Query name must be between 2 and 128 characters")
      .max(128, "Saved Query name must be between 2 and 128 characters")
      .required("This is a required field"),
    Query: Yup.string()
      .max(10000, "Query cannot exceed 10000 characters")
      .required("This is a required field"),
  });

  return (
    <>
      <CDataModalV2
        modalSize="lg"
        displayed={displayed}
        close={close}
        title="Save Query"
        spacedFooter={false}
        primaryButton={
          <CDataButton
            buttonType={ButtonType.Primary}
            type="submit"
            form="newSavedQueryForm"
            data-testid="button-confirm"
          >
            Confirm
          </CDataButton>
        }
        secondaryButton={
          <CDataButton
            buttonType={ButtonType.Secondary}
            onClick={close}
            data-testid="button-cancel"
          >
            Cancel
          </CDataButton>
        }
      >
        <Formik
          initialValues={initialFormValues}
          validationSchema={validationSchema}
          onSubmit={createSavedQuery}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
          }) => (
            <Form id="newSavedQueryForm" onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Label className="required h5">Query Name</Form.Label>
                <Form.Control
                  id="Name"
                  type="text"
                  placeholder="Enter a name for your saved query."
                  value={values.Name}
                  isInvalid={Boolean(touched.Name && errors.Name)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.Name && (
                  <Form.Control.Feedback type="invalid">
                    {errors.Name?.toString()}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Form>
          )}
        </Formik>
      </CDataModalV2>
      <div hidden={!isProcessing}>
        <div className="loading-background" />
        <Spinner className="spinner-border loading-spinner" color="info" />
      </div>
    </>
  );
}
