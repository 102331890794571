import {
  CDataTypography,
  TypographyColor,
} from "../../../components/text/CDataTypography";
import { ListGroup, ListGroupItem } from "reactstrap";
import { useContext, useState } from "react";
import {
  CDataTooltip,
  CDataTooltipType,
} from "../../../components/CDataTooltip";
import { getSalesEmailAddress } from "../../../utility/LocalizedEmailAddresses";
import classNames from "classnames";
import { ConnectionContext } from "./ConnectionContext";
import { sshTypeUpdated } from "./ConnectionFunctions";
import { SSHPropertyCard } from "./SSHPropertyCard";
import { SSHConnectionType } from "./SSHConnectionType";

type SSHConnectionTypeOption = {
  type: SSHConnectionType;
  disabled: boolean;
};

export const SSHConnectionTypeSelector = () => {
  const salesEmail = getSalesEmailAddress();
  const connectionContext = useContext(ConnectionContext);

  const isSSHEnabled =
    connectionContext.driverInfo.advancedProps
      .find((prop) => prop.name === "SSH")
      ?.properties?.find((prop) => prop.propertyName === "UseSSH")
      ?.currentValue.toLocaleLowerCase() === "true";

  const [activeType, setActiveType] = useState<SSHConnectionType>(
    isSSHEnabled ? SSHConnectionType.SSHTunnel : SSHConnectionType.Direct,
  );

  const buttonOptions: SSHConnectionTypeOption[] = [
    {
      type: SSHConnectionType.Direct,
      disabled: false,
    },
    {
      type: SSHConnectionType.SSHTunnel,
      disabled: false,
    },
    {
      type: SSHConnectionType.CloudAgent,
      disabled: true,
    },
    {
      type: SSHConnectionType.AzurePrivateLink,
      disabled: true,
    },
  ];

  function getTypographyColor(
    option: SSHConnectionTypeOption,
  ): TypographyColor {
    if (option.disabled) return "typography-color-gray-065";
    if (activeType === option.type) return "typography-color-white";
    return "typography-color-text-regular";
  }

  const getTooltipText = (optionName: string) => {
    return (
      <>
        Coming soon.{" "}
        <a href={`mailto:${salesEmail}?subject=${optionName} Request`}>
          Request
        </a>{" "}
        early access.
      </>
    );
  };

  const handleSelectionUpdate = (selection: SSHConnectionType) => {
    setActiveType(selection);
    sshTypeUpdated(connectionContext, selection);
  };

  return (
    <div>
      <CDataTypography variant="typography-variant-body-medium">
        Connection Type
      </CDataTypography>
      <ListGroup horizontal className="ssh-connection-type-selector mt-2 mb-4">
        {buttonOptions.map((option, index) => {
          return (
            <ListGroupItem
              key={index}
              id={option.type}
              action
              type="button"
              size="sm"
              onClick={
                option.disabled
                  ? undefined
                  : () => handleSelectionUpdate(option.type)
              }
              active={activeType === option.type}
              className={classNames("text-center ssh-connection-type-option", {
                "disabled-option": option.disabled,
              })}
              data-testid={`button-${option.type}`}
            >
              <CDataTooltip
                aria-label={`tooltip-${option.type}`}
                role="tooltip"
                type={CDataTooltipType.Dark}
                title={option.disabled ? getTooltipText(option.type) : ""}
              >
                <div>
                  <CDataTypography
                    variant="typography-variant-body-medium"
                    color={getTypographyColor(option)}
                  >
                    {option.type}
                  </CDataTypography>
                </div>
              </CDataTooltip>
            </ListGroupItem>
          );
        })}
      </ListGroup>
      {activeType === SSHConnectionType.SSHTunnel && <SSHPropertyCard />}
    </div>
  );
};
