import { cdataFetch } from "../../../api/cdataFetch";
import { IBatchDeleteRequest, IBatchDeleteResponse } from "../../../models";

export async function deleteCacheJobs(request: IBatchDeleteRequest) {
  return cdataFetch<IBatchDeleteResponse>({
    method: "DELETE",
    url: `/cacheJobs`,
    body: request,
  });
}
