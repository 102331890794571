import { logoutUser } from "../../routes/AuthorizeContext";
import { ButtonType, CDataButton } from "../buttons/CDataButton";
import { CDataTypography } from "../text/CDataTypography";
import { CDataModalV2 } from "./CDataModalV2";

interface ITimeoutModal {
  displayed: boolean;
}

export const TimeoutModal = (props: ITimeoutModal) => {
  const { displayed } = props;

  const logoutButton = (
    <CDataButton buttonType={ButtonType.Secondary} onClick={() => logoutUser()}>
      Return to Login
    </CDataButton>
  );

  return (
    <CDataModalV2
      displayed={displayed}
      close={() => null} // The user shouldn't be able to close this modal if it shows up.
      title="Session Timeout"
      secondaryButton={logoutButton}
      displayToggleCloseButton={false}
    >
      <CDataTypography>
        Your session has timed out, please log in again.
      </CDataTypography>
    </CDataModalV2>
  );
};
