import { Badge } from "react-bootstrap";
import {
  CDataTypography,
  TypographyColor,
} from "../../../components/text/CDataTypography";
import {
  CDataTooltip,
  CDataTooltipType,
} from "../../../components/CDataTooltip";

type ConnectionStatus =
  | "connection-status-authenticated"
  | "connection-status-not-authenticated"
  | "connection-status-conditional";

interface IConnectionStatusBadge {
  driver: string;
  isTested: boolean;
  isQueryUser: boolean;
  isUdcConnectionType: boolean;
}

export const ConnectionStatusBadge = (props: IConnectionStatusBadge) => {
  const { driver, isTested } = props;
  const isAPIDriver = driver === "REST";

  let badgeText: string = "";
  let connectionStatus: ConnectionStatus;
  let textColor: TypographyColor;

  if (isAPIDriver) {
    connectionStatus = "connection-status-conditional";
  } else {
    connectionStatus = isTested
      ? "connection-status-authenticated"
      : "connection-status-not-authenticated";
  }

  switch (connectionStatus) {
    case "connection-status-authenticated":
      badgeText = "Authenticated";
      textColor = "typography-color-success";
      break;
    case "connection-status-not-authenticated":
      badgeText = "Not Authenticated";
      textColor = "typography-color-danger-new";
      break;
    case "connection-status-conditional":
      badgeText = "Conditional";
      textColor = "typography-color-white";
      break;
  }
  let toolTipText = "";
  if (connectionStatus === "connection-status-conditional") {
    toolTipText =
      "Status is conditional based on the global settings and tables added to this connector.";
  } else if (props.isQueryUser && !isTested && !props.isUdcConnectionType) {
    toolTipText =
      "Contact an administrator of this account to have this connection authenticated.";
  }

  return (
    <span className="connection-status-badge-container">
      <CDataTooltip type={CDataTooltipType.Dark} title={toolTipText}>
        <Badge
          color=""
          data-testid="connection-status-badge"
          className={`connection-status-badge ${connectionStatus}`}
        >
          <CDataTypography
            color={textColor}
            variant="typography-variant-caption"
          >
            {badgeText}
          </CDataTypography>
        </Badge>
      </CDataTooltip>
    </span>
  );
};
