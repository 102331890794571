import React from "react";
import { Col, Row } from "reactstrap";
import { ModalPromptFieldTypes, ResourceType } from "../ModalPromptBody";
import ClientCardModalField from "./ClientCardModalField";

interface IDatabaseField {
  idName: string;
  isWorkspaceTabSelected: boolean;
  selectedResourceType: ResourceType;
  selectedWorkspaceName: string | undefined;
  userEmail: string | undefined;
  containerClassName?: string;
}

const DatabaseField = (props: IDatabaseField) => {
  if (props.selectedResourceType === ResourceType.Connection) {
    return (
      <Row className={props.containerClassName || "my-3"}>
        <Col className="label-col">
          <div className="client-endpoint-label">Database:</div>
        </Col>
        <Col className="ps-0">
          <span>
            If required, specify the name of the Connection you created.
          </span>
        </Col>
      </Row>
    );
  } else if (props.isWorkspaceTabSelected && props.selectedWorkspaceName) {
    return (
      <Row className={props.containerClassName || "my-3"}>
        <ClientCardModalField
          fieldKey={"Database"}
          fieldType={ModalPromptFieldTypes.SingleField}
          idName={props.idName}
          selectedResourceType={props.selectedResourceType}
          selectedWorkspaceName={props.selectedWorkspaceName}
          value={props.selectedWorkspaceName}
        />
      </Row>
    );
  } else {
    return null;
  }
};

export default DatabaseField;
