import { cdataFetch } from "../../../api/cdataFetch";
import { IScheduledQuery } from "../../../models/ScheduledQueries/IScheduledQuery";
import { IUpdateScheduledQuery } from "../../../models/ScheduledQueries/IUpdateScheduledQuery";

export async function updateScheduledQuery(params: IUpdateScheduledQuery) {
  return cdataFetch<IScheduledQuery, IUpdateScheduledQuery>({
    method: "PUT",
    url: `/scheduledquery/${params.id}`,
    body: params,
  });
}
