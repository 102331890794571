import { useContext, useEffect, useState } from "react";
import { Button } from "reactstrap";
import { IModalProps } from "../../../components/CDataModal";
import { ModalContext } from "../../../routes/ModalContext";
import SelectAssetsTable from "./SelectAssetsTable";
import { RequestType } from "../../../components/withAPI";
import { useAPI } from "../../../components/useAPI";
import { IMoveAssetRequest } from "../../../models/Datasets/IMoveAssetRequest";
import { ToastrError, ToastrSuccess } from "../../../services/toastrService";
import { NotificationBar } from "../../../components/notification/NotificationBar";

interface MoveAssetModalProps {
  isModalOpen: boolean;
  submitMoveSelection: () => void;
  setIsModalOpen: (isModalOpen: boolean) => void;
  workspaceId: string;
  folderId: string;
  assetId?: string;
  movingName: string;
  currentFolder?: string;
}

export const MoveAssetModal = (props: MoveAssetModalProps) => {
  const modalContext = useContext(ModalContext);
  const api = useAPI();
  const [newWorkspaceId, setNewWorkspaceId] = useState<string>();
  const [newFolderId, setNewFolderId] = useState<string>();
  const [selectedName, setSelectedName] = useState<string>();

  useEffect(() => {
    if (props.isModalOpen) {
      modalContext.setModal(modal);
    } else {
      modalContext.toggleModal();
    }
  }, [props.isModalOpen]); // eslint-disable-line

  useEffect(() => {
    if (props.isModalOpen) {
      modalContext.setModal(modal);
    }
    // eslint-disable-next-line
  }, [
    props.workspaceId,
    props.assetId,
    props.folderId,
    newWorkspaceId,
    newFolderId,
  ]);

  const title = "Move Asset";
  const helperText = "Select the folder you'd like to move this asset to.";
  const pageName = "editWorkspace";

  function getComponentName() {
    return "move-asset-modal";
  }

  const moveWorkspace = async () => {
    //No moving to the same folder
    if (
      props.assetId &&
      newWorkspaceId === props.workspaceId &&
      (props.currentFolder === newFolderId ||
        (props.currentFolder === undefined && newFolderId === null))
    ) {
      ToastrError(
        "Error moving asset",
        "Can not move asset to the same workspace",
      );
      return false;
    } else if (!props.assetId && newWorkspaceId === props.workspaceId) {
      ToastrError(
        "Error moving folder",
        "Can not move folder to the same workspace",
      );
      return false;
    }

    const data: IMoveAssetRequest = {
      newWorkspaceId: newWorkspaceId!,
      newParentId: newFolderId,
    };
    if (!props.assetId) {
      const { status } = await api.callAPI(
        RequestType.Post,
        `/workspaces/${props.workspaceId}/folders/${props.folderId}/move`,
        "Failed to move folder",
        data,
      );
      if (status === 200 || status === 204) {
        ToastrSuccess(
          "Item successfully moved",
          `${props.movingName} was succesfully moved to ${selectedName}`,
        );
        return true;
      }
    } else {
      const { status } = await api.callAPI(
        RequestType.Post,
        `/workspaces/${props.workspaceId}/assets/${props.assetId}/move`,
        "Failed to move asset",
        data,
      );
      if (status === 200 || status === 204) {
        ToastrSuccess(
          "Item successfully moved",
          `${props.movingName} was succesfully moved to ${selectedName}`,
        );
        return true;
      }
    }
    return false;
  };

  function setWorkspaceAndFolder(
    workspaceId: string,
    folderId: string,
    name: string,
  ) {
    setNewWorkspaceId(workspaceId);
    setNewFolderId(folderId);
    setSelectedName(name);
  }

  const body = (
    <div className={getComponentName()}>
      <p>{helperText}</p>
      <div className="warning-bar">
        <NotificationBar
          barColor={"notification-bar-orange"}
          message={
            "Moving an asset may affect permissions and privileges set at the role level."
          }
        />
      </div>
      <span className="workspace-table">
        <SelectAssetsTable
          pageName={pageName}
          setMoveDestination={async (
            workspaceId: string,
            folderId: string,
            name: string,
          ) => {
            setWorkspaceAndFolder(workspaceId, folderId, name);
          }}
          isAssetMove={props.assetId !== null}
        />
      </span>
    </div>
  );

  const modal = {
    title: title,
    body: body,
    primaryButton: (
      <Button
        color="primary"
        form="newWorkspaceForm"
        type="submit"
        disabled={!newWorkspaceId}
        onClick={async () => {
          if (await moveWorkspace()) {
            props.submitMoveSelection();
          }
        }}
      >
        <div className="icon no-pointer-event" />
        Confirm
      </Button>
    ),
    close: () => {
      setWorkspaceAndFolder(null!, null!, null!);
      props.setIsModalOpen(false);
    },
    displayed: true,
    displayToggleCloseButton: true,
  } as IModalProps;

  return <span></span>;
};
