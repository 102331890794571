import { useState } from "react";
import { JobListItem } from "../Jobs";
import { IJobsTable, JobsTable } from "./JobsTable";
import { DeleteJobModal } from "../modals/DeleteJobModal";
import { useJobsFunctions } from "../useJobsFunctions";
import { JobType } from "../../../models/Cache/JobType";
import { ToastrSuccess } from "../../../services/toastrService";
import { jobsConstants } from "../Jobs.constants";
import { ICacheJobListItem } from "../../../models/Cache/ICacheJobListItem";
import { IScheduledQueryListItem } from "../../../models/ScheduledQueries/IScheduledQueryListItem";
import { Spinner } from "reactstrap";
import { IConnection } from "../../../models/Connections/IConnection";

interface IJobsTableManager extends IJobsTable {
  setCacheJobs: (jobs: ICacheJobListItem[]) => void;
  setScheduledQueries: (queries: IScheduledQueryListItem[]) => void;
  connections: IConnection[];
}

export const JobsTableManager = (props: IJobsTableManager) => {
  const { cacheJobs, setCacheJobs, scheduledQueries, setScheduledQueries } =
    props;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [jobToDelete, setJobToDelete] = useState<JobListItem | null>(null);

  const { deleteJob, isJobsProcessing } = useJobsFunctions();

  const handleDelete = (job: JobListItem) => {
    setJobToDelete(job);
    setShowDeleteModal(true);
  };

  async function onSingleDelete() {
    if (!jobToDelete) return;

    const { id, name, jobType } = jobToDelete;
    const isCacheJob = jobType === JobType.Caching;
    const result = await deleteJob(jobToDelete);
    if (result.deletedIds?.includes(id)) {
      const toastBody = isCacheJob
        ? `Cache job for table '${name}' deleted successfully.`
        : `Scheduled query '${name}' deleted successfully.`;
      ToastrSuccess(jobsConstants.JOB_SUCCESSFULLY_DELETED_TITLE, toastBody);
      updateJobsListAfterDelete(id, jobType);
    }
    setShowDeleteModal(false);
    setJobToDelete(null);
  }

  const updateJobsListAfterDelete = (deletedId: string, jobType: JobType) => {
    if (jobType === JobType.Caching) {
      setCacheJobs(cacheJobs.filter((job) => job.id !== deletedId));
    } else {
      setScheduledQueries(
        scheduledQueries?.filter((query) => query.id !== deletedId) ?? [],
      );
    }
  };

  return (
    <>
      <div hidden={!isJobsProcessing}>
        <div className="loading-background" />
        <Spinner className="spinner-border loading-spinner" color="info" />
      </div>
      <JobsTable {...props} deleteJob={handleDelete} />
      <DeleteJobModal
        isBulk={false}
        isDisplayed={showDeleteModal}
        onDelete={onSingleDelete}
        setIsDisplayed={setShowDeleteModal}
      />
    </>
  );
};
