import { IDriverList, IPartialDriver } from "../models";
import { FeatureId } from "../models/Features/FeatureId";
import { compareStrings } from "./CompareStrings";
import { isFeatureAllowed } from "./SubscriptionAddonsFactory";

export function IsPremiumConnectorDisabled(
  driverToCheck: string,
  driverList: IDriverList,
  availableFeatureIds: FeatureId[],
) {
  const isPremium = driverList.drivers!.filter((driver: IPartialDriver) =>
    compareStrings(driver.driver, driverToCheck),
  )[0]?.premium;

  const premiumConnectorDisabled =
    isPremium &&
    !isFeatureAllowed(availableFeatureIds, FeatureId.PremiumDataSources);

  return premiumConnectorDisabled;
}
