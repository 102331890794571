/* eslint-disable max-lines */
import { useContext, useEffect, useState, useRef } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Formik, FormikHelpers, FormikProps } from "formik";
import * as Yup from "yup";
import { useFeatureFlags } from "../../hooks/useFeatureFlags";
import { CDataModalV2 } from "../../components/modal/CDataModalV2";
import _ from "lodash";

import {
  Button,
  Card,
  CardTitle,
  CardBody,
  Col,
  Container,
  Label,
  Input,
  Row,
  FormGroup,
} from "reactstrap";

import { Form } from "react-bootstrap";
import Loader from "../../components/Loader";
import { IModalProps } from "../../components/CDataModal";
import PromptWithModal from "../../components/PromptWithModal";
import { ToastrSuccess } from "../../services/toastrService";
import { RequestType } from "../../components/withAPI";
import { PermissionsCard } from "../connections/components/";
import TextInput from "../../components/form/TextInput";
import DropdownListInput from "../../components/form/DropdownListInput";
import { useAPI } from "../../components/useAPI";
import { ModalContext } from "../../routes/ModalContext";
import { UserRole } from "../../models/Users/UserRole";
import { formatUTCDateTime } from "../../utility/FormatUTCDateTime";
import { getIsSupportImpersonationActive } from "../../services/userImpersonation";
import ConnectForSpreadsheetsBlocker from "../../components/ConnectForSpreadsheetsBlocker";
import { useIsConnectForSpreadsheets } from "../../hooks/useIsConnectForSpreadsheets";
import { useAppSelector } from "../../redux/hooks";
import { useMutation } from "@tanstack/react-query";
import { getConnections } from "../connections/connectionList/getConnections";

interface IUserInitial {
  id?: "";
  email?: string;
  enabled?: boolean;
  permissions: any[];
  inviteExpires?: string;
  isInvite?: boolean;
  role: string;
  inviteCode?: string;
  firstName?: string;
  lastName?: string;
  workspacePermissions: any[];
  canBeImpersonated: boolean;
}

type IFormValues = {
  FirstName?: string;
  LastName?: string;
  Role?: string;
  permissions?: any[];
  workspacePermissions?: any[];
  CanBeImpersonated?: boolean;
};

function EditUser() {
  const api = useAPI();
  const modalContext = useContext(ModalContext);
  const navigate = useNavigate();
  const location = useLocation();
  const userId =
    (location.state &&
      (location.state as any).user &&
      (location.state as any).user.id) ||
    null;
  const [userInitial, setUserInitial] = useState<IUserInitial | null>(null);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userInitialFirstTime, setUserInitialFirstTime] = useState(false);
  const [initialImpersonationState, setInitialImpersonationState] =
    useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userInitialPermissions, setUserInitialPermissions] = useState<any[]>(
    [],
  );
  const [unsavedPermissionChanges, setUnsavedPermissionChanges] =
    useState(false);
  const flags = useFeatureFlags().getFlags(["impersonation"]);
  const supportImpersonationText =
    "Once enabled, a Query user can authorize support access to their account through their Account Settings.";
  const isSupportImpersonationActive = getIsSupportImpersonationActive();

  const formikRef = useRef<FormikProps<IFormValues>>(null);
  const loggedInUsersRole = useAppSelector((state) => state.user?.role);
  const isOnlySpreadsheetClient = useIsConnectForSpreadsheets();

  const { mutateAsync: getConnectionsData } = useMutation({
    mutationKey: ["/account/connections"],
    mutationFn: () => getConnections({ IsAdmin: true, CurrentUserId: "" }),
    meta: {
      errorMessage: "Failed to get connection list due to the following error:",
    },
    onSuccess: (connectionListData) => {
      if (connectionListData == null) return;
      const data = connectionListData.connections ?? [];
      const user = userInitial!;
      // Map connection name to permission list
      const newPermissions = data.map((x) => ({
        id: x.id,
        rowName: x.name,
        driver: x.driver,
        opsAllowed:
          user.permissions.filter(
            (y: { connectionId: any }) => y.connectionId === x.id,
          )[0] !== undefined
            ? user.permissions.filter((y) => y.connectionId === x.id)[0]
                ?.opsAllowed
            : 0,
        type: "Connection",
      }));
      user.permissions = newPermissions;

      const userString = JSON.stringify(user);
      const userJsonUpdated = JSON.parse(userString);
      setUserInitial(userJsonUpdated);
    },
  });

  useEffect(() => {
    async function fetchData() {
      await getUserInfo();
    }

    fetchData();
  }, []); // eslint-disable-line

  useEffect(() => {
    async function fetchData() {
      await getConnectionsData();
      await getWorkspaces();
      setLoading(false);
    }

    if (userInitial !== null) {
      fetchData();

      const impersonationState = getImpersonationFlag();
      setInitialImpersonationState(impersonationState);
    }
  }, [userInitialFirstTime]); // eslint-disable-line

  const hasRole = (role: string | UserRole, userRole: UserRole): boolean => {
    const parsedRole = typeof role === "string" ? parseInt(role, 10) : role;

    return parsedRole === userRole;
  };

  function getImpersonationFlag(): boolean {
    if (userInitial === null) return false;

    return hasRole(userInitial.role, UserRole.Admin)
      ? false
      : userInitial.canBeImpersonated;
  }

  function inputUpdated() {
    setUnsavedChanges(true);
  }

  async function getUserInfo() {
    if (!userId) {
      navigate("/user");
      return;
    }

    const getQueryInfoUrl = `/users/${userId}`;
    const { status, payload } = await api.callAPI(
      RequestType.Get,
      getQueryInfoUrl,
      "Failed to get user info due to the following error:",
    );

    if (status === 200) {
      setUserInitial(payload);
      setUserInitialFirstTime(true);
    }
  }

  async function getWorkspaces() {
    const { status, payload } = await api.callAPI(
      RequestType.Get,
      "/workspaces",
      "Error fetching workspaces:",
    );
    if (status === 200) {
      const data = payload;
      const user = userInitial!;
      // Map workspace name to permission list
      const newPermissions =
        data.workspaces?.map((x: any) => ({
          id: x.id,
          rowName: x.name,
          opsAllowed:
            user.workspacePermissions.filter(
              (y) => y.workspaceId === x.id,
            )[0] !== undefined
              ? user.workspacePermissions.filter(
                  (y) => y.workspaceId === x.id,
                )[0]?.opsAllowed
              : 0,
          type: "Workspace",
        })) ?? [];

      if (user.permissions) {
        user.permissions = [...user.permissions, ...newPermissions];
        user.permissions.sort((a, b) => a.rowName.localeCompare(b.rowName));
      }

      const userString = JSON.stringify(user);
      const uesrJsonUpdated = JSON.parse(userString);
      setUserInitial(uesrJsonUpdated);
      setUserInitialPermissions(userInitial!.permissions);
    }
  }

  function openDeleteUserModal(event: React.MouseEvent) {
    event.stopPropagation();
    const modal = {
      title: "Delete User",
      body: <text>Are you sure you want to delete this user?</text>,
      primaryButton: (
        <Button color="danger" onClick={onDeleteUser}>
          Confirm
        </Button>
      ),
      secondaryButton: (
        <Button color="secondary" onClick={modalContext.toggleModal}>
          Close
        </Button>
      ),
      displayToggleCloseButton: true,
      displayed: true,
    } as IModalProps;
    modalContext.setModal(modal);
  }

  async function onDeleteUser() {
    const deleteQueryUrl = `/users/${userId}`;
    setUnsavedChanges(false);

    const { status } = await api.callAPI(
      RequestType.Delete,
      deleteQueryUrl,
      "Failed to delete user due to the following error:",
    );
    if (status === 200 || status === 204) {
      modalContext.toggleModal();
      navigate("/user");
      ToastrSuccess(
        "User successfully deleted",
        `User ${userInitial!.email} was successfully deleted.`,
      );
    }
  }

  function openResendInviteModal(event: React.MouseEvent) {
    event.stopPropagation();
    const modal = {
      title: "Resend Invite",
      body: (
        <text>
          Are you sure you want to send a new invitation email to this user?
        </text>
      ),
      primaryButton: (
        <Button color="primary" onClick={onResendInvite}>
          Confirm
        </Button>
      ),
      secondaryButton: (
        <Button color="secondary" onClick={modalContext.toggleModal}>
          Close
        </Button>
      ),
      displayToggleCloseButton: true,
      displayed: true,
    } as IModalProps;
    modalContext.setModal(modal);
  }

  async function onResendInvite() {
    const postQueryUrl = `/users/invite/${userId}`;

    const { status, payload } = await api.callAPI(
      RequestType.Post,
      postQueryUrl,
      "Failed to resend email invite due to the following error:",
    );
    if (status === 200) {
      const data = payload;
      const user = userInitial!;
      user.inviteExpires = data.expires;

      const userInitialString = JSON.stringify(user);
      const userInitialUpdated = JSON.parse(userInitialString);
      setUserInitial(userInitialUpdated);

      modalContext.toggleModal();
      ToastrSuccess(
        "Invite Successfully Resent!",
        "You have successfully invited user to CData Connect Cloud.",
      );
    }
  }

  async function handleValidSubmit(
    values: IFormValues,
    actions: FormikHelpers<IFormValues>,
  ) {
    const user = userInitial!;
    const data: IFormValues = values;
    const connectionPermissions = user.permissions.filter(
      (item: { type: string }) => item.type === "Connection",
    );
    const workspacePermissions = user.permissions.filter(
      (item) => item.type === "Workspace",
    );
    data.permissions = connectionPermissions.map(
      (permission: { id: any; opsAllowed: any }) => ({
        ConnectionId: permission.id,
        OpsAllowed: permission.opsAllowed,
      }),
    );
    data.workspacePermissions = workspacePermissions.map((permission) => ({
      WorkspaceId: permission.id,
      OpsAllowed: permission.opsAllowed,
    }));

    await manageImpersonationFlags(data);

    const url = `/users/${user.id}`;
    const { status } = await api.callAPI(
      RequestType.Put,
      url,
      "Failed to update user due to the following error:",
      data,
    );
    if (status === 200) {
      ToastrSuccess(
        "User Updated Successfully!",
        `User ${user.email} has been updated successfully.`,
      );
      setUnsavedChanges(false);
      setUnsavedPermissionChanges(false);
      actions.resetForm({ values });
    }
  }

  async function manageImpersonationFlags(data: IFormValues) {
    data.CanBeImpersonated = hasRole(data.Role!, UserRole.Admin)
      ? true
      : data.CanBeImpersonated;

    const user = userInitial!;

    const consentPermissionWasRemoved =
      user.canBeImpersonated && !data.CanBeImpersonated;

    user.canBeImpersonated = data.CanBeImpersonated!;

    //If the users impersonation ability was just removed expire any consents they have open
    if (consentPermissionWasRemoved) {
      const data = {
        expirationDate: new Date().toISOString(),
      };

      const url = `/users/impersonationConsent/${user.id}`;
      const { status } = await api.callAPI(
        RequestType.Put,
        url,
        "Failed to update user impersonation consent due to the following error:",
        data,
      );

      return status;
    }

    return 200;
  }

  function renderEditInviteCard() {
    const user = userInitial!;

    const initialFormValues: IFormValues = {
      Role: user.role.toString(),
      CanBeImpersonated: initialImpersonationState,
    };

    return (
      <Formik initialValues={initialFormValues} onSubmit={handleValidSubmit}>
        {({ handleChange, handleSubmit, values, dirty, isValid }) => {
          // CLOUD-12790: Move or remove this useEffect so that it's not being called inside a callback
          // eslint-disable-next-line
          useEffect(() => {
            setUnsavedChanges(dirty);
          }, [dirty]);
          return (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col className="mb-4">
                  <NavLink
                    className="align-middle text-dark"
                    to={{
                      pathname: "/user",
                    }}
                  >
                    <i className="fa fa-arrow-left fa-lg back-arrow" />
                  </NavLink>
                  <h1 className="h3 d-inline align-middle h3 ms-3">
                    Edit User
                  </h1>
                </Col>
                <Col className="text-end">
                  <Button
                    type="button"
                    color="secondary"
                    className="card-actions me-1"
                    hidden={isSupportImpersonationActive}
                    onClick={(event: React.MouseEvent) =>
                      openDeleteUserModal(event)
                    }
                    data-testid="button-delete-user"
                  >
                    <i className="fa fa-times align-middle me-2 no-pointer-event" />
                    Delete User
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    className="card-actions float-end"
                    disabled={
                      !unsavedChanges &&
                      !(dirty && isValid) &&
                      !unsavedPermissionChanges
                    }
                    hidden={isSupportImpersonationActive}
                    data-testid="button-save-changes"
                  >
                    <i className="fa fa-save fa-sm align-middle mb-1 me-2 no-pointer-event" />
                    Save Changes
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col>
                          <CardTitle className="ms-1 mb-0">General</CardTitle>
                        </Col>
                        <Col className="text-end">
                          <Button
                            key="resendInviteButton"
                            className="edit-resend-invite-button"
                            onClick={(event: React.MouseEvent) =>
                              openResendInviteModal(event)
                            }
                          >
                            Resend Invite
                          </Button>
                        </Col>
                      </Row>
                      <FormGroup className="my-3">
                        <Label className="form-field-title" for="email">
                          Email
                        </Label>
                        <Input
                          id="email"
                          name="email"
                          defaultValue={user.email}
                          disabled
                        ></Input>
                      </FormGroup>

                      <FormGroup className="my-3">
                        <Label
                          className="form-field-title"
                          for="expirationTime"
                        >
                          Expiration Time
                        </Label>
                        <Input
                          id="expirationTime"
                          name="expirationTime"
                          defaultValue={
                            formatUTCDateTime(user.inviteExpires!) + " UTC"
                          }
                          disabled
                        ></Input>
                      </FormGroup>

                      <FormGroup className="my-3">
                        <Label className="form-field-title" for="inviteCode">
                          Invite Code
                        </Label>
                        <Input
                          id="inviteCode"
                          name="inviteCode"
                          defaultValue={user.inviteCode}
                          disabled
                        ></Input>
                      </FormGroup>

                      <FormGroup className="last-form-field">
                        <Label className="form-field-title" for="role">
                          Role
                        </Label>
                        <Input
                          className="caret"
                          name="Role"
                          type="select"
                          defaultValue={values.Role}
                          onChange={handleChange}
                          onInput={inputUpdated}
                          disabled={
                            isSupportImpersonationActive ||
                            isOnlySpreadsheetClient
                          }
                        >
                          <option key="0" value="0">
                            Administrator
                          </option>
                          <option key="1" value="1">
                            Query
                          </option>
                        </Input>
                      </FormGroup>
                      {flags.impersonation.enabled &&
                      hasRole(values.Role!, UserRole.Query) ? (
                        <>
                          <div className="toggle-btn">
                            <CardTitle tag="h3" className="mt-4 mb-3">
                              Support Access Privileges
                            </CardTitle>
                            <div className="switch">
                              <FormGroup
                                switch
                                className="form-check form-switch custom-form"
                              >
                                <Input
                                  type="switch"
                                  name="CanBeImpersonated"
                                  role="switch"
                                  defaultChecked={initialImpersonationState}
                                  onChange={handleChange}
                                  disabled={isSupportImpersonationActive}
                                />
                              </FormGroup>
                            </div>
                          </div>
                          <div>{supportImpersonationText}</div>
                        </>
                      ) : (
                        <></>
                      )}
                    </CardBody>
                  </Card>
                  <ConnectForSpreadsheetsBlocker>
                    <PermissionsCard
                      permissions={user.permissions}
                      setUnsavedChanges={() => {
                        setUnsavedPermissionChanges(
                          !_.isEqual(user.permissions, userInitialPermissions),
                        );
                      }}
                      updatePermissions={(permissions: any[]) => {
                        const userString = JSON.stringify(userInitial);
                        const userJson = JSON.parse(userString);
                        userJson.permissions = permissions;
                        setUserInitial(userJson);
                      }}
                      rowColumnName={"Entity"}
                    />
                  </ConnectForSpreadsheetsBlocker>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    );
  }

  const handleToggleChange = (e: any) => {
    setInitialImpersonationState(e.target.checked);

    const deactivatingPreviouslyActiveImpersonation =
      getImpersonationFlag() && !e.target.checked;
    if (
      hasRole(loggedInUsersRole, UserRole.Admin) &&
      deactivatingPreviouslyActiveImpersonation
    ) {
      setIsModalOpen(true);
    }
  };

  function renderEditUserCard() {
    const user = userInitial!;

    const initialFormValues = {
      FirstName: user.firstName,
      LastName: user.lastName,
      Email: user.email,
      Role: user.role.toString(),
      CanBeImpersonated: initialImpersonationState,
    };

    const validationSchema = Yup.object().shape({
      FirstName: Yup.string()
        .min(1, "First name must be between 1 and 100 characters")
        .max(100, "First name must be between 1 and 100 characters")
        .required("This is a required field"),
      LastName: Yup.string()
        .min(1, "Last name must be between 1 and 100 characters")
        .max(100, "Last name must be between 1 and 100 characters")
        .required("This is a required field"),
    });

    const resetEditUserForm = () => {
      setInitialImpersonationState(getImpersonationFlag());

      formikRef.current?.resetForm();
    };

    const renderDeactivateImpersonationWarning = () => {
      return (
        isModalOpen && (
          <CDataModalV2
            fade={false}
            modalSize="lg"
            displayed={isModalOpen}
            close={() => {
              setIsModalOpen(false);
              resetEditUserForm();
            }}
            title="Disable Support Access"
            spacedFooter={false}
            primaryButton={
              <Button color="primary" onClick={() => setIsModalOpen(false)}>
                Confirm
              </Button>
            }
            secondaryButton={
              <Button
                color="secondary"
                onClick={() => {
                  setIsModalOpen(false);
                  resetEditUserForm();
                }}
              >
                Cancel
              </Button>
            }
          >
            <div>
              Disabling support access privileges for this user will revoke
              their ability to grant support access and will immediately revoke
              access to support in the event they are currently addressing an
              issue on their account. Are you sure you want to proceed?
            </div>
          </CDataModalV2>
        )
      );
    };

    return (
      <Formik
        innerRef={formikRef}
        initialValues={initialFormValues}
        validationSchema={validationSchema}
        onSubmit={handleValidSubmit}
      >
        {({ handleSubmit, dirty, isValid, handleChange, values }) => {
          // CLOUD-12790: Move or remove this useEffect so that it's not being called inside a callback
          // eslint-disable-next-line
          useEffect(() => {
            setUnsavedChanges(dirty);
          }, [dirty]);
          return (
            <Form onSubmit={handleSubmit}>
              {renderDeactivateImpersonationWarning()}
              <Row>
                <Col className="mb-4">
                  <NavLink
                    className="align-middle text-dark"
                    to={{
                      pathname: "/user",
                    }}
                  >
                    <i
                      className="fa fa-arrow-left fa-lg back-arrow"
                      data-testid="back-arrow-button"
                    />
                  </NavLink>
                  <h1 className="h3 d-inline align-middle h3 ms-3">
                    Edit User
                  </h1>
                </Col>
                <Col className="text-end">
                  <Button
                    type="button"
                    color="secondary"
                    className="card-actions me-1"
                    onClick={(event: any) => openDeleteUserModal(event)}
                    hidden={isSupportImpersonationActive}
                  >
                    <i className="fa fa-times align-middle me-2 no-pointer-event" />
                    Delete User
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    className="card-actions float-end"
                    disabled={
                      !unsavedChanges &&
                      !(dirty && isValid) &&
                      !unsavedPermissionChanges
                    }
                    hidden={isSupportImpersonationActive}
                  >
                    <i className="fa fa-save fa-sm align-middle mb-1 me-2 no-pointer-event" />
                    Save Changes
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <h5 className="card-title mb-3">General</h5>
                      <TextInput
                        label="First Name"
                        name="FirstName"
                        isRequired
                        data-testid="input-first-name"
                        isDisabled={isSupportImpersonationActive}
                      />
                      <TextInput
                        label="Last Name"
                        name="LastName"
                        isRequired
                        isDisabled={isSupportImpersonationActive}
                      />
                      <TextInput label="Email" name="Email" isDisabled />
                      <DropdownListInput
                        label="Role"
                        name="Role"
                        list={[
                          <>
                            <option key="0" value="0">
                              Administrator
                            </option>
                            <option key="1" value="1">
                              Query
                            </option>
                          </>,
                        ]}
                        defaultValue={user.role}
                        disabled={
                          isSupportImpersonationActive ||
                          isOnlySpreadsheetClient
                        }
                      />
                      {flags.impersonation.enabled &&
                      hasRole(values.Role!, UserRole.Query) ? (
                        <>
                          <div className="toggle-btn">
                            <CardTitle tag="h3" className="mt-4 mb-3">
                              Support Access Privileges
                            </CardTitle>
                            <div className="switch">
                              <FormGroup
                                switch
                                className="form-check form-switch custom-form"
                              >
                                <Input
                                  type="switch"
                                  name="CanBeImpersonated"
                                  role="switch"
                                  checked={initialImpersonationState}
                                  onChange={(e) => {
                                    handleToggleChange(e);
                                    handleChange(e);
                                  }}
                                  disabled={isSupportImpersonationActive}
                                />
                              </FormGroup>
                            </div>
                          </div>
                          <div>{supportImpersonationText}</div>
                        </>
                      ) : (
                        <></>
                      )}
                    </CardBody>
                  </Card>
                  <ConnectForSpreadsheetsBlocker>
                    <PermissionsCard
                      permissions={user.permissions}
                      setUnsavedChanges={() => {
                        setUnsavedPermissionChanges(
                          !_.isEqual(user.permissions, userInitialPermissions),
                        );
                      }}
                      updatePermissions={(permissions: any[]) => {
                        const userString = JSON.stringify(userInitial);
                        const userJson = JSON.parse(userString);
                        userJson.permissions = permissions;
                        setUserInitial(userJson);
                      }}
                      rowColumnName={"Entity"}
                    />
                  </ConnectForSpreadsheetsBlocker>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    );
  }

  let contents;
  if (loading) {
    contents = (
      <Container fluid className="p-0">
        <Loader />
      </Container>
    );
  } else {
    if (userInitial!.isInvite) {
      contents = renderEditInviteCard();
    } else {
      contents = renderEditUserCard();
    }
  }

  return (
    <Container fluid className="p-0 pages-users-EditUser">
      <PromptWithModal
        when={unsavedChanges || unsavedPermissionChanges}
        navigate={(path: string) => navigate(path)}
      />
      {contents}
    </Container>
  );
}

export default EditUser;
