import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";

import Page404 from "../auth/Page404";
import AdminGuard from "../../components/guards/AdminGuard";
import OData from "./OData";
import { ModalContext } from "../../routes/ModalContext";
import EditTable from "./EditTable";

const ODataRoutes = () => {
  const modalContext = useContext(ModalContext);

  return (
    <Routes>
      {/* /odata route */}
      <Route
        path="/"
        element={
          <AdminGuard>
            <OData
              showErrorModal={modalContext.showError}
              showTimeoutModal={modalContext.showTimeout}
              showUnsupportedBrowser={modalContext.showUnsupportedBrowser}
              setModal={modalContext.setModal}
              toggleModal={modalContext.toggleModal}
            />
          </AdminGuard>
        }
      />

      <Route
        path="editTable"
        element={
          <AdminGuard>
            <EditTable
              showErrorModal={modalContext.showError}
              showTimeoutModal={modalContext.showTimeout}
              showUnsupportedBrowser={modalContext.showUnsupportedBrowser}
              setModal={modalContext.setModal}
              toggleModal={modalContext.toggleModal}
            />
          </AdminGuard>
        }
      />

      <Route path="*" element={<Page404 showBranding={false} />} />
    </Routes>
  );
};

export default ODataRoutes;
