import { APIPaginationType } from "../../../../../models";
import { GLOBAL_SETTINGS_CONSTANTS as Constants } from "../../../ApiConnector.constants";

export interface PaginationField {
  label: string;
  id: string;
  type: PaginationInputType;
  name: string;
  helperText?: string;
  required?: boolean;
  options?: PaginationRadioOption[];
}

export interface PaginationRadioOption {
  label: string;
  id: string;
  paginationType: APIPaginationType;
  correspondingField: string;
  infoIconText: string;
}

export enum PaginationInputType {
  Text,
  Select,
}

export const offsetProps: PaginationField[] = [
  {
    label: "Offset Param",
    id: "OffsetParam",
    type: PaginationInputType.Text,
    name: Constants.OFFSET_PARAM,
    required: true,
    helperText:
      "The name of the URL parameter that defines how many records to skip per page.",
  },
  {
    label: "Offset Starting Number",
    id: "OffsetStartingNumber",
    type: PaginationInputType.Text,
    name: Constants.OFFSET_START,
    helperText:
      'Set this value to "0" or "1" depending upon the API you are connecting to. "0" is normally the most common value for this field.',
  },
  {
    label: "Page Size Param",
    id: "PageSizeParam",
    type: PaginationInputType.Text,
    name: Constants.PAGE_SIZE_PARAM,
    helperText:
      "The name of the URL parameter that defines how many records to include per page.",
  },
  {
    label: "Page Size",
    id: "PageSize",
    type: PaginationInputType.Text,
    name: Constants.PAGE_SIZE,
    required: true,
    helperText:
      "The number of records to request for each page. This is also used to calculate the offset value." +
      " If Paging Size Param is not set, set this value to the API default.",
  },
];

export const numberProps: PaginationField[] = [
  {
    label: "Page Number Param",
    id: "PageNumberParam",
    type: PaginationInputType.Text,
    name: Constants.PAGE_NUMBER_PARAM,
    required: true,
    helperText:
      "The name of the URL parameter that defines which page to retrieve.",
  },
  {
    label: "Page Size Param",
    id: "PageSizeParam",
    type: PaginationInputType.Text,
    name: Constants.PAGE_SIZE_PARAM,
    helperText:
      "The name of the URL parameter that defines how many records to include per page." +
      " Leave this setting blank to use the API default.",
  },
  {
    label: "Page Size",
    id: "PageSize",
    type: PaginationInputType.Text,
    name: Constants.PAGE_SIZE,
    helperText:
      "The number of records to request for each page. Leave this blank to use the API default.",
  },
];

const tokenRadioOptions: PaginationRadioOption[] = [
  {
    label: "URL Parameter",
    id: "UrlParameterOption",
    paginationType: APIPaginationType.PageToken,
    correspondingField: Constants.REQUEST_PARAM,
    infoIconText: "Pass the paging token in a URL parameter.",
  },
  {
    label: "Request Body",
    id: "RequestBodyOption",
    paginationType: APIPaginationType.PageToken,
    correspondingField: Constants.REQUEST_PATH,
    infoIconText: "Pass the paging token in the request body.",
  },
];

export const staticTokenProps: PaginationField[] = [
  {
    label: "Token Path",
    id: "TokenPath",
    type: PaginationInputType.Text,
    name: Constants.TOKEN_PATH,
    required: true,
    helperText: "The path of the paging token returned in the API response.",
  },
  {
    label: "Has More Path",
    id: "HasMorePath",
    type: PaginationInputType.Text,
    name: Constants.HAS_MORE_PATH,
    helperText:
      "The path of the element that determines if there are remaining records.",
  },
  {
    label: "Token Source",
    id: "TokenSource",
    type: PaginationInputType.Select,
    name: Constants.TOKEN_SOURCE,
    options: tokenRadioOptions,
  },
];

export const dynamicTokenProps: PaginationField[] = [
  {
    label: "URL Parameter",
    id: "RequestParam",
    type: PaginationInputType.Text,
    name: Constants.REQUEST_PARAM,
    required: true,
    helperText: "The name of the URL parameter to define the next-page token.",
  },
  {
    label: "Request Body",
    id: "RequestBody",
    type: PaginationInputType.Text,
    name: Constants.REQUEST_PATH,
    required: true,
    helperText:
      "The path in the request body where the next-page token is passed.",
  },
];

const urlRadioOptions: PaginationRadioOption[] = [
  {
    label: "Response Body",
    id: "ResponseBodyOption",
    paginationType: APIPaginationType.URL,
    correspondingField: Constants.URL_PATH,
    infoIconText:
      "The next-page URL is obtained from the body of the response.",
  },
  {
    label: "Response Header",
    id: "ResponseHeaderOption",
    paginationType: APIPaginationType.URL,
    correspondingField: Constants.URL_HEADER,
    infoIconText: "The next-page URL is obtained from a response header.",
  },
];

export const staticUrlProps: PaginationField[] = [
  {
    label: "Response Type",
    id: "ResponseType",
    type: PaginationInputType.Select,
    name: Constants.URL_SOURCE,
    options: urlRadioOptions,
  },
];

export const dynamicUrlProps: PaginationField[] = [
  {
    label: "Response Body",
    id: "ResponseBody",
    type: PaginationInputType.Text,
    name: Constants.URL_PATH,
    required: true,
    helperText: "The path of the next-page URL returned in the response body.",
  },
  {
    label: "Response Header",
    id: "ResponseHeader",
    type: PaginationInputType.Text,
    name: Constants.URL_HEADER,
    required: true,
    helperText:
      "The name of the HTTP response header returning the next-page URL.",
  },
];

export const paginationFieldsToResetOnTypeChange = [
  Constants.OFFSET_PARAM,
  Constants.OFFSET_START,
  Constants.PAGE_SIZE_PARAM,
  Constants.PAGE_SIZE,
  Constants.PAGE_NUMBER_PARAM,
  Constants.TOKEN_PATH,
  Constants.HAS_MORE_PATH,
  Constants.REQUEST_PARAM,
  Constants.REQUEST_PATH,
  Constants.URL_PATH,
  Constants.URL_HEADER,
];
