import React, { useContext, useEffect, useState } from "react";
import { Input, FormText, FormGroup, Button } from "reactstrap";
import { ToastrError } from "../../../../services/toastrService";
import { ModalContext } from "../../../../routes/ModalContext";
import { IModalProps } from "../../../../components/CDataModal";
import { FileCard } from "../../../../components/filecard";
import { getSupportEmailAddress } from "../../../../utility/LocalizedEmailAddresses";
import { useAPI } from "../../../../components/useAPI";
import { RequestType } from "../../../../components/withAPI";
import { IConnectionInfo } from "../../models/IConnectionInfo";

interface IOracleWalletFileManager {
  onFileChangeCallback: (file: File | null) => void;
  handleDeleteClick: () => void;
  walletFilePresent: boolean;
  connectionId: string | undefined;
  formRef: React.RefObject<HTMLFormElement> | undefined;
  driverType: string;
  connectionInfo: IConnectionInfo;
  hasUserConnectionString: boolean;
}

const OracleWalletFileManager = (props: IOracleWalletFileManager) => {
  const api = useAPI();
  const modalFunctions = useContext(ModalContext);
  const [isFilePresent, setIsFilePresent] = useState(props.walletFilePresent);
  const [inputKey, setInputKey] = useState(Math.random().toString(36));

  useEffect(() => {
    setIsFilePresent(props.walletFilePresent);
  }, [props.walletFilePresent]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files!.length === 0) {
      return;
    }
    const file = event.target.files![0];
    if (file && file.size <= 102400 && file.name.endsWith(".sso")) {
      props.onFileChangeCallback(file);
      setIsFilePresent(true);
    } else {
      ToastrError(
        "Unable to upload file",
        <span>
          We could not successfully upload the file provided. Double check that
          the file has not been altered and originates from your Oracle Wallet
          zip folder. If the problem persists, please reach out to{" "}
          <a
            href={`mailto:${getSupportEmailAddress()}`}
            className="support-hyperlink"
          >
            {getSupportEmailAddress()}
          </a>{" "}
          for further assistance.
        </span>,
      );
      setIsFilePresent(false);
    }
  };

  const handleDeleteClick = () => {
    const modal = {
      title: "Delete File",
      body: (
        <text>
          Deleting your Oracle Wallet SSO File will require that you upload a
          new one in order to successfully authenticate your connection. Are you
          sure you want to proceed?
        </text>
      ),
      secondaryButton: (
        <Button color="dark" onClick={() => modalFunctions.toggleModal()}>
          Cancel
        </Button>
      ),
      primaryButton: (
        <Button
          color="danger"
          onClick={async () => {
            if (props.connectionId) {
              const { status, error } = await api.callAPI(
                RequestType.Delete,
                `/account/connections/${props.connectionId}/oauthTokens`,
                "",
              );

              if (status !== 200) {
                if (status !== 401) {
                  modalFunctions.showTimeout();
                } else {
                  const err = error;
                  modalFunctions.showError(
                    "Failed to delete existing OAuth tokens due to the following error:",
                    err.error,
                  );
                  throw Object.assign(
                    new Error("Failed to delete existing OAuth tokens."),
                    { code: 500 },
                  );
                }
              }
            }
            setInputKey(Math.random().toString(36));
            modalFunctions.toggleModal();
            props.handleDeleteClick();
          }}
        >
          Delete
        </Button>
      ),
      displayToggleCloseButton: true,
      displayed: true,
      modalSize: "lg",
    } as IModalProps;

    modalFunctions.setModal(modal);
  };

  return (
    <>
      <FormGroup>
        <Input
          type="file"
          onChange={handleFileChange}
          accept={".sso"}
          data-testid="file-input"
          key={inputKey}
        />
        <FormText>
          Upload the &apos;cwallet.sso&apos; file found in the Oracle Wallet zip
          folder, which can be downloaded from the Database Connection menu of
          your Oracle Autonomous Database. Maximum file size: 100kb.
        </FormText>
      </FormGroup>
      {isFilePresent && (
        <FileCard
          fileName={"cwallet.sso"}
          handleDeleteClick={handleDeleteClick}
        />
      )}
    </>
  );
};

export default OracleWalletFileManager;
