import { Card, CardBody, Col, Row } from "reactstrap";
import Grid from "@mui/material/Unstable_Grid2";
import { IScheduledQuery } from "../../../../models/ScheduledQueries/IScheduledQuery";
import { CDataTypography } from "../../../../components/text/CDataTypography";
import { useFormikContext } from "formik";
import { CDataFormTextField } from "../../../../components/form/CDataFormTextField";

interface IScheduledQueryHeaderCard {
  setUnsavedChanges: (unsavedChanges: boolean) => void;
}

export const ScheduledQueryHeaderCard = (props: IScheduledQueryHeaderCard) => {
  const { setUnsavedChanges } = props;

  const { errors, values, setValues, touched } =
    useFormikContext<IScheduledQuery>();

  const setQueryName = (newName: string) => {
    setValues({ ...values, name: newName }, true);
    setUnsavedChanges(true);
  };

  return (
    <Card className="w-100" data-testid="scheduled-query-header-card">
      <CardBody>
        <Row>
          <Col className="mb-3">
            <CDataTypography variant="typography-variant-card-title">
              General
            </CDataTypography>
          </Col>
        </Row>
        <Row>
          <Col>
            <Grid className="header-card" container columns={2} spacing={2}>
              {/* Title row */}
              <Grid xs={1} className="title-cell-text">
                Name
              </Grid>
              <Grid xs={1} className="title-cell-text">
                Job Type
              </Grid>
              {/* Body row */}
              <Grid xs={1} className="content-cell">
                <CDataFormTextField
                  name="name"
                  className="w-100"
                  isInvalid={touched.name && !!errors.name}
                  onChange={(e) => setQueryName(e.target.value)}
                  value={values.name}
                />
              </Grid>
              <Grid xs={1} className="content-cell">
                <CDataTypography>Scheduled Query</CDataTypography>
              </Grid>
            </Grid>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
