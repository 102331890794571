import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import {
  DropdownItem,
  DropdownToggle,
  ButtonDropdown,
  DropdownMenu,
  ButtonDropdownProps,
} from "reactstrap";

export interface IResultsDetailsGridProps {
  columns: any[];
  noData: () => JSX.Element;
  resultDropdownOpen: boolean;
  toggleResultDropdownOpen: ButtonDropdownProps["toggle"];
  gridData: any[];
  gridID: string;
  wrapperClassNames: string;
}

const ResultsDetailsGrid = (props: IResultsDetailsGridProps) => {
  return (
    <BootstrapTable
      bootstrap4
      wrapperClasses={props.wrapperClassNames}
      headerClasses="text-nowrap"
      rowClasses="text-nowrap fixed-row-height"
      id={props.gridID}
      bordered={true}
      hover={true}
      keyField="id"
      columns={props.columns}
      data={props.gridData}
      condensed={true}
      noDataIndication={props.noData}
      pagination={paginationFactory({
        sizePerPage: 10,
        withFirstAndLast: false,
        sizePerPageList: [1, 10, 25, 50],
        sizePerPageRenderer: ({
          options,
          currSizePerPage,
          onSizePerPageChange,
        }) => (
          <>
            <div className="pagination-container">
              <div className="rows-per-page">Rows per page:</div>
              <ButtonDropdown
                isOpen={props.resultDropdownOpen}
                toggle={props.toggleResultDropdownOpen}
              >
                <DropdownToggle
                  className="btn-outline-secondary btn-custom-primary"
                  caret
                >
                  {currSizePerPage}
                </DropdownToggle>
                <DropdownMenu>
                  {options.map((option) => (
                    <DropdownItem
                      key={option.page}
                      onClick={() => onSizePerPageChange(option.page)}
                    >
                      {option.text}{" "}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </ButtonDropdown>
            </div>
          </>
        ),
      })}
    />
  );
};
export default ResultsDetailsGrid;
