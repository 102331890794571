import { useMutation } from "@tanstack/react-query";
import { IConnection, IWorkspaceListItem, UserRole } from "../models";
import { getConnections } from "../pages/connections/connectionList/getConnections";
import { useUserInfo } from "./useUserInfo";
import { listWorkspaces } from "../api/dataSets/listWorkspaces";

interface IGetRestrictedNames {
  existingConnections?: IConnection[];
  existingWorkspaces?: IWorkspaceListItem[];
  excludedName?: string;
}

/** A hook that allows you to retrieve a list of connection and workspace names that are already in use. */
export function useNameAvailabilityCheck() {
  const user = useUserInfo();

  const { mutateAsync: getConnectionsAsync, isPending: gettingConnections } =
    useMutation({
      mutationKey: ["connections/list"],
      mutationFn: getConnections,
      meta: {
        errorMessage:
          "Failed to retrieve list of connections due to the following error:",
      },
    });

  const { mutateAsync: getWorkspacesAsync, isPending: gettingWorkspaces } =
    useMutation({
      mutationKey: ["workspaces/list"],
      mutationFn: listWorkspaces,
      meta: {
        errorMessage:
          "Failed to retrieve list of workspaces due to the following error:",
      },
    });

  const pendingFetch = gettingConnections || gettingWorkspaces;

  /** Optionally accepts an array of connections and/or workspaces if already available, and a name to exclude from the list, then retrives any data that is not provided, and returns an array of names. */
  const getRestrictedNames = async ({
    existingConnections,
    existingWorkspaces,
    excludedName,
  }: IGetRestrictedNames) => {
    let connections = existingConnections;
    let workspaces = existingWorkspaces;

    if (!existingConnections) {
      const connectionList = await getConnectionsAsync({
        IsAdmin: user.IsInRole(UserRole.Admin),
        CurrentUserId: user.Self.id,
      });
      connections = connectionList.connections ?? [];
    }

    if (!existingWorkspaces) {
      const workspaceList = await getWorkspacesAsync();
      workspaces = workspaceList.workspaces ?? [];
    }

    // We keep the null checks on these maps because the results from the fetch can be undefined if they're empty.
    const connectionNames =
      connections?.map((connection) => connection.name!.toLocaleLowerCase()) ??
      [];

    const workspaceNames =
      workspaces?.map((workspace) => workspace.name!.toLocaleLowerCase()) ?? [];

    const allNames = [...connectionNames, ...workspaceNames];

    // If there's a name we need to exclude from the filter, remove it from the results before returning.
    return excludedName
      ? allNames.filter((name) => name !== excludedName.toLocaleLowerCase())
      : allNames;
  };

  return {
    getRestrictedNames,
    pendingFetch,
  };
}
