import { useState } from "react";
import classnames from "classnames";

import {
  Col,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledTooltip,
} from "reactstrap";

export type ITabComponent = {
  tabName: string;
  tabEnum: string;
  tabComponent: JSX.Element;
  isVisible?: boolean;
  isDisabled?: boolean;
  disabledTooltip?: string;
};

export type ITapWrapperProps = {
  tabs: ITabComponent[];
  extraButton?: JSX.Element;
  onTabChange?: (tab: string) => void;
  smallerBottomPadding?: boolean;
  activeTabWhiteBackground?: boolean;
  currentTab?: string;
  setCurrentTab?: (tab: string) => void;
  mountWhenDisabled?: boolean;
  tabContentClassName?: string;
  /** If supplied, this element will appear on the right side of the content of each tab. To avoid re-renders, use React.memo when creating this element. */
  persistentElement?: JSX.Element;
};

/* A component that allows users to select tabs and controls which is currently displayed.
 * The component can be either controlled or uncontrolled. To make it controlled, pass the
 * currentTab and setCurrentTab props from the parent.  It will be uncontrolled if those
 * props are absent.
 */

export default function TabWrapper(props: ITapWrapperProps) {
  const [internalTab, setInternalTab] = useState<string>(setInitialTab());

  const currentTab = props.currentTab ? props.currentTab : internalTab;
  const setCurrentTab = props.setCurrentTab
    ? props.setCurrentTab
    : setInternalTab;

  function setInitialTab() {
    const params = new URLSearchParams(window.location.search);
    const paramValue = params.get("defaultTab") as string;
    if (paramValue !== null) {
      return paramValue;
    }
    return props.tabs[0].tabEnum;
  }

  function toggleTab(tab: string) {
    if (currentTab !== tab) {
      if (props.onTabChange) {
        props.onTabChange(tab);
      }
      setCurrentTab(tab);
    }
  }

  return (
    <span data-testid="nav-tab-id">
      <Row className="gx-0">
        <Col>
          <Nav
            tabs
            className={classnames("tabs", {
              "mb-2": props.smallerBottomPadding,
            })}
          >
            {props.tabs.map((tab: ITabComponent) => {
              return (
                <NavItem
                  hidden={tab.isVisible === false}
                  key={tab.tabEnum}
                  id={"id-" + tab.tabEnum}
                >
                  <NavLink
                    className={classnames({
                      active: currentTab === tab.tabEnum,
                      "background-white": props.activeTabWhiteBackground,
                    })}
                    onClick={() => toggleTab(tab.tabEnum)}
                    data-testid={"id-" + tab.tabEnum}
                    disabled={tab.isDisabled}
                  >
                    {tab.isDisabled && tab.disabledTooltip && (
                      <UncontrolledTooltip
                        placement="top"
                        target={"id-" + tab.tabEnum}
                      >
                        {tab.disabledTooltip}
                      </UncontrolledTooltip>
                    )}

                    {tab.tabName}
                  </NavLink>
                </NavItem>
              );
            })}
            {props.extraButton}
          </Nav>
        </Col>
      </Row>
      <Row>
        <Col>
          <TabContent
            activeTab={currentTab}
            className={classnames("top-filter-row", props.tabContentClassName)}
          >
            {props.tabs.map((tab: ITabComponent) => {
              return (
                <TabPane
                  hidden={tab.isVisible === false}
                  tabId={tab.tabEnum}
                  key={tab.tabEnum}
                  data-testid={"pane-id-" + tab.tabEnum}
                >
                  {tab.isDisabled && !props.mountWhenDisabled ? (
                    <></>
                  ) : (
                    tab.tabComponent
                  )}
                </TabPane>
              );
            })}
          </TabContent>
        </Col>
        {props.persistentElement}
      </Row>
    </span>
  );
}
