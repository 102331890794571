import React from "react";

interface ISidepane {
  children: JSX.Element;
  className?: string;
}

const Sidepane = (props: ISidepane) => {
  return (
    <div className={`pages-datasets-Sidepane ${props.className}`}>
      {props.children}
    </div>
  );
};

export default Sidepane;
