import React, { useEffect, useState } from "react";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Input } from "reactstrap";

/* A tanstack table that accepts a list of columns and the data that goes into those columns,
 * then renders a table accordingly. Each row has a checkbox next to it to allow for data
 * to be selected and passed back to the parent with the sendCheckedItems function.
 */

export interface ISelectableRowTableProps<T> {
  columns: ColumnDef<T>[];
  data: T[];
  checkedItems: T[];
  sendCheckedItems: (checkedItems: T[]) => void;
  searchEnabled: boolean;
  className: string;
}

function SelectableRowTable<T>(props: ISelectableRowTableProps<T>) {
  const [rowSelection, setRowSelection] = useState({});
  const [globalFilter, setGlobalFilter] = useState<string>("");

  const data = props.data;
  const columns = props.columns;
  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
      rowSelection,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  function getComponentName() {
    return "components-tables-SelectableRowTable";
  }

  // Monitors the table for changes in selected rows, then sends the changes up via sendCheckedItems
  useEffect(() => {
    // flatRows designates the actual values of the selected rows instead of their tanstack index designations
    const checkedTables: any = table.getSelectedRowModel().flatRows;

    // Creates an array to store the data in each selected row (designated by the value of "original")
    // and pushes each item to currentCheckedTablesList
    const currentCheckedTablesList: T[] = checkedTables.map(
      (selectedRow: any) => selectedRow.original,
    );

    props.sendCheckedItems(currentCheckedTablesList as T[]);
  }, [rowSelection]); // eslint-disable-line

  return (
    <div className={getComponentName()}>
      {props.searchEnabled ? (
        <div className="search-filter-bar">
          <i className="fa fa-regular fa-magnifying-glass align-middle me-2" />
          <Input
            value={globalFilter ?? ""}
            autoFocus={false}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setGlobalFilter(event.target.value)
            }
            placeholder="Search..."
          />
        </div>
      ) : null}
      <table className={props.className}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default SelectableRowTable;
