import { IQueryBuilderColumn } from "./IQueryBuilderColumn";
import { IQueryBuilderFilter } from "./IQueryBuilderFilter";
import { IQueryBuilderTable } from "./IQueryBuilderTable";
import { QueryBuilderFilterMethod } from "./QueryBuilderFilterMethod";
import { SortDirection } from "./SortDirection";

export type IQueryBuilderModel = {
  // The FROM table, must be set before the user can get to the Query Builder page.
  from: IQueryBuilderTable;
  joins: IQueryBuilderJoin[];
  filterMethod: QueryBuilderFilterMethod;
  filters: IQueryBuilderFilter[];
  sort: IQueryBuilderSort[];
  // This is the SELECT statement if the user wants to use MAX/MIN/COUNT etc.
  metrics: IQueryBuilderMetric[];
  groupBy: IQueryBuilderGroupBy[];
};

export type IQueryBuilderJoin = {
  joinType: QueryJoinType;
  left: IQueryBuilderColumn;
  right: IQueryBuilderColumn;
};

export enum QueryJoinType {
  InnerJoin = "Inner Join",
  RightJoin = "Right Join",
  LeftJoin = "Left Join",
  FullOuterJoin = "Full Outer Join",
}

export type IQueryBuilderSort = {
  column: IQueryBuilderColumn;
  direction: SortDirection;
};

// Metrics are aggregates for a GROUP BY.
// E.x. SUM(my_column), AVG(my_column), COUNT(my_column), etc.
export type IQueryBuilderMetric = {
  column: IQueryBuilderColumn;
  operatorType: QueryAggregateFunctionType;
};

export enum QueryAggregateFunctionType {
  COUNT = "COUNT",
  DISTINCT_COUNT = "DISTINCT COUNT",
  AVERAGE = "AVERAGE",
  MAX = "MAX",
  MIN = "MIN",
  SUM = "SUM",
}

export type IQueryBuilderGroupBy = {
  column: IQueryBuilderColumn;
};

export function buildDefaultQueryBuilderModel(
  fromClause: IQueryBuilderTable,
): IQueryBuilderModel {
  return {
    from: fromClause,
    joins: [],
    filterMethod: QueryBuilderFilterMethod.AND,
    sort: [],
    filters: [],
    groupBy: [],
    metrics: [],
  };
}
