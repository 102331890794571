import { createContext } from "react";
import { ISelectSchemaWizardContext } from "./SelectSchemaWizardContext";

export interface IAddAssetWizardContextType {
  workspaceID: string | null;
  containerName: string | null | undefined;
  setAssetList: ISelectSchemaWizardContext["setAssetList"];
}

export const AddAssetWizardContext = createContext<IAddAssetWizardContextType>({
  workspaceID: null,
  containerName: null,
  setAssetList: () => {
    // no-op
  },
});
