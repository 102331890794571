import { useContext, useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";

import { Button } from "reactstrap";
import { Form } from "react-bootstrap";

import { IAPI, withAPI, RequestType } from "../../components/withAPI";
import {
  withRouter,
  ComponentWithRouterProps,
} from "../../components/withRouter";
import { ModalContext } from "../../routes/ModalContext";
import { useAuthentication } from "../../hooks/useAuthentication";

type IUserSignUpProps = IAPI & ComponentWithRouterProps;

const UserSignUp = (props: IUserSignUpProps) => {
  const [isUserFound, setIsUserFound] = useState<boolean>(false);
  const modalContext = useContext(ModalContext);
  const auth = useAuthentication();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const paramValue = params.get("isUserFound") as string;
    if (paramValue) {
      setIsUserFound(true);
    }
  }, []);

  async function handleValidSubmit(values: any) {
    const accessToken = await auth.GetAccessTokenSilently();
    values = { ...values, accessToken, emailAddress: auth.Email };
    // Trim strings in input.
    values.AccountName = values.AccountName?.trim();
    values.InviteCode = values.InviteCode?.trim();
    const { status, payload, error } = await props.callAPI(
      RequestType.Post,
      "/account/createInvitedUser",
      "",
      values,
    );

    if (status === 200) {
      const res = payload;
      const accountInfo = {
        accounttype: "user",
        organization: res.account.organization,
        country: res.account.country,
        contactEmail: "",
        contactFirstName: values.FirstName,
        contactLastName: values.LastName,
        id: res.account.id,
        accountName: res.account.accountName,
      };
      props.router.navigate("/?signup", {
        state: { signUpFormValues: accountInfo },
      });
    } else {
      const err = error;
      modalContext.showError(
        "Failed to create user due to the following error:",
        err,
      );
    }
  }

  const initialFormValues = {
    FirstName: "",
    LastName: "",
    AccountName: "",
    InviteCode: "",
  };

  //If the user is found, fill out the minimum so we can clear the form validation
  if (isUserFound) {
    initialFormValues.AccountName = "1234";
    initialFormValues.InviteCode = "1234";
  }

  let validationSchema = null as any;
  validationSchema = Yup.object().shape({
    FirstName: Yup.string()
      .min(1, "First name must be between 1 and 100 characters")
      .max(100, "First name must be between 1 and 100 characters")
      .required("This is a required field"),
    LastName: Yup.string()
      .min(1, "Last name must be between 1 and 100 characters")
      .max(100, "Last name must be between 1 and 100 characters")
      .required("This is a required field"),
    AccountName: Yup.string()
      .matches(
        /^\s*[a-zA-Z0-9][a-zA-Z0-9_-]{0,48}[a-zA-Z0-9]\s*$/,
        "Invalid account name, please refer to your invite email.",
      )
      .required("This is a required field"),
    InviteCode: Yup.string()
      .matches(
        /^\s*[a-zA-Z0-9_-]{16}\s*$/,
        "Invalid invite code, please refer to your invite email.",
      )
      .required("This is a required field"),
  });

  if (isUserFound) {
    validationSchema = Yup.object().shape({
      FirstName: Yup.string()
        .min(1, "First name must be between 1 and 100 characters")
        .max(100, "First name must be between 1 and 100 characters")
        .required("This is a required field"),
      LastName: Yup.string()
        .min(1, "Last name must be between 1 and 100 characters")
        .max(100, "Last name must be between 1 and 100 characters")
        .required("This is a required field"),
    });
  }

  return (
    <div className="pages-auth">
      <div className="m-sm-4">
        <h3>Welcome</h3>
        <p className="font-weight-light text-muted">
          Before you get started, please fill out your account information
          below.
        </p>
      </div>
      <div className="m-sm-4">
        <Formik
          initialValues={initialFormValues}
          validationSchema={validationSchema}
          onSubmit={handleValidSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label className="required">First Name</Form.Label>
                <Form.Control
                  size="lg"
                  name="FirstName"
                  placeholder="Enter your first name"
                  value={values.FirstName}
                  isInvalid={Boolean(touched.FirstName && errors.FirstName)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.FirstName && (
                  <Form.Control.Feedback type="invalid">
                    {errors.FirstName?.toString()}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="required">Last Name</Form.Label>
                <Form.Control
                  size="lg"
                  name="LastName"
                  placeholder="Enter your last name"
                  value={values.LastName}
                  isInvalid={Boolean(touched.LastName && errors.LastName)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.LastName && (
                  <Form.Control.Feedback type="invalid">
                    {errors.LastName?.toString()}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group className="mb-3" hidden={isUserFound}>
                <Form.Label className="required">Account Name</Form.Label>
                <Form.Control
                  size="lg"
                  name="AccountName"
                  placeholder="Enter the name of the account that invited you"
                  value={values.AccountName}
                  isInvalid={Boolean(touched.AccountName && errors.AccountName)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.AccountName && (
                  <Form.Control.Feedback type="invalid">
                    {errors.AccountName?.toString()}
                  </Form.Control.Feedback>
                )}
                <div className="helper-text">
                  The Account Name that is located in the invitation email you
                  received.
                </div>
              </Form.Group>
              <Form.Group className="mb-3" hidden={isUserFound}>
                <Form.Label className="required">Invite Code</Form.Label>
                <Form.Control
                  size="lg"
                  name="InviteCode"
                  placeholder="Enter your invite code"
                  value={values.InviteCode}
                  onBlur={handleBlur}
                  isInvalid={Boolean(touched.InviteCode && errors.InviteCode)}
                  onChange={handleChange}
                />
                {!!touched.InviteCode && (
                  <Form.Control.Feedback type="invalid">
                    {errors.InviteCode?.toString()}
                  </Form.Control.Feedback>
                )}
                <div className="helper-text">
                  The Invite Code that is located in the invitation email you
                  received.
                </div>
              </Form.Group>
              <Form.Group className="text-center mt-4">
                <Button type="submit" color="primary" className="full-width">
                  Complete Sign Up
                </Button>
              </Form.Group>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default withAPI(withRouter(UserSignUp));
