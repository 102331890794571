import { isEmpty } from "lodash";

/**
 * Finds the first displayed form error from a Bootstrap `<Feedback />` component
 * and scrolls it into view.
 */
export function scrollToFirstValidationError() {
  // Wait one render for React to update.
  // The validation messages often do not show up until React has had a chance to re-render.
  setTimeout(() => {
    const validationErrors = document.querySelectorAll(".invalid-feedback");

    for (const error of validationErrors) {
      const myElement = error as HTMLElement;
      // Skip elements that are hidden.
      // See: https://stackoverflow.com/questions/19669786/check-if-element-is-visible-in-dom
      if (myElement.offsetParent == null) {
        continue;
      }

      if (
        !isEmpty(myElement.textContent?.trim()) ||
        myElement.children.length > 0
      ) {
        // Scroll so the element is visible, subtract some pixels so we don't scroll it to the very top of the page.
        window.scrollTo({
          top: myElement.getBoundingClientRect()?.top - 250,
        });
        break;
      }
    }
  }, 0);
}
