import { useEffect, useState } from "react";
import { ConnectionType, FeatureId } from "../../../models";
import classnames from "classnames";

import { Card, CardBody, Col, Row, Tooltip } from "reactstrap";

import { Form } from "react-bootstrap";
import { isFeatureAllowed } from "../../../utility/SubscriptionAddonsFactory";
import { getSettingsPageSubscriptionTab } from "../../../utility/SubscriptionProvider";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../redux/hooks";

interface ICredentialCardProps {
  existingConnectionType: ConnectionType;
  selectedConnectionType: ConnectionType;
  onCredentialTypeChange: (newCredentialType: string) => void;
  cardSource?: string;
  hideCredentialNote?: boolean;
}

const CredentialCard = (props: ICredentialCardProps) => {
  const [isUserDefinedFeatureAllowed, setIsUserDefinedFeatureAllowed] =
    useState<boolean>(false);
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const availableFeatureIds =
    useAppSelector(
      (state) => state.subscription?.limits?.availableFeatureIds,
    ) ?? [];

  const UDC_MODAL = "udcModal";

  function getComponentName() {
    return "pages-connections-components-CredentialCard";
  }

  useEffect(() => {
    checkForAllowedFeatures();
  }, []); // eslint-disable-line

  function onToggleCredentials(event: React.ChangeEvent<HTMLInputElement>) {
    props.onCredentialTypeChange(event.target.value);
  }

  function checkForAllowedFeatures() {
    setIsUserDefinedFeatureAllowed(
      isFeatureAllowed(availableFeatureIds, FeatureId.UserDefinedCredentials),
    );
  }

  function toggle() {
    setTooltipOpen(!tooltipOpen);
  }

  let credentialNote;
  if (props.hideCredentialNote) {
    credentialNote = null;
  } else if (props.cardSource === UDC_MODAL) {
    credentialNote = (
      <Row className="credentials-note-modal">
        <Col className="col-md-1 modal-message">
          <i className="fa fa-info-circle align-middle fa-lg" />
        </Col>
        <Col className="modal-message">
          Note: After clicking Confirm & Continue, you will be prompted to enter
          your credentials for this connection to re-authenticate it.
        </Col>
      </Row>
    );
  } else if (
    props.selectedConnectionType === ConnectionType.Shared &&
    props.existingConnectionType === ConnectionType.UserDefined
  ) {
    credentialNote = (
      <Row>
        <Col>
          <div className="credentials-note-yellow">
            <i className="fa fa-exclamation-triangle fa-lg info-icon" />
            Changing this setting will affect other user(s) who have access to
            the data attached to this connection.
          </div>
        </Col>
      </Row>
    );
  }

  return (
    <>
      <Card
        data-testid="credential-card"
        className={classnames(getComponentName(), {
          "no-margin-bottom": props.cardSource === UDC_MODAL,
        })}
        id="data-credentials"
      >
        <CardBody
          className={classnames(
            {
              "disabled-feature":
                !isUserDefinedFeatureAllowed && props.cardSource !== UDC_MODAL,
            },
            { "credentials-modal-body": props.cardSource === UDC_MODAL },
          )}
        >
          <h5
            className="card-title mb-3"
            hidden={props.cardSource === UDC_MODAL}
          >
            Data Credentials
          </h5>
          <Row>
            <Col>
              <div
                className="determine-title"
                hidden={props.cardSource === UDC_MODAL}
              >
                Determine how you want other users to access the data to this
                data source.
              </div>
            </Col>
          </Row>
          <Row className="ms-1" id="credential-options">
            <Form
              className={
                props.cardSource === UDC_MODAL ? "credentials-modal-row" : ""
              }
            >
              <Form.Check
                type="radio"
                label={
                  <div className="credentials-label">Shared Credentials</div>
                }
                name="credentials"
                value="shared"
                checked={props.selectedConnectionType === ConnectionType.Shared}
                onChange={(event) => onToggleCredentials(event)}
              />
              <div className="credentials-explanation">
                Allow other users to access the information attached to your
                account credentials for this connection.
              </div>
              <Form.Check
                type="radio"
                label={
                  <div className="credentials-label">User Credentials</div>
                }
                name="credentials"
                value="user"
                disabled={props.cardSource === UDC_MODAL}
                checked={
                  props.selectedConnectionType === ConnectionType.UserDefined
                }
                onChange={(event) => onToggleCredentials(event)}
              />
              <div className="credentials-explanation">
                Require other users to use their own account credentials before
                being able to access this connection.
              </div>
            </Form>
          </Row>
          {credentialNote}
        </CardBody>
      </Card>
      {!isUserDefinedFeatureAllowed && props.cardSource !== UDC_MODAL ? (
        <Tooltip
          placement="top"
          target="data-credentials"
          autohide={false}
          toggle={toggle}
          isOpen={tooltipOpen}
        >
          <a
            className="upgrade-link"
            onClick={() =>
              navigate(
                "/settings?defaultTab=" + getSettingsPageSubscriptionTab(),
              )
            }
          >
            Upgrade
          </a>
          &nbsp; your subscription with our Security Package add-on to use this
          feature.
        </Tooltip>
      ) : null}
    </>
  );
};

export default CredentialCard;
