import { BroadcastChannel } from "broadcast-channel";
import Cookies from "js-cookie";
import { useEffect, useRef } from "react";

// The function that we call once the user has finished their OAuth flow.
export type IOAuthCallback = (oauthData: Record<string, unknown>) => void;

/**
 * A simple hook that handles listening for the user to finish their OAuth Sign inflow in a different tab.
 *
 * See OAuthCallback.tsx for the code that sends the OAuth data back to this tab.
 */
export function useOAuthListener() {
  const oauthChannelRef = useRef<BroadcastChannel | null>(null);
  const timerRef = useRef<number | null>(null);
  const isListenerClosed = useRef<boolean>(false);

  /**
   * Listen for a BroadcastChannel call from the OAuthCallback.tsx component.
   *
   * The 3rd party site will redirect us to `/connections/oauth-callback/:callbackId` with query string parameters.
   * That OAuthCallback page just sends us these parameters via BroadcastChannel and closes the tab.
   *
   * We use the unique callback Id from the response body to verify that this is the correct tab waiting for the OAuth response.
   * If the user has multiple tabs open each tab can get this message.
   */
  async function startOAuthListener(
    callbackId: string,
    onOAuthCompleted: IOAuthCallback,
  ) {
    await cancelOAuthListener();
    isListenerClosed.current = false;

    const broadcast = new BroadcastChannel(callbackId);
    broadcast.onmessage = (qp) => {
      if (!isListenerClosed.current) {
        isListenerClosed.current = true;
        onOAuthCompleted(qp);
        cancelOAuthListener();
      }
    };

    oauthChannelRef.current = broadcast;

    // We use a cookie fallback to handle OEM customers putting the create connection page in an iframe.
    // BroadcastChannel cannot work across an iframe since it only looks at the top-level origin (the URL in the browser).
    timerRef.current = setInterval(() => {
      const cookie = Cookies.get(`oauthCallback-${callbackId}`);
      if (cookie) {
        const qp = JSON.parse(cookie);
        if (!isListenerClosed.current) {
          isListenerClosed.current = true;
          onOAuthCompleted(qp);
          cancelOAuthListener();
        }
      }
    }, 2000) as unknown as number;
  }

  /**
   * Cancel the OAuth listener if the user closes out of the OAuth Sign In flow.
   */
  async function cancelOAuthListener() {
    isListenerClosed.current = true;

    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }

    if (oauthChannelRef.current) {
      Cookies.remove(`oauthCallback-${oauthChannelRef.current.name}`);
      await oauthChannelRef.current.close();
      oauthChannelRef.current = null;
    }
  }

  // Cancel any OAuth listeners when the component is destroyed.
  useEffect(() => {
    return () => {
      cancelOAuthListener();
    };
  }, []);

  return {
    startOAuthListener,
    cancelOAuthListener,
  };
}
