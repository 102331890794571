import React from "react";
import { DropdownItem } from "reactstrap";
import {
  ButtonType,
  CDataButton,
} from "../../../components/buttons/CDataButton";
import { useAPI } from "../../../components/useAPI";
import { RequestType } from "../../../components/withAPI";
import { ToastrSuccess } from "../../../services/toastrService";

interface IRefreshMetadataButtonProps {
  isInDropdown: boolean;
  connectionId: string | null | undefined;
  connectionName: string | null | undefined;
}

export function RefreshMetadataButton(props: IRefreshMetadataButtonProps) {
  const { isInDropdown, connectionId, connectionName } = props;

  const api = useAPI();

  async function clearMetadata() {
    const { status } = await api.callAPI(
      RequestType.Delete,
      `/account/connection/${connectionId}/metadata`,
      "Error clearing metadata",
    );

    if (status === 200) {
      ToastrSuccess(
        "Metadata successfully cleared",
        `The metadata for ${connectionName ?? ""} has been successfully cleared.`,
      );
    }
  }

  if (isInDropdown) {
    return (
      <DropdownItem
        className="refreshMetadataButton"
        tag="a"
        onClick={() => clearMetadata()}
      >
        <i className="fas fa-trash align-middle me-2" />
        Clear Metadata
      </DropdownItem>
    );
  } else {
    return (
      <div className="refreshMetadataButton-container">
        <CDataButton
          className="refreshMetadataButton"
          buttonType={ButtonType.Borderless}
          onClick={() => clearMetadata()}
        >
          <i className="fas fa-trash align-middle me-2" />
          Clear Metadata
        </CDataButton>
      </div>
    );
  }
}
