import { Card, CardBody } from "reactstrap";
import { TitleAndToggleButtonRow } from "./TitleAndToggleButtonRow";
import { RunFrequencyRow } from "./RunFrequencyRow";
import { CacheSchemeRow } from "./CacheSchemeRow";
import { TimeCheckColumnDropdown } from "./TimeCheckColumnDropdown";
import { JobType } from "../../../../models/Cache/JobType";

interface ICacheJobDetailsCardProps {
  timeCheckColumnOptions: string[];
  setUnsavedChanges: (unsavedChanges: boolean) => void;
  checkColumnSupport: boolean;
}

export const CacheJobDetailsCard = ({
  timeCheckColumnOptions,
  setUnsavedChanges,
  checkColumnSupport,
}: ICacheJobDetailsCardProps) => {
  return (
    <Card
      className="cache-job-details-card w-100"
      data-testid="cache-job-details-card"
    >
      <CardBody>
        <TitleAndToggleButtonRow
          jobType={JobType.Caching}
          setUnsavedChanges={setUnsavedChanges}
        />
        <RunFrequencyRow
          jobType={JobType.Caching}
          setUnsavedChanges={setUnsavedChanges}
        />
        <CacheSchemeRow
          checkColumnSupport={checkColumnSupport}
          timeCheckColumnOptions={timeCheckColumnOptions}
          setUnsavedChanges={setUnsavedChanges}
        />
        <TimeCheckColumnDropdown
          timeCheckColumnOptions={timeCheckColumnOptions}
          setUnsavedChanges={setUnsavedChanges}
          checkColumnSupport={checkColumnSupport}
        />
      </CardBody>
    </Card>
  );
};
