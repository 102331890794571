import * as types from "../constants";
import { store } from "../store";
import { hideSidebar } from "./sidebarActions";

export function toggleFlyout(docUrl?: any, name?: any, source?: any) {
  return {
    type: types.FLYOUT_VISIBILITY_TOGGLE,
    docUrl: docUrl,
    name: name,
    source: source,
  };
}

export function showFlyout(
  docUrl?: any,
  name?: any,
  source?: any,
  section?: string,
) {
  if (!store.getState().flyout.isOpen) store.dispatch(hideSidebar());
  return {
    type: types.FLYOUT_VISIBILITY_SHOW,
    docUrl: docUrl,
    name: name,
    source: source,
    section: section,
  };
}

export function hideFlyout(docUrl?: any, name?: any, source?: any) {
  return {
    type: types.FLYOUT_VISIBILITY_HIDE,
    docUrl: docUrl,
    name: name,
    source: source,
  };
}
