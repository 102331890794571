import React from "react";
import { Button, ButtonProps } from "reactstrap";

export const ViewDocumentationButton = (props: {
  openFlyout: ButtonProps["onClick"];
}) => {
  return (
    <Button
      outline
      data-testid="button-view-documentation"
      color="primary"
      className="docs-view-btn"
      onClick={props.openFlyout}
    >
      <i className="fa fa-solid fa-book view-documentation-icon no-pointer-event" />
      Documentation
    </Button>
  );
};
