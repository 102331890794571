// Accepts a UTC DateTime string and parses it into usable components that can be displayed more easily in the UI.

interface parsedDateTime {
  hyphenatedDate: string;
  compiledTime: string;
}

export function parseUTCDateTime(dateTime: string): parsedDateTime {
  const date = new Date(dateTime);

  // Splits the UTC DateTime string at the time separator
  const splitDateTime = date.toISOString().split("T");

  // Assigns the pre-time separator section to the date
  const hyphenatedDate = splitDateTime[0];

  // Removes the fractional part of the post-time separator (11:11:11.000Z) and assigns the pre-fractional portion to the compiled time
  const compiledTime = splitDateTime[1].split(".")[0];

  return {
    hyphenatedDate,
    compiledTime,
  };
}
