import {
  ChangeEvent,
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { InfoIcon } from "../../../../components/InfoIcon";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ToastrError } from "../../../../services/toastrService";

import {
  Button,
  Card,
  CardBody,
  Col,
  FormFeedback,
  FormText,
  Input,
  Row,
  Tooltip,
} from "reactstrap";
import { ITableSettings, RootPath } from "../SetTable";
import ColumnsTableContainer from "../components/ColumnsTableContainer";
import { Form, Formik } from "formik";
import { dummyTableSubmit } from "../SetTableValidationDictionary";
import * as Yup from "yup";
import {
  generateInitialValues,
  generateValidationSchema,
} from "./FormikHelpers";
import { SET_TABLE_CONSTANTS as Constants } from "../../ApiConnector.constants";
import { ModalContext } from "../../../../routes/ModalContext";
import TertiaryButton from "../../../../components/buttons/TertiaryButton";
import { hasPerRowRequestError } from "./PerRowRequestHelper";
import { isEmpty } from "lodash";
import { IModalProps } from "../../../../components/CDataModal";
import { IAPIConnectorSettings } from "../types/IAPIConnectorSettings";
import TabWrapper from "../../../../components/TabWrapper";
import PseudoColumnTable from "./PseudoColumnTable";
import { NotificationBar } from "../../../../components/notification/NotificationBar";

export interface ITableDataCardProps {
  apiSettings: IAPIConnectorSettings;
  openWizard: () => void;
  isTableFirstLoad: boolean;
  perRowColumnNotFound: string[];
}

export interface IColumnDataInputFieldValue {
  column: string[];
  pseudoColumn: string[];
}

const TableDataCard = forwardRef(
  (props: ITableDataCardProps, tableDataRef: any) => {
    const {
      openWizard,
      apiSettings: { tableSettings, setTableSettings, setUnsavedChanges },
      isTableFirstLoad,
      perRowColumnNotFound,
    } = props;

    const [isConfigureDisabled, setIsConfigureDisabled] =
      useState<boolean>(true);
    const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);
    const [initialValues, setInitialValues] = useState({});
    const [validationSchema, setValidationSchema] = useState(
      Yup.object().shape({}),
    );
    const [columnsNeedRegeneration, setColumnsNeedRegeneration] =
      useState<boolean>(false);
    const [tableDataTab, setTableDataTab] = useState("1");

    const isTableDataEmpty =
      tableSettings.rootPaths.length === 0 &&
      tableSettings.columns.length === 0;

    const modalContext = useContext(ModalContext);
    const rootPathsRef = useRef<any>();
    const columnsRef = useRef<any>();
    const pseudoColumnsRef = useRef<any>();

    const getComponentName = () => {
      return "pages-apiConnector-SetTable-components-TableDataCard";
    };

    useImperativeHandle(
      tableDataRef,
      () => ({
        submitRootPaths: () => {
          rootPathsRef.current?.submitForm();
        },

        submitColumns: () => {
          columnsRef.current?.submitForm();
        },

        submitPseudoColumns: () => {
          pseudoColumnsRef.current?.submitForm();
        },

        validatePseudoColumnForm: () => {
          return pseudoColumnsRef.current?.validateForm();
        },

        regenerateRootPathsFormik: (data: RootPath[]) => {
          generateFormikParameters(data);
        },

        regenerateColumnsFormik: () => {
          setColumnsNeedRegeneration(true);
        },

        updateTableDataTab: (tabNumber: string) => {
          setTableDataTab(tabNumber);
        },
      }),
      [], // eslint-disable-line
    );

    useEffect(() => {
      generateFormikParameters(tableSettings.rootPaths);
    }, []); // eslint-disable-line

    const initialValueFields: string[] = [Constants.ROOT_PATH];

    function generateFormikParameters(data: RootPath[]) {
      setInitialValues(generateInitialValues(data, initialValueFields));
      setValidationSchema(generateValidationSchema(data, generateColumnSchema));
    }

    function generateColumnSchema(rootPath: RootPath, index: number) {
      const schema = Yup.object().shape({
        [`${Constants.ROOT_PATH}${index}`]: Yup.string()
          .trim()
          .required("Repeat Element is required."),
      });
      return schema;
    }

    const infoIconText =
      "Specify the path of the element whose child-items should be interpreted as rows in your API's model.";
    const columnHelper = createColumnHelper<RootPath>();
    const columns = [
      columnHelper.accessor("rootPath", {
        cell: (info) => info.getValue(),
        header: () => (
          <Row>
            <Col>
              <div className="info-icon-container">
                <label className="form-field-title required">
                  Repeat Element
                </label>
                <InfoIcon
                  tooltipMessage={infoIconText}
                  className="info-icon-root"
                  iconId="info-icon-root"
                />
              </div>
            </Col>
          </Row>
        ),

        footer: (info) => info.column.id,
      }),
    ];

    useEffect(() => {
      if (!tableSettings.requestUrl || tableSettings.responseType === null) {
        setIsConfigureDisabled(true);
      } else {
        setIsConfigureDisabled(false);
      }
    }, [tableSettings.responseType, tableSettings.requestUrl]);

    async function openConfigureModal(event: React.MouseEvent) {
      event.preventDefault();

      const urlPattern = /^https:\/\/[^\s/$.?#].[^\s]*$/;

      if (!urlPattern.test(tableSettings.requestUrl)) {
        ToastrError("Invalid Request URL", "Please enter a valid URL.");
        return;
      }

      openWizard();
    }

    function handleClearTableDataCard() {
      const clearTableDataModal = {
        title: "Clear Table Data",
        body: (
          <p>
            You are about to clear all repeat elements and column configurations
            for your API Table. Please note that this action will also remove
            any filters attached. Are you sure you want to proceed?
          </p>
        ),
        primaryButton: (
          <Button
            color="danger"
            onClick={() => {
              setTableSettings((prevTableSettings: ITableSettings) => {
                const newTableSettings = { ...prevTableSettings };
                newTableSettings.rootPaths = [];
                newTableSettings.columns = [];
                newTableSettings.filters = [];
                return newTableSettings;
              });
              modalContext.toggleModal();
            }}
          >
            Confirm
          </Button>
        ),
        secondaryButton: (
          <Button color="secondary" onClick={() => modalContext.toggleModal()}>
            Cancel
          </Button>
        ),
        displayed: true,
        displayToggleCloseButton: true,
      } as IModalProps;

      modalContext.setModal(clearTableDataModal);
    }

    enum rootPathActions {
      UPDATE,
      DELETE,
      ADD,
    }

    const handleRootPathChange = (
      action: rootPathActions,
      index?: number,
      event?: ChangeEvent<HTMLInputElement>,
    ) => {
      const newTableSettings = { ...tableSettings };
      let newRootPaths = newTableSettings.rootPaths;

      switch (action) {
        case rootPathActions.ADD: {
          newRootPaths = [...newRootPaths, { rootPath: "" } as RootPath];
          generateFormikParameters(newRootPaths);
          break;
        }
        case rootPathActions.UPDATE: {
          if (index && event) {
            newRootPaths[index].rootPath = event.target.value;
            rootPathsRef.current?.setFieldValue(
              event.target.name,
              event.target.value,
            );
          }
          break;
        }
        case rootPathActions.DELETE: {
          if (index) {
            const updatedRootPaths = [...newRootPaths];
            updatedRootPaths.splice(index, 1);
            newRootPaths = updatedRootPaths;
            generateFormikParameters(newRootPaths);
          }
          break;
        }
      }
      updateTableSettingsProps(newRootPaths);
    };

    function updateTableSettingsProps(newRootPaths: RootPath[]) {
      setTableSettings((prevTableSettings: ITableSettings) => {
        const newTableSettings = { ...prevTableSettings };
        newTableSettings.rootPaths = newRootPaths;
        return newTableSettings;
      });

      if (!isTableFirstLoad) {
        setUnsavedChanges(true);
      }
    }

    const data = tableSettings.rootPaths;
    const table = useReactTable<RootPath>({
      data,
      columns,
      getCoreRowModel: getCoreRowModel(),
    });

    const areRootPathsEmpty =
      tableSettings.rootPaths.length === 0 ||
      tableSettings.rootPaths.every((r) => isEmpty(r.rootPath));
    const showEmptyTableDataText = areRootPathsEmpty;
    const pluralSuffix = perRowColumnNotFound?.length > 1 ? "s" : "";
    const singularPrefix = perRowColumnNotFound?.length < 2 ? " a " : " ";

    return (
      <Card className={getComponentName()} data-testid="card-table-data">
        <CardBody>
          <Row>
            <Col>
              <h5 className="card-title">Table Data</h5>
            </Col>
            <Col
              className="configure-btn-column"
              onMouseOver={() => setIsTooltipOpen(true)}
              onMouseOut={() => setIsTooltipOpen(false)}
            >
              {isConfigureDisabled ? (
                <Tooltip
                  placement="left"
                  isOpen={isTooltipOpen}
                  target="button-configure"
                  trigger="hover"
                >
                  A Request URL is required before you can configure your Table.
                </Tooltip>
              ) : null}
              <TertiaryButton
                className="clear-button"
                onClick={handleClearTableDataCard}
                disabled={isTableDataEmpty}
              >
                <i className="fa-regular fa-xmark align-middle me-2" />
                Clear
              </TertiaryButton>

              <Button
                data-testid="button-configure"
                id="button-configure"
                color="primary"
                className="float-end"
                disabled={
                  isConfigureDisabled ||
                  hasPerRowRequestError(tableSettings.requestUrl)
                }
                onClick={(event) => openConfigureModal(event)}
              >
                <i className="fa fa-slider-sh align-middle mb-1 me-2 no-pointer-event" />
                Configure
              </Button>
            </Col>
          </Row>

          <div className="my-3">
            <Formik
              innerRef={rootPathsRef}
              initialValues={initialValues}
              validationSchema={validationSchema}
              enableReinitialize={true}
              validateOnMount={true}
              validateOnChange={true}
              onSubmit={dummyTableSubmit}
            >
              {({ errors, handleBlur, handleSubmit, touched }) => (
                <Form onSubmit={handleSubmit}>
                  {showEmptyTableDataText && (
                    <div className="tableDataCard-emptyText">
                      Click “Configure” to setup the repeat elements and columns
                      of your API Table.
                    </div>
                  )}

                  {!showEmptyTableDataText && (
                    <table className="root-path-table">
                      <thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                          <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                              <th
                                className="root-path-table-header"
                                key={header.id}
                              >
                                {header.isPlaceholder
                                  ? null
                                  : flexRender(
                                      header.column.columnDef.header,
                                      header.getContext(),
                                    )}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody>
                        {table.getRowModel().rows.map((row, index) => (
                          <tr key={row.id} className="root-path-row">
                            {row.getVisibleCells().map((cell) => {
                              const rootPathName =
                                "rootPath" + index.toString();
                              return (
                                <td
                                  className="root-path-table-cell"
                                  key={cell.id}
                                >
                                  <Input
                                    data-testid="root-path"
                                    id="RootPath"
                                    name={rootPathName}
                                    disabled
                                    className="form-control"
                                    onBlur={handleBlur}
                                    key={cell.id}
                                    type="text"
                                    invalid={Boolean(
                                      errors[
                                        rootPathName as keyof typeof errors
                                      ] &&
                                        touched[
                                          rootPathName as keyof typeof errors
                                        ],
                                    )}
                                    value={cell.row.original.rootPath}
                                    onChange={(
                                      event: ChangeEvent<HTMLInputElement>,
                                    ) =>
                                      handleRootPathChange(
                                        rootPathActions.UPDATE,
                                        index,
                                        event,
                                      )
                                    }
                                  />
                                  <FormFeedback type="invalid">
                                    {
                                      errors[
                                        rootPathName as keyof typeof errors
                                      ]
                                    }
                                  </FormFeedback>
                                </td>
                              );
                            })}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </Form>
              )}
            </Formik>
          </div>
          {perRowColumnNotFound && perRowColumnNotFound.length > 0 ? (
            <NotificationBar
              message={`None of the columns returned matched the column${pluralSuffix} referenced within the Request URL. Please rename${singularPrefix}column${pluralSuffix} or create${singularPrefix}Pseudo Column${pluralSuffix} with the name${pluralSuffix}: [${perRowColumnNotFound.join(", ")}].`}
              barColor="notification-bar-red"
            />
          ) : null}
          {!showEmptyTableDataText && (
            <TabWrapper
              currentTab={tableDataTab}
              setCurrentTab={setTableDataTab}
              tabs={[
                {
                  tabName: "Columns",
                  tabEnum: "1",
                  tabComponent: (
                    <>
                      <div className="column-message">
                        <FormText>
                          At least one column of data is required.
                        </FormText>
                      </div>

                      <div className="mt-3">
                        <DndProvider backend={HTML5Backend}>
                          <ColumnsTableContainer
                            tableSettings={tableSettings}
                            setTableSettings={setTableSettings}
                            setUnsavedChanges={setUnsavedChanges}
                            isTableFirstLoad={isTableFirstLoad}
                            columnsNeedRegeneration={columnsNeedRegeneration}
                            setColumnsNeedRegeneration={
                              setColumnsNeedRegeneration
                            }
                            pseudoColumnsRef={pseudoColumnsRef}
                            ref={columnsRef}
                          />
                        </DndProvider>
                      </div>
                    </>
                  ),
                },
                {
                  tabName: "Pseudo Columns",
                  tabEnum: "2",
                  tabComponent: (
                    <>
                      <div className="column-message">
                        <FormText>
                          Pseudo columns represent columns which can be used as
                          filters in the WHERE clause, but are not present in
                          the output data-model.
                        </FormText>
                      </div>
                      <PseudoColumnTable
                        tableSettings={tableSettings}
                        setTableSettings={setTableSettings}
                        setUnsavedChanges={setUnsavedChanges}
                        formRef={pseudoColumnsRef}
                      />
                    </>
                  ),
                },
              ]}
            />
          )}
        </CardBody>
      </Card>
    );
  },
);
TableDataCard.displayName = "TableDataCard";

export default TableDataCard;
