import { Fragment, useContext, useEffect, useState } from "react";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
  flexRender,
} from "@tanstack/react-table";
import { getDriverIcon } from "../../../../../components/drivers/DriverIconFactory";
import { IStepBodyProps } from "../../../../../components/wizard/components/StepWizardStep";
import { StepNames } from "./StepNames";
import classnames from "classnames";
import { SelectSchemaWizardContext } from "../SelectSchemaWizardContext";
import Loader from "../../../../../components/Loader";
import { useUserInfo } from "../../../../../hooks/useUserInfo";
import {
  ConnectionType,
  IConnection,
  IDriverList,
} from "../../../../../models";
import {
  ButtonType,
  CDataButton,
} from "../../../../../components/buttons/CDataButton";
import { StepWizardStepFooter } from "../../../../../components/wizard/components/StepWizardStepFooter";
import { StepWizardContext } from "../../../../../components/wizard/components/StepWizardContext";
import { TextWithOverflowTooltip } from "../../../../../components/text/TextWithOverflowTooltip";
import {
  CDataTooltip,
  CDataTooltipType,
} from "../../../../../components/CDataTooltip";
import { NotificationBar } from "../../../../../components/notification/NotificationBar";
import { useAppSelector } from "../../../../../redux/hooks";
import { useQuery } from "@tanstack/react-query";
import { getConnections } from "../../../../connections/connectionList/getConnections";
import { useNavigate } from "react-router-dom";
import { getSettingsPageSubscriptionTab } from "../../../../../utility/SubscriptionProvider";
import { IsPremiumConnectorDisabled } from "../../../../../utility/IsPremiumConnectorDisabled";
import { FeatureId } from "../../../../../models/Features/FeatureId";

interface ConnectionListItem {
  connectionName: string;
  dataSource: JSX.Element;
  connectionId: string;
  driver: string;
  isUDC: boolean;
  isDisabledCacheRow: boolean;
  isHiddenCacheRow: boolean;
  isPremiumDisabled: boolean;
}

interface ISelectConnectionProps extends IStepBodyProps {
  helperText?: string;
  isCacheJobWizard: boolean;
  nextStepName: string;
}

export const SelectConnection = (props: ISelectConnectionProps) => {
  const [data, setData] = useState<ConnectionListItem[]>([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [selectedFlatRow, setSelectedFlatRow] =
    useState<ConnectionListItem | null>(null);
  const [showDataChangeAlert, setShowDataChangeAlert] = useState(false);
  const userInfo = useUserInfo();
  const navigate = useNavigate();
  const {
    completedSteps,
    resetPendingCompletedSteps,
    setModalSize,
    setPendingCompletedStepsToCurrentStep,
    setStepCompleted,
  } = useContext(StepWizardContext);

  const {
    selectedConnection,
    setAssetList,
    setDriverName,
    setSelectedConnection,
  } = useContext(SelectSchemaWizardContext);

  const driverList: IDriverList = useAppSelector((state) => state.driversList);
  const availableFeatureIds: FeatureId[] =
    useAppSelector(
      (state) => state.subscription?.limits?.availableFeatureIds,
    ) ?? [];

  // Query to load the connections
  const { data: connectionListData, isLoading } = useQuery({
    queryKey: ["/account/connections"],
    queryFn: () =>
      getConnections({ IsAdmin: false, CurrentUserId: userInfo.Self.id }),
    meta: {
      errorMessage: "Failed to get connection list due to the following error:",
    },
  });

  useEffect(() => {
    if (connectionListData != null) {
      const connections: IConnection[] = connectionListData?.connections ?? [];

      // Check if there was a connection selected previously, i.e. if the user navigated back to this step, and set it in the state if there is.
      const previouslySelectedConnection = connections.find(
        (connection) => connection.id === selectedConnection?.id,
      );

      if (previouslySelectedConnection) {
        setSelectedRow({ [previouslySelectedConnection.name!]: true });
        setSelectedFlatRow(
          mapIConnectionToListItem(previouslySelectedConnection),
        );
      }

      setModalSize("md");
    }
  }, [connectionListData]); // eslint-disable-line

  //update connections table data, if connections list updates
  useEffect(() => {
    const newTableData =
      connectionListData?.connections?.map((currentConnection) =>
        mapIConnectionToListItem(currentConnection),
      ) ?? [];
    setData([...newTableData]);
  }, [connectionListData]); // eslint-disable-line

  // If the step is being revisited and the user selects a different connection, block the user from jumping forward and show the alert banner.
  function checkIfSelectionConnectionChanged(
    newRowSelection: ConnectionListItem | null,
  ) {
    if (
      completedSteps?.includes(props.currentStep!) &&
      selectedConnection?.name !== newRowSelection?.connectionName
    ) {
      setPendingCompletedStepsToCurrentStep(props.currentStep!);
      setShowDataChangeAlert(true);
    } else {
      resetPendingCompletedSteps();
      setShowDataChangeAlert(false);
    }
  }

  function mapIConnectionToListItem(
    currentConnection: IConnection,
  ): ConnectionListItem {
    return {
      connectionName: currentConnection.name!,
      dataSource: (
        <span className="d-flex align-items-center">
          {getDriverIcon(currentConnection.driver!, "connection-icon me-2")}
          <TextWithOverflowTooltip
            fullText={getDriverName(currentConnection.driver!)}
          />
        </span>
      ),
      connectionId: currentConnection.id!,
      driver: currentConnection.driver!,
      isUDC: currentConnection.connectionType === ConnectionType.UserDefined,
      isDisabledCacheRow: isCacheRowDisabled(currentConnection),
      isHiddenCacheRow: isCacheRowHidden(currentConnection),
      isPremiumDisabled: IsPremiumConnectorDisabled(
        currentConnection.driver!,
        driverList,
        availableFeatureIds,
      ),
    };
  }

  function isCacheRowDisabled(currentConnection: IConnection) {
    const isUDC =
      currentConnection.connectionType === ConnectionType.UserDefined;

    return props.isCacheJobWizard && isUDC;
  }

  function isCacheRowHidden(currentConnection: IConnection) {
    const isREST = currentConnection.driver === "REST";
    const isCachingDisabledInDriver =
      driverList.drivers!.find((x) => x.driver === currentConnection.driver)
        ?.isCachingDisabled ?? false;

    return props.isCacheJobWizard && (isREST || isCachingDisabledInDriver);
  }

  const columnHelper = createColumnHelper<ConnectionListItem>();

  const columns = [
    columnHelper.accessor("connectionName", {
      header: "Connection Name",
      cell: (info) => <TextWithOverflowTooltip fullText={info.getValue()} />,
    }),
    columnHelper.accessor("dataSource", {
      header: "Data Source",
      cell: (info) => info.getValue(),
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    getRowId: (row) => {
      return row.connectionName;
    },
    initialState: {
      rowSelection: selectedRow,
    },
    state: {
      rowSelection: selectedRow,
    },
    enableRowSelection: (row) =>
      !row.original.isDisabledCacheRow && !row.original.isPremiumDisabled,
    onRowSelectionChange: setSelectedRow,
    getCoreRowModel: getCoreRowModel(),
  });

  const getDriverName = (name: string) => {
    if (name === "REST") {
      return "API";
    }

    return name;
  };

  if (isLoading) {
    return <Loader />;
  }

  const backButton = !props.isCacheJobWizard ? (
    <CDataButton
      buttonType={ButtonType.Borderless}
      onClick={() => props.goToNamedStep!(StepNames.SelectAssetType)}
    >
      <span className="btn-outline-primary">Back</span>
    </CDataButton>
  ) : (
    <span />
  );

  const dataChangeAlert =
    props.isCacheJobWizard && showDataChangeAlert ? (
      <div className="mt-3 mb-2">
        <NotificationBar
          barColor={"notification-bar-blue"}
          message={`You have made changes that require you to select your ${props.isCacheJobWizard ? "tables" : "assets"} again in the next step.`}
        />
      </div>
    ) : null;

  const handleNextStep = () => {
    const selectedConnection = {
      name: selectedFlatRow!.connectionName,
      id: selectedFlatRow!.connectionId,
    };
    if (props.isCacheJobWizard) {
      // If multiple connections have the same schemas/table names, they will appear as selected even if the connection changes.
      // This prevents that behavior by clearing out the selected assets, but only in the cache job wizard.
      setAssetList([]);
    }
    setSelectedConnection(selectedConnection);
    setDriverName(selectedFlatRow!.driver);

    setStepCompleted(props.currentStep!);
    setShowDataChangeAlert(false);
    props.goToNamedStep!(props.nextStepName);
  };

  const tableBody = table
    .getRowModel()
    .rows.filter((row) => !row.original.isHiddenCacheRow)
    .map((row) => {
      let toolTipDisableMessage = undefined;
      if (row.original.isPremiumDisabled) {
        toolTipDisableMessage = (
          <span>
            Premium Connectors are not available with your subscription.
            <a
              className="upgrade-link"
              onClick={() =>
                navigate(
                  "/settings?defaultTab=" + getSettingsPageSubscriptionTab(),
                )
              }
            >
              {" "}
              Upgrade
            </a>{" "}
            your plan today to continue using this connection.
          </span>
        );
      } else if (row.original.isDisabledCacheRow) {
        toolTipDisableMessage =
          "Connections with user credentials enabled are not available for caching.";
      }

      return (
        <Fragment key={row.id}>
          <CDataTooltip
            type={CDataTooltipType.Dark}
            title={toolTipDisableMessage}
          >
            <tr
              data-testid={row.original.connectionName}
              id={row.id}
              className={classnames("table-row", {
                "row-selected": row.getIsSelected(),
                "row-disabled":
                  row.original.isDisabledCacheRow ||
                  row.original.isPremiumDisabled,
              })}
              onClick={() => {
                table.toggleAllRowsSelected(false);
                if (!row.getIsSelected()) {
                  row.toggleSelected(true);
                  setSelectedFlatRow(row.original);
                  checkIfSelectionConnectionChanged(row.original);
                } else {
                  row.toggleSelected(false);
                  setSelectedFlatRow(null);
                  checkIfSelectionConnectionChanged(null);
                }
              }}
            >
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          </CDataTooltip>
        </Fragment>
      );
    });

  return (
    <div className="page-datasets-AddAssetWizard-Steps-SelectConnection">
      <span className="select-table-desc">
        {props.helperText ?? "Select a connection from the list below."}
      </span>
      <span>{dataChangeAlert}</span>
      <table className="select-connections-table add-asset-wizard-table">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>{tableBody}</tbody>
        <tfoot>
          {table.getFooterGroups().map((footerGroup) => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.footer,
                        header.getContext(),
                      )}
                </th>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>
      <StepWizardStepFooter>
        {backButton}
        <CDataButton
          data-testid={"button-next"}
          buttonType={ButtonType.Primary}
          onClick={handleNextStep}
          disabled={Object.keys(selectedRow).length === 0}
        >
          Next
        </CDataButton>
      </StepWizardStepFooter>
    </div>
  );
};
