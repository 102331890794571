import { useState, useEffect, useContext } from "react";
import "codemirror/lib/codemirror.css";
import "codemirror/mode/sql/sql.js";
import "codemirror/addon/selection/active-line";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { ColumnDef } from "@tanstack/react-table";
import { RequestType, BackendType } from "../../../../components/withAPI";
import useQueryTabs, { IQueryTab } from "../Tabs/useQueryTabs";
import { ToastrClean, ToastrError } from "../../../../services/toastrService";
import { BillingStatus, IQueryResult } from "../../../../models";
import ResultsDetailsGridContainer from "../Results/ResultsDetailsGridContainer";
import {
  isSelect,
  sanitizeQuery,
  sanitizeQueryProps,
} from "../QueryEditor/sanitizeQuery";
import { useAPI } from "../../../../components/useAPI";
import { doesDerivedViewReferenceDerivedView } from "../../validation/doesDerivedViewReferenceDerivedView";
import { QueryTabType } from "../Tabs/queryTabType";
import { QueryTabsContext } from "../Tabs/QueryTabsContext";
import { QueryErrorNotificationBanner } from "../QueryEditor/QueryErrorNotificationBanner";
import { useMutation } from "@tanstack/react-query";
import { postQuery } from "../../api/postQuery";
import { HttpError } from "../../../../api/cdataFetch";
import { IServiceError } from "../../../../models/IServiceError";
import { useFeatureFlags } from "../../../../hooks/useFeatureFlags";
import { ManageDerivedViewButtons } from "./ManageDerivedViewButtons";
import { DerivedViewEditor } from "./DerivedViewEditor";
import { DerivedViewResults } from "./DerivedViewResults";
import { IDataExplorerRightPanelColumnMetadata } from "../EditorAndResults";
import { getDataTypeName } from "../../getDataTypeName";
import { useAppSelector } from "../../../../redux/hooks";
import { UpdateEditDerivedViewModal } from "../modals/UpdateEditDerivedViewModal";
import { LeftPanelType } from "../../LeftPanel/leftPanelType";
import { ClearQueryModal } from "../modals/ClearQueryModal";
import { TimeoutModal } from "../../../../components/modal/TimeoutModal";

interface IDerivedViewEditorAndResultsProps {
  openCreateDerivedViewModal: () => void;
  openCreateSavedQueryModal: () => void;
  setSidebarView: (sidebarView: LeftPanelType) => void;
  fetchDerivedViews: () => void;
  fetchSavedQueries: () => void;
  tab: IQueryTab;
  abortRunningQueryRequest: () => void;
}

export const DerivedViewEditorAndResults = (
  props: IDerivedViewEditorAndResultsProps,
) => {
  const api = useAPI();
  const tabs = useQueryTabs();
  const tabContext = useContext(QueryTabsContext);
  const usage = useAppSelector((state) => state.usage);
  const flags = useFeatureFlags().getFlags(["dataexplorer_new_derived_views"]);

  const { tab } = props;

  const [derivedViewTitle, setDerivedViewTitle] = useState<string>(tab.tabName);
  const [derivedViewQuery, setDerivedViewQuery] = useState<string>(
    tab.queryString,
  );
  const [resultData, setResultData] = useState<any[]>([]);
  const [resultColumns, setResultColumns] = useState<ColumnDef<any>[]>([]);
  const [showResults, setShowResults] = useState<boolean>(false);
  const [resultsToggled, setResultsToggled] = useState<boolean>(false);
  const [filteredColumns, setFilteredColumns] = useState<any[]>([]);
  const [columnDetails, setColumnDetails] = useState<any>([]);
  const [columnMetadataLoading, setColumnMetadataLoading] =
    useState<boolean>(false);
  const [columnMetadata, setColumnMetadata] = useState<
    IDataExplorerRightPanelColumnMetadata[]
  >([]);
  const [updateDerivedViewModalOpen, setUpdateDerivedViewModalOpen] =
    useState(false);
  const [clearQueryModalOpen, setClearQueryModalOpen] =
    useState<boolean>(false);
  const [timeoutModalOpen, setTimeoutModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const header = { "Connect-Cloud-Client": "CDataDataExplorer" };

  const isNewDerivedViewUIResult =
    flags.dataexplorer_new_derived_views.enabled &&
    tab.connectionName === "CData" &&
    tab.schemaName === "DerivedViews";

  let timerCacheNotificationPrompt: number | NodeJS.Timeout;
  let startTimeCacheNotificationPrompt: number;

  useEffect(() => {
    const fetchData = async () => {
      if (tab.metadataOnStart) await fetchColumnMetadata();
      if (tab.resultsOnStart) await fetchPreview();
    };
    fetchData();

    return () => {
      if (timerCacheNotificationPrompt != null) {
        clearTimeout(timerCacheNotificationPrompt);
      }
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    setFilteredColumns(columnMetadata);
  }, [columnMetadata, resultsToggled]);

  const fetchColumnMetadata = async () => {
    let workspaceParam = "";
    // Update metadata if table is derived view
    if (tab.tabType === QueryTabType.Workspace) {
      workspaceParam = `&workspace=${encodeURIComponent(tab.connectionName ?? "")}`;
    }

    setColumnMetadata([]);
    setColumnMetadataLoading(true);
    setColumnDetails(columnDetailsWithPrimary);

    try {
      const { status, payload, error } = await api.callAPI(
        RequestType.Get,
        `/columns?catalogName=${encodeURIComponent(tab.connectionName ?? "")}&schemaName=${encodeURIComponent(tab.schemaName ?? "")}&tableName=${encodeURIComponent(tab.tableName ?? "")}${workspaceParam}`,
        "",
        null,
        BackendType.QueryRouter,
        header,
      );
      if (status === 200) {
        if (payload.error) {
          errorCallback(payload.error.message);
          return;
        }
        const colMetadata = payload.results[0].rows;
        const colArray = colMetadata.map((el: any) => {
          return {
            "Column Name": el[3],
            "Column Type": el[5],
            "Primary Key": el[11],
            Nullable: el[12],
          };
        });
        setColumnMetadata(colArray);
        setShowResults(true);
        setResultColumns([]);
        setResultData([]);
      } else {
        if (status === 401) {
          setTimeoutModalOpen(true);
        } else {
          errorCallback(error);
        }
      }
    } catch (err) {
      setColumnMetadata([]);
      errorCallback(`Error fetching column metadata: ${err}`);
    } finally {
      setColumnMetadataLoading(false);
    }
  };

  const errorCallback = (
    errorMsg: Error | string | { message: string } | null | undefined,
  ) => {
    if (typeof errorMsg === "string") {
      setErrorMessage(errorMsg);
    } else if ("message" in errorMsg!) {
      // This handles the Error object case or an object with { message: string }
      setErrorMessage(errorMsg.message);
    } else {
      // This is just here to handle things like nulls
      setErrorMessage(errorMsg ?? "");
    }

    tabs.SetTabStatus(tab.id, false, true);
  };

  const columnDetailsWithoutPrimary = [
    {
      accessorKey: "Column Name",
      id: "Column Name",
      enableSorting: true,
    },
    {
      accessorKey: "Column Type",
      id: "Column Type",
      enableSorting: true,
    },
    {
      accessorKey: "Nullable",
      id: "Nullable",
      enableSorting: true,
    },
  ];

  const getWorkspaceNameFromQuery = (query: string) => {
    if (!query) {
      return "";
    }

    const newQuery = query.toLowerCase();
    const divider = "from ";

    // Break down query after the word from, convert to array based on dot(.)
    // Return first element and then remove [] if any.
    const workspaceName = newQuery
      .split(divider)
      .pop()
      ?.split(".")?.[0]
      ?.replace(/(^.*\[|\].*$)/g, "");

    return workspaceName ?? "";
  };

  const sanitizeParameters: sanitizeQueryProps = {
    currentQueryInput: derivedViewQuery,
    queryLimit: 10,
    setQueryLimit: () => {},
    setCurrentQueryInput: setDerivedViewQuery,
    enforceLimit: true,
  };

  const workspaceName = getWorkspaceNameFromQuery(tab.queryString);

  const { mutate, isPending: isQueryLoading } = useMutation({
    mutationKey: ["/query"],
    mutationFn: postQuery,
    meta: {
      errorMessage: "Failed to execute query",
    },
    onSuccess: (data) => {
      const sanitizedQuery = sanitizeQuery(sanitizeParameters);
      processQuery(data, isSelect(sanitizedQuery));
      clearTimeout(timerCacheNotificationPrompt);
    },
    onError: (error) => {
      if (error instanceof HttpError && error.statusCode === 401) {
        setTimeoutModalOpen(true);
      } else {
        setResultData([]);
        errorCallback(error.message);
      }

      clearTimeout(timerCacheNotificationPrompt);
    },
  });

  const processQuery = (
    payload: IQueryResult | IServiceError,
    isSelect: boolean,
  ) => {
    if (payload.error) {
      errorCallback(payload.error.message);
      clearTimeout(timerCacheNotificationPrompt);
      return;
    }
    if (isSelect && "results" in payload && payload.results != null) {
      const schema = payload.results[0].schema ?? [];
      const rows = payload.results[0].rows ?? [];
      const fieldArray = schema.map((x) => ({
        accessorKey: x.columnLabel!,
        id: x.columnLabel!,
        enableSorting: true,
      }));
      const allColumnNamesArray = fieldArray.map((x) => x.accessorKey);
      setResultColumns(fieldArray);

      const resultArray = [];
      for (let i = 0; i < rows.length; i++) {
        const row: any = {};
        for (let j = 0; j < allColumnNamesArray.length; j++) {
          row[allColumnNamesArray[j]] = rows[i][j];
        }
        resultArray.push(row);
      }
      const colArray = schema.map((el: any) => {
        return {
          "Column Name": el.columnLabel,
          "Column Type": getDataTypeName(el.dataType),
          Nullable: el.nullable,
        };
      });
      setColumnMetadata(colArray);
      setResultData(resultArray);
      setShowResults(true);
      //if query gets executed & results are ready before 2 mins then no need to show use caching prompt notification.
      if (Date.now() - startTimeCacheNotificationPrompt < 120000) {
        clearTimeout(timerCacheNotificationPrompt);
      }
      // Set query text when successful so that the tab is no longer considered "empty"
      const newTabs = [...tabContext.tabs];
      const currentTabIndex = tabContext.tabs.findIndex(
        (tab) => tab.id === tabContext.selectedTab,
      );

      newTabs[currentTabIndex].queryString = derivedViewQuery;
      tabContext.setTabs(newTabs);
      tabs.SetTabStatus(tab.id, false, false);
    } else {
      setResultData([]);
      tabs.SetTabStatus(tab.id, false, false);
      setShowResults(false);
    }
  };

  const executeQuery = async () => {
    tabs.SetTabStatus(tab.id, true, false);

    ToastrClean();
    setResultData([]);
    setResultColumns([]);
    setColumnDetails(columnDetailsWithoutPrimary);

    const sanitizedQuery = sanitizeQuery(sanitizeParameters);

    mutate({
      isWorkspace: Boolean(tab.isWorkspace),
      workspaceName: workspaceName,
      header: header,
      body: {
        query: sanitizedQuery,
        defaultCatalog: tab.connectionName!,
      },
    });
  };

  const handleRunClick = (e: any) => {
    e.preventDefault();
    fetchPreview();
  };

  const handleSaveClick = (e: any) => {
    e.preventDefault();
    setUpdateDerivedViewModalOpen(true);
  };

  const handleRefreshClickCallback = (e: any) => {
    e.preventDefault();
    (() => {
      setClearQueryModalOpen(!clearQueryModalOpen);
    })();
  };

  const clearQuery = () => {
    setDerivedViewQuery("");
    setResultColumns([]);
    setResultData([]);
    setColumnMetadata([]);
    (() => {
      setClearQueryModalOpen(!clearQueryModalOpen);
    })();
  };

  const noData = () => {
    return <div className="text-muted text-center">No matching records</div>;
  };

  const renderResultsTable = () => {
    return (
      <ResultsDetailsGridContainer
        tab={tab}
        loading={columnMetadataLoading || isQueryLoading}
        showResults={showResults}
        resultsToggled={resultsToggled}
        resultColumns={resultColumns}
        resultData={resultData}
        noData={noData}
        columnDetails={columnDetails}
        columnMetadata={filteredColumns}
        enablePagination={!resultsToggled}
        classNames="derived-view-preview-table"
        abortRunningQueryRequest={props.abortRunningQueryRequest}
      />
    );
  };

  const fetchPreview = async () => {
    setColumnMetadata([]);
    startTimeCacheNotificationPrompt = Date.now();
    await executeQuery();
  };

  const columnDetailsWithPrimary = [
    {
      accessorKey: "Column Name",
      id: "Column Name",
      enableSorting: true,
    },
    {
      accessorKey: "Column Type",
      id: "Column Type",
      enableSorting: true,
    },
    {
      accessorKey: "Primary Key",
      id: "Primary Key",
      enableSorting: true,
    },
    {
      accessorKey: "Nullable",
      id: "Nullable",
      enableSorting: true,
    },
  ];

  const openSaveChangesModal = () => {
    if (doesDerivedViewReferenceDerivedView(derivedViewQuery)) {
      ToastrError(
        "Unable to create a derived view",
        "Cannot create a derived view using a query from a previously created derived view." +
          " Please modify your query to use the original source data and try again.",
      );
    } else {
      setUpdateDerivedViewModalOpen(true);
    }
  };

  const filterColumns = (event: any) => {
    let temp: { [key: string]: any }[] = columnMetadata;
    if (event.target.value !== "") {
      temp = temp.filter((ele: any) =>
        ele["Column Name"]
          .toLowerCase()
          .includes(event.target.value.toLowerCase()),
      );
    }
    setFilteredColumns(temp);
  };

  const trialOverage =
    usage.rowsOverLimit === true &&
    usage.billingStatus ===
      (BillingStatus.NewAccount ||
        BillingStatus.Trial ||
        BillingStatus.TrialEnding);

  const disableForOverages =
    usage.connectionsOverLimit === true ||
    usage.dataSourcesOverLimit === true ||
    trialOverage === true;

  return (
    <span
      hidden={!(tabs.CurrentTabId === tab.id)}
      className="derived-view-frame"
    >
      <ManageDerivedViewButtons
        queryInput={derivedViewQuery}
        handleSaveClick={handleSaveClick}
        handleRefreshClickCallback={handleRefreshClickCallback}
        openSaveChangesModal={openSaveChangesModal}
        openCreateDerivedViewModal={props.openCreateDerivedViewModal}
        openCreateSavedQueryModal={props.openCreateSavedQueryModal}
      />
      <QueryErrorNotificationBanner errorMessage={errorMessage} />
      <div id="editorAndResultContainer" className="pb-3">
        <DerivedViewEditor
          tab={tab}
          derivedViewTitle={derivedViewTitle}
          setDerivedViewTitle={setDerivedViewTitle}
          currentDerivedViewQuery={derivedViewQuery}
          setCurrentDerivedViewQuery={setDerivedViewQuery}
          queryEditorDisabled={disableForOverages}
          rowLimitLockdown={trialOverage}
          handleRunClick={handleRunClick}
        />
        <DerivedViewResults
          isNewDerivedViewUIResult={isNewDerivedViewUIResult}
          resultsToggled={resultsToggled}
          resultColumns={resultColumns}
          setResultsToggled={setResultsToggled}
          columnMetadata={columnMetadata}
          filterColumns={filterColumns}
          renderResultsTable={renderResultsTable}
          fetchPreview={fetchPreview}
        />
      </div>
      <UpdateEditDerivedViewModal
        displayed={updateDerivedViewModalOpen}
        close={() => setUpdateDerivedViewModalOpen(false)}
        fetchDerivedViews={props.fetchDerivedViews}
        derivedViewTitle={derivedViewTitle}
      />
      <ClearQueryModal
        displayed={clearQueryModalOpen}
        close={() => setClearQueryModalOpen(false)}
        clearQuery={clearQuery}
      />
      <TimeoutModal displayed={timeoutModalOpen} />
    </span>
  );
};
