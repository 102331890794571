import { CDataModalV2 } from "../../../../components/modal/CDataModalV2";
import {
  ButtonType,
  CDataButton,
} from "../../../../components/buttons/CDataButton";
import { useAPI } from "../../../../components/useAPI";
import { RequestType } from "../../../../components/withAPI";
import { ToastrSuccess } from "../../../../services/toastrService";
import { useState } from "react";
import { Spinner } from "reactstrap";
import { ISavedQuery } from "../../../../models/SavedQueries/ISavedQuery";
import { LeftPanelType } from "../leftPanelType";

export type DeleteSavedQueryModalProps = {
  displayed: boolean;
  close: () => void;
  targetSavedQuery?: ISavedQuery;
  savedQueries: ISavedQuery[];
  fetchSavedQueries: () => void;
  setSidebarView: (sidebarView: LeftPanelType) => void;
};

export function DeleteSavedQueryModal(props: DeleteSavedQueryModalProps) {
  const api = useAPI();

  const {
    displayed,
    close,
    targetSavedQuery,
    savedQueries,
    fetchSavedQueries,
    setSidebarView,
  } = props;

  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  if (!targetSavedQuery) {
    return null;
  }

  const deleteSavedQuery = async (savedQuery: any) => {
    setIsProcessing(true);

    const { status } = await api.callAPI(
      RequestType.Delete,
      `/users/self/savedQueries/deleteSavedQuery/${encodeURIComponent(savedQuery.id)}`,
      "Failed to delete saved query due to the following error:",
    );

    if (status === 200 || status === 204) {
      const currSavedQueryCount = savedQueries.length;
      await fetchSavedQueries();
      ToastrSuccess(
        "Saved Query Successfully Deleted!",
        `${savedQuery.name} was successfully deleted.`,
      );
      if (currSavedQueryCount === 1) {
        //case where there are no more remaining saved queries, navigate back to connections
        setSidebarView(LeftPanelType.Connections);
      }
    }

    setIsProcessing(false);
    close();
  };

  return (
    <>
      <CDataModalV2
        modalSize="lg"
        displayed={displayed}
        close={close}
        title="Delete Saved Query"
        spacedFooter={false}
        primaryButton={
          <CDataButton
            buttonType={ButtonType.Danger}
            onClick={() => deleteSavedQuery(targetSavedQuery)}
          >
            Delete
          </CDataButton>
        }
        secondaryButton={
          <CDataButton buttonType={ButtonType.Secondary} onClick={close}>
            Cancel
          </CDataButton>
        }
      >
        You are about to delete the saved query, {targetSavedQuery.name}. Are
        you sure you want to proceed?
      </CDataModalV2>
      <div hidden={!isProcessing}>
        <div className="loading-background" />
        <Spinner className="spinner-border loading-spinner" color="info" />
      </div>
    </>
  );
}
