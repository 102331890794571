import { CallAPIFunction } from "../../../components/useAPI";
import { JobFrequencyUnit } from "../../../models/Cache/JobFrequencyUnit";
import { formatUTCDateTime } from "../../../utility/FormatUTCDateTime";
import { getTableColumns } from "../components/CachingApiCalls";

export type TableColumnInfo = {
  tableName: string;
  columnName: string;
  columnType: string;
};

export type TimeCheckColumnInfo = {
  tableName: string | null;
  columnName: string;
};

export function findMatchingColumn(
  columnInfo: TableColumnInfo[],
  timecheckColumnInfo: TimeCheckColumnInfo[],
): string[] {
  const matchingColumns: string[] = [];
  for (const timeInfo of timecheckColumnInfo) {
    for (const info of columnInfo) {
      if (!timeInfo.tableName || timeInfo.tableName === info.tableName) {
        if (
          timeInfo.columnName?.toLowerCase() === info.columnName?.toLowerCase()
        ) {
          matchingColumns.push(info.columnName);
          break;
        }
      }
    }
    if (matchingColumns.length > 0) break;
  }
  return matchingColumns;
}

export function getDateTimeColumns(columnInfo: TableColumnInfo[]): string[] {
  const dateTimeColumns: string[] = [];
  for (const info of columnInfo) {
    if (info.columnType === "datetime" || info.columnType === "date") {
      dateTimeColumns.push(info.columnName);
    }
  }
  return dateTimeColumns;
}

export async function getTableColumnInfo(
  callAPI: CallAPIFunction,
  connectionName: string,
  schemaName: string,
  tableName: string,
) {
  const tableColumns = await getTableColumns(
    callAPI,
    connectionName,
    schemaName,
    tableName,
  );
  if (tableColumns == null || tableColumns.error) {
    return null;
  }
  const schema = tableColumns.results[0].schema;
  const rows = tableColumns.results[0].rows;
  const nameIndex = schema.findIndex(
    (property: any) => property.columnName === "COLUMN_NAME",
  );
  const typeIndex = schema.findIndex(
    (property: any) => property.columnName === "TYPE_NAME",
  );

  const columnInfo = rows.map((row: any) => {
    return {
      tableName: tableName,
      columnName: row[nameIndex],
      columnType: row[typeIndex],
    };
  });
  return columnInfo;
}

export function parseTimeCheckColumns(timeCheckColumns: any[]) {
  return timeCheckColumns.map((value) => {
    const [tablename, columnname] = value.includes("=")
      ? value.split("=")
      : [null, value];
    return {
      tableName: tablename,
      columnName: columnname,
    };
  });
}
export function getNextJobRun(
  currentJobRunString: string | undefined,
  nextJobRunString: string | undefined,
  jobFrequency: number,
  jobFrequencyUnit: JobFrequencyUnit,
): string | undefined {
  if (
    nextJobRunString === "0001-01-01T00:00:00Z" ||
    currentJobRunString === "0001-01-01T00:00:00Z" ||
    currentJobRunString == null
  ) {
    return nextJobRunString;
  }
  const currentJobRun: Date = new Date(currentJobRunString);
  let nextJobRun: Date = new Date(currentJobRun.getTime());

  switch (jobFrequencyUnit) {
    case JobFrequencyUnit.Day:
      nextJobRun.setDate(nextJobRun.getDate() + jobFrequency);
      break;
    case JobFrequencyUnit.Week:
      nextJobRun.setDate(nextJobRun.getDate() + 7 * jobFrequency);
      break;
    case JobFrequencyUnit.Month:
      nextJobRun.setMonth(nextJobRun.getMonth() + jobFrequency);
      break;
    case JobFrequencyUnit.Hour:
      nextJobRun.setHours(nextJobRun.getHours() + jobFrequency);
      break;
    default:
      if (nextJobRunString == null) return undefined;
      nextJobRun = new Date(nextJobRunString);
      break;
  }

  return `${formatUTCDateTime(nextJobRun)} UTC`;
}
