import { Col, Row } from "reactstrap";
import { CDataTypography } from "../../../../../../../components/text/CDataTypography";
import { getDriverIcon } from "../../../../../../../components/drivers/DriverIconFactory";
import { IQueryBuilderTable } from "../../../models/IQueryBuilderTable";

interface IQueryBuilderTableDropdownOptionProps {
  option: IQueryBuilderTable;
}

export const QueryBuilderTableDropdownOption = (
  props: IQueryBuilderTableDropdownOptionProps,
) => {
  const { option } = props;

  return (
    <span className="query-builder-table-dropdown-option">
      <Row>
        <CDataTypography
          variant="typography-variant-caption"
          color="typography-color-text-muted"
          className="hide-overflow-text"
        >
          {option.connectionName}.{option.schema}
        </CDataTypography>
      </Row>
      <Row className="align-items-center">
        <Col className="flex-grow-0 pe-0">
          {getDriverIcon(option.driver, "driver-icon m-0 p-0")}
        </Col>
        <Col className="ps-2 table-name-col">
          <CDataTypography
            variant="typography-variant-body-regular"
            className="table-name"
          >
            {option.tableAlias}
          </CDataTypography>
        </Col>
      </Row>
    </span>
  );
};
