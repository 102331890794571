import { useState } from "react";
import { Col, DropdownItemProps, Row } from "reactstrap";
import {
  ButtonType,
  CDataButton,
} from "../../../../components/buttons/CDataButton";
import { UserRole } from "../../../../models";
import { QueryEditorSaveButton } from "../QueryEditor/QueryEditorSaveButton";
import useQueryTabs from "../Tabs/useQueryTabs";
import { useAppSelector } from "../../../../redux/hooks";

interface IManageDerivedViewButtons {
  queryInput: string;
  handleSaveClick: DropdownItemProps["onClick"];
  handleRefreshClickCallback: React.MouseEventHandler<HTMLButtonElement>;
  openSaveChangesModal: () => void;
  openCreateDerivedViewModal: () => void;
  openCreateSavedQueryModal: () => void;
}

export const ManageDerivedViewButtons = (props: IManageDerivedViewButtons) => {
  const {
    queryInput,
    handleSaveClick,
    handleRefreshClickCallback,
    openCreateDerivedViewModal,
    openCreateSavedQueryModal,
    openSaveChangesModal,
  } = props;

  const usage = useAppSelector((state) => state.usage);
  const user = useAppSelector((state) => state.user);
  const tabs = useQueryTabs();

  const [createDropdownOpen, setCreateDropdownOpen] = useState<boolean>(false);
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);

  const currentTab = tabs.List.find((tab) => tab.id === tabs.CurrentTabId);

  //Dropdown needs more time to close in order for tooltip routing not to break.
  const toggleCreateDropdown = () => {
    setTimeout(() => {
      setCreateDropdownOpen(!createDropdownOpen);
    }, 100);
  };

  const isQueryUser = user.role === UserRole.Query;

  return (
    <Row className="data-explorer-top-row align-items-center mx-0">
      <Col className="ps-0">
        <CDataButton
          data-testid="button-clear"
          buttonType={ButtonType.Tertiary}
          onClick={handleRefreshClickCallback}
          className="ms-2"
          disabled={queryInput === ""}
        >
          <i className="fa-regular fa-xmark align-middle me-2"></i>
          Clear
        </CDataButton>
      </Col>
      <Col>
        <div className="float-end limit-container">
          <QueryEditorSaveButton
            areDerivedViewsAllowed={true}
            connectionsOverLimit={usage.connectionsOverLimit!}
            createDropdownOpen={createDropdownOpen}
            handleSaveClick={handleSaveClick}
            isQueryUser={isQueryUser}
            openSaveChangesModal={openSaveChangesModal}
            isSaveChangesVisible={currentTab!.unsavedChanges!}
            areSaveAsButtonsDisabled={false}
            openCreateDerivedViewModal={openCreateDerivedViewModal}
            openCreateSavedQueryModal={openCreateSavedQueryModal}
            toggle={() => setTooltipOpen(!tooltipOpen)}
            toggleCreateDropdown={toggleCreateDropdown}
            tooltipOpen={tooltipOpen}
            usersOverLimit={usage.usersOverLimit!}
            queryText={queryInput}
          />
        </div>
      </Col>
    </Row>
  );
};
