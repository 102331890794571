import React, { useState } from "react";
import { InfoIcon } from "../../../../../../../components/InfoIcon";
import { QueryBuilderAddButton } from "../components/QueryBuilderAddButton";
import { QueryBuilderCard } from "../components/QueryBuilderCard";
import { QueryBuilderCardHeader } from "../components/QueryBuilderCardHeader";
import { IQueryBuilderCommonProps } from "../../../models/IQueryBuilderCommonProps";
import { AddDimensionsModal } from "./AddDimensionsModal";
import { produce } from "immer";
import { QueryBuilderColumnChipList } from "../components/QueryBuilderColumnChipList";
import { getFullyQualifiedColumnName } from "../../../sqlGenerator/getFullyQualifiedColumnName";
import { CDataModalV2 } from "../../../../../../../components/modal/CDataModalV2";
import {
  ButtonType,
  CDataButton,
} from "../../../../../../../components/buttons/CDataButton";
import { CDataTypography } from "../../../../../../../components/text/CDataTypography";

interface QueryBuilderDimensionsCardProps extends IQueryBuilderCommonProps {}

export function QueryBuilderDimensionsCard(
  props: QueryBuilderDimensionsCardProps,
) {
  const isQueryBuilderEmpty = props.queryData.from.connectionId === "";

  const [isModalOpen, setIsModalOpen] = useState(false);

  function onClickAddDimensions() {
    setIsModalOpen(true);
  }

  return (
    <QueryBuilderCard className="queryBuilderDimensionsCard">
      <QueryBuilderCardHeader
        title={
          <span>
            Dimensions{" "}
            <InfoIcon
              iconId="dimensions-tooltip"
              tooltipMessage="Dimensions are qualitative attributes that provides context or categorization for data, such as time, geography, or product category."
            />
          </span>
        }
      >
        <QueryBuilderAddButton
          onClick={() => onClickAddDimensions()}
          isQueryBuilderEmpty={isQueryBuilderEmpty}
          id="add-dimension-button"
        >
          Add Dimensions
        </QueryBuilderAddButton>
      </QueryBuilderCardHeader>
      <CDataTypography>
        Add dimensions that you want to include in the result set.
      </CDataTypography>
      <DimensionChipList {...props} />
      <AddDimensionsModal
        {...props}
        displayed={isModalOpen}
        close={() => setIsModalOpen(false)}
      />
    </QueryBuilderCard>
  );
}

function DimensionChipList(props: QueryBuilderDimensionsCardProps) {
  const { queryData, setQueryData, connectionsList } = props;
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [currDimensionIndex, setCurrDimensionIndex] = useState(-1);

  if (queryData.groupBy.length === 0) {
    return null;
  }

  const dimensionDeleteWarning = (
    <CDataModalV2
      displayed={showDeleteWarning}
      modalSize={"lg"}
      spacedFooter={false}
      close={() => setShowDeleteWarning(false)}
      title={"Delete Dimension"}
      primaryButton={
        <CDataButton
          buttonType={ButtonType.Danger}
          onClick={() => {
            onRemoveColumn(currDimensionIndex);
            setShowDeleteWarning(false);
          }}
        >
          Delete
        </CDataButton>
      }
      secondaryButton={
        <CDataButton
          buttonType={ButtonType.Secondary}
          onClick={() => setShowDeleteWarning(false)}
        >
          Cancel
        </CDataButton>
      }
    >
      <CDataTypography>
        Deleting this dimension will remove all other customizations made within
        the Query Builder that are linked to this dimension. Are you sure you
        want to proceed?
      </CDataTypography>
    </CDataModalV2>
  );

  function onRemoveColumn(index: number) {
    const newQueryData = produce(queryData, (draft) => {
      draft.groupBy.splice(index, 1);
    });

    setQueryData(newQueryData);
  }

  function onReorderColumns(oldColumnIndex: number, newColumnIndex: number) {
    const reorderedColumns = produce(queryData, (draft) => {
      draft.groupBy.splice(
        newColumnIndex,
        0,
        draft.groupBy.splice(oldColumnIndex, 1)[0],
      );
    });

    setQueryData(reorderedColumns);
  }

  const allSorts = new Set([
    ...queryData.sort.map((s) => getFullyQualifiedColumnName(s.column)),
  ]);

  const allMetrics = new Set([
    ...queryData.metrics.map((m) => getFullyQualifiedColumnName(m.column)),
  ]);

  return (
    <>
      {dimensionDeleteWarning}
      <QueryBuilderColumnChipList
        columns={queryData.groupBy.map((g) => g.column)}
        connectionsList={connectionsList}
        onRemoveColumn={(column, index) => {
          if (
            !allMetrics.has(getFullyQualifiedColumnName(column)) &&
            allSorts.has(getFullyQualifiedColumnName(column))
          ) {
            setCurrDimensionIndex(index);
            setShowDeleteWarning(true);
          } else {
            onRemoveColumn(index);
          }
        }}
        onReorderColumns={onReorderColumns}
        getColumnId={(column) => getFullyQualifiedColumnName(column)}
      />
    </>
  );
}
