import { useEffect, useRef, useState } from "react";
import classnames from "classnames";
import TanstackPaginationButtons from "../../../../components/tables/TanstackPaginationButtons";

import {
  ColumnDef,
  SortingState,
  Table,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { SortIcon } from "../../../../components/tables/components/SortIcon";
import { QueryTabType } from "../Tabs/queryTabType";
import { IQueryTab } from "../Tabs/useQueryTabs";

export type ColumnDefWithClassName<T> = ColumnDef<T> & {
  className: string;
};

export interface IQueryResultsTableProps<T> {
  tab: IQueryTab;
  columns: ColumnDef<T>[];
  data: T[];
  className?: string;
  emptyTableMessage: () => JSX.Element;
  enablePagination: boolean;
  id: string;
  queryEditorHeight: number;
  tableRef?: React.MutableRefObject<Table<T> | undefined>;
}

function QueryResultsTable<T>(props: IQueryResultsTableProps<T>) {
  const { tab } = props;

  const [sorting, setSorting] = useState<SortingState>([
    {
      id: "Column Name",
      desc: false,
    },
  ]);
  const [tableHeight, setTableHeight] = useState("0px");
  const data = props.data;
  const columns = props.columns;
  const resultTableRef = useRef<HTMLDivElement>(null);

  const table = useReactTable<T>({
    data,
    columns,
    state: { sorting },
    defaultColumn: { minSize: 200 },
    enableColumnResizing: true,
    columnResizeMode: "onChange",
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row: any) => row.id,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: props.enablePagination
      ? getPaginationRowModel()
      : undefined,
  });

  if (props.tableRef) {
    props.tableRef.current = table;
  }

  const outerMostContainerOfResultGrid =
    resultTableRef.current?.parentElement?.parentElement;
  const outerMostContainer =
    resultTableRef.current?.parentElement?.parentElement?.parentElement
      ?.parentElement;

  useEffect(() => {
    if (!outerMostContainerOfResultGrid || !outerMostContainer) {
      return;
    }
    const updateTableHeight = () => {
      const cardTitle =
        outerMostContainerOfResultGrid?.querySelector(".card-title");
      const queryResultHeading =
        outerMostContainerOfResultGrid?.querySelector(".query-results");
      const pagination =
        outerMostContainerOfResultGrid?.querySelector(".mt-2.row");
      const toggleButton = outerMostContainer?.querySelector(
        ".data-explorer-result-controls",
      );
      const notificationBar = outerMostContainerOfResultGrid?.querySelector(
        ".components-notification-bar",
      );
      const searchBar = outerMostContainerOfResultGrid?.querySelector(
        ".col-search-filter-bar",
      );
      const buffer =
        (cardTitle?.clientHeight ?? 0) +
        (queryResultHeading?.clientHeight ?? 0) +
        (pagination?.clientHeight ?? 0) +
        (toggleButton?.clientHeight ?? 0) +
        (notificationBar?.clientHeight
          ? notificationBar?.clientHeight + 24
          : 0) +
        (searchBar?.clientHeight ? searchBar?.clientHeight + 80 : 0) +
        80;

      setTableHeight(
        `${
          outerMostContainer?.clientHeight - props.queryEditorHeight - buffer
        }px`,
      );
    };

    updateTableHeight();

    // Create a MutationObserver to detect changes in DOM as cachin prompt notification bar gets added to DOM after some time/logic execution. So we have update height of the table again, if that happens.
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === "childList") {
          updateTableHeight();
          break;
        }
      }
    });

    // Observe outerMostContainerOfResultGrid for changes in child nodes
    observer.observe(outerMostContainerOfResultGrid, {
      childList: true,
    });
  }, [props.queryEditorHeight, outerMostContainer]); // eslint-disable-line

  const customHeight =
    tab.tabType !== QueryTabType.EditDerivedView
      ? {
          maxHeight: tableHeight,
          minHeight: "200px",
        }
      : undefined;

  return (
    <>
      <div
        ref={resultTableRef}
        className={classnames(["tanstack-results-table", props.className])}
        style={customHeight}
      >
        <table
          className={classnames(["listTable-table", "data-explorer-table"])}
          id={props.id}
          style={{ width: table.getTotalSize() }}
        >
          <thead className="listTable-thead">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr
                key={headerGroup.id}
                className={classnames([
                  "data-explorer-results-thead-tr",
                  "text-nowrap",
                ])}
              >
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className={classnames(
                      "data-explorer-results-th",
                      header.column.columnDef.meta?.className,
                    )}
                    style={{ minWidth: header.getSize() }}
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        className={
                          header.column.getCanSort()
                            ? "d-flex align-items-center cursor-pointer select-none"
                            : "d-flex align-items-center"
                        }
                        onClick={() => {
                          if (header.column.getCanSort()) {
                            setSorting([
                              {
                                id: header.column.columnDef.id as string,
                                desc: !sorting[0]?.desc,
                              },
                            ]);
                          }
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.id,
                          header.getContext(),
                        )}
                        {header.column.getCanSort() ? (
                          <SortIcon
                            isSorted={header.column.getIsSorted()}
                            nextSortOrder={header.column.getNextSortingOrder()}
                          />
                        ) : null}
                      </div>
                    )}
                    <div
                      className="expander"
                      onMouseDown={header.getResizeHandler()}
                    />
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <TableBody
            table={table}
            emptyTableMessage={props.emptyTableMessage}
          />
        </table>
      </div>
      {props.enablePagination ? (
        <TanstackPaginationButtons
          table={table}
          paginationOptions={[1, 10, 25, 50]}
        />
      ) : null}
    </>
  );
}

function TableBody<T>({
  table,
  emptyTableMessage,
}: {
  table: Table<T>;
  emptyTableMessage: () => JSX.Element;
}) {
  const [expandedCells, setExpandedCells] = useState<{
    [key: string]: boolean;
  }>({});

  const toggleCellExpand = (cellId: string) => {
    setExpandedCells((prev) => ({
      ...prev,
      [cellId]: !prev[cellId],
    }));
  };

  return (
    <tbody className="data-explorer-results-tbody">
      {table.getRowModel().rows.length > 0 ? (
        table.getRowModel().rows.map((row) => {
          return (
            <tr
              data-testid={`row-${row.id}`}
              key={row.id}
              className={"data-explorer-results-tbody-tr"}
            >
              {row.getVisibleCells().map((cell) => {
                const isExpanded = expandedCells[cell.id];
                const cellValueData = cell.getValue();

                let cellValueToRender;

                if (typeof cellValueData === "string") {
                  cellValueToRender =
                    !isExpanded && cellValueData.length > 250
                      ? `${cellValueData.slice(0, 250)}...`
                      : cellValueData;
                } else {
                  cellValueToRender = flexRender(
                    cell.column.columnDef.cell,
                    cell.getContext(),
                  );
                }

                return (
                  <td
                    key={cell.id}
                    className={classnames([
                      "data-explorer-results-td p-2 pe-0 ps-0",
                      cell.column.columnDef.meta?.className,
                      { "null-values": cellValueData == null },
                    ])}
                  >
                    <div className="text-wrapper-data pe-3 ps-2">
                      {cellValueData != null ? (
                        <div>
                          {cellValueToRender}
                          {(cell.row.original as any).IsPrimary &&
                            cell.column.id === "Column Name" && (
                              <span className="primary-key-icon">
                                <i className="fa-solid fa-key" />
                              </span>
                            )}
                        </div>
                      ) : (
                        "null"
                      )}
                      {typeof cellValueData === "string" &&
                        cellValueData.length > 250 && (
                          <div
                            className="see-more-text pt-2"
                            onClick={() => toggleCellExpand(cell.id)}
                          >
                            {isExpanded ? "- See less" : "+ See more"}
                          </div>
                        )}
                    </div>
                  </td>
                );
              })}
            </tr>
          );
        })
      ) : (
        <tr>
          <td
            colSpan={table.getHeaderGroups()[0].headers.length}
            className="empty-table-message"
          >
            {emptyTableMessage()}
          </td>
        </tr>
      )}
    </tbody>
  );
}

export default QueryResultsTable;
