import { MutableRefObject, useEffect } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { Form, Formik, FormikProps, useFormikContext } from "formik";
import { produce } from "immer";
import * as Yup from "yup";
import { HTML5Backend } from "react-dnd-html5-backend";
import { isEmpty } from "lodash";

import { KeyValueTableRow, IGlobalSettings } from "../../../ApiConnector";
import TextInput from "../../../../../components/form/TextInput";
import {
  ButtonType,
  CDataButton,
} from "../../../../../components/buttons/CDataButton";
import {
  YupOptionalSchema,
  YupRequiredSchema,
} from "../../../../../utility/types/yupHelperTypes";

export interface IGlobalHeadersCardProps {
  globalSettings: IGlobalSettings;
  setGlobalSettings: (globalSettings: IGlobalSettings) => void;
  setUnsavedChanges: (hasUnsavedChanges: boolean) => void;
  disableDrag?: boolean;
  formRef: MutableRefObject<FormikProps<{
    headers: KeyValueTableRow[];
  }> | null>;
}

const yupSchema = Yup.object<YupOptionalSchema<IGlobalSettings>>({
  headers: Yup.array().of(
    Yup.object<YupRequiredSchema<KeyValueTableRow>>({
      name: Yup.string().nullable().trim().required("Name is required."),
      value: Yup.string().nullable().trim().required("Value is required."),
    }),
  ),
});

export const GlobalHeadersCard = (props: IGlobalHeadersCardProps) => {
  const { globalSettings, setGlobalSettings, formRef } = props;

  function setHeaders(headers: KeyValueTableRow[]) {
    setGlobalSettings({
      ...globalSettings,
      headers,
    });
  }

  return (
    <>
      <h5 className="card-title">Headers</h5>
      <div className="global-header-card-description mt-3">
        Add request headers below that will be included in every table that is
        added to your API connection.
      </div>
      <div>
        <Formik
          innerRef={formRef}
          initialValues={globalSettings}
          validationSchema={yupSchema}
          validateOnChange={true}
          onSubmit={() => {
            // no-op
          }}
        >
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <GlobalHeadersCardFormBody {...props} setHeaders={setHeaders} />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

interface IGlobalHeadersCardFormBodyProps {
  setHeaders: (headers: KeyValueTableRow[]) => void;
  setUnsavedChanges: (hasUnsavedChanges: boolean) => void;
}

const GlobalHeadersCardFormBody = (props: IGlobalHeadersCardFormBodyProps) => {
  const { setHeaders, setUnsavedChanges } = props;

  const { values, setValues, validateForm, touched } =
    useFormikContext<IGlobalSettings>();

  // This isn't a real form, just update the parent whenever the form data changes.
  useEffect(() => {
    setHeaders(values.headers);
  }, [values.headers]); // eslint-disable-line

  useEffect(() => {
    if (!isEmpty(touched.headers)) {
      setUnsavedChanges(true);
    }
  }, [touched.headers]); // eslint-disable-line

  useEffect(() => {
    // This is annoying but deleting a row causes the indexes to shuffle around
    // and validation messages to show up on the wrong fields. We have no real key for the rows.
    // We need to trigger a full form validation when the rows are deleted to avoid validation messages
    // being moved to the wrong field.
    validateForm();
  }, [values.headers.length]); // eslint-disable-line
  function addNewHeaderRow() {
    const newTableSettings = produce(values, (draft) => {
      draft.headers.push({
        name: "",
        value: "",
      });
    });
    setValues(newTableSettings, true);
    setHeaders(newTableSettings.headers);

    setUnsavedChanges(true);
  }

  return (
    <div className="pages-apiConnector-components-GlobalHeadersCard">
      {values.headers.length > 0 && (
        <div className="header-row headers-title-row">
          <div className="header-column">
            <h5 className="required">Name</h5>
          </div>
          <div className="header-column">
            <h5 className="required">Value</h5>
          </div>
          {/* Empty div to take up the same space as the buttons in the header rows. */}
          <div className="header-button-container" />
        </div>
      )}
      <DndProvider backend={HTML5Backend}>
        <div className="header-rows-container">
          {values.headers.map((h, index) => {
            return <HeaderRow key={index} {...props} index={index} />;
          })}
        </div>
      </DndProvider>
      <CDataButton
        buttonType={ButtonType.Tertiary}
        className="header-add-button"
        onClick={addNewHeaderRow}
      >
        <i className="fa fa-plus icon" />
        Add Header
      </CDataButton>
    </div>
  );
};

interface IHeaderRowProps {
  index: number;
  setUnsavedChanges: (hasUnsavedChanges: boolean) => void;
  disableDrag?: boolean;
}

const HeaderRow = (props: IHeaderRowProps) => {
  const { index, setUnsavedChanges, disableDrag } = props;

  const { values, setValues } = useFormikContext<IGlobalSettings>();

  const myHeader = values.headers[index];

  const [, dragRef, previewRef] = useDrag(
    () => ({
      type: "HeaderRow",
      item: { index },
      previewOptions: {},
    }),
    [],
  );

  const [, dropRef] = useDrop({
    accept: "HeaderRow",
    drop: (draggedRow: { index: number }) => {
      const newData = produce(values, (draft) => {
        draft.headers.splice(
          index,
          0,
          draft.headers.splice(draggedRow.index, 1)[0],
        );
      });
      setValues(newData);
    },
  });

  return (
    <div ref={dropRef}>
      <div ref={previewRef} className="header-row">
        <div className="header-column">
          <TextInput
            label=""
            name={`headers[${index}].name`}
            aria-label="Header Name"
          />
        </div>
        <div className="header-column">
          <TextInput
            label=""
            name={`headers[${index}].value`}
            aria-label="Header Value"
          />
        </div>
        <div className="header-button-container">
          <CDataButton
            className="headers-icon-button"
            buttonType={ButtonType.Tertiary}
            aria-label={`Delete Header ${myHeader.name}`}
            onClick={() => {
              const newValues = produce(values, (draft) => {
                draft.headers.splice(index, 1);
              });
              setValues(newValues);
              setUnsavedChanges(true);
            }}
          >
            <i className="fa fa-trash-alt" />
          </CDataButton>
          {!disableDrag && (
            <span
              className="dnd-btn"
              aria-label={`Reorder Header ${myHeader.name}`}
              ref={dragRef}
            >
              <i className="fa fa-arrows-alt" />
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
