import React, { ChangeEvent, forwardRef, useRef } from "react";
import classnames from "classnames";
import { APIAuthType, ConnectionType, IAPIAuth } from "../../../../../models";
import { ApiConnectionPageType, IGlobalSettings } from "../../../ApiConnector";
import { Form, Formik, FormikErrors } from "formik";
import { GLOBAL_SETTINGS_CONSTANTS as Constants } from "../../../ApiConnector.constants";
import {
  Card,
  CardBody,
  FormFeedback,
  FormGroup,
  FormText,
  Label,
  Input,
  InputProps,
} from "reactstrap";

import {
  authenticationValidationSchema,
  dummyGlobalSubmit,
} from "../../GlobalSettingsValidationDictionary";

import {
  AuthField,
  basicAuthProps,
  digestAuthProps,
  authFieldsToResetOnTypeChange,
  oAuth1Props,
  oAuth2Props,
} from "./AuthenticationCard.constants";
import CallbackURL from "../../../../connections/components/CallbackURL";
import { createDeepCopy } from "../../../../../utility/CreateDeepCopy";
import { InfoIcon } from "../../../../../components/InfoIcon";
import { OAuthClientFormBody } from "./oauth/OAuthClientFormBody";
import { CDataSelect } from "../../../../../components/select/CDataSelect";
import { MenuItem } from "@mui/material";
import { OAuthPasswordGrantMode } from "../../../../../models/APIConnector/OAuthPasswordGrantMode";
import { OAuthButton } from "./oauth/OAuthButton";

interface IAuthenticationCardProps {
  globalSettings: IGlobalSettings;
  setGlobalSettings: (globalSettings: IGlobalSettings) => void;
  setUnsavedChanges: (hasUnsavedChanges: boolean) => void;
  isFlyout: boolean;
  apiConnectionPageType: ApiConnectionPageType | undefined;
}

const AuthenticationCard = forwardRef(
  (props: IAuthenticationCardProps, authRef: any) => {
    const formRef = useRef<HTMLDivElement>(null);

    function handleAuthCardChange(
      event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean,
      ) => Promise<void | FormikErrors<IAPIAuth>>,
      setFieldTouched?: (
        field: string,
        isTouched?: boolean,
        shouldValidate?: boolean,
      ) => Promise<void | FormikErrors<IAPIAuth>>,
    ) {
      const { value, name, id } = event.target;
      const newGlobalSettings = createDeepCopy(props.globalSettings);
      const authentication: any = newGlobalSettings.authentication;

      if (id === "AuthenticationType") {
        const valueAsNumber = Number(value);
        authentication[name] = valueAsNumber;
        setFieldValue(name, valueAsNumber, true);
        if (setFieldTouched) {
          authFieldsToResetOnTypeChange.forEach((field: string) =>
            setFieldTouched(field, false),
          );
        }
      } else {
        authentication[name] = value;
        setFieldValue(name, value, true);
      }

      props.setGlobalSettings(newGlobalSettings);
      props.setUnsavedChanges(true);
    }

    function dynamicAuthFields(
      errors: any,
      handleBlur: InputProps["onBlur"],
      optionValue: APIAuthType,
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean,
      ) => Promise<void | FormikErrors<IAPIAuth>>,
      touched: any,
    ) {
      if (optionValue) {
        const OAuthClientIdHelperText =
          "To authenticate with OAuth, you must register an OAuth application with your API service to obtain the information requested.";

        const OAuthRefreshTokenUrlHelperText =
          "If left blank, OAuth token will be refreshed using the OAuth Access Token URL.";

        let propArray: AuthField[];
        let isOAuth = false;

        switch (optionValue) {
          case APIAuthType.Basic:
            propArray = basicAuthProps;
            break;
          case APIAuthType.Digest:
            propArray = digestAuthProps;
            break;
          case APIAuthType.OAuth1:
            propArray = oAuth1Props;
            isOAuth = true;
            break;
          case APIAuthType.OAuth2:
            propArray = oAuth2Props;
            isOAuth = true;
            break;
          default:
            return;
        }

        return (
          <>
            <div ref={formRef}>
              {isOAuth && <CallbackURL labelClassName="form-field-title" />}
              {propArray.map((property: AuthField, index: number) => (
                <FormGroup className="my-3" key={index}>
                  <div>
                    <Label
                      data-testid={`label-${property.id}`}
                      className={classnames("form-field-title", {
                        required: property.required,
                      })}
                    >
                      {property.label}
                    </Label>
                    {property.label === "Header Prefix" && (
                      <InfoIcon
                        tooltipMessage="Incorporated into the Authorization header before the token."
                        className="info-icon-col ml-3"
                        iconId="column-name-icon"
                      />
                    )}
                  </div>
                  {property.name === Constants.OAUTH_PASSWORD_GRANT_MODE ? (
                    <CDataSelect
                      id={property.id}
                      name={property.name}
                      aria-label="OAuth Client Authentication Mode"
                      value={
                        !props.globalSettings.authentication
                          .oAuthPasswordGrantMode
                          ? property.default!
                          : props.globalSettings.authentication.oAuthPasswordGrantMode.toString()
                      }
                      // Disabling the renderValue shows the text from the MenuItem components
                      renderValue={undefined}
                      onChange={(event) => {
                        handleAuthCardChange(
                          event as unknown as React.ChangeEvent<HTMLSelectElement>,
                          setFieldValue,
                        );
                      }}
                      onBlur={handleBlur}
                      isInvalid={Boolean(
                        touched[property.name] && errors[property.name],
                      )}
                    >
                      <MenuItem value={OAuthPasswordGrantMode.Basic.toString()}>
                        Send as Basic Auth header
                      </MenuItem>
                      <MenuItem value={OAuthPasswordGrantMode.Post.toString()}>
                        Send in Post body
                      </MenuItem>
                    </CDataSelect>
                  ) : (
                    <Input
                      id={property.id}
                      name={property.name}
                      value={
                        props.globalSettings.authentication[
                          property.name as keyof IAPIAuth
                        ] === ""
                          ? property.default
                          : props.globalSettings.authentication[
                              property.name as keyof IAPIAuth
                            ]
                      }
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        handleAuthCardChange(event, setFieldValue);
                      }}
                      onBlur={handleBlur}
                      type={property.name === "password" ? "password" : "text"}
                      invalid={Boolean(
                        touched[property.name] && errors[property.name],
                      )}
                    />
                  )}
                  {property.id === "OAuthRefreshTokenUrl" ? (
                    <FormText className="mt-0 mb-3">
                      {OAuthRefreshTokenUrlHelperText}
                    </FormText>
                  ) : null}
                  {property.id === "OAuthClientId" ? (
                    <FormText className="mt-0 mb-3">
                      {OAuthClientIdHelperText}
                    </FormText>
                  ) : null}
                  <FormFeedback type="invalid">
                    {errors[property.name]}
                  </FormFeedback>
                </FormGroup>
              ))}
            </div>
            <div>
              <OAuthButton
                key="OauthButton"
                connectionId={props.globalSettings.connectionID}
                apiConnectionPageType={props.apiConnectionPageType!}
                driverType="REST"
                authScheme={optionValue}
                isOAuthWebDriver={isOAuth}
                globalSettings={props.globalSettings}
                setGlobalSettings={props.setGlobalSettings}
                connectionType={ConnectionType.Shared}
                isGoogleConnection={false}
                formRef={formRef}
              />
            </div>
          </>
        );
      }
    }

    const authenticationCardContent = (
      <Formik
        innerRef={authRef}
        initialValues={props.globalSettings?.authentication ?? {}}
        validationSchema={authenticationValidationSchema}
        onSubmit={dummyGlobalSubmit}
      >
        {({
          errors,
          handleBlur,
          handleSubmit,
          setFieldTouched,
          setFieldValue,
          touched,
        }) => (
          <Form onSubmit={handleSubmit}>
            <h5 className="card-title">Authentication</h5>
            <FormGroup className="my-3">
              <Label className="form-field-title">Type</Label>
              <Input
                data-testid="input-authentication-type"
                id="AuthenticationType"
                type="select"
                name={Constants.TYPE}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  handleAuthCardChange(event, setFieldValue, setFieldTouched);
                }}
                value={
                  props.globalSettings?.authentication.type || APIAuthType.None
                }
              >
                <option key="noAuth" value={APIAuthType.None}>
                  No Auth
                </option>
                <option key="basicAuth" value={APIAuthType.Basic}>
                  Basic Auth
                </option>
                <option key="digestAuth" value={APIAuthType.Digest}>
                  Digest Auth
                </option>
                <option key="oauth1" value={APIAuthType.OAuth1}>
                  OAuth 1.0
                </option>
                <option key="oauth2" value={APIAuthType.OAuth2}>
                  OAuth 2.0
                </option>
                <option key="oauthClient" value={APIAuthType.OAuthClient}>
                  OAuth Client
                </option>
              </Input>
            </FormGroup>
            {props.globalSettings?.authentication.type ===
              APIAuthType.OAuthClient && (
              <OAuthClientFormBody
                globalSettings={props.globalSettings}
                setGlobalSettings={props.setGlobalSettings}
                setUnsavedChanges={props.setUnsavedChanges}
                isFlyout={props.isFlyout}
              />
            )}
            {props.globalSettings?.authentication.type !==
              APIAuthType.OAuthClient &&
              dynamicAuthFields(
                errors,
                handleBlur,
                props.globalSettings?.authentication.type,
                setFieldValue,
                touched,
              )}
          </Form>
        )}
      </Formik>
    );

    return (
      <>
        {props.isFlyout ? (
          <div>{authenticationCardContent}</div>
        ) : (
          <Card>
            <CardBody>{authenticationCardContent}</CardBody>
          </Card>
        )}
      </>
    );
  },
);
AuthenticationCard.displayName = "AuthenticationCard";

export default AuthenticationCard;
