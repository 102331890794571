import {
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import { InfoIcon } from "../../../../components/InfoIcon";
import { jobsConstants } from "../../Jobs.constants";
import { useFormikContext } from "formik";
import { ICacheJobExtended } from "./ICacheJobExtended";

interface IRunFrequencyRowProps {
  checkColumnSupport: boolean;
  timeCheckColumnOptions: string[];
  setUnsavedChanges: (unsavedChanges: boolean) => void;
}

export const CacheSchemeRow = ({
  checkColumnSupport,
  timeCheckColumnOptions,
  setUnsavedChanges,
}: IRunFrequencyRowProps) => {
  const { values, setValues } = useFormikContext<ICacheJobExtended>();

  const isTimeCheckColumnOptionsEmpty =
    !timeCheckColumnOptions || timeCheckColumnOptions.length === 0;

  const setIsFullUpdate = (newValue: boolean) => {
    setValues({
      ...values,
      isFullUpdate: newValue,
      timeCheckColumn:
        !newValue && !checkColumnSupport && timeCheckColumnOptions?.length === 1
          ? timeCheckColumnOptions[0]
          : "",
    });
  };

  return (
    <span data-testid="row-cache-scheme">
      <label className="fw-bold mb-1">Cache Scheme:</label>
      <Row className="mb-1">
        <Col>
          <FormGroup check>
            <Input
              id="radio-incremental-updates"
              name="isFullUpdate"
              data-testid="radio-incremental-updates"
              type="radio"
              checked={!values.isFullUpdate && !isTimeCheckColumnOptionsEmpty}
              disabled={isTimeCheckColumnOptionsEmpty}
              className="pe-auto"
              onChange={() => {
                setIsFullUpdate(false);
                setUnsavedChanges(true);
              }}
            />
            <Label className="me-2" check>
              Incremental Updates
            </Label>
          </FormGroup>
          {isTimeCheckColumnOptionsEmpty ? (
            <UncontrolledTooltip
              placement="top"
              target="radio-incremental-updates"
              trigger="hover"
            >
              {jobsConstants.FULL_UPDATES_ONLY}
            </UncontrolledTooltip>
          ) : null}
          <InfoIcon
            iconId="icon-incremental-updates"
            tooltipMessage={jobsConstants.INCREMENTAL_UPDATES_INFO}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <FormGroup check>
            <Input
              data-testid="radio-full-updates"
              name="isFullUpdate"
              type="radio"
              checked={values.isFullUpdate || isTimeCheckColumnOptionsEmpty}
              onChange={() => {
                setIsFullUpdate(true);
                setUnsavedChanges(true);
              }}
            />
            <Label check className="me-2">
              Full Updates
            </Label>
          </FormGroup>
          <InfoIcon
            iconId="icon-full-updates"
            tooltipMessage={jobsConstants.FULL_UPDATES_INFO}
          />
        </Col>
      </Row>
    </span>
  );
};
