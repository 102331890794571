import { isIP } from "is-ip";

export const isUrlValid = (propertyValue: string) => {
  // Checks if the URL is a correctly-formatted IPv4 or IPv6 address. If it is one, it is considered valid.
  if (isIP(propertyValue)) {
    return true;
  }

  // For non-IP address URLs, checks that the URL begins with https: followed by exactly two slashes.
  const urlPattern = /^https:\/\/[^/]+(\/.*)?$/;
  return urlPattern.test(propertyValue);
};
