/* eslint-disable max-lines */
import { useState, useEffect, useRef } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { getDriverIcon } from "../../../components/drivers/DriverIconFactory";
import EntitySetFilterUtil from "./EntitySetFilterUtil";
import { Input, Row, UncontrolledTooltip } from "reactstrap";
import { Col } from "react-bootstrap";
import { LeftPanelType } from "./leftPanelType";
import TopLevelNavigation from "../TopLevelNavigation";
import BackNavigation from "../BackNavigation";
import { DataExplorerPanelMetadata } from "./DataExplorerLeftPanelMetadata";
import SavedQueries from "./SavedQueries/SavedQueries";
import ScheduledQueries from "./ScheduledQueries/ScheduledQueries";
import ConnectionList from "./Connections/ConnectionList";
import { WorkspacesList } from "./Workspaces/WorkspacesList";
import { Link, useNavigate } from "react-router-dom";
import { RequestType, BackendType } from "../../../components/withAPI";
import DerivedViewsTable from "./DerivedViews/DerivedViewsTable";
import SchemaList from "./Schemas/SchemaList";
import {
  IWorkspace,
  IDerivedView,
  IConnection,
  UserRole,
  ConnectionType,
} from "../../../models";
import { getUpgradeLink } from "../../connections/components/ConnectionFunctions";
import { useAPI } from "../../../components/useAPI";
import { useSchemaFinder } from "../../../components/useSchemaFinder";
import { useTableToCacheJobMap } from "./useTableToCacheJobMap";
import { useAppSelector } from "../../../redux/hooks";
import { useMutation } from "@tanstack/react-query";
import { getConnections } from "../../connections/connectionList/getConnections";
import { ToastrError } from "../../../services/toastrService";

export enum DerivedViewAction {
  Delete,
  Rename,
}

type SchemaResponse = [string, string];

export type SchemaTable = {
  name: string;
  schema: string;
  type: string;
};

interface IDataExplorerPanelProps {
  derivedViews: IDerivedView[];
  updateDerivedViews: (derivedViewList: IDerivedView[]) => void;
  derivedViewsLoading: boolean;
  errorCallback: (errorMsg: string) => void;
  sidebarView: LeftPanelType;
  setSidebarView: (sidebarView: LeftPanelType) => void;
  savedQueriesLoading: boolean;
  savedQueries: any;
  fetchDerivedViews: () => void;
  fetchSavedQueries: () => void;
  fetchWorkspaces: () => void;
  workspaces: IWorkspace[];
  connectionsList: IConnection[];
}

const DataExplorerLeftPanel = (props: IDataExplorerPanelProps) => {
  const { sidebarView } = props;
  const api = useAPI();
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user);
  const usage = useAppSelector((state) => state.usage);
  const driversList = useAppSelector((state) => state.driversList);
  const schemaFinder = useSchemaFinder();

  const [connections, setConnections] = useState([]);
  const [tables, setTables] = useState<{ rowId: number; rowTables: any[] }[]>(
    [],
  );
  const [expanded, setExpanded] = useState<any[]>([]);
  // State to track loading for each individual schema row tableBlock by rowId
  const [tablesLoading, setTablesLoading] = useState<{
    [key: number]: boolean;
  }>({});
  const [tablesResponseEmpty, setTablesResponseEmpty] =
    useState<boolean>(false);
  const [schemasLoading, setSchemasLoading] = useState<boolean>(false);
  const [schemas, setSchemas] = useState<string[]>([]);
  const [formattedSchemas, setFormattedSchemas] = useState<any[]>([]);
  const [allConnectionTables, setAllConnectionTables] = useState<SchemaTable[]>(
    [],
  );
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const [isScrollOpen, setIsScrollOpen] = useState<boolean>(false);
  const [hasCustomReports, setHasCustomReports] = useState<boolean>(false);
  const [selectedDriverName, setSelectedDriverName] = useState<string>("");
  const [selectedDriver, setSelectedDriver] = useState<string>("");
  const [selectedConnection, setSelectedConnection] = useState<string>("");
  const [isCachingSupported, setIsCachingSupported] = useState<boolean>(false);
  const connectionsOverLimit = usage.connectionsOverLimit;
  const usersOverLimit = usage.usersOverLimit;
  const [areConnectionsLoading, setAreConnectionsLoading] =
    useState<boolean>(true);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [clickDisabled, setClickDisabled] = useState<boolean>(false);

  const currentUserId = user.id;
  const currentUserRole = user.role;

  const header = { "Connect-Cloud-Client": "CDataDataExplorer" };

  const getComponentName = () => {
    return "pages-dataExplorer-DataExplorerPanel";
  };

  // The caches below are stored as refs instead of state to avoid unneeded re-renders.
  const connectionSchemaCache = useRef<Map<string, string[]>>(
    new Map<string, string[]>(),
  );

  const schemaTableCache = useRef<Map<string, SchemaTable[]>>(
    new Map<string, SchemaTable[]>(),
  );

  useEffect(() => {
    const fetchData = async () => {
      await fetchConnections();
    };
    fetchData();
  }, []); // eslint-disable-line

  useEffect(() => {
    setSearchQuery("");
    if (sidebarView === LeftPanelType.SavedQueries) {
      props.fetchSavedQueries();
    }
  }, [sidebarView]); // eslint-disable-line

  useEffect(() => {
    async function getAllTableNames() {
      await fetchTablesForAllSchemas(selectedConnection);
      setSchemasLoading(false);
    }

    if (sidebarView === LeftPanelType.Schemas && !schemasLoading) {
      setSchemasLoading(true);
      getAllTableNames();
    }
  }, [searchQuery]); // eslint-disable-line

  const getOverageEntity = () => {
    const connectionsOverLimit = usage.connectionsOverLimit;
    const usersOverLimit = usage.usersOverLimit;
    return connectionsOverLimit && usersOverLimit
      ? "connections and users"
      : connectionsOverLimit
        ? "connections"
        : usersOverLimit
          ? "users"
          : "";
  };

  const noData = () => {
    const msg = DataExplorerPanelMetadata.find(
      (type) => type.view === sidebarView,
    )!.noData;
    return (
      <div className="centered-muted-text">
        <div className="text-muted text-center">{msg}</div>
      </div>
    );
  };

  // We have to keep this out of the state, because it will trigger repeated re-renders if it gets updated in the filterer
  let filteredOutSchemas: any = [];
  let onSearchFilter: any = null;

  const renderTopLevelContent = (isScrollOpen: boolean) => {
    if (sidebarView === LeftPanelType.Workspaces) {
      const currView = DataExplorerPanelMetadata.find(
        (type) => type.view === sidebarView,
      )!;
      return (
        <BackNavigation
          callbackOnClick={() => goBackFromSchemasList()}
          icon={
            <i
              className={`fa ${currView.icon} me-2 align-middle category-icon`}
            />
          }
          textValue={currView.name}
        />
      );
    } else if (sidebarView === LeftPanelType.Connections) {
      return renderQueryCategories(isScrollOpen);
    } else if (sidebarView === LeftPanelType.Schemas) {
      const icon = getDriverIcon(
        getDriverNameForSelectedConnection()!,
        "data-explorer-driver-icon me-2",
      );
      return (
        <BackNavigation
          callbackOnClick={() => goBackFromSchemasList()}
          icon={<i className={`fa ${icon} me-2 align-middle category-icon`} />}
          textValue={selectedConnection}
        />
      );
    } else if (sidebarView === LeftPanelType.SavedQueries) {
      const currView = DataExplorerPanelMetadata.find(
        (type) => type.view === sidebarView,
      )!;
      return (
        <BackNavigation
          callbackOnClick={() => goBackFromSchemasList()}
          icon={
            <i
              className={`fa ${currView.icon} me-2 align-middle category-icon`}
            />
          }
          textValue={currView.name}
        />
      );
    } else if (sidebarView === LeftPanelType.DerivedViews) {
      const currView = DataExplorerPanelMetadata.find(
        (type) => type.view === sidebarView,
      )!;
      return (
        <BackNavigation
          callbackOnClick={() => goBackFromSchemasList()}
          icon={
            <i
              className={`fa ${currView.icon} me-2 align-middle category-icon`}
            />
          }
          textValue={currView.name}
        />
      );
    } else {
      const currView = DataExplorerPanelMetadata.find(
        (type) => type.view === sidebarView,
      )!;
      return (
        <BackNavigation
          callbackOnClick={() => handleBackClick()}
          icon={
            <i className={`fa ${currView.icon} align-middle category-icon`} />
          }
          textValue={currView.name}
        />
      );
    }
  };

  const handleBackClick = () => {
    onFilterSearchChange("");
    props.setSidebarView(LeftPanelType.Connections);
  };

  const goBackFromSchemasList = () => {
    if (
      !Object.values(tablesLoading).some((loading) => loading === true) &&
      !schemasLoading
    ) {
      setExpanded([]);
      setAllConnectionTables([]);
      setFormattedSchemas([]);
      setTables([]);
      setSelectedConnection("");
      setHasCustomReports(false);
      onFilterSearchChange("");
      props.setSidebarView(LeftPanelType.Connections);
    }
  };

  const getDriverNameForSelectedConnection = () => {
    if (selectedConnection) {
      return connections.find((conn) => conn[0] === selectedConnection)![2];
    } else {
      return null;
    }
  };

  const getIdAndTypeForSelectedConnection = (selectedConnection: string) => {
    if (selectedConnection && props.connectionsList?.length > 0) {
      const currSelectedConnection = props.connectionsList.find(
        (conn) => conn.name === selectedConnection,
      );
      if (currSelectedConnection) {
        return {
          id: currSelectedConnection.id,
          connectionType: currSelectedConnection.connectionType,
        };
      }
    }
    return null;
  };

  const renderQueryCategories = (isScrollOpen: boolean) => {
    if (sidebarView !== LeftPanelType.Connections) {
      return null;
    }
    return (
      <TopLevelNavigation
        callbackOnClick={(value: LeftPanelType) => props.setSidebarView(value)}
        isScrollOpen={isScrollOpen}
      />
    );
  };

  const fetchConnections = async () => {
    const { status, payload } = await api.callAPI(
      RequestType.Get,
      "/catalogs",
      "Failed to get user defined connection list due to the following error:",
      null,
      BackendType.QueryRouter,
      header,
    );
    if (status === 200) {
      if (payload.error) {
        props.errorCallback(payload.error.message);
        return;
      }
      const conns = payload.results[0].rows;
      const filteredConns = conns.filter(
        (conn: any) => conn[0].toLowerCase() !== "cdata",
      );
      setConnections(filteredConns);
      await isScrollOpenCheck();
    }

    setAreConnectionsLoading(false);
  };

  const isScrollOpenCheck = async () => {
    const node = document.getElementById("main-sidebar-content");
    if (node && node.scrollHeight > node.clientHeight) {
      setIsScrollOpen(true);
    }
  };

  const getConnectionIsTested = async (conn: string): Promise<boolean> => {
    const IdAndTypeForSelectedConnection =
      getIdAndTypeForSelectedConnection(conn);

    if (IdAndTypeForSelectedConnection) {
      const url =
        IdAndTypeForSelectedConnection?.connectionType === 0
          ? `account/connections/${IdAndTypeForSelectedConnection?.id}`
          : `account/connections/${IdAndTypeForSelectedConnection?.id}/getUserDefinedConnectionInfo/${currentUserId}`;

      const { status, payload } = await api.callAPI(
        RequestType.Get,
        url,
        "Failed to get connection due to the following error:",
        null,
        undefined,
        header,
      );

      if (status === 200) {
        if (payload.error) {
          props.errorCallback(payload.error.message);
          return false;
        }
        const connection: IConnection =
          IdAndTypeForSelectedConnection.connectionType ===
          ConnectionType.Shared
            ? payload.connection
            : payload;

        if (connection) return connection.isTested ?? false;
      }
      return false;
    }

    return false;
  };

  const fetchSchemas = async (connection: string) => {
    let schemaNames: string[];
    let schemasAreCached = false;

    // Check if the schemas are already cached; if they are, used the cached data, and if they aren't, get new data.
    if (connectionSchemaCache.current?.has(connection)) {
      schemaNames = connectionSchemaCache.current.get(connection)!;
      schemasAreCached = true;
    } else {
      schemaNames = await getNewSchemaData(connection);
    }

    const schemasAsElements = mapSchemasToElements(schemaNames);

    setFormattedSchemas(schemasAsElements);
    setSchemas(schemaNames);
    if (schemaNames.length === 1) {
      setExpanded([0]);
    }

    if (!schemasAreCached) {
      connectionSchemaCache.current?.set(connection, schemaNames);
    }
  };

  const getNewSchemaData = async (connection: string): Promise<string[]> => {
    const isTested = await getConnectionIsTested(connection);
    const errorMessage =
      "This connection has not been set up properly. Please go to the settings page for this connection and ensure you are able to successfully connect to the data source.";
    try {
      const { status, payload } = await api.callAPI(
        RequestType.Get,
        `/schemas?catalogName=${encodeURIComponent(connection)}`,
        "Failed to get user defined connection list due to the following error:",
        null,
        BackendType.QueryRouter,
        header,
      );

      if (status === 200) {
        const data = await payload;
        if (data.error) {
          !isTested
            ? props.errorCallback(errorMessage)
            : props.errorCallback(payload.error.message);
          return [];
        }
        const schemas: Array<SchemaResponse> = data.results[0].rows;
        const schemaNames = schemas.map((schema: SchemaResponse) => {
          return schema[1];
        });
        return schemaNames;
      }

      return [];
    } catch (err) {
      !isTested
        ? props.errorCallback(errorMessage)
        : props.errorCallback(`Error fetching tables: ${err}`);

      return [];
    }
  };

  const mapSchemasToElements = (schemaNames: string[]) => {
    return schemaNames.map((name: string, index: number) => {
      return {
        name: (
          <div
            // @ts-ignore prexisting issue, needs refactor
            // eslint-disable-next-line
            schemaName={name}
          >
            <div
              className="table-name-text data-explorer-container"
              title={name}
            >
              <span className="align-middle truncate">{name}</span>
            </div>
          </div>
        ),
        id: index,
      };
    });
  };

  const fetchTablesForSchema = async (
    connection: string,
    schema: string,
    isFetchAll: boolean,
  ): Promise<SchemaTable[]> => {
    const fullSchemaName = `${connection}.${schema}`;

    // Check if the tables are already cached; if they are, used the cached data, and if they aren't, get new data and cache it.
    let tablesArray: SchemaTable[] | undefined =
      schemaTableCache.current?.get(fullSchemaName);

    if (tablesArray === undefined) {
      tablesArray = await getNewTableData(connection, schema);

      if (!isFetchAll) {
        await handlePostSingleFetch(connection, tablesArray);
      }

      // Cache the results. If a schema's results come back null (i.e. there's an error), store it in the cache as an empty array so it doesn't get re-fetched as an error again
      schemaTableCache.current?.set(fullSchemaName, tablesArray ?? []);
    }

    return tablesArray;
  };

  const getNewTableData = async (
    connection: string,
    schema: string,
  ): Promise<SchemaTable[]> => {
    const tables = await schemaFinder.get(
      `catalogName=${encodeURIComponent(connection)}&schemaName=${encodeURIComponent(schema)}`,
    );

    if (tables !== null && tables?.length > 0) {
      return mapTablesToSchemaTable(tables);
    }
    return [];
  };

  const mapTablesToSchemaTable = (tables: any[]): SchemaTable[] => {
    return tables.map((el: any) => {
      return {
        name: el.defaultTableName,
        schema: el.schemaName,
        type: el.tableType,
      };
    });
  };

  // Used to determine if a table has a cache job associated with it or not.
  // This is here since we only need to load the cache jobs once and not when opening individual schemas.
  const tableToCacheJobMap = useTableToCacheJobMap();

  const handlePostSingleFetch = async (
    connection: string,
    tablesArray: SchemaTable[],
  ) => {
    // If the results are good, we're good.
    if (tablesArray.length > 0) {
      setTablesResponseEmpty(false);
      return;
    }

    // If the results aren't good, handle the errors.
    const isTested = await getConnectionIsTested(connection);
    const errorMessage =
      "This connection has not been set up properly. Please open this connection and ensure you are able to successfully connect to the data source.";

    setTablesResponseEmpty(true);
    setExpanded([]);
    !isTested ? props.errorCallback(errorMessage) : null;
  };

  const fetchDriverInfo = async (driver: string) => {
    setSelectedDriver(driver);
    const data = driversList;
    const hasCustomReport =
      data?.drivers?.find((driv: any) => driv.driver === driver)
        ?.hasCustomReports || false;
    setHasCustomReports(hasCustomReport);

    const isCachingDisabled =
      data?.drivers?.find((driv: any) => driv.driver === driver)
        ?.isCachingDisabled || false;
    setIsCachingSupported(!isCachingDisabled);
  };

  const handleConnectionSelect = async (
    connection: string,
    driverName: string,
  ) => {
    setSelectedConnection(connection);
    setAllConnectionTables([]);
    setExpanded([]);
    setSelectedDriverName(connection);
    setTablesResponseEmpty(false);
    onFilterSearchChange("");
    props.setSidebarView(LeftPanelType.Schemas);
    setSchemasLoading(true);
    await fetchSchemas(connection);
    await fetchDriverInfo(driverName);
    setSchemasLoading(false);
  };

  const handleSchemaSelect = async (row: any) => {
    setTablesLoading((prev) => ({
      ...prev,
      [row.id]: true,
    }));
    const schema = row.name.props.schemaName;

    const tablesArray = await fetchTablesForSchema(
      selectedConnection,
      schema,
      false,
    );
    setTables([...tables, { rowId: row.id, rowTables: tablesArray }]);
    setTablesLoading((prev) => ({
      ...prev,
      [row.id]: false,
    }));
  };

  const fetchTablesFromSchemaCallback = async (
    selectedConnection: string,
    schema: string,
    rowId: number,
  ) => {
    setTablesLoading((prev) => ({
      ...prev,
      [rowId]: true,
    }));

    const tablesArray = await fetchTablesForSchema(
      selectedConnection,
      schema,
      false,
    );
    setTables([...tables, { rowId: rowId, rowTables: tablesArray }]);
    setTablesLoading((prev) => ({
      ...prev,
      [rowId]: false,
    }));
  };

  const handleOnExpand = (
    row: { id: number; name: JSX.Element },
    isExpand: boolean,
  ) => {
    if (isExpand) {
      setExpanded([...expanded, row.id]);
      handleSchemaSelect(row);
    } else {
      setExpanded(expanded.filter((x) => x !== row.id));
    }
  };

  const onFilterSearchChange = (input: string) => {
    setSearchQuery(input);
    if (onSearchFilter) {
      onSearchFilter(input);
    }
  };

  const fetchTablesForAllSchemas = async (connection: string) => {
    if (allConnectionTables.length > 0 || schemasLoading) {
      // If we already have data or are loading data, no need to get it again.
      return;
    }

    const allTables: SchemaTable[] = [];

    for (const schema of schemas) {
      const tables = await fetchTablesForSchema(connection, schema, true);
      if (tables?.length > 0) {
        allTables.push(...tables);
      }
    }

    setAllConnectionTables(allTables);
  };

  const filterSchemas = (filterVal: string, schemas: any) => {
    filteredOutSchemas = [];
    if (filterVal) {
      const nonMatchingTableIndices =
        checkChildTablesForSearchFilter(filterVal);
      filteredOutSchemas = nonMatchingTableIndices;
      const filteredSchemas = [];
      for (let i = 0; i < schemas.length; i++) {
        //check for tables that match search string that belong to non-expanded schemas
        const anyHiddenTableMatch = checkSchemaForFilterMatchingChildTable(
          schemas[i],
          filterVal,
        );
        if (anyHiddenTableMatch) {
          filteredSchemas.push(schemas[i]);
        } else if (
          schemas[i].name.props.schemaName
            .toLowerCase()
            .indexOf(filterVal.toLowerCase()) !== -1
        ) {
          filteredSchemas.push(schemas[i]);
          //if schema is expanded and matches search, show all child tables
          if (expanded.includes(i)) {
            filteredOutSchemas = [];
          }
        } else if (
          expanded.includes(i) && //if any child tables of expanded schema match search, show parent schema and matching tables
          nonMatchingTableIndices.length < tables.length
        ) {
          filteredSchemas.push(schemas[i]);
        }
      }
      return filteredSchemas;
    } else {
      return schemas;
    }
  };

  const checkChildTablesForSearchFilter = (filterVal: string) => {
    const expandedTables = tables;
    const nonMatchingTableIndices: number[] = [];
    for (let i = 0; i < expandedTables.length; i++) {
      expandedTables[i].rowTables.forEach((table, index) => {
        if (table.name.toLowerCase().indexOf(filterVal.toLowerCase()) === -1) {
          nonMatchingTableIndices.push(index);
        }
      });
    }
    return nonMatchingTableIndices;
  };

  const checkSchemaForFilterMatchingChildTable = (
    schema: any,
    filterVal: any,
  ) => {
    const allTables = allConnectionTables;
    const anyMatch = allTables.some(
      (table: any) =>
        table.schema === schema.name.props.schemaName &&
        table.name.toLowerCase().indexOf(filterVal.toLowerCase()) !== -1,
    );
    return anyMatch;
  };

  const callbackSearchFilter = (onFilter: any) => {
    onSearchFilter = onFilter;
  };

  const renderMainSidebarContent = () => {
    switch (sidebarView) {
      case LeftPanelType.Workspaces:
        return (
          <WorkspacesList
            workspaces={props.workspaces}
            searchQuery={searchQuery}
            clickDisabled={clickDisabled}
            disableTableClick={setClickDisabled}
          />
        );
      case LeftPanelType.Connections:
        return (
          <ConnectionList
            noData={noData}
            filterSearch={onSearchFilter}
            filterConnections={EntitySetFilterUtil.filterConnections}
            connections={connections}
            handleConnectionSelect={handleConnectionSelect}
            callbackSearchFilter={callbackSearchFilter}
            areConnectionsLoading={areConnectionsLoading}
          />
        );
      case LeftPanelType.DerivedViews:
        return (
          <DerivedViewsTable
            noData={noData}
            filterSearch={onSearchFilter}
            derivedViews={props.derivedViews}
            derivedViewsLoading={props.derivedViewsLoading}
            filterDerivedViews={EntitySetFilterUtil.filterDerivedViews}
            callbackSearchFilter={callbackSearchFilter}
            isQueryUser={currentUserRole === UserRole.Query}
            updateDerivedViews={props.updateDerivedViews}
          />
        );
      case LeftPanelType.Schemas:
        return (
          <SchemaList
            noData={noData}
            connectionId={
              getIdAndTypeForSelectedConnection(selectedConnection)?.id
            }
            connectionType={
              getIdAndTypeForSelectedConnection(selectedConnection)
                ?.connectionType
            }
            selectedDriver={selectedDriver}
            filterSearch={onSearchFilter}
            callbackSearchFilter={callbackSearchFilter}
            tablesLoading={tablesLoading}
            schemas={schemas}
            tables={tables}
            expanded={expanded}
            formattedSchemas={formattedSchemas}
            handleOnExpand={handleOnExpand}
            selectedConnection={selectedConnection}
            schemasLoading={schemasLoading}
            filterSchemas={filterSchemas}
            fetchTablesFromSchemaCallback={fetchTablesFromSchemaCallback}
            tablesResponseEmpty={tablesResponseEmpty}
            filteredOutSchemas={filteredOutSchemas}
            searchQuery={searchQuery}
            clickDisabled={clickDisabled}
            disableTableClick={setClickDisabled}
            isCachingSupported={isCachingSupported}
            tableToCacheJobMap={tableToCacheJobMap}
          />
        );
      case LeftPanelType.SavedQueries:
        return (
          <SavedQueries
            noData={noData}
            filterSearch={onSearchFilter}
            savedQueries={props.savedQueries}
            savedQueriesLoading={props.savedQueriesLoading}
            filterSavedQueries={EntitySetFilterUtil.filterSavedQueries}
            callbackSearchFilter={callbackSearchFilter}
            fetchSavedQueries={props.fetchSavedQueries}
            setSidebarView={props.setSidebarView}
          />
        );
      case LeftPanelType.ScheduledQueries:
        return <ScheduledQueries noData={noData} />;
    }
  };

  const getSearchPlaceholder = () => {
    const msg = DataExplorerPanelMetadata.find(
      (type) => type.view === sidebarView,
    )!.searchLabel;
    return msg;
  };

  const { mutate: getConnectionsForPushToCustomReports } = useMutation({
    mutationKey: ["/account/connections"],
    mutationFn: () =>
      getConnections({ IsAdmin: false, CurrentUserId: currentUserId }),
    meta: {
      errorMessage: "Failed to get connection list due to the following error:",
    },
    onSuccess: async (data) => {
      const currentConnection = data.connections?.find(
        (t: any) => t.name === selectedDriverName,
      );

      if (currentConnection) {
        navigate("/connections/edit?defaultTab=4", {
          state: {
            driverType: currentConnection.driver,
            connectionId: currentConnection.id,
            hasCustomReports: true,
          },
        });
      }
    },
    onError: (error) => {
      ToastrError(
        "Failed to get data source information due to the following error:",
        error.message,
      );
    },
  });

  const { mutate: getConnectionsAndClearConnectionMetadataCache } = useMutation(
    {
      mutationKey: ["/account/connections"],
      mutationFn: () =>
        getConnections({ IsAdmin: false, CurrentUserId: currentUserId }),
      meta: {
        errorMessage:
          "Failed to get connection list due to the following error:",
      },
      onSuccess: async (data) => {
        const currentConnection = data.connections?.find(
          (t: any) => t.name === selectedDriverName,
        );

        if (currentConnection) {
          await api.callAPI(
            RequestType.Delete,
            `/account/connection/${currentConnection.id}/metadata`,
            "Failed to clear metadata cache due to the following error:",
          );
        }
      },
      onError: (error) => {
        ToastrError(
          "Failed to get data source information due to the following error:",
          error.message,
        );
      },
    },
  );

  const pushToCustomReports = async () => {
    getConnectionsForPushToCustomReports();
  };

  const clearConnectionMetadataCache = async (event: any) => {
    event.preventDefault();
    getConnectionsAndClearConnectionMetadataCache();
  };

  const refresh = async (event: any) => {
    setDisabled(true);

    switch (sidebarView) {
      case LeftPanelType.Connections:
        setAreConnectionsLoading(true);
        await fetchConnections();
        setAreConnectionsLoading(false);
        break;
      case LeftPanelType.Schemas:
        await clearConnectionMetadataCache(event);
        break;
      case LeftPanelType.DerivedViews:
        props.fetchDerivedViews();
        break;
      case LeftPanelType.SavedQueries:
        props.fetchSavedQueries();
        break;
      case LeftPanelType.Workspaces:
        props.fetchWorkspaces();
        break;
      default:
        break;
    }
    setDisabled(false);
  };

  const customReportRow: JSX.Element =
    hasCustomReports && sidebarView === LeftPanelType.Schemas ? (
      currentUserRole === UserRole.Admin ? (
        <Row className="container background-blue mx-0">
          <Col className="col-md-2 align-self-center first-col">
            <i className="fa fa-info-circle align-middle fa-lg" />
          </Col>
          <Col className="no-padding-right">
            If the table or view you are looking to query does not appear in the
            list below, set up{" "}
            <Link
              to={"#"}
              onClick={pushToCustomReports}
              className="white-underline"
            >
              Custom Reports
            </Link>{" "}
            for this connection.
          </Col>
        </Row>
      ) : (
        <Row className="container background-blue mx-0">
          <Col className="col-md-2 align-self-center first-col">
            <i className="fa fa-info-circle align-middle fa-lg" />
          </Col>
          <Col className="no-padding-right">
            If the table or view you are looking to query does not appear in the
            list below, please contact an administrator to set up Custom Reports
            for this connection.
          </Col>
        </Row>
      )
    ) : (
      <></>
    );

  const dataExplorerTableDisabledTooltip =
    connectionsOverLimit || usersOverLimit ? (
      <UncontrolledTooltip
        placement="top"
        target="dataExplorerTableDisabled"
        trigger="hover"
        autohide={false}
      >
        {getUpgradeLink(props)}
        &nbsp;your plan or remove {getOverageEntity()} to retain full Connect
        Cloud functionality.
      </UncontrolledTooltip>
    ) : null;

  return (
    <div className={`px-0 h-100 ${getComponentName()}`}>
      <div
        id="dataExplorerTableDisabled"
        className={
          connectionsOverLimit || usersOverLimit
            ? "disable-side-panel table-container px-0"
            : "table-container px-0"
        }
      >
        <div className="d-flex">
          <Row className="data-explorer-search mx-0 p-3 flex-grow-1">
            <i className="fa-regular fa-magnifying-glass align-middle me-2 search-glass"></i>
            <Input
              data-testid="data-explorer-search"
              id="searchSchemas"
              placeholder={getSearchPlaceholder()}
              value={searchQuery ?? ""}
              onChange={(e) => onFilterSearchChange(e.target.value)}
            />
          </Row>
          <Row>
            <div
              className={disabled ? "refresh-btn-disabled" : "refresh-btn"}
              onClick={(e: any) => refresh(e)}
            >
              <i className="fa fa-rotate-right"></i>
            </div>
          </Row>
        </div>
        <Row className=" mx-0 drop-shadow">
          <div className="list-container px-0">
            {renderTopLevelContent(isScrollOpen)}
          </div>
        </Row>
        {customReportRow}
        <Row className="data-explorer-table-row mx-0" id="main-sidebar-content">
          <div className="list-container px-0">
            {renderMainSidebarContent()}
          </div>
        </Row>
      </div>
      {dataExplorerTableDisabledTooltip}
    </div>
  );
};

export default DataExplorerLeftPanel;
