import {
  CDataTypography,
  TypographyColor,
  TypographyVariant,
} from "./CDataTypography";
import { CDataTooltip, CDataTooltipType } from "../CDataTooltip";
import React, { useRef, useState } from "react";

interface ITextWithOverflowTooltip {
  fullText: string;
  variant?: TypographyVariant;
  color?: TypographyColor;
  className?: string;
  "data-testid"?: string;
}

export const TextWithOverflowTooltip = (props: ITextWithOverflowTooltip) => {
  const textRef = useRef<HTMLDivElement>(null);
  const [hasOverflow, setHasOverflow] = useState(false);

  // To account for window and element resizing, check if there's overflow whenever the element is hovered
  const recomputeOverflow = () => {
    const element = textRef.current!;
    setHasOverflow(element.offsetWidth < element.scrollWidth);
  };

  return (
    <CDataTooltip
      title={hasOverflow ? props.fullText : ""}
      arrow
      placement="top"
      type={CDataTooltipType.Dark}
    >
      <div className="text-with-overflow-tooltip">
        <CDataTypography
          variant={props.variant}
          color={props.color}
          className={props.className}
        >
          <div
            className="text-with-overflow-tooltip"
            data-testid={props["data-testid"]}
            ref={textRef}
            onMouseOver={recomputeOverflow}
          >
            {props.fullText}
          </div>
        </CDataTypography>
      </div>
    </CDataTooltip>
  );
};
