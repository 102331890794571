import React from "react";

export const OracleOciDataSourceHelperText = () => {
  return (
    <div className="helper-text">
      Copy one of the high, medium, or low connection strings available for your
      Oracle Autonomous Database. These strings can be found within the Database
      Connection menu of your Oracle Autonomous Database.
    </div>
  );
};
