import React from "react";
import { Col, Input, Row } from "reactstrap";
import { JobFrequencyUnit } from "../../../../models/Cache/JobFrequencyUnit";
import classnames from "classnames";

interface IWizardRunFrequencyRow {
  jobFrequency: number;
  setJobFrequency: (newFrequency: number) => void;
  jobFrequencyUnit: JobFrequencyUnit;
  setJobFrequencyUnit: (newFrequency: number) => void;
}

export const WizardRunFrequencyRow = ({
  jobFrequency,
  setJobFrequency,
  jobFrequencyUnit,
  setJobFrequencyUnit,
}: IWizardRunFrequencyRow) => {
  const validateFrequency = (event: React.ChangeEvent) => {
    const value = (event.target as HTMLInputElement).value;
    const regex = /^[0-9]{0,3}$/;

    // Limit the length of the frequency to 3 digits
    if (regex.test(value)) {
      setJobFrequency(parseInt(value || "0"));
    }
  };

  return (
    <span data-testid="row-run-frequency" className="run-frequency-row">
      <label className="fw-bold mb-2">
        Set how often you would like this data to be updated.
      </label>
      <Row className="align-items-start mb-3">
        <Col className="frequency-control flex-column">
          <Input
            name="input-frequency"
            data-testid="input-frequency"
            value={jobFrequency}
            className={classnames("form-control select unit-number-input", {
              "red-focus": jobFrequency === 0,
            })}
            onChange={(event: React.ChangeEvent) => {
              validateFrequency(event);
            }}
          />
          {jobFrequency === 0 ? (
            <div
              className="red-error align-self-start"
              data-testid="validation-text-frequency"
            >
              Value can not equal 0.
            </div>
          ) : null}
        </Col>
        <Col className="ps-0 frequency-control">
          <Input
            name="input-select-frequency-unit"
            data-testid="input-select-frequency-unit"
            type="select"
            className="form-control select"
            value={jobFrequencyUnit}
            onChange={(event: React.ChangeEvent) => {
              setJobFrequencyUnit(
                parseInt((event.target as HTMLInputElement).value),
              );
            }}
          >
            {Object.keys(JobFrequencyUnit)
              .filter((frequency) => !isNaN(Number(frequency)))
              .map((frequency) => (
                <option key={frequency} value={frequency}>
                  {`${JobFrequencyUnit[frequency as keyof typeof JobFrequencyUnit]}s`}
                </option>
              ))}
          </Input>
        </Col>
      </Row>
    </span>
  );
};
