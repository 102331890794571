import React from "react";
import { Button } from "reactstrap";
import { getSalesEmailAddress } from "../../utility/LocalizedEmailAddresses";
import ConnectionLimitIcon from "../../assets/img/auth/MaxConnections.svg?react";
import { useNavigate } from "react-router-dom";

const MaxConnections = () => {
  const navigate = useNavigate();
  const salesEmail = getSalesEmailAddress();
  const emailLink = <a href={`mailto:${salesEmail}`}>{salesEmail}</a>;

  return (
    <div className="m-sm-4 text-center">
      <ConnectionLimitIcon />
      <h1 className="my-4">Connection Limit Reached</h1>
      <p className="font-weight-light text-muted">
        Your account connection limit has been reached. Please contact{" "}
        {emailLink} to upgrade your plan or remove one of your existing
        connections to create a new one.
      </p>
      <Button color="primary" onClick={() => navigate("/")}>
        Return to Connect Cloud{" "}
        <i className="fa fa-solid fa-sm fa-arrow-right ms-1" />
      </Button>
    </div>
  );
};

export default MaxConnections;
