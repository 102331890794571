import React from "react";
import { IAPI, withAPI } from "../../../../components/withAPI";
import {
  ComponentWithRouterProps,
  withRouter,
} from "../../../../components/withRouter";
import { PermissionsCard } from "../../../connections/components";
import { IFullPermission } from "../../../connections/components/PermissionsCard";

interface IPermissionsTab extends IAPI, ComponentWithRouterProps {
  setPermissions: (permissions: IFullPermission[]) => void;
  permissions: IFullPermission[];
}

const PermissionsTab = (props: IPermissionsTab) => {
  function updatePermissions(permissions: IFullPermission[]) {
    const newPermissions = [...permissions];
    props.setPermissions(newPermissions);
  }

  return (
    <PermissionsCard
      permissions={props.permissions}
      updatePermissions={(permissions: IFullPermission[]) =>
        updatePermissions(permissions)
      }
      rowColumnName={"Users"}
    />
  );
};

export default withAPI(withRouter(PermissionsTab));
