import { InputBase, InputBaseProps } from "@mui/material";
import classnames from "classnames";
import React from "react";
import { CDataTypography } from "../text/CDataTypography";

export interface CDataTextFieldProps extends InputBaseProps {
  label?: string | JSX.Element;
  isInvalid?: boolean;
  helperText?: string;
  ["data-testid"]?: string;
  containerTestId?: string;
  containerProps?: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >;
}

export const CDataTextField = (props: CDataTextFieldProps) => {
  const { label, isInvalid, helperText, ...inputBaseProps } = props;

  let title = label;
  if (label && typeof label === "string") {
    title = (
      <CDataTypography
        variant="typography-variant-headline-5"
        className={classnames("mb-2", { required: props.required })}
      >
        {label}
      </CDataTypography>
    );
  }

  const feedback = helperText && (
    <CDataTypography
      variant="typography-variant-helper-text"
      color={
        isInvalid ? "typography-color-danger" : "typography-color-text-regular"
      }
    >
      {helperText}
    </CDataTypography>
  );

  return (
    <div
      className={`cdata-text-field ${props.className}`}
      data-testid={props.containerTestId}
      {...props.containerProps}
    >
      {title}
      <InputBase
        {...inputBaseProps}
        aria-label={
          label != null && typeof label === "string" ? label : undefined
        }
        className={classnames("form-control py-0", {
          "is-invalid": isInvalid,
        })}
      />
      {feedback}
    </div>
  );
};
