import { IBreadcrumbInfoList } from "../../models/Datasets/IBreadcrumbInfoList";
import { cdataFetch } from "../cdataFetch";

export async function getFolderBreadcrumbs(
  workspaceId: string,
  folderId: string,
) {
  return await cdataFetch<IBreadcrumbInfoList>({
    method: "GET",
    url: `/workspaces/${workspaceId}/folders/${folderId}/breadcrumbs`,
  });
}
