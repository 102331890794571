import React, { useContext } from "react";
import { ModalContext } from "../../../routes/ModalContext";
import { IModalProps } from "../../../components/CDataModal";
import { Button } from "reactstrap";
import { ConnectionType, IConnection } from "../../../models";

export function useConnectionModals() {
  const modalFunctions = useContext(ModalContext);

  async function openDataCredentials(
    upgradeLink: JSX.Element,
    credentialBody: JSX.Element,
    openEditConnectionPage: (
      event: React.MouseEvent | null | undefined,
      selectedDriver: string,
      id: string,
      clickedFromCustomReportsIcon: boolean,
      connectionType: ConnectionType,
      overrideConnectionType?: boolean,
    ) => void,
    selectedDriver: string,
    id: string,
  ) {
    const modal = {
      title: "Data Credentials",
      body: (
        <div>
          To use this connection,&nbsp;{upgradeLink}&nbsp;your subscription with
          the Security Package add-on or click &quot;Confirm & Continue&quot; to
          switch back to shared credentials.
          {credentialBody}
        </div>
      ),
      primaryButton: (
        <Button
          color="primary"
          onClick={(event: React.MouseEvent) => {
            openEditConnectionPage(
              event,
              selectedDriver,
              id,
              false,
              ConnectionType.Shared,
              true,
            );
            modalFunctions.toggleModal();
          }}
        >
          Confirm & Continue
        </Button>
      ),
      secondaryButton: (
        <Button color="secondary" onClick={() => modalFunctions.toggleModal()}>
          Cancel
        </Button>
      ),
      displayToggleCloseButton: true,
      displayed: true,
    } as IModalProps;
    modalFunctions.setModal(modal);
  }

  async function openDeleteConnectionModal(
    event: any,
    connections: IConnection[],
    onDelete: (
      connectionId: string,
      driver: string,
      connectionName: string,
    ) => void,
    onBatchDelete: (connections: IConnection[]) => void,
    isSpreadsheetsUser: boolean,
  ) {
    event.stopPropagation();
    let message: string;
    let confirmButton: JSX.Element;
    if (connections.length === 1) {
      message =
        "By processing this request, your previously setup Virtual Datasets, Derived Views, Jobs and Saved Queries may become affected that originate from this connection. Are you sure you want to proceed?";
      confirmButton = (
        <Button
          color="danger"
          onClick={() => {
            onDelete(
              connections[0].id!,
              connections[0].driver!,
              connections[0].name!,
            );
            modalFunctions.toggleModal();
          }}
        >
          <div className="icon no-pointer-event" />
          Delete
        </Button>
      );
    } else {
      message =
        "By processing this request, your previously setup Virtual Datasets, Derived Views, Jobs and Saved Queries may become affected that originate from these connections. Are you sure you want to proceed?";
      confirmButton = (
        <Button
          color="danger"
          onClick={() => {
            onBatchDelete(connections);
            modalFunctions.toggleModal();
          }}
        >
          <div className="icon no-pointer-event" />
          Delete
        </Button>
      );
    }
    if (isSpreadsheetsUser) {
      if (connections.length === 1)
        message =
          "You are about to delete a connection which can not be undone.  Are you sure you want to proceed?";
      else {
        message =
          "You are about to delete connections which can not be undone.  Are you sure you want to proceed?";
      }
    }

    const modal = {
      title: "Delete Connection",
      body: <text>{message}</text>,
      primaryButton: confirmButton,
      secondaryButton: (
        <Button color="secondary" onClick={() => modalFunctions.toggleModal()}>
          Cancel
        </Button>
      ),
      displayToggleCloseButton: true,
      displayed: true,
    } as IModalProps;
    modalFunctions.setModal(modal);
  }

  const modalAndAPIs = {
    OpenDataCredentials: openDataCredentials,
    OpenDeleteConnectionModal: openDeleteConnectionModal,
  };

  return modalAndAPIs;
}
