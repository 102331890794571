import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  forwardRef,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ColumnDef } from "@tanstack/react-table";
import { Column, ITableSettings } from "../SetTable";
import ColumnTable from "./ColumnTable";
import { InfoIcon } from "../../../../components/InfoIcon";
import { Row, Col } from "reactstrap";
import { FormikProps } from "formik";

interface IColumnsTableProps {
  tableSettings: ITableSettings;
  setTableSettings: Dispatch<SetStateAction<ITableSettings>>;
  setUnsavedChanges: (unsavedChanges: boolean) => void;
  isTableFirstLoad: boolean;
  columnsNeedRegeneration: boolean;
  setColumnsNeedRegeneration: (columnsNeedRegeneration: boolean) => void;
  pseudoColumnsRef: MutableRefObject<FormikProps<ITableSettings>>;
}

const emptyColumn: Column = {
  columnName: "",
  path: "",
  dataType: null,
  primaryKey: false,
};

export const selectOptions = [
  { key: "Select", value: 0 },
  { key: "Binary", value: 1 },
  { key: "Varchar", value: 5 },
  { key: "Tinyint", value: 6 },
  { key: "Smallint", value: 7 },
  { key: "Integer", value: 8 },
  { key: "Bigint", value: 9 },
  { key: "Float", value: 10 },
  { key: "Double", value: 11 },
  { key: "Decimal", value: 12 },
  { key: "Numeric", value: 13 },
  { key: "Boolean", value: 14 },
  { key: "Date", value: 15 },
  { key: "Time", value: 16 },
  { key: "Timestamp", value: 17 },
  { key: "UUID", value: 18 },
];

const ColumnsTableContainer = forwardRef(
  (props: IColumnsTableProps, columnsRef: any) => {
    const [columns, setColumns] = useState<Column[]>(
      props.tableSettings.columns,
    );

    useEffect(() => {
      handleColumnsChange();
    }, [columns]); // eslint-disable-line

    useEffect(() => {
      setColumns(props.tableSettings.columns);
    }, [props.tableSettings.columns]);

    //this function will remove any filters associated with the table settings columns that no longer exists
    function removeColumnFilter(newTableSettings: ITableSettings) {
      const tableSettings = { ...newTableSettings };
      const columnsList = new Set(
        tableSettings.columns.map((column) => column.columnName),
      );
      const updatedTableSettingsFilters = tableSettings.filters.filter(
        (tableFilter) => columnsList.has(tableFilter.columnName!),
      );
      tableSettings.filters = updatedTableSettingsFilters;
      return tableSettings;
    }

    function handleColumnsChange() {
      props.setTableSettings((prevTableSettings: ITableSettings) => {
        let newTableSettings = { ...prevTableSettings };
        newTableSettings.columns = columns;
        newTableSettings = removeColumnFilter(newTableSettings);
        return newTableSettings;
      });

      if (!props.isTableFirstLoad) {
        props.setUnsavedChanges(true);
      }
    }
    const columnNameInfoText =
      "Specify the name and relative path of the fields you want to be exposed as columns in your API's data model.";
    const columnDefinitions = useMemo<ColumnDef<Column>[]>(
      () => [
        {
          accessorKey: "columnName",
          header: () => (
            <Row>
              <Col>
                <div className="info-icon-container-column">
                  <label className="form-field-title required">
                    Column Name
                  </label>
                  <InfoIcon
                    tooltipMessage={columnNameInfoText}
                    className="info-icon-column"
                    iconId="info-icon-column"
                  />
                </div>
              </Col>
            </Row>
          ),
          footer: (props) => props.column.id,
        },
        {
          accessorFn: (row) => row.path,
          id: "path",
          header: () => (
            <label className="form-field-title required">Path</label>
          ),
          footer: (props) => props.column.id,
        },
        {
          accessorKey: "dataType",
          header: () => (
            <label className="form-field-title required">Data Type</label>
          ),
          footer: (props) => props.column.id,
        },
        {
          accessorKey: "primaryKey",
          header: () => <label className="form-field-title">Primary Key</label>,
          footer: (props) => props.column.id,
        },
      ],
      // We need to re-render the columns when the request URL changes.
      // If the user adds or changes a variable like `http://myurl/{name}` we need to re-render the columns
      // to ensure that we see the checkmark icon
      // TODO: CLOUD-12840 - Fix this useMemo dependency array
      // eslint-disable-next-line
      [props.tableSettings.requestUrl],
    );

    return (
      <ColumnTable
        columns={columnDefinitions}
        data={columns}
        setData={setColumns}
        emptyRow={emptyColumn}
        selectOptions={selectOptions}
        childTableEnabled={props.tableSettings.useQuerySlicer}
        setUnsavedChanges={props.setUnsavedChanges}
        columnsNeedRegeneration={props.columnsNeedRegeneration}
        setColumnsNeedRegeneration={props.setColumnsNeedRegeneration}
        tableSettings={props.tableSettings}
        ref={columnsRef}
        pseudoColumnsRef={props.pseudoColumnsRef}
      />
    );
  },
);
ColumnsTableContainer.displayName = "ColumnsTableContainer";

export default ColumnsTableContainer;
