import { useState } from "react";
import { Col, Row } from "reactstrap";
import {
  ButtonType,
  CDataButton,
} from "../../../../components/buttons/CDataButton";
import useQueryTabs from "../Tabs/useQueryTabs";
import { generateQueryBuilderSql } from "./sqlGenerator/generateQueryBuilderSql";
import { IQueryBuilderCommonProps } from "./models/IQueryBuilderCommonProps";
import { CDataModalV2 } from "../../../../components/modal/CDataModalV2";
import { QueryTabType } from "../Tabs/queryTabType";

export const QueryBuilderActionButtons = (props: IQueryBuilderCommonProps) => {
  const queryTabs = useQueryTabs();
  const [isClearModalOpen, setIsClearModalOpen] = useState(false);

  const openExecutionTab = () => {
    queryTabs.AddEmptyTab(
      QueryTabType.Query,
      undefined,
      generateQueryBuilderSql(props.queryData),
      false,
      true,
    );
  };

  const clearQueryBuilder = () => {
    queryTabs.ClearQueryBuilderTab(queryTabs.CurrentTabId, QueryTabType.Query);
    setIsClearModalOpen(false);
  };

  return (
    <Row className="query-builder-action-buttons p-2 g-0">
      <Col className="execute-button-column pe-2">
        <CDataButton
          buttonType={ButtonType.Primary}
          onClick={openExecutionTab}
          disabled={props.queryData.from.connectionId === ""}
        >
          <i className="fa fa-solid fa-play me-2" />
          Execute
        </CDataButton>
      </Col>
      <Col className="clear-button-column">
        <CDataButton
          buttonType={ButtonType.Tertiary}
          onClick={() => setIsClearModalOpen(true)}
        >
          <i className="fa-regular fa-xmark align-middle me-2"></i>
          Clear
        </CDataButton>
      </Col>
      <CDataModalV2
        title={"Clear"}
        close={() => setIsClearModalOpen(false)}
        displayed={isClearModalOpen}
        spacedFooter={false}
        primaryButton={
          <CDataButton
            buttonType={ButtonType.Danger}
            onClick={() => clearQueryBuilder()}
          >
            Confirm
          </CDataButton>
        }
        secondaryButton={
          <CDataButton
            buttonType={ButtonType.Secondary}
            onClick={() => setIsClearModalOpen(false)}
          >
            Cancel
          </CDataButton>
        }
      >
        Are you sure you want to clear the contents of the Query Builder? This
        action can not be undone.
      </CDataModalV2>
    </Row>
  );
};
