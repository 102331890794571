import { cloneElement, useContext } from "react";
import { StepWizardChildProps } from "react-step-wizard";
import { StepWizardContext } from "./StepWizardContext";

// Children of StepWizard are automatically passed StepWizardChildProps. We do partial extension here
// to indicate that these are present but don't need to be manually specified.
interface IStepWizardStep extends Partial<StepWizardChildProps> {
  children: JSX.Element;
}

export interface IStepBodyProps extends Partial<StepWizardChildProps> {}

function StepWizardStep(props: IStepWizardStep) {
  const { children, ...stepProps } = props;

  const context = useContext(StepWizardContext);

  return cloneElement(children, {
    ...stepProps,
    nextStep: () => {
      stepProps.nextStep!();
      // Automatically mark the current step as completed when going to the next step.
      context.setStepCompleted(stepProps.currentStep!);
    },
  });
}

export default StepWizardStep;
