import { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { hideFlyout } from "../../../redux/actions";
import EditConnection from "../../connections/EditConnection";
import { useAppDispatch } from "../../../redux/hooks";

const CacheConnection = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(hideFlyout());
    };
  }, []); // eslint-disable-line

  return (
    <>
      <Container
        fluid
        className={"p-0 pages-caching"}
        data-testid="cache-connection-page"
      >
        <Row>
          <Col>
            <EditConnection
              driverType={"PostgreSQL"}
              connectionId={""}
              overrideConnectionType={false}
              isCacheConnection={true}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CacheConnection;
