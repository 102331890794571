import * as Yup from "yup";
import {
  APIAuthType,
  APIPaginationType,
  IAPIPagination,
} from "../../../models";
import { GLOBAL_SETTINGS_CONSTANTS as Constants } from "../ApiConnector.constants";

const oAuthTypes = [APIAuthType.OAuth1, APIAuthType.OAuth2];
const basicDigestTypes = [APIAuthType.Basic, APIAuthType.Digest];
export const authenticationValidationSchema = Yup.object().shape({
  [Constants.OAUTH_ACCESS_TOKEN_URL]: createAuthSchema(
    oAuthTypes,
    "OAuth Access Token URL",
  ),
  [Constants.OAUTH_AUTHORIZATION_URL]: createAuthSchema(
    oAuthTypes,
    "OAuth Authorization URL",
  ),
  [Constants.OAUTH_CLIENT_ID]: createAuthSchema(oAuthTypes, "OAuth Client ID"),
  [Constants.OAUTH_CLIENT_SECRET]: createAuthSchema(
    oAuthTypes,
    "OAuth Client Secret",
  ),
  [Constants.OAUTH_REQUEST_TOKEN_URL]: createAuthSchema(
    [APIAuthType.OAuth1],
    "OAuth Request Token URL",
  ),
  [Constants.PASSWORD]: createAuthSchema(basicDigestTypes, "Password", true),
  [Constants.USER]: createAuthSchema(basicDigestTypes, "User"),
});

function createAuthSchema(
  authTypes: APIAuthType[],
  fieldName: string,
  skipTrimming?: boolean,
) {
  const schema = Yup.string().when(Constants.TYPE, {
    is: (value: APIAuthType) => authTypes.includes(value),
    then: Yup.string().required(`${fieldName} is required.`),
    otherwise: Yup.string(),
  });

  return skipTrimming ? schema : schema.trim();
}

export const paginationInitialValues = (pagination: IAPIPagination) => {
  return {
    [Constants.TYPE]: pagination.type,
    [Constants.OFFSET_PARAM]: pagination.offsetParam,
    [Constants.OFFSET_START]: pagination.startingOffset,
    [Constants.PAGE_SIZE_PARAM]: pagination.pageSizeParam,
    [Constants.PAGE_SIZE]: pagination.pageSize,
    [Constants.PAGE_NUMBER_PARAM]: pagination.pageNumberParam,
    [Constants.TOKEN_PATH]: pagination.tokenPath,
    [Constants.HAS_MORE_PATH]: pagination.hasMorePath,
    [Constants.REQUEST_PARAM]: pagination.requestParam,
    [Constants.REQUEST_PATH]: pagination.requestPath,
    [Constants.URL_PATH]: pagination.urlPath,
    [Constants.URL_HEADER]: pagination.urlHeader,
  };
};

export const paginationValidationSchema = Yup.object().shape({
  [Constants.OFFSET_PARAM]: createPaginationSchema(
    [APIPaginationType.Offset],
    "Offset Param",
  ),
  [Constants.PAGE_NUMBER_PARAM]: createPaginationSchema(
    [APIPaginationType.PageNumber],
    "Page Number Param",
  ),
  [Constants.TOKEN_PATH]: createPaginationSchema(
    [APIPaginationType.PageToken],
    "Token Path",
  ),
  [Constants.REQUEST_PARAM]: createPaginationSchema(
    [APIPaginationType.PageToken],
    "URL Parameter",
  ),
  [Constants.REQUEST_PATH]: createPaginationSchema(
    [APIPaginationType.PageToken],
    "Request Body",
  ),
  [Constants.URL_HEADER]: createPaginationSchema(
    [APIPaginationType.URL],
    "Response Header",
  ),
  [Constants.URL_PATH]: createPaginationSchema(
    [APIPaginationType.URL],
    "Response Body",
  ),

  [Constants.PAGE_SIZE]: Yup.number().when("type", {
    is: (value: APIPaginationType) => value === APIPaginationType.Offset,
    then: Yup.number()
      .required("Page Size is required.")
      .moreThan(0, "A non-zero page size must be specified."),
    otherwise: Yup.number(),
  }),

  [Constants.OFFSET_START]: Yup.number().when("type", {
    is: (value: APIPaginationType) => value === APIPaginationType.Offset,
    then: Yup.number(),
    otherwise: Yup.number(),
  }),
});

function createPaginationSchema(
  paginationTypes: APIPaginationType[],
  fieldName: string,
) {
  return Yup.string().when(Constants.TYPE, {
    is: (value: APIPaginationType) => paginationTypes.includes(value),
    then: Yup.string().trim().required(`${fieldName} is required.`),
    otherwise: Yup.string(),
  });
}

// This function is required for Formik to perform submit validation. It intentionally does not do anything else,
// as we are using Formik only for field validation on the Cards.

export function dummyGlobalSubmit() {
  return;
}
