import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import { trimStart } from "lodash";

import { useAPI } from "../../../../../../components/useAPI";
import {
  IAPIResponse,
  RequestType,
} from "../../../../../../components/withAPI";
import { Column, ITableSettings, QueryInput } from "../../../SetTable";
import {
  APITableResponseType,
  IAPIConnectorProps,
} from "../../../../../../models";
import { mapTable } from "../../../SetTableFactory";
import { mapAPIConnectorProps } from "../../../../ApiConnectorFactory";
import Loader from "../../../../../../components/Loader";
import {
  CheckboxTree,
  ICheckboxTreeRow,
  getFlatRowValuesForCheckboxTree,
} from "../../../../../../components/tables/CheckboxTree";
import { ToastrError } from "../../../../../../services/toastrService";
import { JSONTreeTableRow } from "../../ParsingUtilities/JSON/parseJSONAsTreeTable";
import { XMLTreeTableRow } from "../../ParsingUtilities/XML/parseXMLAsTreeTable";
import { parseResponseAsTreeTable } from "../../ParsingUtilities/parseResponseAsTreeTable";
import { jsonPathToXPath } from "../../ParsingUtilities/jsonPathToXPath";
import { replaceAPITableSettingsURL } from "../../../replaceAPITableSettingsURL";
import { RepeatElementsTooltip } from "./RepeatElementsTooltip";
import { CustomRootPaths, ICustomRootPathsFormData } from "./CustomRootPaths";
import { FormikProps } from "formik";
import { IAPIConnectorSettings } from "../../../types/IAPIConnectorSettings";
import { StepWizardStepFooter } from "../../../../../../components/wizard/components/StepWizardStepFooter";
import {
  ButtonType,
  CDataButton,
} from "../../../../../../components/buttons/CDataButton";
import { IStepBodyProps } from "../../../../../../components/wizard/components/StepWizardStep";
import { IRootPathTreeNode, RootPathNode } from "./RootPathNode";
import { StepWizardContext } from "../../../../../../components/wizard/components/StepWizardContext";

export interface IRootPathsStepProps extends IStepBodyProps {
  apiSettings: IAPIConnectorSettings;

  axiosResult: IAPIResponse;

  queryInputs: QueryInput[];

  isCustomRootPaths: boolean;
  setIsCustomRootPaths: (isCustomRootPaths: boolean) => void;

  selectedRootPaths: string[];
  setSelectedRootPaths: (rootPaths: string[]) => void;

  setSelectedColumns: (columns: Column[]) => void;
}

/** Renders the Add Root Paths step of the Configure wizard when setting up an API Connector table */
export const RootPathsStep = (props: IRootPathsStepProps) => {
  const {
    axiosResult,
    apiSettings: { globalSettings, tableSettings },
    queryInputs,
    isCustomRootPaths,
    setIsCustomRootPaths,
    selectedRootPaths,
    setSelectedRootPaths,
    setSelectedColumns,
    nextStep,
    previousStep,
  } = props;

  const { setPendingCompletedStepsToCurrentStep } =
    useContext(StepWizardContext);

  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [rootPathsResponse, setRootPathsResponse] = useState<Record<
    string,
    string
  > | null>(null);

  const formRef = useRef<FormikProps<ICustomRootPathsFormData>>(null);

  const treeTable: XMLTreeTableRow[] | JSONTreeTableRow[] | Error =
    useMemo(() => {
      const parsedResponse = parseResponseAsTreeTable(
        tableSettings,
        axiosResult,
      );

      return parsedResponse;
    }, [tableSettings, axiosResult]);

  const api = useAPI();

  async function getRootPaths() {
    const tableSettingsWithInputId = replaceAPITableSettingsURL(
      tableSettings,
      queryInputs,
    );
    const apiConnectorProps: IAPIConnectorProps = mapAPIConnectorProps(
      globalSettings,
      [mapTable(tableSettingsWithInputId)],
    );

    const { status, payload } = await api.callAPI(
      RequestType.Post,
      "/getAPIRootPaths",
      "Error loading root paths",
      apiConnectorProps,
    );
    if (status === 200) {
      setRootPathsResponse(payload);
    }
    setLoading(false);
  }

  useEffect(() => {
    getRootPaths();
  }, []); // eslint-disable-line

  useEffect(() => {
    // If we can't parse the API response for any reason, then send the
    // user back to the previous step and give the a toast error.
    if (treeTable instanceof Error) {
      previousStep!();
      ToastrError("Failed to parse response", treeTable.message);
    }
  }, [treeTable]); // eslint-disable-line

  if (treeTable instanceof Error) {
    return null;
  }

  let rows = buildRootPathTree(tableSettings, rootPathsResponse).map((node) =>
    toCheckboxTreeRow(node, treeTable),
  );

  function onNext() {
    if (isCustomRootPaths) {
      // Let the form handle the saving so it can validate for blank root paths.
      formRef.current?.handleSubmit();
    } else {
      if (selectedRootPaths.length === 0) {
        ToastrError("Root path not selected", "Select a root path to continue");
      } else {
        // We need to handle an edge case where a user uses the Custom Repeat Elements feature
        // to manually enter root paths, unchecks the Custom Repeat Elements switch, and then
        // selects root paths with the checkboxes.
        // We need to clear out any manually entered root paths that are not in the tree of checkboxes
        const allPossibleRootPaths = new Set(
          getFlatRowValuesForCheckboxTree(rows),
        );
        const validatedRootPaths = selectedRootPaths.filter((rootPath) =>
          allPossibleRootPaths.has(rootPath),
        );
        if (selectedRootPaths.length !== validatedRootPaths.length) {
          setSelectedRootPaths(validatedRootPaths);
        }
        nextStep!();
      }
    }
  }

  if (searchText.trim().length > 0) {
    const trimmedSearch = searchText.trim().toLowerCase();
    // If the user types something in the search, filter by both displayed text lines for our rows.
    rows = rows.filter(
      (r) =>
        r.data.displayTableName.toLowerCase().includes(trimmedSearch) ||
        r.data.pathDisplayName.toLowerCase().includes(trimmedSearch),
    );
  }

  const isNextButtonDisabled =
    selectedRootPaths.length === 0 ||
    selectedRootPaths.some((s) => s.trim().length === 0);

  return (
    <div className="pages-apiConnector-components-RootPathsStep">
      <>
        {loading && <Loader />}
        {!loading && (
          <>
            <div className="instructionsContainer">
              <div className="instructionsText">
                Add the repeat elements you want added to your API table. Each
                repeat element found in the API response will create a new row
                in the table.
              </div>
              <FormGroup switch className="customRepeatElements-formGroup">
                <Label
                  check
                  for="customRepeatElements-switch"
                  id="customRepeatElements-switchLabel"
                  className="customRepeatElements-switchLabel"
                >
                  Custom Repeat Elements
                </Label>
                <Input
                  id="customRepeatElements-switch"
                  aria-labelledby="customRepeatElements-switchLabel"
                  type="switch"
                  role="switch"
                  checked={isCustomRootPaths}
                  onChange={() => setIsCustomRootPaths(!isCustomRootPaths)}
                />
              </FormGroup>
            </div>

            {!isCustomRootPaths && (
              <>
                <div>
                  <i className="fa-regular fa-magnifying-glass align-middle me-2 search-glass"></i>
                  <input
                    className="border shadow rounded search-bar"
                    type="text"
                    key="filter-tree"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    placeholder="Search..."
                    aria-label="Search"
                  />
                </div>
                <CheckboxTree
                  title={<RepeatElementsTooltip />}
                  rows={rows}
                  checkedValues={selectedRootPaths}
                  setCheckedValues={(checkedValues) => {
                    setSelectedRootPaths(checkedValues);
                    // If the user changes the root paths, clear out the columns.
                    // The columns they can pick will be different and we can't have left-over columns
                    // from a previous use of the wizard.
                    setPendingCompletedStepsToCurrentStep(props.currentStep!);
                    setSelectedColumns([]);
                  }}
                />
              </>
            )}

            {isCustomRootPaths && (
              <CustomRootPaths
                formRef={formRef}
                apiSettings={props.apiSettings}
                onNextStep={() => nextStep!()}
                selectedRootPaths={selectedRootPaths}
                setSelectedColumns={setSelectedColumns}
                setSelectedRootPaths={setSelectedRootPaths}
              />
            )}
          </>
        )}
      </>
      <StepWizardStepFooter>
        <CDataButton
          buttonType={ButtonType.Tertiary}
          color="primary"
          onClick={() => previousStep!()}
        >
          Back
        </CDataButton>
        <CDataButton
          buttonType={ButtonType.Primary}
          disabled={isNextButtonDisabled}
          onClick={() => onNext()}
        >
          Next
        </CDataButton>
      </StepWizardStepFooter>
    </div>
  );
};

/**
 * Takes in the possible root paths from the `/getAPIRootPaths` and builds a tree out of them.
 * Each root path stores the information we need in the UI and any child objects/arrays the user can
 * select.
 */
function buildRootPathTree(
  tableSettings: ITableSettings,
  rootPaths: Record<string, string> | null,
): IRootPathTreeNode[] {
  if (!rootPaths || Object.keys(rootPaths).length === 0) {
    return [];
  }

  const responseType = tableSettings.responseType;

  const tableNameToNode = new Map<string, IRootPathTreeNode>();

  // Step 1 - Make a map of each xpath selector to the object we will show in the UI.

  // tableName will be a field name in an object like `addresses`
  //
  // tableName will be XMLData for an XML response with no repeating elements that the
  // driver thinks is a list.
  for (const tableName of Object.keys(rootPaths)) {
    // xpath will be a string like `/people/addresses` for both XML and JSON
    let xpath = rootPaths[tableName];

    if (responseType === APITableResponseType.JSON) {
      xpath = jsonPathToXPath(xpath);
    }

    tableNameToNode.set(tableName, {
      xpath,
      tableName,
      displayTableName: tableName
        .replace("XMLData", "/")
        .replace("JSONData", "/"),
      depth: xpath.split("/").length,
      pathDisplayName: xpath.length > 1 ? trimStart(xpath, "/") : xpath,
      childPaths: [],
    });
  }

  // Step 2 - Make a tree of each xpath selector to its children.

  for (const xpathObject of tableNameToNode.values()) {
    for (const tableName of xpathObject.xpath.split("/")) {
      if (tableNameToNode.has(tableName)) {
        const parentNode = tableNameToNode.get(tableName);

        if (parentNode !== xpathObject) {
          const node = tableNameToNode.get(tableName)!;
          node.childPaths.push(xpathObject);
        }
      }
    }
  }

  // Step 3 - Only return the top-level nodes of the tree.
  // These are the nodes that have the lowest `depth` field.

  const allNodes = Array.from(tableNameToNode.values());

  const minDepth = Math.min(...allNodes.map((node) => node.depth));

  return allNodes.filter((node) => node.depth === minDepth);
}

/** Converts a root path node from the tree into a node for the `<CheckboxTree />` component */
function toCheckboxTreeRow(
  node: IRootPathTreeNode,
  treeTable: XMLTreeTableRow[] | JSONTreeTableRow[],
): ICheckboxTreeRow<IRootPathTreeNode> {
  return {
    data: node,
    childRows: node.childPaths.map((child) =>
      toCheckboxTreeRow(child, treeTable),
    ),
    getValue: (data) => {
      return data.xpath;
    },
    render: (data) => {
      return <RootPathNode node={data} treeTable={treeTable} />;
    },
  };
}
