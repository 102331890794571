import { IDerivedView } from "../../../models";

export type FilterableEntitySet = {
  created: string;
  id: string;
  lastModified: string;
  query: string;
  name: string;
};

export type FilterableConnection = {
  id: number;
  name: JSX.Element;
};

const filterDerivedViews = (
  filterVal: string,
  derivedViews: IDerivedView[],
) => {
  const filteredDerivedViews = derivedViews.filter(
    (derivedView: IDerivedView) =>
      derivedView.name!.toLowerCase().indexOf(filterVal.toLowerCase()) !== -1,
  );
  return filteredDerivedViews;
};

const filterConnections = (
  filterVal: string,
  connections: FilterableConnection[],
) => {
  const filteredConnections = connections.filter(
    (conn: FilterableConnection) =>
      conn.name.props.title.toLowerCase().indexOf(filterVal.toLowerCase()) !==
      -1,
  );
  return filteredConnections;
};

const filterSavedQueries = (
  filterVal: string,
  savedQueries: FilterableEntitySet[],
) => {
  const filteredSavedQueries = savedQueries.filter(
    (savedQuery: FilterableEntitySet) =>
      savedQuery.name.toLowerCase().indexOf(filterVal.toLowerCase()) !== -1,
  );
  return filteredSavedQueries;
};

export default {
  filterDerivedViews,
  filterConnections,
  filterSavedQueries,
};
