import React from "react";
import {
  JSONTreeTableRow,
  JsonValueType,
} from "../../ParsingUtilities/JSON/parseJSONAsTreeTable";
import { XMLTreeTableRow } from "../../ParsingUtilities/XML/parseXMLAsTreeTable";

export interface IAddColumnsTreeNode {
  xpath: string;
  xpathDisplayName: string;
  columnName: string;
  columnDisplayName: string;
  depth: number;
}

interface IAddColumnsNodeProps {
  node: IAddColumnsTreeNode;
  treeTable: XMLTreeTableRow[] | JSONTreeTableRow[];
}

export const AddColumnsNode = (props: IAddColumnsNodeProps) => {
  const { node, treeTable } = props;

  const row = findTreeTableRowForColumnNode(node, treeTable);

  const icon = getIconByType(row?.type ?? null);

  return (
    <div className="pages-apiConnector-components-AddColumnsNode">
      {icon?.length > 0 && (
        <i className={icon} data-testid="addColumnsNode-icon" />
      )}
      <div className="addColumnsNode-textContainer">
        <div className="addColumnsNode-tableName">{node.columnDisplayName}</div>
        <div className="addColumnsNode-path">{node.xpathDisplayName}</div>
      </div>
    </div>
  );
};

/**
 * Get the icon for what type of root path this is.
 *
 * This will almost always be an array icon unless the API doesn't have any arrays,
 * then it will just show the root path as an object icon.
 */
function getIconByType(type: string | null): string {
  if (!type) {
    return "";
  }

  switch (type) {
    case JsonValueType.String:
      return "fa-regular fa-text type-icon";
    case JsonValueType.Number:
      return "fa-regular fa-hashtag type-icon";
    case JsonValueType.Boolean:
      return "fa-regular fa-light-switch-off type-icon";
    case JsonValueType.Object:
      return "fa-regular fa-brackets-curly type-icon";
    case JsonValueType.Array:
      return "fa-regular fa-brackets type-icon";
    default:
      return "";
  }
}

/**
 * Looks through the JSONTreeTableRows to find information about the column.
 *
 * E.x. if our path is `/people/first_name` this tries to find the same object in the JSONTreeTableRows so
 * we can find out information about the type of field this is (a string, boolean, number, etc).
 */
export function findTreeTableRowForColumnNode(
  node: IAddColumnsTreeNode,
  treeTable: XMLTreeTableRow[] | JSONTreeTableRow[],
): XMLTreeTableRow | JSONTreeTableRow | undefined {
  let matchingRow = [...treeTable].find((row) => {
    const idWithoutIndexes = row.id.replace(/\[0\]/g, "");

    if (idWithoutIndexes === node.xpath) {
      return true;
    }

    return false;
  });

  if (matchingRow) {
    return matchingRow;
  }

  for (const row of treeTable) {
    matchingRow = findTreeTableRowForColumnNode(node, row.subRows ?? []);

    if (matchingRow) {
      return matchingRow;
    }
  }

  return undefined;
}
