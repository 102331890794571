import { createContext } from "react";

interface IDataAssetContext {
  selectedConnection: any;
  schemas: string[];
  allTables: { [key: string]: string[] };
}

export const DataAssetContext = createContext<IDataAssetContext>({
  selectedConnection: {
    name: "",
  },
  schemas: [],
  allTables: {},
});
