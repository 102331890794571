export const GLOBAL_SETTINGS_CONSTANTS = {
  HAS_MORE_PATH: "hasMorePath",
  OAUTH_ACCESS_TOKEN_URL: "oAuthAccessTokenUrl",
  OAUTH_AUTHORIZATION_URL: "oAuthAuthorizationUrl",
  OAUTH_CLIENT_ID: "oAuthClientId",
  OAUTH_CLIENT_SECRET: "oAuthClientSecret",
  OAUTH_PARAMS: "oAuthParams",
  OAUTH_REFRESH_TOKEN_URL: "oAuthRefreshTokenUrl",
  OAUTH_REQUEST_TOKEN_URL: "oAuthRequestTokenUrl",
  OAUTH_PASSWORD_GRANT_MODE: "oAuthPasswordGrantMode",
  OAUTH_SCOPES: "oauthScopes",
  OFFSET_PARAM: "offsetParam",
  OFFSET_START: "startingOffset",
  PAGE_NUMBER_PARAM: "pageNumberParam",
  PAGE_SIZE: "pageSize",
  PAGE_SIZE_PARAM: "pageSizeParam",
  PASSWORD: "password",
  REQUEST_PARAM: "requestParam",
  REQUEST_PATH: "requestPath",
  TOKEN_PATH: "tokenPath",
  TOKEN_SOURCE: "tokenSource",
  TYPE: "type",
  URL_HEADER: "urlHeader",
  URL_PATH: "urlPath",
  URL_SOURCE: "urlSource",
  USER: "user",
  AUTHORIZATION_HEADER_PREFIX: "authorizationHeaderPrefix",
};

export const SET_TABLE_CONSTANTS = {
  COLUMN_NAME: "columnName",
  DATA_TYPE: "dataType",
  HEADER_NAME: "headerName",
  OPERATOR: "operator",
  PARAM_NAME: "paramName",
  PATH: "path",
  ROOT_PATH: "rootPath",
  TYPE: "type",
};
