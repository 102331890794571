import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { IScheduledQuery } from "../../../../models/ScheduledQueries/IScheduledQuery";
import { CDataTypography } from "../../../../components/text/CDataTypography";
import { DestinationWriteScheme } from "../../../../models/ScheduledQueries/DestinationWriteScheme";
import { InfoIcon } from "../../../../components/InfoIcon";
import { useFormikContext } from "formik";

interface IDestinationWriteSchemeRow {
  setUnsavedChanges: (unsavedChanges: boolean) => void;
}

export const DestinationWriteSchemeRow = (
  props: IDestinationWriteSchemeRow,
) => {
  const { setUnsavedChanges } = props;
  const { values, setValues } = useFormikContext<IScheduledQuery>();
  const schemeType = values.destinationWriteScheme;

  function setWriteScheme(writeScheme: DestinationWriteScheme) {
    setValues({
      ...values,
      destinationWriteScheme: writeScheme,
    });
    setUnsavedChanges(true);
  }

  return (
    <span data-testid="row-destination-write-scheme">
      <CDataTypography
        variant="typography-variant-body-medium"
        className="mb-2"
      >
        Destination Write Scheme:
      </CDataTypography>
      <Row className="mb-1">
        <Col>
          <FormGroup check>
            <Input
              id="radio-overwrite-table"
              name="destinationWriteScheme"
              data-testid="radio-overwrite-table"
              type="radio"
              checked={schemeType === DestinationWriteScheme.Overwrite}
              className="pe-auto"
              onChange={() => setWriteScheme(DestinationWriteScheme.Overwrite)}
              value={DestinationWriteScheme.Overwrite}
            />
            <Label className="me-2" check>
              Overwrite table
            </Label>
          </FormGroup>
          <InfoIcon
            iconId="icon-overwrite-table"
            tooltipMessage="If the table exists, Connect Cloud will delete data from the table, then overwrite it."
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <FormGroup check>
            <Input
              data-testid="radio-append-table"
              name="destinationWriteScheme"
              type="radio"
              checked={schemeType === DestinationWriteScheme.Append}
              onChange={() => setWriteScheme(DestinationWriteScheme.Append)}
              value={DestinationWriteScheme.Append}
            />
            <Label check className="me-2">
              Append table
            </Label>
          </FormGroup>
          <InfoIcon
            iconId="icon-append-table"
            tooltipMessage="If the table exists, Connect Cloud will append the data to the table."
          />
        </Col>
      </Row>
    </span>
  );
};
