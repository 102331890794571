import { useEffect, useState } from "react";
import { Container } from "reactstrap";
import Loader from "../../components/Loader";
import { FeatureId, IConnection, IPartialDriver, UserRole } from "../../models";
import { isFeatureAllowed } from "../../utility/SubscriptionAddonsFactory";
import {
  compareDrivers,
  driverCategories,
} from "../../components/drivers/DriverUtilities";
import FilterGrid from "../../components/FilterGrid";
import { withRouter } from "../../components/withRouter";
import {
  DataSourceCard,
  mapDataSourceCard,
} from "./components/mapDataSourceCard";
import TitleWithBackButton from "../../components/TitleWithBackButton";
import { Navigate, useNavigate } from "react-router-dom";
import { useFeatureFlags } from "../../hooks/useFeatureFlags";
import { useAppSelector } from "../../redux/hooks";
import { useQuery } from "@tanstack/react-query";
import { getConnections } from "./connectionList/getConnections";

const SelectConnection = () => {
  const [dataSourceCards, setDataSourceCards] = useState<DataSourceCard[]>([]);
  const [dataSourceLimitReached, setDataSourceLimitReached] = useState(false);
  const flags = useFeatureFlags().getFlags(["oracle_oci"]);
  const navigate = useNavigate();

  const currentUser = useAppSelector((state) => state.user);
  const connectionLimitPerSource =
    useAppSelector(
      (state) => state.subscription.limits?.connectionsPerSource,
    ) ?? -1;
  const driversList = useAppSelector((state) => state.driversList);
  const availableFeatureIds =
    useAppSelector(
      (state) => state.subscription?.limits?.availableFeatureIds,
    ) ?? [];
  const drivers = getDrivers();

  const isAdmin = currentUser.role === UserRole.Admin;

  // Query to load the connections
  const { data: connectionList, isLoading } = useQuery({
    queryKey: ["/account/connections"],
    queryFn: () =>
      getConnections({ IsAdmin: isAdmin, CurrentUserId: currentUser.id }),
    meta: {
      errorMessage: "Failed to get connection list due to the following error:",
    },
  });

  useEffect(() => {
    if (connectionList != null) {
      const connections = connectionList.connections?.map((connection) => ({
        ...connection,
        opsAllowed: 0,
        isCRAvailable: drivers.find(
          (driver) => driver.driver === connection.driver,
        )?.hasCustomReports,
      }));
      if (connections) {
        setDataSourceLimitReached(
          isDataSourceLimitExceeded(
            connections,
            connectionList.dataSourceLimit ?? 0,
          ),
        );
        createDataSourceCards(connections);
      }
    }
  }, [connectionList]); // eslint-disable-line

  function isDriverConnectionLimitReached(
    connections: IConnection[],
    driver: string,
  ) {
    if (connectionLimitPerSource === -1) {
      return false;
    }
    const driverConnections = connections.filter(
      (connection) => connection.driver === driver,
    );
    return driverConnections.length >= connectionLimitPerSource;
  }

  function createDataSourceCards(connections: IConnection[]) {
    if (drivers.length > 0) {
      const newDataSourceCards: DataSourceCard[] = drivers.map(
        (driver, index) => {
          const featureIdToCheck =
            driver.driver === "REST"
              ? FeatureId.APIConnector
              : FeatureId.PremiumDataSources;

          const isPremiumAndUserLacksFeature =
            driver.premium &&
            !isFeatureAllowed(availableFeatureIds, featureIdToCheck);
          return mapDataSourceCard(
            driver,
            isPremiumAndUserLacksFeature,
            index,
            navigate,
            connections,
            isDriverConnectionLimitReached(connections, driver.driver!),
          );
        },
      );

      setDataSourceCards(newDataSourceCards);
    }
  }

  function getDrivers() {
    let mappedDrivers = driversList.drivers!.map((driver) => driver);
    mappedDrivers = mappedDrivers.filter((driverItem) => {
      return driverItem.driver === "OracleOci"
        ? flags.oracle_oci.enabled
        : true;
    });

    mappedDrivers.push(createApiConnectorFakeDriver());
    const visibleDrivers = mappedDrivers.filter((driver) => !driver.hidden);
    return visibleDrivers.sort(compareDrivers);
  }

  function createApiConnectorFakeDriver(): IPartialDriver {
    return {
      beta: true,
      category: "apifile",
      driver: "REST",
      hasCustomReports: false,
      hidden: false,
      niceName: "API",
      premium: true,
      isCachingDisabled: false,
      hasMetadataCache: true,
      scheduledQueryDestination: false,
      version: "1",
    };
  }

  const isDataSourceLimitExceeded = (
    currentConnections: any[],
    dataSourceLimit: number,
  ) => {
    const dataSourceCount = [
      ...new Set(
        currentConnections.map(
          (connection: { driver: any }) => connection.driver,
        ),
      ),
    ].length;
    return dataSourceLimit > -1 && dataSourceCount >= dataSourceLimit;
  };

  if (dataSourceLimitReached) {
    return <Navigate to="/connections" />;
  } else {
    if (isLoading || drivers.length === 0) {
      return (
        <Container fluid className="p-0">
          <Loader />
        </Container>
      );
    } else {
      return (
        <>
          <TitleWithBackButton title={"Add Connection"} />
          <div className="list-connection-grid">
            <FilterGrid
              categories={driverCategories}
              tiles={dataSourceCards}
              tileTypes="Connections"
            />
          </div>
        </>
      );
    }
  }
};

export default withRouter(SelectConnection);
