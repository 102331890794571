import React from "react";
import { UncontrolledTooltip } from "reactstrap";

export const InfoIcon = (props: {
  tooltipMessage: string;
  className?: string;
  iconId?: string;
}) => {
  return (
    <div className="info-icon-div">
      <span
        id={props.iconId ? `${props.iconId}` : "info-icon"}
        className={`info-icon-container ${props.className || ""}`}
      >
        <i className="fa fa-solid fa-circle-info fa-sm" />
      </span>
      <UncontrolledTooltip
        placement="top"
        target={props.iconId ? `${props.iconId}` : "info-icon"}
        trigger="hover"
      >
        {props.tooltipMessage}
      </UncontrolledTooltip>
    </div>
  );
};
