import { MenuItem } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useFormikContext } from "formik";
import { produce } from "immer";
import { isEmpty } from "lodash";
import { CDataFormSelect } from "../../../../../../../components/form/CDataFormSelect";
import { getColumnsForTable } from "../../../../../../../api/metadata/getColumnsForTable";
import {
  IQueryBuilderModel,
  QueryAggregateFunctionType,
} from "../../../models/IQueryBuilderModel";

type MetricColumnSelectProps = {
  metricIndex: number;
};

export function MetricColumnSelect(props: MetricColumnSelectProps) {
  const { metricIndex } = props;

  const { values, setValues, handleBlur } =
    useFormikContext<IQueryBuilderModel>();

  const metric = values.metrics[metricIndex];

  // disable this until the user has selected a table.
  const disabled = isEmpty(metric.column.table.tableName);

  const { data, isLoading } = useQuery({
    queryKey: [
      "/columns",
      metric.column.table.connectionName,
      metric.column.table.schema,
      metric.column.table.tableName,
    ],
    queryFn: () => getColumnsForTable(metric.column.table),
    enabled: !disabled,
    meta: {
      errorMessage:
        "An error ocurred loading the columns for connection = " +
        metric.column.table.connectionName,
    },
  });

  const allColumns = data ?? [];

  const children = allColumns.map((item, index) => (
    // Using index here as the value to handle the fact that SQL allows duplicate column
    // names.
    <MenuItem key={index} value={item.columnName}>
      {item.columnName}
    </MenuItem>
  ));

  return (
    <CDataFormSelect
      disabled={disabled}
      loading={isLoading}
      name={`metrics[${metricIndex}].column.column`}
      onChange={(event) => {
        const newColumnMetadata = allColumns.find(
          (c) => c.columnName === event.target.value,
        );
        const oldColumnMetadata = allColumns.find(
          (c) => c.columnName === metric.column.column,
        );

        let operatorType = metric.operatorType;

        // If the user changes to a column with a different data type,
        // then reset the operator type to COUNT.
        if (
          newColumnMetadata != null &&
          oldColumnMetadata != null &&
          newColumnMetadata.dataType !== oldColumnMetadata.dataType
        ) {
          operatorType = QueryAggregateFunctionType.COUNT;
        }

        setValues(
          produce(values, (draft) => {
            draft.metrics[metricIndex].column.column = event.target.value;
            draft.metrics[metricIndex].operatorType = operatorType;
          }),
          true,
        );
      }}
      onBlur={handleBlur}
      suppressInternalHelperText={true}
    >
      {children}
    </CDataFormSelect>
  );
}
