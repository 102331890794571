import React from "react";
import { Badge, Card, CardBody, Col, Row } from "reactstrap";
import { TextWithOverflowTooltip } from "../../components/text/TextWithOverflowTooltip";

interface IStatisticProps {
  title: string;
  icon: JSX.Element;
  value: number;
  percentChange: string;
  timespanMessage: string;
}

const Statistic: React.FC<IStatisticProps> = (props: IStatisticProps) => {
  return (
    <Card
      className="flex-fill"
      data-testid={`${props.title}`}
      style={{ cursor: "pointer" }}
    >
      <CardBody>
        <Row className="d-flex align-items-center">
          <Col xs="8" data-testid={`${props.title}.Title`}>
            <TextWithOverflowTooltip
              variant="typography-variant-headline-5"
              fullText={props.title}
            />
          </Col>
          <Col xs="4" className="text-end">
            <Badge
              color="#e0eafc"
              className="icon-background"
              data-testid={`${props.title}.Badge`}
            >
              {props.icon}
            </Badge>
          </Col>
        </Row>
        <Row className="d-flex align-items-center mb-3">
          <Col xs="8">
            <h2
              className="d-flex align-items-center mb-0 font-weight-light"
              data-testid={`${props.title}.Value`}
            >
              {props.value}
            </h2>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <Badge
              size={18}
              color=""
              className={
                props.percentChange && props.percentChange[0] === "-"
                  ? "badge-soft-danger me-2"
                  : "badge-soft-success me-2"
              }
              data-testid={`${props.title}.PercentChange`}
            >
              {props.percentChange}
            </Badge>
            <span
              className="text-muted ms-2"
              data-testid={`${props.title}.TimespanMessage`}
            >
              {props.timespanMessage}
            </span>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default Statistic;
