//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum JobFrequencyUnit {
  Hour = 3,
  Day = 4,
  Week = 5,
  Month = 6
}
