import React from "react";

export const OEM401Page = () => {
  return (
    <div className="text-center">
      <h1 className="display-1 font-weight-bold">401</h1>
      <p className="h1">Unauthorized</p>
      <p className="h2 font-weight-normal mt-3 mb-4">
        You no longer have access to this page. <br />
        You token may have timed out, please navigate back to the page you were
        on and try again.
      </p>
    </div>
  );
};
