import { MenuItem, Select } from "@mui/material";

export function TimeSelector(props: {
  options: number[];
  onChange: (value: number) => void;
  value: number;
}) {
  const { options, onChange, value } = props;
  return (
    <Select
      className="timeSelector"
      data-testid="timeSelector"
      displayEmpty
      IconComponent={() => (
        <i className="fa fa-solid fa-sharp fa-chevron-down time-picker-dropdown-icon" />
      )}
      MenuProps={{ sx: { maxHeight: "400px" } }}
      onChange={(event) => onChange(event.target.value as number)}
      sx={{
        border: "none",
        borderRadius: "0",
        height: "24px",
        backgroundColor: "#F6F9FC",
        fontSize: "13px",
        width: "43px",
        // Must be higher than the z-index of the date picker Popper.
        zIndex: 1052,
      }}
      value={value}
    >
      {options.map((option, index) => {
        const formattedOption = option.toString().padStart(2, "0");
        return (
          <MenuItem
            key={index}
            value={option}
            sx={{ paddingBottom: "2px", paddingTop: "2px" }}
            data-testid={`timeSelectorOption-${option}`}
          >
            {formattedOption}
          </MenuItem>
        );
      })}
    </Select>
  );
}
