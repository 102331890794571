//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum QueryType {
  Drivers = 0,
  DriverInfo = 1,
  TestConnection = 2,
  GetOAuthAuthorizationUrl = 3,
  GetOAuthAccessToken = 4,
  CustomReportInfo = 5,
  GenerateCustomReport = 6,
  GetAPIRootPaths = 7,
  GetAPIColumns = 8,
  GetAPIRows = 9,
  GetTimeCheckColumns = 10,
  GetDynamicPropValues = 11,
  DisconnectOAuthAccessToken = 20,
  Catalogs = 100,
  Schemas = 101,
  Tables = 102,
  Columns = 103,
  PrimaryKeys = 104,
  ImportedKeys = 105,
  ExportedKeys = 106,
  Procedures = 107,
  ProcedureParameters = 108,
  Indexes = 109,
  Query = 200,
  QuerySchemaOnly = 201,
  Batch = 202,
  Exec = 203,
  Caching = 204,
  ScheduledQuery = 205,
  ODataServiceDocument = 210,
  ODataMetadata = 211,
  ODataQuery = 212,
  OpenAPIDocument = 220,
  CreateDerivedView = 230,
  AlterDerivedView = 231,
  DropDerivedView = 232
}
