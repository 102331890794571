import React, { useState } from "react";
import { Col, FormGroup, Input, Label, Row, Spinner } from "reactstrap";
import { OpenAPIVersion } from "../../../../models/OpenAPI/OpenAPIVersion";
import { InfoIcon } from "../../../../components/InfoIcon";
import { useAPI } from "../../../../components/useAPI";
import {
  ButtonType,
  CDataButton,
} from "../../../../components/buttons/CDataButton";
import { ToastrError } from "../../../../services/toastrService";
import { DisabledInputWithCopyButton } from "../../../../components/form/DisabledInputWithCopyButton";

export const OpenAPIDownload = (props: {
  workspaceName: string;
  workspaceId: string;
}) => {
  const [convertArraysToStrings, setConvertArraysToStrings] =
    useState<boolean>(false);
  const [version, setVersion] = useState<OpenAPIVersion>(OpenAPIVersion.Three);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const api = useAPI();

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    const selectedVersion = parseInt(e.target.value) as OpenAPIVersion;
    setVersion(selectedVersion);
  }

  async function sendDownloadRequest(downloadURL: string) {
    setIsProcessing(true);

    // Passing an empty error title allows us to show a custom error response
    const { error } = await api.downloadAPI(
      downloadURL,
      "",
      "OpenAPIDocument",
      ".json",
    );

    if (error) {
      ToastrError(
        "Failed to download OpenAPI document",
        "Please ensure that all connections for the tables in this workspace are set up correctly.",
        error.message,
      );
    }

    setIsProcessing(false);
  }

  const openAPITooltip =
    "Generate an OpenAPI file for this workspace that can be used in client applications to integrate with Connect Cloud via OpenAPI (e.g. Salesforce).";

  const versionTooltip =
    "Some client applications do not support Array Types (e.g. Power Apps).";

  const workspaceName = props.workspaceName;
  let downloadDomain: string;
  let requestUrl = window.location.origin;

  if (window.location.origin.includes("localhost")) {
    downloadDomain = "https://localhost:44351";
    requestUrl += `/odata/${workspaceName}`;
  } else {
    downloadDomain = `${window.location.origin}/api`;
    requestUrl += `/api/odata/${workspaceName}`;
  }

  const textVersion = version === OpenAPIVersion.Two ? "v2" : "v3";

  const downloadURL = `/openapi/${encodeURIComponent(textVersion)}/${encodeURIComponent(workspaceName)}?convertArraysToStrings=${encodeURIComponent(convertArraysToStrings)}&requesturl=${encodeURIComponent(requestUrl)}`;
  const displayURL = `${downloadDomain}${downloadURL}`;

  return (
    <FormGroup className="open-api-download">
      <div hidden={!isProcessing}>
        <div className="loading-background" />
        <Spinner className="spinner-border loading-spinner" color="info" />
      </div>
      {openAPITooltip}
      <div className="mt-2 mb-2" data-testid="label-version">
        Version
      </div>
      <Row className="mb-2 version-row" data-testid="selector-version">
        <Col className="version-selector">
          <FormGroup check>
            <Input
              data-testid="selector-v3"
              checked={version === OpenAPIVersion.Three}
              name="openAPIVersion"
              onChange={onChange}
              type="radio"
              value={OpenAPIVersion.Three}
            />
            <Label check className="ms-1">
              3.0
            </Label>
          </FormGroup>
        </Col>
        <Col className="version-selector ps-0">
          <FormGroup check>
            <Input
              data-testid="selector-v2"
              checked={version === OpenAPIVersion.Two}
              name="openAPIVersion"
              onChange={onChange}
              type="radio"
              value={OpenAPIVersion.Two}
            />
            <Label check className="ms-1">
              2.0
            </Label>
          </FormGroup>
        </Col>
      </Row>
      <Row className="align-items-center mb-2">
        <Col className="url-label">
          <div className="form-field-title text-nowrap">OpenAPI URL:</div>
        </Col>
        <Col data-testid="navigation-url" className="ps-0">
          <DisabledInputWithCopyButton text={displayURL} />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col>
          <FormGroup switch className="ps-4">
            <Input
              data-testid="toggle-flatten"
              checked={convertArraysToStrings}
              onChange={() => {
                setConvertArraysToStrings(!convertArraysToStrings);
              }}
              role="switch"
              type="switch"
              value={String(convertArraysToStrings)}
            />
            <Label check>
              Convert array types to strings{" "}
              <InfoIcon tooltipMessage={versionTooltip} iconId="convertInfo" />
            </Label>
          </FormGroup>
        </Col>
      </Row>
      <CDataButton
        buttonType={ButtonType.PrimaryOutline}
        data-testid="button-download-document"
        onClick={() => sendDownloadRequest(downloadURL)}
      >
        <i className="fa fa-solid fa-download fa-sm" /> Download
      </CDataButton>
    </FormGroup>
  );
};
