import { APIAuthType, APIPaginationType } from "../../models";
import { IGlobalSettings } from "./ApiConnector";

export const initialGlobalSettings: IGlobalSettings = {
  connectionID: "",
  connectionName: "",
  authentication: {
    accessKey: "",
    oAuthAccessTokenUrl: "",
    oAuthAuthorizationUrl: "",
    oAuthClientId: "",
    oAuthClientSecret: "",
    oAuthParams: "",
    oAuthRefreshTokenUrl: "",
    oAuthRequestTokenUrl: "",
    oAuthScopes: "",
    password: "",
    secretKey: "",
    type: APIAuthType.None,
    user: "",
    oAuthAccessToken: "",
    oAuthRefreshToken: "",
    authorizationHeaderPrefix: "",
  },
  advanced: {
    verbosity: "2",
  },
  headers: [],
  pagination: {
    hasMorePath: "",
    offsetParam: "",
    startingOffset: 0,
    pageNumberParam: "",
    pageSize: 100,
    pageSizeParam: "",
    requestParam: "",
    requestPath: "",
    tokenPath: "",
    type: APIPaginationType.None,
    urlHeader: "",
    urlPath: "",
  },
  queryParams: [],
};
