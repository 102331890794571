import QueryResultsTable from "./QueryResultsTable";
import { ResultsLoading } from "./ResultsLoading";
import { ColumnDef, Table } from "@tanstack/react-table";
import PromptWithModal from "../../../../components/PromptWithModal";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { IQueryTab } from "../Tabs/useQueryTabs";
import { IDataExplorerRightPanelColumnMetadata } from "../EditorAndResults";

export interface IResultsDetailsGridContainerProps {
  tab: IQueryTab;
  loading: boolean;
  showResults: boolean;
  resultsToggled: boolean;
  resultColumns: ColumnDef<any>[];
  resultData: any[];
  noData: () => JSX.Element;
  columnDetails: ColumnDef<any>[];
  columnMetadata: any[];
  enablePagination?: boolean;
  queryEditorHeight?: number;
  classNames?: string;
  abortRunningQueryRequest: () => void;
  tableRef?: React.MutableRefObject<
    Table<IDataExplorerRightPanelColumnMetadata> | undefined
  >;
}

const ResultsDetailsGridContainer = (
  props: IResultsDetailsGridContainerProps,
) => {
  const {
    tab,
    resultsToggled,
    resultColumns,
    columnDetails,
    columnMetadata,
    loading,
    noData,
    resultData,
    showResults,
    enablePagination = true,
    queryEditorHeight,
  } = props;

  const navigate = useNavigate();

  const gridHasColumns =
    (resultsToggled && resultColumns.length > 0) ||
    (!resultsToggled && columnMetadata.length > 0);

  if (loading) {
    return (
      <>
        <ResultsLoading
          abortRunningQueryRequest={props.abortRunningQueryRequest}
        />
        <PromptWithModal
          customMessage="You have an active query running in the background. Navigating away from this page will cancel the query process. Are you sure you want to proceed?"
          customTitle="Query Results"
          when={loading}
          navigate={(path: string) => navigate(path)}
        />
      </>
    );
  } else if (showResults && gridHasColumns) {
    const id = resultsToggled
      ? "dataExplorerResultsTable"
      : "dataExplorerDetailsTable";
    const columns = resultsToggled ? resultColumns : columnDetails;
    const gridData = resultsToggled ? resultData : columnMetadata;
    const wrapperClassNames = classNames("table-responsive", props.classNames, {
      "results-table": resultsToggled,
    });

    return (
      <QueryResultsTable
        tab={tab}
        columns={columns}
        data={gridData}
        emptyTableMessage={() => noData()}
        enablePagination={enablePagination}
        className={wrapperClassNames}
        id={id}
        queryEditorHeight={queryEditorHeight ?? 150}
        tableRef={props.tableRef}
      />
    );
  } else {
    return (
      <div className="centered-muted-text">
        <div className="text-muted text-center">
          Queried data will appear here
        </div>
      </div>
    );
  }
};

export default ResultsDetailsGridContainer;
