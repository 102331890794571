import { useEffect, useState } from "react";
import { hideFlyout } from "../../redux/actions/flyoutActions";
import { Flyout } from "../../components/Flyout";
import TertiaryButton from "../../components/buttons/TertiaryButton";
import { Container, Row, Col } from "reactstrap";
import ClientConnectivityContent from "./ClientConnectivityContent";
import { useIsSpreadsheetUser } from "../../hooks/useIsSpreadsheetUser";
import { useAPI } from "../../components/useAPI";
import { IWorkspaceList, IWorkspaceListItem } from "../../models";
import { RequestType } from "../../components/withAPI";
import { EndpointsModal } from "./EndpointsModal";
import { useAppDispatch } from "../../redux/hooks";

const Clients = () => {
  const dispatch = useAppDispatch();
  const [workspaces, setWorkspaces] = useState<IWorkspaceListItem[]>([]);
  const [modalDisplayed, setModalDisplayed] = useState(false);
  const api = useAPI();
  const showOnlySpreadsheets = useIsSpreadsheetUser();

  useEffect(() => {
    const getWorkspaceList = async () => {
      const { status, payload } = await api.callAPI(
        RequestType.Get,
        "/workspaces/forCurrentUser",
        "Failed to get workspace list due to the following error:",
      );
      if (status === 200) {
        const list = payload as IWorkspaceList;
        setWorkspaces(list.workspaces as IWorkspaceListItem[]);
      }
    };

    getWorkspaceList();
    return () => {
      dispatch(hideFlyout());
    };
  }, []); // eslint-disable-line

  return (
    <Container fluid className={`p-0 pages-clientConnectivity-Clients`}>
      <Row>
        <Col>
          <Row className="flex-header mb-4 align-items-center">
            <Col>
              <h1 className="h3 ms-1 flex-child mb-0">Client Tools</h1>
            </Col>
            <Col
              className="text-end align-self-center endpoints-button"
              hidden={showOnlySpreadsheets}
            >
              <TertiaryButton
                key="selectConnectionButton"
                className="flex-child"
                onClick={() => setModalDisplayed(true)}
                data-testid="button-view-endpoints"
              >
                <i className="fa fa-eye align-middle me-2 no-pointer-event"></i>
                View Endpoints
              </TertiaryButton>
            </Col>
          </Row>
          <ClientConnectivityContent />
        </Col>
        <Flyout />
      </Row>
      <EndpointsModal
        isDisplayed={modalDisplayed}
        setIsDisplayed={setModalDisplayed}
        workspaces={workspaces}
      />
    </Container>
  );
};

export default Clients;
