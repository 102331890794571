import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {
  customFilter,
  CustomFilterProps,
} from "react-bootstrap-table2-filter";
import { getDriverIcon } from "../../../../components/drivers/DriverIconFactory";
import { Spinner, UncontrolledTooltip } from "reactstrap";
import { getSettingsPageSubscriptionTab } from "../../../../utility/SubscriptionProvider";
import { isFeatureAllowed } from "../../../../utility/SubscriptionAddonsFactory";
import { FeatureId, UserRole } from "../../../../models";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../redux/hooks";
import { FilterableConnection } from "../EntitySetFilterUtil";

interface IConnectionListProps {
  noData: () => JSX.Element;
  filterSearch: CustomFilterProps;
  filterConnections: (
    filterVal: string,
    connections: FilterableConnection[],
  ) => FilterableConnection[];
  connections: string[][];
  driversInfo?: string[][];
  handleConnectionSelect: (connection: string, driverName: string) => void;
  callbackSearchFilter: (props: CustomFilterProps) => void;
  areConnectionsLoading: boolean;
}

const ConnectionList = (props: IConnectionListProps) => {
  let onSearchFilter = props.filterSearch;
  const navigate = useNavigate();
  const drivers = useAppSelector((state) => state.driversList?.drivers);
  const availableFeatureIds =
    useAppSelector(
      (state) => state.subscription?.limits?.availableFeatureIds,
    ) ?? [];
  const usage = useAppSelector((state) => state.usage);
  const user = useAppSelector((state) => state.user);

  const connectionColumns = [
    {
      dataField: "name",
      text: "",
      filter: customFilter({
        onFilter: props.filterConnections,
      } as CustomFilterProps),
      filterRenderer: (onFilter: CustomFilterProps): null => {
        onSearchFilter = onFilter;
        props.callbackSearchFilter(onSearchFilter);
        return null;
      },
    },
    {
      dataField: "id",
      text: "",
      hidden: true,
    },
  ];

  function checkPremiumDisabled(connection: string) {
    connection = connection.replace(/\s/g, "");
    const isPremium = drivers?.filter(
      (driver) => driver.driver === connection,
    )[0]?.premium;
    const premiumConnectorDisabled =
      isPremium &&
      !isFeatureAllowed(availableFeatureIds, FeatureId.PremiumDataSources);
    return premiumConnectorDisabled;
  }

  const formattedConnections = props.connections.map(
    (conn: string[], index: number) => {
      const premiumConnectorDisabled = checkPremiumDisabled(conn[2]);
      const overLimit = usage.connectionsOverLimit || usage.usersOverLimit;

      const upgradeLink = !(user?.role === UserRole.Query) ? (
        <a
          className="upgrade-link"
          onClick={() =>
            navigate("/settings?defaultTab=" + getSettingsPageSubscriptionTab())
          }
        >
          Upgrade
        </a>
      ) : (
        "Upgrade"
      );
      const premiumConnectorTooltip =
        premiumConnectorDisabled && !overLimit ? (
          <UncontrolledTooltip
            placement="top"
            target={`connection-list-row-${conn[0]}-premiumConnection`}
            trigger="hover"
            onClick={(e: { stopPropagation: () => any }) => e.stopPropagation()}
            autohide={false}
          >
            Premium Connectors are not available with your subscription.
            <br />
            {upgradeLink}
            &nbsp;your plan today to continue using this connection.
          </UncontrolledTooltip>
        ) : null;

      const id = premiumConnectorDisabled
        ? `connection-list-row-${conn[0]}-premiumConnection`
        : `connection-list-row-${conn[0]}`;
      return {
        name: (
          <div
            className={
              premiumConnectorDisabled && !overLimit
                ? "table-name-text data-explorer-container data-explorer-table-element premiumConnectorDisabled"
                : "table-name-text data-explorer-container data-explorer-table-element"
            }
            title={conn[0]}
            onClick={() => props.handleConnectionSelect(conn[0], conn[2])}
            data-testid={id}
            id={id}
          >
            {getDriverIcon(conn[2], "data-explorer-driver-icon me-2")}
            <span className="align-middle truncate">{conn[0]}</span>
            <div className="ellipsis-right">
              <i className="fa fa-chevron-right align-middle" />
            </div>
            {premiumConnectorTooltip}
          </div>
        ),
        id: index,
      };
    },
  );

  if (props.areConnectionsLoading) {
    return (
      <div className="loading-table-list" data-testid="spinner-connection-list">
        <Spinner color="primary" />
      </div>
    );
  } else {
    return (
      <BootstrapTable
        bootstrap4
        id="connection-list-data-explorer"
        classes="data-explorer-tab-list"
        bordered={false}
        wrapperClasses="table-borderless px-0"
        keyField="id"
        data={formattedConnections}
        columns={connectionColumns}
        hover={true}
        filter={filterFactory()}
        noDataIndication={props.noData}
      />
    );
  }
};

export default ConnectionList;
