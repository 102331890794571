import React from "react";
import { AssetType } from "../../../models/Datasets/AssetType";
import { AssetRowTile, DatasetsTileTypes } from "./AssetRowTile";
import { IAssetListItem } from "../../../models";
import { IWorkspaceAssetListItem } from "../../../bffmodels/IWorkspaceAssetListItem";

// Accepts an array of objects and the parameters required to create DatasetsTile objects
// Returns a custom array of objects that FilterList can process correctly.
export interface IMapDatasetsTilesForFilterList<T> {
  dataObjects: T[];
  selectedDataObjects: T[];
  toggleCheckbox: (checkbox: IAssetListItem) => void;
  onSuccessfulDelete: (assetId: string) => void;
  openMoveAssetModal?: (
    folderId: string,
    assetId?: string,
    name?: string,
  ) => void;
  copyTile?: (id: string) => void;
  nameType: NameType;
}

export enum NameType {
  Name,
  Alias,
}

export function mapAssetsToTiles(
  props:
    | IMapDatasetsTilesForFilterList<IWorkspaceAssetListItem>
    | IMapDatasetsTilesForFilterList<IAssetListItem>,
) {
  return props.dataObjects.map((assetListItem) => {
    let tileType: DatasetsTileTypes = DatasetsTileTypes.FolderAsset;
    if (assetListItem.assetType === AssetType.Data) {
      tileType = DatasetsTileTypes.DataAsset;
    } else if (assetListItem.assetType === AssetType.Folder) {
      tileType = DatasetsTileTypes.FolderAsset;
    }

    return {
      tile: (
        <AssetRowTile
          assetListItem={assetListItem}
          tileType={tileType}
          selectedDataObjects={props.selectedDataObjects}
          toggleCheckbox={props.toggleCheckbox}
          onSuccessfulDelete={props.onSuccessfulDelete}
          openMoveAssetModal={props.openMoveAssetModal}
          copyTile={props.copyTile}
        />
      ),
      name: assetListItem.alias,
    };
  });
}
