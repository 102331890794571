import { useMemo, useState } from "react";
import ListTable from "../../../components/tables/ListTable";
import {
  ColumnDef,
  OnChangeFn,
  Row,
  RowSelectionState,
} from "@tanstack/react-table";
import { ICacheJobListItem } from "../../../models/Cache/ICacheJobListItem";
import { JobStatusBadge, getJobStatusBadgeText } from "./JobStatusBadge";
import { JobStatus } from "../../../models/Cache/JobStatus";
import { JobType } from "../../../models/Cache/JobType";
import { RunButton, EditButton, DeleteButton } from "./JobsTableButtons";
import { parseUTCDateTime } from "../../../utility/ParseUTCDateTime";
import { useFeatureFlags } from "../../../hooks/useFeatureFlags";
import { getDriverIcon } from "../../../components/drivers/DriverIconFactory";
import { FormGroup, Input } from "reactstrap";
import { IScheduledQueryListItem } from "../../../models/ScheduledQueries/IScheduledQueryListItem";
import { JobListItem } from "../Jobs";
import { IConnection } from "../../../models/Connections/IConnection";
import { FailedConnectionWarningIconWithToolTip } from "./FailedConnectionWarningIconWithToolTip";

export type IJobsTable = {
  cacheJobs: ICacheJobListItem[];
  scheduledQueries: IScheduledQueryListItem[] | undefined;
  deleteJob?: (job: JobListItem) => void;
  editJob: (job: JobListItem) => void;
  runJob: (job: JobListItem) => void;
  selection: RowSelectionState;
  setSelection: OnChangeFn<RowSelectionState>;
  toggleCacheJob: (jobId: string) => void;
  toggleScheduledQuery: (queryId: string) => void;
  setRefresh: () => void;
  isRefreshing: boolean;
  connections: IConnection[];
};

export const JobsTable = (props: IJobsTable) => {
  const flags = useFeatureFlags().getFlags(["scheduled_queries"]);
  const scheduledQueriesEnabled = flags.scheduled_queries.enabled;
  const combinedJobs: JobListItem[] = useMemo(() => {
    const scheduledQueries = scheduledQueriesEnabled
      ? props.scheduledQueries || []
      : [];
    return [...props.cacheJobs, ...scheduledQueries];
  }, [props.cacheJobs, props.scheduledQueries, scheduledQueriesEnabled]);

  const rowButtons = (row: JobListItem) => {
    const status = row.status?.status;
    const disableButtons =
      status === JobStatus.JOB_QUEUED ||
      status === JobStatus.JOB_RUNNING ||
      status === JobStatus.JOB_CREATED;

    return (
      <span className="d-flex justify-content-end">
        <RunButton
          row={row}
          disableButtons={disableButtons}
          runJob={props.runJob}
        />
        <EditButton row={row} editJob={props.editJob} />
        <DeleteButton
          row={row}
          deleteJob={(job) => {
            if (props.deleteJob) {
              props.deleteJob(job);
            }
          }}
        />
      </span>
    );
  };

  function getJobName(row: JobListItem) {
    const rowName = row.name!;
    if (row.jobType === JobType.Caching) {
      const cacheJobNameProcessed =
        rowName.indexOf(".") > -1
          ? rowName.split(".").slice(1).join(".")
          : rowName;
      return cacheJobNameProcessed;
    } else {
      return rowName;
    }
  }
  function getJobNameSubText(
    row: JobListItem,
    connection: IConnection | undefined,
  ) {
    if (row.jobType === JobType.Caching) {
      return (
        <div className="job-name-sub-text">
          Connection: {(row as ICacheJobListItem).sourceConnectionName}
          {connection?.isTested || props.isRefreshing ? null : (
            <FailedConnectionWarningIconWithToolTip
              driver={connection?.driver ?? ""}
              connectionId={connection?.id ?? ""}
              toolTipKey={row.id}
            />
          )}
        </div>
      );
    } else {
      const scheduledRow = row as IScheduledQueryListItem;
      return (
        <div className="job-name-sub-text">
          Destination: {scheduledRow.destinationConnectionName}.
          {scheduledRow.destinationSchema}
        </div>
      );
    }
  }

  const columns = useMemo<ColumnDef<JobListItem>[]>(
    () => [
      {
        accessorKey: "name",
        id: "name",
        enableSorting: true,
        header: () => <span>Name</span>,
        meta: {
          className: "name-column",
        },
        cell: ({ row }) => {
          const nameCellElement = scheduledQueriesEnabled ? (
            <>
              {getJobName(row.original)}
              {getJobNameSubText(
                row.original,
                row.original.jobType === JobType.Caching
                  ? props.connections.find(
                      (c) =>
                        (row.original as ICacheJobListItem).sourceConnection ===
                        c.id,
                    )
                  : undefined,
              )}
            </>
          ) : (
            row.original.name
          );

          return <>{nameCellElement}</>;
        },
      },
      scheduledQueriesEnabled
        ? {
            accessorKey: "jobType",
            id: "jobType",
            enableSorting: true,
            header: () => <span className="hide-overflow-text">Job Type</span>,
            meta: {
              className: "connection-column",
            },
            cell: ({ row }) => {
              return (
                <span className="align-middle hide-overflow-text">
                  {row.original.jobType === JobType.ScheduledQuery
                    ? "Scheduled Query"
                    : "Cache"}
                </span>
              );
            },
          }
        : {
            accessorKey: "sourceConnectionName",
            id: "sourceConnectionName",
            enableSorting: true,
            header: () => (
              <span className="hide-overflow-text">Connection Name</span>
            ),
            meta: {
              className: "connection-column",
            },
            cell: ({ row }) => {
              const cacheRow = row as Row<ICacheJobListItem>;
              return (
                <>
                  {getDriverIcon(
                    cacheRow.original.sourceConnectionDriver!,
                    "connection-icon me-2",
                  )}
                  <span className="align-middle hide-overflow-text">
                    {cacheRow.original.sourceConnectionName}
                  </span>
                </>
              );
            },
          },
      {
        accessorKey: "lastRun",
        id: "lastRun",
        enableSorting: true,
        sortingFn: (rowA, rowB) => {
          const lastRunA = rowA.original?.status?.lastRun;
          const lastRunB = rowB.original?.status?.lastRun;

          if (!lastRunA && lastRunB) {
            return -1;
          } else if (lastRunA && !lastRunB) {
            return 1;
          } else if (lastRunA && lastRunB) {
            return lastRunA.localeCompare(lastRunB);
          }

          return 0;
        },
        header: () => <span>Last Run</span>,
        meta: {
          className: "last-run-column",
        },
        cell: ({ row }) => {
          const lastRun = parseLastRun(row.original.status!.lastRun);
          const nextRun = parseNextRun(row);

          return (
            <span className="d-flex flex-column">
              <span className="last-run hide-overflow-text">{lastRun}</span>
              <span className="next-run hide-overflow-text">{nextRun}</span>
            </span>
          );
        },
      },
      {
        accessorKey: "status",
        id: "status",
        enableSorting: true,
        sortingFn: (rowA, rowB) => {
          const statusA = getJobStatusBadgeText(
            rowA.original.status?.status ?? JobStatus.JOB_CREATED,
          );
          const statusB = getJobStatusBadgeText(
            rowB.original.status?.status ?? JobStatus.JOB_CREATED,
          );

          return statusA.localeCompare(statusB);
        },
        header: () => <span>Status</span>,
        meta: {
          className: "status-column",
        },
        cell: ({ row }) => {
          // TODO: CLOUD-12779: Address lint issues with calling hooks inside of cell. The linter thinks is not a React component due to it's name
          const [enabled, setEnabled] = useState<boolean>(row.original.enabled); // eslint-disable-line

          return (
            <span className="d-flex flex-row">
              <FormGroup switch className="ms-0 align-content-center">
                <Input
                  name={`input-toggle-caching-enabled-${row.original.id}`}
                  id={`input-toggle-caching-enabled-${row.original.id}`}
                  data-testid={`input-toggle-caching-enabled-${row.original.id}`}
                  type="switch"
                  role="switch"
                  value={enabled as any}
                  checked={enabled}
                  className="cursor-pointer"
                  onChange={async () => {
                    await toggleJob(row.original);
                    setEnabled(!enabled);
                  }}
                />
              </FormGroup>
              <JobStatusBadge
                status={row.original.status?.status ?? JobStatus.JOB_CREATED}
                isAddJob={false}
              />
            </span>
          );
        },
      },
      {
        accessorKey: "buttons",
        id: "buttons",
        enableSorting: false,
        header: () => <></>,
        meta: {
          className: "buttons-column",
        },
        cell: ({ row }) => {
          return <>{rowButtons(row.original)}</>;
        },
      },
    ],
    // TODO: CLOUD-12788: Fix this dependency array
    [combinedJobs], // eslint-disable-line
  );

  function parseLastRun(lastRun: string | undefined): string {
    if (lastRun && lastRun !== "0001-01-01T00:00:00Z") {
      const parsedLastDate = parseUTCDateTime(lastRun);
      return `${parsedLastDate.hyphenatedDate} ${parsedLastDate.compiledTime} UTC`;
    } else {
      return "---";
    }
  }

  function parseNextRun(row: Row<JobListItem>): string {
    let nextDate = "";
    let nextTime = "";

    if (
      row.original.status?.nextRun &&
      row.original.status.nextRun !== "0001-01-01T00:00:00Z"
    ) {
      const parsedNextDate = parseUTCDateTime(row.original.status.nextRun);
      nextDate = parsedNextDate.hyphenatedDate;
      nextTime = parsedNextDate.compiledTime;
    } else {
      const today = new Date();
      const parsedToday = parseUTCDateTime(today.toISOString());
      nextDate = parsedToday.hyphenatedDate;
      nextTime = parsedToday.compiledTime;
    }

    if (row.original.jobType === JobType.ScheduledQuery) {
      return `Next Run: ${nextDate} ${nextTime} UTC`;
    }

    return `Next Run: ${nextDate}`;
  }

  async function toggleJob(job: JobListItem) {
    if (job.jobType === JobType.Caching) {
      props.toggleCacheJob(job.id);
    } else {
      props.toggleScheduledQuery(job.id);
    }
  }

  return (
    <span className="pages-jobs-components-jobs-table" data-testid="table-jobs">
      {combinedJobs ? (
        <ListTable
          columns={columns}
          data={combinedJobs}
          emptyTableMessage="Click the Add button to add Datasets from your Cached Connections."
          enableFiltering={true}
          enablePagination={true}
          enableCheckboxes={true}
          searchPlaceholder={"Search jobs..."}
          onRowClick={props.editJob}
          selection={props.selection}
          setSelection={props.setSelection}
          setRefresh={props.setRefresh}
          isRefreshing={props.isRefreshing}
        />
      ) : null}
    </span>
  );
};
