import { FeatureId, UserRole } from "../../../../models";
import { NotificationBar } from "../../../../components/notification/NotificationBar";
import { isFeatureAllowed } from "../../../../utility/SubscriptionAddonsFactory";
import { useAppSelector } from "../../../../redux/hooks";

export type CachingPromptNotificationProps = {
  useCachingPrompt: boolean;
  setUseCachingPrompt: (useCachingPrompt: boolean) => void;
};

export function CachingPromptNotification(
  props: CachingPromptNotificationProps,
) {
  const subscription = useAppSelector((state) => state.subscription);
  const user = useAppSelector((state) => state.user);

  const { useCachingPrompt, setUseCachingPrompt } = props;

  const isCachingEnabled = isFeatureAllowed(
    subscription?.limits?.availableFeatureIds ?? [],
    FeatureId.Caching,
  );

  const lightbulbIcon = "fa-solid fa-lightbulb fa-lg pe-2";

  let cachingPromptNotification;

  if (useCachingPrompt) {
    if (user.role === UserRole.Query) {
      cachingPromptNotification = (
        <div className="mb-4">
          <NotificationBar
            message="Contact your administrator to implement Caching for this table to enhance performance & reduce load time."
            barColor="notification-bar-blue"
            onClose={() => setUseCachingPrompt(false)}
            iconOverride={lightbulbIcon}
          />
        </div>
      );
    } else if (isCachingEnabled) {
      cachingPromptNotification = (
        <div className="mb-4">
          <NotificationBar
            message="Enhance performance & reduce load time by implementing Caching for this table."
            barColor="notification-bar-blue"
            onClose={() => setUseCachingPrompt(false)}
            iconOverride={lightbulbIcon}
            linkText="Create Job"
            navigateDestination="/jobs"
          />
        </div>
      );
    } else {
      cachingPromptNotification = (
        <div className="mb-4">
          <NotificationBar
            message="Enhance performance & reduce load time by implementing Caching for this table."
            barColor="notification-bar-blue"
            onClose={() => setUseCachingPrompt(false)}
            iconOverride={lightbulbIcon}
            linkText="Upgrade Plan"
            navigateDestination="/settings?defaultTab=Billing"
          />
        </div>
      );
    }
  }

  return <>{cachingPromptNotification}</>;
}
