import React from "react";
import { Input, Label } from "reactstrap";
import { ResourceType } from "../ModalPromptBody";

interface ISelectResourceTypeButtons {
  selectedResourceType: ResourceType;
  setSelectedResourceType: (resourceType: ResourceType) => void;
}

const SelectResourceTypeButtons = (props: ISelectResourceTypeButtons) => {
  return (
    <>
      <div className="my-2 fw-bold">Configure for:</div>
      <div className="my-2">
        <Input
          name="resourceType"
          type="radio"
          value="Connections"
          checked={props.selectedResourceType === ResourceType.Connection}
          onChange={() =>
            props.setSelectedResourceType(ResourceType.Connection)
          }
        />
        <Label className="ms-2 me-3" check>
          Connections
        </Label>
        <Input
          name="resourceType"
          type="radio"
          value="Workspaces"
          checked={props.selectedResourceType === ResourceType.Workspace}
          onChange={() => props.setSelectedResourceType(ResourceType.Workspace)}
        />
        <Label className="ms-2" check>
          Workspaces
        </Label>
      </div>
    </>
  );
};

export default SelectResourceTypeButtons;
