import { Card, CardBody, Col, Row } from "reactstrap";
import { CDataCodeMirror } from "../../../../components/codemirror/CDataCodeMirror";
import { CDataTypography } from "../../../../components/text/CDataTypography";
import {
  ButtonType,
  CDataButton,
} from "../../../../components/buttons/CDataButton";
import { useNavigate } from "react-router-dom";
import { IScheduledQuery } from "../../../../models/ScheduledQueries/IScheduledQuery";
import { jobsConstants } from "../../Jobs.constants";

interface ISQLCodeCard {
  queryDetails: IScheduledQuery;
}

export const SQLCodeCard = (props: ISQLCodeCard) => {
  const { queryDetails } = props;
  const navigate = useNavigate();

  function editInDataExplorer() {
    navigate("/data-explorer/", {
      state: {
        clickedFromScheduledQuery: true,
        scheduledQueryToEdit: queryDetails,
      },
    });
  }

  return (
    <Card className="w-100">
      <CardBody>
        <Row className="mb-3">
          <Col>
            <CDataTypography variant="typography-variant-card-title">
              SQL Code
            </CDataTypography>
          </Col>
          <Col className="d-flex justify-content-end">
            <CDataButton
              buttonType={ButtonType.Primary}
              disabled={queryDetails.id === jobsConstants.DEFAULT_JOB_ID}
              onClick={() => editInDataExplorer()}
            >
              <i className="fa fa-solid fa-pen-to-square me-1" />
              Edit in Data Explorer
            </CDataButton>
          </Col>
        </Row>
        <CDataCodeMirror sqlText={queryDetails.query} />
      </CardBody>
    </Card>
  );
};
