import { MutableRefObject, useEffect } from "react";
import {
  FieldArray,
  Form,
  Formik,
  FormikProps,
  useFormikContext,
} from "formik";
import * as Yup from "yup";
import { Card, CardBody } from "reactstrap";
import { produce } from "immer";
import { isEmpty } from "lodash";

import { KeyValueTableRow } from "../../ApiConnector";
import { ITableSettings } from "../SetTable";
import {
  YupOptionalSchema,
  YupRequiredSchema,
} from "../../../../utility/types/yupHelperTypes";
import TextInput from "../../../../components/form/TextInput";
import {
  ButtonType,
  CDataButton,
} from "../../../../components/buttons/CDataButton";
import { IAPIConnectorSettings } from "../types/IAPIConnectorSettings";

interface IParametersCardProps {
  apiSettings: IAPIConnectorSettings;
  formRef: MutableRefObject<FormikProps<ITableSettings>>;
}

const yupSchema = Yup.object<YupOptionalSchema<ITableSettings>>({
  queryParams: Yup.array().of(
    Yup.object<YupRequiredSchema<KeyValueTableRow>>({
      name: Yup.string().nullable().trim().required("Name is required."),
      value: Yup.string().nullable().trim().required("Value is required."),
    }),
  ),
});

export const ParametersCard = (props: IParametersCardProps) => {
  const {
    apiSettings: { tableSettings, setTableSettings },
    formRef,
  } = props;

  function setQueryParameters(queryParams: KeyValueTableRow[]) {
    setTableSettings({
      ...tableSettings,
      queryParams,
    });
  }

  return (
    <Card className="pages-apiConnector-TablesTab-ParametersCard">
      <CardBody>
        <h5 className="card-title">Parameters</h5>
        <div>
          Add URL parameters below that will be added to every request. If the
          API has dynamic parameters that may change per query, consider using
          Filters instead.
        </div>
        <div>
          <Formik
            innerRef={formRef}
            initialValues={tableSettings}
            validationSchema={yupSchema}
            validateOnChange={true}
            onSubmit={() => {
              // no-op
            }}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <ParametersCardFormBody
                  {...props}
                  setQueryParameters={setQueryParameters}
                />
              </Form>
            )}
          </Formik>
        </div>
      </CardBody>
    </Card>
  );
};

interface IParametersCardFormBodyProps {
  apiSettings: IAPIConnectorSettings;
  setQueryParameters: (params: KeyValueTableRow[]) => void;
}

const ParametersCardFormBody = (props: IParametersCardFormBodyProps) => {
  const {
    setQueryParameters,
    apiSettings: { setUnsavedChanges },
  } = props;

  const { values, setValues, validateForm, touched } =
    useFormikContext<ITableSettings>();

  // This isn't a real form, just update the parent whenever the form data changes.
  useEffect(() => {
    setQueryParameters(values.queryParams);
  }, [values.queryParams]); // eslint-disable-line

  useEffect(() => {
    // This is annoying but deleting a row causes the indexes to shuffle around
    // and validation messages to show up on the wrong fields. We have no real key for the rows.
    // We need to trigger a full form validation when the rows are deleted to avoid validation messages
    // being moved to the wrong field.
    validateForm();
  }, [values.queryParams.length]); // eslint-disable-line

  useEffect(() => {
    if (!isEmpty(touched.queryParams)) {
      setUnsavedChanges(true);
    }
  }, [touched.queryParams]); // eslint-disable-line

  function addNewQueryParameterRow() {
    const newTableSettings = produce(values, (draft) => {
      draft.queryParams.push({
        name: "",
        value: "",
      });
    });
    setValues(newTableSettings, true);
    setQueryParameters(newTableSettings.queryParams);

    setUnsavedChanges(true);
  }

  return (
    <div>
      {values.queryParams.length > 0 && (
        <div className="query-param-row query-param-title-row">
          <div className="query-param-column">
            <h5 className="required">Name</h5>
          </div>
          <div className="query-param-column">
            <h5 className="required">Value</h5>
          </div>
          {/* Empty div to take up the same space as the buttons in the parameters rows. */}
          <div className="query-param-button-container" />
        </div>
      )}
      <FieldArray
        name="queryParams"
        render={(arrayHelpers) => (
          <div className="query-param-rows-container">
            {values.queryParams.map((h, index) => {
              return (
                <div key={index} className="query-param-row">
                  <div className="query-param-column">
                    <TextInput
                      label=""
                      name={`queryParams[${index}].name`}
                      aria-label="Parameter Name"
                    />
                  </div>
                  <div className="query-param-column">
                    <TextInput
                      label=""
                      name={`queryParams[${index}].value`}
                      aria-label="Parameter Value"
                    />
                  </div>
                  <div className="query-param-button-container">
                    <CDataButton
                      className="query-params-icon-button"
                      buttonType={ButtonType.Tertiary}
                      aria-label={`Delete Parameter ${values.queryParams[index].name}`}
                      onClick={() => {
                        arrayHelpers.remove(index);
                        setUnsavedChanges(true);
                      }}
                    >
                      <i className="fa fa-trash-alt" />
                    </CDataButton>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      />
      <CDataButton
        buttonType={ButtonType.Tertiary}
        className="query-param-add-button"
        onClick={addNewQueryParameterRow}
      >
        <i className="fa fa-plus icon" />
        Add Param
      </CDataButton>
    </div>
  );
};
