export function compareStrings(
  string1: string | null | undefined,
  string2: string | null | undefined,
) {
  if (!string1 || !string2) {
    return false;
  }

  return string1.toLowerCase() === string2.toLowerCase();
}
