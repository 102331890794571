import React, { useState } from "react";
import { QueryBuilderAddButton } from "../components/QueryBuilderAddButton";
import { QueryBuilderCard } from "../components/QueryBuilderCard";
import { QueryBuilderCardHeader } from "../components/QueryBuilderCardHeader";
import { IQueryBuilderCommonProps } from "../../../models/IQueryBuilderCommonProps";
import { AddFiltersModal } from "./AddFiltersModal";
import { Col, ListGroup, ListGroupItem, Row } from "reactstrap";
import { FilterTile } from "./FilterTile";
import { IQueryBuilderFilter } from "../../../models/IQueryBuilderFilter";
import { QueryBuilderFilterMethod } from "../../../models/QueryBuilderFilterMethod";
import { CDataTypography } from "../../../../../../../components/text/CDataTypography";

interface QueryBuilderFiltersCardProps extends IQueryBuilderCommonProps {}

export function QueryBuilderFiltersCard(props: QueryBuilderFiltersCardProps) {
  const { queryData, setQueryData } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterToEditIndex, setFilterToEditIndex] = useState<number>();
  const isQueryBuilderEmpty = props.queryData.from.connectionId === "";
  function onClickAddFilters() {
    setFilterToEditIndex(undefined);
    setIsModalOpen(true);
  }

  function onClickEditFilters(filter: IQueryBuilderFilter, index: number) {
    setFilterToEditIndex(index);
    setIsModalOpen(true);
  }

  function mapFiltersToTiles() {
    return queryData.filters?.map((filter, index) => (
      <FilterTile
        handleDelete={handleDelete}
        index={index}
        queryFilter={filter}
        key={index}
        onClickEditFilter={onClickEditFilters}
      />
    ));
  }

  function handleDelete(filterIndex: number) {
    const newQueryData = { ...queryData };
    const updatedFilters = queryData.filters.filter(
      (_j, index) => filterIndex !== index,
    );

    newQueryData.filters = updatedFilters;

    setQueryData(newQueryData);
  }

  const addFiltersModal = isModalOpen && (
    <AddFiltersModal
      {...props}
      filterToEditIndex={filterToEditIndex}
      displayed={isModalOpen}
      close={() => setIsModalOpen(false)}
    />
  );

  const filterMethodToggle = queryData.filters.length > 1 && (
    <ListGroup horizontal className="connected-button-group-list mt-3">
      <ListGroupItem
        action
        type="button"
        size="sm"
        onClick={() => {
          setQueryData({
            ...queryData,
            filterMethod: QueryBuilderFilterMethod.AND,
          });
        }}
        active={queryData.filterMethod === QueryBuilderFilterMethod.AND}
        className="connected-button"
      >
        And
      </ListGroupItem>
      <ListGroupItem
        action
        type="button"
        size="sm"
        onClick={() => {
          setQueryData({
            ...queryData,
            filterMethod: QueryBuilderFilterMethod.OR,
          });
        }}
        active={queryData.filterMethod === QueryBuilderFilterMethod.OR}
        className="connected-button"
      >
        Or
      </ListGroupItem>
    </ListGroup>
  );

  const cardBody = (
    <>
      <Row className="gray-helper-text">
        <Col>
          <CDataTypography>
            Refine data retrieval by specifying criteria, ensuring that only
            relevant information is included in the results.
          </CDataTypography>
        </Col>
      </Row>
      {filterMethodToggle}
      {mapFiltersToTiles()}
    </>
  );

  return (
    <QueryBuilderCard className="query-builder-filters-card">
      <QueryBuilderCardHeader title="Filters">
        <QueryBuilderAddButton
          onClick={() => onClickAddFilters()}
          isQueryBuilderEmpty={isQueryBuilderEmpty}
          id="add-filter-button"
        >
          Add Filters
        </QueryBuilderAddButton>
      </QueryBuilderCardHeader>
      {cardBody}
      {addFiltersModal}
    </QueryBuilderCard>
  );
}
