import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { IQueryBuilderJoin } from "../../../models/IQueryBuilderModel";
import { getDriverIcon } from "../../../../../../../components/drivers/DriverIconFactory";
import { IQueryBuilderColumn } from "../../../models/IQueryBuilderColumn";
import { Box } from "@mui/material";

import Grid from "@mui/material/Unstable_Grid2";

interface IDataTile {
  handleDelete: (joinIndex: number) => void;
  index: number;
  join: IQueryBuilderJoin;
  onClickEditData: (joinIndex: number) => void;
}

const DataTile = (props: IDataTile) => {
  const { handleDelete, index, join, onClickEditData } = props;

  const renderQueryBuilderColumn = (column: IQueryBuilderColumn) => {
    return (
      <Row>
        <Col className="driver-icon-col pe-0">
          {getDriverIcon(column.table.driver, "connection-icon m-0 p-0")}
        </Col>
        <Col>
          <Row>
            <Col className={"schema-name"}>{column.table.schema}</Col>
          </Row>
          <Row>
            <Col className={"table-and-column-name"}>
              {column.table.tableAlias}.{column.column}
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  const tileButtons = () => {
    return (
      <div className="buttons-column pe-2">
        <a onClick={() => onClickEditData(index)} data-testid="edit-button">
          <i className="fa fa-md fa-regular fa-pencil" />
        </a>
        <a onClick={() => handleDelete(index)} data-testid="delete-button">
          <i className="fa fa-md fa-solid fa-times" />
        </a>
      </div>
    );
  };

  return (
    <Card className="query-builder-data-tile mt-3 mb-0">
      <Box sx={{ width: "100%" }}>
        <Grid container rowSpacing={1} columnSpacing={1} columns={13}>
          <Grid xs={5}>
            <span className={"column-label"}>New Table</span>
          </Grid>
          <Grid xs={5}>
            <span className={"column-label"}>Join Table</span>
          </Grid>
          <Grid xs={2}>
            <span className={"column-label"}>Join Type</span>
          </Grid>
          <Grid xs={1}></Grid>

          <Grid xs={5}>{renderQueryBuilderColumn(join.right)}</Grid>
          <Grid xs={5}>{renderQueryBuilderColumn(join.left)}</Grid>
          <Grid xs={2}>{join.joinType}</Grid>
          <Grid xs={1}>{tileButtons()}</Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export default DataTile;
