import TextField, { TextFieldProps } from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Box, FilterOptionsState, ThemeProvider } from "@mui/material";
import Loader from "../Loader";
import classnames from "classnames";
import React from "react";
import { createAutocompleteTheme } from "@cdatacloud/component-library";

interface ICDataAutocompleteFreeSolo<T> {
  /** Sets the entire selector and all options to disabled. */
  disableWholeSelector?: boolean;
  /** The appearance of the items when the dropdown menu is open */
  dropdownLabel: (option: T) => JSX.Element | string;
  /** Determines whether a custom input matches any existing options, and provides handling for cases where it does not. */
  filterOptions: (options: T[], state: FilterOptionsState<T>) => T[];
  /** Optional function to determine whether an individual option in the list should be disabled. See ConnectionSelector.tsx for an example. */
  getOptionDisabled?: (option: T) => boolean;
  /** This function determines what is displayed in the select when an option is selected, must be unique. */
  getOptionLabel: (option: T | string) => string;
  /** The handler function for when data is selected or cleared. This needs to handle null just in case the user clears the field. */
  handleChange: (
    event: React.SyntheticEvent,
    option: T | string | null,
  ) => void;
  /** Optional function that returns true when an option is selected. Only required if using an object as T. */
  isOptionEqualToValue?: (option: T, value: T) => boolean;
  /** The id to assign to the autocomplete element. */
  id?: string;
  /** If true, shows the field in an error state. If false or not provided, no error state is shown. */
  isInvalid?: boolean;
  /** If provided, displays a spinner over the input portion of the field when true. */
  isLoading?: boolean;
  /** Optional class for customizing the dropdown menu. */
  menuClass?: string;
  /** The full list of options to display in the selector. They must all be of the same type. */
  options: T[];
  /** Optional parameter for customizing the input field. Can be used, for example, to add extra ending adornments.  */
  renderInput?: (params: Partial<TextFieldProps>) => React.ReactNode;
  /** The controlled state variable for tracking which options have been selected. */
  selectedValue: T | null;
}

/** Generally identical to the normal CDataAutocomplete, except it allows uers to enter custom values in the selector. */
export function CDataAutocompleteFreeSolo<T>(
  props: ICDataAutocompleteFreeSolo<T>,
) {
  const {
    disableWholeSelector,
    dropdownLabel,
    filterOptions,
    getOptionDisabled,
    getOptionLabel,
    handleChange,
    id,
    isInvalid,
    isLoading,
    isOptionEqualToValue,
    options,
    selectedValue,
  } = props;

  return (
    <div className="container-cdata-autocomplete">
      <ThemeProvider theme={createAutocompleteTheme(isInvalid ?? false)}>
        <Autocomplete
          componentsProps={{
            // The paper classname is necessary for styling the dropdown menu when portaling is enabled.
            // Portaling needs to be enabled, or else this component won't work inside non-MUI modals.
            paper: {
              className: classnames(
                "cdata-autocomplete-select-menu",
                props.menuClass,
              ),
            },
            popupIndicator: {
              disableRipple: true,
            },
          }}
          id={id}
          data-testid={id}
          options={options}
          noOptionsText="No matching options"
          clearOnBlur
          disabled={disableWholeSelector}
          freeSolo
          filterOptions={filterOptions}
          getOptionDisabled={(option) =>
            isLoading
              ? isLoading
              : false || getOptionDisabled
                ? getOptionDisabled(option)
                : false
          }
          getOptionLabel={(option) => getOptionLabel(option)}
          isOptionEqualToValue={isOptionEqualToValue}
          loading={isLoading}
          loadingText={
            <Box fontSize={14}>
              <Loader customHeightClass="h-100" />
            </Box>
          }
          renderOption={(props, option) => {
            const { key, ...optionProps } = props;
            return (
              <li key={key} {...optionProps}>
                {dropdownLabel(option)}
              </li>
            );
          }}
          onChange={handleChange}
          popupIcon={
            <i
              className={
                "dropdown-icon fa-regular fa-chevron-down me-1 ms-1 no-pointer-events"
              }
            />
          }
          renderInput={(params) =>
            props.renderInput != null ? (
              props.renderInput({
                ...params,
                placeholder: "Select",
                InputLabelProps: {
                  shrink: false,
                },
                size: "small",
              })
            ) : (
              <TextField
                {...params}
                placeholder="Select"
                InputLabelProps={{
                  shrink: false,
                }}
                size="small"
              />
            )
          }
          selectOnFocus
          value={selectedValue!}
        />
      </ThemeProvider>
      {isLoading && (
        <Box position="absolute" top={0} left={0} right={0} bottom={0}>
          <Loader customHeightClass="h-100" />
        </Box>
      )}
    </div>
  );
}
