import { Suspense } from "react";
import { lazyRetry } from "../../../../../utility/lazyRetry";
import Loader from "../../../../../components/Loader";
import { IAddInitialConnectionProps } from "./AddInitialConnection";

const AddInitialConnection = lazyRetry(() =>
  import("./AddInitialConnection").then((module) => ({
    // Convert the export into the format of a default export, doing this so find all references works.
    default: module.AddInitialConnection,
  })),
);

/** A wrapper component that lazy loads the javascript for this step. */
export function AddInitialConnectionStep(props: IAddInitialConnectionProps) {
  return (
    <Suspense fallback={<Loader />}>
      <AddInitialConnection {...props} />
    </Suspense>
  );
}
