import React from "react";
import { Col, Input } from "reactstrap";
import {
  FilterType,
  IQueryBuilderFilter,
} from "../../../models/IQueryBuilderFilter";
import { DataType } from "../../../../../../../models";

enum FilterTypeAlphabetic {
  EqualTo = "Equal to",
  NotEqualTo = "Not equal to",
}

export enum FilterTypeString {
  EqualTo = "Equal to",
  NotEqualTo = "Not equal to",
  Contains = "Contains",
}

export interface FilterOperatorProps {
  queryFilter: IQueryBuilderFilter;
  setQueryFilter: (filter: IQueryBuilderFilter) => void;
  isDisabled: boolean;
}

export function FilterOperator(props: FilterOperatorProps) {
  const { queryFilter, setQueryFilter, isDisabled } = props;

  let filterTypeOptions = [];
  if (
    queryFilter.column.dataType &&
    queryFilter.column.dataType === DataType.VARCHAR
  ) {
    filterTypeOptions = Object.entries(FilterTypeString).map(([key, value]) => (
      <option key={key} value={value}>
        {value}
      </option>
    ));
  } else if (
    queryFilter.column.dataType &&
    queryFilter.column.dataType === DataType.BOOLEAN
  ) {
    filterTypeOptions = Object.entries(FilterTypeAlphabetic).map(
      ([key, value]) => (
        <option key={key} value={value}>
          {value}
        </option>
      ),
    );
  } else {
    filterTypeOptions = Object.entries(FilterType).map(([key, value]) => (
      <option key={key} value={value}>
        {value}
      </option>
    ));
  }

  return (
    <Col className="filter-operator pe-0">
      <Input
        type="select"
        aria-label="Filter Operator Type"
        className={"filter-operator-input form-control select"}
        value={queryFilter.filterType}
        onChange={(e) => {
          setQueryFilter({
            ...queryFilter,
            filterType: e.target.value as FilterType,
            data: {},
          });
        }}
        disabled={isDisabled}
      >
        {filterTypeOptions}
      </Input>
    </Col>
  );
}
