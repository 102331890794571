import React, { useState } from "react";
import { ColumnDef } from "@tanstack/react-table";
import ListTable from "../../../../components/tables/ListTable";
import { Card, CardBody, CardTitle } from "reactstrap";
import { IOEMSubAccount } from "../../../../models/OEM/IOEMSubAccount";
import { parseUTCDateTime } from "../../../../utility/ParseUTCDateTime";
import {
  CDataTooltip,
  CDataTooltipType,
} from "../../../../components/CDataTooltip";

interface IOEMAccountCard {
  oemAccounts: IOEMSubAccount[] | undefined;
  openSubAccount: (row: IOEMSubAccount) => void;
}

const OEMAccountCard = (props: IOEMAccountCard) => {
  const [lastCopiedAccountId, setLastCopiedAccountId] = useState<string>("");

  const columns: ColumnDef<IOEMSubAccount>[] = [
    {
      accessorKey: "accountName",
      id: "accountName",
      enableSorting: true,
      header: () => <span>Accounts</span>,
      meta: {
        className: "name-column",
      },
      cell: ({ row }) => {
        return <>{row.original.accountName}</>;
      },
    },
    {
      accessorKey: "id",
      id: "id",
      enableSorting: true,
      header: () => <span>Account Id</span>,
      meta: {
        className: "id-column",
      },
      cell: ({ row }) => {
        let tooltipMessage, tooltipType, leaveDelay;
        if (lastCopiedAccountId !== row.original.id) {
          tooltipMessage = "Copy";
          tooltipType = CDataTooltipType.Dark;
          leaveDelay = undefined;
        } else {
          tooltipMessage = "Copied Successfully!";
          tooltipType = CDataTooltipType.Light;
          leaveDelay = 1250;
        }

        return (
          <>
            {row.original.id}
            <CDataTooltip
              title={tooltipMessage}
              arrow
              placement="top"
              type={tooltipType}
              leaveDelay={leaveDelay}
            >
              <span
                className="ms-2"
                onClick={(event: React.MouseEvent) => {
                  setLastCopiedAccountId(row.original.id!);
                  navigator.clipboard.writeText(row.original.id!);
                  event.stopPropagation();
                }}
              >
                <i className="fa fa-solid fa-copy" />
              </span>
            </CDataTooltip>
          </>
        );
      },
    },
    {
      accessorKey: "connectionCount",
      id: "connectionCount",
      enableSorting: true,
      header: () => <span># of Connections</span>,
      meta: {
        className: "connections-column",
      },
      cell: ({ row }) => {
        return <>{row.original.connectionCount}</>;
      },
    },
    {
      accessorKey: "lastQueryTime",
      id: "lastQueryTime",
      enableSorting: true,
      header: () => <span>Last Query</span>,
      meta: {
        className: "last-query-column",
      },
      cell: ({ row }) => {
        const parsedLastDate = row.original.lastQueryTime
          ? parseUTCDateTime(row.original.lastQueryTime)
          : null;
        return parsedLastDate ? (
          <>
            {parsedLastDate.hyphenatedDate} {parsedLastDate.compiledTime} UTC
          </>
        ) : (
          <></>
        );
      },
    },
    {
      accessorKey: "buttons",
      id: "buttons",
      enableSorting: false,
      header: () => <></>,
      meta: {
        className: "buttons-column",
      },
      cell: () => {
        return (
          <span className="d-flex justify-content-end">
            <i className="fa fa-regular fa-arrow-right" />
          </span>
        );
      },
    },
  ];

  return props.oemAccounts ? (
    <Card className="oem-account-card" data-testid="oem-accounts-card">
      <CardBody>
        <CardTitle className="d-flex align-items-center">Accounts</CardTitle>
        <ListTable
          columns={columns}
          data={props.oemAccounts}
          emptyTableMessage="No accounts have been found."
          enableFiltering={true}
          enablePagination={true}
          enableCheckboxes={false}
          onRowClick={props.openSubAccount}
          searchPlaceholder="Search accounts..."
          defaultSort={[
            {
              id: "accountName",
              desc: false,
            },
          ]}
        />
      </CardBody>
    </Card>
  ) : null;
};

export default OEMAccountCard;
