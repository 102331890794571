import React from "react";
import { Route, Routes } from "react-router-dom";
import Page404 from "../auth/Page404";
import { OEMUserGuard } from "./OEMUserGuard";
import { EditOEMConnection } from "./oemconnections/EditOEMConnection";
import OEMEditConnectionLayout from "../../layouts/OEMEditConnectionLayout";
import AddReport from "../connections/AddReports";
/**
 * These routes are what the actual users of an OEM see.
 * They have special JWT that only lets them access the specific page they are on.
 * E.x. they can only create a Snowflake connection, or edit a single connection by ID.
 */
const OEMUserRoutes = () => {
  return (
    <Routes>
      {/* /oem/users route - Does all the auth */}
      <Route
        path="/"
        element={
          <OEMUserGuard>
            <OEMEditConnectionLayout />
          </OEMUserGuard>
        }
      >
        {/* The connections/edit route is used to both create and edit connections. */}
        <Route path="connections/edit" element={<EditOEMConnection />} />
        <Route
          path="connections/edit/:connectionId"
          element={<EditOEMConnection />}
        />
        <Route path="connections/reports/edit" element={<AddReport />} />

        {/* The /oem/users route is not valid, give them a 404. */}
        <Route path="" element={<Page404 showBranding={false} />} />
      </Route>

      <Route path="*" element={<Page404 showBranding={false} />} />
    </Routes>
  );
};

export default OEMUserRoutes;
