import React, { useState } from "react";
import { Button, Col, Row } from "reactstrap";
import {
  getApiUrlEndpoint,
  getTdsHostName,
  getTdsPortNumber,
} from "../../services/endpointService";
import TabWrapper, { ITabComponent } from "../../components/TabWrapper";
import { CDataModalV2 } from "../../components/modal/CDataModalV2";
import { IWorkspaceListItem } from "../../models";
import { DisabledInputWithCopyButton } from "../../components/form/DisabledInputWithCopyButton";
import WorkspaceField from "./ConnectionTiles/components/WorkspaceField";
import { ResourceType } from "./ConnectionTiles/ModalPromptBody";
import SelectResourceTypeButtons from "./ConnectionTiles/components/SelectResourceTypeButtons";
import DatabaseField from "./ConnectionTiles/components/DatabaseField";
import { EndpointModalOptions } from "../datasets/EditWorkspace/WorkspaceEditEndpointsModal";
import { EndpointsModalUsernameRow } from "../datasets/EditWorkspace/components/EndpointsModalUsernameRow";
import { OpenAPIEndpointModal } from "./ConnectionTiles/OpenAPIEndpointModal";
import UrlField from "./ConnectionTiles/components/UrlField";

export interface IEndpointsModalProps {
  isDisplayed: boolean;
  setIsDisplayed: (isDisplayed: boolean) => void;
  workspaces: IWorkspaceListItem[];
}

export const EndpointsModal = (props: IEndpointsModalProps) => {
  const apiUrlEndpoint = getApiUrlEndpoint();
  const tdsHostName = getTdsHostName();
  const tdsPortNumber = getTdsPortNumber();

  const [selectedWorkspace, setSelectedWorkspace] = useState<
    IWorkspaceListItem | undefined
  >();
  const [selectedResourceType, setSelectedResourceType] =
    useState<ResourceType>(ResourceType.Connection);

  const handleModalClose = () => {
    setSelectedWorkspace(undefined);
    setSelectedResourceType(ResourceType.Connection);
    props.setIsDisplayed(false);
  };

  const sqlServerContent = () => {
    return (
      <>
        <Row className="my-2">
          <Col className="sql-server-host-name">
            <div className="form-field-title">SQL Server Host Name:</div>
          </Col>
          <Col className="client-endpoint">
            <DisabledInputWithCopyButton text={tdsHostName} />
          </Col>
          <Col className="client-endpoint-adjusted-port">
            <div className="form-field-title">Port:</div>
          </Col>
          <Col className="client-endpoint">
            <DisabledInputWithCopyButton text={tdsPortNumber} />
          </Col>
        </Row>
      </>
    );
  };

  const workspaceField = () => {
    if (selectedResourceType === ResourceType.Workspace) {
      return (
        <WorkspaceField
          selectedWorkspace={selectedWorkspace ?? undefined}
          setSelectedWorkspace={setSelectedWorkspace}
          workspaces={props.workspaces}
          containerClassName="my-2"
        />
      );
    }
  };

  const databaseField = () => {
    return (
      <DatabaseField
        idName={""}
        isWorkspaceTabSelected={selectedResourceType === ResourceType.Workspace}
        selectedResourceType={selectedResourceType}
        selectedWorkspaceName={selectedWorkspace?.name}
        userEmail={""}
        containerClassName="my-2"
      />
    );
  };

  const odataURLField = () => {
    if (selectedWorkspace && selectedWorkspace.name) {
      return (
        <UrlField
          idName={""}
          isOpenApi={false}
          selectedResourceType={selectedResourceType}
          selectedWorkspaceName={selectedWorkspace?.name}
          selectedWorkspaceId={selectedWorkspace?.id || ""}
          containerClassName="my-2"
        />
      );
    }
  };

  const openAPIContent = () => {
    if (selectedWorkspace && selectedWorkspace.name) {
      return (
        <Row>
          <OpenAPIEndpointModal
            workspaceName={selectedWorkspace?.name || ""}
            workspaceId={selectedWorkspace?.id || ""}
          />
        </Row>
      );
    }
  };

  const usernameRow = () => {
    return (
      <EndpointsModalUsernameRow
        tab={
          selectedResourceType === ResourceType.Workspace
            ? EndpointModalOptions.VirtualSQLServer
            : EndpointModalOptions.OData
        }
        workspaceName={selectedWorkspace?.name || ""}
      />
    );
  };

  const tabContent = () => {
    const tabs: ITabComponent[] = [];
    tabs.push({
      tabName: "API",
      tabEnum: "1",
      tabComponent: (
        <Row className="my-2">
          <Col className="client-endpoint-adjusted-sap-data">
            <div className="client-endpoint-label">API URL:</div>
          </Col>
          <Col className="client-endpoint-sap-data">
            <DisabledInputWithCopyButton text={apiUrlEndpoint} />
          </Col>
        </Row>
      ),
    });
    tabs.push({
      tabName: "OData",
      tabEnum: "2",
      tabComponent: (
        <>
          <WorkspaceField
            selectedWorkspace={selectedWorkspace}
            setSelectedWorkspace={setSelectedWorkspace}
            workspaces={props.workspaces}
            containerClassName="my-2"
          />
          {odataURLField()}
        </>
      ),
    });
    tabs.push({
      tabName: "OpenAPI",
      tabEnum: "3",
      tabComponent: (
        <>
          <WorkspaceField
            selectedWorkspace={selectedWorkspace}
            setSelectedWorkspace={setSelectedWorkspace}
            workspaces={props.workspaces}
            containerClassName="my-2"
          />
          {openAPIContent()}
        </>
      ),
    });
    tabs.push({
      tabName: "Virtual SQL Server",
      tabEnum: "4",
      tabComponent: (
        <>
          <SelectResourceTypeButtons
            selectedResourceType={selectedResourceType}
            setSelectedResourceType={setSelectedResourceType}
          />
          {sqlServerContent()}
          {workspaceField()}
          {(selectedResourceType === ResourceType.Connection ||
            (selectedResourceType === ResourceType.Workspace &&
              selectedWorkspace)) &&
            usernameRow()}
          {databaseField()}
        </>
      ),
    });

    return (
      <TabWrapper
        tabs={tabs}
        smallerBottomPadding={true}
        activeTabWhiteBackground={true}
        tabContentClassName="modal-prompt-body"
      />
    );
  };

  return (
    <CDataModalV2
      fade={false}
      displayed={props.isDisplayed}
      close={() => handleModalClose()}
      title="Endpoints"
      spacedFooter={false}
      primaryButton={
        <Button color="primary" onClick={() => handleModalClose()}>
          Close
        </Button>
      }
      className="endpoints-modal"
    >
      Below is a list of available endpoints that you may need if attempting to
      connect a client tool that is not listed on this page.
      {tabContent()}
    </CDataModalV2>
  );
};
