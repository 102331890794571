import { useContext, useEffect } from "react";
import classNames from "classnames";
import { ITableSettings, QueryInput } from "../SetTable";
import { getPerRowRequestValue } from "./PerRowRequestHelper";
import { StepWizardStepFooter } from "../../../../components/wizard/components/StepWizardStepFooter";
import {
  ButtonType,
  CDataButton,
} from "../../../../components/buttons/CDataButton";
import { CDataTextField } from "../../../../components/form/CDataTextField";
import { IStepBodyProps } from "../../../../components/wizard/components/StepWizardStep";
import { StepWizardContext } from "../../../../components/wizard/components/StepWizardContext";

type IQueryInputIdModalProps = IStepBodyProps & {
  queryInputs: QueryInput[];
  setQueryInputs: React.Dispatch<React.SetStateAction<QueryInput[]>>;
  isResponseValid: boolean;
  tableSettings: ITableSettings;
};

export function QueryInputIdModal(props: IQueryInputIdModalProps) {
  const {
    nextStep,
    currentStep,
    queryInputs,
    setQueryInputs,
    isResponseValid,
  } = props;

  const stepperContext = useContext(StepWizardContext);

  const handleInputChange = (index: number, value: string) => {
    stepperContext.setPendingCompletedStepsToCurrentStep(currentStep!);
    props.setQueryInputs((prev: QueryInput[]) => {
      const updatedInputs = [...prev];
      updatedInputs[index] = { ...updatedInputs[index], value: value };
      return updatedInputs;
    });
  };

  // Detect if the request URL has changed.
  // If it has, we need to clear out the text the user entered.
  useEffect(() => {
    if (props.queryInputs.length === 0) {
      const initialValues = getPerRowRequestValue(
        props.tableSettings.requestUrl,
      ).map((key) => ({
        key,
        value: "",
      }));

      setQueryInputs(initialValues);
    }
  }, []); // eslint-disable-line

  const isNextButtonDisabled =
    queryInputs.length === 0 || queryInputs.some((input) => input.value === "");

  return (
    <>
      <div>
        Set a value for the unique record identifier that was provided in your
        Request URL. The value is used to retrieve the initial response data.
      </div>
      {queryInputs.map((input, index) => (
        <div key={index}>
          <div className="mt-3 mb-2 required">
            <b>{input.key}</b>
          </div>
          <div>
            <CDataTextField
              name={input.key}
              className={classNames("mb-0", {
                "input-helper-text": !isResponseValid,
              })}
              onChange={(e) => handleInputChange(index, e.target.value)}
              onKeyUp={(event) => {
                if (!isNextButtonDisabled && event.key === "Enter") {
                  nextStep!();
                }
              }}
              value={input.value}
            />
          </div>
        </div>
      ))}
      {!isResponseValid && (
        <div className="mt-1 query-input-helper-text">
          Unable to obtain data for this Id. Please try a different value to
          preview your request.
        </div>
      )}
      <StepWizardStepFooter>
        <span />
        <CDataButton
          buttonType={ButtonType.Primary}
          disabled={isNextButtonDisabled}
          onClick={() => nextStep!()}
        >
          Next
        </CDataButton>
      </StepWizardStepFooter>
    </>
  );
}
