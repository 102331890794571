import { Route, Routes } from "react-router-dom";
import Page404 from "./Page404";
import Auth from "../../layouts/Auth";
import Page500 from "./Page500";
import NotAnAdmin from "./NotAnAdmin";
import AccountInactive from "./AccountInactive";
import MaxConnections from "./MaxConnections";
import LoggedInGuard from "../../components/guards/LoggedInGuard";
import PageExpired from "./PageExpired";
import ValidateEmailChange from "./ValidateEmailChange";
import UserOverageError from "./UserOverageError";
import PartnerUserSetPassword from "./PartnerUserSetPassword";
import UserSignUp from "./UserSignUp";
import VerifyEmail from "./VerifyEmail";
import ImpersonationNotAllowed from "./ImpersonationNotAllowed";

const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Auth />}>
        <Route path="404" element={<Page404 showBranding={false} />} />

        <Route path="500" element={<Page500 />} />

        <Route path="not-an-admin" element={<NotAnAdmin />} />

        <Route
          path="impersonation-not-allowed"
          element={<ImpersonationNotAllowed />}
        />

        <Route path="not-an-active-account" element={<AccountInactive />} />

        <Route path="max-connections" element={<MaxConnections />} />

        <Route
          path="user-overage"
          element={<LoggedInGuard component={UserOverageError} />}
        />

        <Route path="page-expired" element={<PageExpired />} />

        <Route path="validate-email" element={<ValidateEmailChange />} />

        <Route
          path="partner-user-set-password"
          element={<PartnerUserSetPassword />}
        />

        <Route path="user-sign-up" element={<UserSignUp />} />

        <Route path="verify-email" element={<VerifyEmail />} />

        {/* This is just the /auth route which isn't valid, the wildcard below this handles routes like `/auth/fake-url */}
        <Route path="" element={<Page404 showBranding={false} />} />
      </Route>

      <Route path="*" element={<Page404 showBranding />} />
    </Routes>
  );
};

export default AuthRoutes;
