import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { getDriverIcon } from "../../../../../../../components/drivers/DriverIconFactory";
import { IQueryBuilderColumn } from "../../../models/IQueryBuilderColumn";
import { Box } from "@mui/material";

import Grid from "@mui/material/Unstable_Grid2";
import {
  FilterType,
  IQueryBuilderFilter,
} from "../../../models/IQueryBuilderFilter";
import { DataType } from "../../../../../../../models";
import { format, parseISO } from "date-fns";

interface IFilterTile {
  index: number;
  queryFilter: IQueryBuilderFilter;
  onClickEditFilter: (filter: IQueryBuilderFilter, index: number) => void;
  handleDelete: (joinIndex: number) => void;
}

export const FilterTile = (props: IFilterTile) => {
  const { handleDelete, index, queryFilter, onClickEditFilter } = props;

  const renderQueryBuilderColumn = (column: IQueryBuilderColumn) => {
    return (
      <Row>
        <Col className="driver-icon-col pe-0">
          {getDriverIcon(column.table.driver, "connection-icon m-0 p-0")}
        </Col>
        <Col>
          <Row>
            <Col className={"schema-name"}>{column.table.schema}</Col>
          </Row>
          <Row>
            <Col className={"table-and-column-name"}>
              {column.table.tableAlias}.{column.column}
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  const tileButtons = (
    <div className="buttons-column pe-2">
      <a
        onClick={() => onClickEditFilter(queryFilter, index)}
        data-testid="edit-button"
      >
        <i className="fa fa-md fa-regular fa-pencil" />
      </a>
      <a onClick={() => handleDelete(index)} data-testid="delete-button">
        <i className="fa fa-md fa-solid fa-times" />
      </a>
    </div>
  );

  const displayValue = getFilterDisplayValue(queryFilter);

  return (
    <Card className="query-builder-filter-tile mt-2 mb-0">
      <Box sx={{ width: "100%" }}>
        <Grid container rowSpacing={1} columnSpacing={1} columns={13}>
          <Grid xs={5}>
            <span className={"column-label"}>Filter Column</span>
          </Grid>
          <Grid xs={5}>
            <span className={"column-label"}>Operator Type</span>
          </Grid>
          <Grid xs={2}>
            <span className={"column-label"}>Value</span>
          </Grid>
          <Grid xs={1}></Grid>

          <Grid xs={5}>{renderQueryBuilderColumn(queryFilter.column)}</Grid>
          <Grid xs={5}>{queryFilter.filterType}</Grid>
          <Grid xs={2}>{displayValue}</Grid>
          <Grid xs={1}>{tileButtons}</Grid>
        </Grid>
      </Box>
    </Card>
  );
};

function getFilterDisplayValue(filter: IQueryBuilderFilter) {
  if (
    filter.column.dataType === DataType.DATE ||
    filter.column.dataType === DataType.TIMESTAMP
  ) {
    if (filter.filterType === FilterType.Between) {
      return (
        formatDateString(filter.data.start as string, filter.column.dataType) +
        " - " +
        formatDateString(filter.data.end as string, filter.column.dataType)
      );
    } else {
      return formatDateString(
        filter.data.value as string,
        filter.column.dataType,
      );
    }
  } else if (filter.filterType === FilterType.Between) {
    return `${filter.data.start} - ${filter.data.end}`;
  } else {
    return filter.data.value as string;
  }
}

function formatDateString(dateString: string, dataType: DataType): string {
  const parsedDate = parseISO(dateString);
  if (dataType === DataType.TIMESTAMP) {
    return (
      format(parsedDate, "yyyy-MM-dd") + " " + format(parsedDate, "HH:mm:ss")
    );
  } else {
    return format(parsedDate, "yyyy-MM-dd");
  }
}
