import React, { useState } from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { DisabledInputWithCopyButton } from "../../../components/form/DisabledInputWithCopyButton";
import { OpenAPIVersion } from "../../../models/OpenAPI/OpenAPIVersion";
import { getOpenAPIURL } from "../../../services/endpointService";

export const OpenAPIEndpointModal = (props: {
  workspaceName: string;
  workspaceId: string;
}) => {
  const [version, setVersion] = useState<OpenAPIVersion>(OpenAPIVersion.Three);

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    const selectedVersion = parseInt(e.target.value) as OpenAPIVersion;
    setVersion(selectedVersion);
  }

  const workspaceName = props.workspaceName;
  const textVersion = version === OpenAPIVersion.Two ? "v2" : "v3";
  const displayURL = getOpenAPIURL(workspaceName, textVersion);

  return (
    <FormGroup className="open-api-endpoint-modal">
      <div>Version</div>
      <Row className="version-row">
        <Col className="version-selector">
          <FormGroup check>
            <Input
              checked={version === OpenAPIVersion.Three}
              name="openAPIVersion"
              onChange={onChange}
              type="radio"
              value="3.0"
            />
            <Label check className="ms-1">
              3.0
            </Label>
          </FormGroup>
        </Col>
        <Col className="version-selector ps-0">
          <FormGroup check>
            <Input
              checked={version === OpenAPIVersion.Two}
              name="openAPIVersion"
              onChange={onChange}
              type="radio"
              value={OpenAPIVersion.Two}
            />
            <Label check className="ms-1">
              2.0
            </Label>
          </FormGroup>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col className="label-col url-label">
          <div className="form-field-title text-nowrap">URL:</div>
        </Col>
        <Col className="ps-0">
          <DisabledInputWithCopyButton text={displayURL} />
        </Col>
      </Row>
    </FormGroup>
  );
};
