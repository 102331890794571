import { useEffect, useState } from "react";

export function useCheckboxManager<T>(allCheckboxOptions: T[]) {
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<T[]>([]);

  // If the checkbox options change, reset and update the checkboxes
  useEffect(() => {
    unselectAll();

    allCheckboxOptions.forEach((item: T) => {
      if (selectedCheckboxes.includes(item)) {
        check(item);
      }
    });
  }, [allCheckboxOptions]); // eslint-disable-line

  function toggleCheckbox(checkboxToToggle: T) {
    if (isAlreadyChecked(checkboxToToggle)) {
      uncheck(checkboxToToggle);
    } else {
      check(checkboxToToggle);
    }
  }

  function isAlreadyChecked(checkbox: T) {
    return selectedCheckboxes.some(
      (selectedCheckbox: T) => checkbox === selectedCheckbox,
    );
  }

  function check(checkbox: T) {
    setSelectedCheckboxes((prevCheckboxes: T[]) => {
      const newCheckboxes = [...prevCheckboxes, checkbox];
      return newCheckboxes;
    });
  }

  function uncheck(checkbox: T) {
    setSelectedCheckboxes((prevCheckboxes: T[]) => {
      const newCheckboxes = prevCheckboxes.filter((x: T) => x !== checkbox);
      return newCheckboxes;
    });
  }

  function toggleSelectAll() {
    if (selectedCheckboxes.length < allCheckboxOptions.length) {
      selectAll();
    } else {
      unselectAll();
    }
  }

  function selectAll() {
    setSelectedCheckboxes(allCheckboxOptions);
  }

  function unselectAll() {
    setSelectedCheckboxes([]);
  }

  const checkboxReturn = {
    toggleCheckbox,
    toggleSelectAll,
    selectedCheckboxes,
  };

  return checkboxReturn;
}
