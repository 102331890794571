import React from "react";
import { IQueryBuilderCommonProps } from "../../models/IQueryBuilderCommonProps";
import { QueryBuilderDataCard } from "./Data/QueryBuilderDataCard";
import { QueryBuilderDimensionsCard } from "./Dimensions/QueryBuilderDimensionsCard";
import { QueryBuilderFiltersCard } from "./Filters/QueryBuilderFiltersCard";
import { QueryBuilderMetricsCard } from "./Metrics/QueryBuilderMetricsCard";
import { QueryBuilderSortCard } from "./Sort/QueryBuilderSortCard";

export function BuilderTab(props: IQueryBuilderCommonProps) {
  return (
    <div className="queryBuilderBuilderTab pb-0">
      <QueryBuilderDataCard {...props} />
      <QueryBuilderDimensionsCard {...props} />
      <QueryBuilderMetricsCard {...props} />
      <QueryBuilderFiltersCard {...props} />
      <QueryBuilderSortCard {...props} />
    </div>
  );
}
