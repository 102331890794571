import {
  BillingItemType,
  BillingStatus,
  IAccount,
  IBillingUsage,
  IDriverList,
  ISubscriptionInfo,
  IUser,
  UserRole,
} from "../../../models";
import { BillingProvider } from "../../../models/Billing/BillingProvider";
import { IAzureOpenAIQueryResult } from "../../../models/OpenAI/IAzureOpenAIQueryResult";
import { RootState } from "../../../redux/store";

export const mockAIAccountInfo: IAccount = {
  accountName: "",
  contactEmail: "",
  contactFirstName: "",
  contactLastName: "",
  country: "",
  driverChannel: "",
  enabled: false,
  isOEM: false,
  isSpreadsheets: false,
  organization: "",
  state: "",
  subscriptionLimits: {
    aiCreditLimit: 0,
    availableFeatureIds: [],
    connectionLimit: 0,
    dataSourceLimit: 0,
    monthlyQueryLimit: -1,
    rowLimit: 0,
    userLimit: 0,
  },
  pendingEmail: "",
  isSetupFinished: false,
  phoneNumber: "",
  subscriptionCustomerId: "",
};

export const mockAIDriverList: IDriverList = {
  categories: ["erpcrm", "marketing", "collab"],
  drivers: [
    {
      beta: false,
      category: "erpcrm",
      driver: "AAS",
      hasCustomReports: false,
      niceName: "Azure Analysis Services",
      premium: true,
      isCachingDisabled: false,
      hasMetadataCache: true,
      version: "Unknown",
      scheduledQueryDestination: false,
      hidden: false,
    },
    {
      beta: false,
      category: "collab",
      driver: "ExcelOnline",
      hasCustomReports: false,
      niceName: "Excel Online",
      premium: false,
      isCachingDisabled: false,
      hasMetadataCache: true,
      version: "Unknown",
      scheduledQueryDestination: false,
      hidden: false,
    },
  ],
};

export const mockAISubscription: ISubscriptionInfo = {
  limits: {
    availableFeatureIds: [2, 4],
    connectionLimit: 5,
    dataSourceLimit: -1,
    rowLimit: 2000000,
    monthlyQueryLimit: -1,
    userLimit: 10,
    aiCreditLimit: 0,
  },
  additionalAICredits: 0,
  additionalTrialRows: 0,
  billingStatus: BillingStatus.NewAccount,
  billingProvider: BillingProvider.Stripe,
  includedAddons: [],
  plan: {
    availableAddonIds: [],
    description: "",
    id: 0,
    includedAddonIds: [],
    internalName: "",
    isCustom: false,
    name: "",
    ordinal: 0,
    subscriptionMonthlyPriceId: "",
    subscriptionYearlyPriceId: "",
    type: BillingItemType.BillingPlan,
  },
  purchasedAddons: [],
  subscriptionCustomerId: "",
  subscriptionId: "",
};

export const mockAIUsage: IBillingUsage = {
  billingStatus: 3,
  connectionsOverLimit: false,
  dataSourcesOverLimit: false,
  rowsOverLimit: true,
  usersOverLimit: false,
  connectionsCount: 5,
  dataSourcesCount: 5,
  rowsCount: 1000,
  usersCount: 50,
  queriesIssued: 0,
  nextBillTime: "2023-03-28T06:47:12Z",
  aiCreditsCount: 0,
};

export const mockAIUser: IUser = {
  accountName: "",
  email: "",
  emailVerifyCode: "",
  enabled: true,
  canBeImpersonated: false,
  userImpersonationId: "",
  firstName: "",
  id: "",
  inviteCode: "",
  isInvite: false,
  lastName: "",
  pendingEmail: "",
  permissions: [],
  role: UserRole.Admin,
  workspacePermissions: [],
  canImpersonateAsSupport: false,
};

export const mockState: Partial<RootState> = {
  accountInfo: {
    ...mockAIAccountInfo,
  },
  driversList: {
    ...mockAIDriverList,
  },
  subscription: {
    ...mockAISubscription,
  },
  usage: {
    ...mockAIUsage,
  },
  user: {
    ...mockAIUser,
  },
};

export const mockEmptyAIResponse: IAzureOpenAIQueryResult = {
  result: "",
  totalTokens: 0,
};

export const mockValidAIResponse: IAzureOpenAIQueryResult = {
  result: "SELECT * FROM Googlesheets1.GoogleSheets.Table",
  totalTokens: 100,
};

export const mockAIErrorText = "Mock AI Error text";

export const mockErrorAIResponse: IAzureOpenAIQueryResult = {
  result: "",
  totalTokens: 0,
  error: mockAIErrorText,
};
