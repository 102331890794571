import { cdataFetch } from "../../../../../api/cdataFetch";
import { IUserSignupReason } from "../../../../../models/Accounts/IUserSignupReason";

export async function setSignupReasons(reasons: IUserSignupReason[]) {
  return cdataFetch({
    method: "PUT",
    url: "/signupreasons",
    body: reasons,
  });
}
