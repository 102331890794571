import { ChangeEventHandler } from "react";
import { FormikValues } from "formik";
import { Button, ButtonProps, Input } from "reactstrap";
import { IModalProps } from "../../../components/CDataModal";

export enum VerifyEmailType {
  Login,
  Contact,
}

function generateDeleteAccModal(
  onAccountDelete: ButtonProps["onClick"],
  toggleModal: ButtonProps["onClick"],
  onChangeValidation: ChangeEventHandler<HTMLInputElement>,
  setModal: Function,
  validationMatches: boolean,
) {
  const builder = {
    displayed: true, // update the value of specific key
    title: "Delete Account",
    body: (
      <div>
        <p>
          Enter your email address below to delete this account. This action
          cannot be undone.
        </p>
        <input
          type="email"
          name="emailValidationBox"
          onChange={onChangeValidation}
          className="form-control"
        />
      </div>
    ),
    primaryButton: (
      <Button color="danger" onClick={onAccountDelete} disabled>
        Confirm
      </Button>
    ),
    secondaryButton: (
      <Button color="secondary" onClick={toggleModal}>
        Close
      </Button>
    ),
    displayToggleCloseButton: false,
    modalSize: "lg",
  } as IModalProps;

  if (validationMatches) {
    builder.primaryButton = (
      <Button
        color="danger"
        className="btn btn-danger"
        onClick={onAccountDelete}
      >
        Confirm
      </Button>
    );
  }

  setModal(builder);
}

function generateVerifyEmailModal(
  values: FormikValues,
  setFieldValue: Function,
  resetForm: Function,
  pendingEmail: string,
  updateContactName: Function,
  sendVerificationEmail: Function,
  toggleModal: Function,
  setModal: Function,
  emailType?: VerifyEmailType,
) {
  const isLoginChange = emailType === VerifyEmailType.Login;
  const emailValue = isLoginChange ? values.LoginEmail : values.ContactEmail;
  const fieldValue = isLoginChange
    ? VerifyEmailType.Login
    : VerifyEmailType.Contact;
  const warningText =
    emailType === VerifyEmailType.Login
      ? "You are attempting to change the email address associated with your login. In order to save this change, we need to verify that you have access to the following email address:"
      : `You are attempting to change the primary contact's email. In order to save this change, we need to verify that you have access to the following email address:`;
  const builder = {
    displayed: true,
    title: "Verify Email",
    body: (
      <div>
        <p>{warningText}</p>
        <h5>Email</h5>
        <Input
          disabled
          type="email"
          name={fieldValue.toString()}
          value={emailValue}
          className="form-control"
        />
      </div>
    ),
    primaryButton: (
      <Button
        color="primary"
        onClick={async () => {
          if (updateContactName) {
            await updateContactName(values);
          }
          await sendVerificationEmail(emailValue);
        }}
        data-testid="button-send-verification"
      >
        Send Verification
      </Button>
    ),
    secondaryButton: (
      <Button
        color="secondary"
        onClick={() => {
          if (pendingEmail) {
            setFieldValue(fieldValue, pendingEmail, true);
          } else {
            resetForm();
          }
          toggleModal();
        }}
      >
        Cancel
      </Button>
    ),
    displayToggleCloseButton: true,
    modalSize: "lg",
  } as IModalProps;

  setModal(builder);
}

export { generateDeleteAccModal, generateVerifyEmailModal };
