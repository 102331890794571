import { useEffect, useState } from "react";
import { langs } from "@uiw/codemirror-extensions-langs";
import { tags as t } from "@lezer/highlight";
import { createTheme } from "@uiw/codemirror-themes";
import { getReasonPhrase } from "http-status-codes";
import TertiaryButton from "../../../../../../components/buttons/TertiaryButton";
import { useAPI } from "../../../../../../components/useAPI";
import { IAPIConnectorProps } from "../../../../../../models";
import { mapAPIConnectorProps } from "../../../../ApiConnectorFactory";
import { mapTable } from "../../../SetTableFactory";
import {
  IAPIResponse,
  RequestType,
} from "../../../../../../components/withAPI";
import { ToastrError } from "../../../../../../services/toastrService";
import Loader from "../../../../../../components/Loader";
import { replaceAPITableSettingsURL } from "../../../replaceAPITableSettingsURL";
import { isEmpty } from "lodash";
import { IProxyClientAPIRequest } from "../../../../../../models/Drivers/IProxyClientAPIRequest";
import { IAPIConnectorSettings } from "../../../types/IAPIConnectorSettings";
import { QueryInput } from "../../../SetTable";
import { StepWizardStepFooter } from "../../../../../../components/wizard/components/StepWizardStepFooter";
import {
  ButtonType,
  CDataButton,
} from "../../../../../../components/buttons/CDataButton";
import { IStepBodyProps } from "../../../../../../components/wizard/components/StepWizardStep";
import { CDataCodeMirror } from "../../../../../../components/codemirror/CDataCodeMirror";

interface IPreviewRequestModal extends IStepBodyProps {
  apiSettings: IAPIConnectorSettings;
  axiosResult: IAPIResponse;
  setAxiosResult: (result: IAPIResponse) => void;
  queryInputs: QueryInput[];
}

export const PreviewRequestStep = (props: IPreviewRequestModal) => {
  const {
    axiosResult,
    setAxiosResult,
    queryInputs,
    apiSettings: { globalSettings, tableSettings },
    nextStep,
    previousStep,
  } = props;

  const previewResponse = axiosResult.payload;

  const [isLoading, setIsLoading] = useState(true);

  const previewTheme = createTheme({
    theme: "light",
    settings: {
      background: "#ffffff",
      foreground: "#5c6166",
      caret: "#5d00ff",
      selection: "#036dd626",
      selectionMatch: "#036dd626",
      lineHighlight: "#8a91991a",
      gutterBackground: "#F5F5F5",
      gutterForeground: "#5c6166",
    },
    styles: [
      { tag: t.variableName, color: "#5c6166" },
      { tag: [t.string, t.special(t.brace)], color: "#5c6166" },
      { tag: t.number, color: "#5c6166" },
      { tag: t.bool, color: "#5c6166" },
      { tag: t.null, color: "#5c6166" },
      { tag: t.keyword, color: "#5c6166" },
    ],
  });

  const api = useAPI();

  function onNext() {
    nextStep!();
  }

  async function loadAPIPreview() {
    const tableSettingsWithQueryInput = mapTable(
      replaceAPITableSettingsURL(tableSettings, queryInputs),
    );
    const apiConnectorProps: IAPIConnectorProps = mapAPIConnectorProps(
      globalSettings,
      [tableSettingsWithQueryInput],
    );

    const requestParams: IProxyClientAPIRequest = {
      apiConnectorProps: apiConnectorProps,
      apiTable: tableSettingsWithQueryInput,
    };
    // The error message here will only be used if our own API fails, not if the 3rd party API fails.
    // See apiStatusCode below for that.
    let response = await api.callAPI(
      RequestType.Post,
      "/proxyClientAPI",
      "",
      requestParams,
    );

    const apiStatusCode: string | undefined =
      response.headers?.["x-original-api-status-code"];

    if (response.status === 200 && apiStatusCode === "200") {
      if (response.payload != null && typeof response.payload !== "string") {
        response = {
          ...response,
          payload: JSON.stringify(response.payload, null, 2),
        };
      }

      setAxiosResult({
        ...response,
        status: apiStatusCode != null ? parseInt(apiStatusCode) : 400,
        payload: response.payload,
      });
    } else {
      let details: string | undefined = undefined;
      if (response.payload != null && !isEmpty(response.payload)) {
        if (typeof response.payload === "object") {
          details = JSON.stringify(response.payload, null, 2);
        } else {
          details = response.payload.toString();
        }
      }
      let message = response.error?.toString();

      // If the server cannot talk to the 3rd party API, we get an error back like:
      // `{ code: "INVALID_REQUEST", message: "No such host is known" }`
      if (response.error?.message != null) {
        message = response.error.message.toString();
      }

      // If we get no error message from the API but we do get a status code,
      // try and add a message like Unauthorized.
      if (isEmpty(message) && apiStatusCode != null) {
        let statusDescription = "";
        try {
          statusDescription = getReasonPhrase(apiStatusCode);
        } catch {
          // Do nothing, this just means we somehow received a non-standard status code.
        }

        message = `${apiStatusCode} ${statusDescription}`;
      }

      // Show whatever response the API sent down in the details.
      ToastrError("Error issuing request", message, details);

      previousStep!();
    }

    setIsLoading(false);
  }

  useEffect(() => {
    loadAPIPreview();
  }, [queryInputs]); // eslint-disable-line

  return (
    <div className="pages-apiConnector-components-PreviewRequestModal">
      <>
        {isLoading && <Loader />}
        {!isLoading && (
          <div className="previewRequest-codeMirrorContainer">
            <CDataCodeMirror
              sqlText={previewResponse}
              customExtensions={[langs.json(), langs.xml()]}
              height="300px"
              theme={previewTheme}
              basicSetup={{
                dropCursor: false,
                foldGutter: true,
                highlightActiveLineGutter: true,
                highlightSelectionMatches: true,
                bracketMatching: true,
              }}
            />
          </div>
        )}
      </>
      <StepWizardStepFooter>
        {tableSettings.useQuerySlicer ? (
          <TertiaryButton color="primary" onClick={() => previousStep!()}>
            Back
          </TertiaryButton>
        ) : (
          <span />
        )}
        <CDataButton
          buttonType={ButtonType.Primary}
          onClick={() => onNext()}
          data-testid="preview-request-next"
        >
          Next
        </CDataButton>
      </StepWizardStepFooter>
    </div>
  );
};
