import { Card, CardBody, Col, Row } from "reactstrap";
import { ICacheJob } from "../../../../models/Cache/ICacheJob";
import { getDriverIcon } from "../../../../components/drivers/DriverIconFactory";
import { useFeatureFlags } from "../../../../hooks/useFeatureFlags";
import Grid from "@mui/material/Unstable_Grid2";
import { TextWithOverflowTooltip } from "../../../../components/text/TextWithOverflowTooltip";
import { IConnection } from "../../../../models/Connections/IConnection";
import { FailedConnectionWarningIconWithToolTip } from "../../components/FailedConnectionWarningIconWithToolTip";

interface ICacheJobHeaderCardProps {
  cacheJobDetails: ICacheJob;
  connection?: IConnection;
}

export const CacheJobHeaderCard = (props: ICacheJobHeaderCardProps) => {
  const flags = useFeatureFlags().getFlags(["scheduled_queries"]);
  const details = props.cacheJobDetails;
  const driverIcon = details?.sourceConnectionDriver
    ? getDriverIcon(details.sourceConnectionDriver, "connection-icon me-1")
    : null;

  return (
    <Card className="cache-job-header-card" data-testid="cache-job-header-card">
      <CardBody>
        <Row>
          <Col className="mb-3">
            <h5 className="card-title mb-0">General</h5>
          </Col>
        </Row>
        <Row>
          <Col>
            <Grid
              className="header-card"
              container
              columns={flags.scheduled_queries.enabled ? 6 : 5}
              spacing={2}
            >
              {/* Title row */}
              <Grid xs={3} className="title-cell-text">
                Table Name
              </Grid>
              <Grid
                xs={2}
                className="title-cell-text"
                sx={{ paddingLeft: "0.75rem" }}
              >
                Connection Name
              </Grid>
              {flags.scheduled_queries.enabled ? (
                <Grid xs={1} className="title-cell-text">
                  Job Type
                </Grid>
              ) : null}

              {/* Body row */}
              <Grid xs={3} className="content-cell">
                <TextWithOverflowTooltip fullText={details?.name || "---"} />
              </Grid>
              <Grid xs={2}>
                <div className="content-cell">
                  <span data-testid="driver-icon">{driverIcon}</span>
                  <TextWithOverflowTooltip
                    fullText={details?.sourceConnectionName || "---"}
                  />
                  {props.connection?.isTested ? null : (
                    <FailedConnectionWarningIconWithToolTip
                      driver={props.connection?.driver ?? ""}
                      connectionId={props.connection?.id ?? ""}
                    />
                  )}
                </div>
              </Grid>
              {flags.scheduled_queries.enabled ? (
                <Grid xs={1} className="content-cell">
                  Cache
                </Grid>
              ) : null}
            </Grid>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
