import { useAPI } from "src/components/useAPI";
import { BackendType, RequestType } from "../../../../components/withAPI";
import { IAssetListItem } from "../../../../models";
import { IAssetList } from "../../../../models/Datasets/IAssetList";
import {
  AssetSchema,
  IWorkspacesProps,
  WorkspaceSchema,
} from "./WorkspacesList";

export function useDataExplorerWorkspaces(
  initialData: WorkspaceSchema[],
  filteredData: WorkspaceSchema[],
  setInitialData: (data: WorkspaceSchema[]) => void,
  setFilteredData: (data: WorkspaceSchema[]) => void,
  props: IWorkspacesProps,
) {
  const api = useAPI();
  async function fetchChildAssetFromWorkspace(workspaceId: string) {
    const { status, payload } = await api.callAPI(
      RequestType.Get,
      `/account/getChildAssetFromWorkspace/${workspaceId}`,
      "Error fetching child Assets from Workspace:",
      BackendType.AccountService,
    );

    const result: IAssetList = payload;
    if (status === 200 && (result.assets?.length ?? 0) > 0) {
      const assetList = pushAssets(result.assets!, workspaceId);

      const updatedWorkspaceTables: WorkspaceSchema[] = [];
      for (let index = 0; index < initialData.length; index++) {
        const currentWorkspace: WorkspaceSchema = initialData[index];
        const currentWorkspaceId = currentWorkspace.workspaceId;

        if (workspaceId === currentWorkspaceId) {
          updatedWorkspaceTables.push({
            workspaceId: currentWorkspaceId,
            name: currentWorkspace.name,
            subRows: assetList,
          });
        } else {
          updatedWorkspaceTables.push(currentWorkspace);
        }
      }

      setInitialData(updatedWorkspaceTables);
      if (props.searchQuery) {
        const updatedWorkspaceTables: WorkspaceSchema[] = [];
        for (let wIndex = 0; wIndex < filteredData.length; wIndex++) {
          const currentWorkspaceId = filteredData[wIndex].workspaceId;
          if (currentWorkspaceId === initialData[0].workspaceId) {
            updatedWorkspaceTables.push({
              workspaceId: currentWorkspaceId,
              name: filteredData[wIndex].name,
              subRows: initialData[0].subRows,
            });
          } else {
            updatedWorkspaceTables.push(filteredData[wIndex]);
          }
        }
      }
      setFilteredData(updatedWorkspaceTables);
    }
  }

  async function fetchChildAssetFromFolder(
    workspaceId: string,
    folderId: string,
  ) {
    const { status, payload } = await api.callAPI(
      RequestType.Get,
      `/account/getChildAssetFromFolder/${workspaceId}/${folderId}`,
      "Error fetching child Assets from Folder:",
      BackendType.AccountService,
    );

    const result: IAssetList = payload;
    if (status === 200 && (result.assets?.length ?? 0) > 0) {
      const subAssetList = pushAssets(result.assets!, workspaceId);

      const assetList: AssetSchema[] = [];
      const updatedWorkspaceTables: WorkspaceSchema[] = [];

      for (let index = 0; index < initialData.length; index++) {
        const workspace: WorkspaceSchema = initialData[index];
        const currWorkspaceId = workspace.workspaceId;
        if (workspaceId === currWorkspaceId) {
          for (
            let subIndex = 0;
            subIndex < workspace.subRows!.length;
            subIndex++
          ) {
            const asset = workspace.subRows![subIndex];
            const currAssetId = asset.assetId;

            if (folderId === currAssetId) {
              assetList.push({
                assetId: currAssetId,
                name: asset.name,
                assetType: asset.assetType,
                childCount: asset.childCount,
                parentId: asset.parentId,
                driver: asset.driver,
                workspaceId: workspaceId,
                subRows: subAssetList,
              });
            } else {
              assetList.push(asset);
            }
          }
          updatedWorkspaceTables.push({
            workspaceId: currWorkspaceId,
            name: workspace.name,
            subRows: assetList,
          });
        } else {
          updatedWorkspaceTables.push(workspace);
        }
      }
      setInitialData(updatedWorkspaceTables);

      if (props.searchQuery) {
        const assetList: AssetSchema[] = [];
        const updatedWorkspaceTables: WorkspaceSchema[] = [];
        for (let wIndex = 0; wIndex < filteredData.length; wIndex++) {
          const currentWorkspace: WorkspaceSchema = filteredData[wIndex];
          if (currentWorkspace.workspaceId === initialData[0].workspaceId) {
            for (
              let subIndex = 0;
              subIndex < currentWorkspace.subRows!.length;
              subIndex++
            ) {
              const asset: AssetSchema = currentWorkspace.subRows![subIndex];
              const currAssetId = asset.assetId;

              if (initialData[0].subRows![0].assetId === currAssetId) {
                assetList.push({
                  assetId: currAssetId,
                  name: asset.name,
                  assetType: asset.assetType,
                  childCount: asset.childCount,
                  parentId: asset.parentId,
                  driver: asset.driver,
                  workspaceId: currentWorkspace.workspaceId,
                  subRows: initialData[0].subRows![0].subRows,
                });
              } else {
                assetList.push(asset);
              }
            }
            updatedWorkspaceTables.push({
              workspaceId: currentWorkspace.workspaceId,
              name: currentWorkspace.name,
              subRows: assetList,
            });
          } else {
            updatedWorkspaceTables.push(currentWorkspace);
          }
        }
      }
      setFilteredData(updatedWorkspaceTables);
    }
  }

  const functionsAndAPIs = {
    fetchChildAssetFromWorkspace: fetchChildAssetFromWorkspace,
    fetchChildAssetFromFolder: fetchChildAssetFromFolder,
  };

  return functionsAndAPIs;
}

function pushAssets(
  assets: IAssetListItem[],
  workspaceId: string,
): AssetSchema[] {
  const assetList: AssetSchema[] = [];

  assets.forEach((asset: any) => {
    assetList.push({
      assetId: asset.id,
      name: asset.alias,
      assetType: asset.assetType,
      childCount: asset.childCount,
      parentId: asset.parentId,
      workspaceId: workspaceId,
      driver: asset.driver,
    });
  });

  return assetList;
}
