import React from "react";
import { func, object, string } from "prop-types";
import { Input } from "reactstrap";

export interface ICustomTextFilterProps {
  onFilter: (value: string) => void;
  placeholder?: string;
  column: object | null | undefined;
}

const CustomTextFilter = (props: ICustomTextFilterProps) => {
  return (
    <div
      className="search-filter-bar"
      onClick={(event) => event.stopPropagation()}
    >
      <i className="fa-regular fa-magnifying-glass align-middle me-2 search-glass"></i>
      <Input
        autoFocus={true}
        placeholder={props.placeholder}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          props.onFilter(event.target.value)
        }
        onKeyUp={(event) => {
          if (event.key === "Enter") {
            event.stopPropagation();
          }
        }}
        data-testid="input-search"
      />
    </div>
  );
};

CustomTextFilter.propTypes = {
  column: object.isRequired,
  onFilter: func.isRequired,
  placeholder: string,
};

export default CustomTextFilter;
