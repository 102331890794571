import { useEffect, useMemo, useRef, useState } from "react";
import { CDataModalV2 } from "../../../../../../../../components/modal/CDataModalV2";
import {
  ButtonType,
  CDataButton,
} from "../../../../../../../../components/buttons/CDataButton";
import { Col, Row } from "react-bootstrap";
import JoinLine from "../../../../../../../../assets/img/joinLine.svg?react";
import {
  IQueryBuilderJoin,
  IQueryBuilderModel,
  QueryJoinType,
} from "../../../../models/IQueryBuilderModel";
import { Formik, FormikProps } from "formik";
import { IQueryBuilderCommonProps } from "../../../../models/IQueryBuilderCommonProps";
import { Spinner } from "reactstrap";
import NewTableConnectionField from "./components/NewTableConnectionField";
import NewTableSchemaField from "./components/NewTableSchemaField";
import NewTableTableField from "./components/NewTableTableField";
import NewTableColumnField from "./components/NewTableColumnField";
import JoinTypeField from "./components/JoinTypeField";
import JoinTableTableField from "./components/JoinTableTableField";
import JoinTableColumnField from "./components/JoinTableColumnField";
import { AddDataModalContext } from "./AddDataModalContext";
import Loader from "../../../../../../../../components/Loader";
import { mapColumnMetadataToQueryBuilderColumn } from "./AddDataModal.functions";
import { IQueryBuilderColumn } from "../../../../models/IQueryBuilderColumn";
import { useMutation } from "@tanstack/react-query";
import { getColumnsForTable } from "../../../../../../../../api/metadata/getColumnsForTable";
import { DataType, FeatureId } from "../../../../../../../../models";
import { IQueryBuilderTable } from "../../../../models/IQueryBuilderTable";
import { getTablesForSchema } from "../../../../../../../../api/metadata/getTablesForSchema";
import { getSchemasForConnection } from "../../../../../../../../api/metadata/getSchemasForConnection";
import { generateUniqueTableAlias } from "../utilities/generateUniqueTableAlias";
import { useAppSelector } from "../../../../../../../../redux/hooks";

interface IAddDataModal extends IQueryBuilderCommonProps {
  handleDataSubmit: (values: IQueryBuilderJoin) => void;
  isDisplayed: boolean;
  joinToEditIndex: number | null;
  setIsDisplayed: (isDisplayed: boolean) => void;
  setJoinToEditIndex: (joinIndex: number | null) => void;
}

const AddDataModal = (props: IAddDataModal) => {
  const {
    queryData,
    connectionsList,
    handleDataSubmit,
    isDisplayed,
    setIsDisplayed,
    joinToEditIndex,
    setJoinToEditIndex,
  } = props;

  const joinToEdit =
    joinToEditIndex != null ? queryData.joins[joinToEditIndex] : null;

  const [loading, setLoading] = useState<boolean>(true);
  const [processing, setProcessing] = useState<boolean>(false);
  const [schemas, setSchemas] = useState<string[]>([]);
  const [tables, setTables] = useState<IQueryBuilderTable[]>([]);
  const [newTableColumns, setNewTableColumns] = useState<IQueryBuilderColumn[]>(
    [],
  );
  const [joinTableColumns, setJoinTableColumns] = useState<
    IQueryBuilderColumn[]
  >([]);

  const formikRef = useRef<FormikProps<IQueryBuilderJoin>>(null);

  const defaultJoin = useGetDefaultJoin(queryData);

  const { mutateAsync: getColumnMetadataAsync } = useMutation({
    mutationKey: ["/columns"],
    mutationFn: getColumnsForTable,
    onSuccess: (data) => {
      setNewTableColumns(
        mapColumnMetadataToQueryBuilderColumn(connectionsList, data),
      );
    },
    meta: {
      errorMessage:
        "Failed to retrieve column list list due to the following error:",
    },
  });

  const { mutateAsync: loadSchemasForConnectionAsync } = useMutation({
    mutationKey: ["/schemas"],
    mutationFn: getSchemasForConnection,
    onSuccess: (schemaMetadata) => {
      setSchemas(schemaMetadata.map((s) => s.schema));
    },
    meta: {
      errorMessage:
        "Failed to retrieve schema list list due to the following error:",
    },
  });

  const { mutateAsync: loadTablesForSchemaAsync } = useMutation({
    mutationKey: ["/tables"],
    mutationFn: getTablesForSchema,
    onSuccess: (tableMetadata) => {
      const connection = connectionsList.find(
        (c) =>
          c.name?.toLowerCase() === tableMetadata[0]?.catalog?.toLowerCase(),
      );
      // We should always have a connection, if we don't just bail out, nothing we can do.
      if (connection == null) {
        setTables([]);
        return;
      }

      setTables(
        tableMetadata.map<IQueryBuilderTable>((t) => {
          return {
            connectionId: connection.id!,
            connectionName: connection.name!,
            driver: connection.driver!,
            schema: t.schema,
            tableName: t.tableName,
            tableAlias: generateUniqueTableAlias({
              queryData,
              tableName: t.tableName,
            }),
          };
        }),
      );
    },
    meta: {
      errorMessage:
        "Failed to retrieve table list list due to the following error:",
    },
  });

  useEffect(() => {
    async function populateExistingData() {
      setLoading(true);

      try {
        // Load all the metadata for the existing join, schemas, tables, and columns.
        await Promise.all([
          loadSchemasForConnectionAsync(joinToEdit!.right.table.connectionName),

          loadTablesForSchemaAsync({
            connectionName: joinToEdit!.right.table.connectionName,
            schema: joinToEdit!.right.table.schema,
          }),

          getColumnMetadataAsync({
            connectionName: joinToEdit!.right.table.connectionName,
            schema: joinToEdit!.right.table.schema,
            tableName: joinToEdit!.right.table.tableName,
          }),
        ]);
      } finally {
        setLoading(false);
      }
    }

    // If editing a join, we need to load the metadata for the selected connections/tables/schemas.
    // If adding a new join, we're done and can just render.
    if (joinToEdit) {
      populateExistingData();
    } else {
      setLoading(false);
    }
  }, []); // eslint-disable-line

  const checkSubmitDisabled = () => {
    const values = formikRef.current?.values;

    return (
      !values?.joinType ||
      !values?.right.table.schema ||
      !values?.right.table.tableName ||
      !values?.right.table.connectionId ||
      !values?.right.column ||
      !values?.left.table.tableName ||
      !values?.left.column
    );
  };

  const closeModal = () => {
    setIsDisplayed(false);
    setJoinToEditIndex(null);
    setProcessing(false);
  };

  const handleFormikSubmit = (values: IQueryBuilderJoin) => {
    handleDataSubmit(values);
    closeModal();
  };

  const modalBody = () => {
    if (loading) {
      return <Loader />;
    }
    return (
      <span className="add-data-modal">
        <Row className="gray-helper-text mb-3">
          <Col>
            Specify a column from your new table, then define the table & column
            you would like to join it to.
          </Col>
        </Row>
        <JoinTypeField />
        <Row>
          <Col className="pe-2 data-modal-new-table-column">
            <Row>
              <Col className="section-label mb-3">New Table</Col>
            </Row>
            <NewTableConnectionField {...props} />
            <NewTableSchemaField
              schemaList={schemas}
              connectionsList={connectionsList}
              queryData={queryData}
            />
            <NewTableTableField
              tableList={tables}
              connectionList={connectionsList}
            />
            <NewTableColumnField columnList={newTableColumns} />
          </Col>
          <Col className="curved-arrow px-0 pb-1 mb-4">
            <JoinLine />
          </Col>
          <Col className="ps-0 data-modal-join-table-column">
            <Row>
              <Col className="section-label mb-3">Join Table</Col>
            </Row>
            <JoinTableTableField {...props} />
            <JoinTableColumnField
              joinTableColumns={joinTableColumns}
              processing={processing}
            />
          </Col>
        </Row>
      </span>
    );
  };

  return (
    <AddDataModalContext.Provider
      value={{
        setProcessing: setProcessing,
        setSchemas: setSchemas,
        setTables: setTables,
        setNewTableColumns: setNewTableColumns,
        setJoinTableColumns: setJoinTableColumns,
      }}
    >
      <Formik<IQueryBuilderJoin>
        enableReinitialize
        innerRef={formikRef}
        initialValues={joinToEdit ?? defaultJoin}
        onSubmit={(values) => handleFormikSubmit(values)}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <div hidden={!processing || loading}>
              <div className="loading-background" />
              <Spinner
                className="spinner-border loading-spinner"
                color="info"
              />
            </div>
            <CDataModalV2
              close={closeModal}
              displayed={isDisplayed}
              title={"Add Data"}
              modalSize="lg"
              primaryButton={
                <CDataButton
                  buttonType={ButtonType.Primary}
                  disabled={checkSubmitDisabled()}
                  onClick={() => props.handleSubmit()}
                  type="submit"
                >
                  Confirm
                </CDataButton>
              }
            >
              {modalBody()}
            </CDataModalV2>
          </form>
        )}
      </Formik>
    </AddDataModalContext.Provider>
  );
};

export default AddDataModal;

function useGetDefaultJoin(queryData: IQueryBuilderModel): IQueryBuilderJoin {
  const availableFeatureIds = useAppSelector(
    (state) => state.subscription.limits!.availableFeatureIds,
  );

  const isVirtualizationEnabled = availableFeatureIds.includes(
    FeatureId.QueryFederation,
  );

  // TODO: CLOUD-12788: Wrap this in a useMemo to avoid re-creating the object on every render
  // eslint-disable-next-line
  const defaultJoin = {
    joinType: QueryJoinType.InnerJoin,
    left: {
      column: "",
      dataType: DataType.VARCHAR,
      table: {
        connectionId: "",
        connectionName: "",
        driver: "",
        schema: "",
        tableAlias: "",
        tableName: "",
      },
    },
    right: {
      column: "",
      dataType: DataType.VARCHAR,
      table: {
        connectionId: "",
        connectionName: "",
        driver: "",
        schema: "",
        tableAlias: "",
        tableName: "",
      },
    },
  };

  if (!isVirtualizationEnabled) {
    defaultJoin.right.table.connectionId = queryData.from.connectionId;
    defaultJoin.right.table.connectionName = queryData.from.connectionName;
    defaultJoin.right.table.driver = queryData.from.driver;
  }

  if (queryData.joins.length === 0) {
    defaultJoin.left.table = queryData.from;
  }

  return useMemo(() => {
    return defaultJoin;
  }, [defaultJoin]);
}
