import { useEffect, useState } from "react";

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";

import { ICacheJobList } from "../../models/Cache/ICacheJobList";
import { ICacheJobListItem } from "../../models/Cache/ICacheJobListItem";
import { emptyCacheJobs } from "./Jobs.mock";
import { CacheConnectionAlertBanner } from "./components/CacheConnectionAlertBanner";
import Loader from "../../components/Loader";
import AddCacheJobWizard from "./addWizard/AddCacheJobWizard";
import { useNavigate } from "react-router-dom";
import {
  IBatchCreateRequest,
  IBatchDeleteRequest,
  IConnection,
} from "../../models";
import { jobsConstants } from "./Jobs.constants";
import { ToastrSuccess } from "../../services/toastrService";
import { RowSelectionState } from "@tanstack/react-table";
import { useAppSelector } from "../../redux/hooks";
import { DeleteJobModal } from "./modals/DeleteJobModal";
import { BatchRunJobsModal } from "./modals/BatchRunJobsModal";
import { IScheduledQueryList } from "../../models/ScheduledQueries/IScheduledQueryList";
import { IScheduledQueryListItem } from "../../models/ScheduledQueries/IScheduledQueryListItem";
import { JobType } from "../../models/Cache/JobType";
import { useJobsFunctions } from "./useJobsFunctions";
import { JobsTableManager } from "./components/JobsTableManager";
import { useMutation } from "@tanstack/react-query";
import { getConnections } from "../connections/connectionList/getConnections";
import { useUserInfo } from "../../hooks/useUserInfo";
import { UserRole } from "../../models/Users/UserRole";

// Combined type for the data that allows us to create the ListTable properly
export type JobListItem = ICacheJobListItem | IScheduledQueryListItem;

export const Jobs = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [cacheJobs, setCacheJobs] =
    useState<ICacheJobListItem[]>(emptyCacheJobs);
  const [scheduledQueries, setScheduledQueries] =
    useState<IScheduledQueryListItem[]>();
  const [selectedJobs, setSelectedJobs] = useState<RowSelectionState>({});
  const [isCacheJobWizardDisplayed, setIsCacheJobWizardDisplayed] =
    useState<boolean>(false);
  const [showBatchDeleteJobModal, setShowBatchDeleteJobModal] = useState(false);
  const [showBatchRunModal, setShowBatchRunModal] = useState(false);
  const [connections, setConnections] = useState<IConnection[]>([]);

  const storeCacheConnection: IConnection = useAppSelector(
    (state) => state.cacheConnection!,
  );

  const navigate = useNavigate();
  const {
    getCachedJobsList,
    getScheduledQueryList,
    runScheduledQueryAsync,
    runCacheJobAsync,
    tableToggleCacheJob,
    tableToggleScheduledQuery,
    bulkDeleteCacheJobs,
    bulkDeleteScheduledQueries,
    batchRunCacheJobsAsync,
    batchRunScheduledQueriesAsync,
    isJobsProcessing,
  } = useJobsFunctions();

  const user = useUserInfo();

  const { mutateAsync: getConnectionsAsync } = useMutation({
    mutationKey: ["connections/list"],
    mutationFn: getConnections,
    meta: {
      errorMessage:
        "Failed to retrieve list of connections due to the following error:",
    },
  });

  async function getJobsData() {
    const cacheJobs: ICacheJobList = await getCachedJobsList();
    if (cacheJobs) {
      setCacheJobs(cacheJobs.list ?? []);
    }
    const scheduledQueries: IScheduledQueryList = await getScheduledQueryList();
    if (scheduledQueries) {
      setScheduledQueries(scheduledQueries.list ?? []);
    }

    if (isLoading) {
      setIsLoading(false);
    }
  }

  async function getConnectionsData() {
    const data = await getConnectionsAsync({
      IsAdmin: user.IsInRole(UserRole.Admin),
      CurrentUserId: user.Self.id,
    });
    setConnections(data.connections ?? []);
  }
  useEffect(() => {
    getConnectionsData();
  }, []); //eslint-disable-line

  useEffect(() => {
    getJobsData();
  }, [isCacheJobWizardDisplayed]); // eslint-disable-line

  async function editJob(job: JobListItem) {
    if (job.jobType === JobType.Caching) {
      navigate(`/jobs/editCacheJob/${job.id}`);
    } else {
      navigate(`/jobs/editScheduledQuery/${job.id}`);
    }
  }

  async function manuallyRunJob(job: JobListItem) {
    setIsProcessing(true);

    if (job.jobType === JobType.Caching) {
      await handleCacheJobRun(job as ICacheJobListItem);
    } else {
      await handleScheduledQueryRun(job as IScheduledQueryListItem);
    }

    setIsProcessing(false);
  }

  async function handleCacheJobRun(job: ICacheJobListItem) {
    const runJobSucceeded = await runCacheJobAsync(job.id);

    if (runJobSucceeded) {
      getJobsData();
    }
  }

  async function handleScheduledQueryRun(job: IScheduledQueryListItem) {
    const runJobSucceeded = await runScheduledQueryAsync(job.id);

    if (runJobSucceeded) {
      const newScheduledQueries = await getScheduledQueryList();
      setScheduledQueries(newScheduledQueries.list);
    }
  }

  function parseSelectedJobs(): {
    selectedCacheJobs: string[];
    selectedScheduledQueries: string[];
  } {
    const selectedJobIds = Object.keys(selectedJobs);
    const selectedCacheJobs = selectedJobIds.filter((jobId) =>
      cacheJobs.some((cacheJob) => cacheJob.id === jobId),
    );
    const selectedScheduledQueries = selectedJobIds.filter((jobId) =>
      scheduledQueries?.some((query) => query.id === jobId),
    );

    return {
      selectedCacheJobs: selectedCacheJobs,
      selectedScheduledQueries: selectedScheduledQueries,
    };
  }

  async function onBatchDelete() {
    const { selectedCacheJobs, selectedScheduledQueries } = parseSelectedJobs();

    const cacheDeleteRequest: IBatchDeleteRequest = { ids: selectedCacheJobs };
    const scheduledDeleteRequest: IBatchDeleteRequest = {
      ids: selectedScheduledQueries,
    };

    const deleteCacheJobsResponse =
      await bulkDeleteCacheJobs(cacheDeleteRequest);
    const deleteScheduledQueriesResponse = await bulkDeleteScheduledQueries(
      scheduledDeleteRequest,
    );

    if (deleteCacheJobsResponse) {
      const newCacheJobs = cacheJobs.filter(
        (job) => !deleteCacheJobsResponse.deletedIds?.includes(job.id),
      );
      setCacheJobs(newCacheJobs);
    }

    if (deleteScheduledQueriesResponse) {
      const newScheduledQueries = scheduledQueries?.filter(
        (job) => !deleteScheduledQueriesResponse.deletedIds?.includes(job.id),
      );
      setScheduledQueries(newScheduledQueries);
    }

    if (
      !deleteCacheJobsResponse.error &&
      !deleteScheduledQueriesResponse.error
    ) {
      const totalDeletions =
        (deleteCacheJobsResponse.deletedIds?.length ?? 0) +
        (deleteScheduledQueriesResponse.deletedIds?.length ?? 0);
      let toastTitle, toastBody;
      if (totalDeletions === 1) {
        toastTitle = jobsConstants.JOB_SUCCESSFULLY_DELETED_TITLE;
        toastBody = jobsConstants.JOB_SUCCESSFULLY_DELETED_BODY;
      } else {
        toastTitle = jobsConstants.JOBS_SUCCESSFULLY_DELETED_TITLE;
        toastBody = `${totalDeletions} jobs were successfully deleted.`;
      }
      ToastrSuccess(toastTitle, toastBody);
    }

    setSelectedJobs({});
    setShowBatchDeleteJobModal(false);
  }

  async function onBatchRun() {
    setIsProcessing(true); // Prevents loading spinner from flashing in and out between running cache and scheduled jobs
    const { selectedCacheJobs, selectedScheduledQueries } = parseSelectedJobs();

    const cacheRunRequest: IBatchCreateRequest<string> = {
      records: selectedCacheJobs,
    };
    const scheduledRunRequest: IBatchCreateRequest<string> = {
      records: selectedScheduledQueries,
    };

    const runCacheJobsSucceeded = await batchRunCacheJobsAsync(cacheRunRequest);
    const runScheduledQueriesSucceeded =
      await batchRunScheduledQueriesAsync(scheduledRunRequest);

    if (runCacheJobsSucceeded) {
      getJobsData();
    }

    if (runScheduledQueriesSucceeded) {
      const newScheduledQueries = await getScheduledQueryList();
      setScheduledQueries(newScheduledQueries.list);
    }

    if (runCacheJobsSucceeded && runScheduledQueriesSucceeded) {
      let toastTitle, toastBody;
      if (Object.keys(selectedJobs).length === 1) {
        toastTitle = "Job successfully scheduled";
        toastBody = "The selected job has been scheduled to run.";
      } else {
        toastTitle = "Jobs successfully scheduled";
        toastBody = "The selected jobs have been scheduled to run.";
      }
      ToastrSuccess(toastTitle, toastBody);
    }

    setSelectedJobs({});
    setShowBatchRunModal(false);
    setIsProcessing(false);
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container fluid className={"p-0 pages-jobs"}>
      <div hidden={!isProcessing && !isJobsProcessing}>
        <div className="loading-background" />
        <Spinner className="spinner-border loading-spinner" color="info" />
      </div>
      <Row className="mb-4">
        <Col className="h3 mb-0 d-flex align-items-center">Jobs</Col>
        <Col className="d-flex justify-content-end">
          <Button
            outline
            data-testid="button-manage-cache-connection"
            color="primary"
            className="tertiary-button"
            onClick={() => navigate("/jobs/cacheConnection")}
          >
            Manage Cache Connection
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <Row className="mb-3">
                <Col className="d-flex align-content-center">
                  <CardTitle className="mb-0 d-flex align-items-center">
                    Jobs
                  </CardTitle>
                </Col>
                <Col className="pull-right">
                  <Button
                    data-testid="button-run"
                    color="secondary"
                    disabled={Object.keys(selectedJobs).length === 0}
                    onClick={() => setShowBatchRunModal(true)}
                    className="ms-1"
                  >
                    <i className="fa fa-play icon no-pointer-event" />
                    Run
                  </Button>
                  <Button
                    data-testid="button-delete"
                    color="secondary"
                    disabled={Object.keys(selectedJobs).length === 0}
                    onClick={() => setShowBatchDeleteJobModal(true)}
                    className="ms-1"
                  >
                    <i className="fa fa-times icon no-pointer-event" />
                    Delete
                  </Button>
                  <Button
                    data-testid="button-add"
                    color="primary"
                    disabled={!storeCacheConnection.id}
                    onClick={() => setIsCacheJobWizardDisplayed(true)}
                    className="ms-1"
                  >
                    <i className="fa fa-plus icon no-pointer-event" />
                    Add Job
                  </Button>
                </Col>
              </Row>
              {!storeCacheConnection.id ? <CacheConnectionAlertBanner /> : null}
              <Row>
                <Col>
                  <JobsTableManager
                    cacheJobs={cacheJobs}
                    scheduledQueries={scheduledQueries}
                    setCacheJobs={setCacheJobs}
                    setScheduledQueries={setScheduledQueries}
                    editJob={editJob}
                    runJob={manuallyRunJob}
                    selection={selectedJobs}
                    setSelection={setSelectedJobs}
                    toggleCacheJob={tableToggleCacheJob}
                    toggleScheduledQuery={tableToggleScheduledQuery}
                    setRefresh={getJobsData}
                    isRefreshing={isJobsProcessing}
                    connections={connections}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <AddCacheJobWizard
        toggleModal={setIsCacheJobWizardDisplayed}
        isDisplayed={isCacheJobWizardDisplayed}
        existingCacheJobs={
          cacheJobs ? cacheJobs.map((cacheJob) => cacheJob.name ?? "") : []
        }
      />
      <DeleteJobModal
        isBulk={Object.keys(selectedJobs).length !== 1}
        isDisplayed={showBatchDeleteJobModal}
        onDelete={onBatchDelete}
        setIsDisplayed={setShowBatchDeleteJobModal}
      />
      <BatchRunJobsModal
        isDisplayed={showBatchRunModal}
        onBatchRun={onBatchRun}
        setIsDisplayed={setShowBatchRunModal}
      />
    </Container>
  );
};
