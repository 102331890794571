import { IQueryBuilderColumn } from "./IQueryBuilderColumn";

export enum FilterType {
  Between = "Between",
  EqualTo = "Equal to",
  GreaterThan = "Greater than",
  LessThan = "Less than",
  GreaterThanOrEqualTo = "Greater than or equal to",
  LessThanOrEqualTo = "Less than or equal to",
  NotEqualTo = "Not equal to",
}

export type IQueryBuilderFilter = {
  column: IQueryBuilderColumn;
  filterType: FilterType;
  // The value of this depends on the filterType/column type.
  // We may have to support things like `BETWEEN` for dates which
  // have a start and end date.
  data: Record<string, unknown>;
};
