import { MutableRefObject, useEffect } from "react";
import {
  IAPIFilter,
  APIFilterOperator,
  APIFilterType,
} from "../../../../models";
import { isEmpty, isEqual } from "lodash";

import {
  Card,
  CardBody,
  Col,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import { ITableSettings } from "../SetTable";
import {
  Form,
  Formik,
  FormikErrors,
  FormikProps,
  useFormikContext,
} from "formik";
import * as Yup from "yup";
import { dummyTableSubmit } from "../SetTableValidationDictionary";
import TertiaryButton from "../../../../components/buttons/TertiaryButton";
import { produce } from "immer";
import {
  ButtonType,
  CDataButton,
} from "../../../../components/buttons/CDataButton";
import {
  YupOptionalSchema,
  YupRequiredSchema,
} from "../../../../utility/types/yupHelperTypes";
import { getNumericEnumValues } from "../../../../utility/enums/getNumericEnumValues";
import { InfoIcon } from "../../../../components/InfoIcon";
import { IAPIConnectorSettings } from "../types/IAPIConnectorSettings";

export interface IFiltersCardProps {
  apiSettings: IAPIConnectorSettings;
  formRef: MutableRefObject<FormikProps<ITableSettings>>;
}

const yupSchema = Yup.object<YupOptionalSchema<ITableSettings>>({
  filters: Yup.array().of(
    Yup.object<YupRequiredSchema<IAPIFilter>>({
      columnName: Yup.string()
        .nullable()
        .trim()
        .required("A Column Name is required"),
      operator: Yup.number()
        .nullable()
        .oneOf(getNumericEnumValues(APIFilterOperator))
        .required("A SQL Operator is required"),
      type: Yup.number()
        .nullable()
        .oneOf(getNumericEnumValues(APIFilterType))
        .required("A Request Filter type is required"),
      paramName: Yup.string()
        .nullable()
        .trim()
        .test({
          message: "A URL Parameter name is required",
          test: (value, context) => {
            const column = context.parent as IAPIFilter;
            if (column.type === APIFilterType.Parameter) {
              return !isEmpty(value);
            }
            return true;
          },
        }),
      headerName: Yup.string()
        .nullable()
        .trim()
        .test({
          message: "A Request header name is required",
          test: (value, context) => {
            const column = context.parent as IAPIFilter;
            if (column.type === APIFilterType.Header) {
              return !isEmpty(value);
            }
            return true;
          },
        }),
    }),
  ),
});

export const FiltersCard = (props: IFiltersCardProps) => {
  const {
    apiSettings: { tableSettings, setTableSettings },
    formRef,
  } = props;

  function setTableFilters(filters: IAPIFilter[]) {
    setTableSettings({
      ...tableSettings,
      filters,
    });
  }

  return (
    <Card className="p-0 pages-apiConnector-components-FiltersCard">
      <CardBody>
        <Row>
          <Col>
            <h5 className="card-title mb-0">Filters</h5>
          </Col>
          <Col></Col>
        </Row>
        <Formik
          innerRef={formRef}
          initialValues={tableSettings}
          validationSchema={yupSchema}
          validateOnChange={true}
          onSubmit={dummyTableSubmit}
        >
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <FiltersCardFormBody
                {...props}
                setTableFilters={setTableFilters}
              />
            </Form>
          )}
        </Formik>
      </CardBody>
    </Card>
  );
};

const apiFilterOperatorToName: Record<APIFilterOperator, string> = {
  [APIFilterOperator.Equal]: "Equal",
  [APIFilterOperator.LessThan]: "Less Than",
  [APIFilterOperator.LessThanOrEqual]: "Less Than Or Equal",
  [APIFilterOperator.GreaterThan]: "Greater Than",
  [APIFilterOperator.GreaterThanOrEqual]: "Greater Than Or Equal",
  [APIFilterOperator.Like]: "Like",
};

interface IFiltersCardFormBodyProps {
  apiSettings: IAPIConnectorSettings;
  setTableFilters: (filters: IAPIFilter[]) => void;
}

const FiltersCardFormBody = (props: IFiltersCardFormBodyProps) => {
  const {
    apiSettings: { tableSettings, setUnsavedChanges },
    setTableFilters,
  } = props;

  const {
    values,
    setValues,
    validateForm,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
  } = useFormikContext<ITableSettings>();

  // This isn't a real form, just update the parent whenever the form data changes.
  useEffect(() => {
    setTableFilters(values.filters);
  }, [values.filters]); // eslint-disable-line

  // If the columns change, we need to update the form with the new column values for the dropdown.
  useEffect(() => {
    if (!isEqual(values.columns, tableSettings.columns)) {
      setValues(tableSettings, true);
    }
  }, [values.columns, tableSettings]); // eslint-disable-line

  useEffect(() => {
    if (!isEmpty(touched.filters)) {
      setUnsavedChanges(true);
    }
  }, [touched.filters]); // eslint-disable-line

  useEffect(() => {
    // This is annoying but deleting the filter row causes the indexes to shuffle around
    // and validation messages to show up on the wrong fields. We have no real key for the rows.
    // We need to trigger a full form validation when the rows are deleted to avoid validation messages
    // being moved to the wrong field.
    validateForm();
  }, [values.filters.length]); // eslint-disable-line

  function addNewFilterRow() {
    const newTableSettings = produce(values, (draft) => {
      draft.filters.push({
        columnName: "",
        headerName: "",
        operator: undefined!,
        paramName: "",
        type: undefined!,
      });
    });
    setValues(newTableSettings, true);
    setTableFilters(newTableSettings.filters);

    setUnsavedChanges(true);
  }

  function deleteFilterRow(index: number) {
    const newTableSettings = produce(values, (draft) => {
      draft.filters.splice(index, 1);
    });
    setValues(newTableSettings, false);
    setTableFilters(newTableSettings.filters);

    setUnsavedChanges(true);
  }

  const allColumnNames = tableSettings.columns.map((c) => c.columnName);

  /**
   * Handles the logic of changing the Request Filter type dropdown.
   *
   * We need to swap the `headerName` and `paramName` fields so we don't clear out the user's text
   * if they made a mistake and change the type after typing in the input field.
   */
  function setRequestFilterType(
    filterIndex: number,
    requestFilterType: APIFilterType | null,
  ) {
    const filter = values.filters[filterIndex];

    setFieldValue(`filters[${filterIndex}].type`, requestFilterType, true);

    // If the user enters text in the Request Filter text field and then
    // changes the type dropdown, we need to swap what field the text is stored in.
    if (
      !isEmpty(filter.headerName) &&
      (requestFilterType == null ||
        requestFilterType === APIFilterType.Parameter)
    ) {
      setFieldValue(`filters[${filterIndex}].headerName`, "", true);
      setFieldValue(
        `filters[${filterIndex}].paramName`,
        filter.headerName,
        true,
      );
    }
    if (
      !isEmpty(filter.paramName) &&
      requestFilterType === APIFilterType.Header
    ) {
      setFieldValue(
        `filters[${filterIndex}].headerName`,
        filter.paramName,
        true,
      );
      setFieldValue(`filters[${filterIndex}].paramName`, "", true);
    }
  }

  return (
    <div className="mt-3">
      <div className="gray-helper-text pb-3">
        Use filters to process WHERE clause conditions by dynamically including
        headers or URL parameters to the request. Refer to our documentation for
        more details and examples.
      </div>
      <div>
        {values.filters.map((filter, index) => {
          function getFilterError(
            fieldName: keyof IAPIFilter,
          ): string | undefined {
            const filterErrors = errors.filters?.[index] as
              | FormikErrors<IAPIFilter>
              | undefined;
            const filterTouched = touched.filters?.[index];

            // Don't show the error until the user touched the field.
            if (!filterTouched?.[fieldName]) {
              return undefined;
            }

            return filterErrors?.[fieldName]?.toString();
          }

          return (
            <div className="filter-row" key={index}>
              {/* Column Name */}
              <div className="filter-field-container">
                <div>
                  <Label className="h5 required">Column Name</Label>
                  <InfoIcon
                    tooltipMessage="This filter will be applied when the selected column and SQL operator exist in the WHERE clause."
                    className="info-icon-col"
                    iconId="column-name-icon"
                  />
                </div>
                <Input
                  aria-label="Column Name"
                  name={`filters[${index}].columnName`}
                  type="select"
                  value={filter.columnName ?? ""}
                  onBlur={handleBlur}
                  onChange={(event) => {
                    handleChange(event);
                  }}
                  invalid={!!getFilterError("columnName")}
                >
                  <option value={""}>Select</option>
                  {allColumnNames.map((columnName) => (
                    <option key={columnName} value={columnName}>
                      {columnName}
                    </option>
                  ))}
                </Input>
                <FormFeedback type="invalid">
                  {getFilterError("columnName")}
                </FormFeedback>
              </div>

              {/* SQL Operator */}
              <div className="filter-field-container">
                <div>
                  <Label className="h5 required">SQL Operator</Label>
                  <InfoIcon
                    tooltipMessage="This filter will be applied when the selected column and SQL operator exist in the WHERE clause."
                    className="info-icon-col"
                    iconId="sql-operator-icon"
                  />
                </div>
                <Input
                  aria-label="SQL Operator"
                  name={`filters[${index}].operator`}
                  type="select"
                  value={filter?.operator ?? ""}
                  onBlur={handleBlur}
                  onChange={(event) => {
                    // Convert the string from the value into a number so it matches the enum.
                    const operatorName = `filters[${index}].operator`;
                    let newValue: string | undefined = event.target.value;
                    if (newValue === "") {
                      newValue = undefined;
                    }
                    const operatorType =
                      newValue != null ? parseInt(newValue) : null;
                    setFieldValue(operatorName, operatorType);

                    // for any operator type besides Equal, a user can only select a URL Parameter
                    // in the Request Filter type dropdown.
                    if (
                      operatorType != null &&
                      operatorType !== APIFilterOperator.Equal
                    ) {
                      setRequestFilterType(index, APIFilterType.Parameter);
                    }
                  }}
                  invalid={!!getFilterError("operator")}
                >
                  <option value={""}>Select</option>
                  {Object.keys(apiFilterOperatorToName).map((filterKey) => (
                    <option key={filterKey} value={filterKey}>
                      {
                        apiFilterOperatorToName[
                          parseInt(filterKey) as APIFilterOperator
                        ]
                      }
                    </option>
                  ))}
                </Input>
                <FormFeedback type="invalid">
                  {getFilterError("operator")}
                </FormFeedback>
              </div>

              {/* Request Filter */}
              <div className="request-filter-field-container">
                <div>
                  <Label className="h5 required">Request Filter</Label>
                  <InfoIcon
                    tooltipMessage="Specify how the filter value is passed from the SQL Statement to the API when the filter is activated. Define the URL Parameter name if using the Parameter method, or the HTTP Request Header name if using the Header method."
                    className="info-icon-col"
                    iconId="request-filter-icon"
                  />
                </div>
                <div className="request-filter-inputs-container">
                  <Input
                    className="request-filter-type-select"
                    aria-label="Request Filter Type"
                    name={`filters[${index}].type`}
                    type="select"
                    value={filter?.type ?? ""}
                    // The user can only use the Request Filter type dropdown when using the Equal operator.
                    disabled={
                      filter?.operator != null &&
                      filter.operator !== APIFilterOperator.Equal
                    }
                    onBlur={handleBlur}
                    onChange={(event) => {
                      // Convert the string from the value into a number so it matches the enum.
                      let newValue: string | undefined = event.target.value;
                      if (isEmpty(newValue)) {
                        newValue = undefined;
                      }
                      const requestFilterType =
                        newValue != null
                          ? (parseInt(newValue) as APIFilterType)
                          : null;
                      setRequestFilterType(index, requestFilterType);
                    }}
                    invalid={!!getFilterError("type")}
                  >
                    <option value={""}>Select</option>
                    <option value={APIFilterType.Parameter}>
                      URL Parameter
                    </option>
                    <option value={APIFilterType.Header}>Request Header</option>
                  </Input>

                  <Input
                    className="request-filter-name-input"
                    aria-label="Request Filter Text"
                    name={
                      filter?.type === APIFilterType.Header
                        ? `filters[${index}].headerName`
                        : `filters[${index}].paramName`
                    }
                    type="text"
                    value={
                      filter?.type === APIFilterType.Header
                        ? filter?.headerName
                        : filter?.paramName ?? ""
                    }
                    placeholder={
                      filter?.type == null
                        ? undefined
                        : filter?.type === APIFilterType.Parameter
                          ? "e.g. startYear"
                          : "e.g. x-start-year"
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    invalid={
                      filter?.type === APIFilterType.Header
                        ? !!getFilterError("headerName")
                        : !!getFilterError("paramName")
                    }
                  />

                  {/* Delete Icon */}
                  {/* This is in the container for the Request filter to make it line up correctly with the input fields. */}
                  <CDataButton
                    buttonType={ButtonType.Tertiary}
                    className="delete-row-button"
                    onClick={() => deleteFilterRow(index)}
                    aria-label="Delete Filter Row"
                  >
                    <i className="fa fa-trash-alt" />
                  </CDataButton>
                </div>
                <FormFeedback>{getFilterError("type")}</FormFeedback>
                <FormFeedback>{getFilterError("paramName")}</FormFeedback>
                <FormFeedback>{getFilterError("headerName")}</FormFeedback>
              </div>
            </div>
          );
        })}

        <TertiaryButton type="button" onClick={() => addNewFilterRow()}>
          <i className="fa fa-plus icon" />
          Add Filter
        </TertiaryButton>
      </div>
    </div>
  );
};
