import { CardProps } from "@mui/material";
import classNames from "classnames";

interface CDataCardProps extends CardProps {
  outline?: boolean;
  roundedCorners?: boolean;
  noShadow?: boolean;
}

export function CDataCard(props: CDataCardProps) {
  const { outline, roundedCorners, noShadow, className, ...remainingProps } =
    props;

  const classes = classNames("cdata-card", className, {
    outline: outline,
    "rounded-corners": roundedCorners,
    "no-shadow": noShadow,
  });

  return (
    <div {...remainingProps} className={classes}>
      {props.children}
    </div>
  );
}
