import { ProgressBar } from "react-bootstrap";
import { IUsageBar } from "./IUsageBar";
import { IUsageAggregate, UsageType } from "./IUsage";
import { BillingStatus } from "../../models/";
import classNames from "classnames";
import { getSalesEmailAddress } from "../../utility/LocalizedEmailAddresses";
import { isAfter, isBefore } from "date-fns";
import { UTCDate } from "@date-fns/utc";
import { useIsConnectForSpreadsheets } from "../../hooks/useIsConnectForSpreadsheets";

interface IUsageMessage {
  message: JSX.Element;
  amount: number;
  overage: boolean;
}

export const UsageBar = (props: IUsageBar) => {
  const usageLimit = props.aggregate.usageLimit;
  const salesEmailAddress = getSalesEmailAddress();
  const usageType = Object.values(UsageType)[props.usageType];
  const pluralUsageType = usageType === "query" ? "queries" : `${usageType}s`;
  const isSpreadsheetUser = useIsConnectForSpreadsheets();
  function getComponentName(): string {
    return "components-UsageBar";
  }

  function getUsageMessage(selectedPeriod: IUsageAggregate): IUsageMessage {
    // we have an unlimited user
    if (selectedPeriod.usageLimit < 0) {
      return {
        message: (
          <>
            {selectedPeriod.usage}{" "}
            {selectedPeriod.usage !== 1 ? pluralUsageType : usageType} used for
            this billing period.
          </>
        ),
        amount: selectedPeriod.usage * 100,
        overage: false,
      } as IUsageMessage;
    }

    // is this a trial user or not?
    const isTrial =
      props.billingStatus === BillingStatus.Trial ||
      props.billingStatus === BillingStatus.TrialEnding;

    // we have to determine if we're looking at a current period or not to adjust the language
    let isCurrent;

    // first let's check if the dates even exist, if both are non-existent, then we'll treat this as current
    if (
      selectedPeriod.periodStart === undefined &&
      selectedPeriod.periodEnd === undefined
    ) {
      isCurrent = true;
    } else {
      // the dates do exist, but is today between them?
      const now = new Date();
      isCurrent =
        isAfter(now, new UTCDate(selectedPeriod.periodStart!)) &&
        isBefore(now, new UTCDate(selectedPeriod.periodEnd!));
    }
    if (isCurrent) {
      // current period so look at remainder vs. warning
      if (selectedPeriod.usage <= selectedPeriod.usageLimit) {
        return {
          message: (
            <>
              {selectedPeriod.usageLimit - selectedPeriod.usage}{" "}
              {selectedPeriod.usageLimit - selectedPeriod.usage !== 1
                ? pluralUsageType
                : usageType}
              {isSpreadsheetUser && usageLimit === -1 ? ` used` : ` remaining`}{" "}
              in your {isTrial ? " Trial " : null} plan.
            </>
          ),
          amount: (selectedPeriod.usage / selectedPeriod.usageLimit) * 100,
          overage: false,
        } as IUsageMessage;
      } else {
        // current period but over
        return {
          message: isTrial ? (
            <>
              <i className="fa fa-exclamation-triangle me-2 no-pointer-event overage-alert-icon" />
              You have reached your {pluralUsageType} limit for your Trial
              period. Please contact{" "}
              <a
                href={`mailto:${salesEmailAddress}`}
                className="overage-hyperlink"
              >
                {salesEmailAddress}
              </a>{" "}
              for further assistance.
            </>
          ) : (
            <>
              <i className="fa fa-exclamation-triangle me-2 no-pointer-event overage-alert-icon" />
              {selectedPeriod.usage - selectedPeriod.usageLimit}{" "}
              {selectedPeriod.usage - selectedPeriod.usageLimit !== 1
                ? pluralUsageType
                : usageType}{" "}
              over your plan limit. Please upgrade your plan today to avoid
              incurring additional overage charges.
            </>
          ),
          amount: (selectedPeriod.usage / selectedPeriod.usageLimit) * 100,
          overage: true,
        } as IUsageMessage;
      }
    } else {
      // we're looking at a past period
      if (selectedPeriod.usage <= selectedPeriod.usageLimit) {
        return {
          message: (
            <>
              {selectedPeriod.usage}{" "}
              {selectedPeriod.usage !== 1 ? pluralUsageType : usageType} used in
              this billing period.
            </>
          ),
          amount: (selectedPeriod.usage / selectedPeriod.usageLimit) * 100,
          overage: false,
        } as IUsageMessage;
      } else {
        return {
          message: (
            <>
              Usage exceeded by{" "}
              {selectedPeriod.usage - selectedPeriod.usageLimit}{" "}
              {selectedPeriod.usage !== 1 ? pluralUsageType : usageType} for
              this billing period.
            </>
          ),
          amount: (selectedPeriod.usage / selectedPeriod.usageLimit) * 100,
          overage: true,
        } as IUsageMessage;
      }
    }
  }

  const usageMessage: IUsageMessage = getUsageMessage(props.aggregate);

  return (
    <div className={getComponentName()}>
      {isSpreadsheetUser && usageLimit === -1 ? (
        <></>
      ) : (
        <ProgressBar
          className="progress-sm"
          now={usageMessage.amount}
          variant={usageMessage.overage ? "danger" : undefined}
        />
      )}
      <div
        className={classNames("mt-2 mb-0 usage-subtext", {
          "overage-text": usageMessage.overage,
          "bottom-divider": props.bottomDivider,
        })}
        data-testid="usage-bar-message"
      >
        {usageMessage.message}
      </div>
    </div>
  );
};
