import { useContext, useEffect, useMemo, useState } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { IndeterminateCheckbox } from "../../../../../components/tables/IndeterminateCheckbox";
import { RequestType } from "../../../../../components/withAPI";
import {
  AssetType,
  IDerivedView,
  IDerivedViewList,
} from "../../../../../models";
import { IStepBodyProps } from "../../../../../components/wizard/components/StepWizardStep";

import { StepNames } from "./StepNames";
import { useAPI } from "../../../../../components/useAPI";
import { AddAssetWizardContext } from "../AddAssetWizardContext";
import Loader from "../../../../../components/Loader";
import { ToastrSuccess } from "../../../../../services/toastrService";
import { useParams } from "react-router-dom";
import SelectableRowTable from "../../../../../components/tables/SelectableRowTable";
import { sortAssetsByAlias } from "../../ObjectSorter";
import { IDataAssetDerivedViewBulkCreate } from "../../../../../models/Datasets/IDataAssetDerivedViewBulkCreate";
import {
  ButtonType,
  CDataButton,
} from "../../../../../components/buttons/CDataButton";
import { IDatasetBulkAddError } from "../DatasetBulkAddErrorAccordion";
import { StepWizardStepFooter } from "../../../../../components/wizard/components/StepWizardStepFooter";
import { StepWizardContext } from "../../../../../components/wizard/components/StepWizardContext";

interface ISelectDerivedViewsProps extends IStepBodyProps {
  setAddAssertErrors: (errors: IDatasetBulkAddError[]) => void | null;
}

export const SelectDerivedViews = (props: ISelectDerivedViewsProps) => {
  const { setAddAssertErrors } = props;

  const [derivedViews, setDerivedViews] = useState<IDerivedView[]>([]);
  const [selectedDerivedViews, setSelectedDerivedViews] = useState<
    IDerivedView[]
  >([]);
  const [loading, setLoading] = useState(true);
  // True while we're saving the selected derived views, disables the Confirm button to prevent
  // duplicate submission which will add the same derived views multiple times.
  const [disableConfirmButton, setDisableConfirmButton] = useState(false);

  const addAssetWizardContext = useContext(AddAssetWizardContext);
  const { toggleModal } = useContext(StepWizardContext);
  const api = useAPI();
  const workspaceId = useParams().workspaceId;

  useEffect(() => {
    async function getInitialData() {
      await fetchDerivedViews();
    }

    getInitialData();
  }, []); // eslint-disable-line

  async function fetchDerivedViews(): Promise<IDerivedView[] | null> {
    const { status, payload } = await api.callAPI<IDerivedViewList>(
      RequestType.Get,
      "/account/derivedViews",
      "Error fetching derived views:",
    );
    if (status === 200) {
      setDerivedViews(payload!.derivedViews!);
      setLoading(false);
      return payload!.derivedViews!;
    }

    return null;
  }

  async function createAssetFromDerivedView() {
    setDisableConfirmButton(true);
    // Clear out any existing errors while we save.
    setAddAssertErrors?.([]);

    try {
      const folderId = window.location.href.includes("folder")
        ? window.location.pathname.split("/").pop()
        : null;

      const body = {
        Records: selectedDerivedViews.map((derivedView) => {
          return {
            DerivedViewId: derivedView.id,
            ParentID: folderId,
          };
        }),
      };

      const { status, payload } =
        await api.callAPI<IDataAssetDerivedViewBulkCreate>(
          RequestType.Post,
          `/workspaces/${workspaceId}/assets/fromDerivedView/batch`,
          "Error creating folder:",
          body,
        );

      if (status === 200) {
        setAddAssertErrors?.(
          payload!.errors.map<IDatasetBulkAddError>((error) => {
            return {
              ...error,
              derivedView: selectedDerivedViews.find(
                (r) => r.id === error.derivedViewId,
              )!,
            };
          }),
        );

        addAssetWizardContext.setAssetList((prevAssetList) => {
          const newAssetList = [...prevAssetList];
          for (const createdAsset of payload!.createdDataAssets) {
            newAssetList.push({
              alias: createdAsset.alias,
              assetType: AssetType.Data,
              childCount: 0,
              description: createdAsset.description,
              driver: createdAsset.driver,
              id: createdAsset.id,
              sourceSchema: null!,
              sourceTable: null!,
            });
          }

          sortAssetsByAlias(newAssetList);
          return newAssetList;
        });

        if (payload!.createdDataAssets.length > 0) {
          ToastrSuccess(
            "Asset successfully added",
            `Successfully added asset(s) to ${addAssetWizardContext.containerName}`,
          );
        }
      }

      toggleModal();
    } finally {
      setDisableConfirmButton(true);
    }
  }

  const columns = useMemo<ColumnDef<IDerivedView>[]>(
    () => [
      {
        id: "select",
        header: ({ table }) => (
          <IndeterminateCheckbox
            checked={table.getIsAllRowsSelected()}
            indeterminate={table.getIsSomeRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
          />
        ),
        cell: ({ row }) => (
          <div className="px-1">
            <IndeterminateCheckbox
              checked={row.getIsSelected()}
              disabled={!row.getCanSelect()}
              indeterminate={row.getIsSomeSelected()}
              onChange={row.getToggleSelectedHandler()}
            />
          </div>
        ),
      },
      {
        accessorKey: "name",
        id: "name",
        header: () => <span>View Name</span>,
        cell: (info) => info.getValue(),
      },
    ],
    [],
  );

  function renderSelectDerivedViews() {
    return (
      <div>
        <div>
          Select the derived views from the list below that you want to add.
        </div>
        <SelectableRowTable
          data={derivedViews}
          columns={columns}
          checkedItems={selectedDerivedViews}
          sendCheckedItems={setSelectedDerivedViews}
          searchEnabled={true}
          className="select-derived-views-table data-assets-derived-views add-asset-wizard-table"
        />
        <StepWizardStepFooter>
          <CDataButton
            buttonType={ButtonType.Borderless}
            onClick={() => props.goToNamedStep!(StepNames.SelectAssetType)}
          >
            Back
          </CDataButton>
          <CDataButton
            buttonType={ButtonType.Primary}
            onClick={createAssetFromDerivedView}
            disabled={selectedDerivedViews.length === 0 || disableConfirmButton}
          >
            Confirm
          </CDataButton>
        </StepWizardStepFooter>
      </div>
    );
  }

  const contents = loading ? <Loader /> : renderSelectDerivedViews();

  return contents;
};
