import { useNavigate } from "react-router-dom";

import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";

import TertiaryButton from "../../../../components/buttons/TertiaryButton";
import { CDataCodeMirror } from "../../../../components/codemirror/CDataCodeMirror";

export interface ISQLTabProps {
  sourceCatalog: string;
  sourceSchema: string;
  sourceTable: string;
  assetQuery: string;
}

const SQLTab = (props: ISQLTabProps) => {
  const navigate = useNavigate();

  const handleCopy = () => {
    navigator.clipboard.writeText(props.assetQuery);
  };

  const tableObj: any = {
    type: "asset",
    name: null,
    query: null,
    meta: {
      connectionName: props.sourceCatalog,
      tableName: props.sourceTable,
      schemaName: props.sourceSchema,
    },
  };

  return (
    <Card className="sql-tab">
      <CardBody>
        <Row className="sql-header">
          <Col>
            <CardTitle tag="h3" className="mb-0">
              SQL Code
            </CardTitle>
          </Col>
          <Col className="card-buttons">
            <span className="d-flex align-items-center">
              <TertiaryButton
                onClick={() =>
                  navigate("/data-explorer/", {
                    state: {
                      assetQuery: props.assetQuery,
                      clickedFromDatasets: true,
                      clickedFromDatasetsTable: tableObj,
                    },
                  })
                }
                data-testid="button-navigate"
              >
                <i className="fa fa-search me-1" />
                Explore Data
              </TertiaryButton>
            </span>
            <button
              type="button"
              color="secondary"
              className="copy-button ms-2"
              onClick={handleCopy}
            >
              <i className="fa fa-solid fa-copy" /> Copy
            </button>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <CDataCodeMirror sqlText={props.assetQuery} height="300px" />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default SQLTab;
