import classNames from "classnames";
import React from "react";

import {
  ButtonType,
  CDataButton,
  CDataButtonProps,
} from "../../../../../../../components/buttons/CDataButton";
import {
  CDataTooltip,
  CDataTooltipType,
} from "../../../../../../../components/CDataTooltip";

type QueryBuilderAddButtonProps = Omit<CDataButtonProps, "buttonType"> & {
  isQueryBuilderEmpty?: boolean;
};

export function QueryBuilderAddButton(props: QueryBuilderAddButtonProps) {
  const { children, className, ...otherProps } = props;
  // The span is just here to easily color the button.
  return (
    <>
      <CDataTooltip
        type={CDataTooltipType.Dark}
        title={
          props.isQueryBuilderEmpty
            ? "Add a table to begin customizing your query."
            : ""
        }
      >
        <span className={classNames("queryBuilderAddButton", className)}>
          <CDataButton
            buttonType={ButtonType.Borderless}
            disabled={props.isQueryBuilderEmpty}
            {...otherProps}
          >
            <i className="fa fa-solid fa-plus me-2" />
            {children}
          </CDataButton>
        </span>
      </CDataTooltip>
    </>
  );
}
