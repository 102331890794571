import React, { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import EmbeddedDocumentation from "../../../components/EmbeddedDocumentation";
import { Col, Container, Row } from "reactstrap";
import EditConnection from "../../connections/EditConnection";
import { useAppSelector } from "../../../redux/hooks";

/** This component is a wrapper around the editing a connection that handles the OEM-specific logic. */
export const EditOEMConnection = () => {
  const [searchParams] = useSearchParams();
  const { connectionId } = useParams();
  const user = useAppSelector((state) => state.user);

  useEffect(() => {
    if (user.logoColor) {
      const root = document.documentElement;
      root.style.setProperty("--bs-primary", user.logoColor);
    }
  }, [user.logoColor]);

  // This is the driver type, we need to load the real driver information here.
  const driverType = searchParams.get("driver")!;
  const redirectUrl = searchParams.get("redirectUrl")!;

  return (
    <Container fluid className="pages-edit-oem-connection">
      <Row>
        <Col className="column-edit-connection">
          <EditConnection
            connectionId={connectionId}
            driverType={driverType}
            isOEMConnection={true}
            redirectUrl={redirectUrl}
          />
        </Col>
        <EmbeddedDocumentation driver={driverType} />
      </Row>
    </Container>
  );
};
