import React from "react";
import { UncontrolledTooltip, Row, Col } from "reactstrap";
import { Form } from "react-bootstrap";
import { IWorkspaceListItem } from "../../../../models";

interface IWorkspaceField {
  selectedWorkspace: IWorkspaceListItem | undefined;
  setSelectedWorkspace: (workspace: IWorkspaceListItem) => void;
  workspaces: IWorkspaceListItem[];
  containerClassName?: string;
}

const WorkspaceField = (props: IWorkspaceField) => {
  const workspaceDropdownOptions = props.workspaces.map(
    (workspace: IWorkspaceListItem) => (
      <option key={workspace.name} value={workspace.name}>
        {workspace.name}
      </option>
    ),
  );

  workspaceDropdownOptions.unshift(
    <option key={-1} value={-1} hidden>
      Select
    </option>,
  );

  const workspaceTooltip =
    props.workspaces.length === 0 ? (
      <UncontrolledTooltip
        placement="top"
        target="selectWorkspaceField"
        autohide={false}
      >
        {"Looks like you haven't created your first Workspace. Navigate to \""}
        <a
          className="text-light text-decoration-underline"
          onClick={() => window.open("/datasets")}
        >
          Virtual Datasets
        </a>
        {'" to begin organizing your data.'}
      </UncontrolledTooltip>
    ) : null;

  return (
    <Row className={props.containerClassName || "my-3"}>
      <Col className="label-col">
        <div className="client-endpoint-label">Workspace:</div>
      </Col>
      <Col className="input-col">
        <Form.Control
          id="selectWorkspaceField"
          name="selectWorkspaceField"
          as="select"
          className="form-select"
          value={props.selectedWorkspace?.name}
          disabled={props.workspaces.length === 0}
          onChange={(event: any) => {
            const newSelectedWorkspace = props.workspaces.find(
              (workspace: IWorkspaceListItem) =>
                workspace.name === event.target.value,
            );
            if (newSelectedWorkspace) {
              props.setSelectedWorkspace(newSelectedWorkspace);
            }
          }}
        >
          {workspaceDropdownOptions}
        </Form.Control>
        {workspaceTooltip}
      </Col>
    </Row>
  );
};

export default WorkspaceField;
