import { ReactNode, useState } from "react";
import { ResizableBox, ResizableBoxProps } from "react-resizable";
import "react-resizable/css/styles.css";

interface IResizableDiv {
  children: ReactNode;
  initialWidth?: number;
  initialHeight?: number;
  minHeight?: number;
  minWidth?: number;
  maxHeight?: number;
  maxWidth?: number;
  shouldResizeHorizontally?: boolean;
  shouldResizeVertically?: boolean;
  onHeightChange: (height: number) => void;
}

export default function ResizableDiv(props: IResizableDiv) {
  const {
    children,
    initialWidth,
    initialHeight,
    minHeight,
    minWidth,
    maxHeight,
    maxWidth,
    shouldResizeHorizontally,
    shouldResizeVertically,
    onHeightChange,
  } = props;

  const [width, setWidth] = useState(initialWidth ?? 300);
  const [height, setHeight] = useState(initialHeight ?? 150);

  const onResize: ResizableBoxProps["onResize"] = (_event, { size }) => {
    if (shouldResizeHorizontally) setWidth(size.width);
    if (shouldResizeVertically) {
      setHeight(size.height);
      onHeightChange(size.height);
    }
  };

  return (
    <ResizableBox
      width={shouldResizeHorizontally ? width : Infinity}
      height={shouldResizeVertically ? height : Infinity}
      minConstraints={[minWidth ?? 0, minHeight ?? 0]}
      maxConstraints={[maxWidth ?? Infinity, maxHeight ?? Infinity]}
      onResize={onResize}
      resizeHandles={["s"]}
    >
      {children}
    </ResizableBox>
  );
}
