/* eslint-disable max-lines */
import React, { Component } from "react";
import BootstrapTable, { SelectRowProps } from "react-bootstrap-table-next";
import filterFactory, { customFilter } from "react-bootstrap-table2-filter";

import { Button, Container, Input, InputProps, Spinner } from "reactstrap";

import { LinearProgress } from "@mui/material";

import Loader from "../../../components/Loader";
import { ODataContext } from "./ODataContext";
import { CDataComponent } from "../../../utility/CDataComponent";
import {
  ODataEdmType,
  IODataSchema,
  IODataKey,
  IODataMetadata,
} from "../../../models";
import { ToastrError, ToastrSuccess } from "../../../services/toastrService";
import { RequestType, withAPI, IAPI } from "../../../components/withAPI";
import SelectSchemaTable from "./SelectSchemaTable";
import { SelectSchemaMode } from "../SelectSchemaMode";
import CustomTextFilter from "../../../components/CustomTextFilter";

export interface SelectTableRow {
  fullName: string;
  connectionName: string;
  schemaName: string;
  defaultTableName: string;
  tableType: string;
}

export function fixInvalidIdentifierCharacter(tableName: string) {
  /* Naming Rules for OData Identifier Names
  odataIdentifier = identifierLeadingCharacter * 127identifierCharacter
  identifierLeadingCharacter = ALPHA / "_"; plus Unicode characters from the categories L or Nl
  identifierCharacter = ALPHA / "_" / DIGIT; plus Unicode characters from the categories L, Nl, Nd, Mn, Mc, Pc, or Cf*/

  const leadingCharacter = tableName.charAt(0);
  // First the below logic will replace the invalid leading character with _
  tableName = /^[^a-zA-Z_\p{L}\p{Nl}]/u.test(leadingCharacter)
    ? "_" + tableName.slice(1)
    : tableName;

  return tableName.replace(
    /[^\p{L}\p{Nl}\p{Nd}\p{Mn}\p{Mc}\p{Pc}\p{Cf}]/gu,
    (char) => {
      const encodedChar = char.codePointAt(0)!.toString(16).toUpperCase();
      return `_${encodedChar}_`; // Any occurrences of the invalid `char` character are replaced with underscores and the `encodedChar`.
    },
  );
}

interface ISelectTablesProps extends IAPI {
  previousStep?: () => void;
  currentStep?: number;
  goToNamedStep?: (namedStep: string) => void;
  toggleOdataModal: () => void;
  setAlreadyExistingTables: (metadata: IODataMetadata | null) => void;
  setFailedTables: (
    failedTables: { id: string; table: string; message: any }[],
  ) => void;
  onCompletion: () => void;
  odataExists: boolean;
  setModalBackButton: (backButton: JSX.Element) => void;
  setModalNextButton: (nextButton: JSX.Element) => void;
  setIsModalFooterDisplayed: (isModalFooterDisplayed: boolean) => void;
  entityType: string;
  setEntityType: (entityType: string) => void;
}
interface ISelectTablesState {
  schema: IODataSchema[];
  schemaName: string;
  selectedTables: SelectTableRow[];
  selectedTableNames: string[];
  progress: number;
  progressMessage: string;
  processingRequest: boolean;
  loading: boolean;
}

class SelectTables
  extends Component<ISelectTablesProps, ISelectTablesState>
  implements CDataComponent
{
  constructor(props: ISelectTablesProps) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      schema: [],
      schemaName: "",
      selectedTables: [],
      selectedTableNames: [],
      progress: 0,
      progressMessage: "Preparing to add tables to OData",
      processingRequest: false,
      loading: false,
    };
  }
  public getComponentName() {
    return "pages-odata-Wizard-SelectTables";
  }

  componentDidMount() {
    if (!window.location.href.includes("initial-setup")) {
      this.props.setModalBackButton(
        <button
          key="selectConnectionButton"
          className="borderless-button"
          onClick={() =>
            this.props.entityType === "Views"
              ? this.props.goToNamedStep!("SelectEntitySetType")
              : this.props.previousStep!()
          }
          data-testid="button-back"
        >
          Back
        </button>,
      );
    }
    this.props.setModalNextButton(
      <Button
        key="confirmTables"
        color="primary"
        disabled
        onClick={this.handleSubmit}
        data-testid="button-confirm"
      >
        Confirm
      </Button>,
    );
    this.props.setIsModalFooterDisplayed(true);
  }

  static contextType = ODataContext;
  context!: React.ContextType<typeof ODataContext>;

  async getColumns(
    connectionName: string,
    schemaName: string,
    defaultTableName: string,
  ) {
    const searchParams = new URLSearchParams({
      catalogName: connectionName,
      schemaName: schemaName,
      tableName: defaultTableName,
    });
    const columnURL = `/columns?${searchParams}`;
    const { status, payload } = await this.props.callAPI(
      RequestType.Get,
      columnURL,
      "",
    );
    if (status === 200) {
      return payload;
    } else {
      return null;
    }
  }

  async createODataContainer() {
    const { status, error } = await this.props.callAPI(
      RequestType.Put,
      "/account/dataBlobStorage",
      "",
    );
    if (status === 200) {
      return true;
    } else {
      // TODO: Clean up this error handling
      ToastrError("Failed to create blob container", error.error);
      return false;
    }
  }

  isHeaderCheckboxIndeterminate = () => {
    const tables = this.context.availableTables;
    const selectedTables = this.state.selectedTables;
    const element = document.getElementById(
      "checkAllTablesWizard",
    ) as HTMLInputElement;
    if (element) {
      if (selectedTables.length > 0 && selectedTables.length < tables.length) {
        element.indeterminate = true;
      } else {
        element.indeterminate = false;
      }
    }
  };

  enableConfirmButton = (checkedTablesList: SelectTableRow[]) => {
    this.setState({ selectedTables: checkedTablesList });
    if (checkedTablesList.length > 0) {
      this.props.setModalNextButton(
        <Button key="confirmTables" color="primary" onClick={this.handleSubmit}>
          Confirm
        </Button>,
      );
    } else {
      this.props.setModalNextButton(
        <Button key="confirmTables" color="primary" disabled>
          Confirm
        </Button>,
      );
    }
  };

  checkForDuplicateAlias(alias: string) {
    const entitySets =
      this.context.metadata!.dataServices!.schema!.entityContainer!.entitySets;
    let updatedAlias = alias;
    let numberOfDuplicates = 0;
    while (
      entitySets!.find(
        (table) => table.name.toLowerCase() === updatedAlias.toLowerCase(),
      )
    ) {
      numberOfDuplicates++;
      updatedAlias = `${alias}_${numberOfDuplicates}`;
    }

    return updatedAlias;
  }

  async handleSubmit() {
    this.setState({ processingRequest: true });
    const selectedTables = this.state.selectedTables;
    const failedTables = [];
    for (const [index, table] of selectedTables.entries()) {
      const columnInfo = await this.getColumns(
        table.connectionName,
        table.schemaName,
        table.defaultTableName,
      );
      if (table.defaultTableName.includes(" ")) {
        table.defaultTableName = table.defaultTableName.replace(/\s+/g, "_");
      }
      table.defaultTableName = fixInvalidIdentifierCharacter(
        table.defaultTableName,
      );
      if (columnInfo.error) {
        failedTables.push({
          id: table.fullName,
          table: table.defaultTableName,
          message: columnInfo.error.message,
        });
        continue;
      }

      const schema = columnInfo.results[0].schema;
      const rows = columnInfo.results[0].rows;
      const keyIndex = schema.findIndex(
        (property: any) => property.columnName === "IS_KEY",
      );
      const nameIndex = schema.findIndex(
        (property: any) => property.columnName === "COLUMN_NAME",
      );
      const typeIndex = schema.findIndex(
        (property: any) => property.columnName === "TYPE_NAME",
      );
      const isNullableIndex = schema.findIndex(
        (property: any) => property.columnName === "IS_NULLABLE",
      );
      const maxLengthIndex = schema.findIndex(
        (property: any) => property.columnName === "COLUMN_SIZE",
      );

      const keyRow = rows.filter((row: any) => {
        return row[keyIndex] === true;
      });

      const key: IODataKey | null =
        keyRow.length > 0
          ? {
              propertyRefs: [{ name: keyRow[0][nameIndex] }],
            }
          : null;

      const properties = rows.map((row: any) => {
        // Remove invalid characters from column name/alias
        let columnAlias: string = row[nameIndex];
        columnAlias = fixInvalidIdentifierCharacter(columnAlias);

        if (
          ODataEdmType[row[typeIndex].toUpperCase() as keyof ODataEdmType] ===
          ODataEdmType.STRING
        ) {
          return {
            columnName: row[nameIndex],
            name: columnAlias,
            type:
              ODataEdmType[
                row[typeIndex].toUpperCase() as keyof ODataEdmType
              ] ?? ODataEdmType.STRING,
            nullable: row[isNullableIndex],
            maxLength: row[maxLengthIndex],
          };
        } else {
          return {
            columnName: row[nameIndex],
            name: columnAlias,
            type:
              ODataEdmType[
                row[typeIndex].toUpperCase() as keyof ODataEdmType
              ] ?? ODataEdmType.STRING,
            nullable: row[isNullableIndex],
          };
        }
      });

      const alias = this.checkForDuplicateAlias(table.defaultTableName);

      this.context.metadata!.dataServices!.schema!.entityContainer!.entitySets!.push(
        {
          fullName: table.fullName,
          name: alias,
          entityType: `CData.${alias}`,
          annotations: [],
          navigationPropertyBindings: [],
        },
      );

      if (key) {
        this.context.metadata!.dataServices!.schema!.entityTypes!.push({
          fullName: table.fullName,
          name: alias,
          baseType: undefined,
          key: key,
          properties: properties,
          navigationProperties: [],
        });
      } else {
        this.context.metadata!.dataServices!.schema!.entityTypes!.push({
          fullName: table.fullName,
          name: alias,
          baseType: undefined,
          key: undefined,
          properties: properties,
          navigationProperties: [],
        });
      }

      this.setState({
        progress: (index / selectedTables.length) * 100,
        progressMessage: `${index} out of ${selectedTables.length} tables added`,
      });
    }

    if (!this.props.odataExists) {
      await this.createODataContainer();
    }

    const connectionName = this.context.selectedConnection.name;

    const { status } = await this.props.callAPI(
      RequestType.Post,
      "/odataStorage/writeToBlob",
      "",
      this.context.metadata,
    );
    if (status === 200) {
      this.props.setAlreadyExistingTables(this.context.metadata);
      this.props.toggleOdataModal();
      if (failedTables.length > 0) {
        ToastrError(
          "Error",
          "There were issues adding some of the tables you selected. Please try again.",
        );
        this.props.setFailedTables(failedTables);
      }
      if (failedTables.length !== selectedTables.length) {
        ToastrSuccess(
          "Tables Added Successfully!",
          `You have successfully added tables from ${connectionName} to OData.`,
        );
      }

      await this.props.onCompletion();
    }

    this.setState({ processingRequest: false });
  }

  renderTablesList() {
    const tables = this.context.availableTables;

    const tableListColumns = [
      {
        dataField: "fullName",
        text: "Full Name",
        sort: false,
        hidden: true,
      },
      {
        dataField: "defaultTableName",
        text: this.props.entityType === "Views" ? "View Name" : "Table Name",
        sort: false,
        filter: customFilter({}),
        filterRenderer: (onFilter: (value: string) => void, column: any) => (
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            placeholder="Search by name..."
          />
        ),
      },
    ];
    const selectTableListRow: SelectRowProps<any> = {
      mode: "checkbox",
      clickToSelect: true,
      bgColor: "#dee2e6",
      selected: this.state.selectedTableNames,
      selectColumnStyle: () => {
        return {
          paddingTop: "0",
          paddingBottom: "0",
        };
      },
      selectionRenderer: (props) => (
        <Input
          type={props.mode as InputProps["type"]}
          checked={props.checked}
          onChange={(): any => {
            return null;
          }}
        />
      ),
      selectionHeaderRenderer: (props) => (
        <Input
          id="checkAllTablesWizard"
          type={props.mode as InputProps["type"]}
          checked={props.checked}
          ref={() => this.isHeaderCheckboxIndeterminate()}
          onChange={(): any => {
            return null;
          }}
        />
      ),
      onSelect: (row, isSelect) => {
        if (isSelect) {
          const selectedTables = [...this.state.selectedTables, row];
          const selectedTableNames = [
            ...this.state.selectedTableNames,
            row.fullName,
          ];
          this.setState({
            selectedTables: selectedTables,
            selectedTableNames: selectedTableNames,
          });
          this.props.setModalNextButton(
            <Button
              key="confirmTables"
              color="primary"
              onClick={this.handleSubmit}
            >
              Confirm
            </Button>,
          );
        } else {
          const selectedTables = this.state.selectedTables.filter(
            (table: SelectTableRow) => table.fullName !== row.fullName,
          );
          const selectedTableNames = this.state.selectedTableNames.filter(
            (fullName: string) => fullName !== row.fullName,
          );
          this.setState({
            selectedTables: selectedTables,
            selectedTableNames: selectedTableNames,
          });
          if (selectedTables.length === 0) {
            this.props.setModalNextButton(
              <Button
                key="confirmTables"
                color="primary"
                disabled
                onClick={this.handleSubmit}
              >
                Confirm
              </Button>,
            );
          }
        }
      },
      onSelectAll: (isSelect, rows) => {
        if (isSelect) {
          this.setState({
            selectedTables: rows,
            selectedTableNames: rows.map((row: any) => row.fullName),
          });
          this.props.setModalNextButton(
            <Button
              key="confirmTables"
              color="primary"
              onClick={this.handleSubmit}
            >
              Confirm
            </Button>,
          );
        } else {
          this.setState({
            selectedTables: [],
            selectedTableNames: [],
          });
          this.props.setModalNextButton(
            <Button
              key="confirmTables"
              color="primary"
              disabled
              onClick={this.handleSubmit}
            >
              Confirm
            </Button>,
          );
        }
      },
    };

    const tableList = (
      <BootstrapTable
        bootstrap4
        id="odataTableList"
        classes="odata-tables-list mb-0"
        bordered={false}
        keyField="fullName"
        columns={tableListColumns}
        data={tables}
        selectRow={selectTableListRow}
        filter={filterFactory()}
      />
    );

    return (
      <div>
        <div hidden={!this.state.processingRequest} className="mt-2">
          {this.state.progressMessage}
          <LinearProgress
            className="my-1"
            variant="determinate"
            value={this.state.progress}
          />
        </div>
        <div className="mt-3 mb-5">
          Select the{" "}
          {this.props.entityType === "Views"
            ? "derived views"
            : "tables and views"}{" "}
          from the list below that you want added to OData.
        </div>
        {tableList}
      </div>
    );
  }

  render() {
    const contents = this.state.loading ? (
      <Container fluid className="p-0">
        <Loader />
      </Container>
    ) : (
      this.renderTablesList()
    );

    return (
      <div className={this.getComponentName()}>
        <div hidden={!this.state.processingRequest}>
          <div className="loading-background-clear" />
          <Spinner className="spinner-border loading-spinner" color="info" />
        </div>
        {this.props.entityType === "Views" ? (
          contents
        ) : (
          <SelectSchemaTable
            enableConfirmButton={this.enableConfirmButton}
            setModalNextButton={this.props.setModalNextButton}
            mode={SelectSchemaMode.Odata}
          />
        )}
      </div>
    );
  }
}
export default withAPI(SelectTables);
