import { createTheme, Theme } from "@mui/material";

// This theme dynamically sets the field border & highlight colors depending on whether there's an error.
export function createAutocompleteTheme(isInvalid: boolean): Theme {
  return createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            border: isInvalid ? "1px solid #d9534f" : "1px solid #cad2da",
            "&.Mui-focused": {
              border: isInvalid ? "1px solid #d9534f" : "1px solid #80d0ff",
              boxShadow: isInvalid
                ? "0 0 0 0.2rem #f8c6c8"
                : "0 0 0 0.2rem RGB(0 160 255 / 25%)",
            },
          },
        },
      },
    },
  });
}
