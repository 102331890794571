import { MouseEventHandler } from "react";
import { Button } from "reactstrap";
import { CDataModalV2 } from "../../../components/modal/CDataModalV2";

interface IBatchRunJobsModal {
  isDisplayed: boolean;
  onBatchRun: MouseEventHandler<HTMLButtonElement>;
  setIsDisplayed: (displayed: boolean) => void;
}

export function BatchRunJobsModal(props: IBatchRunJobsModal) {
  const { isDisplayed, onBatchRun, setIsDisplayed } = props;

  if (!isDisplayed) return null;

  return (
    <CDataModalV2
      title="Run"
      close={() => setIsDisplayed(false)}
      displayed={isDisplayed}
      displayToggleCloseButton={true}
      primaryButton={
        <Button color="primary" onClick={onBatchRun}>
          Confirm
        </Button>
      }
      secondaryButton={
        <Button color="secondary" onClick={() => setIsDisplayed(false)}>
          Cancel
        </Button>
      }
    >
      You are about to initiate an immediate run of the selected jobs. Are you
      sure you want to proceed?
    </CDataModalV2>
  );
}
