import React, { useEffect } from "react";
import CDataIcon from "../assets/img/icons/CloudLogo.svg?react";
import Main from "../components/Main";
import { Col, Row } from "reactstrap";
import { Outlet } from "react-router-dom";
import Toshiba from "../assets/img/icons/ToshibaLogo.svg?react";
import Mitsubishi from "../assets/img/icons/MitsubishiLogo.svg?react";
import Syngenta from "../assets/img/icons/SyngentaLogo.svg?react";
import CMN from "../assets/img/icons/CMNLogo.svg?react";
import Belden from "../assets/img/icons/BeldenLogo.svg?react";
import Liberty from "../assets/img/icons/LibertyLogo.svg?react";
import BulletIcon1 from "../assets/img/icons/LayoutBulletIcon1.svg?react";
import BulletIcon2 from "../assets/img/icons/LayoutBulletIcon2.svg?react";
import BulletIcon3 from "../assets/img/icons/LayoutBulletIcon3.svg?react";
import BulletIcon4 from "../assets/img/icons/LayoutBulletIcon4.svg?react";

interface ISignUpLayoutProps {
  children: React.ReactNode;
}

const SignUpLayout: React.FC<ISignUpLayoutProps> = ({ children }) => {
  useEffect(() => {
    if (window.VITE_CONFIG.VITE_SIGNUP_URL) {
      window.location.href = window.VITE_CONFIG.VITE_SIGNUP_URL;
    }
  }, []);

  return (
    <>
      <Main className="pages-auth">
        <Row className="background-white" id="main-row">
          <Col
            sm={{ size: 6, order: 1 }}
            xs={{ size: 12, order: 2 }}
            className="left-side-display"
          >
            <span className="d-none d-sm-block">
              <CDataIcon className="cdata-logo" aria-description="CData Logo" />
            </span>
            <div className="bold-text d-none d-sm-block">
              All Your Data. All Your Apps. Connected through 1 Platform.
            </div>
            <div id="signup-subparagraph">
              Provide live access to disparate data without ETL.
            </div>
            <div id="signup-bigger-font">
              <Row className="bullet-point">
                <Col>
                  <BulletIcon1
                    className="align-middle me-3 no-pointer-event text-primary"
                    aria-description="connect"
                  />
                </Col>
                <Col>
                  <span className="bold-blue">Connect</span> instantly to any
                  data with pre-built connectors to 250+ data sources
                </Col>
              </Row>
              <Row className="bullet-point">
                <Col>
                  <BulletIcon2
                    className="align-middle me-3 no-pointer-event text-primary"
                    aria-description="access"
                  />
                </Col>
                <Col>
                  <span className="bold-blue">Access</span> live data in your
                  favorite analytics or workflow app without data
                  replication/ETL
                </Col>
              </Row>
              <Row className="bullet-point">
                <Col>
                  <BulletIcon3
                    className="align-middle me-3 no-pointer-event text-primary"
                    aria-description="empower"
                  />
                </Col>
                <Col>
                  <span className="bold-blue">Empower</span> 1,000s of users to
                  access data from any system while maintaining governance
                </Col>
              </Row>
              <Row className="bullet-point">
                <Col>
                  <BulletIcon4
                    className="align-middle me-3 no-pointer-event text-primary"
                    aria-description="start"
                  />
                </Col>
                <Col>
                  <span className="bold-blue">Start</span> in seconds by
                  creating an account to access disparate data in your favorite
                  app
                </Col>
              </Row>
            </div>
          </Col>
          <Col
            sm={{ size: 6, order: 2 }}
            xs={{ size: 12, order: 1 }}
            className="mx-auto d-table justify-content-center bg-white padding-mobile-layout"
          >
            <div className="d-table-cell align-middle">
              {children}
              <Outlet />
            </div>
          </Col>
        </Row>
        <div className="footer d-none d-sm-block">
          <Row className="footer-heading">
            <div> Trusted By </div>
          </Row>
          <Row className="footer-card">
            <Col>
              <Toshiba
                className="footer-branding b1"
                aria-description="toshiba"
              />
            </Col>
            <Col>
              <Mitsubishi
                className="footer-branding b2"
                aria-description="mitsubishi"
              />
            </Col>
            <Col>
              <Syngenta
                className="footer-branding b3"
                aria-description="syngenta"
              />
            </Col>
            <Col>
              <CMN className="footer-branding b4" aria-description="cmn" />
            </Col>
            <Col>
              <Belden
                className="footer-branding b5"
                aria-description="belden"
              />
            </Col>
            <Col>
              <Liberty
                className="footer-branding b6"
                aria-description="liberty"
              />
            </Col>
          </Row>
        </div>
      </Main>
    </>
  );
};

export default SignUpLayout;
