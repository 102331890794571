import { useState } from "react";

import { Col, Row } from "reactstrap";

import { SettingTabs } from "../../settings/Settings";
import { IWorkspaceListItem } from "../../../models";
import { CreatePATButton } from "../../../components/buttons/CreatePATButton";
import SelectResourceTypeButtons from "./components/SelectResourceTypeButtons";
import WorkspaceField from "./components/WorkspaceField";
import ConnectionFields from "./components/ConnectionFields";
import DatabaseField from "./components/DatabaseField";
import UrlField from "./components/UrlField";
import { useAppSelector } from "../../../redux/hooks";

export enum ModalPromptFieldTypes {
  Email,
  SingleField,
  MultiField,
}

export interface ModalPromptBodyRow {
  type?: ModalPromptFieldTypes;
  key?: string;
  value?: string;
  children?: { key: string; value: string }[];
}
export enum ResourceType {
  Connection = 0,
  Workspace = 1,
}

export interface IModalPromptBodyProps {
  idName: string;
  modalBodyValues: ModalPromptBodyRow[];
  workspaces: IWorkspaceListItem[];
  isOdata: boolean;
  isOpenApi: boolean;
  isRestApi?: boolean;
}

export default function ModalPromptBody(props: IModalPromptBodyProps) {
  const [selectedResourceType, setSelectedResourceType] =
    useState<ResourceType>(0);
  const userEmail = useAppSelector((state) => state.user?.email);
  const [selectedWorkspace, setSelectedWorkspace] =
    useState<IWorkspaceListItem>();

  const isWorkspaceTabSelected =
    selectedResourceType === ResourceType.Workspace;

  const areConnectionFieldsDisplayed =
    !props.isOdata &&
    (selectedResourceType === ResourceType.Connection ||
      (isWorkspaceTabSelected && selectedWorkspace));

  const selectionButtons =
    props.isOdata || props.isRestApi ? null : (
      <SelectResourceTypeButtons
        selectedResourceType={selectedResourceType}
        setSelectedResourceType={setSelectedResourceType}
      />
    );

  const workspaceField =
    (isWorkspaceTabSelected || props.isOdata) && !props.isRestApi ? (
      <WorkspaceField
        selectedWorkspace={selectedWorkspace}
        setSelectedWorkspace={setSelectedWorkspace}
        workspaces={props.workspaces}
      />
    ) : null;

  const connectionFields =
    areConnectionFieldsDisplayed && props.modalBodyValues ? (
      <ConnectionFields
        idName={props.idName}
        modalBodyValues={props.modalBodyValues}
        selectedResourceType={selectedResourceType}
        selectedWorkspaceName={selectedWorkspace?.name || ""}
        userEmail={userEmail}
      />
    ) : null;

  const urlField =
    props.isOdata && (selectedWorkspace || props.isRestApi) ? (
      <UrlField
        idName={props.idName}
        isOpenApi={props.isOpenApi}
        selectedResourceType={selectedResourceType}
        selectedWorkspaceName={selectedWorkspace?.name}
        selectedWorkspaceId={selectedWorkspace?.id || ""}
        isRestApi={props.isRestApi}
      />
    ) : null;

  const dataField = !props.isOdata ? (
    <DatabaseField
      idName={props.idName}
      isWorkspaceTabSelected={isWorkspaceTabSelected}
      selectedResourceType={selectedResourceType}
      selectedWorkspaceName={selectedWorkspace?.name}
      userEmail={userEmail}
    />
  ) : null;

  return (
    <div className="modal-prompt-body">
      <Row className="card-info">
        This client requires you to create a Personal Access Token. You can
        generate a Personal Access Token (PAT) on the
        <span className="p-0">
          <a
            className="card-setting"
            onClick={() =>
              window.open("/settings?defaultTab=" + SettingTabs.AccountSettings)
            }
          >
            {" "}
            Settings
          </a>
        </span>{" "}
        page. Copy this down, as it acts as your password during authentication.
      </Row>
      {selectionButtons}
      {workspaceField}
      {connectionFields}
      {urlField}
      {dataField}
      <Row>
        <Col className="label-col">
          <div className="client-endpoint-label">Password:</div>
        </Col>
        <Col className="input-col">
          <CreatePATButton />
        </Col>
      </Row>
    </div>
  );
}
