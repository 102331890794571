import { useState, useEffect, useContext, useMemo } from "react";
import { withAPI, IAPI } from "../../../components/withAPI";
import { ODataContext } from "./ODataContext";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  ColumnDef,
  getExpandedRowModel,
  getFilteredRowModel,
  Column,
} from "@tanstack/react-table";

import { Badge, Button, Spinner } from "reactstrap";

import { SelectTableRow } from "./SelectTables";
import { IndeterminateCheckbox } from "../../../components/tables/IndeterminateCheckbox";
import { DataAssetContext } from "../../datasets/DataAssetContext";
import { IModalFunctions } from "../../../layouts/Dashboard";
import { SelectSchemaMode } from "../SelectSchemaMode";
import { useSchemaFinder } from "../../../components/useSchemaFinder";

interface ISelectSchemaTableProps extends IAPI, IModalFunctions {
  enableConfirmButton: (selectedTableRows: SelectTableRow[]) => void;
  setModalNextButton: (modalNextButton: JSX.Element | undefined) => void;
  mode: SelectSchemaMode;
}

type Schema = {
  SchemaName: string;
  ParentName: string | undefined;
  subRows?: Schema[];
};

const SelectSchemaTable = (props: ISelectSchemaTableProps): any => {
  const [formattedTables, setFormattedTables] = useState<any[]>([]);
  const [tablesCount, setTablesCount] = useState<{ [key: string]: number }>({});
  const odataContext = useContext(ODataContext);
  const dataAssetContext = useContext(DataAssetContext);
  const schemaFinder = useSchemaFinder();

  const initialData: any[] = [];

  function getComponentName() {
    return "select-schema-table";
  }

  useEffect(() => {
    props.setModalNextButton(
      <Button key="confirmTables" color="primary" disabled>
        Confirm
      </Button>,
    );

    if (data.length === 1) {
      autoExpand();
    }
  }, []); // eslint-disable-line

  if (odataContext.schemas && props.mode === SelectSchemaMode.Odata) {
    odataContext.schemas.forEach((currSchema) => {
      initialData.push({ SchemaName: currSchema, ParentName: undefined });
    });
  } else if (
    dataAssetContext.schemas &&
    props.mode === SelectSchemaMode.Datasets
  ) {
    dataAssetContext.schemas.forEach((currSchema) => {
      const currentSchemaSubRow: Schema[] = [];

      const currSchemaTables = dataAssetContext.allTables[currSchema as string];
      currSchemaTables?.forEach((tableName) => {
        currentSchemaSubRow.push({
          SchemaName: tableName,
          ParentName: currSchema,
          subRows: undefined,
        });
      });

      initialData.push({
        SchemaName: currSchema,
        ParentName: undefined,
        subRows: currentSchemaSubRow,
      });
    });
  }

  const [data, setData] = useState(initialData);
  const [tablesLoading, setTablesLoading] = useState(false);

  const columns = useMemo<ColumnDef<Schema>[]>(
    () => [
      {
        header: "Name",
        footer: (props) => props.column.id,
        columns: [
          {
            accessorFn: (row) => row.SchemaName,
            accessorKey: "schemaName",
            header: ({ table }) => (
              <>
                <IndeterminateCheckbox
                  {...{
                    checked: table.getIsAllRowsSelected(),
                    indeterminate: table.getIsSomeRowsSelected(),
                    onChange: table.getToggleAllRowsSelectedHandler(),
                  }}
                />
                Table Name
              </>
            ),
            cell: ({ row, getValue }) => (
              <>
                {row.depth === 0 ? (
                  <button className="expand-collapse-button">
                    {row.getIsExpanded() ? (
                      <i className="fa fa-chevron-down"></i>
                    ) : (
                      <i className="fa fa-chevron-right"></i>
                    )}
                  </button>
                ) : (
                  ""
                )}
                {
                  <IndeterminateCheckbox
                    {...{
                      checked: row.getIsSelected(),
                      indeterminate: row.getIsSomeSelected(),
                      onChange: row.getToggleSelectedHandler(),
                    }}
                  />
                }
                {getValue()}
              </>
            ),
            footer: (props) => props.column.id,
          },
        ],
      },
    ],
    [],
  );

  const [expanded, setExpanded] = useState({});
  const [rowSelection, setRowSelection] = useState({});

  const table = useReactTable({
    data,
    columns,
    state: {
      expanded,
      rowSelection,
    },
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    debugTable: true,
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    filterFromLeafRows: true,
    maxLeafRowFilterDepth: 1,
    enableSubRowSelection: true,
  });

  const flatRows = table.getSelectedRowModel().flatRows;

  //on check/selecting tables use effect.
  useEffect(() => {
    const checkedTables: any = flatRows;
    const currCheckedTablesList: any = [];

    checkedTables.forEach((selectedRow: any) => {
      //currCheckedTablesList = [[tableName, schemaName]]
      currCheckedTablesList.push([
        selectedRow.original.SchemaName,
        selectedRow.original.ParentName,
      ]);
    });
    const formattedCheckedTables: SelectTableRow[] = [];

    currCheckedTablesList.forEach((tableSchemaObj: any) => {
      formattedTables.forEach((schemaTableObj: any) => {
        if (schemaTableObj.Tables) {
          schemaTableObj.Tables.forEach((ele: any) => {
            const selectedConnectionName =
              props.mode === SelectSchemaMode.Odata
                ? odataContext.selectedConnection.name
                : dataAssetContext.selectedConnection.name;
            if (
              ele.connectionName === selectedConnectionName &&
              ele.defaultTableName === tableSchemaObj[0] &&
              ele.schemaName === tableSchemaObj[1]
            ) {
              formattedCheckedTables.push(ele);
            }
          });
        }
      });
    });

    //tableCount handling
    const updatedTablesCount: { [key: string]: number } = {};
    //updating count
    checkedTables.forEach((table: any) => {
      /*we only have to count number of tables selected, the tables are in depth 1, depth 0 have schema rows. Clicking select all button will also behave as if schema rows are also selected,
        hence giving wrong count, so we will only count for depth 1 rows i.e.tables.*/

      if (table.depth === 1) {
        const rowId = table.id.split(".").slice(0, -1).join(".");

        if (Object.prototype.hasOwnProperty.call(updatedTablesCount, rowId)) {
          //rowId index exist in tableCount array
          updatedTablesCount[rowId] = updatedTablesCount[rowId] + 1;
        } else {
          //rowId doesnt exist
          updatedTablesCount[rowId] = 1;
        }
      }
    });

    setTablesCount(updatedTablesCount);

    props.enableConfirmButton(formattedCheckedTables);
  }, [flatRows]); // eslint-disable-line

  async function setTablesFromSchema(tables: any, schema: string) {
    let schemaFound = false;
    formattedTables.forEach((schemaObj: any) => {
      if (schemaObj.SchemaName === schema) {
        schemaFound = true;
        //break;
      }
    });
    //schema not found, hence call made first time, add tables to formattedTables
    if (!schemaFound) {
      const newSchemaFormattedObj = { SchemaName: schema, Tables: tables };
      setFormattedTables((values) => [...values, newSchemaFormattedObj]);
    }
    const tablesArray = tables.map((el: any) => {
      return el.defaultTableName;
    });

    const tanstackTableData: any[] = [];
    tablesArray.forEach((ele: any) => {
      tanstackTableData.push({
        SchemaName: ele,
        ParentName: schema,
        subRows: undefined,
      });
    });

    const updatedCurrentSchemasTables: Schema[] = [];

    for (let index = 0; index < data.length; index++) {
      const ele = data[index];
      const currSchema = ele.SchemaName;
      if (currSchema === schema) {
        updatedCurrentSchemasTables.push({
          SchemaName: currSchema,
          ParentName: undefined,
          subRows: tanstackTableData,
        });
      } else {
        updatedCurrentSchemasTables.push(ele);
      }
    }

    setData(updatedCurrentSchemasTables);
  }

  //auto expand if only one schema
  async function autoExpand() {
    if (props.mode === SelectSchemaMode.Odata) {
      const conn =
        props.mode === SelectSchemaMode.Odata
          ? odataContext.selectedConnection.name
          : dataAssetContext.selectedConnection.name;
      const schema = data[0].SchemaName;
      setTablesLoading(true);
      const tables = await schemaFinder.get(
        `catalogName=${encodeURIComponent(conn)}&schemaName=${encodeURIComponent(schema)}`,
      );
      setTablesLoading(false);
      setTablesFromSchema(tables, schema);
    }

    setExpanded({ "0": true });
  }

  async function expandRowCustom(row: any, schemaName: any) {
    if (row.depth !== 0) {
      return;
    }

    if (props.mode === SelectSchemaMode.Odata) {
      //make table calls
      const conn =
        props.mode === SelectSchemaMode.Odata
          ? odataContext.selectedConnection.name
          : dataAssetContext.selectedConnection.name;
      setTablesLoading(true);
      const tables = await schemaFinder.get(
        `catalogName=${encodeURIComponent(conn)}&schemaName=${encodeURIComponent(schemaName)}`,
      );
      setTablesLoading(false);
      setTablesFromSchema(tables, schemaName);
    }

    const expandRow = { [row.id]: true };
    const updatedExpandedState = { ...expanded, ...expandRow };
    setExpanded(updatedExpandedState);
  }

  function collapseRowCustom(row: any) {
    const collapseRow = { [row.id]: false };
    const UpdatedCollapsedState = { ...expanded, ...collapseRow };
    setExpanded(UpdatedCollapsedState);
  }

  const filterValue = table.getColumn("schemaName")!.getFilterValue();

  function handleFilterAutoExpand() {
    if (!filterValue) {
      return;
    }

    const expandRows: { [key: string]: boolean } = {};
    const currentFilteredRows = table.getFilteredRowModel().rows;
    currentFilteredRows.forEach((row) => {
      expandRows[row.id] = true;
    });
    setExpanded(expandRows);
  }

  //custom functionality that we want on change in filter value. This will expand all filtered rows with the table result matching with the filtered vlaue.
  useEffect(() => {
    if (props.mode === SelectSchemaMode.Datasets) {
      setExpanded({});
      const timer = setTimeout(() => {
        if (filterValue) {
          handleFilterAutoExpand();
        }
      }, 2000);

      //resetting timer if useEffect is executed again before previous execution can be completed.
      return () => {
        clearTimeout(timer);
      };
    }
  }, [filterValue]); // eslint-disable-line

  return (
    <div className={getComponentName()}>
      <div hidden={!tablesLoading}>
        <div className="loading-background" />
        <Spinner className="spinner-border loading-spinner" color="info" />
      </div>
      <table>
        <thead>
          <span className="select-table-desc">
            Select the tables and views from the list below that you want added
            to {props.mode === SelectSchemaMode.Datasets ? "Catalog" : "OData"}.
          </span>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <>
                  {header.column.getCanFilter() ? (
                    <div>
                      <Filter
                        column={header.column}
                        handleFilterAutoExpand={handleFilterAutoExpand}
                      />
                    </div>
                  ) : null}

                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </th>
                </>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} className={row.depth === 0 ? "schema-row" : ""}>
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  onClick={() => {
                    row.getIsExpanded()
                      ? collapseRowCustom(row)
                      : expandRowCustom(row, cell.getValue());
                  }}
                >
                  <div className={row.depth === 1 ? "subRows" : ""}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}

                    {tablesCount[row.id] ? (
                      <span>
                        <Badge className="tables-counter-badge">
                          {tablesCount[row.id]} Selected
                        </Badge>
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

function Filter({
  column,
  handleFilterAutoExpand,
}: {
  column: Column<any, any>;
  handleFilterAutoExpand: () => void;
}) {
  const columnFilterValue = column.getFilterValue();

  function handleKeyDownEnter(event: any) {
    if (event.key === "Enter") {
      handleFilterAutoExpand();
    }
  }

  return (
    <>
      <i className="fa-regular fa-magnifying-glass align-middle me-2 search-glass"></i>
      <input
        type="text"
        value={(columnFilterValue ?? "") as string}
        onChange={(e) => column.setFilterValue(e.target.value)}
        placeholder={"Search..."}
        className="border shadow rounded search-bar"
        onKeyDown={(e) => handleKeyDownEnter(e)}
      />
    </>
  );
}

export default withAPI(SelectSchemaTable);
