import { useMemo } from "react";
import ListTable from "../../components/tables/ListTable";
import {
  ColumnDef,
  OnChangeFn,
  RowSelectionState,
} from "@tanstack/react-table";
import { IUserImpersonationSummary } from "../../models/Users/IUserImpersonationSummary";
import "./UsersTable.scss";
import {
  setImpersonatingUserId,
  setIsSupportImpersonationActive,
} from "../../services/userImpersonation";
import { useAPI } from "../../components/useAPI";
import { RequestType } from "../../components/withAPI";
import { ToastrError } from "../../services/toastrService";
import { IUserImpersonationRequest } from "../../models/Users/IUserImpersonationRequest";
import { useAppSelector } from "../../redux/hooks";

interface IUsersTable {
  selection: RowSelectionState;
  setSelection: OnChangeFn<RowSelectionState>;
  users: IUserImpersonationSummary[];
}

const formatDate = (dateString: string): string => {
  const date = new Date(dateString);

  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const day = String(date.getUTCDate()).padStart(2, "0");
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");
  const seconds = String(date.getUTCSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds} UTC`;
};

export const UsersTable = (props: IUsersTable) => {
  const user = useAppSelector((state) => state.user);
  const api = useAPI();
  async function startImpersonationSession(rowData: IUserImpersonationSummary) {
    if (!user) {
      ToastrError("Error fetching userInfo", "No user information available.");
      return;
    }

    const selectedUserId = rowData.userId;

    const data: IUserImpersonationRequest = {
      impersonatedUserId: selectedUserId,
    };

    const { status } = await api.callAPI(
      RequestType.Put,
      `/users/self/impersonate`,
      "Failed to set user impersonation id.",
      data,
    );

    if (status !== 200) {
      ToastrError(
        "Error updating user impersonation id",
        "Failed to set user impersonation id.",
      );
      return;
    }

    setImpersonatingUserId(selectedUserId);
    setIsSupportImpersonationActive(true);

    window.location.href = "/";
  }

  const columns = useMemo<ColumnDef<IUserImpersonationSummary>[]>(
    () => [
      {
        accessorKey: "email",
        id: "email",
        enableSorting: true,
        header: () => <span>User</span>,
        cell: ({ row }) => {
          return row.original.email;
        },
      },
      {
        accessorKey: "organization",
        id: "organization",
        enableGlobalFilter: false,
        enableSorting: true,
        header: () => <span className="hide-overflow-text">Organization</span>,
        cell: ({ row }) => {
          return row.original.organization;
        },
      },
      {
        accessorKey: "consentExpirationDate",
        id: "consentExpirationDate",
        enableGlobalFilter: false,
        enableSorting: true,
        header: () => <span>Support Access Expires</span>,
        cell: ({ row }) => {
          return formatDate(row.original.consentExpirationDate);
        },
      },
      {
        accessorKey: "buttons",
        id: "buttons",
        enableSorting: false,
        header: () => <></>,
        meta: {
          className: "buttons-column",
        },
        cell: ({ row }) => {
          return (
            <span
              className="d-flex justify-content-end icon-padding-right"
              onClick={() => startImpersonationSession(row.original)}
              style={{ cursor: "pointer" }}
            >
              <i className="fa fa-regular fa-arrow-right" />
            </span>
          );
        },
      },
    ],
    // TODO: CLOUD-12788: Fix this dependency array
    [], // eslint-disable-line
  );

  return (
    <span className="pages-impersonation-users-table" data-testid="table-users">
      {props.users ? (
        <ListTable
          columns={columns}
          data={props.users}
          emptyTableMessage="No users available to impersonate."
          enableFiltering={true}
          enablePagination={true}
          enableCheckboxes={false}
          searchPlaceholder={"Search users..."}
          selection={props.selection}
          setSelection={props.setSelection}
          defaultSort={[]}
        />
      ) : null}
    </span>
  );
};
