export enum ViewMode {
  List,
  Grid,
}

export enum EmptyMessages {
  WorkspacesNoData = 'Click "Add" to set up your first Workspace.',
  WorkspacesNoMatches = "No workspaces match your search criteria.",
  AssetsNoData = 'Click "Add" to begin adding assets to your Workspace.',
  AssetsNoMatches = "No assets match your search criteria.",
  FolderNoData = 'Click "Add" to begin adding assets to your Folder.',
}
