export const PER_ROW_REQUEST_DETECTED_REGEX = /\{.*\}/;
export const VALID_UNIQUE_RECORD_IDENTIFIER_REGEX =
  /\{([a-zA-Z_][a-zA-Z0-9_]{0,98})\}/;
export const DUPLICATE_REQUEST_URL_FIELDS = /\{([^{}]+)\}.*\{\1\}/;

export const PER_ROW_RECORD_IDENTIFIER_REGEX = /\{([^{}]*)\}/g;

export const isPerRowRequest = (requestUrl: string) => {
  return PER_ROW_REQUEST_DETECTED_REGEX.test(requestUrl);
};

export const isValidPerRowRequest = (requestUrl: string) => {
  return VALID_UNIQUE_RECORD_IDENTIFIER_REGEX.test(requestUrl);
};

export const hasPerRowRequestError = (requestUrl: string) => {
  return isPerRowRequest(requestUrl) && !isValidPerRowRequest(requestUrl);
};

export const duplicateRequestUrlFields = (requestUrl: string) => {
  return DUPLICATE_REQUEST_URL_FIELDS.test(requestUrl);
};

export const getPerRowValue = (requestUrl: string) => {
  const matches = requestUrl.matchAll(PER_ROW_RECORD_IDENTIFIER_REGEX);
  const values: string[] = [];

  for (const match of matches) {
    if (match[1]) {
      values.push(match[1]);
    }
  }

  return new Set(values);
};

export const getPerRowRequestValue = (requestUrl: string) => {
  const regex = new RegExp(VALID_UNIQUE_RECORD_IDENTIFIER_REGEX, "g");
  const matches = [...requestUrl.matchAll(regex)];
  return matches.map((match) => match[1]) ?? [];
};
