import React from "react";
import {
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  InputProps,
  Label,
} from "reactstrap";
import { PaginationField } from "./PaginationCard.constants";
import classnames from "classnames";
import { useFormikContext } from "formik";

interface IGlobalTextField {
  handlePaginationCardChange: InputProps["onChange"];
  property: PaginationField;
  propertyInArray: any;
  propIndex: number;
  type: "static" | "dynamic";
}

export const GlobalTextField = (props: IGlobalTextField) => {
  const { type, property, propertyInArray, propIndex } = props;
  const { handleBlur, touched, errors } = useFormikContext<any>();

  return (
    <FormGroup key={type + propIndex}>
      <Label
        data-testid={property.id}
        className={classnames("form-field-title", {
          required: property.required,
        })}
      >
        {property.label}
      </Label>
      <Input
        id={property.id}
        name={property.name}
        value={propertyInArray}
        onChange={props.handlePaginationCardChange}
        onBlur={handleBlur}
        invalid={Boolean(touched[property.name] && errors[property.name])}
      />
      <FormFeedback type="invalid">
        {errors[property.name]?.toString()}
      </FormFeedback>
      <FormText>{property.helperText}</FormText>
    </FormGroup>
  );
};
