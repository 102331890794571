import { useRef, useState } from "react";
import {
  Card,
  CardBody,
  CardText,
  Badge,
  UncontrolledTooltip,
} from "reactstrap";
import { hasOverflow } from "../ClientConnectivityFunctions";

export interface IComingSoonProps {
  idName: string;
  testId?: string;
  imageIcon: string;
  description: string;
  showBetaBadge: boolean;
}

const ComingSoonTile = (props: IComingSoonProps) => {
  const cardRef = useRef(null);
  const [showToolTip, setShowToolTip] = useState(false);

  return (
    <Card
      data-testid={props.testId}
      name={props.idName}
      id={props.idName}
      className="shadow-sm client-tools-card"
      onMouseOver={() => setShowToolTip(hasOverflow(cardRef.current))}
      onMouseOut={() => setShowToolTip(false)}
    >
      <div>
        <svg
          className="client-connectivity-icon client-connectivity-icon-disabled card-img-top img-thumbnail"
          viewBox="0 0 48 48"
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
        >
          <use xlinkHref={props.imageIcon}></use>
        </svg>
      </div>

      <CardBody className="card-body">
        <UncontrolledTooltip
          placement="top"
          target={`title-${props.idName}`}
          isOpen={showToolTip}
        >
          {props.description}
        </UncontrolledTooltip>
        <CardText
          id={`title-${props.idName}`}
          tag="h6"
          className="card=text text-truncate text-muted"
          ref={cardRef}
        >
          {props.description}
        </CardText>
      </CardBody>

      <div>
        <div className="action-card-container coming-soon">
          <Badge
            hidden={!props.showBetaBadge}
            color=""
            className="client-tools-beta-badge"
          >
            Beta
          </Badge>
          <a href={""} target="_blank" rel="noopener noreferrer">
            Coming Soon
          </a>
        </div>
      </div>

      <UncontrolledTooltip
        placement="top"
        target={props.idName}
        trigger="hover"
      >
        Coming soon!
      </UncontrolledTooltip>
    </Card>
  );
};

export default ComingSoonTile;
