import React from "react";
import {
  JSONTreeTableRow,
  JsonValueType,
} from "../../ParsingUtilities/JSON/parseJSONAsTreeTable";
import { XMLTreeTableRow } from "../../ParsingUtilities/XML/parseXMLAsTreeTable";

export type IRootPathTreeNode = {
  /** The raw xpath string in a format like `$.people.addresses`. */
  xpath: string;
  /**
   * How deep in the tree this object is, the lowest number is top-level fields.
   * Note that top-level fields may have a depth of 0 or a depth of 1, depending on
   * if a root path like `/` is valid
   */
  depth: number;
  /** The unique table name from the driver */
  tableName: string;
  /** The field name we display in the UI. */
  displayTableName: string;
  /** The API path in the format of `people/addresses` */
  pathDisplayName: string;
  /** Any child fields that can be selected as root paths. */
  childPaths: IRootPathTreeNode[];
};

interface IRootPathNodeProps {
  node: IRootPathTreeNode;
  treeTable: XMLTreeTableRow[] | JSONTreeTableRow[];
}

/**
 * A single node displayed in the `<CheckboxTree />` that lets the user
 * select a root path.
 */
export const RootPathNode = (props: IRootPathNodeProps) => {
  const { node, treeTable } = props;

  const row = findRowForNode(node, treeTable);

  const icon = getIconByType(row?.type ?? null);

  return (
    <div className="pages-apiConnector-components-RootPathNode">
      {icon?.length > 0 && (
        <i className={icon} data-testid="rootPathNode-icon" />
      )}
      <div className="rootPathNode-textContainer">
        <div className="rootPathNode-tableName">{node.displayTableName}</div>
        <div className="rootPathNode-path">{node.pathDisplayName}</div>
      </div>
    </div>
  );
};

/**
 * Get the icon for what type of root path this is.
 *
 * This will almost always be an array icon unless the API doesn't have any arrays,
 * then it will just show the root path as an object icon.
 */
function getIconByType(type: string | null): string {
  if (!type) {
    return "";
  }

  switch (type) {
    case JsonValueType.Object:
      return "fa-regular fa-brackets-curly type-icon";
    case JsonValueType.Array:
      return "fa-regular fa-brackets type-icon";
    default:
      return "";
  }
}

/**
 * Looks through the JSONTreeTableRows to find information about the path.
 *
 * E.x. if our path is `/people/addresses` this tries to find the same object in the JSONTreeTableRows so
 * we can find out information about the type of field this is (an object or an array).
 */
function findRowForNode(
  node: IRootPathTreeNode,
  treeTable: XMLTreeTableRow[] | JSONTreeTableRow[],
): XMLTreeTableRow | JSONTreeTableRow | undefined {
  let matchingRow = [...treeTable].find(
    (row) => row.path.replace(/\[0\]/g, "") === node.xpath,
  );

  if (matchingRow) {
    return matchingRow;
  }

  for (const row of treeTable) {
    matchingRow = findRowForNode(node, row.subRows ?? []);

    if (matchingRow) {
      return matchingRow;
    }
  }

  return undefined;
}
