import React from "react";
import { UncontrolledTooltip } from "reactstrap";

interface IListContainerProps {
  listOfTiles: any[];
}

function ListContainer(props: IListContainerProps) {
  return (
    <span className={(() => "components-FilterList")()}>
      <div className="list-container mt-1">
        {props.listOfTiles.map((row, index) => (
          <>
            {row.showToolTip ? (
              <UncontrolledTooltip target={`id-${index}`}>
                Contact your system administrator to request access to this
                workspace.
              </UncontrolledTooltip>
            ) : null}

            <div key={index} id={`id-${index}`}>
              {row.tile}
            </div>
          </>
        ))}
      </div>
    </span>
  );
}

export default ListContainer;
