const ClientToolsInfo = {
  tableauDesktop: {
    windows:
      "https://www.cdata.com/download/getfile.aspx?file=free/LFTJ-V/setup.exe&name=Tableau%20Connector%20for%20CData%20Connect&tag=Download-tableau",
    linux:
      "https://www.cdata.com/download/getfile.aspx?file=free/LFTJ-V/connector.zip&name=Tableau%20Connector%20for%20CData%20Connect&tag=Download-tableau",
    mac: "https://www.cdata.com/download/getfile.aspx?file=free/LFTJ-V/setup.dmg&name=Tableau%20Connector%20for%20CData%20Connect&tag=Download-tableau",
  },

  odbc: {
    windows:
      "https://www.cdata.com/download/getfile.aspx?file=free/LFDJ-A/setup.exe&name=ODBC%20Driver%20for%20CData%20Connect&tag=Download-odbc",
    mac: "https://www.cdata.com/download/getfile.aspx?file=free/LFDJ-M/setup.dmg&name=ODBC%20Driver%20for%20CData%20Connect&tag=Download-odbc",
    linux: {
      deb64:
        "https://www.cdata.com/download/getfile.aspx?file=free/LFDJ-U/setup.x86_64.deb&name=ODBC%20Driver%20for%20CData%20Connect&tag=Download-odbc",
      rpm64:
        "https://www.cdata.com/download/getfile.aspx?file=free/LFDJ-U/setup.x86_64.rpm&name=ODBC%20Driver%20for%20CData%20Connect&tag=Download-odbc",
    },
  },

  python: {
    windows:
      "https://www.cdata.com/download/getfile.aspx?file=free/LFVJ-X/setup_win.zip&name=Python%20Connector%20for%20CData%20Connect&tag=Download-python",
    linux:
      "https://www.cdata.com/download/getfile.aspx?file=free/LFVJ-X/setup_unix.zip&name=Python%20Connector%20for%20CData%20Connect&tag=Download-python",
    mac: "https://www.cdata.com/download/getfile.aspx?file=free/LFVJ-X/setup_mac.zip&name=Python%20Connector%20for%20CData%20Connect&tag=Download-python",
  },

  // As per docs these 2 client cards share the same download links.
  jbdcOrIbmCognos: {
    windows:
      "https://www.cdata.com/download/getfile.aspx?file=free/LFRJ-V/setup.exe&name=JDBC%20Driver%20for%20CData%20Connect&tag=Download-jdbc",
    linux:
      "https://www.cdata.com/download/getfile.aspx?file=free/LFRJ-V/setup.zip&name=JDBC%20Driver%20for%20CData%20Connect&tag=Download-jdbc",
    mac: "https://www.cdata.com/download/getfile.aspx?file=free/LFRJ-V/setup.dmg&name=JDBC%20Driver%20for%20CData%20Connect&tag=Download-jdbc",
  },
};

export default ClientToolsInfo;
