import { IDriverExtended } from "../../../../bffmodels/IDriverExtended";
import { compareStrings } from "../../../../utility/CompareStrings";
import { getAuthSchemeOAuthMapping } from "../ConnectionFunctions";
import {
  getSelectedAuthSchemeProperty,
  getSelectedAuthSchemeValue,
} from "./getSelectedAuthScheme";

/**
 * Returns true if this is an OAuth app driver or the user selected an Auth Scheme option that uses OAuth app.
 * OAuth app drivers and auth schemes do not display the sign in button, they typically take in either a client ID and secret
 * or a username and password.
 */
export function isOAuthAppDriver(driver: IDriverExtended): boolean {
  const authSchemeProperty = getSelectedAuthSchemeProperty(driver);

  // If the driver does not have an auth scheme dropdown, use the driver metadata
  // to determine if the driver only supports OAuth app.
  if (authSchemeProperty == null) {
    return driver.isOAuthApp;
  }

  const authScheme = getSelectedAuthSchemeValue(driver);

  return compareStrings(
    getAuthSchemeOAuthMapping(driver.name!, authScheme),
    "OAUTH_APP",
  );
}
