import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  ColumnDef,
  getExpandedRowModel,
  getFilteredRowModel,
  Row,
} from "@tanstack/react-table";
import { useEffect, useState, useMemo } from "react";
import { IWorkspace } from "../../../../models";
import { PopoverList } from "../../../../components/popover/PopoverList";
import { useDataExplorerWorkspaces } from "./useDataExplorerWorkspaces";
import useQueryTabs from "../../RightPanel/Tabs/useQueryTabs";
import classNames from "classnames";

export type IWorkspacesProps = {
  workspaces: IWorkspace[];
  searchQuery: string | null;
  disableTableClick: (disabled: boolean) => void;
  clickDisabled: boolean;
};

export type AssetSchema = {
  assetId: string;
  workspaceId: string;
  name: string;
  assetType: number;
  childCount: number;
  parentId: string;
  driver?: string;
  subRows?: AssetSchema[];
};

export type WorkspaceSchema = {
  assetType?: number;
  assetId?: string;
  workspaceId: string;
  name: string;
  driver?: string;
  subRows?: AssetSchema[];
};

export const WorkspacesList = (props: IWorkspacesProps) => {
  function getComponentName() {
    return "workspaces-list";
  }

  const initialData: WorkspaceSchema[] = [];
  const tabs = useQueryTabs();

  props.workspaces.forEach((workspace: IWorkspace) => {
    initialData.push({ workspaceId: workspace.id, name: workspace.name! });
  });

  const [data, setData] = useState(initialData);
  const [filteredData, setFilteredData] = useState(initialData);

  const dataExplorerWorkspacesFunctions = useDataExplorerWorkspaces(
    data,
    filteredData,
    setData,
    setFilteredData,
    props,
  );

  function getRowIcons(row: Row<WorkspaceSchema>) {
    const getExpandableRowIcons = (isFolder: boolean) => (
      <button className="expand-collapse-button ">
        {row.getIsExpanded() ? (
          <i className="fa fa-chevron-down"></i>
        ) : (
          <i
            className="fa fa-chevron-right"
            data-testId={isFolder ? "" : `expand-${row.original.workspaceId}`}
          ></i>
        )}

        {isFolder && <i className="fa-solid fa-folder"></i>}
      </button>
    );

    const getAssetIcon = () => {
      if (row.original.driver === "DerivedView") {
        return (
          <i
            className={classNames("fa-regular fa-border-none", {
              "align-dataAsset-icon": row.depth === 1,
              "align-icon": row.depth === 2,
            })}
          />
        );
      } else {
        return (
          <i
            className={classNames("fa-solid fa-table", {
              "align-dataAsset-icon": row.depth === 1,
              "align-icon": row.depth === 2,
            })}
          />
        );
      }
    };

    switch (row.depth) {
      case 0: // Workspace names
        return getExpandableRowIcons(false);
      case 1: // Folders or assets
        if (row.original.assetType === 0) {
          return getExpandableRowIcons(true);
        } else if (row.original.assetType === 1) {
          return getAssetIcon();
        }
        break;
      case 2: // Assets only
        return getAssetIcon();
    }
  }

  const columns = useMemo<ColumnDef<WorkspaceSchema>[]>(
    () => [
      {
        header: "Name",
        footer: (props) => props.column.id,
        columns: [
          {
            accessorFn: (row) => row.name,
            accessorKey: "Name",

            cell: ({ row, getValue }) => (
              <>
                {getRowIcons(row)}
                <span className="truncate">{getValue()}</span>
              </>
            ),
            footer: (props) => props.column.id,
          },
        ],
      },
    ],
    [],
  );

  // This useEffect hook is updating the state of the data based on changes to the searchQuery prop.
  useEffect(() => {
    const updatedWorkspaces: WorkspaceSchema[] = [];
    for (let index = 0; index < filteredData.length; index++) {
      const workspace = filteredData[index];
      const currWorkspaceName = workspace.name;
      if (
        props.searchQuery &&
        currWorkspaceName
          .toLowerCase()
          .includes(props.searchQuery.toLowerCase())
      ) {
        updatedWorkspaces.push(workspace);
      } else {
        const updatesAssetList: AssetSchema[] = [];
        if (workspace.subRows) {
          for (
            let subIndex = 0;
            subIndex < workspace.subRows.length;
            subIndex++
          ) {
            const asset = workspace.subRows[subIndex];
            const currAssetName = asset.name;
            if (
              props.searchQuery &&
              currAssetName
                .toLowerCase()
                .includes(props.searchQuery.toLowerCase())
            ) {
              updatesAssetList.push(asset);
            } else {
              if (asset.assetType === 0) {
                const subAssetList: AssetSchema[] = [];
                if (asset.subRows) {
                  for (
                    let subRowIndex = 0;
                    subRowIndex < asset.subRows.length;
                    subRowIndex++
                  ) {
                    const subAsset = asset.subRows[subRowIndex];
                    const currSubAssetName = subAsset.name;
                    if (
                      props.searchQuery &&
                      currSubAssetName
                        .toLowerCase()
                        .includes(props.searchQuery.toLowerCase())
                    ) {
                      subAssetList.push(subAsset);
                    }
                  }
                  if (subAssetList.length !== 0) {
                    asset.subRows = subAssetList;
                    updatesAssetList.push(asset);
                  }
                }
              }
            }
          }
          if (updatesAssetList.length !== 0) {
            workspace.subRows = updatesAssetList;
            updatedWorkspaces.push(workspace);
          }
        }
      }
    }

    if (props.searchQuery) {
      setData(updatedWorkspaces);
    } else {
      setData(initialData);
      setExpanded({});
    }
  }, [props.searchQuery]); // eslint-disable-line

  const [expanded, setExpanded] = useState({});

  const table = useReactTable({
    data,
    columns,
    state: {
      expanded,
    },
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    debugTable: true,
    enableRowSelection: true,
    filterFromLeafRows: true,
    maxLeafRowFilterDepth: 1,
    enableSubRowSelection: true,
  });

  async function expandRowCustom(row: any, name: any) {
    if (row.depth === 0) {
      const workspaceObject = data.find((workspace: any) => {
        if (workspace.name === name) {
          return true;
        }
        return false;
      });
      const workspaceId = workspaceObject!.workspaceId;
      await dataExplorerWorkspacesFunctions.fetchChildAssetFromWorkspace(
        workspaceId,
      );
      const expandRow = { [row.id]: true };
      const updatedExpandedState = { ...expanded, ...expandRow };
      setExpanded(updatedExpandedState);
    } else if (row.depth === 1) {
      let assetObject: AssetSchema | undefined = undefined;
      for (let index = 0; index < data.length; index++) {
        const assets = data[index].subRows;
        if (assets) {
          assetObject = assets.find((asset) => {
            if (asset.name === name) {
              return true;
            }
            return false;
          });
          if (assetObject) {
            break;
          }
        }
      }
      await dataExplorerWorkspacesFunctions.fetchChildAssetFromFolder(
        assetObject!.workspaceId,
        assetObject!.assetId,
      );
      const expandRow = { [row.id]: true };
      const expandParentRow = { [Math.trunc(row.id)]: true };
      const updatedExpandedState =
        row.original.assetType === 1
          ? { ...expanded, ...expandParentRow }
          : { ...expanded, ...expandParentRow, ...expandRow };
      setExpanded(updatedExpandedState);
    }
  }

  function collapseRowCustom(row: any) {
    let updatedCollapsedState = {};
    if (row.depth === 0) {
      const subRowId = [];
      for (let index = 0; index < row.subRows.length; index++) {
        if (row.subRows[index].id in expanded) {
          subRowId.push(row.subRows[index].id);
          const collapseRow = { [row.subRows[index].id]: false };
          updatedCollapsedState = { ...updatedCollapsedState, ...collapseRow };
        }
      }
    }
    const collapseRow = { [row.id]: false };
    updatedCollapsedState = {
      ...expanded,
      ...updatedCollapsedState,
      ...collapseRow,
    };
    setExpanded(updatedCollapsedState);
  }

  async function queryAsset(action: string, rowId: string) {
    const currentRow = table.getRow(rowId);
    const parentRow = table.getRow(currentRow.parentId!);

    const assetName = currentRow.original.name;
    const workspaceName =
      currentRow.depth === 1
        ? parentRow.original.name
        : table.getRow(parentRow.parentId!).original.name;
    const folderName =
      currentRow.depth === 1 ? "ROOT" : parentRow.original.name;
    tabs.AddWorkspaceTab(assetName, folderName, action, workspaceName);
  }

  function handleTableClick(rowId: string) {
    props.disableTableClick(true);
    queryAsset("open", rowId);
    props.disableTableClick(false);
  }

  function getRowClass(row: Row<WorkspaceSchema>) {
    let classNames = "table-name-text data-explorer-container";
    if ((row.depth === 1 && row.original.assetType === 1) || row.depth === 2) {
      classNames += props.clickDisabled
        ? " disable-table-click"
        : " cursor-pointer";
    }

    if (row.depth !== 0) {
      classNames += " subRows";
    }

    return classNames;
  }
  return (
    <div className={getComponentName()}>
      <table className="table table-borderless table-hover data-explorer-tab-list">
        <thead></thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} className={row.depth === 0 ? "schema-row" : ""}>
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  onClick={(e) => {
                    e.stopPropagation();
                    row.getIsExpanded()
                      ? collapseRowCustom(row)
                      : expandRowCustom(row, cell.getValue());
                  }}
                >
                  <div
                    className="table-name-text data-explorer-container"
                    title={row.original.name}
                  >
                    <div
                      className={getRowClass(row)}
                      onClick={
                        (row.depth === 1 && row.original.assetType === 1) ||
                        row.depth === 2
                          ? () => handleTableClick(row.id)
                          : undefined
                      }
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </div>
                    <div
                      id={"Popover-" + row.original.assetId}
                      data-testid={"Popover-" + row.original.assetId}
                      className={
                        (row.depth === 1 && row.original.assetType === 1) ||
                        row.depth === 2
                          ? "ellipsis-right"
                          : "hidden"
                      }
                    >
                      <i className="fa fa-ellipsis-v align-middle" />
                      <PopoverList
                        target={"Popover-" + row.original.assetId}
                        key={row.original.assetId}
                        popoverOptions={[
                          {
                            label: "Open",
                            action: () => queryAsset("open", row.id),
                          },
                          {
                            label: "Query",
                            action: () => queryAsset("query", row.id),
                          },
                        ]}
                      />
                    </div>
                  </div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
