import React, { ReactNode, useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionBody,
  AccordionHeader,
} from "reactstrap";
import ListTable from "./tables/ListTable";
import { ColumnDef } from "@tanstack/react-table";

export type IErrorTableRow = {
  name: ReactNode;
  errorMessage: ReactNode;
};

interface IErrorTableProps {
  title: string;
  entityName: string;
  data: IErrorTableRow[];
  className?: string;
}

export function ErrorTable(props: IErrorTableProps) {
  const [titleId, setTitleId] = useState<string>("0");

  const toggle = (titleId: string) => {
    titleId === "0" ? setTitleId("1") : setTitleId("0");
  };

  if (props.data.length < 1) {
    return null;
  }

  const columns: ColumnDef<IErrorTableRow>[] = [
    {
      accessorKey: "name",
      id: "name",
      enableSorting: true,
      header: () => <span>{props.entityName}</span>,
      meta: {
        className: "name-column",
      },
      cell: ({ row }) => {
        return <>{row.original.name}</>;
      },
    },
    {
      accessorKey: "errorMessage",
      id: "errorMessage",
      enableSorting: true,
      header: () => <span>Reason</span>,
      meta: {
        className: "error-message-column",
      },
      cell: ({ row }) => {
        return <>{row.original.errorMessage}</>;
      },
    },
  ];

  return (
    <div
      className={`error-table ${props.className}`}
      hidden={props.data.length === 0}
    >
      <Accordion toggle={() => toggle(titleId)} open={titleId}>
        <AccordionItem>
          <AccordionHeader targetId="1">{props.title}</AccordionHeader>
          <AccordionBody accordionId="1">
            <ListTable
              columns={columns}
              data={props.data}
              emptyTableMessage=""
              enableFiltering={false}
              enablePagination={true}
              enableCheckboxes={false}
            />
          </AccordionBody>
        </AccordionItem>
      </Accordion>
    </div>
  );
}
