import { useQuery } from "@tanstack/react-query";
import { CheckboxMultiSelect } from "../../../../../../../components/select/CheckboxMultiSelect";
import {
  getColumnsForTable,
  IColumnMetadata,
} from "../../../../../../../api/metadata/getColumnsForTable";
import { useMemo } from "react";

type QueryBuilderColumnMultiSelectProps = {
  onChange: (values: IColumnMetadata[]) => void;
  selectedValues: string[];
  table: {
    connectionName: string;
    schema: string;
    tableName: string;
  };
};

export function QueryBuilderColumnMultiSelect(
  props: QueryBuilderColumnMultiSelectProps,
) {
  const { table, selectedValues, onChange } = props;

  const { data, isLoading } = useQuery({
    queryKey: ["/columns", table.connectionName, table.schema, table.tableName],
    queryFn: () => getColumnsForTable(table),
    meta: {
      errorMessage:
        "An error ocurred loading the columns for connection = " +
        table.connectionName,
    },
  });

  const values = data ?? [];

  const columnNameToColumnMetadata = useMemo<
    Map<string, IColumnMetadata>
  >(() => {
    if (data == null) {
      return new Map();
    }

    return new Map(data.map((d) => [d.columnName, d]));
  }, [data]);

  return (
    <CheckboxMultiSelect
      selectedValues={selectedValues}
      allItems={values.map((c) => c.columnName)}
      isLoading={isLoading}
      onChange={(columnNames) =>
        onChange(columnNames.map((c) => columnNameToColumnMetadata.get(c)!))
      }
    />
  );
}
