import DataLabelAndDropdown from "./DataLabelAndDropdown";
import { useFormikContext } from "formik";
import {
  IQueryBuilderJoin,
  QueryJoinType,
} from "../../../../../models/IQueryBuilderModel";
import { produce } from "immer";

type JoinTypeOption = {
  value: QueryJoinType;
  label: string;
};

const JoinTypeField = () => {
  const { setValues, values } = useFormikContext<IQueryBuilderJoin>();

  const joinTypes: JoinTypeOption[] = Object.values(QueryJoinType).map(
    (value) => ({
      value,
      label: value,
    }),
  );

  const currentJoinType: JoinTypeOption = {
    value: values.joinType,
    label: values.joinType,
  };

  function updateJoinType(type: JoinTypeOption) {
    setValues(
      produce(values, (draft) => {
        draft.joinType = type.value;
      }),
    );
  }

  const helperText = (
    <span className="helper-text">
      Inner Joins are the most commonly used type. For more on join types, visit
      our&nbsp;
      <a
        className="helper-text-link"
        href="https://cloud.cdata.com/docs/QueryBuilder.html"
        rel="noreferrer"
        target="_blank"
      >
        Documentation
      </a>
      .
    </span>
  );

  const isOptionEqualToValue = (
    option: JoinTypeOption,
    value: JoinTypeOption,
  ) => {
    return option.label === value.label && option.value === value.value;
  };

  return (
    <DataLabelAndDropdown<JoinTypeOption>
      handleChange={updateJoinType}
      helperText={helperText}
      isDisabled={false}
      isOptionEqualToValue={isOptionEqualToValue}
      label={"Join Type"}
      dropdownLabel={(option) => option.value}
      optionLabel={(option) => option.value}
      id={"joinType"}
      key={currentJoinType.value}
      options={joinTypes}
      value={currentJoinType}
    />
  );
};

export default JoinTypeField;
