import React from "react";
import { Button } from "reactstrap";
import { SettingTabs } from "../../pages/settings/Settings";

export const CreatePATButton = () => {
  return (
    <Button
      type="button"
      color="primary"
      className="card-actions d-flex flex-row align-items-center"
      onClick={() =>
        window.open(
          "/settings?defaultTab=" + SettingTabs.AccountSettings,
          "_blank",
        )
      }
      data-testid="button-create-pat"
    >
      <i className="fa fa-solid fa-plus fa-sm align-middle no-pointer-event me-1" />
      Create PAT...
    </Button>
  );
};
