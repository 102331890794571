import { MouseEventHandler } from "react";
import { jobsConstants } from "../Jobs.constants";
import { Button } from "reactstrap";
import { CDataModalV2 } from "../../../components/modal/CDataModalV2";

interface IDeleteJobModal {
  isBulk: boolean;
  isDisplayed: boolean;
  onDelete: MouseEventHandler<HTMLButtonElement>;
  setIsDisplayed: (displayed: boolean) => void;
}

export function DeleteJobModal(props: IDeleteJobModal) {
  const { isBulk, isDisplayed, onDelete, setIsDisplayed } = props;
  const message = isBulk
    ? jobsConstants.DELETE_MULTIPLE_JOBS
    : jobsConstants.DELETE_SINGLE_JOB;
  const buttonText = isBulk ? "Delete Jobs" : "Delete Job";

  if (!isDisplayed) return null;

  return (
    <CDataModalV2
      title={buttonText}
      close={() => setIsDisplayed(false)}
      displayed={isDisplayed}
      displayToggleCloseButton={true}
      primaryButton={
        <Button color="danger" onClick={onDelete}>
          {buttonText}
        </Button>
      }
      secondaryButton={
        <Button color="secondary" onClick={() => setIsDisplayed(false)}>
          Cancel
        </Button>
      }
      spacedFooter={false}
    >
      {message}
    </CDataModalV2>
  );
}
