import { compareStrings } from "../../../../../utility/CompareStrings";

const YOUTUBE_ANALYTICS = "youtubeanalytics";

export function isGoogleDriver(name: string) {
  const driverName = name?.toLowerCase();
  return (
    driverName?.includes("google") ||
    compareStrings(driverName, "gmail") ||
    compareStrings(driverName, YOUTUBE_ANALYTICS)
  );
}
