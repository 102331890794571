import { Button, Label } from "reactstrap";
import { IAzureOpenAIQueryResult } from "../../../models/OpenAI/IAzureOpenAIQueryResult";
import useQueryTabs from "../RightPanel/Tabs/useQueryTabs";
import { QueryTabType } from "../RightPanel/Tabs/queryTabType";
import { CDataCodeMirror } from "../../../components/codemirror/CDataCodeMirror";

interface IResponseField {
  aiResponse: IAzureOpenAIQueryResult | undefined;
  closeWizard: () => void;
  responseControlsDisabled: boolean;
}

export const ResponseField = (props: IResponseField) => {
  const error = props.aiResponse?.error;
  const response = props.aiResponse?.result?.trim() ?? "";

  const tabs = useQueryTabs();
  function copyToClipboardEndpoints() {
    navigator.clipboard.writeText(response);
  }

  function executeReturnedQuery() {
    tabs.AddEmptyTab(QueryTabType.Query, undefined, response, false, true);
    props.closeWizard();
  }

  return (
    <div className="mt-3" data-testid="component-response-field">
      <Label className={"mb-2"} style={{ fontWeight: "bold" }}>
        SQL Statement
      </Label>
      <div className="mb-3">
        <CDataCodeMirror sqlText={error ? "" : response} height="300px" />
      </div>
      <Button
        type="button"
        color="primary"
        style={{ position: "relative" }}
        className={"me-2"}
        onClick={executeReturnedQuery}
        disabled={response === undefined || props.responseControlsDisabled}
      >
        <i className="fa fa-sm fa-solid fa-play me-1" />
        Execute
      </Button>
      <Button
        type="button"
        color="secondary"
        className="copy-button"
        style={{ position: "relative" }}
        onClick={copyToClipboardEndpoints}
        disabled={response === undefined || props.responseControlsDisabled}
      >
        <i className="fa fa-sm fa-solid fa-copy me-1" />
        Copy
      </Button>
    </div>
  );
};
