import classNames from "classnames";
import { IPopoverOption } from "./PopoverList";
import { CDataTypography } from "../text/CDataTypography";

interface IPopoverItem {
  popoverOption: IPopoverOption;
  toggleIsDisplayed?: () => void;
}

export const PopoverItem = (props: IPopoverItem) => {
  const { label, action, disabled } = props.popoverOption;

  function handleEnabledClick() {
    if (props.toggleIsDisplayed) {
      props.toggleIsDisplayed();
    }
    action();
  }

  return (
    <div
      data-testid={props.popoverOption["data-testid"]}
      className={classNames("popover-item", {
        disabled: disabled,
      })}
      onClick={() => (disabled ? null : handleEnabledClick())}
      aria-disabled={disabled}
    >
      <CDataTypography>{label}</CDataTypography>
    </div>
  );
};
