import { createContext } from "react";
import { NotImplementedException } from "../../../utility/NotImplementedException";
import { IAssetListItem } from "../../../models";

export interface ISelectedConnection {
  driver: string;
  niceName: string;
  name?: string;
  connectionId?: string;
  properties?: { [key: string]: any };
}

export interface ISelectedWorkspace {
  name: string;
  id: string;
  tables: IAssetListItem[];
}

export interface IPersonalAccessToken {
  name: string;
  token: string;
  copied: boolean;
}

export interface IInitialSetupContext {
  selectedConnection: ISelectedConnection;
  setSelectedConnection: (connection: ISelectedConnection) => void;
}

export const InitialSetupContext = createContext<IInitialSetupContext>({
  selectedConnection: {} as ISelectedConnection,
  setSelectedConnection: () => NotImplementedException("Function"),
});
