import React, { useContext } from "react";
import useQueryTabs, { IQueryTab } from "../Tabs/useQueryTabs";
import { QueryTabsContext } from "../Tabs/QueryTabsContext";
import { createDeepCopy } from "../../../../utility/CreateDeepCopy";
import { CDataTypography } from "../../../../components/text/CDataTypography";
import { CDataTextField } from "../../../../components/form/CDataTextField";
import {
  ButtonType,
  CDataButton,
} from "../../../../components/buttons/CDataButton";
import { QueryTabType } from "../Tabs/queryTabType";
interface IDerivedViewEditor {
  tab: IQueryTab;
  derivedViewTitle: string;
  setDerivedViewTitle: (newDerivedViewTitle: string) => void;
  currentDerivedViewQuery: string;
  setCurrentDerivedViewQuery: (newQueryInput: string) => void;
  handleRunClick: React.MouseEventHandler<HTMLButtonElement>;
  queryEditorDisabled: boolean;
  rowLimitLockdown: boolean;
}

export const DerivedViewEditor = (props: IDerivedViewEditor) => {
  const { tab } = props;

  const tabContext = useContext(QueryTabsContext);
  const tabs = useQueryTabs();

  const updateQueryTab = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
  ) => {
    tabContext.setTabs((previousTabs) => {
      const newTabs = createDeepCopy(previousTabs);
      const rowToMutate = newTabs.find((tab) => tab.id === tab.id);
      if (rowToMutate) {
        rowToMutate.queryString = event.target.value;
        rowToMutate.unsavedChanges = true;
      }

      return newTabs;
    });
  };

  const openExecuteTab = () => {
    tabs.AddDerivedViewTab(
      tab.tabName,
      props.currentDerivedViewQuery,
      tab.uniqueId!,
      QueryTabType.DerivedView,
      "query",
      true,
      tab.id,
    );
  };

  return (
    <div className="derived-view-editor">
      <CDataTypography variant="typography-variant-card-title" className="mb-3">
        General
      </CDataTypography>
      <CDataTextField
        label="Name"
        value={props.derivedViewTitle}
        onChange={(event) => {
          props.setDerivedViewTitle(event.target.value);
          tabs.SetUnsavedChanges(tab.id, true);
        }}
        required
        className="mb-3"
      />
      <CDataTextField
        label="Query"
        value={props.currentDerivedViewQuery}
        onChange={(event) =>
          props.setCurrentDerivedViewQuery(event.target.value)
        }
        onBlur={(event) => updateQueryTab(event)}
        required
        multiline
        minRows={4}
        className="query-text-box mb-2"
      />
      <div>
        <CDataButton
          onClick={openExecuteTab}
          buttonType={ButtonType.Primary}
          className="me-1"
        >
          <i className="fa fa-solid fa-play me-2" />
          Execute
        </CDataButton>
        <CDataButton
          onClick={props.handleRunClick}
          buttonType={ButtonType.Tertiary}
        >
          <i className="fa fa-solid fa-rotate-right me-2" />
          Refresh
        </CDataButton>
      </div>
    </div>
  );
};
