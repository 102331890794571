import React, { MutableRefObject } from "react";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import {
  Card,
  CardBody,
  Col,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
  Badge,
} from "reactstrap";
import {
  APITableRequestMethod,
  APITableResponseType,
} from "../../../../models";
import {
  IConfigureTableInitialValues,
  configureTableInitialValues,
  configureTableValidationSchema,
  dummyTableSubmit,
} from "../SetTableValidationDictionary";
import {
  duplicateRequestUrlFields,
  isPerRowRequest,
  isValidPerRowRequest,
} from "./PerRowRequestHelper";
import { IAPIConnectorSettings } from "../types/IAPIConnectorSettings";
import { NotificationBar } from "../../../../components/notification/NotificationBar";
import { CDataRadioGroup } from "src/components/radio/CDataRadioGroup";
import { CDataRadio } from "src/components/radio/CDataRadio";

interface IConfigureTableCardProps {
  apiSettings: IAPIConnectorSettings;
  showChangeSettingsWarning: boolean;
  onChangeRequestUrl: (newUrl: string) => void;
  setShowChangeSettingsWarning: (showWarning: boolean) => void;
  formRef: MutableRefObject<FormikProps<IConfigureTableInitialValues>>;
}

enum TableCardFieldChange {
  RequestUrl = "requestUrl",
  ResponseType = "responseType",
  RequestMethod = "requestMethod",
}

export const ConfigureTableCard = (props: IConfigureTableCardProps) => {
  const {
    apiSettings,
    showChangeSettingsWarning,
    setShowChangeSettingsWarning,
    onChangeRequestUrl,
    formRef,
  } = props;
  const { tableSettings, setTableSettings, setUnsavedChanges } = apiSettings;

  const duplicateRequestFieldsDetected = duplicateRequestUrlFields(
    tableSettings.requestUrl,
  );
  function getComponentName() {
    return "pages-apiConnector-ConfigureTableCard";
  }

  function handleConfigureTableCardChange(
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: FormikHelpers<IConfigureTableInitialValues>["setFieldValue"],
    setFieldTouched?: FormikHelpers<IConfigureTableInitialValues>["setFieldTouched"],
  ) {
    const { value, name } = event.target;
    let parsedValue: any = value;
    const newTableSettings: any = { ...tableSettings };
    switch (name) {
      case TableCardFieldChange.RequestUrl:
        onChangeRequestUrl(parsedValue);
        newTableSettings["useQuerySlicer"] = isValidPerRowRequest(parsedValue);
        break;
      case TableCardFieldChange.ResponseType:
        parsedValue = Number(parsedValue);
        break;
      case TableCardFieldChange.RequestMethod:
        parsedValue = Number(value);
        if (setFieldTouched) {
          setFieldTouched("requestBody", false);
        }
        break;
    }

    newTableSettings[name] = parsedValue;
    setFieldValue(name, parsedValue);
    setTableSettings(newTableSettings);
    setUnsavedChanges(true);
  }

  function renderConfigureTableCard() {
    return (
      <Card data-testid="table-card">
        <Formik
          innerRef={formRef}
          initialValues={configureTableInitialValues(tableSettings)}
          validationSchema={configureTableValidationSchema()}
          onSubmit={dummyTableSubmit}
        >
          {({
            errors,
            handleBlur,
            handleSubmit,
            setFieldTouched,
            setFieldValue,
            touched,
          }) => (
            <Form onSubmit={handleSubmit}>
              <CardBody>
                <h5 className="card-title">Table</h5>
                {showChangeSettingsWarning && (
                  <NotificationBar
                    message="Changing any settings within this section may potentially break any previously configured settings in your Table Data."
                    onClose={() => setShowChangeSettingsWarning(false)}
                    barColor="notification-bar-orange"
                  />
                )}
                <FormGroup className="my-3">
                  <Label className="form-field-title required">Name</Label>
                  <Input
                    data-testid="input-table-name"
                    name="tableName"
                    value={tableSettings.tableName}
                    invalid={Boolean(touched.tableName && errors.tableName)}
                    onBlur={handleBlur}
                    onChange={(event) => {
                      handleConfigureTableCardChange(event, setFieldValue);
                    }}
                  ></Input>
                  <FormFeedback type="invalid">{errors.tableName}</FormFeedback>
                </FormGroup>
                <Row>
                  <div className="label-badge-container d-flex align-items-center mb-2">
                    <Label className="form-field-title required mb-0">
                      Request URL
                    </Label>
                    {isPerRowRequest(tableSettings.requestUrl) ? (
                      <Badge
                        color={"success"}
                        size="11"
                        className="per-row-request-badge align-middle"
                      >
                        Request Per Row Detected
                      </Badge>
                    ) : null}
                  </div>
                  <Col className="api-request-type">
                    <FormGroup>
                      <Input
                        data-testid="select-crud"
                        type="select"
                        className="request-method-selector"
                        name="requestMethod"
                        value={tableSettings.requestMethod}
                        onBlur={handleBlur}
                        onChange={(event) => {
                          handleConfigureTableCardChange(
                            event,
                            setFieldValue,
                            setFieldTouched,
                          );
                        }}
                      >
                        <option
                          key={APITableRequestMethod.GET}
                          value={APITableRequestMethod.GET}
                        >
                          GET
                        </option>
                        <option
                          key={APITableRequestMethod.POST}
                          value={APITableRequestMethod.POST}
                        >
                          POST
                        </option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col className="api-request-url">
                    <FormGroup>
                      <Input
                        data-testid="input-apiUrl"
                        name="requestUrl"
                        className="request-url"
                        value={tableSettings.requestUrl}
                        required={true}
                        placeholder={
                          tableSettings.useQuerySlicer
                            ? "e.g. https://domain.com/example/test/{ID}"
                            : "e.g. https://domain.com/example/test"
                        }
                        invalid={Boolean(
                          (touched.requestUrl && errors.requestUrl) ||
                            duplicateRequestFieldsDetected,
                        )}
                        onBlur={handleBlur}
                        onChange={(event) => {
                          handleConfigureTableCardChange(event, setFieldValue);
                        }}
                      ></Input>
                      {!duplicateRequestFieldsDetected &&
                        (!errors.requestUrl || !touched.requestUrl) && (
                          <FormText className="request-url-form-feedback">
                            {
                              "To connect to an API that requires a different request for each record, include the name of the column in curly brackets for the unique record identifier. For example, https://api.example.com/table/{id}."
                            }
                          </FormText>
                        )}
                      {(touched.requestUrl ||
                        duplicateRequestFieldsDetected) && (
                        <FormFeedback
                          type="invalid"
                          className="request-url-form-feedback"
                        >
                          {duplicateRequestFieldsDetected
                            ? "Duplicate unique record identifier detected within your Request URL. Remove or change the value of the duplicate to proceed."
                            : errors.requestUrl}
                        </FormFeedback>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                {tableSettings.requestMethod === APITableRequestMethod.POST ? (
                  <Row>
                    <Col>
                      <Label className="form-field-title">Body</Label>
                      <Input
                        type="textarea"
                        name="requestBody"
                        id="postBody"
                        value={tableSettings.requestBody}
                        className="mb-0"
                        onBlur={handleBlur}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>,
                        ) => {
                          handleConfigureTableCardChange(event, setFieldValue);
                        }}
                      />
                    </Col>
                    <FormText className="mt-0 mb-3">
                      Specify the data to be sent to the API as part of the API
                      Call. Define the format of the content by setting the
                      Content-Type HTTP Header. This field is required for most
                      APIs.
                    </FormText>
                  </Row>
                ) : null}
                <Row>
                  <h5 className="card-title">Response Type</h5>
                  <Col className="radio-buttons">
                    <CDataRadioGroup
                      row
                      value={tableSettings.responseType}
                      onBlur={handleBlur}
                      name="responseType"
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        handleConfigureTableCardChange(event, setFieldValue);
                      }}
                    >
                      <CDataRadio
                        value={APITableResponseType.XML}
                        label="XML"
                        data-testid="radio-xml"
                        className="me-3"
                      />
                      <CDataRadio
                        value={APITableResponseType.JSON}
                        label="JSON"
                        data-testid="radio-json"
                      />
                    </CDataRadioGroup>
                  </Col>
                </Row>
              </CardBody>
            </Form>
          )}
        </Formik>
      </Card>
    );
  }

  return (
    <div className={`p-0, ${getComponentName()}`}>
      {renderConfigureTableCard()}
    </div>
  );
};
