import React, { useEffect, useState } from "react";
import { Row, Col, Spinner, Button } from "reactstrap";
import { getSupportEmailAddress } from "../../../../utility/LocalizedEmailAddresses";

export const ResultsLoading = ({
  abortRunningQueryRequest,
}: {
  abortRunningQueryRequest: () => void;
}) => {
  const [elapsedTime, setElapsedTime] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setElapsedTime((prevElapsedTime) => prevElapsedTime + 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const formatElapsedTime = () => {
    const minutes = Math.floor(elapsedTime / 60);
    const seconds = elapsedTime % 60;

    if (minutes < 1) {
      return `${seconds} seconds`;
    } else if (minutes === 1) {
      return `${minutes} minute ${seconds} seconds`;
    } else {
      return `${minutes} minutes ${seconds} seconds`;
    }
  };

  return (
    <div id="results-loading-container" data-testid="results-loading-container">
      <Row id="results-loading-spinner-row">
        <Col className="centered-information">
          <Spinner
            data-testid="results-loading-spinner"
            id="results-loading-spinner"
            color="info"
          />
        </Col>
      </Row>
      <Row id="results-loading-info-row">
        <Col
          className="centered-information"
          data-testid="results-loading-message"
        >
          Keep this page open to ensure the completion of your query. If time
          elapsed surpasses 5 minutes, please reach out to&nbsp;
          <a href={`mailto:${getSupportEmailAddress()}`} className="underline">
            {getSupportEmailAddress()}
          </a>
          &nbsp;for further assistance.
        </Col>
      </Row>
      <Row id="results-loading-timer-row">
        <Col
          className="centered-information"
          data-testid="results-loading-timer"
        >
          <span className="bold">Time Elapsed:&nbsp;</span>{" "}
          {formatElapsedTime()}
        </Col>
      </Row>
      <Row className="mt-5">
        <Col className="centered-information">
          <Button
            color="secondary"
            aria-label="cancel-button"
            onClick={() => {
              abortRunningQueryRequest();
            }}
            disabled={false}
          >
            Cancel
          </Button>
        </Col>
      </Row>
    </div>
  );
};
