import { useEffect, useState } from "react";

import { IAPIConnectorProps } from "../../../../../../models";
import { Column, QueryInput } from "../../../SetTable";
import PreviewTable from "../../PreviewTable";
import { useAPI } from "../../../../../../components/useAPI";
import { mapAPIConnectorProps } from "../../../../ApiConnectorFactory";
import { mapColumns, mapTable } from "../../../SetTableFactory";
import { IAPIRowsRequest } from "../../../../../../models/Drivers/IAPIRowsRequest";
import { RequestType } from "../../../../../../components/withAPI";
import Loader from "../../../../../../components/Loader";
import { replaceAPITableSettingsURL } from "../../../replaceAPITableSettingsURL";
import { IAPIConnectorSettings } from "../../../types/IAPIConnectorSettings";
import { getPerRowValue } from "../../PerRowRequestHelper";
import { StepWizardStepFooter } from "../../../../../../components/wizard/components/StepWizardStepFooter";
import {
  ButtonType,
  CDataButton,
} from "../../../../../../components/buttons/CDataButton";
import { IStepBodyProps } from "../../../../../../components/wizard/components/StepWizardStep";

interface IPreviewTableStep extends IStepBodyProps {
  apiSettings: IAPIConnectorSettings;
  queryInputs: QueryInput[];
  selectedRootPaths: string[];
  selectedColumns: Column[];
  onSaveChanges: () => void;
}

export const PreviewTableStep = (props: IPreviewTableStep) => {
  const {
    apiSettings: { globalSettings, tableSettings },
    queryInputs,
    selectedRootPaths,
    selectedColumns,
    previousStep,
    onSaveChanges,
  } = props;

  const [loading, setLoading] = useState(true);
  const [apiResponse, setApiResponse] = useState<string[] | null>(null);

  const api = useAPI();

  async function getAPITablePreview() {
    const tableSettingsWithInputId = replaceAPITableSettingsURL(
      tableSettings,
      queryInputs,
    );
    const apiConnectorProps: IAPIConnectorProps = mapAPIConnectorProps(
      globalSettings,
      [mapTable(tableSettingsWithInputId)],
    );
    const rootPaths: IAPIRowsRequest["rootPaths"] = {};

    for (const rootPath of selectedRootPaths) {
      rootPaths[rootPath] = rootPath;
    }

    const apiColumnsParams: IAPIRowsRequest = {
      apiConnectorProps,
      rootPaths,
      columns: mapColumns(tableSettings.requestUrl, selectedColumns),
    };

    for (let i = 0; i < selectedColumns.length; ++i) {
      // For the GetAPIRows endpoint, we need to use the generated column name the driver gives us
      // instead of the user friendly, shorter name.
      // Without this, the wizard preview does not work correctly since it does not use the path like the regular preview
      apiColumnsParams.columns[i].path = selectedColumns[i].driverColumnName!;
    }

    const perRowColumns = getPerRowValue(tableSettings.requestUrl);

    tableSettings.pseudoColumns?.forEach((pseudoColumn, index) => {
      if (pseudoColumn.outputColumn === true) {
        apiColumnsParams.columns.push({
          name: pseudoColumn.pseudoColumnName,
          path: pseudoColumn.pseudoColumnName,
          dataType: pseudoColumn.dataType,
          isQuerySlicerKey: perRowColumns.has(pseudoColumn.pseudoColumnName),
          ordinal: selectedColumns.length + index,
          isKey: false,
        });
      }
    });

    const { status, payload } = await api.callAPI(
      RequestType.Post,
      "/getAPIRows",
      "",
      apiColumnsParams,
    );
    if (status === 200) {
      setApiResponse(payload);
    }
    setLoading(false);
  }

  useEffect(() => {
    getAPITablePreview();
  }, []); // eslint-disable-line

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <PreviewTable
          previewRows={apiResponse ?? []}
          columns={selectedColumns}
          pseudoColumns={tableSettings.pseudoColumns}
        />
      )}

      <StepWizardStepFooter>
        <CDataButton
          buttonType={ButtonType.Tertiary}
          onClick={() => previousStep!()}
        >
          Back
        </CDataButton>
        <CDataButton
          buttonType={ButtonType.Primary}
          onClick={() => onSaveChanges()}
        >
          Next
        </CDataButton>
      </StepWizardStepFooter>
    </>
  );
};
