import { getDriverIcon } from "../../../../../../../components/drivers/DriverIconFactory";
import { IConnection } from "../../../../../../../models/Connections/IConnection";

interface IQueryBuilderConnectionDropdownOptionProps {
  option: IConnection;
}

export const QueryBuilderConnectionDropdownOption = (
  props: IQueryBuilderConnectionDropdownOptionProps,
) => {
  const { option } = props;

  return (
    <span className="connection-dropdown-option">
      <span>{getDriverIcon(option.driver!, "driver-icon m-0 p-0")}</span>
      <span className="hide-overflow-text">{option.name}</span>
    </span>
  );
};
