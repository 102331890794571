import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { getDriverIcon } from "../../../../../../../components/drivers/DriverIconFactory";
import { IQueryBuilderColumn } from "../../../models/IQueryBuilderColumn";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { CSS } from "@dnd-kit/utilities";
import { IQueryBuilderSort } from "../../../models/IQueryBuilderModel";
import { useSortable } from "@dnd-kit/sortable";
import {
  ButtonType,
  CDataButton,
} from "../../../../../../../components/buttons/CDataButton";

enum SortDirectionString {
  "asc" = "Ascending",
  "desc" = "Descending",
}

interface ISortTile {
  index: number;
  querySort: IQueryBuilderSort;
  getSortId: (column: IQueryBuilderSort) => string;
  onClickEditSort: (index: number) => void;
  handleDelete: (joinIndex: number) => void;
}

export const SortTile = (props: ISortTile) => {
  const { index, querySort, getSortId, onClickEditSort, handleDelete } = props;

  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: getSortId(querySort) });

  const renderQueryBuilderColumn = (column: IQueryBuilderColumn) => {
    const displayTableName =
      column.alias ?? `${column.table.tableAlias}.${column.column}`;
    return (
      <Row>
        <Col className="driver-icon-col pe-0">
          {getDriverIcon(column.table.driver, "connection-icon m-0 p-0")}
        </Col>
        <Col>
          <Row>
            <Col className={"schema-name"}>{column.table.schema}</Col>
          </Row>
          <Row>
            <Col className={"table-and-column-name"}>{displayTableName}</Col>
          </Row>
        </Col>
      </Row>
    );
  };

  const tileButtons = (
    <div className="buttons-column pe-2">
      <CDataButton
        data-testid="edit-button"
        aria-label="Edit Sort"
        buttonType={ButtonType.Borderless}
        onClick={() => onClickEditSort(index)}
        className="p-0"
      >
        <i className="fa fa-md fa-regular fa-pencil" />
      </CDataButton>
      <CDataButton
        data-testid="delete-button"
        aria-label="Delete Sort"
        buttonType={ButtonType.Borderless}
        onClick={() => handleDelete(index)}
        className="p-0"
      >
        <i className="fa fa-md fa-solid fa-times" />
      </CDataButton>
    </div>
  );

  return (
    <Card
      ref={setNodeRef}
      className="query-builder-sort-tile mt-2 mb-0"
      style={{
        position: "relative",
        display: "inline-block",
        opacity: isDragging ? 0.7 : undefined,
        transform: CSS.Translate.toString(transform),
        transition,
      }}
    >
      <Row>
        <Col className="flex-grow-0 align-content-center pe-2">
          <span
            className="column-label mt-n2"
            style={{
              cursor: isDragging ? "grabbing" : "grab",
            }}
            {...attributes}
            {...listeners}
          >
            <i className="fa fa-solid fa-grip-dots-vertical" />
          </span>
        </Col>
        <Col>
          <Box sx={{ width: "100%" }}>
            <Grid container rowSpacing={1} columnSpacing={1} columns={13}>
              <Grid xs={6}>
                <span className="column-label">Sort Column</span>
              </Grid>
              <Grid xs={6}>
                <span className="column-label">Sort Type</span>
              </Grid>
              <Grid xs={1}></Grid>
              <Grid xs={6}>{renderQueryBuilderColumn(querySort.column)}</Grid>
              <Grid xs={6}>{SortDirectionString[querySort.direction]}</Grid>
              <Grid xs={1}>{tileButtons}</Grid>
            </Grid>
          </Box>
        </Col>
      </Row>
    </Card>
  );
};
