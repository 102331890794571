import { useState } from "react";
import TabWrapper from "../../../../../components/TabWrapper";
import { BuilderTab } from "./Builder/BuilderTab";
import { IQueryBuilderCommonProps } from "../models/IQueryBuilderCommonProps";
import {
  ButtonType,
  CDataButton,
} from "../../../../../components/buttons/CDataButton";
import { generateQueryBuilderSql } from "../sqlGenerator/generateQueryBuilderSql";
import { QueryBuilderPreviewTab } from "./Preview/QueryBuilderPreviewTab";
import { CDataCodeMirror } from "../../../../../components/codemirror/CDataCodeMirror";
import { IQueryTab } from "../../Tabs/useQueryTabs";

export interface IQueryBuilderTabsProps extends IQueryBuilderCommonProps {
  tab: IQueryTab;
  abortRunningQueryRequest: () => void;
}

export const QueryBuilderTabs = (props: IQueryBuilderTabsProps) => {
  const { tab, queryData } = props;

  const isQueryBuilderEmpty = props.queryData.from.connectionId === "";

  const [queryBuilderTab, setQueryBuilderTab] = useState<string>("");

  const copyButton =
    queryBuilderTab === "2" ? (
      <CDataButton
        buttonType={ButtonType.Secondary}
        className="query-builder-copy-button ms-auto align-items-center"
        onClick={() =>
          navigator.clipboard.writeText(
            generateQueryBuilderSql(props.queryData),
          )
        }
      >
        <i className="fa fa-solid fa-copy me-2" />
        Copy
      </CDataButton>
    ) : undefined;

  const disabledTabMessage = "Add a table to begin customizing your query.";

  return (
    <TabWrapper
      currentTab={queryBuilderTab}
      setCurrentTab={setQueryBuilderTab}
      extraButton={copyButton}
      tabContentClassName="query-builder-tabs"
      smallerBottomPadding
      tabs={[
        {
          tabName: "Builder",
          tabEnum: "1",
          tabComponent: <BuilderTab {...props} />,
        },
        {
          tabName: "SQL",
          tabEnum: "2",
          tabComponent: (
            <CDataCodeMirror
              sqlText={generateQueryBuilderSql(props.queryData)}
              height="480px"
            />
          ),
          isDisabled: isQueryBuilderEmpty,
          disabledTooltip: disabledTabMessage,
        },
        {
          tabName: "Preview",
          tabEnum: "3",
          // Only render the tab when it's displayed.
          // This stops the query from re-running in the background.
          tabComponent:
            queryBuilderTab === "3" ? (
              <QueryBuilderPreviewTab
                tab={tab}
                queryData={queryData}
                abortRunningQueryRequest={props.abortRunningQueryRequest}
              />
            ) : (
              <></>
            ),
          isDisabled: isQueryBuilderEmpty,
          disabledTooltip: disabledTabMessage,
        },
      ]}
    />
  );
};
