import { MenuItem, MenuItemProps } from "@mui/material";
import classNames from "classnames";

export interface CDataMenuItemProps extends MenuItemProps {}

export function CDataMenuItem(props: CDataMenuItemProps) {
  const { className, children, ...remainingProps } = props;

  const classes = classNames("cdata-menu-item", className);

  return (
    <MenuItem {...remainingProps} className={classes}>
      {children}
    </MenuItem>
  );
}
