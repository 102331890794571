import {
  ChangeEvent,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Card, CardBody, FormGroup, Label, Input } from "reactstrap";
import { APIPaginationType, IAPIPagination } from "../../../../../models";
import { IGlobalSettings } from "../../../ApiConnector";
import { Form, Formik } from "formik";
import { GLOBAL_SETTINGS_CONSTANTS as Constants } from "../../../ApiConnector.constants";
import {
  dummyGlobalSubmit,
  paginationInitialValues,
  paginationValidationSchema,
} from "../../GlobalSettingsValidationDictionary";
import {
  paginationFieldsToResetOnTypeChange,
  numberProps,
  offsetProps,
  PaginationField,
  staticTokenProps,
  dynamicUrlProps,
  PaginationInputType,
  dynamicTokenProps,
  staticUrlProps,
} from "./PaginationCard.constants";
import { GlobalTextField } from "./GlobalTextField";
import { GlobalRadioField } from "./GlobalRadioField";
import { preventNonIntegerInput } from "../../../../../utility/InputSanitization";
import { createDeepCopy } from "../../../../../utility/CreateDeepCopy";

interface IPaginationCardProps {
  globalSettings: IGlobalSettings;
  setGlobalSettings: (newGlobalSettings: IGlobalSettings) => void;
  setUnsavedChanges: (newUnsavedChanges: boolean) => void;
  isFlyout?: boolean;
}

const PaginationCard = forwardRef(
  (props: IPaginationCardProps, paginationRef: any) => {
    const [tokenSource, setTokenSource] = useState<string>("");
    const [urlResponseType, setUrlResponseType] = useState<string>("");

    useEffect(() => {
      if (props.globalSettings.pagination.requestPath) {
        setTokenSource(Constants.REQUEST_PATH);
      } else {
        setTokenSource(Constants.REQUEST_PARAM);
      }

      if (props.globalSettings.pagination.urlHeader) {
        setUrlResponseType(Constants.URL_HEADER);
      } else {
        setUrlResponseType(Constants.URL_PATH);
      }
    }, []); // eslint-disable-line

    const handlePaginationCardChange = useCallback(
      (
        event: ChangeEvent<HTMLInputElement>,
        setGlobalSettings: (newGlobalSettings: IGlobalSettings) => void,
        setUnsavedChanges: (newUnsavedChanges: boolean) => void,
      ) => {
        const { value, name, id } = event.target;
        const newGlobalSettings = createDeepCopy(props.globalSettings);
        const pagination: any = newGlobalSettings.pagination;

        let convertedValue;
        if (id === "PaginationType") {
          convertedValue = Number(value);
          paginationFieldsToResetOnTypeChange.forEach((field) =>
            paginationRef.current?.setFieldTouched(field, false),
          );
        } else if (id === "PageSize" || id === "OffsetStartingNumber") {
          convertedValue = preventNonIntegerInput(value);
        } else {
          convertedValue = value;
        }
        pagination[name] = convertedValue;
        paginationRef.current?.setFieldValue(name, convertedValue, true);

        setGlobalSettings(newGlobalSettings);
        setUnsavedChanges(true);
      },
      [props.globalSettings, paginationRef],
    );

    interface PropArrays {
      static?: PaginationField[];
      dynamic?: PaginationField[];
    }

    const propArraysMap = useMemo<{
      [key in APIPaginationType]?: PropArrays;
    }>(() => {
      return {
        [APIPaginationType.None]: {},
        [APIPaginationType.Offset]: { static: offsetProps },
        [APIPaginationType.PageNumber]: { static: numberProps },
        [APIPaginationType.PageToken]: {
          static: staticTokenProps,
          dynamic: dynamicTokenProps,
        },
        [APIPaginationType.URL]: {
          static: staticUrlProps,
          dynamic: dynamicUrlProps,
        },
      };
    }, []);

    function generatePaginationFields(optionValue: APIPaginationType) {
      if (!optionValue) return;

      const propArrays = propArraysMap[optionValue];
      return (
        <>
          {propArrays?.static
            ? generateFields("static", propArrays.static)
            : null}
          {propArrays?.dynamic
            ? generateFields("dynamic", propArrays.dynamic)
            : null}
        </>
      );
    }

    function generateFields(
      type: "static" | "dynamic",
      propArray: PaginationField[],
    ) {
      if (!propArray || propArray.length === 0) return null;

      return propArray.map((property: PaginationField, propIndex: number) => {
        const propertyInArray =
          props.globalSettings.pagination[
            property.name as keyof IAPIPagination
          ];

        if (type === "static") {
          if (property.type === PaginationInputType.Text) {
            return (
              <GlobalTextField
                key={propIndex}
                handlePaginationCardChange={(event) =>
                  handlePaginationCardChange(
                    event,
                    props.setGlobalSettings,
                    props.setUnsavedChanges,
                  )
                }
                property={property}
                propertyInArray={propertyInArray}
                propIndex={propIndex}
                type={type}
              />
            );
          } else if (property.type === PaginationInputType.Select) {
            return (
              <GlobalRadioField
                key={propIndex}
                globalSettings={props.globalSettings}
                setDynamicFieldChoice={setDynamicFieldChoice}
                property={property}
                propIndex={propIndex}
                tokenSource={tokenSource}
                urlResponseType={urlResponseType}
              />
            );
          }
        } else if (type === "dynamic") {
          const stateToCheck =
            property.name === Constants.REQUEST_PARAM ||
            property.name === Constants.REQUEST_PATH
              ? tokenSource
              : urlResponseType;

          if (stateToCheck === property.name) {
            return (
              <GlobalTextField
                key={propIndex}
                handlePaginationCardChange={(event) =>
                  handlePaginationCardChange(
                    event,
                    props.setGlobalSettings,
                    props.setUnsavedChanges,
                  )
                }
                property={property}
                propertyInArray={propertyInArray}
                propIndex={propIndex}
                type={type}
              />
            );
          }
        }
      });
    }

    function setDynamicFieldChoice(chosenField: string) {
      const settingsMap = {
        [Constants.REQUEST_PARAM]: {
          stateSetter: setTokenSource,
          paramToClear: Constants.REQUEST_PATH,
        },
        [Constants.REQUEST_PATH]: {
          stateSetter: setTokenSource,
          paramToClear: Constants.REQUEST_PARAM,
        },
        [Constants.URL_PATH]: {
          stateSetter: setUrlResponseType,
          paramToClear: Constants.URL_HEADER,
        },
        [Constants.URL_HEADER]: {
          stateSetter: setUrlResponseType,
          paramToClear: Constants.URL_PATH,
        },
      };

      const setting = settingsMap[chosenField];
      if (setting) {
        setting.stateSetter(chosenField);
        clearUnusedExclusiveParameters(setting.paramToClear);
        paginationRef.current?.setFieldTouched(setting.paramToClear, false);
      }
    }

    // It's easier to do this here instead of during the submission process
    function clearUnusedExclusiveParameters(parameterToClear: string) {
      const newGlobalSettings = createDeepCopy(props.globalSettings);
      const pagination: any = newGlobalSettings.pagination;
      pagination[parameterToClear] = "";
      props.setGlobalSettings(newGlobalSettings);
    }

    const paginationCardContent = (
      <Formik
        innerRef={paginationRef}
        initialValues={paginationInitialValues(
          props.globalSettings?.pagination,
        )}
        validationSchema={paginationValidationSchema}
        onSubmit={dummyGlobalSubmit}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <h5 className="card-title">Pagination</h5>
            <FormGroup className="my-3">
              <Label className="form-field-title">Type</Label>
              <Input
                data-testid="select-pagination-type"
                id="PaginationType"
                type="select"
                name={Constants.TYPE}
                onChange={(event) =>
                  handlePaginationCardChange(
                    event,
                    props.setGlobalSettings,
                    props.setUnsavedChanges,
                  )
                }
                value={props.globalSettings.pagination.type}
              >
                <option key="none" value={APIPaginationType.None}>
                  None
                </option>
                <option key="offSet" value={APIPaginationType.Offset}>
                  Offset
                </option>
                <option key="number" value={APIPaginationType.PageNumber}>
                  Number
                </option>
                <option key="token" value={APIPaginationType.PageToken}>
                  Token
                </option>
                <option key="url" value={APIPaginationType.URL}>
                  URL
                </option>
              </Input>
            </FormGroup>
            {generatePaginationFields(props.globalSettings.pagination.type)}
          </Form>
        )}
      </Formik>
    );

    function renderPaginationCard() {
      return (
        <>
          {props.isFlyout ? (
            <div>{paginationCardContent}</div>
          ) : (
            <Card>
              <CardBody>{paginationCardContent}</CardBody>
            </Card>
          )}
        </>
      );
    }

    return <>{renderPaginationCard()}</>;
  },
);
PaginationCard.displayName = "PaginationCard";

export default PaginationCard;
