import React from "react";
import { Input, InputProps } from "reactstrap";

type SelectProps = {
  value: string;
  options: { key: string; value: string | number }[];
  onChange: (value: string) => void;
  onBlur: InputProps["onBlur"];
  placeholder?: string;
  name?: string;
  invalid?: boolean;
  disabled?: boolean;
};

const Select = (props: SelectProps) => {
  props.options.splice(0, 1, {
    key: props.placeholder ?? "Select",
    value: props.placeholder ?? "",
  });
  return (
    <Input
      name={props.name ?? ""}
      data-testid="select"
      type="select"
      className="form-control select"
      value={props.value}
      onChange={(e: any) => props.onChange(e.target.value)}
      onBlur={props.onBlur}
      invalid={props.invalid ?? false}
      disabled={props.disabled}
    >
      {props.options.map((option) => (
        <option key={option.key} value={option.value}>
          {option.key}
        </option>
      ))}
    </Input>
  );
};

export default Select;
