import { useRef, useState } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardText,
  UncontrolledTooltip,
} from "reactstrap";

import { showFlyout } from "../../../redux/actions/flyoutActions";
import { hasOverflow } from "../ClientConnectivityFunctions";
import { IWorkspaceListItem } from "../../../models";
import ModalPromptBody, { ModalPromptBodyRow } from "./ModalPromptBody";
import { CDataModalV2 } from "../../../components/modal/CDataModalV2";
import { useAppDispatch } from "../../../redux/hooks";
import { ButtonBase } from "@mui/material";

export interface IModalPromptTileProps {
  idName: string;
  testId?: string;
  imageIcon: string;
  description: string;
  showBetaBadge: boolean;
  externalDoc: string;
  modalTitle: string;
  modalBodyValues: ModalPromptBodyRow[];
  workspaces: IWorkspaceListItem[];
  isOdata: boolean;
  isOpenApi: boolean;
  isRestApi?: boolean;
}

function ModalPromptTile(props: IModalPromptTileProps) {
  const [showToolTip, setShowTooltip] = useState(false);
  const [isModalDisplayed, setIsModalDisplayed] = useState(false);
  const cardRef = useRef(null);
  const dispatch = useAppDispatch();

  const modalBody = (
    <ModalPromptBody
      idName={props.idName}
      modalBodyValues={props.modalBodyValues}
      workspaces={props.workspaces}
      isOdata={props.isOdata}
      isOpenApi={props.isOpenApi}
      isRestApi={props.isRestApi}
    />
  );

  const connectModal = (
    <CDataModalV2
      title={props.modalTitle}
      displayed={isModalDisplayed}
      close={() => setIsModalDisplayed(false)}
      primaryButton={
        <Button
          color="primary"
          tag="a"
          onClick={() => setIsModalDisplayed(false)}
          data-testid="button-close-modal"
        >
          Close
        </Button>
      }
    >
      {modalBody}
    </CDataModalV2>
  );

  const docUrl = props.externalDoc;
  const name = props.description;
  const source = "Clients";

  return (
    <>
      <Card
        data-testid={props.testId}
        name={props.idName}
        id={props.idName}
        className="shadow-sm client-tools-card"
        onMouseOver={() => setShowTooltip(hasOverflow(cardRef.current))}
        onMouseOut={() => setShowTooltip(false)}
      >
        <ButtonBase
          disableRipple
          className="client-tools-clickable-area"
          onClick={() => {
            setIsModalDisplayed(true);
          }}
        >
          <div>
            <svg
              className="client-connectivity-icon card-img-top img-thumbnail"
              viewBox="0 0 48 48"
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
            >
              <use xlinkHref={props.imageIcon}></use>
            </svg>
          </div>

          <CardBody className="card-body">
            <div>
              <UncontrolledTooltip
                placement="top"
                target={`title-${props.idName}`}
                isOpen={showToolTip}
              >
                {props.description}
              </UncontrolledTooltip>
              <CardText
                id={`title-${props.idName}`}
                tag="h6"
                className="card-text text-truncate text-muted"
                ref={cardRef}
              >
                {props.description}
              </CardText>
            </div>
          </CardBody>
        </ButtonBase>

        <div
          className={`action-card-container ${
            props.idName === "salesforceCloudLink" ? "sales-force-fix" : ""
          } d-flex`}
        >
          <Badge
            id="beta-badge"
            hidden={!props.showBetaBadge}
            color=""
            className="client-tools-beta-badge"
          >
            Beta
          </Badge>
          <a
            rel="noopener noreferrer"
            className="docs-btn"
            onClick={() => dispatch(showFlyout(docUrl, name, source))}
          >
            Docs
          </a>
          <div className="divider" />
          <Button
            onClick={() => setIsModalDisplayed(true)}
            className="connect-btn"
          >
            Connect
          </Button>
        </div>
      </Card>
      {connectModal}
    </>
  );
}

export default ModalPromptTile;
