import React, { ReactNode } from "react";
import { Row } from "reactstrap";
import { CDataTypography } from "../../../../../../../components/text/CDataTypography";
import { FilterOperator } from "./FilterOperator";
import { FilterType } from "../../../models/IQueryBuilderFilter";
import { FilterRange } from "./FilterRange";
import { FilterValue } from "./FilterValue";
import { IQueryFilterProps } from "../../../types/IQueryFilterProps";
import { DataType } from "../../../../../../../models";
import { FilterSingleDate } from "../../../DateFilters/FilterSingleDate";
import { FilterDateBetween } from "../../../DateFilters/FilterDateBetween";

export function FilterTypeAndRange(props: IQueryFilterProps) {
  const filterValues = getFilterComponentForColumn(props);

  return (
    <div className="filter-type-and-range">
      <CDataTypography
        variant="typography-variant-headline-5"
        className="required mb-2"
      >
        SQL Operator
      </CDataTypography>
      <Row>
        <FilterOperator {...props} />
        {filterValues}
      </Row>
    </div>
  );
}

function getFilterComponentForColumn(props: IQueryFilterProps): ReactNode {
  const { queryFilter } = props;

  if (
    queryFilter.column.dataType === DataType.DATE ||
    queryFilter.column.dataType === DataType.TIMESTAMP
  ) {
    if (queryFilter.filterType === FilterType.Between) {
      return <FilterDateBetween {...props} />;
    } else {
      return <FilterSingleDate {...props} />;
    }
  }

  if (queryFilter.filterType === FilterType.Between) {
    return <FilterRange {...props} />;
  }

  return <FilterValue {...props} />;
}
