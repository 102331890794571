import { createContext } from "react";

export interface IStepWizardContext {
  completedSteps: number[];
  modalSize: string;
  setPendingCompletedStepsToCurrentStep: (stepNumber: number) => void;
  setModalSize: (modalSize: string) => void;
  setStepCompleted: (completedStep: number) => void;
  resetPendingCompletedSteps: () => void;
  toggleModal: (event?: React.MouseEvent<HTMLButtonElement>) => void;
}

export const StepWizardContext = createContext<IStepWizardContext>(null!);
