import { isEmpty } from "lodash";
import { compareStrings } from "../../../../utility/CompareStrings";
import { IDriverExtended } from "../../../../bffmodels/IDriverExtended";
import { IHierarchyPropertyExtended } from "../../../../bffmodels/IHierarchyPropertyExtended";

/** Returns the Auth Scheme dropdown property if it exists, or null if the driver has no Auth Scheme dropdown. */
export function getSelectedAuthSchemeProperty(
  driverInfo: IDriverExtended | null | undefined,
): IHierarchyPropertyExtended | null {
  if (driverInfo == null) {
    return null;
  }
  return getSelectedAuthSchemePropertyRecursive(driverInfo.basicProps);
}

function getSelectedAuthSchemePropertyRecursive(
  properties: IHierarchyPropertyExtended[],
): IHierarchyPropertyExtended | null {
  const authProperty = properties.find((t) =>
    compareStrings(t.propertyName, "authscheme"),
  );

  if (authProperty != null) {
    return authProperty;
  }

  for (const property of properties) {
    const hierarchyRules = property.hierarchyRules;
    if (hierarchyRules == null) {
      continue;
    }

    for (const key of Object.keys(hierarchyRules)) {
      // Only follow the currently selected hierarchy.
      // E.x if they are using the NetSuite driver and selected a Schema of SuiteQL, ignore the SuiteTalk hierarchy
      // since there are 2 fields called Auth Scheme, one for each hierarchy.
      if (!compareStrings(property.currentValue, key)) {
        continue;
      }
      const authPropertyDeep = getSelectedAuthSchemePropertyRecursive(
        hierarchyRules[key],
      );

      if (authPropertyDeep != null) {
        return authPropertyDeep;
      }
    }
  }

  return null;
}

/**
 * Returns the Auth Scheme the user has selected for the connection
 * Returns null if no value is selected or the driver doesn't have an Auth Scheme option.
 */
export function getSelectedAuthSchemeValue(
  driverInfo: IDriverExtended | null | undefined,
): string | null {
  const authProperty = getSelectedAuthSchemeProperty(driverInfo);

  if (authProperty == null) {
    return null;
  }

  if (isEmpty(authProperty.currentValue)) {
    return null;
  }

  return authProperty.currentValue?.toLowerCase?.() ?? null;
}
