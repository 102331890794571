import React from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import {
  PaginationField,
  PaginationRadioOption,
} from "./PaginationCard.constants";
import { APIPaginationType } from "../../../../../models";
import { InfoIcon } from "../../../../../components/InfoIcon";
import { IGlobalSettings } from "../../../ApiConnector";

interface IGlobalRadioField {
  globalSettings: IGlobalSettings;
  setDynamicFieldChoice: (dynamicFieldChoice: string) => void;
  property: PaginationField;
  propIndex: number;
  tokenSource: string;
  urlResponseType: string;
}

export const GlobalRadioField = (props: IGlobalRadioField) => {
  const { property, propIndex, tokenSource, urlResponseType } = props;

  return (
    <FormGroup key={"static" + propIndex}>
      <Label data-testid={property.id} className={"form-field-title"}>
        {property.label}
      </Label>
      {property.options?.map(
        (option: PaginationRadioOption, optionIndex: number) => {
          const checkedState =
            option.paginationType === APIPaginationType.PageToken
              ? tokenSource
              : urlResponseType;
          return (
            <Row key={"staticOption" + optionIndex}>
              <Col>
                <Label>
                  <Input
                    name={"options" + propIndex}
                    type="radio"
                    className="me-2"
                    checked={checkedState === option.correspondingField}
                    onChange={() =>
                      props.setDynamicFieldChoice(option.correspondingField)
                    }
                  />
                  {option.label}
                  <InfoIcon
                    tooltipMessage={option.infoIconText}
                    className="mx-1 pt-1"
                    iconId={"infoIcon" + optionIndex}
                  />
                </Label>
              </Col>
            </Row>
          );
        },
      )}
    </FormGroup>
  );
};
