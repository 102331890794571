import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import {
  randomFlyoutKey,
  setBaseDocsAddress,
} from "../../../utility/FlyoutDocsFactory";
import { CDataTypography } from "../../../components/text/CDataTypography";

interface IDocumentationPane {
  driverType: string;
}

export const DocumentationPane = React.memo((props: IDocumentationPane) => {
  const { driverType } = props;

  const docUrl = `${setBaseDocsAddress()}${driverType}.html?hideborders=true&hidepaddingtop=true&`;

  const unique_key = randomFlyoutKey();

  return (
    <Card className="connection-documentation-pane">
      <CardHeader className="px-3 pt-3 pb-0">
        <CDataTypography
          variant="typography-variant-card-title"
          color="typography-color-dark-grey"
          className="documentation-pane-header pb-3"
        >
          Documentation
        </CDataTypography>
      </CardHeader>
      <CardBody className="p-0">
        <iframe
          key={unique_key}
          scrolling="yes"
          className="iframe-doc"
          frameBorder="0"
          src={`${docUrl}${unique_key}`}
          title="docs"
        ></iframe>
      </CardBody>
    </Card>
  );
});

DocumentationPane.displayName = "DocumentationPane";
