import React from "react";
import {
  ModalPromptBodyRow,
  ModalPromptFieldTypes,
  ResourceType,
} from "../ModalPromptBody";
import { Row } from "reactstrap";
import ClientCardModalField from "./ClientCardModalField";

interface IConnectionFields {
  idName: string;
  modalBodyValues: ModalPromptBodyRow[];
  selectedResourceType: ResourceType;
  selectedWorkspaceName: string;
  userEmail: string | undefined;
}

const ConnectionFields = (props: IConnectionFields) => {
  function renderMultiField(children: { key: string; value: string }[]) {
    const columns = children.map((child) => {
      return (
        <ClientCardModalField
          fieldKey={child.key}
          fieldType={ModalPromptFieldTypes.MultiField}
          idName={props.idName}
          key={child.key}
          selectedResourceType={props.selectedResourceType}
          selectedWorkspaceName={props.selectedWorkspaceName}
          userEmail={props.userEmail}
          value={child.value}
        />
      );
    });

    return <>{columns}</>;
  }

  return (
    <>
      {props.modalBodyValues.map((rowDetails, index) => {
        let columns: JSX.Element;
        switch (rowDetails.type) {
          case ModalPromptFieldTypes.Email:
            rowDetails.value = props.userEmail;
            columns = (
              <ClientCardModalField
                fieldKey={rowDetails.key!}
                fieldType={ModalPromptFieldTypes.Email}
                idName={props.idName}
                selectedResourceType={props.selectedResourceType}
                selectedWorkspaceName={props.selectedWorkspaceName}
                userEmail={props.userEmail}
                value={rowDetails.value!}
              />
            );
            break;
          case ModalPromptFieldTypes.SingleField:
            columns = (
              <ClientCardModalField
                fieldKey={rowDetails.key!}
                fieldType={ModalPromptFieldTypes.SingleField}
                idName={props.idName}
                selectedResourceType={props.selectedResourceType}
                selectedWorkspaceName={props.selectedWorkspaceName}
                value={rowDetails.value!}
              />
            );
            break;
          case ModalPromptFieldTypes.MultiField:
            columns = renderMultiField(rowDetails.children!);
            break;
          default:
            return;
        }

        return (
          <Row className="my-3" key={index}>
            {columns}
          </Row>
        );
      })}
    </>
  );
};

export default ConnectionFields;
