import { useContext } from "react";
import { Col, Row, NavLink, Button } from "reactstrap";

import EditConnection from "../../../../connections/EditConnection";
import { PardotLogger } from "../../../../../components";
import { InitialSetupContext } from "../../InitialSetupContext";
import { IModalProps } from "../../../../../components/CDataModal";
import { ModalContext } from "../../../../../routes/ModalContext";
import { IStepBodyProps } from "../../../../../components/wizard/components/StepWizardStep";
import { useAPI } from "../../../../../components/useAPI";
import { useIsConnectForSpreadsheets } from "../../../../../hooks/useIsConnectForSpreadsheets";
import { goToDashboard } from "../../../goToDashboard";

export interface IAddInitialConnectionProps extends IStepBodyProps {
  updatePreventNavigation: (
    preventNavigation: boolean,
    preventNavigationTitle: string,
    preventNavigationMessage: string,
  ) => void | undefined;
  preventNavigation: boolean;
}

export function AddInitialConnection(props: IAddInitialConnectionProps) {
  const initialSetupContext = useContext(InitialSetupContext);
  const modalFunctions = useContext(ModalContext);
  const api = useAPI();
  const isConnectForSpreadsheets = useIsConnectForSpreadsheets();

  if (initialSetupContext.selectedConnection.driver === "") {
    props.goToStep!(1);
  }

  // For Connect for Spreadsheets users, we go directly to the dashboard after they save their connection. All other users proceed to step 3.
  function nextStep() {
    if (isConnectForSpreadsheets) {
      goToDashboard(api.callAPI);
    } else {
      props.nextStep!();
    }
  }

  const handleNavigation = (onLeaveClick: () => void) => {
    if (!props.preventNavigation) {
      onLeaveClick();
      return;
    }

    const modal = {
      title: "Unsaved Changes",
      body: (
        <text>
          If you leave this page, any unsaved changes will be lost. Are you sure
          you want to leave?
        </text>
      ),
      secondaryButton: (
        <Button color="dark" onClick={() => modalFunctions.toggleModal()}>
          Cancel
        </Button>
      ),
      primaryButton: (
        <Button
          color="danger"
          onClick={() => {
            modalFunctions.toggleModal();
            onLeaveClick();
          }}
        >
          Leave Page
        </Button>
      ),
      displayToggleCloseButton: true,
      displayed: true,
      modalSize: "lg",
    } as IModalProps;

    modalFunctions.setModal(modal);
  };

  return (
    <>
      <PardotLogger driver={initialSetupContext.selectedConnection.driver} />
      <Row className="mb-3">
        <Col>
          <h1 className="h3 text-center setup-wizard-title">
            <NavLink
              onClick={() => handleNavigation(props.previousStep!)}
              className="d-inline align-middle text-dark me-2"
            >
              <i className="fa fa-arrow-left back-arrow" />
            </NavLink>
            <div className="d-inline align-middle">Add Connection</div>
          </h1>
        </Col>
      </Row>
      <Row className="mb-4">
        <div className="text-center">
          Follow the prompts below to establish a connection to the data source.
        </div>
      </Row>
      {
        /* We are navigating to step-1 if initialSetupContext is not setup anyways, lets not render the EditConnection as well */
        initialSetupContext.selectedConnection.driver && (
          <EditConnection
            isInitialSetupPage={true}
            nextStep={nextStep}
            updatePreventNavigation={props.updatePreventNavigation}
            handleNavigation={handleNavigation}
          />
        )
      }
    </>
  );
}
