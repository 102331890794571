import { Button } from "reactstrap";
import { ConnectionContext } from "./ConnectionContext";
import { UserRole } from "../../../models";
import React, { useContext } from "react";
import { useUserInfo } from "../../../hooks/useUserInfo";
import { useAPI } from "../../../components/useAPI";
import { goToDashboard } from "../../initialSetup/goToDashboard";

interface ISecondaryActionButton {
  openDeleteConnectionModal: (event: React.MouseEvent) => void;
  handleNavigation?: (onNavigate: () => void) => void;
}

export const SecondaryActionButton = ({
  handleNavigation,
  openDeleteConnectionModal,
}: ISecondaryActionButton) => {
  const api = useAPI();
  const user = useUserInfo();
  const isAdmin = user.IsInRole(UserRole.Admin);
  const { connectionId, isInitialSetupPage } = useContext(ConnectionContext);

  if (isInitialSetupPage) {
    return (
      <div className="float-end dashboard-arrow-outer me-0">
        <button
          onClick={() =>
            handleNavigation?.(() => {
              goToDashboard(api.callAPI);
            })
          }
          type="button"
          className="skip-button text-nowrap"
          data-testid="button-go-to-dashboard"
        >
          Go to Dashboard
        </button>
      </div>
    );
  } else if (isAdmin && connectionId) {
    return (
      <Button
        type="button"
        color="secondary"
        className="card-actions float-end text-nowrap"
        onClick={(event: React.MouseEvent) => openDeleteConnectionModal(event)}
        data-testid="button-delete-connection-modal"
      >
        <i className="fa fa-times align-middle me-2 no-pointer-event" />
        Delete
      </Button>
    );
  } else {
    return null;
  }
};
