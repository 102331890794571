import { SortDirection } from "@tanstack/react-table";
import classnames from "classnames";
import { ButtonType, CDataButton } from "../../buttons/CDataButton";

interface ISortIconProps {
  isSorted: false | SortDirection;
  nextSortOrder: false | SortDirection;
}

export const SortIcon = (props: ISortIconProps) => {
  const { isSorted, nextSortOrder } = props;

  const className = classnames("fa-arrow-down-arrow-up ms-1 sort-icon", {
    "fa-solid sorting-unselected": !isSorted,
    "fa-duotone": isSorted,
    "fa-flip-vertical": isSorted === "desc",
  });

  return (
    <CDataButton
      className="component-SortIcon"
      buttonType={ButtonType.Borderless}
      aria-label={`Change sort direction to ${nextSortOrder === "desc" ? "descending" : "ascending"}`}
    >
      <i className={className}></i>
    </CDataButton>
  );
};
