import React from "react";
import { Link } from "react-router-dom";

export interface BreadcrumbItem {
  display: string;
  navigation: string;
}

interface Props {
  items: BreadcrumbItem[];
}

const Breadcrumbs: React.FC<Props> = ({ items }) => {
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        {items.map(({ navigation, display }, index) => {
          const isLastItem = index === items.length - 1;
          return (
            <li
              key={navigation}
              className={`breadcrumb-item${isLastItem ? " active" : ""}`}
              aria-current={isLastItem ? "page" : undefined}
            >
              {isLastItem ? (
                display
              ) : (
                <Link to={navigation} data-testid={`link-${display}`}>
                  {display}
                </Link>
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
