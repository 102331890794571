import { CallAPIFunction } from "src/components/useAPI";
import { RequestType } from "../../../components/withAPI";
import {
  IAccountStatsReport,
  IConnectionStatsReport,
  IQueriesOverTimeReport,
  IRowsOverTimeReport,
  IUserStatsReport,
} from "../../../models";

export const getAccountStatistics = async (
  callAPI: CallAPIFunction,
  timePeriod: number,
): Promise<IAccountStatsReport | null> => {
  const { status, payload } = await callAPI(
    RequestType.Get,
    `/account/reports/accountStats/${timePeriod}`,
    "Failed to get account statistics due to the following error:",
  );
  if (status === 200) {
    return payload as IAccountStatsReport;
  } else {
    return null;
  }
};

export const getActiveConnectionStatistics = async (
  callAPI: CallAPIFunction,
  timePeriod: number,
): Promise<IConnectionStatsReport | null> => {
  const { status, payload } = await callAPI(
    RequestType.Get,
    `/account/reports/getActiveConnectionStatistics/${timePeriod}`,
    "Failed to get active connection statistics due to the following error:",
  );
  if (status === 200) {
    return payload as IConnectionStatsReport;
  } else {
    return null;
  }
};

export const getActiveUserStatistics = async (
  callAPI: CallAPIFunction,
  timePeriod: number,
): Promise<IUserStatsReport | null> => {
  const { status, payload } = await callAPI(
    RequestType.Get,
    `account/reports/userStats/${timePeriod}`,
    "Failed to get active user statistics due to the following error:",
  );
  if (status === 200) {
    return payload as IUserStatsReport;
  } else {
    return null;
  }
};

export const getQueryLogsOverTime = async (
  callAPI: CallAPIFunction,
  timePeriod: number,
): Promise<IQueriesOverTimeReport | null> => {
  const { status, payload } = await callAPI(
    RequestType.Get,
    `account/reports/queriesOverTime/${timePeriod}`,
    "Failed to get query logs due to the following error:",
  );
  if (status === 200) {
    return payload as IQueriesOverTimeReport;
  } else {
    return null;
  }
};

export const getRowLogsOverTime = async (
  callAPI: CallAPIFunction,
  timePeriod: number,
): Promise<IRowsOverTimeReport | null> => {
  const { status, payload } = await callAPI(
    RequestType.Get,
    `account/reports/rowsOverTime/${timePeriod}`,
    "Failed to get row logs due to the following error:",
  );
  if (status === 200) {
    return payload as IRowsOverTimeReport;
  } else {
    return null;
  }
};
