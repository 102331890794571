import { Card, CardBody, Row } from "reactstrap";
import { AutoTruncateStringsToggleButton } from "./AutoTruncateStringsToggleButton";
import { LogVerbosityDropdown } from "./LogVerbosityDropdown";
import { ICacheJob } from "../../../../models/Cache/ICacheJob";
import { JobType } from "../../../../models/Cache/JobType";
import { IScheduledQuery } from "../../../../models/ScheduledQueries/IScheduledQuery";

interface IAdvancedSettingsCard {
  jobType: JobType;
  setUnsavedChanges: (unsavedChanges: boolean) => void;
}

export default function AdvancedSettingsCard<
  T extends ICacheJob | IScheduledQuery,
>(props: IAdvancedSettingsCard) {
  const { jobType, setUnsavedChanges } = props;

  return (
    <Card className="w-100">
      <CardBody>
        <Row className="mb-3">
          <h5 className="card-title mb-0" data-testid="header-run-history">
            Advanced
          </h5>
        </Row>
        {jobType === JobType.Caching && (
          <AutoTruncateStringsToggleButton
            setUnsavedChanges={setUnsavedChanges}
          />
        )}
        <LogVerbosityDropdown<T> setUnsavedChanges={setUnsavedChanges} />
      </CardBody>
    </Card>
  );
}
