import { useMutation } from "@tanstack/react-query";
import { IHierarchyCategoryExtended } from "../bffmodels/IHierarchyCategoryExtended";
import { IHierarchyPropertyExtended } from "../bffmodels/IHierarchyPropertyExtended";
import { useAPI } from "../components/useAPI";
import { RequestType } from "../components/withAPI";
import { IDriver, Ops, UserRole } from "../models";
import { IConnectionContext } from "../pages/connections/components/ConnectionContext";
import { IFullPermission } from "../pages/connections/components/PermissionsCard";
import { useAppSelector } from "../redux/hooks";
import { useIsConnectForSpreadsheets } from "./useIsConnectForSpreadsheets";
import { getConnectionModel } from "../api/Connections/getConnectionModel";

export function useConnectionSetup(
  connectionContext: IConnectionContext | null,
) {
  const api = useAPI();
  const isSpreadsheetUser = useIsConnectForSpreadsheets();
  const user = useAppSelector((state) => state.user);

  const initializeConnectionState = async (
    driverType: string,
    connectionId: string,
  ) => {
    const model = await getConnectionModelAsync({ driverType, connectionId });

    //Set Current Values
    model?.basicProps?.forEach((basicProps: IHierarchyPropertyExtended) => {
      basicProps.currentValue = basicProps.default!;
    });
    model?.advancedProps?.forEach(
      (advancedProperty: IHierarchyCategoryExtended) => {
        advancedProperty.properties!.forEach(
          (advancedProperty: IHierarchyPropertyExtended) => {
            advancedProperty.currentValue = advancedProperty.default!;
          },
        );
      },
    );
    return model;
  };

  const getDriverInfo = async () => {
    const { status, payload } = await api.callAPI(
      RequestType.Get,
      `/drivers/${connectionContext?.driverType}`,
      "Failed to get driver info.",
    );
    if (status === 200) {
      return payload as IDriver;
    }
  };

  const { mutateAsync: getConnectionModelAsync } = useMutation({
    mutationKey: ["/account/connection"],
    mutationFn: ({
      driverType,
      connectionId,
    }: {
      driverType: string;
      connectionId: string;
    }) => getConnectionModel(driverType, connectionId),
    meta: {
      errorMessage:
        "Failed to get query credential list due to the following error:",
    },
  });

  const initializeUserPermissions = async (
    driverType: string,
    connectionId: string | undefined,
  ): Promise<IFullPermission[]> => {
    const { status, payload } = await api.callAPI(
      RequestType.Get,
      `/account/connection/${driverType}/${connectionId}/users`,
      "Failed to get query credential list due to the following error:",
    );
    if (status === 200) {
      const permissions = payload as IFullPermission[];

      if (isSpreadsheetUser && user?.role === UserRole.Admin) {
        for (const permission of permissions) {
          permission.opsAllowed = Ops.All;
        }
      }

      return permissions;
    }
    return [];
  };

  const modalAndAPIs = {
    getDriverInfo: getDriverInfo,
    initializeConnectionState: initializeConnectionState,
    initializeUserPermissions: initializeUserPermissions,
  };

  return modalAndAPIs;
}
