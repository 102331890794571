import { cdataFetch } from "../../../api/cdataFetch";
import { IConnectionList } from "../../../models";

export interface IGetConnectionParams {
  IsAdmin: boolean;
  CurrentUserId?: string;
}

export async function getConnections(params: IGetConnectionParams) {
  if (params.IsAdmin)
    return cdataFetch<IConnectionList>({
      method: "GET",
      url: "/account/connections",
    });
  else {
    return cdataFetch<IConnectionList>({
      method: "GET",
      url: `/account/connections/forUser/${params.CurrentUserId}`,
    });
  }
}
