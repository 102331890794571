import { UncontrolledTooltip } from "reactstrap";
import { JobListItem } from "../Jobs";

export const RunButton = (props: {
  row: JobListItem;
  disableButtons: boolean;
  runJob: (job: JobListItem) => void;
}) => {
  return (
    <>
      <button
        onClick={(event: any) => {
          event.stopPropagation();
          props.runJob(props.row);
        }}
        id={`button-run-job-${props.row.id}`}
        data-testid={`button-run-job-${props.row.id}`}
        className="table-button button-color"
        aria-label="run job"
        disabled={props.disableButtons}
      >
        <i className="fa-regular fa-play fa-md align-middle" />
      </button>
      {props.disableButtons ? (
        <UncontrolledTooltip
          target={`button-run-job-${props.row.id}`}
          placement="top"
        >
          Running is currently in progress.
        </UncontrolledTooltip>
      ) : null}
    </>
  );
};

export const EditButton = (props: {
  row: JobListItem;
  editJob: (job: JobListItem) => void;
}) => {
  return (
    <button
      onClick={() => props.editJob(props.row)}
      data-testid={`button-edit-job-${props.row.id}`}
      className="table-button button-color"
      aria-label="edit job"
    >
      <i className="fa-regular fa-pen fa-md align-middle" />
    </button>
  );
};

export const DeleteButton = (props: {
  row: JobListItem;
  deleteJob: (job: JobListItem) => void;
}) => {
  return (
    <button
      onClick={(event: any) => {
        event.stopPropagation();
        props.deleteJob(props.row);
      }}
      id={`button-delete-job-${props.row.id}`}
      data-testid={`button-delete-job-${props.row.id}`}
      className="table-button button-color"
      aria-label="delete job"
    >
      <i className="fa-regular fa-xmark fa-md align-middle" />
    </button>
  );
};
