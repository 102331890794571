import React from "react";
import { Col, Row } from "reactstrap";
import { EndpointModalOptions } from "../WorkspaceEditEndpointsModal";
import classnames from "classnames";
import { CreatePATButton } from "../../../../components/buttons/CreatePATButton";

export const EndpointsModalPasswordRow = (props: {
  tab: EndpointModalOptions;
}) => {
  const { tab } = props;
  return (
    <Row>
      <Col
        className={classnames({
          "sql-server-host-name": tab === EndpointModalOptions.VirtualSQLServer,
          "client-endpoint-adjusted-powerbi":
            tab === EndpointModalOptions.OData,
        })}
      >
        <div className="form-field-title">Password:</div>
      </Col>
      <Col className="client-endpoint">
        <CreatePATButton />
      </Col>
    </Row>
  );
};
