import { useState, useRef } from "react";
import { Formik, FormikValues, FormikHelpers, FormikProps } from "formik";
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import { Container, Form } from "react-bootstrap";
import { IUser } from "../../../models";
import { RequestType } from "../../../components/withAPI";
import { useAPI } from "../../../components/useAPI";
import { CDataModalV2 } from "../../../components/modal/CDataModalV2";
import { ToastrSuccess } from "../../../services/toastrService";
import { UserRole } from "../../../models/Users/UserRole";
import { CDataTypography } from "../../../components/text/CDataTypography";
import { formatUTCDateTime } from "../../../utility/FormatUTCDateTime";
import { useAppSelector } from "../../../redux/hooks";
import { CDataToggleButton } from "../../../components/CDataToggleButton";

interface ISupportAccessCardProps {
  userInfo: IUser;
  setUserInfo: Function;
  isSupportImpersonationActive: boolean;
}

type IFormValues = {
  GrantSupportAccess?: boolean;
};

const SupportAccessCard = (props: ISupportAccessCardProps) => {
  const formikRef = useRef<FormikProps<IFormValues>>(null);
  const api = useAPI();
  const [toggleGrantSupportAccessModal, setToggleGrantSupportAccessModal] =
    useState<boolean>(false);
  const [toggleExtendExpirationModal, setToggleExtendExpirationModal] =
    useState<boolean>(false);
  const [grantSupportAccess, setGrantSupportAccess] = useState<boolean>(
    isConsentValid(props.userInfo.consentExpirationDate),
  );

  const [isNotImpersonatedQueryUser, setIsNotImpersonatedQueryUser] =
    useState<boolean>(
      props.userInfo.role.toString() === "1" &&
        props.userInfo.canBeImpersonated === false,
    );

  const user = useAppSelector((u) => u.user);
  const isOemSub = user.role === UserRole.ServiceUser;

  //Reflects identification for query users that have not been granted the ability to allow impersonation
  const isConsentExpirationDateExpiredOrNull = !isConsentValid(
    props.userInfo.consentExpirationDate,
  );

  let consentExpirationDate: Date;
  let formattedDate: string;

  function isConsentValid(consentExpirationDate: string | null | undefined) {
    if (!consentExpirationDate) {
      return false; // Indicates the consent expiration date is null or undefined
    }

    const expirationDate = new Date(consentExpirationDate);
    const now = new Date();

    return expirationDate > now;
  }

  const dateTimeFormatter = (expirationDate: Date): string => {
    return formatUTCDateTime(expirationDate, "yyyy-MM-dd 'at' hh:mm a 'UTC'");
  };

  if (isConsentExpirationDateExpiredOrNull) {
    const defaultExpirationDate = new Date();
    defaultExpirationDate.setDate(defaultExpirationDate.getDate() + 7);
    consentExpirationDate = defaultExpirationDate;
    formattedDate = dateTimeFormatter(defaultExpirationDate);
  } else {
    const existingExpirationDate = new Date(
      props.userInfo.consentExpirationDate!,
    );
    consentExpirationDate = existingExpirationDate;
    formattedDate = dateTimeFormatter(existingExpirationDate);
  }

  const resetForm = (operationCanceled: boolean) => {
    setToggleGrantSupportAccessModal(false);

    if (operationCanceled) {
      setGrantSupportAccess(false);
    }

    formikRef.current?.resetForm({
      values: {
        GrantSupportAccess: !operationCanceled,
      },
    });
  };

  const grantSupportAccessModal = () => {
    return (
      <CDataModalV2
        fade={false}
        modalSize="sm"
        displayed={toggleGrantSupportAccessModal === true}
        close={() => {
          resetForm(true);
        }}
        title="Grant Support Access"
        spacedFooter={false}
        primaryButton={
          <Button
            color="primary"
            onClick={async () => {
              resetForm(false);
              await submitChanges();
            }}
          >
            Accept
          </Button>
        }
        secondaryButton={
          <Button
            color="secondary"
            onClick={() => {
              resetForm(true);
            }}
          >
            Decline
          </Button>
        }
      >
        <div>
          <h5>Terms & Conditions</h5>
          <div>
            By clicking Accept, you agree to allow our support team access to
            your account for troubleshooting purposes as defined in our{" "}
            <a
              href="https://cloud.cdata.com/docs/terms-of-service.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Services
            </a>
            .
          </div>
        </div>
      </CDataModalV2>
    );
  };

  const extendExpirationModal = (
    <CDataModalV2
      fade={false}
      modalSize="lg"
      displayed={toggleExtendExpirationModal === true}
      close={() => {
        setToggleExtendExpirationModal(!toggleExtendExpirationModal);
      }}
      title="Extend Expiration"
      spacedFooter={false}
      primaryButton={
        <Button
          color="primary"
          onClick={() => {
            setToggleExtendExpirationModal(!toggleExtendExpirationModal);
            handleExtendExpiration();
          }}
        >
          Confirm
        </Button>
      }
      secondaryButton={
        <Button
          color="secondary"
          onClick={() => {
            setToggleExtendExpirationModal(!toggleExtendExpirationModal);
          }}
        >
          Cancel
        </Button>
      }
    >
      <div>
        <div>
          By extending your expiration, your support access will be reset and
          will expire 7 days from today. You may choose to disable this setting
          at any time if you wish to revoke access sooner.
        </div>
      </div>
    </CDataModalV2>
  );

  const handleExtendExpiration = async () => {
    const extendedExpirationDate = new Date();
    extendedExpirationDate.setDate(extendedExpirationDate.getDate() + 7);
    formattedDate = dateTimeFormatter(extendedExpirationDate);

    const url = `users/self/extendImpersonationConsent/${props.userInfo.id}`;
    const { status, payload } = await api.callAPI<IUser>(
      RequestType.Put,
      url,
      "Failed to update user impersonation consent due to the following error:",
      extendedExpirationDate,
      undefined,
      undefined,
      isOemSub,
    );

    if (status === 200) {
      await props.setUserInfo(payload);
      ToastrSuccess(
        "User Updated Successfully!",
        "Expiration of Consent for impersonation on this user account has been extended successfully.",
      );
    }
  };

  const initialFormValues: IFormValues = {
    GrantSupportAccess: grantSupportAccess,
  };

  const submitChanges = async () => {
    const url = `users/impersonationConsent/${props.userInfo.id}`;
    const data = {
      expirationDate: grantSupportAccess
        ? consentExpirationDate
        : new Date().toISOString(),
      grantSupportAccess,
    };

    const { status } = await api.callAPI(
      RequestType.Put,
      url,
      "Failed to update user due to the following error:",
      data,
      undefined,
      undefined,
      isOemSub,
    );

    switch (status) {
      case 200:
        {
          props.userInfo.consentExpirationDate =
            consentExpirationDate.toString();
          const status = grantSupportAccess ? "granted" : "revoked";

          ToastrSuccess(
            "User Updated Successfully!",
            `Consent for impersonation on this user account has been ${status} successfully.`,
          );
        }
        break;
      case 403:
        setGrantSupportAccess(false);
        setIsNotImpersonatedQueryUser(true);
        break;
    }
  };

  const handleValidSubmit = async (
    values: FormikValues,
    actions: FormikHelpers<IFormValues>,
  ) => {
    if (grantSupportAccess === true) {
      setToggleGrantSupportAccessModal(true);
    } else {
      await submitChanges();
      actions.resetForm({
        values: {
          GrantSupportAccess: grantSupportAccess,
        },
      });
    }
  };

  return (
    <Container fluid className="p-0 pages-settings-SupportAccessCard">
      <Card>
        <CardBody>
          <Formik
            innerRef={formikRef}
            initialValues={initialFormValues}
            onSubmit={handleValidSubmit}
          >
            {({ handleChange, handleSubmit, values, dirty }) => (
              <Form id="supportAccessForm" onSubmit={handleSubmit}>
                {grantSupportAccessModal()}
                <Row className="mb-3">
                  <Col className="flex-grow-0 align-content-center pb-2 pe-2">
                    <CDataTypography
                      variant="typography-variant-headline-4"
                      className="text-nowrap"
                    >
                      Grant Support Access
                    </CDataTypography>
                  </Col>
                  <Col className="ps-0 pb-2 d-flex">
                    <span
                      className="align-self-center"
                      id="impersonationToggle"
                    >
                      <CDataToggleButton
                        name="GrantSupportAccess"
                        className="align-self-center"
                        aria-label="Grant support access toggle button"
                        checked={grantSupportAccess}
                        onChange={(e) => {
                          setGrantSupportAccess(!!e.target.checked);
                          handleChange(e);
                        }}
                        disabled={
                          isNotImpersonatedQueryUser ||
                          props.isSupportImpersonationActive
                        }
                      />
                    </span>
                    <div
                      className="align-content-center ps-2"
                      hidden={
                        isNotImpersonatedQueryUser ||
                        grantSupportAccess === false
                      }
                    >
                      <CDataTypography variant="typography-variant-body-regular-italic">
                        Expires: {formattedDate}
                      </CDataTypography>
                    </div>
                    {isNotImpersonatedQueryUser && (
                      <UncontrolledTooltip
                        placement="top"
                        target="impersonationToggle"
                        trigger="hover"
                      >
                        Contact your administrator to enable allowing support
                        access to your account.
                      </UncontrolledTooltip>
                    )}
                  </Col>
                  <Col className="text-end">
                    <Button
                      type="button"
                      className="card-actions me-2"
                      hidden={
                        !values.GrantSupportAccess ||
                        isNotImpersonatedQueryUser ||
                        props.isSupportImpersonationActive
                      }
                      disabled={dirty}
                      onClick={() =>
                        setToggleExtendExpirationModal(
                          !toggleExtendExpirationModal,
                        )
                      }
                    >
                      <i className="fa fa-clock small-icon align-middle no-pointer-event" />
                      Extend Expiration
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      className="card-actions float-end"
                      disabled={!dirty || isNotImpersonatedQueryUser}
                      hidden={props.isSupportImpersonationActive}
                      data-testid="support-access-card-save-changes-button"
                    >
                      <i className="fa fa-save small-icon align-middle no-pointer-event" />
                      Save Changes
                    </Button>
                  </Col>
                </Row>
                <CDataTypography className="mb-0">
                  If you&apos;re having trouble with a query, our support team
                  may ask you to grant them access to users in your account to
                  help troubleshoot your issue. Access will automatically expire
                  within 7 days after enabling. If you wish to revoke access
                  sooner, you can disable this setting at any time.
                </CDataTypography>
              </Form>
            )}
          </Formik>
        </CardBody>
      </Card>
      {extendExpirationModal}
    </Container>
  );
};

export default SupportAccessCard;
