export enum ClientTileType {
  ComingSoonTile,
  DocOnlyTile,
  ExternalDocTile,
  ExternalDownloadTile,
  ExternalSubMenuDownloadTile,
  ModalPromptTile,
  UpgradePlanTile,
  OpenAPIModalPromptTitle,
}
