import { CallAPIFunction } from "src/components/useAPI";
import { RequestType } from "../../components/withAPI";
import {
  IBatchDeleteRequest,
  IBatchDeleteResponse,
  IInviteUsersRequest,
  IInviteUsersResponse,
  IPartialUser,
  IResendInviteResponse,
  IUserList,
} from "../../models";

export async function getUserList(
  callAPI: CallAPIFunction,
): Promise<IUserList | null> {
  const { status, payload } = await callAPI(
    RequestType.Get,
    "/users",
    "Failed to get user list due to the following error:",
  );

  if (status === 200) {
    return payload;
  } else {
    return null;
  }
}

export async function inviteUsers(
  callAPI: CallAPIFunction,
  body: IInviteUsersRequest,
): Promise<IInviteUsersResponse | null> {
  const { status, payload } = await callAPI(
    RequestType.Post,
    "/users/invite",
    "Failed to invite users due to the following error:",
    body,
  );

  if (status === 200) {
    return payload;
  } else {
    return null;
  }
}

export async function resendInvite(
  callAPI: CallAPIFunction,
  selectedUser: string,
): Promise<IResendInviteResponse | null> {
  const { status, payload } = await callAPI(
    RequestType.Post,
    `/users/invite/${selectedUser}`,
    "Failed to resend email invite due to the following error:",
  );

  if (status === 200) {
    return payload;
  } else {
    return null;
  }
}

export async function deleteUsers(
  callAPI: CallAPIFunction,
  body: IBatchDeleteRequest,
): Promise<IBatchDeleteResponse | null> {
  const { status, payload } = await callAPI(
    RequestType.Delete,
    "/users",
    "Failed to delete selected user(s) due to the following error:",
    body,
  );

  if (status === 200) {
    return payload;
  } else {
    return null;
  }
}

export async function updateUser(
  callAPI: CallAPIFunction,
  userId: string,
  body: IPartialUser,
): Promise<boolean> {
  const { status } = await callAPI(
    RequestType.Put,
    `/users/${userId}`,
    "Failed to update user due to the following error:",
    body,
  );

  return status === 200;
}
