import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import { Card, CardBody, Container, Col, Row } from "reactstrap";

import Loader from "../../../components/Loader";
import { addWhiteSpace } from "../../../services/textFormatterService";
import { RequestType } from "../../../components/withAPI";
import { ICustomReport, UserRole } from "../../../models";
import { useAPI } from "../../../components/useAPI";
import { ConnectionContext } from "./ConnectionContext";
import { ColumnDef, RowSelectionState } from "@tanstack/react-table";
import ListTable from "../../../components/tables/ListTable";
import {
  ButtonType,
  CDataButton,
} from "../../../components/buttons/CDataButton";
import { SearchInput } from "../../../components/tables/components/SearchInput";
import { CustomReportPickReportTypeModal } from "./CustomReportPickReportTypeModal";
import {
  CustomReportFormModal,
  ICustomReportListItem,
} from "./CustomReportFormModal";
import { CustomReportDeleteReportModal } from "./CustomReportDeleteReportModal";
import { useDetectAdBlock } from "adblock-detect-react";
import { NotificationBar } from "../../../components/notification/NotificationBar";
import { getSelectedNetSuiteSchema } from "./utils/netsuite/getSelectedNetSuiteSchema";
import { useAppSelector } from "../../../redux/hooks";
import { ToastrSuccess } from "../../../services/toastrService";

interface ICustomReportListProps {
  id: string;
  driver: string;
  reportTypes: string[];
}

function CustomReportListCard(props: ICustomReportListProps) {
  const [globalSearchValue, setGlobalSearchValue] = useState("");

  const api = useAPI();
  const navigate = useNavigate();
  const connectionContext = useContext(ConnectionContext);
  const adBlockDetected = useDetectAdBlock();
  const selectedSchema = getSelectedNetSuiteSchema(
    connectionContext.driverInfo,
  );

  const userRole = useAppSelector((state) => state.user.role);
  const singleReport = props.reportTypes
    ? props.reportTypes.length === 1
    : false;

  // Table Variables
  const [createdReportsList, setCreatedReportsList] = useState<ICustomReport[]>(
    [],
  );
  // This is a mapping of a report id to a boolean.
  // The boolean will be true for any report that has the table checkbox checked.
  const [selectedReportNames, setSelectedReportNames] =
    useState<RowSelectionState>({});
  const selectedReports = createdReportsList.filter(
    (r) => selectedReportNames[r.id!] === true,
  );
  const editReportUrl = connectionContext.isOEMConnection
    ? "/oem/user/connections/reports/edit"
    : "/connections/reports/edit";
  // Modal Variables

  // If a connector has multiple custom reports (e.x. NetSuite), we pop up a modal allowing the user to pick
  // what report they want.
  const [isPickReportTypeModalOpen, setIsPickReportTypeModalOpen] =
    useState(false);
  // We show the custom report form in a modal in the initial setup wizard, in the regular connections page this is a separate URL entirely.
  const [isCustomReportModalOpen, setIsCustomReportModalOpen] = useState(false);
  const [reportsToDelete, setReportsToDelete] = useState<ICustomReport[]>([]);
  const deleteButtonEnabled = selectedReports.length > 0;
  const [reportToAdd, setReportToAdd] = useState<ICustomReportListItem | null>(
    null,
  );
  const [showAdBlockBanner, setShowAdBlockBanner] = useState(false);

  useEffect(() => {
    setShowAdBlockBanner(adBlockDetected);
  }, [adBlockDetected]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function initializeData() {
      if (
        (userRole === UserRole.Admin || userRole === UserRole.ServiceUser) &&
        props.id
      ) {
        await getReportList();
      }
      setLoading(false);
    }
    initializeData();
  }, []); // eslint-disable-line

  // TODO: CLOUD-7346 - Remove this if possible, I don't think we really need to make a call to the backend to refresh the list, we can just add the row ourselves
  async function getReportList() {
    const { status, payload } = await api.callAPI(
      RequestType.Get,
      `/account/connections/${props.id}/customReports`,
      "Failed to get report list due to the following error:",
    );

    if (status === 200) {
      const data = payload;
      if (!data.customReports || data.customReports.length === 0) return;
      const reportList = data.customReports;
      setCreatedReportsList(reportList);
    }
  }

  const openEditReportPage = (row: ICustomReport) => {
    navigate(editReportUrl, {
      state: {
        reportType: row.reportType,
        driver: props.driver,
        connectionId: props.id,
        customReportId: row.id,
        schema: selectedSchema,
      },
    });
  };

  function onSelectReportType(reportType: string) {
    setReportToAdd({
      id: undefined,
      name: addWhiteSpace(reportType),
      rawName: reportType,
      parameters: {},
      reportType: "",
    });

    if (connectionContext.isInitialSetupPage) {
      setIsCustomReportModalOpen(true);
    } else {
      navigate(editReportUrl, {
        state: {
          reportType: reportType,
          driver: props.driver,
          connectionId: props.id,
          schema: selectedSchema,
          overrideConnectionType: connectionContext.overrideConnectionType,
        },
      });
    }
  }

  const onClickAddReportButton = async () => {
    // If there is only one custom report for the driver, this sets it to the active report and opens it.
    if (singleReport) {
      onSelectReportType(props.reportTypes[0]);
    } else {
      setIsPickReportTypeModalOpen(true);
    }
  };

  const duplicateReport = (report: ICustomReport) => {
    navigate(editReportUrl, {
      state: {
        reportType: report.reportType,
        driver: props.driver,
        connectionId: props.id,
        isDuplicating: true,
        createdReportsList: createdReportsList,
        customReportId: report.id,
        schema: selectedSchema,
      },
    });
  };

  if (loading) {
    return <Loader />;
  }

  const reportListColumns: ColumnDef<ICustomReport>[] = [
    {
      id: "name",
      accessorKey: "name",
      header: "Report",
      enableSorting: true,
    },
    {
      id: "reportType",
      accessorFn: (row) => {
        return addWhiteSpace(row.reportType!);
      },
      header: "Report Type",
      enableSorting: true,
    },
    {
      id: "editButtons",
      header: "",
      enableSorting: false,
      cell: (context) => {
        const report = context.row.original;
        return (
          <span className="d-flex justify-content-end">
            <CDataButton
              buttonType={ButtonType.Borderless}
              data-testid={`link-edit-report-${report.name}`}
              className="text-dark align-middle"
              hidden={connectionContext.isInitialSetupPage}
              onClick={(event) => {
                event.stopPropagation();
                openEditReportPage(report);
              }}
              title="Edit Report"
              aria-label={`Edit Report ${report.name}`}
            >
              <i className="fa-regular fa-pen align-middle" />
            </CDataButton>
            {!connectionContext.isInitialSetupPage && (
              <CDataButton
                buttonType={ButtonType.Borderless}
                data-testid={`button-duplicate-report-${report.name}`}
                className="text-dark"
                onClick={(event) => {
                  event.stopPropagation();
                  duplicateReport(report);
                }}
                title="Duplicate Report"
                aria-label={`Duplicate Report ${report.name}`}
              >
                <i className="fa fa-regular fa-clone fa-md align-middle" />
              </CDataButton>
            )}
            <CDataButton
              buttonType={ButtonType.Borderless}
              data-testid={`button-delete-report-${report.name}`}
              className="text-dark"
              onClick={(event) => {
                event.stopPropagation();
                setReportsToDelete([report]);
              }}
              title="Delete Report"
              aria-label={`Delete Report ${report.name}`}
            >
              <i className="fa-regular fa-xmark fa-lg align-middle" />
            </CDataButton>
          </span>
        );
      },
    },
  ];

  const createdReportsListTable = (
    <ListTable
      columns={reportListColumns}
      data={createdReportsList}
      enableCheckboxes
      enableFiltering={false}
      enablePagination
      emptyTableMessage="Click the Add Report button to create a new custom report."
      globalSearchValue={globalSearchValue}
      defaultSort={[
        {
          desc: false,
          id: "name",
        },
      ]}
      selection={selectedReportNames}
      setSelection={setSelectedReportNames}
      onRowClick={(row) => {
        !connectionContext.isInitialSetupPage ? openEditReportPage(row) : null;
      }}
    />
  );

  return (
    <Container
      data-testid="card-custom-report-list"
      fluid
      className="p-0 pages-connections-CustomReportListCard"
    >
      {showAdBlockBanner && (
        <Row className="mb-4" data-testid="ad-block-banner">
          <Col>
            <NotificationBar
              message="Ad Blocker Detected: To create Custom Reports, make sure you disable your ad blocker or whitelist this site."
              barColor="notification-bar-orange"
              onClose={() => setShowAdBlockBanner(false)}
            />
          </Col>
        </Row>
      )}
      {connectionContext.isInitialSetupPage ? (
        <Card>
          <CardBody className="initial-reports-banner">
            <Col className="alert-circle col-md-2">
              <i className="fa fa-info-circle align-middle fa-lg" />
            </Col>
            <Col className="reports-text">
              If you cannot find the table or view you are looking to query for
              this connection, you may need to set up Custom Reports. You can do
              that now, or you can choose to do it later.
            </Col>
          </CardBody>
        </Card>
      ) : null}
      <div>
        <Card>
          <CardBody>
            <Col className="searchbar-btn-outer">
              <SearchInput
                searchPlaceholder="Search by report..."
                className="search-input"
                value={globalSearchValue}
                setValue={setGlobalSearchValue}
              />
              <span
                id="addReportButton"
                className="add-delete-btn-outer col flex-grow-0"
              >
                <CDataButton
                  data-testid="button-delete-report"
                  disabled={!deleteButtonEnabled}
                  buttonType={ButtonType.Secondary}
                  onClick={(event) => {
                    event.stopPropagation();
                    setReportsToDelete(selectedReports);
                  }}
                  className="me-1 card-actions"
                >
                  <i className="fa fa-times align-middle me-2 no-pointer-event" />
                  Delete
                </CDataButton>
                <CDataButton
                  data-testid="button-add-report"
                  buttonType={ButtonType.Primary}
                  onClick={() => onClickAddReportButton()}
                >
                  <i className="fa fa-plus icon no-pointer-event" />
                  Add
                </CDataButton>
              </span>
            </Col>
            <div data-testid="table-reports-list">
              {createdReportsListTable}
            </div>
          </CardBody>
        </Card>
      </div>

      {/* Destroy this modal when it closes so the selection is reset. */}
      {isPickReportTypeModalOpen && (
        <CustomReportPickReportTypeModal
          reportTypes={props.reportTypes}
          isModalOpen={isPickReportTypeModalOpen}
          onCloseModal={() => {
            setReportToAdd(null);
            setIsPickReportTypeModalOpen(false);
          }}
          onSelectReportType={(reportType) => {
            setIsPickReportTypeModalOpen(false);
            onSelectReportType(reportType);
          }}
        />
      )}

      {/* Destroy this modal when it closes so the state is reset. */}
      {isCustomReportModalOpen && (
        <CustomReportFormModal
          isModalOpen={isCustomReportModalOpen}
          onCloseModal={() => setIsCustomReportModalOpen(false)}
          onBackButton={() => {
            setIsCustomReportModalOpen(false);
            if (!singleReport) {
              setIsPickReportTypeModalOpen(true);
            }
          }}
          reportTypes={props.reportTypes}
          selectedReport={reportToAdd}
          connectionId={props.id}
          driver={props.driver}
          onSaveReport={(status, reportResponse) => {
            if (status === 200 && !reportResponse.testCustomReportError) {
              setReportToAdd(null);
              setIsCustomReportModalOpen(false);
            }

            getReportList();
          }}
        />
      )}

      <CustomReportDeleteReportModal
        key="DeleteReportModal"
        connectionId={props.id}
        selectedReports={reportsToDelete}
        isModalOpen={reportsToDelete.length > 0}
        onCloseModal={() => setReportsToDelete([])}
        onDeleteSucceeded={(reports, bulkDeleteResponse) => {
          setReportsToDelete([]);

          const deletedReportIds = new Set(
            bulkDeleteResponse != null
              ? bulkDeleteResponse.deletedIds
              : reports.map((r) => r.id!),
          );

          const updatedReportList = createdReportsList.filter((report) => {
            return !deletedReportIds.has(report.id!);
          });

          ToastrSuccess(
            `Custom ${reportsToDelete.length > 1 ? "Reports" : "Report"} Deleted Successfully!`,
            `Your custom ${reportsToDelete.length > 1 ? "reports have" : "report has"} been deleted successfully.`,
          );

          setSelectedReportNames({});
          setCreatedReportsList(updatedReportList);
        }}
      />
    </Container>
  );
}

export default CustomReportListCard;
