import { Ref, useEffect } from "react";
import { Form, Formik, FormikProps, useFormikContext } from "formik";
import * as Yup from "yup";

import TertiaryButton from "../../../../../../components/buttons/TertiaryButton";
import { Column } from "../../../SetTable";
import { RepeatElementsTooltip } from "./RepeatElementsTooltip";
import { ToastrError } from "../../../../../../services/toastrService";
import TextInput from "../../../../../../components/form/TextInput";
import { IAPIConnectorSettings } from "../../../types/IAPIConnectorSettings";

interface ICustomRootPathsProps {
  apiSettings: IAPIConnectorSettings;

  onNextStep: () => void;

  selectedRootPaths: string[];
  setSelectedRootPaths: (rootPaths: string[]) => void;

  setSelectedColumns: (columns: Column[]) => void;

  formRef: Ref<FormikProps<ICustomRootPathsFormData>>;
}

export type ICustomRootPathsFormData = {
  selectedRootPaths: string[];
};

const validationSchema = Yup.object().shape({
  selectedRootPaths: Yup.array().of(
    Yup.string().required("Repeat element is required"),
  ),
});

export const CustomRootPaths = (props: ICustomRootPathsProps) => {
  const {
    selectedRootPaths,
    setSelectedRootPaths,
    setSelectedColumns,
    formRef,
    onNextStep,
  } = props;

  return (
    <div>
      <div>
        <RepeatElementsTooltip />
      </div>

      <Formik<ICustomRootPathsFormData>
        innerRef={formRef}
        initialValues={{
          selectedRootPaths: selectedRootPaths,
        }}
        validationSchema={validationSchema}
        validateOnChange
        onSubmit={(formData) => {
          if (formData.selectedRootPaths.length === 0) {
            ToastrError(
              "Repeat element not selected",
              "Enter at least one repeat element to continue",
            );
          } else {
            setSelectedRootPaths(formData.selectedRootPaths);
            onNextStep();
          }
        }}
      >
        {() => {
          return (
            <Form>
              <CustomRootPathsFormBody
                setSelectedRootPaths={setSelectedRootPaths}
                setSelectedColumns={setSelectedColumns}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

type ICustomRootPathsFormBodyProps = {
  setSelectedRootPaths: (rootPaths: string[]) => void;
  setSelectedColumns: (columns: Column[]) => void;
};

const CustomRootPathsFormBody = (props: ICustomRootPathsFormBodyProps) => {
  const { setSelectedRootPaths, setSelectedColumns } = props;
  const { values, setValues, dirty } =
    useFormikContext<ICustomRootPathsFormData>();

  useEffect(() => {
    // Make sure we have at least one root path to show the user so they
    // see the input field.
    if (
      values.selectedRootPaths == null ||
      values.selectedRootPaths.length === 0
    ) {
      setValues({
        ...values,
        selectedRootPaths: [""],
      });
    } else {
      // Set the root paths in the parent to disable the Next button if the user has validation errors.
      setSelectedRootPaths(values.selectedRootPaths);

      // If the user changed anything in the form, reset the columns.
      if (dirty) {
        setSelectedColumns([]);
      }
    }
  }, [values.selectedRootPaths]); // eslint-disable-line

  return (
    <div className="pages-apiConnector-components-CustomRootPaths">
      <div className="rootPathsListContainer">
        {values.selectedRootPaths.map((rootPath, index) => {
          return (
            // key needs to be the index here so it doesn't change as the user changes the root path.
            // changing the key breaks the keyboard focus.
            <div key={index.toString()} className="rootPathRowContainer">
              <div className="rootPathRow-inputAndErrorContainer">
                <TextInput
                  className="rootPathInput"
                  data-testid="rootPathInput"
                  label=""
                  name={`selectedRootPaths[${index}]`}
                />
              </div>

              {values.selectedRootPaths.length > 1 && (
                <TertiaryButton
                  onClick={() => {
                    setValues(
                      {
                        ...values,
                        selectedRootPaths: values.selectedRootPaths.filter(
                          (_rootPath, otherIndex) => index !== otherIndex,
                        ),
                      },
                      false,
                    );
                  }}
                  aria-label={`Delete Repeat Element ${rootPath}`}
                >
                  <i className="fa fa-trash-alt customRootPaths-trashIcon" />
                </TertiaryButton>
              )}
            </div>
          );
        })}
      </div>

      <TertiaryButton
        onClick={() => {
          setValues(
            {
              ...values,
              selectedRootPaths: [...values.selectedRootPaths, ""],
            },
            false,
          );
        }}
      >
        <i className="fa fa-plus icon" />
        Add Repeat Element
      </TertiaryButton>
    </div>
  );
};
