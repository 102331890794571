//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum JobStatus {
  JOB_CREATED = 1,
  JOB_QUEUED = 2,
  JOB_RUNNING = 3,
  JOB_COMPLETED = 4,
  JOB_FAILED = 5,
  JOB_CANCELED = 6
}
