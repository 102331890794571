import { useState, useMemo } from "react";
import classnames from "classnames";
import { Badge, Card, Col, Row } from "reactstrap";
import { ListGroup } from "react-bootstrap";
import {
  compareDrivers,
  driverCategories,
} from "../../../../../components/drivers/DriverUtilities";
import { useAPI } from "../../../../../components/useAPI";
import { useFlags } from "flagsmith/react";
import { FeatureId, IPartialDriver } from "../../../../../models";
import FilterGrid from "../../../../../components/FilterGrid";
import { DataSourceCard } from "../../../../connections/components/mapDataSourceCard";
import { isFeatureAllowed } from "../../../../../utility/SubscriptionAddonsFactory";
import { DriverConnectionTile } from "../../../../../components/drivers/DriverConnectionTile";
import { goToDashboard } from "../../../goToDashboard";
import { useAppSelector } from "../../../../../redux/hooks";
import { SignupReasonModal } from "./SignupReasonModal";
import { useQuery } from "@tanstack/react-query";
import { getSignupReasons } from "./getSignupReasons";
import Loader from "../../../../../components/Loader";
import { ISelectedConnection } from "../../InitialSetupContext";

export interface ISelectInitialConnectionProps {
  hashKey?: string;
  updateSelectedConnection: (selectedConnection: ISelectedConnection) => void;
  nextStep?: () => void;
  currentStep?: number;
}

export const SelectInitialConnection = (
  props: ISelectInitialConnectionProps,
) => {
  const flagsmith = useFlags(["oracle_oci", "signup_wizard_reason_selector"]);
  const api = useAPI();

  const [activeCategoryFilter, setActiveCategoryFilter] = useState("all");
  const [showSignupReasonModal, setShowSignupReasonModal] = useState(true);

  const allDrivers = useAppSelector((state) => state.driversList?.drivers);
  const availableFeatureIds = useAppSelector(
    (state) => state.subscription?.limits.availableFeatureIds,
  );

  const { data: existingSignupReasons, isPending: loadingSignupReasons } =
    useQuery({
      queryKey: ["/signupreasons/get"],
      queryFn: () => getSignupReasons(),
      meta: {
        errorMessage:
          "Failed to get signup reasons due to the following error:",
      },
    });

  const drivers: IPartialDriver[] = useMemo(() => {
    const filteredDrivers =
      allDrivers?.filter((driverItem) => {
        if (driverItem.hidden || driverItem.driver === "REST") {
          return false;
        }

        if (driverItem.driver === "OracleOci") {
          return flagsmith.oracle_oci.enabled;
        }

        return true;
      }) ?? [];

    filteredDrivers.sort(compareDrivers);

    return filteredDrivers;
  }, []); // eslint-disable-line

  const categories = Object.keys(driverCategories).map((key) => {
    let categoryCount;
    if (driverCategories[key] === "All") {
      categoryCount = drivers.length;
    } else {
      categoryCount = drivers.filter((driver) => {
        return driver.category === key;
      }).length;
    }

    return (
      <ListGroup.Item
        action
        key={key}
        className={classnames({
          active: activeCategoryFilter === key,
        })}
        onClick={() => setActiveCategoryFilter(key)}
      >
        {driverCategories[key]}
        <Badge color="">{categoryCount}</Badge>
      </ListGroup.Item>
    );
  });

  if (loadingSignupReasons) return <Loader />;

  let connectionCards: DataSourceCard[];
  if (drivers.length > 0) {
    connectionCards = drivers.map((driver, index) => {
      const featureIdToCheck =
        driver.driver === "REST"
          ? FeatureId.APIConnector
          : FeatureId.PremiumDataSources;

      const isPremiumAndUserLacksFeature =
        driver.premium &&
        !isFeatureAllowed(availableFeatureIds ?? [], featureIdToCheck);

      const goToNextStep = () => {
        const selectedConnection = {
          driver: driver.driver!,
          niceName: driver.niceName!,
        };

        props.updateSelectedConnection(selectedConnection);
        props.nextStep!();
      };

      const clientCard = (
        <DriverConnectionTile
          driver={driver.driver!}
          isBeta={driver.beta}
          niceName={driver.niceName!}
          onClick={goToNextStep}
          disabled={isPremiumAndUserLacksFeature}
        />
      );
      const connection: DataSourceCard = {
        id: index,
        tile: clientCard,
        driverName: driver.driver!,
        name: driver.niceName!,
        category: driver.category!,
        isPremium: isPremiumAndUserLacksFeature,
      };

      return connection;
    });

    return (
      <div className="pages-initialSetup-components-Steps-1-SelectConnection-SelectInitialConnection">
        <Row className="mb-4">
          <h1 className="h3 text-center setup-wizard-title">
            Setup Your First Connection
          </h1>
        </Row>
        <Row className="mb-2 prompt-dashboard-row">
          <Col>
            To get started, select a data source from the list below to begin
            making the most of CData Connect Cloud.
          </Col>
          <Col sm="3" className="button-col">
            <button
              onClick={() => {
                window.DD_RUM?.addAction("go to dashboard on step-1 clicked");
                goToDashboard(api.callAPI);
              }}
              className=" mb-2 skip-button"
              data-testid="button-go-to-dashboard"
              data-dd-action-name="go-to-dashboard-on-step-1"
            >
              Go to Dashboard
            </button>
          </Col>
        </Row>
        <Row className="data-source-connection-container">
          <Card className="data-source-selector">
            <ListGroup>
              <ListGroup.Item className="fw-bold">Data Sources</ListGroup.Item>
              {categories}
            </ListGroup>
          </Card>
          <Col className="connection-table list-connection-grid">
            <FilterGrid
              categories={driverCategories}
              selectedCategory={activeCategoryFilter}
              tiles={connectionCards}
              tileTypes="Connections"
            />
          </Col>
        </Row>
        {!existingSignupReasons ||
          (existingSignupReasons?.length === 0 && (
            <SignupReasonModal
              showModal={showSignupReasonModal}
              setShowModal={setShowSignupReasonModal}
            />
          ))}
      </div>
    );
  }
};
