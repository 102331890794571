export const getDataTypeName = (datatype: number) => {
  switch (datatype) {
    case 1:
    case 2:
    case 3:
    case 4:
      return "binary";
    case 5:
      return "varchar";
    case 6:
      return "tinyint";
    case 7:
      return "smallint";
    case 8:
      return "int";
    case 9:
      return "bigint";
    case 10:
      return "real";
    case 11:
      return "float";
    case 12:
      return "decimal";
    case 13:
      return "numeric";
    case 14:
      return "bool";
    case 15:
      return "date";
    case 16:
      return "time";
    case 17:
      return "datetime";
    case 18:
      return "uuid";
    default:
      return "unknown";
  }
};
