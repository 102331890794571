import { ChangeEvent } from "react";
import { CDataTextField } from "src/components/form/CDataTextField";

export interface ILimitControl {
  queryLimit: number;
  setQueryLimit: (queryLimit: number) => void;
}

export const LimitControl = (props: ILimitControl) => {
  const { queryLimit, setQueryLimit } = props;

  const setLimit = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const value = parseInt(event.target.value, 10);

    if (value > 0 || event.target.value === "") {
      setQueryLimit(value);
    }
  };

  return (
    <CDataTextField
      value={queryLimit}
      type="number"
      inputProps={{ min: "0" }}
      className="d-inline-block limit-control"
      onChange={(event) => setLimit(event)}
      data-testid="limit-value"
    />
  );
};
