import { useState, useEffect } from "react";
import DataExplorerLeftPanel from "./LeftPanel/DataExplorerLeftPanel";
import DataExplorerRightPanel from "./RightPanel/DataExplorerRightPanel";
import { Button, Col, Row, Card } from "reactstrap";
import { ToastrError } from "../../services/toastrService";
import { LeftPanelType } from "./LeftPanel/leftPanelType";
import { RequestType, BackendType } from "../../components/withAPI";
import { IDerivedView, IWorkspace } from "../../models";
import AIWizardFlyout from "./AIWizardFlyout/AIWizardFlyout";
import { useAPI } from "../../components/useAPI";
import { QueryTabsContext } from "./RightPanel/Tabs/QueryTabsContext";
import { IQueryTab } from "./RightPanel/Tabs/useQueryTabs";
import {
  updateDataExplorerQueryTabTitleCounter,
  updateDataExplorerSelectedTab,
  updateDataExplorerTabs,
  updateDataExplorerTabTitleCounter,
} from "../../redux/actions";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getConnections } from "../../api/Connections/getConnections";
import { useQuery } from "@tanstack/react-query";
import Page500 from "../auth/Page500";
import { ISavedQuery } from "../../models/SavedQueries/ISavedQuery";
import { ISavedQueryList } from "../../models/SavedQueries/ISavedQueryList";

const DataExplorer = () => {
  const api = useAPI();
  const dataExplorer = useAppSelector((state) => state.dataExplorerTabs);
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);

  const [derivedViews, setDerivedViews] = useState<IDerivedView[]>([]);
  const [derivedViewsLoading, setDerivedViewsLoading] =
    useState<boolean>(false);
  const [savedQueries, setSavedQueries] = useState<ISavedQuery[]>([]);
  const [savedQueriesLoading, setSavedQueriesLoading] =
    useState<boolean>(false);
  const [workspaces, setWorkspaces] = useState<IWorkspace[]>([]);
  const [sidebarView, setSidebarView] = useState<LeftPanelType>(
    LeftPanelType.Connections,
  );
  const [aiExplorerVisible, setAiExplorerVisible] = useState<boolean>(false);
  const [tabs, setTabs] = useState<IQueryTab[]>(dataExplorer.tabs);
  const [selectedTab, setSelectedTab] = useState<number>(
    dataExplorer.selectedTab,
  );
  const [queryTabTitleCounter, setQueryTabTitleCounter] = useState(
    dataExplorer.queryTabTitleCounter,
  );
  const [queryBuilderTabTitleCounter, setQueryBuilderTabTitleCounter] =
    useState(dataExplorer.queryBuilderTabTitleCounter);

  const header = { "Connect-Cloud-Client": "CDataDataExplorer" };

  const { data, error } = useQuery({
    queryKey: ["/account/connections"],
    queryFn: () => getConnections(user.role, user.id, header),
    meta: {
      errorMessage:
        "Failed to get user defined connection list due to the following error:",
    },
  });
  const connectionsList = data?.connections ?? [];

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        fetchDerivedViews().then((r) => setDerivedViews(r)),
        fetchSavedQueries().then((r) => setSavedQueries(r)),
        fetchWorkspaces().then((r) => setWorkspaces(r)),
      ]);
    };
    fetchData();
  }, []); // eslint-disable-line

  const getComponentName = () => {
    return "pages-dataExplorer-DataExplorer";
  };

  const fetchSavedQueries = async () => {
    setSavedQueriesLoading(true);

    const { status, payload } = await api.callAPI<ISavedQueryList>(
      RequestType.Get,
      "/users/self/savedQueries",
      "Error fetching saved queries:",
    );

    if (status === 200) {
      const data = payload;
      setSavedQueriesLoading(false);
      return data?.savedQueries ?? [];
    } else {
      const err = payload;
      errorCallback(`Error fetching saved queries: ${err}`);
    }
    setSavedQueriesLoading(false);
    return [];
  };

  const fetchAndSetSavedQueries = async () => {
    const newSavedQueries = await fetchSavedQueries();
    setSavedQueries(newSavedQueries);
  };

  const fetchDerivedViews = async () => {
    setDerivedViewsLoading(true);

    const { status, payload, error } = await api.callAPI(
      RequestType.Get,
      "/account/derivedViews",
      "Error fetching results:",
    );
    if (status === 200) {
      const derivedViewList = payload?.derivedViews ?? [];
      derivedViewList.forEach(
        (derivedView: any) => (derivedView.accountId = payload?.accountId),
      ); //to match model
      setDerivedViewsLoading(false);
      return derivedViewList;
    } else {
      errorCallback(`Error fetching saved queries: ${error?.message}`);
    }
    setDerivedViewsLoading(false);
    return [];
  };

  const fetchAndSetSavedDerivedViews = async () => {
    const newDerivedViews = await fetchDerivedViews();
    setDerivedViews(newDerivedViews);
  };

  const fetchWorkspaces = async () => {
    const { status, payload, error } = await api.callAPI(
      RequestType.Get,
      "/workspaces/forCurrentUser",
      "Error fetching workspaces:",
      BackendType.AccountService,
    );

    if (status === 200) {
      const data = payload.workspaces;
      return data;
    } else {
      errorCallback(`Error fetching workspaces: ${error?.message}`);
    }
  };

  const fetchAndSetSavedWorkspaces = async () => {
    const newWorkspaces = await fetchWorkspaces();
    setWorkspaces(newWorkspaces);
  };

  const errorCallback = (errorMsg: any) => {
    ToastrError("Error fetching results", errorMsg);
  };

  const updateDerivedViews = (derivedViews: IDerivedView[]) => {
    const derivedViewsSorted = derivedViews.sort((a: any, b: any) =>
      a.name > b.name ? 1 : -1,
    );
    setDerivedViews(derivedViewsSorted);
  };

  useEffect(() => {
    dispatch(updateDataExplorerTabs(tabs));
  }, [tabs]); // eslint-disable-line

  if (error) {
    return <Page500 error={error} />;
  }

  return (
    <div id="data-explorer-container" className={`h-100 ${getComponentName()}`}>
      <QueryTabsContext.Provider
        value={{
          tabs: tabs,
          setTabs: setTabs,
          selectedTab: selectedTab,
          setSelectedTab: (newSelectedTab) => {
            setSelectedTab(newSelectedTab);
            dispatch(updateDataExplorerSelectedTab(newSelectedTab));
          },
          queryTabTitleCounter: queryTabTitleCounter,
          setQueryTabTitleCounter: (newCounter) => {
            setQueryTabTitleCounter(newCounter);
            dispatch(updateDataExplorerTabTitleCounter(newCounter));
          },
          queryBuilderTabTitleCounter: queryBuilderTabTitleCounter,
          setQueryBuilderTabTitleCounter: (newCounter) => {
            setQueryBuilderTabTitleCounter(newCounter);
            dispatch(updateDataExplorerQueryTabTitleCounter(newCounter));
          },
        }}
      >
        <Row>
          <Col>
            <h1 className="h3 ms-1 mb-4">Data Explorer</h1>
          </Col>
          <Col className="float-end-ai-btn ps-2" hidden={aiExplorerVisible}>
            <Button
              data-testid="button-open-ai-generator"
              color="primary"
              className="button-open-ai-generator"
              onClick={() => {
                setAiExplorerVisible(true);
              }}
            >
              <span style={{ marginRight: "8px" }}>
                <i className="fa fa-solid fa-sparkles align-self-center" />
              </span>
              AI Generator
            </Button>
          </Col>
          <AIWizardFlyout
            isVisible={aiExplorerVisible}
            closeWizard={() => setAiExplorerVisible(false)}
          />
        </Row>
        <Card className="mb-0" id="data-explorer-card">
          <Row className="h-100 ms-0 ps-0">
            <DataExplorerLeftPanel
              derivedViews={derivedViews}
              updateDerivedViews={updateDerivedViews}
              derivedViewsLoading={derivedViewsLoading}
              errorCallback={errorCallback}
              savedQueries={savedQueries}
              fetchDerivedViews={fetchAndSetSavedDerivedViews}
              fetchSavedQueries={fetchAndSetSavedQueries}
              fetchWorkspaces={fetchAndSetSavedWorkspaces}
              sidebarView={sidebarView}
              setSidebarView={setSidebarView}
              workspaces={workspaces}
              savedQueriesLoading={savedQueriesLoading}
              connectionsList={connectionsList}
            />
            <DataExplorerRightPanel
              setSidebarView={setSidebarView}
              fetchDerivedViews={fetchAndSetSavedDerivedViews}
              fetchSavedQueries={fetchAndSetSavedQueries}
              connectionsList={connectionsList}
            />
          </Row>
        </Card>
      </QueryTabsContext.Provider>
    </div>
  );
};

export default DataExplorer;
