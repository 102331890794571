import { CDataModalV2 } from "../../../../components/modal/CDataModalV2";
import { IDerivedView } from "../../../../models";
import {
  ButtonType,
  CDataButton,
} from "../../../../components/buttons/CDataButton";
import { useAPI } from "../../../../components/useAPI";
import { RequestType } from "../../../../components/withAPI";
import { ToastrSuccess } from "../../../../services/toastrService";
import { useState } from "react";
import { Spinner } from "reactstrap";

export type DeleteDerivedViewModalProps = {
  displayed: boolean;
  close: () => void;
  derivedViews: IDerivedView[];
  updateDerivedViews: (derivedViews: IDerivedView[]) => void;
  targetDerivedView: IDerivedView | null;
  setTargetDerivedView: (newTargetDerivedView: IDerivedView | null) => void;
};

export function DeleteDerivedViewModal(props: DeleteDerivedViewModalProps) {
  const api = useAPI();

  const {
    displayed,
    close,
    derivedViews,
    updateDerivedViews,
    targetDerivedView,
    setTargetDerivedView,
  } = props;

  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const deleteDerivedView = async (selectedDerivedView: any) => {
    setIsProcessing(true);
    const url = `/account/derivedViews/${encodeURIComponent(selectedDerivedView.id)}`;
    const { status } = await api.callAPI(
      RequestType.Delete,
      url,
      "Failed to delete derived view due to the following error:",
    );
    if (status === 200) {
      const updatedDerivedViewList = [
        ...derivedViews.filter((dv) => dv.id !== selectedDerivedView.id),
      ];
      updateDerivedViews(updatedDerivedViewList);
      ToastrSuccess(
        "Derived View Successfully Deleted!",
        `${selectedDerivedView.name} was successfully deleted.`,
      );
      setTargetDerivedView(null);
    }

    setIsProcessing(false);
  };

  return (
    <>
      <CDataModalV2
        modalSize="lg"
        displayed={displayed}
        close={() => {
          setTargetDerivedView(null);
          close();
        }}
        title="Delete Derived View"
        spacedFooter={false}
        primaryButton={
          <CDataButton
            buttonType={ButtonType.Danger}
            onClick={() => {
              deleteDerivedView(targetDerivedView);
              close();
            }}
          >
            Delete
          </CDataButton>
        }
        secondaryButton={
          <CDataButton
            buttonType={ButtonType.Secondary}
            onClick={() => {
              setTargetDerivedView(null);
              close();
            }}
          >
            Cancel
          </CDataButton>
        }
      >
        You are about to delete the derived view, {targetDerivedView?.name}. By
        processing this request, any OData entity sets that originate from it
        will also be removed. Are you sure you want to proceed?
      </CDataModalV2>
      <div hidden={!isProcessing}>
        <div className="loading-background" />
        <Spinner className="spinner-border loading-spinner" color="info" />
      </div>
    </>
  );
}
