import { IDriverExtended } from "../../../../../bffmodels/IDriverExtended";
import { compareStrings } from "../../../../../utility/CompareStrings";

/**
 * Returns the selected Schema field if the user is using a NetSuite driver.
 * Returns null if this is not a NetSuite driver.
 */
export function getSelectedNetSuiteSchema(
  driverInfo: IDriverExtended,
): string | null {
  // For NetSuite, we only show the Custom Reports tab is SuiteTalk is selected in the Schema dropdown.
  if (compareStrings(driverInfo.name, "NetSuite")) {
    const schemaProp = driverInfo.basicProps?.find((b) =>
      compareStrings(b.name, "Schema"),
    );
    if (schemaProp != null && schemaProp.currentValue) {
      return schemaProp.currentValue;
    }
  }

  return null;
}
