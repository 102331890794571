import React, { useContext } from "react";

import { Card, CardBody, Container } from "reactstrap";

import { Form } from "react-bootstrap";
import { ConnectionProperty } from "./";
import { ConnectionContext } from "./ConnectionContext";
import { compareStrings } from "../../../utility/CompareStrings";
import { IHierarchyCategoryExtended } from "../../../bffmodels/IHierarchyCategoryExtended";
import { IHierarchyPropertyExtended } from "../../../bffmodels/IHierarchyPropertyExtended";

interface IAdvancedSettingsProps {
  location: string;
  displayedBasicDriverProperties: any[];
}

export function AdvancedSettings(props: IAdvancedSettingsProps) {
  const connectionContext = useContext(ConnectionContext);
  const advancedDriverProperties = connectionContext.driverInfo!.advancedProps;

  // Handle special case where miscellaneous properties will be returned as a HierarchyCategory with no category name.
  const miscellaneousCategoryIndex = advancedDriverProperties.findIndex(
    (category: IHierarchyCategoryExtended) => category.name === "",
  );
  if (miscellaneousCategoryIndex !== -1) {
    advancedDriverProperties.push(
      advancedDriverProperties.splice(miscellaneousCategoryIndex, 1)[0],
    );
  }

  // Drivers flag SSH properties as advanced. However, we override this and treat them as basic. We only want specific SSH properties to appear under advanced.
  const sshPropCategory = advancedDriverProperties.find(
    (prop) => prop.name === "SSH",
  );
  const filteredSshPropNames =
    sshPropCategory?.properties
      ?.filter(
        (prop) =>
          prop.propertyName !== "SSHClientCertSubject" &&
          prop.propertyName !== "SSHServerFingerprint", // TODO: Remove me in CLOUD-11995 to re-enable suppression of fingerprint field
      )
      .map((prop) => prop.propertyName) ?? [];

  const advancedSettingsBody = advancedDriverProperties.map(
    (category: IHierarchyCategoryExtended) => {
      const categoryName =
        category.name !== "" ? category.name : "Miscellaneous";
      const categoryProperties = category.properties!.map(
        (advancedProperty: IHierarchyPropertyExtended) => {
          // Hide properties already displayed under the basic tab
          const alreadyDisplayed: boolean =
            props.displayedBasicDriverProperties.some(
              (basicProperty: any) =>
                basicProperty.key.includes(advancedProperty.propertyName) ||
                (compareStrings(advancedProperty.propertyName, "AuthScheme") &&
                  basicProperty.key.includes("AuthScheme")),
            );

          const isBasicSSHProperty: boolean = filteredSshPropNames.includes(
            advancedProperty.propertyName,
          );

          return (
            <ConnectionProperty
              key={advancedProperty.propertyName!}
              location={props.location}
              property={advancedProperty}
              tab="basic"
              hidden={alreadyDisplayed || isBasicSSHProperty}
            />
          );
        },
      );

      return (
        <Card
          data-testid={`category-${categoryName}`}
          key={categoryName}
          hidden={categoryProperties.every(
            (advancedProperty: any) => advancedProperty.props.hidden === true,
          )}
        >
          <CardBody>
            <Form.Group>
              <h5 className="card-title mb-3">{categoryName}</h5>
              {categoryProperties}
            </Form.Group>
          </CardBody>
        </Card>
      );
    },
  );

  return (
    <Container
      data-testid="advanced-settings"
      fluid
      className="p-0 pages-connections-components-AdvancedSettings"
    >
      {advancedSettingsBody}
    </Container>
  );
}
