import { useContext, useEffect, useState } from "react";
import { Button } from "reactstrap";
import { IModalProps } from "../../../components/CDataModal";
import { ModalContext } from "../../../routes/ModalContext";
import SelectAssetsTable from "./SelectAssetsTable";
import { RequestType } from "../../../components/withAPI";
import { useAPI } from "../../../components/useAPI";
import { ToastrSuccess } from "../../../services/toastrService";

interface CreateAssetModalProps {
  isModalOpen: boolean;
  setIsMoveWorkspaceOpen: (isMoveWorkspaceOpen: boolean) => void;
  isDerivedView: boolean;
  derivedViewId?: string;
  connectionId?: string;
  schemaName?: string;
  tableName?: string;
}

export const CreateAssetModal = (props: CreateAssetModalProps) => {
  const modalContext = useContext(ModalContext);
  const api = useAPI();
  const [workspaceId, setWorkspaceId] = useState<string>();
  const [folderId, setFolderId] = useState<string>();
  const [selectedName, setSelectedName] = useState<string>();

  useEffect(() => {
    if (props.isModalOpen) {
      modalContext.setModal(modal);
    } else {
      modalContext.toggleModal();
    }
  }, [props.isModalOpen]); // eslint-disable-line

  useEffect(() => {
    if (props.isModalOpen) {
      modalContext.setModal(modal);
    }
  }, [workspaceId, folderId]); // eslint-disable-line

  const title = "Add to Workspace";
  const helperText = "Select the Workspace you'd like to move this asset to.";
  const pageName = "dataExplorer";

  const createWorkspaceItem = async () => {
    if (props.isDerivedView) {
      const body = {
        DerivedViewId: props.derivedViewId,
        parentId: folderId,
      };
      const { status } = await api.callAPI(
        RequestType.Post,
        `/workspaces/${workspaceId}/assets/fromDerivedView`,
        "Error creating folder:",
        body,
      );
      if (status === 200) {
        ToastrSuccess(
          "Dataset successfully added",
          `${props.tableName} was succesfully added to ${selectedName}`,
        );
        return true;
      }
    } else {
      const body = {
        connectionId: props.connectionId,
        parentId: folderId,
        schemaName: props.schemaName,
        tableName: props.tableName,
      };

      const { status } = await api.callAPI(
        RequestType.Post,
        `/workspaces/${workspaceId}/assets/fromConnection`,
        "Error creating folder:",
        body,
      );
      if (status === 200) {
        ToastrSuccess(
          "Dataset successfully added",
          `${props.tableName} was succesfully added to ${selectedName}`,
        );
        return true;
      }
    }

    return false;
  };

  function setWorkspaceAndFolder(
    workspaceId: string,
    folderId: string,
    name: string,
  ) {
    setWorkspaceId(workspaceId);
    setFolderId(folderId);
    setSelectedName(name);
  }

  const body = (
    <div className={"create-asset-modal"}>
      <p>{helperText}</p>
      <span className="workspace-table">
        <SelectAssetsTable
          pageName={pageName}
          setMoveDestination={async (
            workspaceId: string,
            folderId: string,
            name: string,
          ) => {
            setWorkspaceAndFolder(workspaceId, folderId, name);
          }}
          isAssetMove={true}
        />
      </span>
    </div>
  );

  const modal = {
    title: title,
    body: body,
    primaryButton: (
      <Button
        color="primary"
        form="newWorkspaceForm"
        type="submit"
        disabled={!workspaceId}
        onClick={async () => {
          if (await createWorkspaceItem()) {
            modalContext.toggleModal();
          }
        }}
      >
        <div className="icon no-pointer-event" />
        Confirm
      </Button>
    ),
    displayed: true,
    close: () => {
      setWorkspaceAndFolder(null!, null!, null!);
      props.setIsMoveWorkspaceOpen(false);
    },
    displayToggleCloseButton: true,
  } as IModalProps;

  return <span></span>;
};
