import { useContext, useEffect, useState } from "react";

import {
  BillingStatus,
  IBillingUsage,
  ISubscriptionInfo,
  UserRole,
} from "../../../models";

import { Button, Card, CardBody, Col, FormGroup, Row } from "reactstrap";
import { Container } from "react-bootstrap";
import { RequestType, BackendType } from "../../../components/withAPI";
import { SettingTabs } from "../Settings";
import { getSalesEmailAddress } from "../../../utility/LocalizedEmailAddresses";
import { useAPI } from "../../../components/useAPI";
import { BillingUsageBars } from "./BillingUsageBars";
import { Status } from "../../../utility/Status";
import { BillingProvider } from "../../../models/Billing/BillingProvider";
import { CDataModalV2 } from "../../../components/modal/CDataModalV2";
import { getIsSupportImpersonationActive } from "../../../services/userImpersonation";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { ModalContext } from "../../../routes/ModalContext";
import { addSubscription, addUsage } from "../../../redux/actions";
import { CDataTypography } from "../../../components/text/CDataTypography";
import { NextBillingDate } from "./NextBillingDate";
import { useIsConnectForSpreadsheets } from "../../../hooks/useIsConnectForSpreadsheets";
import { IBillingAggregateSummaryList } from "../../../bffmodels/IBillingAggregateSummaryList";
import {
  CDataButton,
  ButtonType,
} from "../../../components/buttons/CDataButton";

const StripeBillingInfoTab = () => {
  const [subscriptionInfo, setSubscriptionInfo] = useState<ISubscriptionInfo>();
  const [billingUsageInfo, setBillingUsageInfo] = useState<IBillingUsage>();
  const [aggregateInfo, setAggregateInfo] =
    useState<IBillingAggregateSummaryList>();
  const [toggleCancelBillingModal, setToggleCancelBillingModal] =
    useState<boolean>(false);
  const modalContext = useContext(ModalContext);
  const api = useAPI();
  const user = useAppSelector((s) => s.user);
  const isOem = user.role === UserRole.OEMAdmin;
  const isSupportImpersonationActive = getIsSupportImpersonationActive();
  const isConnectForSpreadsheetsClient = useIsConnectForSpreadsheets();
  const dispatch = useAppDispatch();

  function getComponentName() {
    return "pages-settings-billinginfotab-StripeBillingInfoTab";
  }

  useEffect(() => {
    async function getToken() {
      await getUsage();
      await getSubscriptionInfo();
      await getAggregateInfo();
    }
    getToken();
  }, []); // eslint-disable-line

  async function getUsage() {
    const { status, payload } = await api.callAPI(
      RequestType.Get,
      "/account/billing/usage",
      "Failed to get account usage due to the following error:",
      BackendType.AccountService,
    );
    if (status === 200) {
      setBillingUsageInfo(payload);
      dispatch(addUsage(payload));
    }
  }

  async function getSubscriptionInfo() {
    const { status, payload } = await api.callAPI(
      RequestType.Get,
      "/account/billing/subscription",
      "Failed to get subscription info due to the following error:",
      BackendType.AccountService,
    );
    if (status === 200) {
      setSubscriptionInfo(payload);
      dispatch(addSubscription(payload));
    }
  }

  async function getAggregateInfo() {
    const { status, payload } = await api.callAPI(
      RequestType.Get,
      "/aggregates",
      "Failed to get aggregate info due to the following error:",
    );
    if (status === 200) {
      setAggregateInfo(payload);
    }
  }

  async function redirectToStripeBillingPortal(event: any) {
    event.preventDefault();
    let billingUrl = "";
    const data = `${window.location.protocol}//${window.location.host}${window.location.pathname}?tab=${SettingTabs.Billing}`;
    const { status, payload, error } = await api.callAPI(
      RequestType.Post,
      "/account/billing/createPortalSession",
      "",
      data,
      BackendType.AccountService,
    );
    if (status === 200) {
      billingUrl = payload.billingPortalUrl;
    } else {
      modalContext.showError(
        "Failed to get billing portal redirect URL due to the following error:",
        error,
      );
    }
    if (billingUrl) {
      window.location.href = billingUrl;
    }
  }

  const userLimit = subscriptionInfo?.limits?.userLimit ?? 0;

  const CancelBillingModal = () => {
    return (
      <CDataModalV2
        className="cancel-billing-modal"
        fade={false}
        displayed={toggleCancelBillingModal === true}
        close={() => {
          setToggleCancelBillingModal(!toggleCancelBillingModal);
        }}
        title="Cancel Plan"
        spacedFooter={false}
        primaryButton={
          <Button
            color="primary"
            onClick={() => {
              window.location.href = `mailto:${getSalesEmailAddress()}`;
              setToggleCancelBillingModal(!toggleCancelBillingModal);
            }}
          >
            Contact Sales
          </Button>
        }
        secondaryButton={
          <Button
            color="secondary"
            onClick={() => {
              setToggleCancelBillingModal(!toggleCancelBillingModal);
            }}
          >
            Cancel
          </Button>
        }
      >
        <div>
          Looking to cancel your Connect Cloud subscription plan? Contact our
          Sales team today to assist you in this request.
        </div>
      </CDataModalV2>
    );
  };

  const connectionLimit = subscriptionInfo?.limits?.connectionLimit ?? 0;
  const connectionsPerSource =
    subscriptionInfo?.limits?.connectionsPerSource ?? 0;

  const isTrialOrTrialEnding =
    subscriptionInfo?.billingStatus === BillingStatus.Trial ||
    subscriptionInfo?.billingStatus === BillingStatus.TrialEnding;

  return (
    <Container fluid className={`p-0 ${getComponentName()}`}>
      <div className="container-fluid content-row">
        <Row>
          <div className="col d-flex">
            <Card className="billing-card">
              <CardBody>
                {subscriptionInfo?.billingStatus !==
                  BillingStatus.NewAccount && (
                  <Row className="mb-3">
                    <Col>
                      <h4>Billing</h4>
                    </Col>
                    <Col className="text-right">
                      <Button
                        onClick={(event: any) =>
                          redirectToStripeBillingPortal(event)
                        }
                        hidden={
                          subscriptionInfo?.billingProvider !==
                            BillingProvider.Stripe ||
                          isOem ||
                          isSupportImpersonationActive
                        }
                        className="float-end minor-margin-right"
                        type="button"
                        color="primary"
                        outline
                        data-testid="button-manage-billing"
                      >
                        <i className="fa fa-credit-card fa-sm align-middle me-2 no-pointer-event" />
                        <span className="align-middle">Manage Billing</span>
                      </Button>
                      {isConnectForSpreadsheetsClient && (
                        <CDataButton
                          buttonType={ButtonType.Borderless}
                          data-testid="button"
                          className="plan-button mr-1"
                          onClick={() => {
                            window.open(
                              "http://www.cdata.com/spreadsheets/pricing",
                              "_blank",
                            );
                          }}
                        >
                          <span className="align-middle me-2">
                            Compare Plans{" "}
                          </span>
                          <i className="fa fa-regular fa-arrow-up-right-from-square align-middle"></i>
                        </CDataButton>
                      )}
                      <Button
                        hidden={
                          isSupportImpersonationActive ||
                          isConnectForSpreadsheetsClient
                        }
                        onClick={() => setToggleCancelBillingModal(true)}
                        type="button"
                        className="invalid-button btn-outline-danger float-end minor-margin-right btn-spacing"
                      >
                        <i className="fa-regular fa-xmark align-middle"></i>
                        <span className="align-middle ms-2">Cancel Plan</span>
                      </Button>
                    </Col>
                  </Row>
                )}
                <FormGroup
                  className={
                    subscriptionInfo?.billingStatus !== BillingStatus.Canceled
                      ? "mb-3"
                      : "mb-0"
                  }
                >
                  <h5 className="mb-3">Billing Plan</h5>
                  <div id="accountName">
                    {subscriptionInfo?.billingStatus !== BillingStatus.Canceled
                      ? subscriptionInfo?.plan?.name
                      : null}
                    <Status
                      className={
                        subscriptionInfo?.billingStatus !==
                        BillingStatus.Canceled
                          ? "ms-2"
                          : "mt-2"
                      }
                      status={subscriptionInfo?.billingStatus}
                    />
                    {subscriptionInfo?.billingStatus ===
                    BillingStatus.Canceled ? (
                      <div className="mt-3 mb-0">
                        You currently do not have a CData Connect Cloud
                        subscription. Reactivate your plan today to take
                        advantage of all its features.
                      </div>
                    ) : null}
                  </div>
                </FormGroup>
                <NextBillingDate subscriptionInfo={subscriptionInfo} />
                <FormGroup
                  hidden={
                    subscriptionInfo?.billingStatus ===
                      BillingStatus.Canceled ||
                    (isConnectForSpreadsheetsClient && isTrialOrTrialEnding) ||
                    isOem
                  }
                  className="mb-0 row plan-contents"
                >
                  <div className="col-sm-2">
                    <h5 className="mb-0">Your Plan Includes</h5>
                    <div>
                      <i className="fa fa-check fa-sm billing-check align-middle me-2 no-pointer-event mb-1" />
                      {(userLimit < 0 ? "Unlimited" : userLimit) +
                        (billingUsageInfo?.usersCount !== 1
                          ? " User Seats"
                          : " User Seat")}
                    </div>
                    {subscriptionInfo?.limits?.dataSourceLimit !== -1 ? (
                      <div>
                        <i className="fa fa-check fa-sm billing-check align-middle me-2 no-pointer-event mb-1" />
                        {subscriptionInfo?.limits?.dataSourceLimit +
                          (subscriptionInfo?.limits?.dataSourceLimit !== 1
                            ? " Data Sources"
                            : " Data Source")}
                        {connectionsPerSource > 0 && (
                          <>
                            <i className="fa fa-solid fa-circle divider-dot align-middle mx-2" />
                            <CDataTypography
                              className="d-inline-block connections-per-source"
                              variant="typography-variant-body-regular-italic"
                            >
                              {connectionsPerSource}
                              {connectionsPerSource === 1
                                ? " Connection "
                                : " Connections "}
                              p/source
                            </CDataTypography>
                          </>
                        )}
                      </div>
                    ) : null}
                    {!isConnectForSpreadsheetsClient && (
                      <div>
                        <i className="fa fa-check fa-sm billing-check align-middle me-2 no-pointer-event mb-1" />
                        {connectionLimit < 0 ? "Unlimited" : connectionLimit}
                        {connectionLimit !== 1 ? " Connections" : "Connection"}
                      </div>
                    )}
                    {subscriptionInfo?.plan?.description != null
                      ? subscriptionInfo?.plan?.description
                          .split("|")
                          .map((descriptionItem) => {
                            return (
                              <div key={descriptionItem}>
                                <i className="fa fa-check fa-sm billing-check align-middle me-2 no-pointer-event mb-1" />
                                {descriptionItem}
                              </div>
                            );
                          })
                      : null}
                  </div>
                  {((subscriptionInfo?.purchasedAddons?.length ?? 0) > 0 ||
                    (subscriptionInfo?.includedAddons?.length ?? 0) > 0) && (
                    <div className="col-sm-2">
                      <h5 className="mb-0">Add-Ons:</h5>
                      {subscriptionInfo?.includedAddons?.map((addOn) => {
                        return (
                          <div key={addOn.name}>
                            <i className="fa fa-check fa-sm billing-check align-middle me-2 no-pointer-event mb-1" />
                            {addOn.name} (Included)
                          </div>
                        );
                      })}
                      {subscriptionInfo?.purchasedAddons?.map((addOn) => {
                        return (
                          <div key={addOn.name}>
                            <i className="fa fa-check fa-sm billing-check align-middle me-2 no-pointer-event mb-1" />
                            {addOn.name}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </FormGroup>
              </CardBody>
            </Card>
          </div>
          {!isConnectForSpreadsheetsClient && (
            <Col className="col-auto">
              <Card>
                <CardBody className="sales-card-body">
                  <Row>
                    <h2 className="mb-0">Update Plan</h2>
                  </Row>
                  <Row className="sales-prompt">
                    Looking to update your Connect Cloud subscription plan?
                    Contact our Sales team today to assist you in your request!
                  </Row>
                  <span className="phone-icon-bg">
                    <i className="fa fa-phone fa-lg phone-icon" />
                  </span>
                  <p className="sales-contact-info mb-0">
                    <b>Mon-Fri, 9am-6pm EST</b>
                    <br />
                    (800) 235-7250 (within US)
                    <br />
                    (919) 928-5214 (outside US)
                  </p>
                  <a
                    href={`mailto:${getSalesEmailAddress()}`}
                    className="btn btn-primary"
                  >
                    <i className="fa fa-envelope fa-sm align-middle me-2 no-pointer-event" />
                    Email Sales
                  </a>
                </CardBody>
              </Card>
            </Col>
          )}
        </Row>
        {billingUsageInfo &&
        subscriptionInfo &&
        subscriptionInfo?.billingStatus !== BillingStatus.Canceled ? (
          <BillingUsageBars
            subscriptionInfo={subscriptionInfo}
            aggregateInfo={aggregateInfo}
            billingUsageInfo={billingUsageInfo}
          />
        ) : null}
      </div>
      {CancelBillingModal()}
    </Container>
  );
};

export default StripeBillingInfoTab;
