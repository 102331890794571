import { CallAPIFunction } from "src/components/useAPI";
import { RequestType } from "../../../../components/withAPI";
import { ToastrError } from "../../../../services/toastrService";
import { AITableOption } from "../AIWizard";

export async function buildMetadataString(
  table: AITableOption,
  callAPI: CallAPIFunction,
) {
  let tableString = `[${table.connectionName}].[${table.schemaName}].[${table.tableName}](`;

  const columnNames = await getColumnMetadata(table, callAPI);
  columnNames.forEach((name: string) => (tableString += `${name}, `));

  tableString = tableString.slice(0, -2); // Lazy way to get rid of the last comma and space
  tableString += ")";

  return tableString;
}

export async function getColumnMetadata(
  table: AITableOption,
  callAPI: CallAPIFunction,
) {
  const url = `/columns?catalogName=${encodeURIComponent(table.connectionName ?? "")}&schemaName=${encodeURIComponent(table.schemaName ?? "")}&tableName=${encodeURIComponent(table.tableName ?? "")}`;
  const { status, payload, error } = await callAPI(
    RequestType.Get,
    url,
    "",
    null,
    undefined,
    { "Connect-Cloud-Client": "CDataDataExplorer" },
  );

  if (status === 200) {
    if (payload.error) {
      ToastrError("Error fetching metadata", payload.error.message);
      return [];
    }
    const colMetadata = payload.results[0].rows;
    const colArray = colMetadata.map((el: any) => {
      return el[3];
    });
    return colArray;
  } else {
    const err = error;
    ToastrError("Error fetching metadata", err);
  }
  return [];
}
