import { IQueryResult } from "../../models";
import { IServiceError } from "../../models/IServiceError";
import { cdataFetch } from "../cdataFetch";

export function runCacheJob(jobId: string) {
  return cdataFetch<IQueryResult | IServiceError>({
    method: "POST",
    url: `/cacheJobs/${jobId}/run`,
  });
}
