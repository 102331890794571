import React from "react";
import { useAuthentication } from "../../hooks/useAuthentication";

interface ILoggedInGuardProps {
  component: React.ComponentType<object>;
  args?: any;
}

const LoggedInGuard = ({ component, args }: ILoggedInGuardProps) => {
  const auth = useAuthentication();

  const Component = auth.WithAuthenticationRequired(component, args);
  return <Component />;
};

export default LoggedInGuard;
