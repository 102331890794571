import { useState } from "react";
import { QueryBuilderAddButton } from "../components/QueryBuilderAddButton";
import { QueryBuilderCard } from "../components/QueryBuilderCard";
import { QueryBuilderCardHeader } from "../components/QueryBuilderCardHeader";
import { IQueryBuilderCommonProps } from "../../../models/IQueryBuilderCommonProps";
import AddDataModal from "./AddDataModal/AddDataModal";
import DataTile from "./DataTile";
import {
  IQueryBuilderJoin,
  IQueryBuilderModel,
} from "../../../models/IQueryBuilderModel";
import { Col, Row } from "react-bootstrap";
import { produce } from "immer";
import { CDataModalV2 } from "../../../../../../../components/modal/CDataModalV2";
import {
  ButtonType,
  CDataButton,
} from "../../../../../../../components/buttons/CDataButton";
import {
  CDataTooltip,
  CDataTooltipType,
} from "../../../../../../../components/CDataTooltip";
import { CDataTypography } from "../../../../../../../components/text/CDataTypography";

interface QueryBuilderDataCardProps extends IQueryBuilderCommonProps {}

export function QueryBuilderDataCard(props: QueryBuilderDataCardProps) {
  const { queryData, setQueryData } = props;
  const joinCount = queryData.joins?.length ?? 0;

  const isQueryBuilderEmpty = props.queryData.from.connectionId === "";

  const [modalOpen, setModalOpen] = useState(false);
  const [joinToEditIndex, setJoinToEditIndex] = useState<number | null>(null);

  const [currJoinIndex, setCurrJoinIndex] = useState(-1);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);

  function onClickAddData() {
    setJoinToEditIndex(null);
    setModalOpen(true);
  }

  function onClickEditData(joinIndex: number) {
    setJoinToEditIndex(joinIndex);
    setModalOpen(true);
  }

  function handleDataSubmit(newJoin: IQueryBuilderJoin) {
    if (joinToEditIndex != null) {
      const updatedJoins = [...queryData.joins];
      updatedJoins[joinToEditIndex] = newJoin;

      const updatedQueryData = updateQueryDataOnJoinChange({
        ...queryData,
        joins: updatedJoins,
      });

      setQueryData(updatedQueryData);
    } else {
      setQueryData({
        ...queryData,
        joins: [...queryData.joins, newJoin],
      });
    }
  }

  const cascadeDeleteModal = (
    <CDataModalV2
      displayed={showDeleteWarning}
      modalSize={"lg"}
      spacedFooter={false}
      close={() => setShowDeleteWarning(false)}
      title={"Delete Join"}
      primaryButton={
        <CDataButton
          buttonType={ButtonType.Danger}
          onClick={() => {
            handleDelete(currJoinIndex);
            setShowDeleteWarning(false);
          }}
        >
          Delete
        </CDataButton>
      }
      secondaryButton={
        <CDataButton
          buttonType={ButtonType.Secondary}
          onClick={() => setShowDeleteWarning(false)}
        >
          Cancel
        </CDataButton>
      }
    >
      Deleting this join will remove all other customizations made within the
      Query Builder that are linked to this data table. Are you sure you want to
      proceed?
    </CDataModalV2>
  );

  function handleDelete(joinIndex: number) {
    const currentJoin = queryData.joins[joinIndex];

    const updatedJoins = queryData.joins.filter((obj, index) => {
      return (
        index !== joinIndex &&
        obj.left.table.tableAlias !== currentJoin.right.table.tableAlias
      );
    });

    const updatedQueryData = updateQueryDataOnJoinChange({
      ...queryData,
      joins: updatedJoins,
    });
    setQueryData(updatedQueryData);
  }

  // Handles the logic of a JOIN being changed or removed.
  // This will remove any column that references that JOIN.
  function updateQueryDataOnJoinChange(
    queryData: IQueryBuilderModel,
  ): IQueryBuilderModel {
    // Get the names for all our tables.
    // This needs to be updated when we deal with allowing multiple JOINs on the same table.
    const allTables = new Set([
      queryData.from.tableAlias,
      ...queryData.joins.map((j) => j.right.table.tableAlias),
    ]);

    return produce(queryData, (draft) => {
      draft.groupBy = draft.groupBy.filter((g) =>
        allTables.has(g.column.table.tableAlias),
      );
      draft.sort = draft.sort.filter((s) =>
        allTables.has(s.column.table.tableAlias),
      );
      draft.metrics = draft.metrics.filter((m) =>
        allTables.has(m.column.table.tableAlias),
      );
      draft.filters = draft.filters.filter((f) =>
        allTables.has(f.column.table.tableAlias),
      );
    });
  }

  function mapJoinsToTiles() {
    if (joinCount > 0) {
      return queryData.joins?.map((join, index) => (
        <DataTile
          handleDelete={() => {
            setCurrJoinIndex(index);
            setShowDeleteWarning(true);
          }}
          index={index}
          join={join}
          key={index}
          onClickEditData={onClickEditData}
        />
      ));
    }
  }

  const cardBody = (
    <>
      <Row className="gray-helper-text">
        <Col>
          <CDataTypography>
            Combine data from additional tables to consolidate data from
            multiple sources.
          </CDataTypography>
        </Col>
      </Row>
      {mapJoinsToTiles()}
    </>
  );

  return (
    <>
      {cascadeDeleteModal}
      <QueryBuilderCard>
        <QueryBuilderCardHeader title="Data">
          <CDataTooltip
            type={CDataTooltipType.Dark}
            title={
              joinCount >= 3
                ? "A max of three additional tables can be added to your query."
                : ""
            }
          >
            <div>
              <QueryBuilderAddButton
                id="queryBuilderDataCard-addDataButton"
                onClick={() => onClickAddData()}
                disabled={joinCount >= 3 || isQueryBuilderEmpty}
                isQueryBuilderEmpty={isQueryBuilderEmpty}
              >
                Add Data
              </QueryBuilderAddButton>
            </div>
          </CDataTooltip>
        </QueryBuilderCardHeader>
        {cardBody}
        {modalOpen ? (
          <AddDataModal
            {...props}
            handleDataSubmit={handleDataSubmit}
            isDisplayed={modalOpen}
            setIsDisplayed={setModalOpen}
            joinToEditIndex={joinToEditIndex}
            setJoinToEditIndex={setJoinToEditIndex}
          />
        ) : null}
      </QueryBuilderCard>
    </>
  );
}
