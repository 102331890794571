import { Formik } from "formik";
import React, { useContext } from "react";
import { Form } from "react-bootstrap";
import { ModalContext } from "../routes/ModalContext";
import { ToastrError, ToastrSuccess } from "../services/toastrService";
import * as Yup from "yup";
import { Button } from "reactstrap";
import { ICreateWorkspaceRequest } from "../models/Datasets/ICreateWorkspaceRequest";
import { IWorkspaceListItem } from "../models/Datasets/IWorkspaceListItem";
import { useAPI } from "../components/useAPI";
import { RequestType } from "../components/withAPI";
import TextInput from "../components/form/TextInput";
import { IModalProps } from "../components/CDataModal";

export function useWorkspaceModals(
  list: IWorkspaceListItem[],
  setListFunction: (listFunction: IWorkspaceListItem[]) => void,
  unavailableNames?: string[],
) {
  const modalFunctions = useContext(ModalContext);
  const api = useAPI();

  async function createNewWorkspace(values: { WorkspaceName: string }) {
    const workspaceToCreate = {
      name: values.WorkspaceName,
    } as ICreateWorkspaceRequest;

    const { status, payload } = await api.callAPI(
      RequestType.Post,
      "/workspaces",
      "Failed to create workspace due to the following error:",
      workspaceToCreate,
    );
    if (status === 200) {
      const workspaceItem = {
        childCount: payload.childCount,
        id: payload.id,
        name: payload.name,
      } as IWorkspaceListItem;

      const updatedData = [...list];
      updatedData.push(workspaceItem);
      setListFunction(updatedData);
      ToastrSuccess(
        "Workspace successfuly added",
        `Successfully added ${values.WorkspaceName} to your Workspace`,
      );
    }

    modalFunctions.toggleModal();
  }

  function openAddWorkspaceModal() {
    const initialFormValues = {
      WorkspaceName: "",
    };

    const validationSchema = Yup.object().shape({
      WorkspaceName: Yup.string()
        .matches(
          /^\w[\w_. -]{0,99}(?<! )$/,
          "Workspace name must only consist of letters, numbers, underscores, hyphens, and are limited to 100 characters.",
        )
        .required("This is a required field")
        .test(
          "unique-name",
          "This name is already used in a Connection or Workspace.",
          function (value) {
            return !unavailableNames?.some(
              (name) => name?.toLowerCase() === value?.toLowerCase(),
            );
          },
        ),
    });

    const modalTitle = "Add Workspace";

    const modalBody = (
      <>
        <Formik
          initialValues={initialFormValues}
          validationSchema={validationSchema}
          onSubmit={createNewWorkspace}
        >
          {({ handleSubmit }) => (
            <Form id="newWorkspaceForm" onSubmit={handleSubmit}>
              <TextInput
                label={"Workspace Name"}
                name={"WorkspaceName"}
                isRequired={true}
              />
            </Form>
          )}
        </Formik>
      </>
    );

    const modal = {
      title: modalTitle,
      body: modalBody,
      primaryButton: (
        <Button color="primary" form="newWorkspaceForm" type="submit">
          <div className="icon no-pointer-event" />
          Confirm
        </Button>
      ),
      secondaryButton: (
        <Button color="secondary" onClick={modalFunctions.toggleModal}>
          Cancel
        </Button>
      ),
      displayToggleCloseButton: true,
      modalSize: "md",
      displayed: true,
    } as IModalProps;
    modalFunctions.setModal(modal);
  }

  async function batchDeleteFromStore(workspaceIds: string[]) {
    const workspacesToDelete = {
      ids: workspaceIds,
    };

    const { status, payload } = await api.callAPI(
      RequestType.Delete,
      "/workspaces",
      "Failed to delete workspace due to the following error:",
      workspacesToDelete,
    );
    if (status === 200) {
      const workspaceDeletedCount = payload.deletedIds.length;
      ToastrSuccess(
        "Workspace deleted successfully",
        `${workspaceDeletedCount} workspaces were successfully deleted.`,
      );
      let updatedData = [...list];
      updatedData = updatedData.filter((workspace: IWorkspaceListItem) => {
        return !workspaceIds.includes(workspace.id);
      });
      setListFunction(updatedData);
    } else {
      ToastrError(
        "Failed to delete workspace due to following error:",
        payload,
      );
    }

    modalFunctions.toggleModal();
  }

  function openBatchDeleteWorkspaceModal(workspaceIds: string[]) {
    const modalTitle = "Delete Workspace";
    const modalBody = (
      <text>
        You are about to delete multiple workspaces. Are you sure you want to
        proceed?
      </text>
    );

    const modal = {
      title: modalTitle,
      body: modalBody,
      primaryButton: (
        <Button
          color="danger"
          onClick={() => batchDeleteFromStore(workspaceIds)}
        >
          Delete
        </Button>
      ),
      secondaryButton: (
        <Button color="secondary" onClick={modalFunctions.toggleModal}>
          Cancel
        </Button>
      ),
      displayToggleCloseButton: true,
      modalSize: "md",
      displayed: true,
    } as IModalProps;
    modalFunctions.setModal(modal);
  }

  async function deleteWorkspace(
    workspaceId: string,
    workspaceDeletedName: string,
  ) {
    const { status, payload } = await api.callAPI(
      RequestType.Delete,
      `/workspaces/${workspaceId}`,
      "Failed to delete workspace due to the following error:",
    );
    if (status === 200) {
      let updatedData = [...list];
      updatedData = updatedData.filter((workspace: IWorkspaceListItem) => {
        return workspace.id !== workspaceId;
      });
      setListFunction(updatedData);
      ToastrSuccess(
        "Workspace deleted successfully",
        `${workspaceDeletedName} was successfully deleted`,
      );
    } else {
      const err = payload;
      ToastrError("Error deleting workspace", err);
    }

    modalFunctions.toggleModal();
  }

  function openDeleteWorkspaceModal(selectedRow: any) {
    const workspaceId = selectedRow.id;
    const workspaceDeleted = selectedRow.name;

    const modal = {
      title: "Delete Workspace",
      body: (
        <text>
          You are about to delete the workspace, {workspaceDeleted}. Are you
          sure you want to proceed?
        </text>
      ),
      primaryButton: (
        <Button
          color="danger"
          onClick={() => deleteWorkspace(workspaceId, workspaceDeleted)}
        >
          Delete
        </Button>
      ),
      secondaryButton: (
        <Button color="secondary" onClick={modalFunctions.toggleModal}>
          Cancel
        </Button>
      ),
      displayToggleCloseButton: true,
      modalSize: "md",
      displayed: true,
    } as IModalProps;
    modalFunctions.setModal(modal);
  }

  async function copyWorkspace(workspaceId: string) {
    const { status, payload } = await api.callAPI(
      RequestType.Post,
      `/workspaces/${workspaceId}/duplicate`,
      "Failed to create workspace due to the following error:",
      "",
    );
    if (status === 200) {
      const updatedData = [...list];
      updatedData.push(payload);
      setListFunction(updatedData);
      ToastrSuccess(
        "Workspace successfuly copied",
        `${payload.name} was successfully created`,
      );
      return payload;
    } else {
      ToastrError(
        "Failed to create workspace copy due to following error:",
        payload,
      );
    }
  }

  const modalAndAPIs = {
    createWorkspaceConfirmation: openAddWorkspaceModal,
    deleteWorkspaceConfirmationModal: openDeleteWorkspaceModal,
    batchDeleteWorkspaceConfirmationModal: openBatchDeleteWorkspaceModal,
    copyWorkspace: copyWorkspace,
  };

  return modalAndAPIs;
}
