/**
 * TypeScript enums that use numbers as their values are bi-directional.
 *
 * E.x. an enum like this:
 * ```ts
 * enum MyEnum {
 *   Something = 1,
 *   Whatever = 2
 * }
 * ```
 *
 * turns into this javascript code:
 * ```ts
 * const MyEnum = {
 *   Something: 1,
 *   Whatever: 2,
 *   "1": "Something",
 *   "2": "Whatever",
 * }
 * ```
 *
 * This causes issues when trying to validate if a field in a form is one of the chosen numeric values.
 * This function just trims out the string values from an enum and returns only the numbers like this:
 *
 * ```
 * const myNumbers = getNumericEnumValues(MyEnum);
 *
 * myNumbers = [1, 2];
 * ```
 *
 * Then you can make a Yup schema like:
 *
 * ```ts
 * Yup.number().oneOf(getNumericEnumValues(MyEnum)).required()
 * ```
 */
export function getNumericEnumValues(
  myEnum: Record<string, string | number>,
): number[] {
  return Object.values(myEnum).filter(
    (value): value is number => typeof value === "number",
  );
}
