import { useContext } from "react";
import { ConnectionSaveButton } from "./ConnectionSaveButton";
import { SecondaryActionButton } from "./SecondaryActionButton";
import { ConnectionCrudCalls } from "../../../hooks/useConnectionCrudCalls";
import { ConnectionContext } from "./ConnectionContext";
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import { RefreshMetadataButton } from "./RefreshMetadataButton";
import { useAppSelector } from "../../../redux/hooks";
import { useUserInfo } from "../../../hooks/useUserInfo";
import { UserRole } from "../../../models";

interface ITitleRowButtons {
  shouldAbbreviateButtons: boolean;
  connectionCrudCalls: ConnectionCrudCalls;
  displayedBasicDriverProperties: JSX.Element[];
  finishCustomReports: () => void;
  handleNavigation: (onNavigate: () => void) => void;
}

const TitleRowButtons = (props: ITitleRowButtons) => {
  const subscription = useAppSelector((state) => state.subscription);
  const user = useUserInfo();

  const {
    driverInfo,
    isCacheConnection,
    isInitialSetupPage,
    connectionId,
    connectionInfo,
  } = useContext(ConnectionContext);

  const renderRefreshCacheOption = (isInDropdown: boolean) => (
    <>
      {driverInfo?.hasMetadataCache === true && !isInitialSetupPage && (
        <RefreshMetadataButton
          isInDropdown={isInDropdown}
          connectionId={connectionId}
          connectionName={connectionInfo?.existingConnectionName}
        />
      )}
    </>
  );

  const renderActionsAsButtons = () => (
    <div className="titleRowButtons-container">
      {renderRefreshCacheOption(false)}
      <SecondaryActionButton
        openDeleteConnectionModal={
          props.connectionCrudCalls.openDeleteConnectionModal
        }
        handleNavigation={props.handleNavigation}
      />
    </div>
  );

  const renderActionsAsDropdown = () => (
    <UncontrolledDropdown className="titleRowButtons-dropdown">
      <DropdownToggle nav>
        <i className="fas fa-ellipsis-v fa-lg align-middle me-2"></i>
      </DropdownToggle>
      <DropdownMenu end className="connection-dropdown-offset">
        {renderRefreshCacheOption(true)}
        <DropdownItem
          tag="a"
          onClick={(event) =>
            props.connectionCrudCalls.openDeleteConnectionModal(event)
          }
        >
          <i className="fas fa-times fa-lg align-middle me-2"></i>
          Delete
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );

  function renderConnectionActions() {
    if (
      isCacheConnection ||
      user.IsInRole(UserRole.Query) ||
      (!connectionId && !isInitialSetupPage)
    ) {
      return null;
    }

    if (props.shouldAbbreviateButtons && !isInitialSetupPage) {
      return renderActionsAsDropdown();
    } else {
      return renderActionsAsButtons();
    }
  }

  const contents = (
    <>
      <Col className="flex-grow-0 pe-1">{renderConnectionActions()}</Col>
      <Col className="flex-grow-0 ps-1">
        <ConnectionSaveButton
          finishCustomReports={props.finishCustomReports}
          subscription={subscription}
          openSaveConnectionWithCacheJobsModal={(event: any) =>
            props.connectionCrudCalls.openSaveConnectionWithCacheJobsModal(
              event,
              props.displayedBasicDriverProperties,
            )
          }
          // TODO: Re-enable me in CLOUD-11995 once fingerprint fetching logic is in place
          // openSaveSSHConnectionModal={(event: any) =>
          //   props.connectionCrudCalls.openSaveSSHConnectionModal(
          //     event,
          //     props.displayedBasicDriverProperties
          //   )
          // }
        />
      </Col>
    </>
  );

  if (isInitialSetupPage) {
    return (
      <Col className="buttons-column">
        <Row>{contents}</Row>
      </Col>
    );
  } else {
    return contents;
  }
};

export default TitleRowButtons;
