import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Card, CardBody, Col, Row } from "reactstrap";

import TertiaryButton from "../../../../components/buttons/TertiaryButton";
import { useAPI } from "../../../../components/useAPI";
import { RequestType, withAPI } from "../../../../components/withAPI";
import { ModalContext } from "../../../../routes/ModalContext";
import { ToastrError } from "../../../../services/toastrService";
import ResultsDetailsGrid from "../../../dataExplorer/RightPanel/Results/ResultsDetailsGrid";
import { AssetDetailsTab } from "../AssetDetails";
import LoadingPlaceholderTable from "../LoadingPlaceholderTable";

export interface IPreviewTabProps {
  previewColumns: any[];
  previewData: any[];
  sourceCatalog: string;
  sourceSchema: string;
  sourceTable: string;
  activeTab: string;
  assetQuery: string;
}

const PreviewTab = (props: IPreviewTabProps) => {
  const [toggleResult, setToggleResult] = useState(false);
  const [previewColumns, setPreviewColumns] = useState([]);
  const [previewData, setPreviewData] = useState<any[]>([]);

  const api = useAPI();
  const navigate = useNavigate();
  const modalFunctions = useContext(ModalContext);

  const tableObj: any = {
    type: "asset",
    name: null,
    query: null,
    meta: {
      connectionName: props.sourceCatalog,
      tableName: props.sourceTable,
      schemaName: props.sourceSchema,
    },
  };

  useEffect(() => {
    async function fetchSetPreviewTableData() {
      await setPreviewTableData();
    }
    if (props.activeTab === AssetDetailsTab.Preview) {
      fetchSetPreviewTableData();
    }
  }, [props.activeTab]); // eslint-disable-line

  function getPreviewTabCols(results: any[]) {
    const schema = results[0].schema;
    const fieldArray = schema.map((x: any) => ({
      dataField: x.columnLabel,
      text: x.columnLabel,
      sort: true,
    }));
    return fieldArray;
  }

  function getPreviewTabData(previewTabCols: any[], results: any[]) {
    const allRowsArray = results[0].rows;
    const allColumnNamesArray = previewTabCols.map((x: any) => x.dataField);
    const resultArray = [];
    for (let i = 0; i < allRowsArray.length; i++) {
      const row: any = {};
      for (let j = 0; j < allColumnNamesArray.length; j++) {
        if (allRowsArray[i][j] === null) {
          allRowsArray[i][j] = <span className="null-values">null</span>;
        }
        row[allColumnNamesArray[j]] = allRowsArray[i][j];
      }
      resultArray.push(row);
    }
    return resultArray;
  }

  async function setPreviewTableData() {
    const queryString = `SELECT * FROM [${props.sourceCatalog}].[${props.sourceSchema}].[${props.sourceTable}] LIMIT 100`;
    const postData = {
      query: queryString,
      defaultCatalog: props.sourceCatalog,
    };

    setPreviewData([]);
    setPreviewColumns([]);

    try {
      const { status, payload, error } = await api.callAPI(
        RequestType.Post,
        `/query?workspace=${props.sourceCatalog}`,
        "",
        JSON.stringify(postData),
        null!,
        { "Connect-Cloud-Client": "CDataDataExplorer" },
      );
      if (status === 200) {
        if (payload.error) {
          ToastrError("Error fetching results", payload.error.message);
          return;
        } else {
          const previewTabCols = getPreviewTabCols(payload.results);
          setPreviewColumns(previewTabCols);
          const previewTabData = getPreviewTabData(
            previewTabCols,
            payload.results,
          );
          setPreviewData(previewTabData);
        }
      } else {
        if (status === 401) {
          modalFunctions.showTimeout();
        } else {
          const err = error;
          ToastrError("Error fetching results", err);
        }
      }
    } catch (err) {
      setPreviewData([]);
      setPreviewColumns([]);
      ToastrError("Error fetching column metadata", err);
    }
  }

  const previewTable =
    previewColumns.length === 0 ? (
      <LoadingPlaceholderTable />
    ) : (
      <ResultsDetailsGrid
        columns={previewColumns}
        resultDropdownOpen={toggleResult}
        toggleResultDropdownOpen={() => setToggleResult(!toggleResult)}
        gridData={previewData}
        gridID={"tableAssetPreviewTable"}
        wrapperClassNames={"table-responsive"}
        noData={() => {
          return (
            <div className="text-muted text-center">No matching records</div>
          );
        }}
      />
    );

  return (
    <div className="preview-tab">
      <Card>
        <CardBody>
          <Row className="align-items-center">
            <Col>
              <h5 className="card-title">Preview Table</h5>
            </Col>
            <Col>
              <TertiaryButton
                className="explore-data-button"
                onClick={() =>
                  navigate("/data-explorer/", {
                    state: {
                      assetQuery: props.assetQuery,
                      clickedFromDatasets: true,
                      clickedFromDatasetsTable: tableObj,
                    },
                  })
                }
              >
                <i className="fa fa-search" />
                Explore Data
              </TertiaryButton>
            </Col>
          </Row>
          {previewTable}
        </CardBody>
      </Card>
    </div>
  );
};

export default withAPI(PreviewTab);
