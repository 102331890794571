import { JobStatus } from "../../../models/Cache/JobStatus";
import { Badge, Spinner } from "reactstrap";

export const getJobStatusBadgeText = (status: JobStatus) => {
  switch (status) {
    case JobStatus.JOB_CREATED:
    case JobStatus.JOB_QUEUED:
      return "Queued";
    case JobStatus.JOB_RUNNING:
      return "Running";
    case JobStatus.JOB_COMPLETED:
      return "Success";
    case JobStatus.JOB_FAILED:
      return "Failed";
    case JobStatus.JOB_CANCELED:
      return "Canceled";
    default:
      return "";
  }
};

export const getJobStatusBadgeClass = (status: JobStatus) => {
  switch (status) {
    case JobStatus.JOB_CREATED:
    case JobStatus.JOB_QUEUED:
      return "badge-dark";
    case JobStatus.JOB_RUNNING:
      return "badge-quaternary";
    case JobStatus.JOB_COMPLETED:
      return "badge-success";
    case JobStatus.JOB_FAILED:
      return "badge-danger";
    case JobStatus.JOB_CANCELED:
      return "badge-dark";
    default:
      return "";
  }
};

export const JobStatusBadge = (props: {
  status: JobStatus;
  isAddJob: boolean;
}) => {
  let badgeText = "Draft";
  let badgeClass = "badge-dark";
  if (!props.isAddJob) {
    badgeText = getJobStatusBadgeText(props.status);
    badgeClass = getJobStatusBadgeClass(props.status);
  }

  return (
    <span className="job-status-badge-container">
      <Badge color="" data-testid="badge-status" className={badgeClass}>
        {badgeText}
      </Badge>
      {props.status === JobStatus.JOB_RUNNING ? (
        <span>
          <Spinner
            data-testid="spinner-job-running"
            className="running-spinner ms-2 d-flex"
            size="sm"
          />
        </span>
      ) : null}
    </span>
  );
};
