import { IQueryResult } from "../../models";
import { IServiceError } from "../../models/IServiceError";
import { cdataFetch } from "../cdataFetch";

export async function getSchemas(connection: string) {
  return cdataFetch<IQueryResult | IServiceError>({
    method: "GET",
    url: `/schemas?catalogName=${encodeURIComponent(connection)}`,
  });
}
