import { Formik, useFormikContext } from "formik";
import { produce } from "immer";
import {
  ButtonType,
  CDataButton,
} from "../../../../../../../components/buttons/CDataButton";
import { CDataModalV2 } from "../../../../../../../components/modal/CDataModalV2";
import { IQueryBuilderCommonProps } from "../../../models/IQueryBuilderCommonProps";
import { IQueryBuilderModel } from "../../../models/IQueryBuilderModel";
import { QueryBuilderColumnMultiSelect } from "../components/QueryBuilderColumnMultiSelect";
import { IQueryBuilderTable } from "../../../models/IQueryBuilderTable";
import { IColumnMetadata } from "../../../../../../../api/metadata/getColumnsForTable";

interface AddDimensionsModalProps extends IQueryBuilderCommonProps {
  displayed: boolean;
  close: () => void;
}

export function AddDimensionsModal(props: AddDimensionsModalProps) {
  const { queryData, setQueryData, displayed, close } = props;

  function onSubmit(newQueryData: IQueryBuilderModel) {
    setQueryData(newQueryData);
    close();
  }

  // Destroy the modal if the modal is closed.
  // Otherwise we run queries when the modal is closed.
  if (!displayed) {
    return null;
  }

  return (
    <Formik initialValues={queryData} onSubmit={onSubmit}>
      {({ values, handleSubmit }) => (
        <CDataModalV2
          displayed={displayed}
          close={close}
          title="Add Dimensions"
          className="addDimensionsModal"
          primaryButton={
            <CDataButton
              buttonType={ButtonType.Primary}
              disabled={values.groupBy.length === 0}
              onClick={() => handleSubmit()}
            >
              Confirm
            </CDataButton>
          }
        >
          <AddDimensionsFormBody />
        </CDataModalV2>
      )}
    </Formik>
  );
}

function AddDimensionsFormBody() {
  const { values, setValues } = useFormikContext<IQueryBuilderModel>();

  const allTables = [values.from, ...values.joins.map((j) => j.right.table)];

  function onChangeColumns(
    table: IQueryBuilderTable,
    columns: IColumnMetadata[],
  ) {
    const updatedValues = produce(values, (draft) => {
      // Remove all group bys for this table.
      draft.groupBy = draft.groupBy.filter(
        (g) => g.column.table.tableAlias !== table.tableAlias,
      );

      // Re-add the group bys for the table, using the new list of columns.
      for (const column of columns) {
        draft.groupBy.push({
          column: {
            table: table,
            column: column.columnName,
            dataType: column.dataType,
          },
        });
      }
    });
    setValues(updatedValues);
  }

  return (
    <div className="addDimensionsModal-body">
      <div>Select the columns you want to see in the result set.</div>

      <div>
        <div className="headers">
          <b className="header-text">Table Name</b>
          <b className="header-text required">Column Name</b>
        </div>

        <div className="table-input-container">
          {allTables.map((table) => {
            return (
              <div
                key={table.connectionId + "-" + table.tableAlias}
                className="input-row"
              >
                <div className="input-container" title={table.tableAlias}>
                  {table.tableAlias}
                </div>
                <div className="input-container border-shadow">
                  <QueryBuilderColumnMultiSelect
                    selectedValues={findGroupBysInTable(table, values).map(
                      (g) => g.column.column,
                    )}
                    table={table}
                    onChange={(values) => {
                      onChangeColumns(table, values);
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

function findGroupBysInTable(
  table: IQueryBuilderTable,
  model: IQueryBuilderModel,
) {
  return model.groupBy.filter(
    (g) => g.column.table.tableAlias === table.tableAlias,
  );
}
