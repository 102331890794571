import { isEmpty } from "lodash";

/**
 * We do not allow derived views to reference other derived views.
 * Block the user from saving if they try and do this.
 *
 * @param query - The query text for the derived view that the user is attempting to save.
 */
export function doesDerivedViewReferenceDerivedView(
  query: string | null | undefined,
): boolean {
  if (isEmpty(query) || query == null) {
    return false;
  }

  // Trim out anything besides alphanumeric characters, periods, and spaces.
  // This is to handle strings like `[CData].[DerivedViews]` or `"CData"."DerivedViews"`
  // or people putting newlines between them.
  const trimmedQuery = query.replaceAll(/[^a-z0-9 \\.]+/gi, "");
  if (trimmedQuery.toLowerCase().includes("cdata.derivedviews.")) {
    return true;
  }

  return false;
}
