import * as Yup from "yup";
import { ITableSettings } from "./SetTable";
import { hasPerRowRequestError } from "./components/PerRowRequestHelper";
import { APITableRequestMethod, APITableResponseType } from "../../../models";

const TABLE_NAME_LENGTH_INVALID =
  "Table name must be between 2 and 128 characters.";
const TABLE_NAME_CHARACTERS_INVALID =
  "Table name must only consist of letters, numbers, underscores, hyphens, and " +
  "spaces. Name must begin with a letter or underscore and cannot end with a space.";
export const TABLE_COLUMN_NAME_REGEX = /^[a-zA-Z_][a-zA-Z0-9_ -]*(?<! )$/;
export const COLUMN_NAME_LENGTH_INVALID =
  "Column name must be between 1 and 64 characters.";
export const COLUMN_NAME_CHARACTERS_INVALID = "Column name is not valid.";

export type IConfigureTableInitialValues = {
  requestBody: string;
  requestMethod: APITableRequestMethod;
  requestUrl: string;
  responseType: APITableResponseType;
  tableName: string;
  useQuerySlicer: boolean;
};

export const configureTableInitialValues = (
  tableSettings: ITableSettings,
): IConfigureTableInitialValues => {
  return {
    requestBody: tableSettings.requestBody,
    requestMethod: tableSettings.requestMethod,
    requestUrl: tableSettings.requestUrl,
    responseType: tableSettings.responseType,
    tableName: tableSettings.tableName,
    useQuerySlicer: tableSettings.useQuerySlicer,
  };
};

export const configureTableValidationSchema = () =>
  Yup.object().shape({
    tableName: Yup.string()
      .required("A table name is required.")
      .min(2, TABLE_NAME_LENGTH_INVALID)
      .max(128, TABLE_NAME_LENGTH_INVALID)
      .matches(TABLE_COLUMN_NAME_REGEX, TABLE_NAME_CHARACTERS_INVALID),

    requestUrl: Yup.string()
      .required("A Request URL is required.")
      .matches(/^https:\/\/[^\s/$.?#].[^\s]*$/, "Please enter a valid URL.")
      .test(
        "isValidUniqueRecordIdentifier",
        "An invalid record identifier was detected. Record identifiers start with a letter and only contain alphanumerical or underscore characters. For example, {Identifier}.",
        (value: string | undefined) => {
          if (!value) {
            return true;
          }
          return !hasPerRowRequestError(value);
        },
      ),
  });

/*
 * This function is required for Formik to perform submit validation. It intentionally does not do anything else,
 * as we are using Formik only for field validation on the Cards.
 */
export function dummyTableSubmit() {
  return;
}
