import { RequestType } from "../../../components/withAPI";
import { ToastrError } from "../../../services/toastrService";

// TODO: CLOUD-13011: Convert this to useMutate
export async function duplicateColumnsInTableResult(
  queryInput: string,
  api: any,
) {
  const data: { [key: string]: string | boolean } = {};
  data["query"] = queryInput;
  data["defaultCatalog"] = "CData";
  data["schemaOnly"] = true;

  const { status, payload, error, headers } = await api.callAPI(
    RequestType.Post,
    "/query",
    "Failed to create derived view due to the following error:",
    JSON.stringify(data),
  );
  const queryIdHeader = headers ? headers["x-cdata-queryid"] : "";
  try {
    if (status === 200) {
      if (payload.error) {
        ToastrError("Error fetching results", payload.error.message);
        return {
          duplicateColumns: [],
          queryId: queryIdHeader,
          invalidQuery: true,
        };
      }

      const duplicateColumns = [];
      const columnsSet = new Set();
      const result = payload.results[0].schema;

      for (let i = 0; i < result.length; i++) {
        const currColumn = result[i].columnLabel;
        if (columnsSet.has(currColumn)) {
          duplicateColumns.push(currColumn);
        } else {
          columnsSet.add(currColumn);
        }
      }

      return {
        duplicateColumns: duplicateColumns,
        queryId: queryIdHeader,
        invalidQuery: false,
      };
    } else {
      ToastrError("Error fetching results", error);
    }
  } catch (err) {
    ToastrError("Error fetching results", err);
  }
  return { duplicateColumns: [], queryId: queryIdHeader, invalidQuery: true };
}
