import { JobRunType } from "src/models/Cache/JobRunType";
import { ConnectionType, IConnection } from "../../models";
import { ICacheJobListItem } from "../../models/Cache/ICacheJobListItem";
import { JobFrequencyUnit } from "../../models/Cache/JobFrequencyUnit";
import { JobStatus } from "../../models/Cache/JobStatus";
import { JobType } from "../../models/Cache/JobType";
import { DestinationWriteScheme } from "../../models/ScheduledQueries/DestinationWriteScheme";
import { IScheduledQueryListItem } from "../../models/ScheduledQueries/IScheduledQueryListItem";
import { RootState } from "../../redux/store";

export const emptyCacheConnection: IConnection = {
  accountId: "",
  id: "",
  connectionString: "",
  created: "",
  driver: "",
  isTested: false,
  lastModified: "",
  apiConnectorProps: undefined,
  connectionType: ConnectionType.Shared,
  driverVersion: "",
  name: "",
  oAuthProps: "",
  permissions: [],
  userConnectionString: "",
  walletFileContent: "",
  walletFilePresent: false,
};

export const mockCacheConnection: IConnection = {
  accountId: "12345",
  id: "6267028b-46a9-4a69-b59c-682090985c65",
  connectionString: "MockConnection.Mock.Mock",
  created: "2023-12-13T18:18:44Z",
  driver: "PostgreSQL",
  isTested: true,
  lastModified: "2023-12-13T18:18:44Z",
  apiConnectorProps: undefined,
  connectionType: ConnectionType.Shared,
  driverVersion: "",
  name: "",
  oAuthProps: "",
  permissions: [],
  userConnectionString: "",
  walletFileContent: "",
  walletFilePresent: false,
};

export const emptyCacheJobs: ICacheJobListItem[] = [
  {
    id: "",
    name: "",
    enabled: false,
    sourceConnectionName: "",
    sourceConnectionDriver: "",
    jobFrequency: 0,
    jobFrequencyUnit: JobFrequencyUnit.Hour,
    sourceConnection: "",
    sourceSchema: "",
    sourceTable: "",
    status: {
      status: JobStatus.JOB_CREATED,
      info: "",
    },
    jobType: JobType.Caching,
  },
];

export const mockCacheJobs: ICacheJobListItem[] = [
  {
    id: "testId1",
    name: "testName1",
    enabled: true,
    sourceConnectionName: "testSourceName1",
    sourceConnectionDriver: "testSourceDriver1",
    jobFrequency: 10,
    jobFrequencyUnit: JobFrequencyUnit.Day,
    sourceConnection: "testConnectionId1",
    sourceSchema: "testSourceSchema1",
    sourceTable: "testSourceTable1",
    status: {
      status: JobStatus.JOB_COMPLETED,
      info: "",
    },
    jobType: JobType.Caching,
  },
  {
    id: "testId2",
    name: "testName2",
    enabled: true,
    sourceConnectionName: "testSourceName2",
    sourceConnectionDriver: "testSourceDriver2",
    jobFrequency: 1,
    jobFrequencyUnit: JobFrequencyUnit.Hour,
    sourceConnection: "testConnectionId2",
    sourceSchema: "testSourceSchema2",
    sourceTable: "testSourceTable2",
    status: {
      status: JobStatus.JOB_FAILED,
      info: "",
    },
    jobType: JobType.Caching,
  },
];

export const mockScheduledQueries: IScheduledQueryListItem[] = [
  {
    id: "scheduledid1",
    name: "scheduledquery1",
    destinationWriteScheme: DestinationWriteScheme.Append,
    destinationConnection: "testConnectionId1",
    destinationConnectionName: "testConnectionName1",
    destinationSchema: "testSchema1",
    destinationTable: "testTable1",
    jobFrequency: 1,
    jobFrequencyUnit: JobFrequencyUnit.Day,
    query: "testQuery1",
    enabled: true,
    logVerbosity: 2,
    status: {
      status: JobStatus.JOB_COMPLETED,
      info: "",
      runType: JobRunType.SCHEDULED,
    },
    jobType: JobType.ScheduledQuery,
  },
  {
    id: "scheduledid2",
    name: "scheduledquery2",
    destinationWriteScheme: DestinationWriteScheme.Append,
    destinationConnection: "testConnectionId2",
    destinationConnectionName: "testConnectionName2",
    destinationSchema: "testSchema2",
    destinationTable: "testTable2",
    jobFrequency: 2,
    jobFrequencyUnit: JobFrequencyUnit.Week,
    query: "testQuery2",
    enabled: true,
    logVerbosity: 3,
    status: {
      status: JobStatus.JOB_FAILED,
      info: "",
      runType: JobRunType.SCHEDULED,
    },
    jobType: JobType.ScheduledQuery,
  },
];

export const mockQueuedRunningJobs: ICacheJobListItem[] = [
  {
    id: "created1",
    name: "created1",
    enabled: true,
    sourceConnectionName: "testcreated",
    sourceConnectionDriver: "testcreated",
    jobFrequency: 1,
    jobFrequencyUnit: JobFrequencyUnit.Day,
    sourceConnection: "createdConnection1",
    sourceSchema: "testcreatedschema",
    sourceTable: "testcreatedtable",
    status: {
      status: JobStatus.JOB_CREATED,
      info: "",
    },
    jobType: JobType.Caching,
  },
  {
    id: "queued1",
    name: "queued1",
    enabled: true,
    sourceConnectionName: "testqueued",
    sourceConnectionDriver: "testqueued",
    jobFrequency: 1,
    jobFrequencyUnit: JobFrequencyUnit.Day,
    sourceConnection: "queuedConnecion1",
    sourceSchema: "testqueuedschema",
    sourceTable: "testqueuedtable",
    status: {
      status: JobStatus.JOB_QUEUED,
      info: "",
    },
    jobType: JobType.Caching,
  },
  {
    id: "running1",
    name: "running1",
    enabled: true,
    sourceConnectionName: "testrunning",
    sourceConnectionDriver: "testrunning",
    jobFrequency: 1,
    jobFrequencyUnit: JobFrequencyUnit.Day,
    sourceConnection: "runningConnection1",
    sourceSchema: "testrunningschema",
    sourceTable: "testrunningtable",
    status: {
      status: JobStatus.JOB_RUNNING,
      info: "",
    },
    jobType: JobType.Caching,
  },
];

export const mockEmptyRedux: Partial<RootState> = {
  cacheConnection: {
    ...emptyCacheConnection,
  },
};

export const mockPopulatedRedux: Partial<RootState> = {
  cacheConnection: {
    ...mockCacheConnection,
  },
};
