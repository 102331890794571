import { Component } from "react";

import {
  Button,
  Collapse,
  Navbar,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { logoutUser } from "../../../routes/AuthorizeContext";
import CDataIcon from "../../../assets/img/icons/CloudLogo.svg?react";
import { store } from "../../../redux/store";

interface IOnboardingHeaderProps {
  showErrorModal: (text: string, error: any) => void;
}

interface IOnboardingHeaderState {}

class OnboardingHeader extends Component<
  IOnboardingHeaderProps,
  IOnboardingHeaderState
> {
  constructor(props: any) {
    super(props);
  }

  getComponentName() {
    return "pages-initialSetup-components-OnboardingHeader";
  }

  render() {
    return (
      <Navbar color="white" className={this.getComponentName()} light expand>
        <Button
          transparent={"true"}
          color="white"
          className="no-pointer-event ps-0"
        >
          <CDataIcon className="cdata-logo" aria-description="CData logo" />
        </Button>
        <Collapse navbar>
          <Nav className="ms-auto" navbar>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                <span className="text-dark">{store.getState().user.email}</span>
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem tag="a" onClick={() => logoutUser()}>
                  <i className="fa fa-solid fa-right-from-bracket align-middle me-2"></i>
                  Sign out
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}

export default OnboardingHeader;
