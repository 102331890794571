/* eslint-disable max-lines */
import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { customFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  FeatureId,
  IODataEntitySet,
  IODataEntityType,
  IODataMetadata,
} from "../../models";

import {
  Button,
  ButtonDropdown,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Spinner,
  InputGroup,
  UncontrolledPopover,
  PopoverBody,
  UncontrolledTooltip,
} from "reactstrap";

import Loader from "../../components/Loader";
import { IModalFunctions } from "../../layouts/Dashboard";
import { IModalProps } from "../../components/CDataModal";
import ODataWizard from "./Wizard/ODataWizard";
import { IODataTableRow, ODataContext } from "./Wizard/ODataContext";
import { CDataComponent } from "../../utility/CDataComponent";
import { getDriverIcon } from "../../components/drivers/DriverIconFactory";
import { ToastrSuccess, ToastrWarning } from "../../services/toastrService";
import { ErrorTable, IErrorTableRow } from "../../components/ErrorTable";
import {
  RequestType,
  IAPI,
  withAPI,
  BackendType,
} from "../../components/withAPI";
import {
  withRouter,
  ComponentWithRouterProps,
} from "../../components/withRouter";
import { isFeatureAllowed } from "../../utility/SubscriptionAddonsFactory";
import CustomTextFilter from "../../components/CustomTextFilter";
import { getSettingsPageSubscriptionTab } from "../../utility/SubscriptionProvider";
import { store } from "../../redux/store";

export function checkPremiumDisabled(connection: string) {
  const drivers = store.getState().driversList?.drivers;
  connection = connection.replace(/\s/g, "");
  const isPremium = drivers?.filter((driver) => driver.driver === connection)[0]
    ?.premium;
  const premiumConnectorDisabled =
    isPremium &&
    !isFeatureAllowed(
      store.getState().subscription?.limits?.availableFeatureIds ?? [],
      FeatureId.PremiumDataSources,
    );
  return premiumConnectorDisabled;
}

let odataEndpoint: string;
if (window.location.origin.includes("localhost")) {
  odataEndpoint = window.location.origin + "/odata/service";
} else {
  odataEndpoint = window.location.origin + "/api/odata/service";
}

type IODataProps = IModalFunctions & IAPI & ComponentWithRouterProps;

interface IODataState {
  catalog: any[];
  odataEnabled: boolean;
  odataExists: boolean;
  odataMetadata: IODataMetadata | null;
  alreadyExistingTables: IODataTableRow[] | undefined;
  tables: (IODataTableRow | null)[];
  selectedTables: IODataTableRow[];
  selectedTableNames: string[];
  paginationDropdownOpen: boolean;
  paginationSizePerPage: number;
  paginationCurrentPage: number;
  failedTables: IErrorTableRow[];
  errorTableTitle: string;
  errorTableData: any[];
  fiveOrFewerErrors: boolean;
  displayErrorTable: boolean;
  addButtonEnabled: boolean;
  deleteButtonEnabled: boolean;
  odataWizardDisplayed: boolean;
  odataWizard: JSX.Element;
  processingRequest: boolean;
  loading: boolean;
}

class OData
  extends Component<IODataProps, IODataState>
  implements CDataComponent
{
  constructor(props: IODataProps) {
    super(props);
    this.setAlreadyExistingTables = this.setAlreadyExistingTables.bind(this);
    this.toggleOdataModal = this.toggleOdataModal.bind(this);
    this.openDeleteOdataTableModal = this.openDeleteOdataTableModal.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.copyToClipboard = this.copyToClipboard.bind(this);

    this.state = {
      catalog: [],
      odataEnabled: true,
      odataExists: false,
      odataMetadata: null,
      alreadyExistingTables: [],
      tables: [],
      selectedTables: [],
      selectedTableNames: [],
      paginationDropdownOpen: false,
      paginationSizePerPage: 20,
      paginationCurrentPage: 1,
      failedTables: [],
      errorTableTitle: "",
      errorTableData: [],
      fiveOrFewerErrors: false,
      displayErrorTable: false,
      addButtonEnabled:
        !store.getState().usage?.connectionsOverLimit &&
        !store.getState().usage?.dataSourcesOverLimit &&
        !store.getState().usage?.usersOverLimit,
      deleteButtonEnabled: false,
      odataWizardDisplayed: false,
      odataWizard: <React.Fragment />,
      processingRequest: false,
      loading: true,
    };
  }

  public getComponentName() {
    return "pages-odata-OData";
  }

  async componentDidMount() {
    await this.getCurrentOData();
    await this.getCatalogs();
  }

  getOverageEntity = () => {
    const connectionsOverLimit = store.getState().usage?.connectionsOverLimit;
    const usersOverLimit = store.getState().usage?.usersOverLimit;
    return connectionsOverLimit && usersOverLimit
      ? "connections and users"
      : connectionsOverLimit
        ? "connections"
        : usersOverLimit
          ? "users"
          : "";
  };

  toggleOdataModal() {
    this.setState({ odataWizardDisplayed: !this.state.odataWizardDisplayed });
  }

  togglePaginationDropdown = () => {
    this.setState({
      paginationDropdownOpen: !this.state.paginationDropdownOpen,
    });
  };

  setAlreadyExistingTables(metadata: IODataMetadata | null) {
    const tables =
      metadata?.dataServices?.schema?.entityContainer?.entitySets?.map(
        (table: IODataEntitySet) => {
          return {
            fullName: table.fullName,
            name: table.name,
          };
        },
      );

    this.setState({ alreadyExistingTables: tables, odataExists: true });
  }

  async getCurrentOData() {
    const emptyOdata: IODataMetadata = {
      dataServices: {
        schema: {
          namespace: "CData",
          entityTypes: [],
          enumTypes: [],
          complexTypes: [],
          functions: [],
          actions: [],
          entityContainer: {
            name: "CData",
            entitySets: [],
            singletons: [],
            functionImports: [],
            actionImports: [],
          },
        },
      },
    };

    const { status, payload, error } = await this.props.callAPI(
      RequestType.Get,
      "/odataStorage/readFromBlob",
      "",
    );

    if (status === 200) {
      if (Object.keys(payload).length === 0) {
        this.setState({
          odataExists: false,
          odataMetadata: emptyOdata,
        });
        return;
      }
      const data = payload;
      if (data) {
        this.setAlreadyExistingTables(data);
        this.setState({
          odataExists: true,
          odataMetadata: data,
        });
      } else {
        this.setState({
          odataExists: false,
          odataMetadata: emptyOdata,
        });
      }
    } else {
      if (status === 401) {
        this.props.showTimeoutModal();
      } else if (status === 404) {
        this.setState({
          odataExists: false,
          odataMetadata: emptyOdata,
        });
      } else {
        const err = error;
        this.props.showErrorModal(
          "Failed to get OData due to the following error:",
          err.error,
        );
      }
    }
  }

  async getCatalogs() {
    const { status, payload } = await this.props.callAPI(
      RequestType.Get,
      "/catalogs",
      "Failed to get catalog due to the following error:",
      null,
      BackendType.QueryRouter,
    );
    if (status === 200) {
      if (!payload.error) {
        let catalog = payload.results[0].rows;
        //Filter out CData result, Track Cloud-1807 for Driver level fix for this issue to remove this
        catalog = catalog.filter(
          (t: any) => !(t[0] === "CData" && t[1] === ""),
        );
        this.setState({ catalog: catalog });
        await this.setInitialTables(catalog);
      } else {
        this.setState({ odataEnabled: false });
        this.props.showErrorModal(
          "Failed to get catalog due to the following error:",
          payload.error,
        );
      }
    }

    this.setState({ loading: false });
  }

  async setInitialTables(catalog: any[]) {
    let tables: (IODataTableRow | null)[] = [];
    if (this.state.odataExists && this.state.odataEnabled) {
      tables =
        this.state.odataMetadata?.dataServices?.schema?.entityContainer?.entitySets?.map(
          (table: IODataEntitySet) => {
            const connectionNameIndex = 0;
            const connectionName =
              table.fullName.split(".")[connectionNameIndex];
            const currentConnection = catalog.find(
              (connection: string[]) =>
                connection[connectionNameIndex] === connectionName,
            );
            if (currentConnection) {
              return table;
            } else {
              return null;
            }
          },
        ) ?? [];
    }

    // Remove not found/undefined/null values
    tables = tables.filter((table) => table);
    this.setState({ tables: tables });
  }

  isHeaderCheckboxIndeterminate = () => {
    const checkAllTablesCheckbox = document.getElementById(
      "checkAllTables",
    ) as HTMLInputElement;

    const tables = this.state.alreadyExistingTables;
    if (!tables) return;

    const selectedTables = this.state.selectedTables;
    const sizePerPage = this.state.paginationSizePerPage;
    const currentPage = this.state.paginationCurrentPage;

    const totalPages = Math.ceil(tables.length / sizePerPage);
    const leftoverTables = tables.length % sizePerPage;
    const tablesDisplayed =
      currentPage === totalPages && leftoverTables !== 0
        ? leftoverTables
        : sizePerPage;

    if (checkAllTablesCheckbox) {
      if (
        selectedTables.length > 0 &&
        selectedTables.length < tablesDisplayed
      ) {
        checkAllTablesCheckbox.indeterminate = true;
      } else {
        checkAllTablesCheckbox.indeterminate = false;
      }
    }
  };

  copyToClipboard() {
    navigator.clipboard.writeText(odataEndpoint);
  }

  openEditTablePage(event: React.MouseEvent, fullName: string, name: string) {
    const connectionNameIndex = 0;
    const connectionTypeIndex = 2;
    const connectionName = fullName.split(".")[connectionNameIndex];
    const currentConnection =
      connectionName === "CData"
        ? ["CData", "DerivedViews", "Derived View", "Unknown"]
        : this.state.catalog.find(
            (connection: string[]) =>
              connection[connectionNameIndex] === connectionName,
          );
    const premiumConnectorDisabled = currentConnection
      ? checkPremiumDisabled(currentConnection[connectionTypeIndex])
      : true;
    event.preventDefault();
    if (!premiumConnectorDisabled) {
      this.props.router.navigate("/odata/editTable", {
        state: {
          odataMetadata: this.state.odataMetadata,
          fullName: fullName,
          name: name,
        },
      });
    }
  }

  async openDeleteOdataTableModal(event: any, tableName: any) {
    event.stopPropagation();
    let selectedTables: any = [];
    if (tableName !== null) {
      selectedTables = [
        this.state.alreadyExistingTables?.find(
          (existingTable: any) => existingTable.name === tableName,
        ),
      ];
    } else {
      selectedTables = this.state.selectedTables;
    }

    let modalTitle: string;
    let modalMessage: string;
    switch (selectedTables.length) {
      case 1:
        modalTitle = "Delete Table";
        modalMessage =
          "Are you sure you want to delete this table from your OData?";
        break;
      default:
        modalTitle = "Delete Tables";
        modalMessage =
          "You have multiple tables selected. Are you sure you want to delete these tables from your odata?";
    }

    const modal = {
      title: modalTitle,
      body: <text>{modalMessage}</text>,
      primaryButton: (
        <Button
          color="danger"
          onClick={() => this.onDelete(selectedTables)}
          hidden={selectedTables.length === 0}
        >
          Delete
        </Button>
      ),
      secondaryButton: (
        <Button color="secondary" onClick={this.props.toggleModal}>
          Cancel
        </Button>
      ),
      displayToggleCloseButton: true,
      modalSize: "lg",
      displayed: true,
    } as IModalProps;
    this.props.setModal(modal);
  }

  async onDelete(tablesToDelete: any): Promise<any> {
    const selectedTables = tablesToDelete.map((table: any) => {
      return { ...table, fullName: table.fullName };
    });

    const metadata = this.state.odataMetadata;

    selectedTables.forEach((table: any) => {
      if (metadata?.dataServices?.schema) {
        if (metadata.dataServices.schema.entityContainer)
          metadata.dataServices.schema.entityContainer.entitySets =
            metadata.dataServices.schema.entityContainer.entitySets?.filter(
              (enitySet: IODataEntitySet) =>
                !(
                  enitySet.fullName === table.fullName &&
                  enitySet.name === table.name
                ),
            );

        metadata.dataServices.schema.entityTypes =
          metadata.dataServices.schema.entityTypes?.filter(
            (entityType: IODataEntityType) =>
              !(
                entityType.fullName === table.fullName &&
                entityType.name === table.name
              ),
          );
      }
    });

    const { status, error } = await this.props.callAPI(
      RequestType.Post,
      "/odataStorage/writeToBlob",
      "",
      metadata,
    );

    if (status === 200) {
      ToastrSuccess(
        "Tables Deleted Successfully!",
        "You have successfully deleted tables from your OData.",
      );
      this.setAlreadyExistingTables(metadata);
      this.setState({
        odataMetadata: metadata,
        selectedTables: [],
        selectedTableNames: [],
        deleteButtonEnabled: false,
      });
    } else {
      const err = error;
      ToastrWarning("Unable to delete table(s)", err);
      return null;
    }
    this.props.toggleModal();
  }

  setFailedTables = (tables: any) => {
    if (tables.length === 1) {
      this.setState({ errorTableTitle: "1 Table Failed" });
    }

    if (tables.length > 1) {
      this.setState({ errorTableTitle: `${tables.length} Tables Failed` });
    }

    if (tables.length <= 5) {
      this.setState({ fiveOrFewerErrors: true });
    } else {
      this.setState({ fiveOrFewerErrors: false });
    }

    const failedTables = tables.map((table: any) => {
      return {
        name: table.table,
        errorMessage: table.message,
      };
    });

    this.setState({ failedTables: failedTables });
  };

  renderOdataTableList() {
    let tables: (IODataTableRow | undefined)[] | undefined = [];
    if (this.state.odataExists && this.state.odataEnabled) {
      tables =
        this.state.odataMetadata?.dataServices?.schema?.entityContainer?.entitySets?.map(
          (table: IODataEntitySet) => {
            const connectionNameIndex = 0;
            const connectionTypeIndex = 2;
            const connectionName =
              table.fullName.split(".")[connectionNameIndex];
            const currentConnection =
              connectionName === "CData"
                ? ["CData", "DerivedViews", "Derived View", "Unknown"]
                : this.state.catalog.find(
                    (connection: string[]) =>
                      connection[connectionNameIndex] === connectionName,
                  );
            const premiumConnectorDisabled = currentConnection
              ? checkPremiumDisabled(currentConnection[connectionTypeIndex])
              : true;

            const premiumConnectorTooltip =
              premiumConnectorDisabled && this.state.addButtonEnabled ? (
                <UncontrolledTooltip
                  placement="top"
                  target="premiumConnection"
                  trigger="hover"
                  autohide={false}
                >
                  Premium Connectors are not available with your subscription.
                  <br />
                  <a
                    className="upgrade-link"
                    onClick={() =>
                      this.props.router.navigate(
                        "/settings?defaultTab=" +
                          getSettingsPageSubscriptionTab(),
                      )
                    }
                  >
                    Upgrade
                  </a>
                  &nbsp; your plan today to continue using this connection.
                </UncontrolledTooltip>
              ) : null;
            if (currentConnection) {
              const connectionType = currentConnection[connectionTypeIndex];
              return {
                key: `${table.fullName}.${table.name}`,
                fullName: table.fullName,
                name: !premiumConnectorDisabled ? (
                  table.name
                ) : (
                  <div
                    key={table.name}
                    className={
                      premiumConnectorDisabled && this.state.addButtonEnabled
                        ? "premiumConnectorDisabled"
                        : ""
                    }
                  >
                    {table.name}
                  </div>
                ),
                connection:
                  connectionName === "CData" ? (
                    <>
                      <i className="fa fa-regular fa-border-none fa-lg align-middle"></i>
                      {"Derived View"}
                    </>
                  ) : (
                    <div
                      id={
                        premiumConnectorDisabled && this.state.addButtonEnabled
                          ? "premiumConnection"
                          : ""
                      }
                      className={
                        premiumConnectorDisabled && this.state.addButtonEnabled
                          ? "premiumConnectorDisabled"
                          : ""
                      }
                    >
                      {getDriverIcon(connectionType, "connection-icon me-2")}
                      {connectionName}
                      {premiumConnectorTooltip}
                    </div>
                  ),
                editButtons: (
                  <>
                    <button
                      className={
                        !this.state.addButtonEnabled || premiumConnectorDisabled
                          ? "edit-button-disabled table-button me-1"
                          : "table-button me-1"
                      }
                      onClick={(event: React.MouseEvent) => {
                        this.state.addButtonEnabled
                          ? this.openEditTablePage(
                              event,
                              table.fullName,
                              table.name,
                            )
                          : "";
                      }}
                      aria-label="edit"
                      data-testid="button-open-edit"
                    >
                      <i className="fa-regular fa-pen align-middle"></i>
                    </button>
                    <button
                      onClick={(event) =>
                        this.openDeleteOdataTableModal(event, table.name)
                      }
                      className="table-button"
                      aria-label="delete"
                      data-testid="button-delete"
                    >
                      <i className="fa-regular fa-xmark fa-lg align-middle"></i>
                    </button>
                  </>
                ),
              };
            }
          },
        );
    }

    // Remove not found/undefined values
    tables = tables?.filter((table) => table);

    const odataColumns = [
      {
        dataField: "key",
        text: "",
        sort: false,
        hidden: true,
      },
      {
        dataField: "fullName",
        text: "Full Name",
        sort: false,
        hidden: true,
      },
      {
        dataField: "name",
        text: "Name",
        sort: true,
        sortFunc: (a: any, b: any, order: string) => {
          if (order === "asc") {
            return a.localeCompare(b);
          }
          return b.localeCompare(a);
        },
        filter: customFilter({}),
        filterRenderer: (onFilter: (value: string) => void, column: any) => (
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            placeholder="Search by name..."
          />
        ),
      },
      {
        dataField: "connection",
        text: "Source",
        sort: true,
        sortFunc: (a: any, b: any, order: string) => {
          if (order === "asc") {
            return a.props.children[1].localeCompare(b.props.children[1]);
          }
          return b.props.children[1].localeCompare(a.props.children[1]);
        },
      },
      {
        dataField: "editButtons",
        text: "",
        sort: false,
      },
    ];
    const selectOdataRow: any = {
      mode: "checkbox",
      clickToSelect: true,
      bgColor: "#f8f9fa",
      selected: this.state.selectedTableNames,
      selectionRenderer: (props: any) => (
        <Input
          type={props.mode}
          checked={props.checked}
          onChange={(): any => {
            return null;
          }}
        />
      ),
      selectionHeaderRenderer: (props: any) => (
        <Input
          id="checkAllTables"
          type={props.mode}
          checked={props.checked}
          ref={() => this.isHeaderCheckboxIndeterminate()}
          onChange={(): any => {
            return null;
          }}
        />
      ),
      onSelect: (row: any, isSelect: any, rowIndex: any, event: any) => {
        if (event.currentTarget instanceof HTMLTableCellElement) {
          if (isSelect) {
            const selectedTables = [...this.state.selectedTables, row];
            const selectedTableNames = [
              ...this.state.selectedTableNames,
              row.key,
            ];
            this.setState({
              selectedTables: selectedTables,
              selectedTableNames: selectedTableNames,
              deleteButtonEnabled: selectedTables.length > 0,
            });
          } else {
            const selectedTables = this.state.selectedTables.filter(
              (table: any) => table.key !== row.key,
            );
            const selectedTableNames = this.state.selectedTableNames.filter(
              (tableName: string) => tableName !== row.key,
            );
            this.setState({
              selectedTables: selectedTables,
              selectedTableNames: selectedTableNames,
              deleteButtonEnabled: selectedTables.length > 0,
            });
          }
        } else {
          if (this.state.addButtonEnabled) {
            this.openEditTablePage(event, row.fullName, row.name);
          }
          return false;
        }
      },
      onSelectAll: (isSelect: any, rows: any) => {
        if (isSelect) {
          if (rows.length === 0) {
            return false;
          }
          this.setState({
            selectedTables: rows,
            selectedTableNames: rows.map((row: any) => row.key),
            deleteButtonEnabled: true,
          });
        } else {
          this.setState({
            selectedTables: [],
            selectedTableNames: [],
            deleteButtonEnabled: false,
          });
        }
      },
    };

    const odataTable = (
      <BootstrapTable
        bootstrap4
        id="odataTable"
        classes="odata-table mt-2 mb-0"
        bordered={false}
        keyField="key"
        columns={odataColumns}
        data={tables ?? []}
        selectRow={selectOdataRow}
        filter={filterFactory()}
        noDataIndication={() => (
          <div className="empty-table-row">
            Click the Add button to add tables to your OData.
          </div>
        )}
        pagination={paginationFactory({
          sizePerPage: 20,
          withFirstAndLast: false,
          sizePerPageList: [5, 10, 20, 50, 100],
          hidePageListOnlyOnePage: true,
          onSizePerPageChange: (sizePerPage, page) => {
            this.setState({
              paginationSizePerPage: sizePerPage,
              paginationCurrentPage: page,
            });
          },
          onPageChange: (page) => {
            this.setState({
              selectedTables: [],
              selectedTableNames: [],
              paginationCurrentPage: page,
            });
          },
          sizePerPageRenderer: ({
            options,
            currSizePerPage,
            onSizePerPageChange,
          }) => (
            <>
              <div className="pagination-container mt-2 mb-2">
                <div className="rows-per-page me-2">Rows per page:</div>
                <ButtonDropdown
                  isOpen={this.state.paginationDropdownOpen}
                  toggle={this.togglePaginationDropdown}
                >
                  <DropdownToggle className="dropdown-toggle" caret>
                    {currSizePerPage}
                  </DropdownToggle>
                  <DropdownMenu>
                    {options.map((option) => (
                      <DropdownItem
                        key={option.page}
                        onClick={() => onSizePerPageChange(option.page)}
                      >
                        {option.text}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </ButtonDropdown>
              </div>
            </>
          ),
        })}
      />
    );

    const odataTableDisabled = !this.state.addButtonEnabled;
    const odataTableDisabledTooltip = odataTableDisabled ? (
      <UncontrolledTooltip
        placement="top"
        target="odataTableDisabled"
        trigger="hover"
        autohide={false}
      >
        <a
          className="upgrade-link"
          onClick={() =>
            this.props.router.navigate(
              "/settings?defaultTab=" + getSettingsPageSubscriptionTab(),
            )
          }
        >
          Upgrade
        </a>
        &nbsp;your plan or remove {this.getOverageEntity()} to retain full
        Connect Cloud functionality.
      </UncontrolledTooltip>
    ) : null;

    return (
      <>
        <ErrorTable
          title={this.state.errorTableTitle}
          entityName="Table"
          data={this.state.failedTables}
          className="mb-4"
        />
        <Card>
          <CardBody className="pb-2">
            <div
              id="odataTableDisabled"
              className={
                odataTableDisabled ? "odata-table-disabled mt-5" : "mt-5"
              }
            >
              {odataTable}
              {odataTableDisabledTooltip}
            </div>
          </CardBody>
        </Card>
      </>
    );
  }

  render() {
    const contents = this.state.loading ? (
      <Container fluid className="p-0">
        <Loader />
      </Container>
    ) : (
      this.renderOdataTableList()
    );
    const addOdataButtonTooltip = !this.state.addButtonEnabled ? (
      <UncontrolledTooltip
        placement="left"
        target="addODataButton"
        trigger="hover"
        autohide={false}
      >
        <a
          className="upgrade-link"
          onClick={() =>
            this.props.router.navigate(
              "/settings?defaultTab=" + getSettingsPageSubscriptionTab(),
            )
          }
        >
          Upgrade
        </a>
        &nbsp;your plan or remove {this.getOverageEntity()} to retain full
        Connect Cloud functionality.
      </UncontrolledTooltip>
    ) : null;
    return (
      <Container fluid className={`p-0 ${this.getComponentName()}`}>
        <Row>
          <Col>
            <div hidden={!this.state.processingRequest}>
              <div className="loading-background" />
              <Spinner
                className="spinner-border loading-spinner"
                color="info"
              />
            </div>
            <h1 className="h3 ms-1 mb-2">OData</h1>
          </Col>
        </Row>
        <Row className="mb-3 mt-3">
          <Col className="odata-endpoint">
            <div className="odata-endpoint-label">OData URL:</div>
            <InputGroup className="mb-3">
              <Input
                disabled
                type="text"
                name="text"
                id="token"
                value={odataEndpoint}
                className="odata-endpoint-input"
              />
              <Button
                id="copyToClipboardButton"
                name="copyToClipboardButton"
                color="secondary"
                onClick={this.copyToClipboard}
              >
                <i className="fa fa-copy align-middle no-pointer-event" />
              </Button>
              <UncontrolledPopover
                placement="top"
                target="copyToClipboardButton"
                trigger="legacy"
              >
                <PopoverBody>Copied Successfully!</PopoverBody>
              </UncontrolledPopover>
            </InputGroup>
          </Col>
          <Col className="text-end">
            <div className="action-button">
              <Button
                id="addODataButton"
                color="primary"
                onClick={this.toggleOdataModal}
                data-toggle="tooltip"
                className={
                  !this.state.addButtonEnabled
                    ? "add-button-disabled me-1 card-actions float-end"
                    : "me-1 card-actions float-end"
                }
                data-testid="button-odata-add"
              >
                <i className="fa fa-plus icon no-pointer-event" />
                Add
              </Button>
              <Button
                disabled={!this.state.deleteButtonEnabled}
                color="secondary"
                onClick={(event: React.MouseEvent) =>
                  this.openDeleteOdataTableModal(event, null)
                }
                className="me-1 card-actions float-end"
                data-testid="button-odata-delete"
              >
                <i className="fa fa-times icon no-pointer-event" />
                Delete
              </Button>
              {addOdataButtonTooltip}
            </div>
          </Col>
        </Row>
        {contents}
        <ODataContext.Provider
          value={{
            selectedConnection: { name: "", type: "" },
            alreadyExistingTables: this.state.alreadyExistingTables ?? [],
            availableTables: [],
            failedTables: this.state.failedTables,
            metadata: this.state.odataMetadata,
            schemas: [],
          }}
        >
          <ODataWizard
            catalog={this.state.catalog}
            odataWizardDisplayed={this.state.odataWizardDisplayed}
            toggleOdataModal={this.toggleOdataModal}
            setAlreadyExistingTables={this.setAlreadyExistingTables}
            setFailedTables={this.setFailedTables}
            onCompletion={(): any => void 0}
            odataExists={this.state.odataExists}
          />
        </ODataContext.Provider>
      </Container>
    );
  }
}

export default withAPI(withRouter(OData));
