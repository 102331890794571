import { useContext } from "react";
import { Col, ListGroup, ListGroupItem, Row } from "reactstrap";
import { ViewMode } from "./IFilterList";
import { AuthorizeContext } from "./guards/UserAuthenticationGuard";
import { UserRole } from "../models/Users/UserRole";
import { ButtonType, CDataButton } from "./buttons/CDataButton";

interface IWorkspaceRibbon {
  setViewMode?: (viewMode: ViewMode) => void;
  toggleSelectAll: () => void;
  selectedDataObjects: any;
  listOfDataObjects: any[];
  displayViewButtons: boolean;
  displayAssetCount: boolean;
  viewMode?: ViewMode;
}

const WorkspaceRibbon = (props: IWorkspaceRibbon) => {
  const userAccount = useContext(AuthorizeContext);
  const isAdminUser = userAccount && userAccount.role === UserRole.Admin;

  function setViewToList() {
    props.setViewMode!(ViewMode.List);
  }
  function setViewToGrid() {
    props.setViewMode!(ViewMode.Grid);
  }

  return (
    <Row className="view-selection-container">
      {props.displayViewButtons ? (
        <>
          <Col className="view-selection-buttons">
            <ListGroup horizontal className="connected-button-group">
              <ListGroupItem
                action
                type="button"
                onClick={() => setViewToList()}
                active={props.viewMode === ViewMode.List}
                className="selection-button text-center"
              >
                <i className="fa fa-regular fa-list" />
              </ListGroupItem>
              <ListGroupItem
                action
                type="button"
                onClick={() => setViewToGrid()}
                active={props.viewMode === ViewMode.Grid}
                className="selection-button text-center"
              >
                <i className="fa fa-solid fa-table-cells-large" />
              </ListGroupItem>
            </ListGroup>
          </Col>
        </>
      ) : null}
      {props.displayAssetCount ? (
        <Col className="asset-count">
          <Col className="assets-count p-0">
            <i className="fa-regular fa-files assets-icon"></i>&nbsp;
            {props.listOfDataObjects.length}&nbsp;assets
          </Col>
        </Col>
      ) : null}
      {isAdminUser ? (
        <Col className="view-selection-selectAll">
          <CDataButton
            buttonType={ButtonType.Tertiary}
            key="selectAllWorkspaces"
            className="flex-child"
            onClick={() => props.toggleSelectAll()}
          >
            Select All
          </CDataButton>
        </Col>
      ) : null}
    </Row>
  );
};

export default WorkspaceRibbon;
