import { IBatchDeleteRequest } from "../../../models";
import { JobType } from "../../../models/Cache/JobType";
import { JobListItem } from "../Jobs";
import { deleteCacheJobs } from "./deleteCacheJobs";
import { deleteScheduledQueries } from "./deleteScheduledQueries";

export async function deleteSingleJob(job: JobListItem) {
  const deleteRequest: IBatchDeleteRequest = { ids: [job.id] };
  if (job.jobType === JobType.Caching) {
    return deleteCacheJobs(deleteRequest);
  } else {
    return deleteScheduledQueries(deleteRequest);
  }
}
