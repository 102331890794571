import React, { useState } from "react";
import { Button, Spinner } from "reactstrap";

import { CDataModalV2 } from "../../../components/modal/CDataModalV2";
import { useAPI } from "../../../components/useAPI";
import { RequestType } from "../../../components/withAPI";
import { IBatchDeleteResponse, ICustomReport } from "../../../models";
import { ToastrError } from "../../../services/toastrService";

interface IDeleteReportModalProps {
  connectionId: string;
  selectedReports: ICustomReport[];
  onDeleteSucceeded: (
    deletedReports: ICustomReport[],
    bulkDeleteResponse: IBatchDeleteResponse | null,
  ) => void;
  isModalOpen: boolean;
  onCloseModal: () => void;
}

export const CustomReportDeleteReportModal = (
  props: IDeleteReportModalProps,
) => {
  const {
    connectionId,
    selectedReports,
    onDeleteSucceeded,
    isModalOpen,
    onCloseModal,
  } = props;
  const [processing, setProcessing] = useState(false);
  const api = useAPI();

  const onDelete = async (selectedReport: ICustomReport) => {
    setProcessing(true);
    const { status } = await api.callAPI(
      RequestType.Delete,
      `/account/connections/${connectionId}/customReports/${selectedReport.id}`,
      "Failed to delete selected report due to the following error:",
    );

    setProcessing(false);

    if (status === 200) {
      onDeleteSucceeded([selectedReport], null);
    } else {
      onCloseModal();
    }
  };

  const onBatchDelete = async (selectedReports: ICustomReport[]) => {
    setProcessing(true);
    const requestBody = {
      ids: selectedReports.map((report) => report.id),
    };

    const { status, payload } = await api.callAPI<IBatchDeleteResponse>(
      RequestType.Delete,
      `/account/connections/${connectionId}/customReports`,
      "",
      requestBody,
    );

    setProcessing(false);

    if (status === 200) {
      const data = payload;
      if (data?.error) {
        ToastrError(
          "Error",
          "There was a problem deleting some of your reports. Please try again.",
        );
      }

      onDeleteSucceeded(selectedReports, data ?? null);
    } else {
      if (status !== 401) {
        const err = payload;
        ToastrError(
          "Failed to delete selected report due to the following error:",
          err?.error,
        );
        onCloseModal();
      }
    }
  };

  return (
    <>
      <div hidden={!processing}>
        <div className="loading-background" />
        <Spinner className="spinner-border loading-spinner" color="info" />
      </div>
      <CDataModalV2
        close={onCloseModal}
        displayed={isModalOpen}
        title={
          selectedReports.length === 1
            ? "Delete Custom Report"
            : "Delete Custom Reports"
        }
        spacedFooter={false}
        primaryButton={
          <Button
            color="danger"
            onClick={() =>
              selectedReports.length === 1
                ? onDelete(selectedReports[0])
                : onBatchDelete(selectedReports)
            }
            data-testid="button-confirm-delete-report"
          >
            Delete
          </Button>
        }
        secondaryButton={
          <Button
            color="secondary"
            onClick={onCloseModal}
            data-testid="button-cancel-delete-report"
          >
            Cancel
          </Button>
        }
      >
        <div>
          {selectedReports.length === 1
            ? "You are about to delete a custom report. Are you sure you want to proceed?"
            : "You are about to delete multiple custom reports. Are you sure you want to proceed?"}
        </div>
      </CDataModalV2>
    </>
  );
};
