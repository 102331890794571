import { MouseEventHandler, useContext } from "react";
import { connect } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  toggleSidebar,
  showSidebar,
  hideSidebar,
} from "../../redux/actions/sidebarActions";
import { Badge } from "reactstrap";
import { AuthorizeContext } from "../guards/UserAuthenticationGuard";
import { Location, NavLink, useLocation } from "react-router-dom";
import getRoutePaths from "./getRoutePaths";
import classNames from "classnames";
import { useFeatureFlags } from "../../hooks/useFeatureFlags";
import { useAppSelector } from "../../redux/hooks";

interface ISidebarItem {
  name: string;
  icon: JSX.Element;
  location: Location;
  to: string;
  isOpen: boolean;
  onClick: MouseEventHandler<HTMLDivElement>;
  isNew: boolean;
  testId: string | undefined;
}

const SidebarItem = ({
  name,
  icon: Icon,
  location,
  to,
  isOpen,
  onClick,
  isNew,
  testId,
}: ISidebarItem) => {
  const getSidebarItemClass = (path: any) => {
    if (
      location.pathname.toLowerCase() === "/apiconnector" &&
      path === "/connections"
    )
      return "active";

    return location.pathname === path ||
      (path !== "/" && location.pathname.startsWith(path))
      ? "active"
      : "";
  };

  return (
    <div
      className={"sidebar-item " + getSidebarItemClass(to)}
      onClick={onClick}
    >
      {to.includes("http") ? (
        <a
          href={to}
          target="_blank"
          rel="noopener noreferrer"
          className={`sidebar-link ${!isOpen ? "collapsed" : ""}`}
          data-testid={testId}
        >
          <div className="sidebar-link-icon-container">
            {Icon ? Icon : null}
          </div>
          <div className="sidebar-link-spacer" />
          <div className="sidebar-link-name align-middle">
            <span className="sidebar-link-name-outer">{name}</span>
          </div>
        </a>
      ) : (
        <NavLink
          to={to}
          className={({ isActive }) =>
            "sidebar-link" +
            (!isOpen ? " collapsed" : "") +
            (isActive ? " active" : "")
          }
          data-testid={testId}
        >
          <div className="sidebar-link-icon-container">
            {Icon ? Icon : null}
          </div>
          <div className="sidebar-link-spacer" />
          <div className="sidebar-link-name align-middle">
            <span className="sidebar-link-name-outer">{name}</span>
          </div>

          {isNew ? (
            <Badge bg="secondary" className="datasets-new-badge">
              New
            </Badge>
          ) : null}
        </NavLink>
      )}
    </div>
  );
};

const Sidebar = (props: any) => {
  const sidebar = props.sidebar;
  const userAccount = useContext(AuthorizeContext);
  const location = useLocation();
  const subscription = useAppSelector((state) => state.subscription);
  const routeFlags = useFeatureFlags().getFlags([
    "odata_client",
    "impersonation",
  ]);

  const openRoutes = getRoutePaths(userAccount.role, subscription, routeFlags);

  const keepExpandedOrCollapsed = () => {
    if (props.sidebar.keepExpanded) {
      props.showSidebar();
    } else {
      props.hideSidebar();
    }
  };

  return (
    <nav
      className={classNames("sidebar", {
        toggled: !sidebar.isOpen,
        "sidebar-sticky": sidebar.isSticky,
      })}
    >
      <div className="sidebar-content">
        <PerfectScrollbar className="sidebar-container">
          <div className="sidebar-nav">
            {openRoutes.map((category, index) => {
              if (category.showSidebarItem) {
                return (
                  <div key={index}>
                    <SidebarItem
                      name={category.name}
                      to={category.path}
                      isOpen={sidebar.isOpen}
                      icon={category.icon}
                      location={location}
                      onClick={() => keepExpandedOrCollapsed()}
                      isNew={category.isNew}
                      testId={category.testId}
                    />
                  </div>
                );
              }
            })}
          </div>
        </PerfectScrollbar>
      </div>
    </nav>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    toggleSidebar: () => dispatch(toggleSidebar()),
    showSidebar: () => dispatch(showSidebar()),
    hideSidebar: () => dispatch(hideSidebar()),
  };
};

export default connect(
  (store: any) => ({
    sidebar: store.sidebar,
  }),
  mapDispatchToProps,
)(Sidebar);
