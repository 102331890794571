export const getWorkspaceNameFromQuery = (query: string) => {
  if (!query) {
    return "";
  }

  const newQuery = query.toLowerCase();
  const divider = "from ";

  // Break down query after the word from, convert to array based on dot(.)
  // Return first element and then remove [] if any.
  const workspaceName = newQuery
    .split(divider)
    .pop()
    ?.split(".")?.[0]
    ?.replace(/(^.*\[|\].*$)/g, "");

  return workspaceName ?? "";
};
