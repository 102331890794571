import BootstrapTable from "react-bootstrap-table-next";

interface IBackNavigationProps {
  callbackOnClick: () => void;
  icon: any;
  textValue: string;
}

const BackNavigation = (props: IBackNavigationProps) => {
  const columns = [
    {
      dataField: "name",
      text: "",
    },
    {
      dataField: "id",
      text: "",
      hidden: true,
    },
  ];

  const backNavigationRow = [
    {
      name: (
        <div
          className="table-name-text data-explorer-container data-explorer-table-element"
          onClick={() => props.callbackOnClick()}
        >
          <i className="fa fa-arrow-left align-middle me-2" />
          {props.icon}
          <span className="align-middle truncate">{props.textValue}</span>
        </div>
      ),
      id: 0,
    },
  ];

  return (
    <BootstrapTable
      bootstrap4
      id="back-navigation-data-explorer"
      classes="data-explorer-tab-list"
      bordered={false}
      wrapperClasses="table-borderless px-0"
      keyField="id"
      data={backNavigationRow}
      columns={columns}
      hover={true}
    />
  );
};

export default BackNavigation;
