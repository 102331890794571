import { IQueryResult } from "../../models";
import { IServiceError } from "../../models/IServiceError";
import { cdataFetch } from "../cdataFetch";

export async function getTables(connection: string, schema: string) {
  return cdataFetch<IQueryResult | IServiceError>({
    method: "GET",
    url: `/tables?catalogName=${encodeURIComponent(connection)}&schemaName=${encodeURIComponent(schema)}`,
  });
}
