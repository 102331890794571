import {
  useState,
  useEffect,
  useMemo,
  startTransition,
  useContext,
} from "react";

import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  ColumnDef,
  getExpandedRowModel,
  getFilteredRowModel,
  Column,
  Row,
} from "@tanstack/react-table";

import { Badge, Spinner, UncontrolledTooltip } from "reactstrap";

import { IndeterminateCheckbox } from "../../../../components/tables/IndeterminateCheckbox";
import { Schema } from "./Steps/SelectSchemaTable";
import classnames from "classnames";
import { ToastrError } from "../../../../services/toastrService";
import { useAPI } from "../../../../components/useAPI";
import { RequestType } from "../../../../components/withAPI";
import { useMemoryCache } from "../../../../components/useMemoryCache";
import {
  SelectSchemaWizardAsset,
  SelectSchemaWizardContext,
} from "./SelectSchemaWizardContext";
import { TextWithOverflowTooltip } from "../../../../components/text/TextWithOverflowTooltip";

interface ISchemaTableProps {
  data: any;
  setData: (newData: any) => void;
  selectedConnection: string;
  setSelectedTables: Function;
  cachedJobs?: string[];
  className: string;
  helperText?: string;
  changeAlertBanner?: JSX.Element | null; // Notification bar type element
  currentStep?: number;
  checkIfTablesHaveChanged?: (
    newTableSelection: SelectSchemaWizardAsset[],
  ) => void;
}

function SchemaTable(props: ISchemaTableProps) {
  const api = useAPI();
  const fetchTablesForSchemaCached = useMemoryCache(fetchTablesForSchema);

  const { assetList } = useContext(SelectSchemaWizardContext);

  const [expanded, setExpanded] = useState<{ [key: number]: boolean }>({});
  const [rowSelection, setRowSelection] = useState({});
  const [selectedTablesPerConnection, setSelectedTablesPerConnection] =
    useState<{ [key: string]: number }>({});
  const [inputFilterValue, setInputFilterValue] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  // Setup Table
  const columns = useMemo<ColumnDef<Schema>[]>(
    () => {
      async function handleRowChecked(row: Row<Schema>) {
        if (row.depth !== 0) {
          row.toggleSelected();
          return;
        }

        if (row.original.subRows!.length === 0 && !row.getIsSelected()) {
          loadTablesForRow(row);
          expandCollapseRow(row, true);
        }

        row.toggleSelected();
      }

      return [
        {
          header: "Name",
          footer: (props) => props.column.id,
          columns: [
            {
              accessorFn: (row) => row.schemaName,
              accessorKey: "schemaName",
              cell: ({ row, getValue }) => (
                <span className="d-flex flex-direction-row">
                  {row.depth === 0 ? (
                    <button className="expand-collapse-button">
                      {row.getIsExpanded() ? (
                        <i className="fa fa-chevron-down"></i>
                      ) : (
                        <i className="fa fa-chevron-right"></i>
                      )}
                    </button>
                  ) : null}
                  {row.depth !== 0 ? (
                    <span
                      className="checkbox-span"
                      id={`${row.original.parentName}-${row.original.schemaName}`.replace(
                        /\s+/g,
                        "",
                      )}
                    >
                      <IndeterminateCheckbox
                        {...{
                          checked: row.getIsSelected(),
                          disabled: row.original.isRowDisabled,
                          onChange: () => handleRowChecked(row),
                        }}
                      />
                    </span>
                  ) : null}
                  {row.original.isRowDisabled ? (
                    <UncontrolledTooltip
                      placement="top"
                      target={`${row.original.parentName}-${row.original.schemaName}`.replace(
                        /\s+/g,
                        "",
                      )}
                      trigger="hover"
                    >
                      You cannot create a job for a dataset that is already
                      associated with another job.
                    </UncontrolledTooltip>
                  ) : null}
                  <TextWithOverflowTooltip
                    className="schema-table-text-element"
                    fullText={getValue()}
                  />
                </span>
              ),
              footer: (props) => props.column.id,
            },
          ],
        },
      ];
    },
    // TODO: CLOUD-12788: Fix this dependency array
    [], // eslint-disable-line
  );

  const data = props.data;
  const table = useReactTable({
    data,
    columns,
    initialState: {
      rowSelection,
    },
    state: {
      expanded,
      rowSelection,
    },
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    debugTable: true,
    enableRowSelection: (row) => !row.original.isRowDisabled,
    onRowSelectionChange: setRowSelection,
    filterFromLeafRows: true,
    maxLeafRowFilterDepth: 1,
    enableSubRowSelection: true,
  });

  // Row Expansion Functions
  useEffect(() => {
    if (props.data.length === 1) {
      autoExpand();
    }
  }, []); // eslint-disable-line

  async function autoExpand() {
    const firstRowSchemaName = data[0].schemaName;
    const tables = await fetchTablesForSchemaCached.get(firstRowSchemaName);
    updateSchemaChildren(firstRowSchemaName, tables);
    setExpanded({ "0": true });
  }

  async function loadTablesForRow(row: Row<any>) {
    const tables = await fetchTablesForSchemaCached.get(
      row.original.schemaName,
    );
    if (tables.length > 0) {
      updateSchemaChildren(row.original.schemaName, tables);
    }
  }

  function updateSchemaChildren(schemaName: string, tables: any[]) {
    setIsProcessing(true);
    const schemaList = [...props.data];
    const selectedSchemaIndex = schemaList?.findIndex(
      (x) => x.schemaName === schemaName,
    );

    if (schemaList[selectedSchemaIndex]) {
      schemaList[selectedSchemaIndex].subRows = tables?.map((table: any) => {
        const fullTableName = `${props.selectedConnection}.${schemaName}.${table[2]}`;
        return {
          schemaName: table[2],
          parentName: schemaName,
          assetType: table[3],
          subRows: [],
          isRowDisabled: props.cachedJobs?.some(
            (existingCacheJobName) => existingCacheJobName === fullTableName,
          ),
        };
      });
    }

    props.setData(schemaList);
    setIsProcessing(false);
  }

  function expandCollapseRow(row: Row<Schema>, expand: boolean) {
    const expandRow = { [row.id]: expand };
    setExpanded({ ...expanded, ...expandRow });
  }

  async function handleRowClicked(row: Row<Schema>) {
    if (row.depth !== 0) {
      return;
    }

    if (row.original.subRows?.length === 0) {
      loadTablesForRow(row);
    }

    expandCollapseRow(row, true);
  }

  // Check if there were schemas selected previously, i.e. if the user navigated back to this step, and set it in the state if any are present.
  useEffect(() => {
    let newRowSelection = { ...rowSelection };

    if (assetList && assetList.length > 0) {
      props.data.forEach((schema: any, schemaIndex: number) => {
        schema.subRows.forEach((subRow: any, tableIndex: number) => {
          assetList.forEach((asset) => {
            if (
              asset[0] === subRow.schemaName &&
              asset[1] === subRow.parentName
            ) {
              const rowId = `${schemaIndex}.${tableIndex}`;
              newRowSelection = { ...newRowSelection, [rowId]: true };
            }
          });
        });
      });

      setRowSelection(newRowSelection);
    }
  }, [props.data]); // eslint-disable-line

  // On selection change
  useEffect(() => {
    const checkedTables: any = table.getSelectedRowModel().flatRows;
    const currCheckedTablesList: any = [];

    const updatedTablesCount: { [key: string]: number } = {};
    checkedTables.forEach((table: any) => {
      if (table.depth === 1) {
        currCheckedTablesList.push([
          table.original.schemaName,
          table.original.parentName,
          table.original.assetType,
        ]);

        const rowId = table.id.split(".").slice(0, -1).join(".");
        if (Object.prototype.hasOwnProperty.call(updatedTablesCount, rowId)) {
          updatedTablesCount[rowId] = updatedTablesCount[rowId] + 1;
        } else {
          updatedTablesCount[rowId] = 1;
        }
      }
    });

    props.setSelectedTables(currCheckedTablesList);

    // If we're in the AddAssetWizard -- i.e., not the onboarding wizard -- check for changes for the wizard flow.
    if (props.checkIfTablesHaveChanged) {
      props.checkIfTablesHaveChanged(currCheckedTablesList);
    }

    setSelectedTablesPerConnection(updatedTablesCount);
  }, [rowSelection]); // eslint-disable-line

  async function fetchTablesForSchema(schema: string) {
    setIsProcessing(true);

    const { status, payload } = await api.callAPI(
      RequestType.Get,
      `/tables?catalogName=${props.selectedConnection}&schemaName=${schema}`,
      "Error fetching tables:",
    );
    if (status === 200) {
      if (payload.error) {
        ToastrError("Error fetchig tables", payload.error.message);
        return;
      }
      const tables = payload.results[0].rows;
      setIsProcessing(false);
      return tables;
    }

    setIsProcessing(false);
  }

  const filterValue = table.getColumn("schemaName")!.getFilterValue();

  // Filtering Functions
  useEffect(() => {
    handleFilterAutoExpand();
  }, [filterValue]); // eslint-disable-line

  function handleFilterAutoExpand() {
    startTransition(() => {
      if (!filterValue) {
        if (props.data.length > 1) setExpanded({});
        return;
      }

      const expandRows: { [key: string]: boolean } = {};
      const currentFilteredRows = table.getFilteredRowModel().rows;
      currentFilteredRows.forEach((row) => {
        expandRows[row.id] = true;
      });
      setExpanded(expandRows);
    });
  }

  function handleFilterValueChange(
    event: React.ChangeEvent<HTMLInputElement>,
    column: Column<any, any>,
  ) {
    setInputFilterValue(event.target.value);
    column.setFilterValue(event.target.value ?? "");
  }

  return (
    <>
      <div hidden={!isProcessing}>
        <div className="loading-background" />
        <Spinner className="spinner-border loading-spinner" color="info" />
      </div>
      <table className="schema-table">
        <thead>
          <span className="select-table-desc mt-0">
            {props.helperText ??
              "Select the tables and views from the list below that you want added to Catalog."}
          </span>
          <span>{!isProcessing ? props.changeAlertBanner : null}</span>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <>
                  {header.column.getCanFilter() ? (
                    <div>
                      <Filter
                        inputFilterValue={inputFilterValue}
                        handleFilterValueChange={(e) =>
                          handleFilterValueChange(e, header.column)
                        }
                      />
                    </div>
                  ) : null}
                </>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className={props.className}>
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              className={classnames({ "schema-row": row.depth === 0 })}
            >
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  onClick={() => {
                    row.getIsExpanded()
                      ? expandCollapseRow(row, false)
                      : handleRowClicked(row);
                  }}
                >
                  <div className={classnames({ subRows: row.depth === 1 })}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    {selectedTablesPerConnection[row.id] ? (
                      <span>
                        <Badge className="tables-counter-badge">
                          {selectedTablesPerConnection[row.id]} Selected
                        </Badge>
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

function Filter({
  inputFilterValue,
  handleFilterValueChange,
}: {
  inputFilterValue: string;
  handleFilterValueChange: React.ChangeEventHandler<HTMLInputElement>;
}) {
  return (
    <>
      <i className="fa-regular fa-magnifying-glass align-middle me-2 search-glass"></i>
      <input
        type="text"
        value={inputFilterValue}
        onChange={(e) => handleFilterValueChange(e)}
        placeholder={"Search..."}
        className="border shadow rounded search-bar"
      />
    </>
  );
}

export default SchemaTable;
