import React from "react";
import { Button } from "reactstrap";
import AccessDeniedIcon from "../../assets/img/auth/AccessDenied.svg?react";
import { useNavigate } from "react-router-dom";

const NotAnAdmin = () => {
  const navigate = useNavigate();

  return (
    <div className="m-sm-4 text-center">
      <AccessDeniedIcon />
      <h1 className="my-4">Access Denied</h1>
      <p className="font-weight-light text-muted">
        Your account does not have the appropriate role or privileges to setup a
        connection. Please contact your Connect Cloud system administrator for
        further assistance.
      </p>
      <Button color="primary" onClick={() => navigate("/")}>
        Return to Connect Cloud{" "}
        <i className="fa fa-solid fa-sm fa-arrow-right ms-1" />
      </Button>
    </div>
  );
};

export default NotAnAdmin;
