import { Row, flexRender } from "@tanstack/react-table";
import { useDrag, useDrop } from "react-dnd";
import { Column } from "../../pages/apiConnector/SetTable/SetTable";

interface IDraggableRow<T> {
  row: Row<T>;
  reorderRow: (draggedRowIndex: number, targetRowIndex: number) => void;
  rowIndex: number;
  data: any;
  deleteRow: (rowIndex: number) => void;
  isFlyout?: boolean;
  disableDrag?: boolean;
}

function DraggableRow<T>(props: IDraggableRow<T>) {
  const [, dropRef] = useDrop({
    accept: "row",
    drop: (draggedRow: Row<Column>) =>
      props.reorderRow(draggedRow.index, props.row.index),
  });

  // eslint-disable-next-line
  const [isDragging, dragRef, previewRef] = useDrag({
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: () => props.row,
    type: "row",
  });

  return (
    <tr ref={previewRef} data-testid={`row-${props.row.id}`}>
      {props.row.getVisibleCells().map((cell, index) => (
        <td
          data-testid={`column-${index}`}
          className={
            "column-table-cell" +
            (cell.column.id === "dataType" ? " data-type-column" : "")
          }
          key={cell.id}
        >
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
      ))}
      {props.data.length > 0 ? (
        <td ref={dropRef} className="column-row">
          <span
            data-testid="trash-button"
            className="delete-btn"
            onClick={() => {
              props.deleteRow(props.rowIndex);
            }}
          >
            <i className="fa fa-trash-alt" />
          </span>
          {!props.isFlyout && !props.disableDrag ? (
            <span
              data-testid="drag-and-drop-button"
              className="dnd-btn"
              ref={dragRef}
            >
              <i className="fa fa-arrows-alt" />
            </span>
          ) : (
            ""
          )}
        </td>
      ) : (
        ""
      )}
    </tr>
  );
}

export default DraggableRow;
