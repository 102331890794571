import { IQueryBuilderModel } from "../../../../models/IQueryBuilderModel";

export function generateUniqueTableAlias(params: {
  queryData: IQueryBuilderModel;
  tableName: string;
}): string {
  const { queryData, tableName } = params;
  const uniqueTableAliases = new Set<string>([
    queryData.from.tableAlias,
    ...queryData.joins.map((j) => j.right.table.tableAlias),
  ]);

  // Use the table name as the alias unless we already have a table with the same name.
  let tableAlias = tableName;
  let counter = 2;

  // Keep incrementing the counter until we have a unique alias.
  while (uniqueTableAliases.has(tableAlias)) {
    tableAlias = `${tableName}_${counter}`;
    ++counter;
  }

  return tableAlias;
}
