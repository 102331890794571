import { useEffect, useState } from "react";
import { Container } from "reactstrap";
import { useAPI } from "../../../components/useAPI";
import Loader from "../../../components/Loader";
import TabWrapper from "../../../components/TabWrapper";
import OEMAccountCard from "./components/OEMAccountCard";
import OEMUserCard from "./components/OEMUserCard";
import { IAccount } from "../../../models";
import { IOEMSubAccountList } from "../../../models/OEM/IOEMSubAccountList";
import {
  getOEMSubAccounts,
  getSubAccountUserId,
} from "./OEMLandingPageAPICalls";
import {
  setAdminUserEmail,
  setImpersonatingUserId,
} from "../../../services/userImpersonation";
import { useAppSelector } from "../../../redux/hooks";

const OEMLandingPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [subAccounts, setSubAccounts] = useState<IOEMSubAccountList>();
  const api = useAPI();
  const user = useAppSelector((state) => state.user);

  useEffect(() => {
    const getOEMInformationOnLoad = async () => {
      const accounts = await getOEMSubAccounts(api.callAPI);
      if (accounts) {
        setSubAccounts(accounts);
      }

      setIsLoading(false);
    };
    getOEMInformationOnLoad();
  }, []); // eslint-disable-line

  async function openSubAccount(account: IAccount) {
    const subAccountUserId = await getSubAccountUserId(
      api.callAPI,
      account.id!,
    );
    setImpersonatingUserId(subAccountUserId);
    setAdminUserEmail(user.email ?? "");
    window.location.href = "/";
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container fluid className={"p-0 pages-oem-overview"}>
      <TabWrapper
        tabs={[
          {
            tabName: "Accounts",
            tabEnum: "1",
            tabComponent: (
              <OEMAccountCard
                oemAccounts={subAccounts?.accounts}
                openSubAccount={openSubAccount}
              />
            ),
          },
          {
            tabName: "Users",
            tabEnum: "2",
            tabComponent: <OEMUserCard />,
          },
        ]}
      />
    </Container>
  );
};

export default OEMLandingPage;
