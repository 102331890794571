import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

import { useState } from "react";
import { DateRangePicker, Range } from "react-date-range";
import { Col, Row } from "react-bootstrap";
import { ButtonType, CDataButton } from "../buttons/CDataButton";
import { ClickAwayListener, Divider, Popper } from "@mui/material";
import { produce } from "immer";
import { IHourMinuteSecond } from "./types/IHourMinuteSecond";
import { TimeSelector } from "./TimeSelector";

interface ICDataLogsDateRangerPicker {
  anchorEl: HTMLElement | null;
  dateRange: Range;
  setDateRange: (dateRange: Range) => void;
}

export const CDataLogsDateRangePicker = (props: ICDataLogsDateRangerPicker) => {
  const { anchorEl, dateRange, setDateRange } = props;

  const [open, setOpen] = useState(true);

  const startTime: IHourMinuteSecond = {
    hour: dateRange.startDate?.getHours() ?? 0,
    minute: dateRange.startDate?.getMinutes() ?? 0,
    second: dateRange.startDate?.getSeconds() ?? 0,
  };

  const endTime: IHourMinuteSecond = {
    hour:
      dateRange.endDate == null || isNaN(dateRange.endDate.getTime())
        ? 23
        : dateRange.endDate.getHours(),
    minute:
      dateRange.endDate == null || isNaN(dateRange.endDate.getTime())
        ? 59
        : dateRange.endDate.getMinutes(),
    second:
      dateRange.endDate == null || isNaN(dateRange.endDate.getTime())
        ? 59
        : dateRange.endDate.getSeconds(),
  };

  const today = new Date();

  const handleClear = () => {
    setDateRange({
      startDate: undefined,
      endDate: new Date(""), // This silly-looking workaround causes no selection to be displayed
      key: "selection",
    });
    setOpen(false);
  };

  const handleTimeChange = (
    type: "start" | "end",
    field: "hour" | "minute" | "second",
    value: number,
  ) => {
    // Update the time value for the date
    const newDateRange = produce(dateRange, (draft) => {
      if (!draft || !draft.startDate || !draft.endDate) {
        return;
      }

      const dateToUpdate =
        type === "start" ? new Date(draft.startDate) : new Date(draft.endDate);

      if (field === "hour") {
        dateToUpdate.setHours(value);
      } else if (field === "minute") {
        dateToUpdate.setMinutes(value);
      } else if (field === "second") {
        dateToUpdate.setSeconds(value);
      }

      type === "start"
        ? (draft.startDate = dateToUpdate)
        : (draft.endDate = dateToUpdate);
    });
    if (newDateRange) {
      setDateRange(newDateRange);
    }
  };

  const handleDateChange = (dateRange: Range) => {
    if (dateRange.endDate) {
      dateRange.endDate.setHours(23);
      dateRange.endDate.setMinutes(59);
      dateRange.endDate.setSeconds(59);
    }

    setDateRange(dateRange);
  };

  const hours = Array.from({ length: 24 }, (_, index) => index);
  const minutes = Array.from({ length: 60 }, (_, index) => index);
  const seconds = minutes;

  return (
    <div>
      <ClickAwayListener
        mouseEvent="onMouseUp" // This prevents the popper from closing when opening a time selector
        onClickAway={() => setOpen(false)}
      >
        <Popper
          anchorEl={anchorEl}
          className="component-CDataDatePicker-popper component-CDataDateRangePicker-popper"
          open={open}
          placement="bottom-start"
        >
          <div className="date-picker-contents mt-1">
            <Row>
              <Col>
                <DateRangePicker
                  calendarFocus="backwards"
                  direction="horizontal"
                  dragSelectionEnabled={false}
                  initialFocusedRange={[0, 1]}
                  inputRanges={[]} // Gets rid of the "X days before/after today" options
                  maxDate={today} // Sets the max date to the current date since logs can't exist from the future
                  monthDisplayFormat="MMMM yyyy"
                  months={2}
                  moveRangeOnFirstSelection={false}
                  onChange={(item) => handleDateChange(item.selection)}
                  preventSnapRefocus={true}
                  ranges={[dateRange!]}
                  showDateDisplay={false}
                  showMonthAndYearPickers={false}
                  showPreview={true}
                  staticRanges={[]}
                  weekdayDisplayFormat="EEEEEE" // Two letter abbreviations
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col className="time-selector-column">
                <TimeSelector
                  options={hours}
                  onChange={(value) => handleTimeChange("start", "hour", value)}
                  value={startTime.hour}
                />
                {" : "}
                <TimeSelector
                  options={minutes}
                  onChange={(value) =>
                    handleTimeChange("start", "minute", value)
                  }
                  value={startTime.minute}
                />
                {" : "}
                <TimeSelector
                  options={seconds}
                  onChange={(value) =>
                    handleTimeChange("start", "second", value)
                  }
                  value={startTime.second}
                />
              </Col>
              <Col className="time-selector-column">
                <TimeSelector
                  options={hours}
                  onChange={(value) => handleTimeChange("end", "hour", value)}
                  value={endTime.hour}
                />
                {" : "}
                <TimeSelector
                  options={minutes}
                  onChange={(value) => handleTimeChange("end", "minute", value)}
                  value={endTime.minute}
                />
                {" : "}
                <TimeSelector
                  options={seconds}
                  onChange={(value) => handleTimeChange("end", "second", value)}
                  value={endTime.second}
                />
              </Col>
            </Row>
            <Divider
              aria-hidden="true"
              sx={{ borderBottom: "2px solid #DAE0E6" }}
            />
            <Row>
              <Col className="d-flex justify-content-end m-3 gap-2">
                <CDataButton
                  buttonType={ButtonType.Borderless}
                  onClick={handleClear}
                >
                  Clear
                </CDataButton>
                <CDataButton
                  buttonType={ButtonType.Primary}
                  onClick={() => setOpen(false)}
                >
                  Done
                </CDataButton>
              </Col>
            </Row>
          </div>
        </Popper>
      </ClickAwayListener>
    </div>
  );
};
