import filterFactory, { customFilter } from "react-bootstrap-table2-filter";
import { CDataModalV2 } from "../../components/modal/CDataModalV2";
import CustomTextFilter from "../../components/CustomTextFilter";
import { Col, Row } from "reactstrap";
import { NotificationBar } from "../../components/notification/NotificationBar";
import { getSettingsPageSubscriptionTab } from "../../utility/SubscriptionProvider";
import { useNavigate } from "react-router-dom";
import { compareStrings } from "../../utility/CompareStrings";
import {
  getBetaIcon,
  getDriverIcon,
} from "../../components/drivers/DriverIconFactory";
import classNames from "classnames";
import BootstrapTable, { SelectRowProps } from "react-bootstrap-table-next";
import { IConnectionItem } from "./Connections";
import { useIsConnectForSpreadsheets } from "../../hooks/useIsConnectForSpreadsheets";

interface IAddConnectionModal {
  connections: IConnectionItem[];
  connectionsPerSource: number;
  drivers: any[];
  isAtDataSourceLimit: boolean;
  showConnectionModal: boolean;
  setShowConnectionModal: (show: boolean) => void;
}

export const AddConnectionModal = (props: IAddConnectionModal) => {
  const {
    connections,
    connectionsPerSource,
    drivers,
    isAtDataSourceLimit,
    showConnectionModal,
    setShowConnectionModal,
  } = props;
  const navigate = useNavigate();
  const isSpreadsheetUser = useIsConnectForSpreadsheets();

  const driverOptionColumns = [
    {
      dataField: "name",
      text: "",
      sort: false,
      hidden: true,
    },
    {
      dataField: "icon",
      text: "",
      sort: false,
    },
    {
      dataField: "niceName",
      text: "Driver Name",
      sort: false,
      filter: customFilter({}),
      filterRenderer: (onFilter: (value: string) => void, column: any) =>
        isAtDataSourceLimit ? (
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            placeholder="Search by name..."
          />
        ) : (
          <></>
        ),
    },
    {
      dataField: "isBeta",
      text: "",
      sort: false,
    },
  ];

  function isDriverConnectionLimitReached(driverName: string) {
    // This only applies to spreadsheet users with limited connections per source
    if (!isSpreadsheetUser || connectionsPerSource === -1) return false;

    const driverConnections = connections.filter(
      (connection) => connection.driver === driverName,
    );
    return driverConnections.length >= connectionsPerSource;
  }

  let driverOptions: any[] = [];
  const connectionDrivers = connections.map((connection) => connection.driver!);

  if (isAtDataSourceLimit) {
    driverOptions = drivers!
      .filter((driver) => connectionDrivers.includes(driver.driver!))
      .map((driver, index) => {
        return {
          name: driver.driver,
          icon: getDriverIcon(driver.driver!, "driver-icon"),
          niceName: driver.niceName,
          id: index,
          isBeta: getBetaIcon(
            driver.beta || compareStrings(driver.driver, "APIConnector"),
            "badge-beta-add-connection",
          ),
          disabled: isDriverConnectionLimitReached(driver.driver!),
        };
      });
  }

  const rowClasses = (row: any) =>
    classNames("addConnectionRow-" + row.name, {
      "table-disabled": row.disabled,
    });

  const selectRow: SelectRowProps<any> = {
    mode: "radio",
    clickToSelect: true,
    bgColor: "#dee2e6",
    onSelect: (row) => {
      if (row.disabled) return false;
      navigate("/connections/edit", {
        state: {
          driverType: row.name,
          driverNiceName: row.niceName,
          connections: connections,
        },
      });
      setShowConnectionModal(false);
    },
  };

  const dataSourceLimitReachedBanner = (
    <Row className="mb-4" data-testid="data-source-limit-reached-banner">
      <Col>
        <NotificationBar
          message={
            isSpreadsheetUser
              ? `You have reached your data source limit.
                     You can select new sources by removing the connections attached to your current ones or by upgrading your plan.`
              : `You have reached your data source limit. You can select new data
                     sources by removing the connections attached to your current
                     ones or by upgrading your subscription. You can still create new
                     connections using one of your existing data sources below.`
          }
          barColor={"notification-bar-pale-orange"}
          linkText={"Upgrade"}
          linkClickOverride={() => {
            navigate(
              "/settings?defaultTab=" + getSettingsPageSubscriptionTab(),
            );
            setShowConnectionModal(false);
          }}
        />
      </Col>
    </Row>
  );

  if (!showConnectionModal) return null;

  return (
    <CDataModalV2
      title="Add Connection"
      close={() => setShowConnectionModal(false)}
      displayed={showConnectionModal}
      displayToggleCloseButton={true}
    >
      <div className="driver-options">
        <p>Select a data source from the list below to add a new connection.</p>
        {dataSourceLimitReachedBanner}
        <BootstrapTable
          bootstrap4
          bordered={false}
          keyField="id"
          data={driverOptions}
          columns={driverOptionColumns}
          selectRow={selectRow}
          filter={filterFactory()}
          rowClasses={rowClasses}
        />
      </div>
    </CDataModalV2>
  );
};
