import { useEffect, useState } from "react";
import { BreadcrumbType } from "../../models/Datasets/BreadcrumbType";
import { IBreadcrumbInfoList } from "../../models/Datasets/IBreadcrumbInfoList";
import Breadcrumbs from "./BreadCrumbs";
import { useMutation } from "@tanstack/react-query";
import { getFolderBreadcrumbs } from "../../api/Breadcrumbs/getFolderBreadcrumbs";
import { getAssetBreadcrumbs } from "../../api/Breadcrumbs/getAssetBreadcrumbs";
import { getWorkspace } from "../../api/dataSets/getWorkspace";

export interface BreadcrumbItem {
  display: string;
  navigation: string;
}

interface DatasetBreadcrumbProps {
  workspaceId?: string;
  folderId?: string;
  dataAssetId?: string;
  folderName?: string;
  page?: BreadcrumbType;
}

export function DatasetsBreadcrumbs(props: DatasetBreadcrumbProps) {
  const { page, workspaceId, folderId, dataAssetId } = props;

  const [breadCrumbs, setBreadcrumbs] = useState<BreadcrumbItem[]>([
    { display: "Virtual Datasets", navigation: "/datasets" },
  ]);

  const {
    mutate: fetchFolderAssetBreadcrumbs,
    isPending: isFolderAssetsLoading,
  } = useMutation({
    mutationKey: [`/workspaces/${workspaceId}/folders/${folderId}/breadcrumbs`],
    mutationFn: () => getFolderBreadcrumbs(workspaceId!, folderId!),
    meta: {
      errorMessage: "Error fetching folder asset details.",
    },
    onSuccess: (data) => {
      convertBreadcrumbList(data);
    },
  });

  const { mutate: fetchDataAssetBreadcrumbs, isPending: isDataAssetsLoading } =
    useMutation({
      mutationKey: [
        `/workspaces/${workspaceId}/assets/${dataAssetId}/breadcrumbs`,
      ],
      mutationFn: () => getAssetBreadcrumbs(workspaceId!, dataAssetId!),
      meta: {
        errorMessage: "Failed to fetch asset details.",
      },
      onSuccess: (data) => {
        convertBreadcrumbList(data);
      },
    });

  const { mutate: fetchWorkspace, isPending: isWorkspaceLoading } = useMutation(
    {
      mutationKey: [`/workspaces/${workspaceId}`],
      mutationFn: () => getWorkspace(workspaceId!),
      meta: {
        errorMessage: "Error fetching workspace.",
      },
      onSuccess: (data) => {
        setBreadcrumbs([
          { display: "Virtual Datasets", navigation: "/datasets" },
          { display: data.name!, navigation: "" },
        ]);
      },
    },
  );

  useEffect(() => {
    function getBreadcrumbs() {
      if (page === undefined || page === null) {
        return;
      } else if (page === BreadcrumbType.Workspace) {
        fetchWorkspace();
      } else if (page === BreadcrumbType.Folder) {
        fetchFolderAssetBreadcrumbs();
      } else {
        fetchDataAssetBreadcrumbs();
      }
    }

    getBreadcrumbs();
  }, [props.folderName]); // eslint-disable-line

  function convertBreadcrumbList(breadcrumbList: IBreadcrumbInfoList) {
    const startingList = [
      { display: "Virtual Datasets", navigation: "/datasets" },
    ] as BreadcrumbItem[];
    const baseWorkspace = breadcrumbList.breadcrumbs.filter(
      (value) => value.type === BreadcrumbType.Workspace,
    )[0];
    if (baseWorkspace) {
      startingList.push({
        display: baseWorkspace.name,
        navigation: `/datasets/workspace/${baseWorkspace.id}`,
      } as BreadcrumbItem);
    }

    const folder = breadcrumbList.breadcrumbs.filter(
      (value) => value.type === BreadcrumbType.Folder,
    )[0];
    if (folder) {
      startingList.push({
        display: folder.name,
        navigation: `/datasets/workspace/${workspaceId}/folder/${folder.id}`,
      } as BreadcrumbItem);
    }

    const asset = breadcrumbList.breadcrumbs.filter(
      (value) => value.type === BreadcrumbType.Data,
    )[0];
    if (asset) {
      startingList.push({
        display: asset.name,
        navigation: `/datasets/workspace/${workspaceId}/asset/${dataAssetId}`,
      } as BreadcrumbItem);
    }
    setBreadcrumbs(startingList);
  }

  if (isWorkspaceLoading || isFolderAssetsLoading || isDataAssetsLoading)
    return <></>;

  return <Breadcrumbs items={breadCrumbs} />;
}
