import { RequestType } from "../../../../components/withAPI";
import { IConnectionContext } from "../ConnectionContext";
import { IModalFunctionFromModal } from "../../../../components/CDataModal";
import { IGetOAuthTokenArgs } from "src/bffmodels/Connection/IGetOAuthTokenArgs";
import { ConnectionType } from "src/models";

export function getDriverAndProps(
  connectionContext: IConnectionContext,
  userId: string,
) {
  const form = new FormData(
    document.getElementById("connectionProps") as HTMLFormElement,
  );

  const data: Partial<IGetOAuthTokenArgs> = {
    driver: connectionContext.driverType,
    props: {},
    connectionId: connectionContext.connectionId
      ? connectionContext.connectionId
      : undefined,
    connectionType:
      connectionContext?.connectionInfo?.selectedConnectionType ??
      ConnectionType.Shared,
    userId,
  };

  for (const pair of form) {
    if (
      pair[0].match(
        /^connectionName|0:SELECT|1:INSERT|2:UPDATE|3:DELETE|4:EXECUTE$/,
      )
    )
      continue;
    data.props![pair[0] as keyof IGetOAuthTokenArgs] = pair[1] as string;
  }
  return data as IGetOAuthTokenArgs;
}

export async function doAppOAuth(
  connectionContext: IConnectionContext,
  modalContext: IModalFunctionFromModal,
  userId: string,
  api: any,
) {
  const data = getDriverAndProps(connectionContext, userId);

  const { status, payload, error } = await api.callAPI(
    RequestType.Post,
    `/oauth/${data.driver}/token`,
    "",
    data,
  );

  if (status === 200) {
    connectionContext.setOauthProps(payload);
    return payload;
  } else {
    if (status === 401) {
      modalContext.showTimeout();
    } else {
      // Some drivers support both OAUTH_APP and other non-OAuth auth schemes, and more notably might only support OAuth for (e.g.)
      // a REST schema but a non-OAuth scheme for (e.g.) a SOAP schema. In those cases, we will rightly get an error here that the
      // driver "does not support OAuth"...if that happens, just eat the error and return true as if we were successful so that the
      // save and test process can continue on unimpeded.
      if (error.message && error.message.includes("does not support OAuth"))
        return {}; // Return empty object so that the caller doesn't abort out.

      // Otherwise, show the error modal as normal.
      modalContext.showError(
        "Failed to get OAuth settings due to the following error:",
        error,
      );
      connectionContext.setProcessingRequest(false);
    }
    return null;
  }
}

export function setOAuthProps(
  connectionContext: IConnectionContext,
  oAuthProps: any,
  unsavedChanges?: boolean,
) {
  connectionContext.setOauthProps(oAuthProps);

  // We now have an OAuth token, update the OAuth button to say Reconnect.
  connectionContext.setConnectionInfo({
    ...connectionContext.connectionInfo,
    isOauthTokenPresent: oAuthProps != null,
  });

  if (unsavedChanges) {
    connectionContext.setUnsavedChanges(unsavedChanges);
  }
}
