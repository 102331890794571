import React from "react";
import { Col, Row, UncontrolledTooltip } from "reactstrap";

interface IGridContainer {
  workspacesMappedToTilesGrid: any[];
}

function GridContainer(props: IGridContainer) {
  return (
    <>
      <Row className="mt-3">
        {props.workspacesMappedToTilesGrid.map((card, index) => (
          <>
            {card.showToolTip ? (
              <UncontrolledTooltip target={`id-${index}`}>
                Contact your system administrator to request access to this
                workspace.
              </UncontrolledTooltip>
            ) : null}

            <Col
              className="card-margin col-2"
              key={card.name}
              id={`id-${index}`}
            >
              {card.tile}
            </Col>
          </>
        ))}
      </Row>
    </>
  );
}

export default GridContainer;
