import { cdataFetch } from "../../../api/cdataFetch";
import { ICreateScheduledQuery } from "../../../models/ScheduledQueries/ICreateScheduledQuery";
import { IScheduledQuery } from "../../../models/ScheduledQueries/IScheduledQuery";

export async function createScheduledQuery(params: ICreateScheduledQuery) {
  return cdataFetch<IScheduledQuery, ICreateScheduledQuery>({
    method: "POST",
    url: "/scheduledquery",
    body: params,
  });
}
