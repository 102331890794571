import React from "react";
import { Col, Row } from "reactstrap";
import {
  randomFlyoutKey,
  setBaseDocsAddress,
} from "../utility/FlyoutDocsFactory";

interface IEmbeddedDocumentation {
  driver: string;
}

const EmbeddedDocumentation = (props: IEmbeddedDocumentation) => {
  const uniqueKey = randomFlyoutKey();
  const docUrl = `${setBaseDocsAddress() + props.driver + ".html"}?hideborders=true&hidepaddingtop=true&onboarding=true&`;

  return (
    <Col className="embedded-documentation">
      <Row className="top-heading-row">
        <Col className="top-heading-contents">
          <h3>Documentation</h3>
        </Col>
      </Row>
      <Row className="doc-container">
        <iframe
          key={uniqueKey}
          className="iframe-doc pe-0"
          src={`${docUrl}${uniqueKey}`}
          title="docs"
        />
      </Row>
    </Col>
  );
};

export default EmbeddedDocumentation;
