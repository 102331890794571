import { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  Container,
  ListGroup,
  ListGroupItem,
  Progress,
} from "reactstrap";

import Loader from "../../components/Loader";
import { getDriverIcon } from "../../components/drivers/DriverIconFactory";
import {
  IConnectionStats,
  IConnectionStatsReport,
  UserRole,
} from "../../models";
import { ColumnDef } from "@tanstack/react-table";
import { calculatePercentage } from "../../utility/CaclulatePercentage";
import ListTable from "../../components/tables/ListTable";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { getActiveConnectionStatistics } from "./components/OverviewApiCalls";
import { useAPI } from "../../components/useAPI";
import { useAppSelector } from "../../redux/hooks";
import { CDataTypography } from "src/components/text/CDataTypography";

interface IQueriesByConnectionProps {
  period: number;
}

export const QueriesByConnection = (props: IQueriesByConnectionProps) => {
  const api = useAPI();
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user);

  const [activeConnectionStatistics, setActiveConnectionStatistics] =
    useState<IConnectionStatsReport | null>(null);
  const [connectionsTableSwitch, setConnectionsTableSwitch] =
    useState<boolean>(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const statistics = await getActiveConnectionStatistics(
        api.callAPI,
        props.period,
      );
      setActiveConnectionStatistics(statistics);
      setIsLoading(false);
    };

    fetchData();
  }, [props.period]); // eslint-disable-line

  if (isLoading) {
    return <Loader />;
  }

  if (!activeConnectionStatistics) {
    return null;
  }

  const navigateToLogs = (row: IConnectionStats) => {
    navigate("/logs", {
      state: {
        timePeriod: activeConnectionStatistics.period,
        connection: row.connection,
      },
    });
  };

  const columnsByQuery: ColumnDef<IConnectionStats>[] = [
    {
      accessorKey: "connection",
      id: "name",
      enableSorting: true,
      header: "Connection",
      meta: {
        className: "connection-column",
      },
      cell: ({ row }) => {
        return (
          <>
            {getDriverIcon(row.original.driver!, "connection-icon-small me-2")}
            {row.original.connection}
          </>
        );
      },
    },
    {
      accessorKey: "totalQueries",
      id: "totalQueries",
      enableSorting: true,
      header: () => "Queries",
      meta: {
        className: "queries-column",
      },
      cell: ({ row }) => {
        return <>{row.original.totalQueries}</>;
      },
    },
    {
      accessorKey: "successfulQueries",
      id: "successfulQueries",
      enableSorting: true,
      sortingFn: (rowA, rowB) => {
        const successfulPercentA = calculatePercentage(
          rowA.original.successfulQueries,
          rowA.original.totalQueries,
          1,
        );
        const successfulPercentB = calculatePercentage(
          rowB.original.successfulQueries,
          rowB.original.totalQueries,
          1,
        );

        return successfulPercentA - successfulPercentB;
      },
      header: () => "Successful",
      meta: {
        className: "successful-queries-column",
      },
      cell: ({ row }) => {
        const percentSuccessful = calculatePercentage(
          row.original.successfulQueries,
          row.original.totalQueries,
          1,
        );

        return (
          <>
            <Progress color="primary" value={percentSuccessful} />
            <div className="percentage-success">{percentSuccessful}%</div>
          </>
        );
      },
    },
  ];

  const columnsByRow: ColumnDef<IConnectionStats>[] = [
    {
      accessorKey: "connection",
      id: "name",
      enableSorting: true,
      header: "Connection",
      meta: {
        className: "connection-column",
      },
      cell: ({ row }) => {
        return (
          <>
            {getDriverIcon(row.original.driver!, "connection-icon-small me-2")}
            {row.original.connection}
          </>
        );
      },
    },
    {
      accessorKey: "rows",
      id: "rows",
      enableSorting: true,
      header: () => "Rows",
      cell: ({ row }) => {
        return <>{row.original.rows}</>;
      },
    },
  ];

  const isOEM = user?.role === UserRole.ServiceUser;

  const isTableDisabled = activeConnectionStatistics.datasets.length === 0;

  return (
    <Container fluid className="ps-0 queries-by-connection">
      <Card
        className="by-connection-card flex-fill w-100"
        onClick={() => {
          window.DD_RUM?.addAction("Connection card clicked");
        }}
      >
        <CardHeader>
          <CardTitle className="mb-0 card-title-queries">
            <span className="by-connection-title">By Connection</span>
            <ListGroup horizontal className="connected-button-group-list mt-1">
              <ListGroupItem
                action
                type="button"
                size="sm"
                onClick={(e) => {
                  e.stopPropagation();
                  setConnectionsTableSwitch(true);
                  window.DD_RUM?.addAction(
                    "Connection card toggle tab clicked",
                    {
                      activeTab: "Queries",
                    },
                  );
                }}
                active={connectionsTableSwitch}
                className="text-center connected-button queries-button"
                data-testid="button-queries"
              >
                Queries
              </ListGroupItem>
              <ListGroupItem
                action
                type="button"
                size="sm"
                onClick={(e) => {
                  e.stopPropagation();
                  setConnectionsTableSwitch(false);
                  window.DD_RUM?.addAction(
                    "Connection card toggle tab clicked",
                    {
                      activeTab: "Rows",
                    },
                  );
                }}
                active={!connectionsTableSwitch}
                className="text-center connected-button"
                data-testid="button-rows"
              >
                Rows
              </ListGroupItem>
            </ListGroup>
          </CardTitle>
        </CardHeader>
        {isTableDisabled ? (
          <div className="table-disabled-message">
            <i className="fa fa-chart-line-up table-disabled-icon fa-7x mb-2" />
            <CDataTypography>
              No data available for the selected time period
            </CDataTypography>
          </div>
        ) : null}
        {
          <ListTable
            columns={connectionsTableSwitch ? columnsByQuery : columnsByRow}
            data={activeConnectionStatistics!.datasets!}
            className={classNames("by-connection-table", {
              "table-disabled": isTableDisabled,
            })}
            defaultSort={[{ id: "totalQueries", desc: true }]}
            emptyTableMessage={""}
            enableFiltering={false}
            enablePagination={false}
            enableCheckboxes={false}
            onRowClick={isOEM ? undefined : (row) => navigateToLogs(row)}
          />
        }
      </Card>
    </Container>
  );
};
