import React from "react";
import {
  Button,
  Col,
  Label,
  Input,
  Row,
  FormFeedback,
  FormText,
} from "reactstrap";
import TertiaryButton from "../../../components/buttons/TertiaryButton";
import { InfoIcon } from "../../../components/InfoIcon";
import { IAzureOpenAIQueryResult } from "../../../models/OpenAI/IAzureOpenAIQueryResult";
import { NotificationBar } from "../../../components/notification/NotificationBar";

export type IPromptInput = {
  aiResponse: IAzureOpenAIQueryResult | undefined;
  inputCanBeExecuted: boolean;
  onPromptChange: (prompt: string) => void;
  promptText: string;
  sendQueryGenerationRequest: () => void;
  promptDisabled: boolean;
  tokenCount: number;
  resetFields: () => void;
  resetDisabled: boolean;
  tokensOverLimit: boolean;
};

export const PromptInput = (props: IPromptInput) => {
  const infoIconTooltipText =
    "AI models understand and process text by breaking it down into tokens. The total amount" +
    "processed in a single API call is also limited by the total number of tokens in the call. The" +
    "metadata for the selected tables and the question are included in the total count of tokens.";

  return (
    <div
      className="components-Prompt-Input mt-3"
      data-testid="component-prompt-input"
    >
      <Label className="required" style={{ fontWeight: "bold" }}>
        SQL Prompt
      </Label>
      <Input
        data-testid="input-prompt"
        id="description"
        type="textarea"
        rows="4"
        value={props.promptText}
        disabled={props.promptDisabled}
        invalid={props.tokensOverLimit}
        placeholder="Write a query to list the names of the departments which employed more than 10 employees in the last 3 months."
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          props.onPromptChange(`${event.target.value}`)
        }
      />
      <FormText hidden={props.tokensOverLimit}>
        Prompts are designed exclusively for generating SQL statements. Monthly
        credits are deducted for each prompt submitted, even in the event of an
        error.
      </FormText>
      <FormFeedback type="invalid">
        Token limit exceeded. Try removing a table or shortening your prompt.
      </FormFeedback>
      <Row className={"mt-2 mb-2"}>
        <Col className="controls-row">
          <span className="generate-button me-2">
            <Button
              data-testid="button-generate"
              color={"primary"}
              onClick={props.sendQueryGenerationRequest}
              disabled={!props.inputCanBeExecuted || props.tokensOverLimit}
            >
              <i className="fa-regular fa-code align-middle no-pointer-event me-1" />{" "}
              Generate SQL
            </Button>
          </span>
          <span className="reset-button">
            <TertiaryButton
              data-testid="button-reset"
              onClick={props.resetFields}
              disabled={props.resetDisabled}
            >
              <i className="fa-solid fa-rotate-left align-middle me-1" /> Reset
            </TertiaryButton>
          </span>
          <span className="token-indicator mt-1" data-testid="token-counter">
            Tokens:
            <InfoIcon
              tooltipMessage={infoIconTooltipText}
              className="info-icon-root me-1"
            />
            <span className={props.tokensOverLimit ? "error-text" : ""}>
              {" "}
              {props.tokenCount}/2000
            </span>
          </span>
        </Col>
      </Row>
      <Row>
        <Col>
          {props.aiResponse?.error ? (
            <div
              className="warning-bar mt-2"
              data-testid="error-message-banner"
            >
              <NotificationBar
                barColor={"notification-bar-red"}
                message={props.aiResponse?.error ?? ""}
              />
            </div>
          ) : null}
        </Col>
      </Row>
    </div>
  );
};
