import React from "react";
import { Input, UncontrolledTooltip } from "reactstrap";
import classnames from "classnames";
import { jobsConstants } from "../../Jobs.constants";
import { useFormikContext } from "formik";
import { ICacheJobExtended } from "./ICacheJobExtended";

interface IRunFrequencyRowProps {
  timeCheckColumnOptions: string[];
  setUnsavedChanges: (unsavedChanges: boolean) => void;
  checkColumnSupport: boolean;
}

export const TimeCheckColumnDropdown = ({
  timeCheckColumnOptions,
  setUnsavedChanges,
  checkColumnSupport,
}: IRunFrequencyRowProps) => {
  const { values, setValues } = useFormikContext<ICacheJobExtended>();

  const dropdownOptions = timeCheckColumnOptions.map((option: string) => {
    return (
      <option key={option} value={option}>
        {option}
      </option>
    );
  });

  dropdownOptions.unshift(
    <option key="Select" value="">
      Select
    </option>,
  );

  return (
    <span data-testid="row-timecheck-dropdown">
      <label className="fw-bold mb-2">Time Check Column</label>
      <Input
        id="input-select-time-check-column"
        name="input-select-time-check-column"
        data-testid="input-select-time-check-column"
        type="select"
        value={values.timeCheckColumn}
        className={classnames("form-control select mb-0", {
          "red-focus":
            !values.isFullUpdate &&
            timeCheckColumnOptions.length > 0 &&
            values.timeCheckColumn === "",
        })}
        disabled={
          values.isFullUpdate ||
          !timeCheckColumnOptions ||
          timeCheckColumnOptions.length === 0 ||
          (!checkColumnSupport && timeCheckColumnOptions.length === 1)
        }
        onChange={(event: React.ChangeEvent) => {
          setValues({
            ...values,
            timeCheckColumn: (event.target as HTMLInputElement).value,
          });
          setUnsavedChanges(true);
        }}
      >
        {dropdownOptions}
      </Input>
      {values.isFullUpdate ||
      !timeCheckColumnOptions ||
      timeCheckColumnOptions.length === 0 ? (
        <UncontrolledTooltip
          placement="top"
          target="input-select-time-check-column"
          trigger="hover"
        >
          {jobsConstants.TIME_CHECK_NOT_REQUIRED}
        </UncontrolledTooltip>
      ) : null}
      {!values.isFullUpdate &&
      timeCheckColumnOptions.length > 0 &&
      values.timeCheckColumn === "" ? (
        <div
          className="red-error align-self-start mb-3"
          data-testid="validation-text-frequency"
        >
          Time check column is required.
        </div>
      ) : (
        <div
          className="helper-text mb-3"
          data-testid="helper-text-time-check-column"
        >
          In order to support iterative look ups, specify the last modified
          column.
        </div>
      )}
    </span>
  );
};
