import { Button, UncontrolledTooltip } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useIsConnectForSpreadsheets } from "../../hooks/useIsConnectForSpreadsheets";

export const ExploreDataButton = (props: { isDisabled: boolean }) => {
  const navigate = useNavigate();
  const isSpreadsheetUser = useIsConnectForSpreadsheets();

  return (
    <>
      {!isSpreadsheetUser && (
        <div id="explore-data">
          <Button
            outline
            data-testid="button-explore-data"
            color="primary"
            disabled={props.isDisabled}
            className="tertiary-button btn btn-secondary"
            onClick={() => navigate("/data-explorer")}
          >
            <i className="fa fa-regular fa-magnifying-glass explore-data-icon no-pointer-event" />
            Explore Data
          </Button>
          <UncontrolledTooltip placement="top" target="explore-data">
            Successfully authenticate your connection to access your data
            through the Data Explorer.
          </UncontrolledTooltip>
        </div>
      )}
    </>
  );
};
