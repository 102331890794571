import React, { useState } from "react";
import StepWizard from "react-step-wizard";

import { Modal, ModalBody, ModalHeader, ModalHeaderProps } from "reactstrap";

import { NoTransitions } from "./components/NoTransitions";
import StepWizardNav from "./components/StepWizardNav";
import { StepWizardContext } from "./components/StepWizardContext";

interface IStepWizardModalProps {
  children: (JSX.Element | null | undefined)[]; // Must be of type StepWizardStep
  className?: string;
  isDisplayed: boolean;
  showNavigation: boolean;
  title: string;
  modalSize?: string;
  toggleModal: ModalHeaderProps["toggle"];
}

export function StepWizardModal(props: IStepWizardModalProps) {
  const [completedSteps, setCompletedSteps] = useState<number[]>([]);
  const [modalSize, setModalSize] = useState<string>(props.modalSize ?? "md");
  // pendingCompletedSteps is used for temporary UI changes if the user changes a selection from a previous step in the modal
  const [pendingCompletedSteps, setPendingCompletedSteps] = useState<number[]>(
    [],
  );

  const children = props.children
    // Filter out things like null and booleans, these occur when conditionally rendering child components.
    .filter((c) => c != null && typeof c === "object");

  const stepNames = children.map((child: JSX.Element) => child.props.stepName);

  function setStepCompleted(stepNumber: number) {
    const updatedCompletedSteps = [...pendingCompletedSteps, stepNumber].sort();
    setCompletedSteps(updatedCompletedSteps);
    setPendingCompletedSteps(updatedCompletedSteps);
  }

  function resetPendingCompletedSteps() {
    setPendingCompletedSteps(completedSteps);
  }

  function setPendingCompletedStepsToCurrentStep(stepNumber: number) {
    const updatedCompletedSteps = completedSteps.slice(0, stepNumber - 1);
    setPendingCompletedSteps(updatedCompletedSteps);
  }

  function toggleModalAndClearCompletedSteps(event: any) {
    setCompletedSteps([]);
    setPendingCompletedSteps([]);
    props.toggleModal?.(event);
  }

  return (
    <StepWizardContext.Provider
      value={{
        completedSteps: completedSteps,
        modalSize: modalSize,
        setPendingCompletedStepsToCurrentStep:
          setPendingCompletedStepsToCurrentStep,
        setModalSize: setModalSize,
        setStepCompleted: setStepCompleted,
        resetPendingCompletedSteps: resetPendingCompletedSteps,
        toggleModal: toggleModalAndClearCompletedSteps,
      }}
    >
      <Modal
        data-testid="step-wizard-modal"
        centered
        className={`components-wizard-StepWizardModal ${props.className}`}
        isOpen={props.isDisplayed}
        toggle={toggleModalAndClearCompletedSteps}
        backdrop="static"
        size={modalSize}
        contentClassName="step-wizard"
      >
        <ModalHeader
          data-testid="modal-header"
          toggle={toggleModalAndClearCompletedSteps}
        >
          {props.title}
        </ModalHeader>
        <ModalBody data-testid="modal-body" className="px-3 py-0">
          <StepWizard
            isLazyMount={true}
            nav={
              props.showNavigation ? (
                <StepWizardNav
                  pendingCompletedSteps={pendingCompletedSteps}
                  stepNames={stepNames}
                />
              ) : undefined
            }
            transitions={NoTransitions}
          >
            {children}
          </StepWizard>
        </ModalBody>
      </Modal>
    </StepWizardContext.Provider>
  );
}
