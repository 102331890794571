import { Suspense } from "react";
import { lazyRetry } from "../../../../../utility/lazyRetry";
import { ISelectInitialConnectionProps } from "./SelectInitialConnection";
import Loader from "../../../../../components/Loader";

const SelectInitialConnection = lazyRetry(() =>
  import("./SelectInitialConnection").then((module) => ({
    // Convert the export into the format of a default export, doing this so find all references works.
    default: module.SelectInitialConnection,
  })),
);

/** A wrapper component that lazy loads the javascript for this step. */
export function SelectInitialConnectionStep(
  props: ISelectInitialConnectionProps,
) {
  return (
    <Suspense fallback={<Loader />}>
      <SelectInitialConnection {...props} />
    </Suspense>
  );
}
