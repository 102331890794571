import { useState } from "react";

import {
  Card,
  CardBody,
  Container,
  Label,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import { Ops, UserRole } from "../../../models";
import classNames from "classnames";

import { PermissionFlag } from "../../../utility/PermissionFlag";
import { useUserInfo } from "../../../hooks/useUserInfo";
import { getDriverIcon } from "../../../components/drivers/DriverIconFactory";
import { compareStrings } from "../../../utility/CompareStrings";
import { getIsSupportImpersonationActive } from "../../../services/userImpersonation";
import { CDataCheckbox } from "../../../components/CDataCheckbox";

export interface IFullPermission {
  id: string;
  type: string;
  opsAllowed: Ops;
  rowName: string;
  driver: string;
  row?: number;
}

export enum PermissionNames {
  SELECT = "0:SELECT",
  INSERT = "1:INSERT",
  UPDATE = "2:UPDATE",
  DELETE = "3:DELETE",
  EXECUTE = "4:EXECUTE",
}

interface IPermissionProps {
  permissions: IFullPermission[];
  updatePermissions: (permissions: IFullPermission[]) => void;
  rowColumnName: string;
  setUnsavedChanges?: () => void;
}

const PermissionsCard = (props: IPermissionProps) => {
  const userInfo = useUserInfo();
  const [sortedEntityPermissionBody, setSortedEntityPermissionBody] = useState<
    IFullPermission[]
  >([]);
  const [sortOrder, setSortOrder] = useState("desc");
  const [sortOrderType, setSortOrderType] = useState("desc");
  const [sortOrderRowName, setSortOrderRowName] = useState("desc");
  const isUsersPage = window.location.href.includes("users");
  const isApiConnectionPage = window.location.href.includes("apiconnector");
  const isSupportImpersonationActive = getIsSupportImpersonationActive();

  if (!userInfo.IsInRole(UserRole.Admin)) {
    return null;
  }

  if (props.permissions === null) {
    return null;
  }

  const toggleSort = (property: keyof IFullPermission) => {
    const sortedData = [...props.permissions];
    if (compareStrings(sortOrder, "asc")) {
      sortedData.sort((a, b) => {
        const comparison = a[property!]!.toString().localeCompare(
          b[property!]!.toString(),
        );
        if (comparison === 0 && compareStrings(property, "type")) {
          return a["rowName"].toString().localeCompare(b["rowName"].toString());
        } else {
          return comparison;
        }
      });
      setSortOrder("desc");
      compareStrings(property, "rowName")
        ? setSortOrderType("desc")
        : setSortOrderRowName("desc");
    } else {
      sortedData.sort((a, b) => {
        const comparison = a[property!]!.toString().localeCompare(
          b[property!]!.toString(),
        );

        if (comparison === 0 && compareStrings(property, "type")) {
          return a["rowName"].toString().localeCompare(b["rowName"].toString());
        } else {
          return comparison * -1;
        }
      });
      setSortOrder("asc");
      compareStrings(property, "rowName")
        ? setSortOrderType("asc")
        : setSortOrderRowName("asc");
    }
    setSortedEntityPermissionBody(sortedData);
  };

  const getRowNameSortIconClassName = () => {
    return compareStrings(sortOrderRowName, "asc")
      ? "fa fa-sort-alt rotate-asc"
      : "fa fa-sort-alt rotate-desc";
  };

  const getTypeSortIconClassName = () => {
    return compareStrings(sortOrderType, "asc")
      ? "fa fa-sort-alt rotate-asc"
      : "fa fa-sort-alt rotate-desc";
  };

  function isHeaderCheckboxSelected(column: string) {
    const { permissions } = props;
    const operation = PermissionFlag[column as keyof typeof PermissionFlag];
    const unselectedRows = permissions.filter((row: IFullPermission) => {
      return (operation & row.opsAllowed) === 0;
    });
    return unselectedRows.length === 0;
  }

  function isHeaderCheckboxIndeterminate(column: string) {
    const { permissions } = props;
    const operation = PermissionFlag[column as keyof typeof PermissionFlag];
    const unselectedRows = permissions.filter((row: IFullPermission) => {
      return (operation & row.opsAllowed) === 0;
    });

    return (
      unselectedRows?.length > 0 && unselectedRows?.length < permissions?.length
    );
  }

  function handlePermissionChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { permissions } = props;
    const permission = event.target.id.split(":");
    const rowToUpdate = permissions
      .map((permission: IFullPermission) => permission.id)
      .indexOf(permission[0]);
    const operation =
      PermissionFlag[permission[1] as keyof typeof PermissionFlag];

    if (event.target.checked === true) {
      permissions[rowToUpdate].opsAllowed =
        permissions[rowToUpdate].opsAllowed | operation;
    } else if (event.target.checked === false) {
      permissions[rowToUpdate].opsAllowed =
        permissions[rowToUpdate].opsAllowed ^ operation;
    }

    props.setUnsavedChanges ? props.setUnsavedChanges() : null;
    props.updatePermissions([...permissions]);
  }

  function selectAllInColumn(event: React.ChangeEvent<HTMLInputElement>) {
    let { permissions } = props;
    const columnToUpdate = event.target.name.split(":");
    const operation =
      PermissionFlag[columnToUpdate[1] as keyof typeof PermissionFlag];
    const unselectedRows = permissions.filter((row: IFullPermission) => {
      return (operation & row.opsAllowed) === 0;
    });

    if (unselectedRows.length > 0) {
      permissions = permissions.map((row: IFullPermission) => {
        row.opsAllowed = row.opsAllowed | operation;
        return row;
      });
    } else {
      permissions = permissions.map((row: IFullPermission) => {
        row.opsAllowed = row.opsAllowed ^ operation;
        return row;
      });
    }

    props.setUnsavedChanges ? props.setUnsavedChanges() : null;
    props.updatePermissions(permissions);
  }

  const permissionBody =
    sortedEntityPermissionBody.length > 0
      ? sortedEntityPermissionBody
      : props.permissions;

  const entityPermissionBody = permissionBody.map(
    (permission: IFullPermission, index: number) => {
      const isRestDriver = compareStrings(permission.driver, "REST");
      return (
        <tr key={index}>
          <td>
            <span className="align-item">
              {compareStrings(props.rowColumnName, "Entity") &&
                (compareStrings(permission.type, "Connection") ? (
                  getDriverIcon(permission.driver, "connection-icon me-2")
                ) : (
                  <i className="fa fa-regular fa-folder-open align-folder-icon" />
                ))}
              {permission.rowName}
            </span>
          </td>
          <td className={`text-left ${isUsersPage ? "" : "d-none"}`}>
            {permission.type}
          </td>
          <td className="text-center">
            <CDataCheckbox
              id={permission.id + ":SELECT"}
              value={Ops.Select}
              className="py-0"
              checked={(PermissionFlag.SELECT & permission.opsAllowed) > 0}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handlePermissionChange(event)
              }
              disabled={isSupportImpersonationActive}
            />
          </td>
          <td className="text-center">
            <CDataCheckbox
              id={permission.id + ":INSERT"}
              value={Ops.Insert}
              className="py-0"
              checked={
                !isRestDriver &&
                (PermissionFlag.INSERT & permission.opsAllowed) > 0
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handlePermissionChange(event)
              }
              disabled={isRestDriver || isSupportImpersonationActive}
            />
          </td>
          <td className="text-center">
            <CDataCheckbox
              id={permission.id + ":UPDATE"}
              value={Ops.Update}
              className="py-0"
              checked={
                !isRestDriver &&
                (PermissionFlag.UPDATE & permission.opsAllowed) > 0
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handlePermissionChange(event)
              }
              disabled={isRestDriver || isSupportImpersonationActive}
            />
          </td>
          <td className="text-center">
            <CDataCheckbox
              id={permission.id + ":DELETE"}
              value={Ops.Delete}
              className="py-0"
              checked={
                !isRestDriver &&
                (PermissionFlag.DELETE & permission.opsAllowed) > 0
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handlePermissionChange(event)
              }
              disabled={isRestDriver || isSupportImpersonationActive}
            />
          </td>
          <td className="text-center">
            <CDataCheckbox
              id={permission.id + ":EXECUTE"}
              value={Ops.Execute}
              className="py-0"
              checked={
                !isRestDriver &&
                (PermissionFlag.EXECUTE & permission.opsAllowed) > 0
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handlePermissionChange(event)
              }
              disabled={isRestDriver || isSupportImpersonationActive}
            />
          </td>
        </tr>
      );
    },
  );

  return (
    <Container
      fluid
      className="p-0 pages-connections-components-permissions-card"
    >
      <Card data-testid="permissions-card">
        <CardBody>
          <h4 className="card-title mb-3">Permissions</h4>
          <Table className="permission-table text-left">
            <thead className="table-borderless">
              <tr>
                <th
                  className="text-left "
                  onClick={() => toggleSort("rowName")}
                >
                  {props.rowColumnName}{" "}
                  {<i className={getTypeSortIconClassName()}></i>}
                </th>
                {
                  <th
                    className={`text-left ${isUsersPage ? "" : "d-none"}`}
                    onClick={() => toggleSort("type")}
                  >
                    {"Type"} {<i className={getRowNameSortIconClassName()}></i>}
                  </th>
                }
                <th className="text-center">
                  <CDataCheckbox
                    id="check-all-select"
                    name={PermissionNames.SELECT}
                    className="py-0"
                    checked={isHeaderCheckboxSelected("SELECT")}
                    indeterminate={isHeaderCheckboxIndeterminate("SELECT")}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      selectAllInColumn(event)
                    }
                    disabled={isSupportImpersonationActive}
                    inputProps={{
                      "data-testid": "check-all-select",
                    }}
                  />
                  <Label for="check-all-select" className="align-middle">
                    Select
                  </Label>
                  <UncontrolledTooltip
                    placement="top"
                    target="check-all-select"
                  >
                    Select statements can be used to retrieve records from a
                    data source based on specified criteria
                  </UncontrolledTooltip>
                </th>
                <th className="text-center">
                  <CDataCheckbox
                    id="check-all-insert"
                    name={PermissionNames.INSERT}
                    className="py-0"
                    data-testid="check-all-insert"
                    checked={
                      !isApiConnectionPage && isHeaderCheckboxSelected("INSERT")
                    }
                    indeterminate={isHeaderCheckboxIndeterminate("INSERT")}
                    disabled={
                      isApiConnectionPage || isSupportImpersonationActive
                    }
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      selectAllInColumn(event)
                    }
                    inputProps={{
                      "data-testid": "check-all-insert",
                    }}
                  />
                  <Label
                    for="check-all-insert"
                    className={classNames("align-middle", {
                      "header-disabled": isApiConnectionPage,
                    })}
                  >
                    Insert
                  </Label>
                  <UncontrolledTooltip
                    placement="top"
                    target="check-all-insert"
                  >
                    Insert statements can be used to insert records into an
                    existing data source
                  </UncontrolledTooltip>
                </th>
                <th className="text-center">
                  <CDataCheckbox
                    id="check-all-update"
                    name={PermissionNames.UPDATE}
                    className="py-0"
                    data-testid="check-all-update"
                    checked={
                      !isApiConnectionPage && isHeaderCheckboxSelected("UPDATE")
                    }
                    indeterminate={isHeaderCheckboxIndeterminate("UPDATE")}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      selectAllInColumn(event)
                    }
                    disabled={
                      isApiConnectionPage || isSupportImpersonationActive
                    }
                    inputProps={{
                      "data-testid": "check-all-update",
                    }}
                  />
                  <Label
                    for="check-all-update"
                    className={classNames("align-middle", {
                      "header-disabled": isApiConnectionPage,
                    })}
                  >
                    Update
                  </Label>
                  <UncontrolledTooltip
                    placement="top"
                    target="check-all-update"
                  >
                    Update statements can be used to update records in an
                    existing data source
                  </UncontrolledTooltip>
                </th>
                <th className="text-center">
                  <CDataCheckbox
                    id="check-all-delete"
                    name={PermissionNames.DELETE}
                    className="py-0"
                    data-testid="check-all-delete"
                    checked={
                      !isApiConnectionPage && isHeaderCheckboxSelected("DELETE")
                    }
                    indeterminate={isHeaderCheckboxIndeterminate("DELETE")}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      selectAllInColumn(event)
                    }
                    disabled={
                      isApiConnectionPage || isSupportImpersonationActive
                    }
                    inputProps={{
                      "data-testid": "check-all-delete",
                    }}
                  />
                  <Label
                    for="check-all-delete"
                    className={classNames("align-middle", {
                      "header-disabled": isApiConnectionPage,
                    })}
                  >
                    Delete
                  </Label>
                  <UncontrolledTooltip
                    placement="top"
                    target="check-all-delete"
                  >
                    Delete statements can be used to delete records in an
                    existing data source
                  </UncontrolledTooltip>
                </th>
                <th className="text-center">
                  <CDataCheckbox
                    id="check-all-execute"
                    name={PermissionNames.EXECUTE}
                    className="py-0"
                    data-testid="check-all-execute"
                    checked={
                      !isApiConnectionPage &&
                      isHeaderCheckboxSelected("EXECUTE")
                    }
                    indeterminate={isHeaderCheckboxIndeterminate("EXECUTE")}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      selectAllInColumn(event)
                    }
                    disabled={
                      isApiConnectionPage || isSupportImpersonationActive
                    }
                    inputProps={{
                      "data-testid": "check-all-execute",
                    }}
                  />
                  <Label
                    for="check-all-execute"
                    className={classNames("align-middle", {
                      "header-disabled": isApiConnectionPage,
                    })}
                  >
                    Execute
                  </Label>
                  <UncontrolledTooltip
                    placement="top"
                    target="check-all-execute"
                  >
                    Execute statements can be used to execute stored procedures
                    in a specified data source
                  </UncontrolledTooltip>
                </th>
              </tr>
            </thead>
            <tbody is="permissions">{entityPermissionBody}</tbody>
          </Table>
        </CardBody>
      </Card>
    </Container>
  );
};

export default PermissionsCard;
