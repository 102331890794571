import { useRef, useState } from "react";
import ApiConnectorLogo from "../../assets/img/Rest.svg?react";
import { Badge, Card, CardBody, CardText } from "reactstrap";
import { getBaseIconUrl } from "./DriverIconFactory";
import { ButtonBase } from "@mui/material";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { getSettingsPageSubscriptionTab } from "../../utility/SubscriptionProvider";
import { CDataTooltip, CDataTooltipType } from "../CDataTooltip";
import { CDataTypography } from "../text/CDataTypography";

export interface IDriverConnection {
  driver: string;
  isBeta: boolean;
  niceName: string;
  onClick: () => void;
  disabled?: boolean;
  connectionLimitReached?: boolean;
}

export const DriverConnectionTile = (props: IDriverConnection) => {
  const {
    driver,
    isBeta,
    niceName,
    onClick,
    disabled = false,
    connectionLimitReached = false,
  } = props;

  const cardRef = useRef<HTMLDivElement>(null);
  const { showTooltip, setShowTooltip, updateTooltip, getTooltipText } =
    useDriverTileTooltip(disabled, niceName, connectionLimitReached);

  return (
    <CDataTooltip
      open={showTooltip}
      onOpen={() => updateTooltip(cardRef.current!)}
      onClose={() => setShowTooltip(false)}
      type={CDataTooltipType.Dark}
      title={getTooltipText()}
    >
      <div>
        <Card
          id={driver}
          className={classNames("shadow-sm driver-card", {
            "disabled-opacity": disabled,
          })}
        >
          <ButtonBase
            disableRipple
            className="driver-clickable-area"
            disabled={disabled}
            onClick={() => {
              disabled ? null : onClick();
            }}
          >
            <div className="driver-tile-icon-container">
              <DriverIcon driver={driver} />
            </div>
            <CardBody className="card-body">
              <CardText
                id={`row-${driver}`}
                className="driver-card-title"
                tag="div"
              >
                <div ref={cardRef}>
                  <CDataTypography
                    variant="typography-variant-body-medium"
                    color="typography-color-text-muted"
                  >
                    {niceName}
                  </CDataTypography>
                </div>
              </CardText>
            </CardBody>
          </ButtonBase>
          <div>
            <div className="action-card-container d-flex">
              <Badge
                id="beta-badge"
                hidden={!isBeta}
                color=""
                className="driver-beta-badge"
              >
                Beta
              </Badge>
            </div>
          </div>
        </Card>
      </div>
    </CDataTooltip>
  );
};

/** A small custom hook that handles the layered logic for tooltips on driver tiles. */
const useDriverTileTooltip = (
  disabled: boolean,
  niceName: string,
  connectionLimitReached: boolean,
) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const navigate = useNavigate();

  const updateTooltip = (element: {
    offsetHeight: number;
    scrollHeight: number;
    offsetWidth: number;
    scrollWidth: number;
  }) => {
    // If the driver is disabled, we need the tooltip to show on hover no matter what. If it's not disabled, check for overflow.
    if (disabled) {
      setShowTooltip(true);
    } else {
      const hasOverflowingChildren: boolean =
        element.offsetHeight < element.scrollHeight ||
        element.offsetWidth < element.scrollWidth;

      setShowTooltip(hasOverflowingChildren);
    }
  };

  const getTooltipText = () => {
    // If the driver isn't disabled, we would only need the full driver name to show in the tooltip.
    if (!disabled) return niceName;

    // If the driver is disabled, figure out why and set the tooltip accordingly.
    const upgradeLink = (
      <a
        className="upgrade-link"
        onClick={() =>
          navigate("/settings?defaultTab=" + getSettingsPageSubscriptionTab())
        }
      >
        Upgrade
      </a>
    );

    if (connectionLimitReached) {
      return (
        <span>
          You&apos;ve reached your Connection limit for this Data Source.{" "}
          {upgradeLink} your plan to add more.
        </span>
      );
    } else {
      return (
        <span>
          Premium Connectors are not available with your subscription.{" "}
          {upgradeLink} your plan today.
        </span>
      );
    }
  };

  return { showTooltip, setShowTooltip, updateTooltip, getTooltipText };
};

const DriverIcon = ({ driver }: { driver: string }) =>
  driver === "ApiConnector" ? (
    <ApiConnectorLogo
      className="driver-tile-icon card-img-top img-thumbnail"
      aria-description="API Connector"
    />
  ) : (
    <img
      src={`${getBaseIconUrl()}/${driver}.svg`}
      className="driver-tile-icon card-img-top img-thumbnail"
      alt={`${driver} icon`}
    />
  );
