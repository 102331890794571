import { CDataModalV2 } from "../../../../components/modal/CDataModalV2";
import {
  ButtonType,
  CDataButton,
} from "../../../../components/buttons/CDataButton";
import useQueryTabs from "../Tabs/useQueryTabs";
import { Spinner } from "reactstrap";
import {
  sanitizeQuery,
  sanitizeQueryProps,
} from "../QueryEditor/sanitizeQuery";
import { ToastrError, ToastrSuccess } from "../../../../services/toastrService";
import { getWorkspaceNameFromQuery } from "../getWorkspaceNamefromQuery";
import { useMutation } from "@tanstack/react-query";
import { downloadCsv } from "../../api/downloadCsv";

export type DownloadCSVModalProps = {
  displayed: boolean;
  close: () => void;
  queryInput: string;
  setQueryInput: (queryInput: string) => void;
  queryLimit: number;
  setQueryLimit: (queryLimit: number) => void;
  csvDownloadLoading: boolean;
  setCSVDownloadLoading: (csvDownloadLoading: boolean) => void;
  setCsvDownloadError: (csvDownloadError: boolean) => void;
};

export function DownloadCSVModal(props: DownloadCSVModalProps) {
  const tabs = useQueryTabs();

  const {
    displayed,
    close,
    queryInput,
    setQueryInput,
    queryLimit,
    setQueryLimit,
    csvDownloadLoading,
    setCSVDownloadLoading,
    setCsvDownloadError,
  } = props;

  const currentTab = tabs.List.find((t) => t.id === tabs.CurrentTabId);

  const { mutateAsync: downloadCsvAsync } = useMutation({
    mutationKey: ["/downloadcsv"],
    mutationFn: downloadCsv,
    onError: (error) => {
      setCsvDownloadError(true);
      ToastrError("Error Downloading CSV", error.message);
    },
    onSuccess: () => {
      ToastrSuccess(
        "CSV successfully downloaded",
        "Your query results were successfully downloaded.",
      );
    },
    onSettled: () => {
      setCSVDownloadLoading(false);
      close();
    },
  });

  if (!currentTab) {
    return null;
  }

  const handleCSVDownload = async () => {
    setCSVDownloadLoading(true);
    setCsvDownloadError(false);
    const sanitizeParameters: sanitizeQueryProps = {
      currentQueryInput: queryInput,
      setCurrentQueryInput: setQueryInput,
      queryLimit: queryLimit,
      setQueryLimit: setQueryLimit,
      enforceLimit: false,
    };

    const sanitizedQuery = sanitizeQuery(sanitizeParameters);
    const workspaceName = getWorkspaceNameFromQuery(queryInput);

    const requestBody = {
      query: sanitizedQuery,
      defaultCatalog: currentTab.connectionName ?? "",
    };

    await downloadCsvAsync({
      isWorkspace: currentTab.isWorkspace ?? false,
      workspaceName: workspaceName,
      body: requestBody,
    });
  };

  return (
    <>
      <CDataModalV2
        displayed={displayed}
        close={close}
        title="Download CSV"
        spacedFooter={false}
        primaryButton={
          <CDataButton
            buttonType={ButtonType.Primary}
            disabled={csvDownloadLoading}
            onClick={handleCSVDownload}
          >
            {csvDownloadLoading ? (
              <div className="button-loading-state">
                <Spinner size="sm" className="me-2 export-spinner" />
                Exporting...
              </div>
            ) : (
              "Confirm"
            )}
          </CDataButton>
        }
        secondaryButton={
          <CDataButton
            buttonType={ButtonType.Secondary}
            disabled={csvDownloadLoading}
            onClick={() => {
              close();
            }}
          >
            Cancel
          </CDataButton>
        }
      >
        <>
          <div>
            You are about to export the full results of your query which may
            take several minutes to complete. Navigating away from the page will
            cancel the request.
          </div>
        </>
      </CDataModalV2>
    </>
  );
}
