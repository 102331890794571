import { Radio, RadioProps } from "@mui/material";
import { CDataTypography } from "src/components/text/CDataTypography";

export interface CDataRadioProps extends RadioProps {
  label?: string;
}

export function CDataRadio(props: CDataRadioProps) {
  const label = props.label && (
    <CDataTypography className="d-inline align-text-top">
      {props.label}
    </CDataTypography>
  );

  return (
    <div className={props.className}>
      <Radio
        {...props}
        className="cdata-radio ps-0 pe-2 py-0"
        icon={<span className="radio-unselected" />}
        checkedIcon={<span className="radio-selected" />}
        disableRipple
      />
      {label}
    </div>
  );
}
