import { getScheduledQuery } from "./getScheduledQuery";
import { updateScheduledQuery } from "./updateScheduledQuery";
import { IUpdateScheduledQuery } from "../../../models/ScheduledQueries/IUpdateScheduledQuery";

type IUpdateScheduledQuerySQL = {
  queryId: string;
  queryText: string;
};

export async function updateScheduledQuerySQL(
  params: IUpdateScheduledQuerySQL,
) {
  const scheduledQueryDetails = await getScheduledQuery({
    queryId: params.queryId,
  });

  const updateDetails: IUpdateScheduledQuery = {
    ...scheduledQueryDetails,
    query: params.queryText,
  };

  const updateSqlText = await updateScheduledQuery(updateDetails);

  return updateSqlText;
}
