import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";

interface IScheduledQueriesProps {
  noData: any;
}

const ScheduledQueries = (props: IScheduledQueriesProps) => {
  const ScheduledQueryColumns = [
    {
      dataField: "name",
      text: "",
    },
    {
      dataField: "id",
      text: "",
      hidden: true,
    },
  ];
  return (
    <BootstrapTable
      bootstrap4
      bordered={false}
      classes="data-explorer-tab-list"
      wrapperClasses="generic-panel-table table-borderless px-0"
      rowClasses="generic-panel-table-row"
      keyField="name"
      data={[]}
      columns={ScheduledQueryColumns}
      filter={filterFactory()}
      noDataIndication={props.noData}
    />
  );
};

export default ScheduledQueries;
