import * as Yup from "yup";

export function generateInitialValues<T>(data: T[], fieldNames: string[]) {
  const values = data.reduce((result: any, item: T, index) => {
    fieldNames.forEach((name: string) => {
      result[`${name}${index}`] = item[name as keyof T];
    });
    return result;
  }, {});
  return values;
}

export function generateValidationSchema<T>(
  data: T[],
  generateSchema: (item: T, index: number) => Yup.ObjectSchema<any>,
) {
  const individualSchemas = data.map((item: T, index) => {
    return generateSchema(item, index);
  });

  const combinedSchemas = Yup.object().shape(
    individualSchemas.reduce((accumulator, schema) => {
      return Object.assign(accumulator, schema.fields);
    }, {}),
  );
  return combinedSchemas;
}
