import { cdataFetch } from "../../../api/cdataFetch";
import { IBatchCreateRequest, IBatchCreateResponse } from "../../../models";

export async function batchRunCacheJobs(jobs: IBatchCreateRequest<string>) {
  await cdataFetch<IBatchCreateResponse<string>>({
    method: "POST",
    url: "/cacheJobs/run",
    body: jobs,
  });
  return true; // The back-end model doesn't return anything, so we just assert success this way
}
