import React, { useState } from "react";
import {
  ButtonType,
  CDataButton,
} from "../../../../../../../components/buttons/CDataButton";
import { CDataModalV2 } from "../../../../../../../components/modal/CDataModalV2";
import { IQueryBuilderCommonProps } from "../../../models/IQueryBuilderCommonProps";
import { IQueryBuilderSort } from "../../../models/IQueryBuilderModel";
import { SortDirection } from "../../../models/SortDirection";
import { AddSortsModalContent } from "./AddSortsModalContent";
import { DataType } from "../../../../../../../models";

interface AddSortsModalProps extends IQueryBuilderCommonProps {
  displayed: boolean;
  close: () => void;
  sortToEditIndex?: number;
}

export function AddSortsModal(props: AddSortsModalProps) {
  const { queryData, setQueryData, displayed, close, sortToEditIndex } = props;

  const sortToEdit =
    sortToEditIndex !== undefined ? queryData.sort[sortToEditIndex] : undefined;

  const [querySort, setQuerySort] = useState<IQueryBuilderSort>(
    sortToEdit !== undefined ? sortToEdit : getDefaultValues(),
  );

  function getDefaultValues(): IQueryBuilderSort {
    return {
      column: {
        table:
          queryData.joins.length === 0
            ? queryData.from
            : {
                connectionId: "",
                connectionName: "",
                driver: "",
                schema: "",
                tableAlias: "",
                tableName: "",
              },
        column: "",
        // Just default to something here, we change it later.
        dataType: DataType.VARCHAR,
      },
      direction: SortDirection.ASC,
    };
  }

  function onSubmit() {
    const updatedSorts = [...queryData.sort];
    if (props.sortToEditIndex !== undefined) {
      updatedSorts[sortToEditIndex!] = { ...querySort };
    } else {
      updatedSorts.push({ ...querySort });
    }

    setQueryData({
      ...queryData,
      sort: updatedSorts,
    });
    close();
  }

  const modalTitle = props.sortToEditIndex ? "Edit Sort" : "Add Sort";

  const isConfirmButtonDisabled =
    querySort.column.table.tableName === "" || querySort.column.column === "";

  return (
    <>
      <CDataModalV2
        displayed={displayed}
        close={close}
        title={modalTitle}
        className="add-sorts-modal"
        primaryButton={
          <CDataButton
            aria-label="Confirm"
            buttonType={ButtonType.Primary}
            disabled={isConfirmButtonDisabled}
            onClick={() => onSubmit()}
          >
            Confirm
          </CDataButton>
        }
      >
        <AddSortsModalContent
          {...props}
          querySort={querySort}
          setQuerySort={setQuerySort}
          sortToEdit={sortToEdit}
          connectionsList={props.connectionsList}
        />
      </CDataModalV2>
    </>
  );
}
