import { useEffect, useState } from "react";
import { FormControl, Select, MenuItem } from "@mui/material";
import { Search } from "react-feather";
import { Badge, Card, Col, Input, Row } from "reactstrap";
import classnames from "classnames";

interface IFilterGridProps {
  categories: any;
  selectedCategory?: string;
  tiles: any[];
  tileTypes: "Connections" | "Clients";
  searchHidden?: boolean;
}

const FilterGrid = ({
  categories,
  selectedCategory,
  tiles,
  tileTypes,
  searchHidden,
}: IFilterGridProps) => {
  const [textFilter, setTextFilter] = useState<string>("");
  const [categoryFilter, setCategoryFilter] = useState<number>(0);
  const [filteredTiles, setFilteredTiles] = useState<any[]>(tiles);

  const isCategoryDropdownDisplayed = selectedCategory === undefined;

  useEffect(() => {
    if (!isCategoryDropdownDisplayed) {
      if (selectedCategory !== "all") {
        tiles = tiles.filter((tile: any) => tile.category === selectedCategory); // eslint-disable-line
      }
    } else if (categoryFilter !== 0) {
      tiles = tiles.filter(
        (tile: any) =>
          tile.category === Object.keys(categories)[categoryFilter],
      );
    }

    if (textFilter !== "") {
      tiles = tiles.filter((tile: any) =>
        tile.name.toLowerCase().includes(textFilter.toLowerCase()),
      );
    }

    setFilteredTiles(tiles);
  }, [textFilter, selectedCategory, categoryFilter, tiles]);

  function handleTextChange(event: any) {
    setTextFilter(event.target.value);
  }

  const tabItems = Object.keys(categories).map((key, index) => {
    let categoryCount: any;
    if (categories[key] === "All") {
      categoryCount = tiles.length;
    } else {
      categoryCount = tiles.filter((currData: any) => {
        return currData.category === Object.keys(categories)[index];
      }).length;
    }
    return (
      <MenuItem className="drop-down-menu" value={index} key={key}>
        <div className="drop-down-items">
          {categories[key]}
          <Badge className="drop-down-count-badge" color="">
            {categoryCount}
          </Badge>
        </div>
      </MenuItem>
    );
  });

  const categoryDropdown = isCategoryDropdownDisplayed && (
    <Col className="flex-grow-0 drop-down-outer">
      <span className="drop-down-view d-inline-block">View:</span>
      <FormControl fullWidth className="drop-down d-inline">
        <Select
          id="demo-select-small"
          MenuProps={{
            className: "filter-grid-category-dropdown",
          }}
          value={categoryFilter}
          onChange={(event) => setCategoryFilter(event.target.value as number)}
        >
          {tabItems}
        </Select>
      </FormControl>
    </Col>
  );

  return (
    <div className="components-FilterGrid">
      <Row>
        <Col>
          <Card>
            <Row id="target-premium" hidden={searchHidden}>
              <Col
                className={classnames("search-bar-outer flex-grow-1", {
                  "pe-0": isCategoryDropdownDisplayed,
                })}
              >
                <Search className="align-middle me-2" size={16} />
                <Input
                  autoFocus={true}
                  onChange={handleTextChange}
                  placeholder="Search by name..."
                  value={textFilter}
                />
              </Col>
              {categoryDropdown}
            </Row>
            <Row
              className={classnames("scrollable-container card-row my-3", {
                "connections-container": tileTypes === "Connections",
                "clients-container": tileTypes === "Clients",
              })}
            >
              {filteredTiles.map((card) => (
                <Col key={card.driverName ?? card.name}>{card.tile}</Col>
              ))}
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default FilterGrid;
