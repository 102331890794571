import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { customFilter } from "react-bootstrap-table2-filter";
import { PopoverList } from "../../../../components/popover/PopoverList";
import useQueryTabs from "../../RightPanel/Tabs/useQueryTabs";
import { useState } from "react";
import { Spinner } from "reactstrap";
import { RenameSavedQueryModal } from "./RenameSavedQueryModal";
import { ISavedQuery } from "../../../../models/SavedQueries/ISavedQuery";
import { DeleteSavedQueryModal } from "./DeleteSavedQueryModal";
import { LeftPanelType } from "../leftPanelType";

interface ISavedQueriesProps {
  noData: any;
  filterSearch: any;
  savedQueries: any;
  savedQueriesLoading: boolean;
  filterSavedQueries: any;
  callbackSearchFilter: any;
  fetchSavedQueries: () => void;
  setSidebarView: (sidebarView: LeftPanelType) => void;
}

const SavedQueries = (props: ISavedQueriesProps) => {
  let onSearchFilter = props.filterSearch;

  const tabs = useQueryTabs();

  const [targetSavedQuery, setTargetSaveQuery] = useState<ISavedQuery>();
  const [renameSavedQueryModalOpen, setRenameSavedQueryModalOpen] =
    useState<boolean>(false);
  const [deleteSavedQueryModalOpen, setDeleteSavedQueryModalOpen] =
    useState<boolean>(false);

  async function openSavedQueryTab(
    name: string,
    queryText: string,
    id: string,
  ) {
    await tabs.AddSavedQueryTab(name, queryText, id);
  }

  const savedQueriesColumns = [
    {
      dataField: "name",
      text: "",
      filter: customFilter({
        // @ts-ignore prexisting issue, needs refactor
        onFilter: (input, rows) => props.filterSavedQueries(input, rows),
      }),
      formatExtraData: openSavedQueryTab,
      filterRenderer: (onFilter: any): any => {
        onSearchFilter = onFilter;
        props.callbackSearchFilter(onSearchFilter);
        return null;
      },
      formatter: (
        cell: any,
        row: any,
        rowIndex: number,
        formatExtraData: any,
      ) => {
        const popoverOptions = [
          {
            label: "Query",
            action: () => formatExtraData(row.name, row.query, row.id),
          },
          {
            label: "Rename",
            action: () => {
              setTargetSaveQuery(row);
              setRenameSavedQueryModalOpen(true);
            },
          },
          {
            label: "Delete",
            action: () => {
              setTargetSaveQuery(row);
              setDeleteSavedQueryModalOpen(true);
            },
          },
        ];

        return (
          <div
            // @ts-ignore prexisting issue, needs refactor
            name={row.name}
            // eslint-disable-next-line
            query={row.query}
            id={row.id}
          >
            <div
              className="table-name-text data-explorer-container"
              title={row.name}
            >
              <div
                className="cursor-pointer table-name-text"
                onClick={() => formatExtraData(row.name, row.query, row.id)}
              >
                <i className="fa fa-table align-middle" />
                <span className="align-middle truncate ms-3">{row.name}</span>
              </div>
              <div
                id={"SavedQueryPopover-" + rowIndex}
                className="ellipsis-right cursor-pointer"
              >
                <i
                  className="fa fa-ellipsis-v align-middle"
                  data-testid={"popover-saved-query-" + rowIndex}
                />
                <PopoverList
                  target={"SavedQueryPopover-" + rowIndex}
                  key={rowIndex}
                  popoverOptions={popoverOptions}
                />
              </div>
            </div>
          </div>
        );
      },
    },
    {
      dataField: "query",
      text: "",
      hidden: true,
    },
  ];

  if (props.savedQueriesLoading) {
    return (
      <div
        className="loading-table-list"
        data-testid="spinner-saved-query-list"
      >
        <Spinner color="primary" />
      </div>
    );
  }

  return (
    <>
      <BootstrapTable
        bootstrap4
        bordered={false}
        classes="data-explorer-tab-list"
        wrapperClasses="generic-panel-table table-borderless px-0"
        rowClasses="generic-panel-table-row"
        keyField="name"
        data={props.savedQueries}
        columns={savedQueriesColumns}
        hover={true}
        filter={filterFactory()}
        noDataIndication={props.noData}
      />
      <RenameSavedQueryModal
        displayed={renameSavedQueryModalOpen}
        close={() => setRenameSavedQueryModalOpen(false)}
        targetSavedQuery={targetSavedQuery}
        fetchSavedQueries={props.fetchSavedQueries}
      />
      <DeleteSavedQueryModal
        displayed={deleteSavedQueryModalOpen}
        close={() => setDeleteSavedQueryModalOpen(false)}
        targetSavedQuery={targetSavedQuery}
        savedQueries={props.savedQueries}
        fetchSavedQueries={props.fetchSavedQueries}
        setSidebarView={props.setSidebarView}
      />
    </>
  );
};

export default SavedQueries;
