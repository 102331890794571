import classNames from "classnames";
import React, { ReactNode } from "react";

interface IQueryBuilderCardProps {
  children: ReactNode;
  className?: string;
}

export function QueryBuilderCard(props: IQueryBuilderCardProps) {
  const { children, className } = props;
  return (
    <div className={classNames("queryBuilderCard", className)}>{children}</div>
  );
}
