import { Tooltip, TooltipProps, styled, tooltipClasses } from "@mui/material";

export enum CDataTooltipType {
  Light,
  Dark,
}

interface ICDataTooltipProps extends TooltipProps {
  type: CDataTooltipType;
  children: JSX.Element;
}

// The only customization here is a lower max width (default is 300px) and center-aligned text
const DarkTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 200,
    textAlign: "center",
  },
  ["a"]: {
    color: "white",
    textDecoration: "underline",
  },
  ["a:hover"]: {
    color: "white",
    textDecoration: "underline",
  },
});

// Custom styling for tooltips that need a light background, including the width & centering from the dark tooltip
const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    maxWidth: 200,
    textAlign: "center",
  },
  ".MuiTooltip-arrow": {
    color: theme.palette.common.white,

    "&::before": {
      backgroundColor: theme.palette.common.white,
      boxShadow: theme.shadows[1],
    },
  },
}));

export const CDataTooltip = (props: ICDataTooltipProps) => {
  if (props.type === CDataTooltipType.Light) {
    return <LightTooltip {...props}>{props.children}</LightTooltip>;
  } else {
    return <DarkTooltip {...props}>{props.children}</DarkTooltip>;
  }
};
