import { useNavigate } from "react-router-dom";
import {
  CDataTooltip,
  CDataTooltipType,
} from "../../../components/CDataTooltip";
import { CDataTypography } from "../../../components/text/CDataTypography";

export function FailedConnectionWarningIconWithToolTip({
  driver,
  connectionId,
  toolTipKey,
}: {
  driver: string;
  connectionId: string;
  toolTipKey?: string;
}) {
  const navigate = useNavigate();
  return (
    <CDataTooltip
      key={toolTipKey}
      type={CDataTooltipType.Dark}
      title={
        <CDataTypography
          color="typography-color-white"
          variant="typography-variant-tooltip"
        >
          {"We've detected an issue with this connection. Please visit the "}
          <a
            className="underline"
            onClick={(e) => {
              e.stopPropagation();
              navigate("/connections/edit", {
                state: {
                  driverType: driver,
                  connectionId: connectionId,
                },
              });
            }}
          >
            Edit Connection
          </a>
          {" page to resolve it."}
        </CDataTypography>
      }
    >
      <i className="fa-solid fa-triangle-exclamation warning-icon"></i>
    </CDataTooltip>
  );
}
