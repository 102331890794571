// Accepts an array of objects and sorts it alphabetically according to the name field
import { IWorkspaceAssetListItem } from "../../../bffmodels/IWorkspaceAssetListItem";
import { IAssetListItem } from "../../../models";

// If the name field is not available, sorts by alias instead.
export function sortByNameOrAlias(objects: any[]) {
  return objects.sort((a: any, b: any) => {
    if (a.name) {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    } else if (a.alias) {
      return a.alias.toLowerCase().localeCompare(b.alias.toLowerCase());
    }
  });
}

export function sortAssetsByAlias(
  objects: IAssetListItem[] | IWorkspaceAssetListItem[],
) {
  objects.sort((a: IAssetListItem, b: IAssetListItem) => {
    return a.assetType - b.assetType || a.alias!.localeCompare(b.alias!);
  });
}
