import { useState } from "react";
import { getEncoding } from "js-tiktoken";

const useTokenCalculator = () => {
  const [tokenCount, setTokenCount] = useState<number>(0);

  const calculateTokens = (input: string) => {
    const enc = getEncoding("p50k_base");
    const inputTokens = enc.encode(input);
    setTokenCount(inputTokens.length);
  };

  return { tokenCount, calculateTokens };
};

export default useTokenCalculator;
