import { ButtonBase, Tooltip } from "@mui/material";
import React, { ReactNode, useState } from "react";
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  DragStartEvent,
  DragOverEvent,
  DragOverlay,
  pointerWithin,
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Badge } from "reactstrap";
import { getDriverIcon } from "../../../../../../../components/drivers/DriverIconFactory";
import { IConnection } from "../../../../../../../models";
import { IQueryBuilderColumn } from "../../../models/IQueryBuilderColumn";

type QueryBuilderColumnChipListProps = {
  connectionsList: IConnection[];
  columns: IQueryBuilderColumn[];
  onRemoveColumn: (column: IQueryBuilderColumn, index: number) => void;
  onReorderColumns: (oldColumnIndex: number, newColumnIndex: number) => void;
  getColumnText?: (column: IQueryBuilderColumn, index: number) => ReactNode;
  /**
   * This needs to return a unique ID for each column.
   * Be careful not to have duplicate IDs or drag and drop breaks.
   */
  getColumnId: (column: IQueryBuilderColumn) => string;
};

export function QueryBuilderColumnChipList(
  props: QueryBuilderColumnChipListProps,
) {
  const { columns, onReorderColumns, getColumnId } = props;

  // This is the ID from getColumnId for the element we're dragging.
  // This is used to show the drag preview.
  const [columnIdBeingDragged, setColumnIdBeingDragged] = useState<
    string | null
  >(null);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  // Stores the element we are dragging so we can manually show a preview of it.
  function handleDragStart(event: DragStartEvent) {
    setColumnIdBeingDragged(event.active.id as string);
  }

  // Handles showing the preview for where the chip will appear when dragging.
  // dndkit normally does this automatically but it can't handle elements with different width.
  // As a result, we just re-order the array as the user is dragging to show the real element in the actual
  // location it will be in.
  // See: https://github.com/clauderic/dnd-kit/issues/44#issuecomment-757312037
  function handleDragOver(event: DragOverEvent) {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = columns.findIndex((c) => getColumnId(c) === active.id);
      const newIndex = columns.findIndex((c) => getColumnId(c) === over.id);
      onReorderColumns(oldIndex, newIndex);
    }
  }

  // Called when the user finishes dragging, re-orders the array of columns and stops showing the preview.
  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    setColumnIdBeingDragged(null);

    if (over == null) {
      return;
    }

    if (active.id !== over.id) {
      const oldIndex = columns.findIndex((c) => getColumnId(c) === active.id);
      const newIndex = columns.findIndex((c) => getColumnId(c) === over.id);
      onReorderColumns(oldIndex, newIndex);
    }
  }

  return (
    <div className="queryBuilderColumnChipList">
      <DndContext
        sensors={sensors}
        collisionDetection={pointerWithin}
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDragCancel={handleDragEnd}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={columns.map((c) => getColumnId(c))}
          // This disables the built in sorting preview because our items are not all the same size.
          // The preview is manually handled in code.
          // See: https://github.com/clauderic/dnd-kit/issues/44#issuecomment-757312037
          strategy={() => null}
        >
          {columns.map((column, index) => {
            return (
              <ColumnChip
                // While dragging chips, do not show the tooltips since it breaks the layout.
                hideTooltip={columnIdBeingDragged != null}
                key={getColumnId(column)}
                {...props}
                columnIndex={index}
                getColumnId={getColumnId}
              />
            );
          })}
        </SortableContext>
        {/*
          This is the drag preview shown as the user drags.
          This is normally automatically done by dnd-kit but since our elements are variable in width
          we need to do it ourselves.
        */}
        <DragOverlay>
          {columnIdBeingDragged ? (
            <div>
              <ColumnChip
                hideTooltip
                key={columnIdBeingDragged}
                {...props}
                columnIndex={columns.findIndex(
                  (c) => getColumnId(c) === columnIdBeingDragged,
                )}
                getColumnId={getColumnId}
              />
            </div>
          ) : null}
        </DragOverlay>
      </DndContext>
    </div>
  );
}
type ColumnChipProps = Omit<QueryBuilderColumnChipListProps, "getColumnId"> & {
  columnIndex: number;
  /** Used to hide the tooltip when dragging, the tooltips break the layout while dragging. */
  hideTooltip: boolean;
  getColumnId: (column: IQueryBuilderColumn) => string;
};

function ColumnChip(props: ColumnChipProps) {
  const {
    connectionsList,
    columns,
    columnIndex,
    hideTooltip,
    onRemoveColumn,
    getColumnText = (column) => column.column,
    getColumnId,
  } = props;

  const column = columns[columnIndex];

  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: getColumnId(column) });

  const connection = connectionsList.find(
    (c) => c.id === column.table.connectionId,
  );
  const badge = (
    <span>
      <Badge color="" className="columnChip">
        {connection != null &&
          getDriverIcon(connection.driver!, "connection-icon")}
        <span>{getColumnText(column, columnIndex)}</span>
        <ButtonBase
          aria-label={`Remove column ${column.column}`}
          onClick={(event) => {
            event.stopPropagation();
            onRemoveColumn(column, columnIndex);
          }}
          onPointerDown={(event) => {
            // Prevent drag and drop when clicking the close button
            event.stopPropagation();
            event.preventDefault();
          }}
          className="columnChip-closeButton"
        >
          <i className="fa fa-times fa-solid columnChip-closeIcon align-content-center" />
        </ButtonBase>
      </Badge>
    </span>
  );

  return (
    <div
      ref={setNodeRef}
      style={{
        position: "relative",
        display: "inline-block",
        cursor: isDragging || hideTooltip ? "grabbing" : "grab",
        opacity: isDragging ? 0.7 : undefined,
        transform: CSS.Translate.toString(transform),
        transition,
      }}
      {...attributes}
      {...listeners}
    >
      {hideTooltip === true ? (
        badge
      ) : (
        <Tooltip
          key={getColumnId(column)}
          title={`${column.table.connectionName}.${column.table.schema}.${column.table.tableAlias}`}
        >
          {badge}
        </Tooltip>
      )}
    </div>
  );
}
