import React, { useContext } from "react";
import { MSSQL } from "@codemirror/lang-sql";
import { EditorView } from "@codemirror/view";
import { langs } from "@uiw/codemirror-extensions-langs";
import { Prec } from "@codemirror/state";
import useQueryTabs from "../Tabs/useQueryTabs";
import ResizableDiv from "../../../../components/ResizableDiv";
import { QueryTabsContext } from "../Tabs/QueryTabsContext";
import { createDeepCopy } from "../../../../utility/CreateDeepCopy";
import { autocompletion } from "@codemirror/autocomplete";
import { CDataCodeMirror } from "../../../../components/codemirror/CDataCodeMirror";

interface IQueryEditor {
  currentQueryInput: string;
  queryEditorDisabled: boolean;
  rowLimitLockdown: boolean;
  setCurrentQueryInput: (input: string) => void;
  setExecuteDisabled: (disabled: boolean) => void;
  hasUnsavedChanges: boolean;
  setUnsavedChanges: () => void;
  queryEditorHeight: number;
  setQueryEditorHeight: (queryEditorHeight: number) => void;
}

export const QueryEditor = (props: IQueryEditor) => {
  const tabContext = useContext(QueryTabsContext);
  const tabs = useQueryTabs();

  const setQueryTabInput = (
    event: React.FocusEvent<HTMLDivElement, Element>,
  ) => {
    tabContext.setTabs((previousTabs) => {
      const newTabs = createDeepCopy(previousTabs);
      const rowToMutate = newTabs.find((tab) => tab.id === tabs.CurrentTabId);
      if (rowToMutate) {
        rowToMutate.queryString = event.target.innerText;
      }

      return newTabs;
    });
  };

  // TODO: Use this as a starting point in CLOUD-9460 to combine the MS SQL keywords with the names of the connections,
  // schemas, and tables into a list of typeahead suggestions
  // const queryEditorKeywords = (context: CompletionContext) => {
  //   const msSqlKeywords = MSSQL.spec.keywords
  //     ?.split(" ")
  //     .map((keyword) => keyword.toUpperCase());

  //   let word = context.matchBefore(/\w*/);
  //   if (!word) return null;
  //   return {
  //     from: word.from,
  //     options: [
  //       ...(msSqlKeywords ?? []).map((keyword) => ({
  //         label: keyword,
  //         type: "keyword",
  //       })),
  //       ...(connections ?? []).map((conn) => ({
  //         label: conn.name ?? "",
  //         type: "connection",
  //       })),
  //     ],
  //   };
  // };

  const extensions = [
    langs.sql({
      upperCaseKeywords: true,
      dialect: MSSQL,
    }),
    autocompletion(),
    // autocompletion({ override: [queryEditorKeywords] }), TODO: Use this syntax in CLOUD-9460
    EditorView.lineWrapping,
    EditorView.updateListener.of((update) => {
      if (update.docChanged) {
        const value = update.state.doc.toString();
        props.setCurrentQueryInput(value);
        tabs.SetTabEmpty(tabs.CurrentTabId, value.trim() === "");
        value.trim() === "" || props.rowLimitLockdown
          ? props.setExecuteDisabled(true)
          : props.setExecuteDisabled(false);
      }
    }),
    EditorView.editable.of(!props.queryEditorDisabled),
    Prec.high(
      EditorView.domEventHandlers({
        keydown: () => {
          if (!props.hasUnsavedChanges) {
            props.setUnsavedChanges();
          }
          return false;
        },
      }),
    ),
  ];

  return (
    <ResizableDiv
      initialHeight={150}
      minHeight={150}
      maxHeight={window.innerHeight * 0.24}
      shouldResizeHorizontally={false}
      shouldResizeVertically={true}
      onHeightChange={props.setQueryEditorHeight}
    >
      <CDataCodeMirror
        sqlText={props.currentQueryInput}
        customExtensions={extensions}
        disableDefaultExtensions
        height={`${props.queryEditorHeight}px`}
        theme="light"
        onBlur={(event) => setQueryTabInput(event)}
        basicSetup={{
          bracketMatching: true,
          autocompletion: true,
        }}
        editable={!props.queryEditorDisabled}
      />
    </ResizableDiv>
  );
};
