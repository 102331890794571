import { IODataMetadata } from "../../models";
import {
  ISelectedConnection,
  ISelectedWorkspace,
} from "./components/InitialSetupContext";

export const initialSelectedConnection: ISelectedConnection = {
  driver: "",
  niceName: "",
  name: "",
  connectionId: "",
  properties: {},
};

export const initialOData: IODataMetadata = {
  dataServices: {
    schema: {
      namespace: "CData",
      entityTypes: [],
      enumTypes: [],
      complexTypes: [],
      functions: [],
      actions: [],
      entityContainer: {
        name: "CData",
        entitySets: [],
        singletons: [],
        functionImports: [],
        actionImports: [],
      },
    },
  },
};

export const initialWorkspace: ISelectedWorkspace = {
  name: "Workspace1",
  id: "",
  tables: [],
};

export const testSelectedConnection: ISelectedConnection = {
  driver: "GoogleSheets",
  niceName: "Google Sheets",
  name: "GoogleSheets1",
  connectionId: "51bd4878-74da-43b4-8cbd-c9a70e0efd49",
  properties: {},
};

export const testSelectedClient = "Salesforce Lightning Connect";
