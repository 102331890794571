/* eslint-disable max-lines */
import React, { useState, useEffect, useMemo } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import classnames from "classnames";
import { connect } from "react-redux";
import {
  BillingStatus,
  IBillingUsage,
  IConnection,
  ConnectionType,
  FeatureId,
  IPartialDriver,
  UserRole,
} from "../../models";
import { addUsage } from "../../redux/actions/usageActions";
import {
  getSettingsPageSubscriptionTab,
  ManageSubscriptionText,
  isManageSubscriptionVisible,
} from "../../utility/SubscriptionProvider";
import CustomReportTooltip from "../../components/CustomReportTooltip";

import {
  Badge,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap";

import Loader from "../../components/Loader";
import { ToastrError, ToastrSuccess } from "../../services/toastrService";
import { useNavigate } from "react-router-dom";
import { RequestType, BackendType } from "../../components/withAPI";
import { getDriverIcon } from "../../components/drivers/DriverIconFactory";
import CredentialCard from "./components/CredentialCard";
import { isFeatureAllowed } from "../../utility/SubscriptionAddonsFactory";
import { compareDrivers } from "../../components/drivers/DriverUtilities";
import { ApiConnectionPageType } from "../apiConnector/ApiConnector";
import TertiaryButton from "../../components/buttons/TertiaryButton";
import { getUpgradeLink } from "./components/ConnectionFunctions";
import { useAPI } from "../../components/useAPI";
import { useUserInfo } from "../../hooks/useUserInfo";
import { useConnectionModals } from "./connectionList/useConnectionModals";
import { useQuery } from "@tanstack/react-query";
import { getConnections } from "./connectionList/getConnections";
import { IConnectionList } from "../../models/Connections/IConnectionList";
import ListTable from "../../components/tables/ListTable";
import {
  ColumnDef,
  Row as TanstackRow,
  RowSelectionState,
} from "@tanstack/react-table";
import { compareStrings } from "../../utility/CompareStrings";
import { ErrorTable, IErrorTableRow } from "../../components/ErrorTable";
import { IFailedDeletedIdDetail } from "../../models/Batch/IFailedDeletedIdDetail";
import { formatUTCDateTime } from "../../utility/FormatUTCDateTime";
import { compareAsc } from "date-fns";
import { ConnectionStatusBadge } from "./components/ConnectionStatusBadge";
import { useIsConnectForSpreadsheets } from "../../hooks/useIsConnectForSpreadsheets";
import { AddConnectionModal } from "./AddConnectionModal";
import { useAppSelector } from "../../redux/hooks";

export type IConnectionItem = IConnection & {
  niceName: string;
  opsAllowed: number;
  isCRAvailable: boolean;
};

interface IConnectionTableRow {
  name: string;
  displayConnectionName: JSX.Element;
  connectionNiceName: string;
  dataSource: JSX.Element;
  lastModifiedRaw: string;
  lastModified: JSX.Element;
  connectionType: ConnectionType;
  editButtons: JSX.Element;
  id: string;
  isTested: boolean;
  driver: string;
  connectionTypeRaw: ConnectionType;
}

interface IConnectionsProps {
  addUsage: (usage: IBillingUsage) => void;
}

function Connections(props: IConnectionsProps) {
  const api = useAPI();

  const userInfo = useUserInfo();
  const currentUserRole = userInfo.Self.role;
  const currentUserId = userInfo.Self.id;
  const isAdminUser = userInfo.IsInRole(UserRole.Admin);
  const isQueryUser = userInfo.IsInRole(UserRole.Query);

  const [connections, setConnections] = useState<IConnectionItem[]>([]);
  const [connectionDeleteFailedMessages, setConnectionDeleteFailedMessages] =
    useState<IErrorTableRow[]>([]);
  const [drivers, setDrivers] = useState<any[]>();
  const [selectedConnections, setSelectedConnections] =
    useState<RowSelectionState>({});
  const [showConnectionModal, setShowConnectionModal] = useState(false);

  const navigate = useNavigate();
  const useModals = useConnectionModals();
  const connectionsPerSource =
    useAppSelector(
      (state) => state.subscription.limits?.connectionsPerSource,
    ) ?? -1;
  const availableFeatureIds =
    useAppSelector(
      (state) => state.subscription?.limits?.availableFeatureIds,
    ) ?? [];

  const driversList = useAppSelector((state) => state.driversList);
  const usage = useAppSelector((state) => state.usage);

  const isSpreadsheetUser = useIsConnectForSpreadsheets();
  const dataSourceCount =
    [...new Set(connections?.map((x) => x.driver))]?.length ?? 0;
  const connectionCount = connections?.length ?? 0;
  const userCredentialsAvailable = checkIfUDCPurchased();
  function getComponentName() {
    return "pages-connections-Connections";
  }

  function checkIfUDCPurchased() {
    return isFeatureAllowed(
      availableFeatureIds,
      FeatureId.UserDefinedCredentials,
    );
  }

  // Query to load the connections
  const {
    data: connectionList,
    refetch: getConnectionAsync,
    isLoading,
  } = useQuery({
    queryKey: ["/account/connections"],
    queryFn: () =>
      getConnections({ IsAdmin: isAdminUser, CurrentUserId: currentUserId }),
    meta: {
      errorMessage:
        "Failed to get user defined connection list due to the following error:",
    },
  });

  useEffect(() => {
    if (connectionList != null) {
      mapDrivers(connectionList);
    }
  }, [connectionList]); // eslint-disable-line

  async function getUsage() {
    const { status, payload } = await api.callAPI(
      RequestType.Get,
      "/account/billing/usage",
      "Failed to get account usage due to the following error:",
      BackendType.AccountService,
    );
    if (status === 200) {
      addUsage(payload);
    }
  }

  async function onDelete(
    connectionId: string,
    driver: string,
    connectionName: string,
  ) {
    const { status } = await api.callAPI(
      RequestType.Delete,
      `/account/connections/${connectionId}`,
      "Failed to delete connection due to the following error:",
    );

    if (status === 200 || status === 204) {
      getConnectionAsync();
      setSelectedConnections({});
      ToastrSuccess(
        "Connection successfully deleted",
        `Your ${driver} connection, ${connectionName} was successfully deleted.`,
      );
      setConnectionDeleteFailedMessages([]);
    }
    getUsage();
  }

  async function onBatchDelete(connections: IConnection[]) {
    const deleteQueryUrl = "/account/connections/deleteConnections";
    const requestBody = {
      ids: connections.map((connection: IConnection) => connection.id),
    };

    const { status, payload } = await api.callAPI(
      RequestType.Delete,
      deleteQueryUrl,
      "Failed to delete connection due to the following error:",
      requestBody,
    );
    if (status === 200 || status === 204) {
      const data = payload;
      setConnectionDeleteFailedMessages([]);
      getConnectionAsync();
      setSelectedConnections({});

      if (data.failedDeletedIdsDetail?.length > 1) {
        const updatedBulkDeleteFailedMessages: IErrorTableRow[] = [];

        data.failedDeletedIdsDetail.forEach((obj: IFailedDeletedIdDetail) => {
          updatedBulkDeleteFailedMessages.push({
            name: getConnectionName(obj.id),
            errorMessage: obj.error?.message,
          });
        });
        setConnectionDeleteFailedMessages(updatedBulkDeleteFailedMessages);
      } else if (data.failedDeletedIdsDetail?.length === 1) {
        ToastrError(
          "Delete Failed",
          data.failedDeletedIdsDetail[0].error.message,
        );
      } else if (data.error) {
        ToastrError("Error", data.error.message);
      }

      if (data.deletedIds.length > 0) {
        ToastrSuccess(
          "Connections successfully deleted",
          `${payload.deletedIds.length} connections were successfully deleted.`,
        );
      }
    }
    getUsage();
  }

  function mapDrivers(connectionList: IConnectionList) {
    // Map the drivers into a new array to avoid Redux state mutations
    const mappedDrivers = driversList.drivers!.map(
      (driver: IPartialDriver) => driver,
    );

    const connectionsMap = connectionList.connections!.map<IConnectionItem>(
      (connection) => ({
        ...connection,
        niceName:
          mappedDrivers.filter((driver: IPartialDriver) =>
            compareStrings(driver.driver, connection.driver),
          )[0]?.niceName ?? "",
        opsAllowed: 0,
        isCRAvailable:
          mappedDrivers.find((driver: IPartialDriver) =>
            compareStrings(driver.driver, connection.driver),
          )?.hasCustomReports ?? false,
      }),
    );

    setConnections(connectionsMap);
    setDrivers(mappedDrivers.sort(compareDrivers));
  }

  const dataSourceLimit = connectionList?.dataSourceLimit ?? 0;
  const connectionLimit = connectionList?.connectionLimit ?? 0;
  const isConnectionsMaxed =
    connectionCount === connectionsPerSource * dataSourceLimit;

  const isLimitedDataSources = dataSourceLimit !== -1;
  const isLimitedConnectionsPerSource =
    isSpreadsheetUser && connectionsPerSource !== -1;

  const isAtDataSourceLimit =
    isLimitedDataSources && dataSourceCount >= dataSourceLimit;

  const deleteButtonEnabled = Object.keys(selectedConnections)?.length > 0;

  const selectedConnectionRows = (): IConnectionItem[] => {
    const selectedUserIds = Object.keys(selectedConnections);
    const matchedUsers = connections.filter((connection) =>
      selectedUserIds.includes(connection.id!),
    );
    return matchedUsers;
  };

  function getConnectionName(connectionId: string) {
    const connection = connections.find((conn) => conn.id === connectionId);
    return connection ? connection.name : "";
  }

  function displayRedExclamationIcon(index: number) {
    return (
      <>
        <i
          className="fa fa-exclamation-triangle red-triangle me-2 fa-1x"
          id={"icon" + index}
        ></i>
        <UncontrolledTooltip
          placement="top"
          target={"icon" + index}
          trigger="hover"
        >
          Re-authentication of this connection is required.
        </UncontrolledTooltip>
      </>
    );
  }

  // If a connection is UDC but the user doesn't have the security package, this modal prompts them to fix it
  function openInvalidUDCModal(selectedDriver: string, id: string) {
    const credentialBody = (
      <CredentialCard
        existingConnectionType={ConnectionType.Shared}
        selectedConnectionType={ConnectionType.Shared}
        onCredentialTypeChange={() => {
          // no-op
        }}
        cardSource={"udcModal"}
      />
    );

    const upgradeLink = (
      <a
        className="modal-link"
        onClick={() => {
          navigate("/settings?defaultTab=" + getSettingsPageSubscriptionTab());
        }}
      >
        upgrade
      </a>
    );

    useModals.OpenDataCredentials(
      upgradeLink,
      credentialBody,
      openEditConnectionPage,
      selectedDriver,
      id,
    );
  }

  function openEditConnectionPage(
    event: React.MouseEvent | null | undefined,
    selectedDriver: string,
    id: string,
    clickedFromCustomReportsIcon: boolean,
    connectionType: ConnectionType,
    overrideConnectionType?: boolean,
  ) {
    if (event) {
      event.stopPropagation();
    }
    window.DD_RUM?.addAction("Navigating to Edit Connection page", {
      driver: selectedDriver,
    });
    const isPremium = drivers!.filter((driver: IPartialDriver) =>
      compareStrings(driver.driver, selectedDriver),
    )[0]?.premium;
    const premiumConnectorDisabled =
      isPremium &&
      !isFeatureAllowed(availableFeatureIds, FeatureId.PremiumDataSources);
    // If the connection is user defined AND the user does NOT have the security package, open a modal prompt
    if (
      connectionType === ConnectionType.UserDefined &&
      !userCredentialsAvailable
    ) {
      openInvalidUDCModal(selectedDriver, id);
    } else {
      // Otherwise, proceed as normal
      const customReportsActiveTab = clickedFromCustomReportsIcon
        ? "?defaultTab=4"
        : "";
      if (!premiumConnectorDisabled) {
        if (compareStrings(selectedDriver, "REST")) {
          navigate("/apiconnector", {
            state: {
              activeTab: null,
              driverType: "REST",
              connectionId: id,
              connections: connections,
              apiConnectionPageType: ApiConnectionPageType.EditConnection,
            },
          });
        } else {
          navigate("/connections/edit" + customReportsActiveTab, {
            state: {
              driverType: selectedDriver,
              connectionId: id,
              hasCustomReports: drivers!.find((driver: IPartialDriver) =>
                compareStrings(driver.driver, selectedDriver),
              ).hasCustomReports,
              overrideConnectionType: overrideConnectionType,
            },
          });
        }
      }
    }
  }

  function onAddConnectionButtonClick() {
    if (isAtDataSourceLimit) {
      setShowConnectionModal(true);
    } else {
      navigate("/connections/SelectConnection");
    }
  }

  const connectionsColumns = useMemo<ColumnDef<IConnectionTableRow>[]>(
    () => [
      {
        accessorKey: "name",
        id: "name",
        enableSorting: true,
        header: () => <span>Connection Name</span>,
        meta: {
          className: "name-column",
        },
        cell: ({ row }) => {
          return <>{row.original.displayConnectionName}</>;
        },
      },
      {
        accessorKey: "dataSource",
        id: "dataSource",
        enableSorting: true,
        header: () => <span>Data Source</span>,
        meta: {
          className: "dataSource-column",
        },
        sortingFn: (rowA, rowB) => {
          let driverA = rowA.original.driver;
          if (driverA === "REST") {
            driverA = "API";
          }

          let driverB = rowB.original.driver;
          if (driverB === "REST") {
            driverB = "API";
          }
          return driverA.localeCompare(driverB);
        },
        cell: ({ row }) => {
          return <>{row.original.dataSource}</>;
        },
      },
      {
        accessorKey: "status",
        id: "status",
        enableSorting: true,
        header: () => <span>Status</span>,
        meta: {
          className: "status-column",
        },
        sortingFn: (rowA, rowB) => {
          // Sorts by the contents of the badge alphabetically (Authenticated -> Conditional -> Not Authenticated)
          // by setting the order as 1) isTested, not REST; 2) REST, regardless of testing, 3) !isTested, not REST
          const getSortValue = (row: TanstackRow<IConnectionTableRow>) => {
            if (row.original.driver === "REST") return 1;
            return row.original.isTested ? 0 : 2;
          };

          return getSortValue(rowA) - getSortValue(rowB);
        },
        cell: ({ row }) => {
          return (
            <ConnectionStatusBadge
              driver={row.original.driver}
              isTested={row.original.isTested}
              isQueryUser={isQueryUser}
              isUdcConnectionType={row.original.connectionTypeRaw === 1}
            />
          );
        },
      },
      {
        accessorKey: "lastModified",
        id: "lastModified",
        enableSorting: true,
        header: () => <span>Last Modified</span>,
        sortingFn: (rowA, rowB) => {
          const date1 = new Date(rowA.original.lastModifiedRaw);
          const date2 = new Date(rowB.original.lastModifiedRaw);
          return compareAsc(date1, date2);
        },
        meta: {
          className: "lastModified-column",
        },
        cell: ({ row }) => {
          return <>{row.original.lastModified}</>;
        },
      },
      {
        accessorKey: "editButtons",
        id: "editButtons",
        enableSorting: false,
        header: () => <></>,
        meta: {
          className: "buttons-column",
        },
        cell: ({ row }) => {
          return <>{row.original.editButtons}</>;
        },
      },
    ],
    [isQueryUser],
  );

  function renderConnectionsList() {
    // Set up connections list
    let connectionsBody: IConnectionTableRow[] = [];
    if (connections.length !== 0) {
      connectionsBody = connections.map<IConnectionTableRow>(
        (connection, index) => {
          const isPremium = drivers!.filter((driver: IPartialDriver) =>
            compareStrings(driver.driver, connection.driver),
          )[0]?.premium;
          const premiumConnectorDisabled =
            isPremium &&
            !isFeatureAllowed(
              availableFeatureIds,
              FeatureId.PremiumDataSources,
            );

          const premiumConnectorTooltip =
            premiumConnectorDisabled && !usage.connectionsOverLimit ? (
              <UncontrolledTooltip
                placement="top"
                target="premiumConnection"
                trigger="hover"
                onClick={(e: { stopPropagation: () => any }) =>
                  e.stopPropagation()
                }
                autohide={false}
              >
                Premium Connectors are not available with your subscription.
                <br />
                {getUpgradeLink(props)}
                &nbsp;your plan today to continue using this connection.
              </UncontrolledTooltip>
            ) : null;
          const displayEditIcon =
            isAdminUser ||
            (isQueryUser &&
              connection.connectionType === ConnectionType.UserDefined &&
              userCredentialsAvailable);
          return {
            id: connection.id!,
            name: connection.name!,
            connectionTypeRaw: connection.connectionType,
            displayConnectionName: (
              <div
                key={connection.name}
                className={
                  premiumConnectorDisabled && !usage.connectionsOverLimit
                    ? "datasource-outer-container premiumConnectorDisabled"
                    : "datasource-outer-container"
                }
              >
                {!connection.hasUserConnectionString &&
                connection.connectionType === ConnectionType.UserDefined
                  ? displayRedExclamationIcon(index)
                  : null}
                {connection.name}
              </div>
            ),
            driver: connection.driver!,
            connectionNiceName: compareStrings(connection.driver, "REST")
              ? "API"
              : connection.niceName,
            dataSource: (
              <div
                key={connection.driver}
                id={
                  premiumConnectorDisabled && !usage.connectionsOverLimit
                    ? "premiumConnection"
                    : ""
                }
                className={
                  premiumConnectorDisabled && !usage.connectionsOverLimit
                    ? "datasource-outer-container premiumConnectorDisabled"
                    : "datasource-outer-container"
                }
              >
                {getDriverIcon(connection.driver!, "connection-icon me-2")}
                <span className="driver-name">
                  {compareStrings(connection.driver, "REST")
                    ? "API"
                    : connection.niceName}
                </span>
                <span className="beta-crIcon-outer">
                  <CustomReportTooltip
                    id={connection.id!}
                    isCRAvailable={connection.isCRAvailable}
                    selectedDriver={connection.driver!}
                    connectionType={connection.connectionType}
                    currentUserRole={currentUserRole}
                    openEditConnectionPage={openEditConnectionPage}
                  />
                </span>
                {premiumConnectorTooltip}
              </div>
            ),
            lastModifiedRaw: connection.lastModified!,
            isTested: connection.isTested ?? false,
            lastModified: (
              <div
                key={formatUTCDateTime(connection.lastModified!) + " UTC"}
                className={
                  premiumConnectorDisabled && !usage.connectionsOverLimit
                    ? "datasource-outer-container premiumConnectorDisabled"
                    : "datasource-outer-container"
                }
              >
                {formatUTCDateTime(connection.lastModified!) + " UTC"}
              </div>
            ),
            connectionType: connection.connectionType,
            editButtons: (
              <>
                {displayEditIcon && (
                  <button
                    className={classnames("table-button", {
                      "edit-button-disabled":
                        usage.connectionsOverLimit ||
                        (premiumConnectorDisabled &&
                          !usage.connectionsOverLimit),
                      "float-end": isQueryUser,
                    })}
                    onClick={(event) =>
                      !usage.connectionsOverLimit
                        ? openEditConnectionPage(
                            event,
                            connection.driver!,
                            connection.id!,
                            false,
                            connection.connectionType,
                          )
                        : ""
                    }
                    aria-label="edit connection"
                    data-testid="button-edit-connection"
                  >
                    <i className="fa-regular fa-pen align-middle"></i>
                  </button>
                )}
                {isAdminUser && (
                  <button
                    onClick={(event) =>
                      useModals.OpenDeleteConnectionModal(
                        event,
                        [connection],
                        onDelete,
                        onBatchDelete,
                        isSpreadsheetUser,
                      )
                    }
                    className="table-button"
                    aria-label="delete connection"
                    data-testid="button-delete-connection"
                  >
                    <i className="fa-regular fa-xmark fa-lg align-middle"></i>
                  </button>
                )}
              </>
            ),
          };
        },
      );
    }

    const connectionsTable = (
      <ListTable
        className={"connections-table mt-n1 mb-0"}
        columns={connectionsColumns}
        data={connectionsBody}
        emptyTableMessage={
          currentUserRole === UserRole.ServiceUser
            ? "Setup your first connection to get started."
            : "Click the Add Connection button to create a new connection."
        }
        defaultSort={[
          {
            desc: false,
            id: "name",
          },
        ]}
        enableFiltering={true}
        searchPlaceholder="Search connections..."
        enablePagination={true}
        enableCheckboxes={isAdminUser}
        onRowClick={(e: IConnectionTableRow) => {
          if (
            isAdminUser ||
            e.connectionTypeRaw === ConnectionType.UserDefined
          ) {
            openEditConnectionPage(
              null,
              e.driver,
              e.id,
              false,
              e.connectionTypeRaw,
            );
          }
        }}
        selection={selectedConnections}
        setSelection={setSelectedConnections}
      />
    );

    // Set up data source badge
    let dataSourceBadgeText;
    let dataSourceBadgeColor = "secondary";
    let dataSourceBadgeTooltip = null;
    if (dataSourceLimit === -1) {
      dataSourceBadgeText = "Unlimited";
    } else if (dataSourceLimit !== -1 && dataSourceCount > dataSourceLimit) {
      dataSourceBadgeText = "Over Limit";
      dataSourceBadgeColor = "danger";
      const excessDataSources = dataSourceCount - dataSourceLimit;
      const excessDataSourcesMessage =
        excessDataSources === 1
          ? `You are ${excessDataSources} data source over your plan limit.`
          : `You are ${excessDataSources} data sources over your plan limit.`;
      dataSourceBadgeTooltip = (
        <UncontrolledTooltip placement="top" target="dataSourceBadge">
          {excessDataSourcesMessage}
        </UncontrolledTooltip>
      );
    } else {
      dataSourceBadgeText = `${dataSourceCount}/${dataSourceLimit}`;
    }

    // Set up connection badge
    let connectionBadgeText;
    let connectionBadgeColor = "secondary";
    let connectionBadgeTooltip = null;
    if (connectionLimit === -1) {
      connectionBadgeText = "Unlimited";
    } else if (connectionCount > connectionLimit) {
      connectionBadgeText = "Over Limit";
      connectionBadgeColor = "danger";
      const excessConnections = connectionCount - connectionLimit;
      const excessConnectionsMessage =
        excessConnections === 1
          ? `You are ${excessConnections} connection over your plan limit.`
          : `You are ${excessConnections} connections over your plan limit.`;
      connectionBadgeTooltip = (
        <UncontrolledTooltip placement="top" target="connectionsBadge">
          {excessConnectionsMessage}
        </UncontrolledTooltip>
      );
    } else {
      connectionBadgeText = `${connectionCount}/${connectionLimit}`;
    }

    // Check if add connection button needs to be disabled
    const billingStatus = usage?.billingStatus;
    let addConnectionButtonDisabled;

    if (billingStatus === BillingStatus.Canceled) {
      addConnectionButtonDisabled = true;
    } else if (isSpreadsheetUser) {
      addConnectionButtonDisabled =
        isLimitedConnectionsPerSource &&
        isLimitedDataSources &&
        (usage.connectionsPerDataSourceOverLimit ||
          usage.dataSourcesOverLimit ||
          isConnectionsMaxed);
    } else {
      addConnectionButtonDisabled =
        connectionLimit !== -1 && usage.connectionsOverLimit;
    }

    const addConnectionButtonTooltip = addConnectionButtonDisabled ? (
      <UncontrolledTooltip
        placement="top"
        target="addConnection"
        trigger="hover"
        autohide={false}
      >
        {isSpreadsheetUser
          ? `You've reached your usage limit for Data Sources & Connections. `
          : ""}
        <a
          className="upgrade-link"
          onClick={() =>
            navigate("/settings?defaultTab=" + getSettingsPageSubscriptionTab())
          }
        >
          Upgrade
        </a>
        {isSpreadsheetUser
          ? ` your plan to add more, or delete some from the list below.`
          : ` your plan or remove connections to retain full Connect Cloud
        functionality.`}
      </UncontrolledTooltip>
    ) : null;

    // Check if the whole connections table should be disabled
    let connectionsTableDisabled;

    if (isSpreadsheetUser) {
      connectionsTableDisabled =
        isLimitedConnectionsPerSource &&
        isLimitedDataSources &&
        (usage.connectionsPerDataSourceOverLimit || usage.dataSourcesOverLimit);
    } else {
      connectionsTableDisabled =
        connectionLimit !== -1 && usage.connectionsOverLimit;
    }

    const connectionsTableDisabledTooltip = connectionsTableDisabled ? (
      <UncontrolledTooltip
        placement="top"
        target="connectionsTableDisabled"
        trigger="hover"
        autohide={false}
      >
        {getUpgradeLink(props)}
        &nbsp;your plan or remove connections to retain full Connect Cloud
        functionality.
      </UncontrolledTooltip>
    ) : null;
    return (
      <>
        <Row>
          <Col>
            <h1 className="h3 ms-1 mb-4">
              {isSpreadsheetUser ? "Sources" : "Connections"}
            </h1>
          </Col>
          <ErrorTable
            title={`${connectionDeleteFailedMessages.length} Errors`}
            entityName="Connection"
            data={connectionDeleteFailedMessages}
          />
          {isManageSubscriptionVisible(currentUserRole) && (
            <Col className="text-end">
              <TertiaryButton
                key="selectConnectionButton"
                onClick={() =>
                  navigate(
                    "/settings?defaultTab=" + getSettingsPageSubscriptionTab(),
                  )
                }
                data-testid="button-manage-subscription"
              >
                {ManageSubscriptionText()}
              </TertiaryButton>
            </Col>
          )}
        </Row>
        <Card>
          <CardBody>
            {isAdminUser && (
              <Row className="mb-3">
                <Col className="d-flex align-items-center">
                  <CardTitle
                    className={
                      isLimitedDataSources || isSpreadsheetUser
                        ? "ms-1 mb-0"
                        : "hidden"
                    }
                  >
                    Data Sources:
                  </CardTitle>
                  <span
                    className={
                      isLimitedDataSources || isSpreadsheetUser
                        ? "ms-2"
                        : "hidden"
                    }
                  >
                    <Badge
                      id="dataSourceBadge"
                      color={dataSourceBadgeColor}
                      size={16}
                    >
                      {dataSourceBadgeText}
                    </Badge>
                    {dataSourceBadgeTooltip}
                  </span>
                  {!isSpreadsheetUser ? (
                    <>
                      <CardTitle
                        className={
                          isLimitedDataSources ? "ms-3 mb-0" : "ms-1 mb-0"
                        }
                      >
                        Connections:
                      </CardTitle>
                      <span className="ms-2">
                        <Badge
                          id="connectionsBadge"
                          color={connectionBadgeColor}
                          size={16}
                        >
                          {connectionBadgeText}
                        </Badge>
                        {connectionBadgeTooltip}
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                </Col>
                {isAdminUser && (
                  <Col className="text-end">
                    <span>
                      <Button
                        disabled={!deleteButtonEnabled}
                        color="secondary"
                        onClick={(event: React.MouseEvent) =>
                          useModals.OpenDeleteConnectionModal(
                            event,
                            selectedConnectionRows() as IConnection[],
                            onDelete,
                            onBatchDelete,
                            isSpreadsheetUser,
                          )
                        }
                        className="me-1 card-actions"
                      >
                        <i className="fa fa-times icon no-pointer-event"></i>
                        Delete
                      </Button>
                      <span id="addConnection" className="tooltip-padding">
                        <Button
                          color="primary"
                          className={
                            addConnectionButtonDisabled
                              ? "me-1 no-pointer-event"
                              : "me-1"
                          }
                          /*apply ternary condition for limit in onclick*/
                          onClick={() => onAddConnectionButtonClick()}
                          disabled={addConnectionButtonDisabled}
                        >
                          <i className="fa fa-plus icon no-pointer-event"></i>
                          Add Connection
                        </Button>
                      </span>
                    </span>
                    {addConnectionButtonTooltip}
                  </Col>
                )}
              </Row>
            )}
            <div
              id="connectionsTableDisabled"
              className={
                connectionsTableDisabled ? "connections-table-disabled" : ""
              }
            >
              {connectionsTableDisabledTooltip}
              {connectionsTable}
            </div>
          </CardBody>
        </Card>
      </>
    );
  }

  if (isLoading) {
    return (
      <Container fluid className="p-0">
        <Loader />
      </Container>
    );
  }

  return (
    <Container fluid className={`p-0 ${getComponentName()}`}>
      {renderConnectionsList()}
      <AddConnectionModal
        connections={connections}
        connectionsPerSource={connectionsPerSource}
        drivers={drivers ?? []}
        isAtDataSourceLimit={isAtDataSourceLimit}
        showConnectionModal={showConnectionModal}
        setShowConnectionModal={setShowConnectionModal}
      />
    </Container>
  );
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    addUsage: (billingUsageInfo: IBillingUsage) =>
      dispatch(addUsage(billingUsageInfo)),
  };
};

export default connect(
  (store: any) => ({
    usage: store.usage,
  }),
  mapDispatchToProps,
)(Connections);
