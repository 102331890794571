import { cdataFetch } from "../../../api/cdataFetch";
import { IBatchDeleteRequest, IBatchDeleteResponse } from "../../../models";

export async function deleteScheduledQueries(request: IBatchDeleteRequest) {
  return cdataFetch<IBatchDeleteResponse>({
    method: "DELETE",
    url: `/scheduledquery`,
    body: request,
  });
}
