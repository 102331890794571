import React from "react";
import { Helmet } from "react-helmet";

interface IFullScreenDashboardContentProps {
  children: React.ReactNode;
}

function FullScreenDashboardContent(props: IFullScreenDashboardContentProps) {
  return (
    <>
      <Helmet>
        <style type="text/css">{`
          .content {
            padding: 0;
          }
        `}</style>
      </Helmet>
      {props.children}
    </>
  );
}

export default FullScreenDashboardContent;
