import { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { IAccountStatsReport, UserRole } from "../../models";
import Statistic from "./Statistic";
import { NavLink, useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import { useAPI } from "../../components/useAPI";
import { getAccountStatistics } from "./components/OverviewApiCalls";
import { useAppSelector } from "../../redux/hooks";

export function getPercentageChange(previous: number, current: number): string {
  if (current !== previous) {
    if (current !== 0 && previous === 0) {
      return "+100%";
    } else if (current === 0 && previous !== 0) {
      return "-100%";
    } else {
      const percentage = ((current / previous) * 100).toFixed(1) + "%";
      if (current < previous) {
        return "-" + percentage;
      } else {
        return "+" + percentage;
      }
    }
  } else {
    return "+0%";
  }
}

export function getTimespanMessage(timespan: number): string {
  switch (timespan) {
    case 1:
      return "today";
    case 7:
      return "this week";
    case 30:
      return "this month";
    default:
      return "";
  }
}

interface IStatisticsProps {
  period: number;
}

export const Statistics = (props: IStatisticsProps) => {
  const api = useAPI();
  const user = useAppSelector((state) => state.user);
  const navigate = useNavigate();

  const [accountStatistics, setAccountStatistics] =
    useState<IAccountStatsReport | null>({} as IAccountStatsReport);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const statistics = await getAccountStatistics(api.callAPI, props.period);
      setAccountStatistics(statistics);
      setIsLoading(false);
    };

    fetchData();
  }, [props.period]); // eslint-disable-line

  if (isLoading) {
    return <Loader customHeightClass="statistics-loader-height" />;
  }

  if (!accountStatistics) {
    return null;
  }

  const isOEM = user?.role === UserRole.ServiceUser;

  const current = accountStatistics.current;
  const previous = accountStatistics.previous;

  const percentChangeQueries = getPercentageChange(
    previous.queries,
    current.queries,
  );

  const percentChangeRows = getPercentageChange(previous.rows, current.rows);

  const percentChangeConnections = getPercentageChange(
    previous.connections,
    current.connections,
  );

  const percentChangeUsers = getPercentageChange(previous.users, current.users);

  const timespanMessage = getTimespanMessage(props.period);

  const navigateToLogs = () => {
    navigate("/logs", {
      state: { timePeriod: props.period },
    });
  };

  return (
    <Container fluid className="pages-status-Statistics">
      <Row data-testid="statistics" className="statistics-row">
        <Col md="6" xl onClick={isOEM ? undefined : () => navigateToLogs()}>
          <Statistic
            title="Rows"
            icon={
              <i className="fa-regular fa-bars fa-lg text-primary bars"></i>
            }
            value={current.rows}
            percentChange={percentChangeRows}
            timespanMessage={timespanMessage}
          />
        </Col>
        <Col md="6" xl onClick={isOEM ? undefined : () => navigateToLogs()}>
          <Statistic
            title="Queries"
            icon={
              <i className="fa-regular fa-wave-pulse fa-lg text-primary wave-pulse"></i>
            }
            value={current.queries}
            percentChange={percentChangeQueries}
            timespanMessage={timespanMessage}
          />
        </Col>
        <Col md="6" xl>
          <NavLink to="/connections">
            <Statistic
              title="Active Connections"
              icon={
                <i className="fa-regular fa-database fa-lg text-primary database"></i>
              }
              value={current.connections}
              percentChange={percentChangeConnections}
              timespanMessage={timespanMessage}
            />
          </NavLink>
        </Col>
        <Col md="6" xl hidden={isOEM}>
          <NavLink to="/user">
            <Statistic
              title="Active Users"
              icon={
                <i className="fa-regular fa-user fa-lg text-primary user"></i>
              }
              value={current.users}
              percentChange={percentChangeUsers}
              timespanMessage={timespanMessage}
            />
          </NavLink>
        </Col>
      </Row>
    </Container>
  );
};
