import React, { useState } from "react";
import { InfoIcon } from "../../../../../../../components/InfoIcon";
import { QueryBuilderAddButton } from "../components/QueryBuilderAddButton";
import { QueryBuilderCard } from "../components/QueryBuilderCard";
import { QueryBuilderCardHeader } from "../components/QueryBuilderCardHeader";
import { IQueryBuilderCommonProps } from "../../../models/IQueryBuilderCommonProps";
import { AddMetricsModal } from "./AddMetricsModal";
import { produce } from "immer";
import { QueryBuilderColumnChipList } from "../components/QueryBuilderColumnChipList";
import { IQueryBuilderColumn } from "../../../models/IQueryBuilderColumn";
import { getFullyQualifiedColumnName } from "../../../sqlGenerator/getFullyQualifiedColumnName";
import { CDataModalV2 } from "../../../../../../../components/modal/CDataModalV2";
import {
  ButtonType,
  CDataButton,
} from "../../../../../../../components/buttons/CDataButton";
import { CDataTypography } from "../../../../../../../components/text/CDataTypography";

interface QueryBuilderMetricsCardProps extends IQueryBuilderCommonProps {}

export function QueryBuilderMetricsCard(props: QueryBuilderMetricsCardProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isQueryBuilderEmpty = props.queryData.from.connectionId === "";
  function onClickAddMetrics() {
    setIsModalOpen(true);
  }

  return (
    <QueryBuilderCard>
      <QueryBuilderCardHeader
        title={
          <span>
            Metrics{" "}
            <InfoIcon
              iconId="metrics-tooltip"
              tooltipMessage="Metrics are quantitative measures that represent a specific aspect of the data, such as total revenue, customer count, or average order value."
            />
          </span>
        }
      >
        <QueryBuilderAddButton
          onClick={() => onClickAddMetrics()}
          isQueryBuilderEmpty={isQueryBuilderEmpty}
          id="add-meteric-button"
        >
          Add Metrics
        </QueryBuilderAddButton>
      </QueryBuilderCardHeader>
      <CDataTypography>
        Define metrics to aggregate data with functions like COUNT, AVERAGE and
        MAX to create key insights within a dataset.
      </CDataTypography>
      <MetricsChipList {...props} />
      <AddMetricsModal
        {...props}
        displayed={isModalOpen}
        close={() => setIsModalOpen(false)}
      />
    </QueryBuilderCard>
  );
}

function MetricsChipList(props: QueryBuilderMetricsCardProps) {
  const { queryData, setQueryData, connectionsList } = props;
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [currMetricIndex, setCurrMetricIndex] = useState(-1);

  if (queryData.metrics.length === 0) {
    return null;
  }

  const metricDeleteWarning = (
    <CDataModalV2
      displayed={showDeleteWarning}
      modalSize={"lg"}
      spacedFooter={false}
      close={() => setShowDeleteWarning(false)}
      title={"Delete Metric"}
      primaryButton={
        <CDataButton
          buttonType={ButtonType.Danger}
          onClick={() => {
            onRemoveColumn(currMetricIndex);
            setShowDeleteWarning(false);
          }}
        >
          Delete
        </CDataButton>
      }
      secondaryButton={
        <CDataButton
          buttonType={ButtonType.Secondary}
          onClick={() => setShowDeleteWarning(false)}
        >
          Cancel
        </CDataButton>
      }
    >
      <CDataTypography>
        Deleting this metric will remove all other customizations made within
        the Query Builder that are linked to this metric. Are you sure you want
        to proceed?
      </CDataTypography>
    </CDataModalV2>
  );

  function onRemoveColumn(index: number) {
    const newQueryData = produce(queryData, (draft) => {
      draft.metrics.splice(index, 1);
    });

    setQueryData(newQueryData);
  }

  function onReorderColumns(oldColumnIndex: number, newColumnIndex: number) {
    const reorderedColumns = produce(queryData, (draft) => {
      draft.metrics.splice(
        newColumnIndex,
        0,
        draft.metrics.splice(oldColumnIndex, 1)[0],
      );
    });

    setQueryData(reorderedColumns);
  }

  function getColumnId(column: IQueryBuilderColumn): string {
    const fullColumnName = getFullyQualifiedColumnName(column);

    const matchingMetric = queryData.metrics.find((m) => m.column === column);

    if (matchingMetric == null) {
      return fullColumnName;
    }

    return fullColumnName + "-" + matchingMetric.operatorType;
  }

  const allSorts = new Set([
    ...queryData.sort.map((s) => getFullyQualifiedColumnName(s.column)),
  ]);
  const allGroupBys = new Set([
    ...queryData.groupBy.map((g) => getFullyQualifiedColumnName(g.column)),
  ]);

  return (
    <>
      {metricDeleteWarning}
      <QueryBuilderColumnChipList
        columns={queryData.metrics.map((g) => g.column)}
        connectionsList={connectionsList}
        onRemoveColumn={(column, index) => {
          if (
            !allGroupBys.has(getFullyQualifiedColumnName(column)) &&
            allSorts.has(getFullyQualifiedColumnName(column))
          ) {
            setCurrMetricIndex(index);
            setShowDeleteWarning(true);
          } else {
            onRemoveColumn(index);
          }
        }}
        onReorderColumns={onReorderColumns}
        getColumnText={(column, index) =>
          `${column.column}_${queryData.metrics[index].operatorType.replaceAll(" ", "_")}`
        }
        getColumnId={getColumnId}
      />
    </>
  );
}
